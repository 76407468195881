import React from 'react';

interface TotalCardProps {
  title: string;
  value: number;
  percent: number;
}
export default function TotalCard({ title, value, percent }: TotalCardProps) {
  return (
    <div style={{ padding: '24px 21px' }} className="card">
      <h4 className="loans-overview__metric-section__title-secondary">{title}</h4>
      <div style={{ display: 'flex' }}>
        <span className="loans-overview__metric-section__small-section-value">{value}</span>
        <div className="loans-overview__metric-section__percent-container">
          <div className="triangle" />
          <div className="loans-overview__metric-section__percent-value">{percent}%</div>
        </div>
      </div>
    </div>
  );
}
