export enum UserRoleString {
  UNRECOGNIZED = 'UNRECOGNIZED',
  ANONYMOUS = 'ANONYMOUS',
  GUEST = 'GUEST',
  PHONE_VERIFIED = 'PHONE_VERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',

  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  VERIFIED = 'VERIFIED',

  ADMIN = 'ADMIN',
  BORROWER = 'BORROWER',
  LENDER = 'LENDER',

  DEACTIVATED = 'DEACTIVATED',
  SUSPENDED = 'SUSPENDED',

  COGNITO_VERIFIED = 'COGNITO_VERIFIED',
  COGNITO_UNVERIFIED = 'COGNITO_UNVERIFIED',
}
