import { FormikHelpers, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label } from '../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { validatePasswordStrength } from '../../../shared/utils/validation';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { acceptInvitation } from '../store/actions/acceptInvitation';

type InviteRouteParams = {
  token: string;
};

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const inviteValidationSchema = yup.object({
  password: yup
    .string()
    .min(8)
    .test('is-strong', 'Password is not strong', (passVal) => {
      const [isValid, _] = validatePasswordStrength(passVal || '');
      return !!isValid;
    })
    .label('Password')
    .required(),
  passwordConfirmation: yup
    .string()
    .label('Password confirmation')
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required(),
});

export default function Invite() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { token: inviteToken } = useParams<InviteRouteParams>();
  const { loading } = useAppSelector((state) => state.auth);
  const [type, setType] = useState<'text' | 'password'>('text');
  const [typeConfirm, setTypeConfirm] = useState<'text' | 'password'>('text');

  const onSubmit = async ({ password }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    const result = await dispatch(acceptInvitation({ inviteToken, password }));

    if (acceptInvitation.fulfilled.match(result)) {
      Amplitude.logEvent(AmplitudeEvents.memberActivated);
      history.push('/success-activate');
    }

    if (acceptInvitation.rejected.match(result)) {
      // @ts-ignore
      setErrors(result.payload);
    }
  };

  const { errors, handleSubmit, handleChange, handleBlur, values, touched, dirty, isValid } =
    useFormik<FormValues>({
      initialValues: {
        password: '',
        passwordConfirmation: '',
      },
      onSubmit,
      validationSchema: inviteValidationSchema,
    });

  const buildErrorText = (field: 'password' | 'passwordConfirmation') =>
    Boolean(errors[field]) && touched[field] && errors[field];

  const [_, passwordStrengthVector] = useMemo(
    () => validatePasswordStrength(values.password),
    [values.password],
  );

  if (!inviteToken) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
        }}
      />
    );
  }

  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignIn}
      bannerContent={AuthBannerContent.Invite}
    >
      <form action="#" className="auth-form auth-form--styled" onSubmit={handleSubmit}>
        <h1 className="auth-form__title">Accept invite</h1>

        <div className="form-group">
          <Label forId="password">Password</Label>
          <Input
            type={type}
            id="password"
            placeholder="Create a password"
            createMode
            passStrengthVector={passwordStrengthVector}
            error={buildErrorText('password')}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => setType('password')}
          />
        </div>

        <div className="form-group">
          <Label forId="passwordConfirmation">Confirm password</Label>
          <Input
            type={typeConfirm}
            id="passwordConfirmation"
            placeholder="Confirm password"
            value={values.passwordConfirmation}
            error={buildErrorText('passwordConfirmation')}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => setTypeConfirm('password')}
          />
        </div>

        <button
          data-testid="submit"
          type="submit"
          className="button button--primary-blue button--lg button--block"
          disabled={loading || !dirty || !isValid}
        >
          Sign in
        </button>
      </form>
    </AuthorizationLayout>
  );
}
