import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../main/store';
import { useAppSelector } from '../../../main/store/hooks';
import { Icon, Tooltip } from '../../../shared';
import ModalImagePreview from '../../integrations/components/ModalImagePreview';
import ValidationPreview from '../components/ValidationPreview';
import { overViewList } from '../helpers/overViewData';
import DevToolsLayout from '../layout/DevToolsLayout';

import ValidationAccountCreationPage from './ValidationAccountCreationPage';

const SftpOverviewPage = () => {
  const history = useHistory();
  const { sftpSetting } = useAppSelector((state: RootState) => state.devTools);

  const [isOpen, setIsOpen] = useState(true);
  const [isZoomModal, setIsZoomModal] = useState(false);
  const [isValidationAccountCreationPage, setIsValidationAccountCreationPage] = useState(false);

  const handleToggleValidationPreview = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleAccountCreationPage = useCallback(() => {
    setIsValidationAccountCreationPage(!isValidationAccountCreationPage);
  }, [isValidationAccountCreationPage]);

  const toggleZoomModal = useCallback(() => {
    setIsZoomModal(!isZoomModal);
  }, [isZoomModal]);

  if (isValidationAccountCreationPage) {
    if (sftpSetting) {
      history.push('/developer-tools/sftp/configuration');
    }
    return <ValidationAccountCreationPage onBack={toggleAccountCreationPage} />;
  }

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Account Validation</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">Account Validation</h2>
      <div className="validation__wrapper">
        <div className={`validation card ${!isOpen && 'open'}`}>
          <div className="dev-tools__wrap-title">
            <h3 className="dev-tools__title dev-tools__title--validation">
              Send account validation data to Zirtue
            </h3>
          </div>
          <div className="validation__container-list">
            {overViewList.map((item) => (
              <div
                key={item.title}
                className={`${item.borderTop && 'validation__list--border-top'}`}
              >
                <div className="validation__list-title">{item.title}</div>
                <ul className="validation__list">
                  {item.items.map((subItem, index) => (
                    <li
                      key={subItem.text}
                      className="validation__list-item validation__list-item--"
                    >
                      {item.listType === 'numeric' ? (
                        // eslint-disable-next-line react/jsx-one-expression-per-line
                        <strong>{index + 1}.&nbsp;</strong>
                      ) : (
                        <Icon name="check" classes="validation__check-icon" />
                      )}

                      {subItem.text}
                      {subItem.tooltipText && (
                        <Tooltip
                          title={subItem.tooltipTitle}
                          text={subItem.tooltipText}
                          position="right center"
                        >
                          <div>
                            <Icon name="feedback" />
                          </div>
                        </Tooltip>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="line" />
          <div className="dev-tools__control">
            <button
              type="submit"
              className="button button--primary-blue button--lg"
              onClick={toggleAccountCreationPage}
            >
              Next
            </button>
          </div>
        </div>
        <ValidationPreview
          handleToggleValidationPreview={handleToggleValidationPreview}
          isOpen={isOpen}
          toggleZoomModal={toggleZoomModal}
        />
      </div>

      <ModalImagePreview
        isOpen={isZoomModal}
        setIsOpen={setIsZoomModal}
        image="/images/dashboard/modals/zirtue-preview-experience-3.png"
      />
    </DevToolsLayout>
  );
};

export default SftpOverviewPage;
