import { FC } from 'react';

import Button from '../../../shared/components/Button';
import { download } from '../../../shared/utils/helpers';

type ValidationPreviewProps = {
  handleToggleValidationPreview: () => void;
  toggleZoomModal: () => void;
  isOpen?: boolean;
};

const ValidationPreview: FC<ValidationPreviewProps> = ({
  handleToggleValidationPreview,
  toggleZoomModal,
  isOpen,
}) => (
  <div className={`validationPreview ${isOpen ? 'open' : ''}`}>
    <div className={`validationPreview__header ${!isOpen ? 'closed' : ''}`}>
      {isOpen ? 'Preview' : ''}
      {/* eslint-disable-next-line */}
      <img
        src="/images/dashboard/angle-right-solid.svg"
        alt="angle-right-solid"
        onClick={handleToggleValidationPreview}
      />
    </div>
    <div className={`validationPreview__body ${!isOpen ? 'closed' : ''}`}>
      <div className="validationPreview__title">Sample file download</div>
      <div className="validationPreview__text">
        Download an example CSV file of the type of data you will be required to share with us.
      </div>
      <Button
        type="button"
        className="button button--outline-gray button--lg validationPreview__download-btn"
        icon="file-csv"
        onClick={() => {
          download('/files/account_validation.csv', 'account_validation.csv');
        }}
      >
        Download file
      </Button>
      <div className="validationPreview__image-container">
        <div className="validationPreview__title">Billing account validation</div>
        <div className="validationPreview__text">
          Here is how your customers will be validated in the Zirtue app.
        </div>
        <div
          onClick={toggleZoomModal}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleZoomModal();
            }
          }}
          tabIndex={0}
          role="button"
        >
          <img
            alt="validationPreview"
            className="validationPreview__image"
            src="/images/dashboard/zirtue-preview-experience.png"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ValidationPreview;
