import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { PartnerMember, PartnerMembersDto, PartnerMemberModel } from '../../../../shared/models';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getCompanyMembers = createAsyncThunk<
  Array<PartnerMember>,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/getMembers', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<PartnerMembersDto>(`/members`);
    const response = data.members.map((item) => PartnerMemberModel.fromDTO(item));
    const sortedMembers = response.sort((memberA, memberB) => {
      const b = memberB.firstName || 'unavailable';
      const a = memberA.firstName || 'unavailable';
      if (a > b || a === 'unavailable') {
        return 1;
      }
      if (a < b || b === 'unavailable') {
        return -1;
      }
      return 0;
    });

    return sortedMembers;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
