import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';

import { FeatureTogglesNames } from '../../../../main/env/feature-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { FormWrapper } from '../../../init-setup/components';
import newLoan from '../../assets/new-loan.svg';
import preExistingLoan from '../../assets/pre-existing-loan.svg';
import successWhite from '../../assets/success-white.svg';
import { LoanType, setLoanType } from '../../store';

type FormData = {
  loanType: string;
};

type FormicDataTypes = {
  currentStepIndex: number;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
};

type LoanTypeSelectProps = {
  type: LoanType;
  currentValue: string;
  onSelect: (type: LoanType) => void;
  imgSrc: string;
  title: string;
  subtitle: string;
};

const LoanTypeSelect = ({
  type,
  currentValue,
  onSelect,
  imgSrc,
  title,
  subtitle,
}: LoanTypeSelectProps) => (
  // eslint-disable-next-line
  <div
    className={classNames(
      'loanTypeForm__typeSelect',
      currentValue === type && 'loanTypeForm__typeSelect--active',
    )}
    onClick={() => onSelect(type)}
  >
    <div className="loanTypeForm__typeSelect__left">
      <img src={imgSrc} alt={title} />
      <div className="loanTypeForm__typeSelect__descr">
        <div className="loanTypeForm__typeSelect__title">{title}</div>
        <div className="loanTypeForm__typeSelect__subtitle">{subtitle}</div>
      </div>
    </div>
    <div
      className={classNames(
        'loanTypeForm__typeSelect__radio',
        currentValue === type && 'loanTypeForm__typeSelect__radio--active',
      )}
    >
      <img src={successWhite} alt="Selected" />
    </div>
  </div>
);

export default function SelectLoanTypeForm({ currentStepIndex, setFieldValue }: FormicDataTypes) {
  const TOGGLES_LOAN_CREATOR_TYPE_NEW = useFlag(FeatureTogglesNames.TOGGLES_LOAN_CREATOR_TYPE_NEW);
  const TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING = useFlag(
    FeatureTogglesNames.TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING,
  );

  const { loanType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');

  const handleChangeType = (type: LoanType) => {
    dispatch(setLoanType(type));
    setFieldValue('loanType', type);
    setValue(type);
  };

  useEffect(() => {
    setValue(loanType);
  }, [currentStepIndex, loanType]);

  return (
    <FormWrapper>
      <div className="loanTypeForm">
        <div className="loanTypeForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">Pick your loan type</h2>
            <div className="loanTypeForm__subtitle">
              Below you have the option to initiate a new loan or manage the repayments of an
              outstanding loan.
            </div>
          </div>
          <div className="loanTypeForm__body">
            {TOGGLES_LOAN_CREATOR_TYPE_NEW && (
              <LoanTypeSelect
                type={LoanType.NEW_LOAN}
                currentValue={value}
                onSelect={handleChangeType}
                imgSrc={newLoan}
                title="New loan"
                subtitle="Create a loan offer for a member of your community through Zirtue."
              />
            )}
            {TOGGLES_LOAN_CREATOR_TYPE_PRE_EXISTING && (
              <LoanTypeSelect
                type={LoanType.PRE_EXISTINS_LOAN}
                currentValue={value}
                onSelect={handleChangeType}
                imgSrc={preExistingLoan}
                title="Pre-existing loan"
                subtitle="Create repayment request for a member of your community through Zirtue."
              />
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
