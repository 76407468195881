import { Suspense, lazy, useEffect, useState } from 'react';

import { useAppSelector } from '../../../main/store/hooks';
import CheckoutList from '../../../shared/components/CheckoutList/CheckoutList';
import { FlowTypes } from '../../auth/store';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { SelectionFlow } from '../components';
import MultiStepForm from '../components/MultiStepForm/MultiStepForm';

const LazyComponent = lazy(() => import('../components/SelectionFlow/SelectionFlow'));

export default function InitSetup() {
  const {
    partner,
    selectedFlow: flow,
    finishedSetup,
    currentStepSetup,
  } = useAppSelector((state) => state.auth);
  const [mainComponent, setMainComponent] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (
      (!finishedSetup && currentStepSetup === 1) ||
      (!finishedSetup && currentStepSetup === 2 && flow === FlowTypes.AFFILIATE_PARTNER)
    ) {
      setMainComponent(<LazyComponent />);
    } else if (flow !== FlowTypes.AFFILIATE_PARTNER && !finishedSetup) {
      setMainComponent(<MultiStepForm />);
    } else if (finishedSetup) {
      setMainComponent(<CheckoutList />);
    }
  }, [currentStepSetup, flow, finishedSetup]);

  return (
    <DashboardLayout pageTitle="Get Started">
      <Suspense fallback={<div>Loading...</div>}>
        {partner && <div className="init-setup">{mainComponent}</div>}
      </Suspense>
    </DashboardLayout>
  );
}
