// TODO: design a project-wide pattern for that (file naming, placement, composability, etc)
//   `routePages` from the Router.tsx seems not to be used anywhere,
//   and it if we decide to start using it, it'll create circular dependencies,
//   eg: Router -> ... -> DashboardNav -> Router
export const PLATFORM_ADMIN_ROUTES = {
  USERS: '/platform-admin/users',
  LOANS: '/platform-admin/loans',
  TRANSACTIONS: '/platform-admin/transactions',
  PARTNERS: '/platform-admin/partners',
  PAYMENTS: '/platform-admin/payments',
};
