import { createAsyncThunk } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { TransactionsSearchResponseDto } from '../../../../shared/models/platform-admin/transactions/TransactionsSearchResponseDto';
import { selectUsersState } from '../selectors/users';

export const adminTransaction = createAsyncThunk<
  TransactionsSearchResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>('platformAdmin/adminTransaction', async (_, { getState }) => {
  const { selectedItemId } = selectUsersState(getState());
  const { selectedItemTransaction } = getState().platformAdmin;

  const { page, pageSize, sortField, sortOrder } = selectedItemTransaction;

  const { data } = await api.get<TransactionsSearchResponseDto>(
    '/platform-admin/transfers/search',
    {
      params: <{ callbackId: string }>{
        callbackId: selectedItemId,
        dateStart: addDays(new Date(), -365),
        dateEnd: new Date(),
        limit: pageSize,
        skip: (page - 1) * pageSize,
        sortField,
        sortOrder,
      },
    },
  );

  return data;
});
