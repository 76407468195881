import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { useAppDispatch } from '../../../main/store/hooks';
import { CompanyUpdateData, Input, Label, Select, MaskInput } from '../../../shared';
import { Partner } from '../../../shared/models/PartnerModel';
import { AlertType, setModalConfirmOpen, addAlert } from '../../../shared/store/modals';
import { getContact } from '../../../shared/utils';
import { getPartner } from '../../auth/store/actions';
import { updateCompany } from '../store/actions/updateCompany';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SUPPORTED_FORMATS = ['image/png'];

const validationSchema1 = yup.object({
  email: yup.string().email().label('Company email').required(),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number to short')
    .max(12, 'Phone number to long'),
});

const validationSchema2 = yup.object({
  email: yup.string().email().label('Company email').required(),
  profilePicture: yup
    .mixed()
    .test(
      'supportedFormat',
      'Unsupported file`s type',
      (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type)),
    ) // @ts-ignore
    .test('typeFile', 'File should be 80 x 80px', async (value) => {
      if (value && SUPPORTED_FORMATS.includes(value.type)) {
        try {
          const promise = new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(value);
            img.onload = () => resolve(img.width === 80 && img.height === 80);
          });
          const isValid = await promise;
          return isValid;
        } catch (error) {
          return false;
        }
      }
      return false;
    })
    .test(
      'sizeFile',
      'File should`t be more then 0.5MB',
      (value) => value && value.size / 1000 <= 500,
    ),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number to short')
    .max(12, 'Phone number to long'),
});

type FormValues = {
  profilePicture?: File;
  email: string;
  phone: string;
  codeCountry: string;
};

type Props = {
  partner: Partner;
};

const CompanyGeneralInfoEdit: React.FC<Props> = ({ partner }: Props) => {
  const [schema, setSchema] = useState(validationSchema1);
  const [rerender, setRerender] = useState(1);
  const dispatch = useAppDispatch();
  const phone = getContact(partner?.details.phoneUri);

  const modalConfirmTitle = 'Confirm business email address change';
  const modalConfirmText = [
    'Your current company email address is Zirtue’s primary way to reach out to your business regarding the partner dashboard.',
    'Once changed all communications will transfer to  the new email address.',
  ];
  const modalConfirmOkText = 'Yes, change email';
  const modalConfirmCancelText = 'No, don’t change email';
  function getFirstLetters(name?: string): string {
    if (name) {
      return name
        .trim()
        .split(' ')
        .map((word: string) => word[0].toUpperCase())
        .join('');
    }
    return '';
  }

  const updCompany = async (req: CompanyUpdateData) => {
    const result = await dispatch(updateCompany(req));

    if (updateCompany.fulfilled.match(result)) {
      dispatch(addAlert({ text: 'Save changes successfully' }));

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setSubmitting(true);

      setTimeout(async () => {
        await dispatch(getPartner());
        setRerender((prev) => prev + 1);
      }, 2000);
    }

    if (updateCompany.rejected.match(result)) {
      dispatch(
        addAlert({
          text: (result.payload as string) || 'Error while adding account.',
          type: AlertType.error,
        }),
      );
    }
  };

  const {
    errors,
    values,
    touched,
    setFieldValue,
    isValid,
    dirty,
    submitForm,
    handleBlur,
    isSubmitting,
    setSubmitting,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      profilePicture: undefined,
      email: getContact(partner?.details.emailUri),
      phone: phone.slice(-10),
      codeCountry: phone[1] || '1',
    },
    onSubmit: async (v: FormValues) => {
      const req: CompanyUpdateData = {};

      if (partner?.details.emailUri !== `mailto:${v.email}`) {
        req.businessEmailUri = `mailto:${v.email}`;
      }
      if (v.profilePicture) {
        req.file = v.profilePicture;
      }
      const valuePhone = `tel:+${v.codeCountry}${v.phone.split('-').join('')}`;
      if (partner?.details.phoneUri !== valuePhone) {
        req.businessPhoneUri = valuePhone;
      }

      if (req.businessEmailUri) {
        dispatch(
          setModalConfirmOpen({
            opened: true,
            title: modalConfirmTitle,
            text: modalConfirmText,
            okText: modalConfirmOkText,
            cancelText: modalConfirmCancelText,
            onConfirm: () => updCompany(req),
          }),
        );
      } else {
        updCompany(req);
      }
    },
    validationSchema: schema,
  });

  useEffect(() => {
    if (errors.profilePicture) {
      dispatch(addAlert({ text: errors.profilePicture, type: AlertType.error }));
    }
  }, [errors]);

  const fieldChange = (field: string, value: string | File) => {
    setSubmitting(false);
    if (field === 'profilePicture') {
      setFieldError('profilePicture', '');
      // @ts-ignore
      setSchema(validationSchema2);
      setTimeout(() => {
        setFieldValue(field, value);
      }, 200);
    } else {
      setFieldValue(field, value);
    }
  };

  return (
    <div className="col profile-edit__col-general">
      <form className="card">
        <h2 className="card__title">General information</h2>
        {/* Intentional comment, may be used in the future */}

        {/* <p className="card__text">
          You can upload or change your profile picture by clicking below.
        </p>
        <div className="form-group mb-8">
          <p className="label">Profile picture</p>
          <UploadPhoto
            initials={getFirstLetters(partner?.details.name || '')}
            onFileSelect={(file: File) => {
              fieldChange('profilePicture', file);
            }}
            photoUrl={partner.details.icons?.highRes || ''}
            rerender={rerender}
          />
        </div> */}
        <div className="form-group mb-8">
          <Label forId="company-name">Company name</Label>
          <Input
            type="text"
            id="company-name"
            placeholder="Company name"
            value={partner?.details.name}
            prependImageUrl={partner.details.icons?.highRes}
            prependInitials={getFirstLetters(partner?.details.name || '')}
            disabled
            rerender={rerender}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="company-email">Company email</Label>
          <Input
            type="email"
            id="email"
            placeholder="Company email"
            error={Boolean(errors.email) && touched.email && errors.email}
            value={values.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              fieldChange('email', e.target.value)
            }
            onBlur={handleBlur}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="deep-link">Subdomain</Label>
          <Input
            type="text"
            id="deep-link"
            value={partner?.details?.urlFriendlyName}
            disabled
            copyButton
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="company-phone">Phone number</Label>
          <div className="form-group__row form-group__row--phone-code profile-edit__phone-group">
            <div className="form-group__row-item form-group__row-item--phone-code">
              <Select
                id="codeCountry"
                haveFlag
                data={[{ dataId: '1', name: '+1' }]}
                value={values.codeCountry}
                classes="select--small"
                onChange={(e) => fieldChange('codeCountry', e)}
              />
            </div>
            <div className="form-group__row-item">
              <MaskInput
                mask="999-999-9999"
                id="phone"
                inputClassName="input input-group__input"
                placeholder="Enter business phone number"
                value={values.phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  fieldChange('phone', e.target.value)
                }
                error={Boolean(errors.phone) && touched.phone && errors.phone}
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="button button--primary-blue button--lg button--block"
          onClick={(e) => {
            e.preventDefault();
            submitForm();
          }}
          disabled={!(isValid && dirty) || isSubmitting}
        >
          Save changes
        </button>
      </form>
    </div>
  );
};

export default CompanyGeneralInfoEdit;
