import format from 'date-fns/format';
import numeral from 'numeral';
import React, { useEffect } from 'react';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { RefundHistoryEntryDto } from '../../../shared/models';
import { formatToCDT } from '../../../shared/utils';
import { RefundInfo } from '../pages/BillPayments';
import { getSubmittedByName } from '../store/actions';

interface RefundEventInfoProps {
  data: RefundInfo;
}

const RefundEventInfo = ({ data }: RefundEventInfoProps) => {
  const { selectedBillPayment, selectedRefundIndex, selectedSubmittedByName } = useAppSelector(
    (state) => state.payments,
  );
  const dispatch = useAppDispatch();
  const item = selectedBillPayment?.refundHistory[selectedRefundIndex || 0];

  const getTitle = (item: RefundHistoryEntryDto) => {
    if (item.outcome && item.outcome.failure) {
      return `$${numeral(item.amount.value).format('0,000.00')} Refund Failed`;
    }

    if (item.outcome) {
      return `$${numeral(item.amount.value).format('0,000.00')} Refund Submitted`;
    }

    return `$${numeral(item.amount.value).format('0,000.00')} Refund Pending`;
  };

  const type = selectedBillPayment?.amount.value === item?.amount.value ? 'Full' : 'Partial';

  useEffect(() => {
    if (item) {
      if (item.initiatedBy) {
        dispatch(getSubmittedByName(item.initiatedBy.uuid));
      }

      if (!item.initiatedBy) {
        const refundEvent = selectedBillPayment?.refundHistory.find(
          (el) => el.refundId === item.refundId && el.hasOwnProperty('initiatedBy'),
        );

        if (refundEvent && refundEvent.initiatedBy) {
          dispatch(getSubmittedByName(refundEvent.initiatedBy.uuid));
        }
      }
    }
  }, [item]);

  return (
    <div className="col customers__col">
      <div className="card">
        {item && (
          <>
            <h2 className="card__title">{getTitle(item)}</h2>
            <table className="user-profile__table">
              <tbody>
                <tr>
                  <td>Timestamp:</td>
                  <td>{formatToCDT(item.timestamp)}</td>
                </tr>

                <tr>
                  <td colSpan={2} className="user-profile__table-title">
                    <h3 className="user-profile__sub-title">Refund Properties:</h3>
                  </td>
                </tr>

                <tr>
                  <td>Customer Name:</td>
                  <td>{data.customerName}</td>
                </tr>
                <tr>
                  <td>Account number:</td>
                  <td>{data.billingAccountNumber}</td>
                </tr>
                <tr>
                  <td>Refund type</td>
                  <td>{type} refund</td>
                </tr>
                <tr>
                  <td>Transaction ID:</td>
                  <td>{data.transactionId}</td>
                </tr>
                <tr>
                  <td>Refund amount:</td>
                  <td>${numeral(item.amount.value).format('0,000.00')}</td>
                </tr>
                <tr>
                  <td>Submitted by:</td>
                  <td>{selectedSubmittedByName}</td>
                </tr>
                <tr>
                  <td>Reason for refund:</td>
                  <td>{item.reason}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default RefundEventInfo;
