import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import useSelectButton from '../../../shared/components/UI/SelectButton/hooks/useSelectButton';
import { FlowTypes, SelectedFlow, resetSelectedCampaign } from '../../auth/store';
import { setClientId } from '../../auth/store/actions/setClientId';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import RequestContainer from '../components/RequestContainer';
import SelectedCampaign from '../components/SelectedCampaign';
import useIntegrations from '../hooks/useIntegrations';

const Integrations = () => {
  const flowType = useAppSelector((store) => store.auth.partner?.details.flowType);
  const selectedCampaign = useAppSelector((store) => store.auth.campaign);

  const dispatch = useAppDispatch();

  const selectedType: SelectedFlow =
    flowType === FlowTypes.AFFILIATE_PARTNER ? 'affiliate' : 'billpay';

  const [selectedFlow, setSelectedFlow] = useState<SelectedFlow | null>(selectedType);
  const [responseClientId, setResponseClientId] = useState('');

  const {
    properties: { presets, hasQuickLink },
  } = useIntegrations();
  const history = useHistory();

  if (flowType === 'UNKNOWN') {
    history.push('/init-setup');
    return null;
  }

  const {
    properties: { selected },
  } = useSelectButton({ selectedFlow });

  useEffect(() => {
    setSelectedFlow(selectedType);
  }, [flowType]);

  const handleResetSelectedCampaign = () => {
    dispatch(resetSelectedCampaign());
  };

  const newClientId = () => {
    // @ts-ignore
    dispatch(setClientId())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((clientIdResponse: any) => {
        setResponseClientId(clientIdResponse.payload.clientId);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        console.error('Error fetching clientId', error);
      });
  };

  useEffect(() => {
    newClientId();
  }, []);

  return (
    <DashboardLayout pageTitle="Integrations">
      <div className="integration-block">
        <div className="breadcrumb">
          <button type="button" className="breadcrumb__item" onClick={handleResetSelectedCampaign}>
            <span className="breadcrumb__text">Loan toolkit</span>
            <img src="images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
          </button>
          <button type="button" className="breadcrumb__item" onClick={handleResetSelectedCampaign}>
            <img
              src={`images/dashboard/arrow-tail-${selectedCampaign ? 'blue' : 'gray'}.svg`}
              alt="arrow-tail"
            />
            <span className="breadcrumb__text">Campaigns</span>
            <img
              src={`images/dashboard/arrow-head-${selectedCampaign ? 'blue' : 'gray'}.svg`}
              alt="arrow-head"
            />
          </button>
          {selectedCampaign && (
            <div className="breadcrumb__item">
              <img src="images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
              <span className="breadcrumb__text">{selectedCampaign?.displayName}</span>
              <img src="images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
            </div>
          )}
        </div>
        <h2 className="main-title">
          {selectedCampaign?.displayName ? selectedCampaign?.displayName : 'Campaigns'}
        </h2>

        {selectedCampaign ? (
          <SelectedCampaign selectedFlow={selectedFlow} responseClientId={responseClientId} />
        ) : (
          <>
            <div className="integrations">
              <div className="integrations__content">
                <div className="integrations__requestContainer">
                  <RequestContainer
                    presets={presets}
                    selectedFlow={selectedFlow}
                    selected={selected}
                    hasQuickLink={hasQuickLink}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Integrations;
