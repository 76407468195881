import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { addAlert } from '../../../shared/store/modals';
import DeleteModal from '../components/DeleteModal';
import WebhookForm, { WebhookFormData } from '../components/WebhookForm';
import { normalizeWebhook } from '../helpers/getNormalizedData';
import DevToolsLayout from '../layout/DevToolsLayout';
import { deletePartnerWebhook } from '../store/actions/deleteWebhook';
import { getPartnerWebhook } from '../store/actions/getPartnerWebhook';
import { testPartnerWebhook } from '../store/actions/testWebhook';
import { updatePartnerWebhook } from '../store/actions/updatePartnerWebhook';

export default function EditWebhook() {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isResendButton, setIsResendButton] = useState(false);
  const [testMode, setTestMode] = useState(true);
  const { webhooks, testWebhookLoading, testWebhookError } = useAppSelector(
    (state: RootState) => state.devTools,
  );
  const webhook = webhooks[0];
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const toggleDeleteModal = useCallback(() => {
    setIsDeleteModal(!isDeleteModal);
  }, [isDeleteModal]);

  useEffect(() => {
    dispatch(getPartnerWebhook());
  }, []);

  const handleEditMode = useCallback(() => {
    setTestMode(false);
    setIsResendButton(false);
  }, []);

  const handleTestMode = useCallback(() => {
    setTestMode(true);
  }, []);

  const handleDeleteModal = useCallback(() => {
    dispatch(deletePartnerWebhook('partner-webhooks'));
    dispatch(addAlert({ text: 'Webhook subscription successfully deleted' }));
    toggleDeleteModal();
    setTimeout(() => push('/developer-tools/webhooks/configuration'), 1000);
  }, []);

  const handleChange = useCallback(
    (data) => {
      if (testMode) {
        dispatch(testPartnerWebhook());
        setIsResendButton(true);
      } else {
        dispatch(updatePartnerWebhook(data)).then(() => handleTestMode());
      }
    },
    [testMode],
  );

  if (!webhook || !webhook.key) {
    return null;
  }

  const formData = {
    title: testMode ? 'Webhook subscription (1 out of 1)' : 'Configure webhook subscription',
    subTitle: testMode
      ? 'Get notified when an event happens in your account'
      : 'Use webhook subscription to get real-time updates',
    submitButtonName:
      // eslint-disable-next-line
      testMode && !testWebhookLoading && !isResendButton
        ? 'Send webhook'
        : testWebhookLoading // eslint-disable-line
        ? 'Sending...'
        : isResendButton && testMode
        ? 'Resend webhook'
        : 'Save changes',
    cancelButtonName: testMode ? '' : 'Cancel',
  };
  const { title, subTitle, submitButtonName, cancelButtonName } = formData;

  const initialFromValues = normalizeWebhook(webhook);

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-blue.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Webhooks</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button
          type="button"
          className="breadcrumb__item"
          onClick={() => push('/developer-tools/webhooks/configuration')}
        >
          <img src="/images/dashboard/arrow-tail-blue.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Subscriptions</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Test Webhook</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">Test Webhook</h2>
      <WebhookForm
        onChange={handleChange}
        setTestMode={handleTestMode}
        onDelete={toggleDeleteModal}
        title={title}
        subTitle={subTitle}
        submitButtonName={submitButtonName}
        cancelButtonName={cancelButtonName}
        webhook={initialFromValues}
        webhookKey={webhook.key}
        hasDisable={testMode}
        setEditMode={handleEditMode}
        testMode={testMode}
      />
      <DeleteModal
        onCancel={toggleDeleteModal}
        onDelete={handleDeleteModal}
        isDeleteModal={isDeleteModal}
      />
    </DevToolsLayout>
  );
}
