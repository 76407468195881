import { addDays, endOfDay } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Spinner from '../../../shared/components/DataTable/Spinner';
import AsyncStoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { LoanDto } from '../../../shared/models/platform-admin';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import {
  LoansTable,
  SelectedLoan,
  SelectedLoanDirectControl,
  TablePagination,
} from '../components';
import LoanSummary from '../components/loans/LoadSummary';
import { LoansTableControls } from '../components/loans/LoansTableControls';
import UserDetails from '../components/users/UserDetails';
import { useLoansTableSearchEffect } from '../hooks';
import { selectTableItem, setTableDateFilter, setTableSearchQuery } from '../store';
import { adminLoansSearch } from '../store/actions';
import {
  loansCollectionSelectors,
  selectLoansPagination,
  selectLoansState,
} from '../store/selectors/loans';

export const PlatformAdminLoansPage: FC = () => {
  const [refresh, setRefresh] = useState(false);

  const dispatch = useAppDispatch();
  const loans = useAppSelector(loansCollectionSelectors.selectAll);
  const loansPagination = useAppSelector(selectLoansPagination);

  const { total, ...restLoansPagination } = loansPagination;
  const { selectedItemId } = useAppSelector(selectLoansState);

  useLoansTableSearchEffect();

  const onLoanClick = useCallback(
    ({ uuid }: LoanDto) => dispatch(selectTableItem({ id: uuid, tableName: 'loans' })),
    [dispatch],
  );

  const handleRefresh = async () => {
    setRefresh(true);
    // @ts-ignore
    const response = await dispatch(adminLoansSearch());

    if (adminLoansSearch.fulfilled.match(response)) {
      setRefresh(false);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem(AsyncStoreKeys.userForLoan);
    localStorage.removeItem(AsyncStoreKeys.userForLoan);
    if (userId) {
      dispatch(setTableSearchQuery({ query: userId, tableName: 'loans' }));
      dispatch(
        setTableDateFilter({
          range: [addDays(new Date(), -365).toISOString(), endOfDay(new Date()).toISOString()],
          tableName: 'loans',
        }),
      );
    }
  }, [dispatch]);

  return (
    <DashboardLayout pageTitle="Admin Loans">
      <LoansTableControls />

      <TablePagination
        tableName="loans"
        {...loansPagination}
        controls={[
          <button className="button button--outline-gray" type="button" onClick={handleRefresh}>
            <Icon name="refresh" classes="button__icon" />
            Refresh
          </button>,
        ]}
      />

      {refresh && <Spinner />}
      {!refresh && (
        <LoansTable loans={loans} selectedLoanId={selectedItemId} onLoanClick={onLoanClick} />
      )}
      <TablePagination tableName="loans" {...restLoansPagination} />
      {selectedItemId && (
        <Tabs className="tabs tabs--payments">
          <TabList className="tabs__list">
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              Details
            </Tab>
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              Loan Request History
            </Tab>
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              Repayment History
            </Tab>
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              Raw Data
            </Tab>
          </TabList>
          <TabPanel className="tabs__panel">
            <div className="row user-profile__row">
              <div className="col user-profile__col">
                <LoanSummary />
              </div>
              <div className="col user-profile__col">
                <UserDetails />
              </div>
            </div>
            <div className="row user-profile__row">
              <div className="col user-profile__col">
                <SelectedLoanDirectControl />
              </div>
            </div>
          </TabPanel>
          <TabPanel className="tabs__panel">
            <div className="card">Loan Request History Not Found</div>
          </TabPanel>
          <TabPanel className="tabs__panel">
            <div className="card">Repayment History Not Found</div>
          </TabPanel>
          <TabPanel className="tabs__panel">
            <SelectedLoan />
          </TabPanel>
        </Tabs>
      )}
    </DashboardLayout>
  );
};
