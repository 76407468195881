export enum Colors {
  GREEN = 'mint20',
  MINT = 'mint50',
  YELLOW = 'yellow55',
  RED = 'red70',
  GREY = 'neutral70',
  BROWN = 'chery24',
}

export interface ColorItem {
  id: string;
  valueColor: string;
  label: string;
}

export const colorList: ColorItem[] = [
  { id: 'green', valueColor: Colors.GREEN, label: 'Green' },
  { id: 'mint', valueColor: Colors.MINT, label: 'Mint' },
  { id: 'yellow', valueColor: Colors.YELLOW, label: 'Yellow' },
  { id: 'red', valueColor: Colors.RED, label: 'Red' },
  { id: 'grey', valueColor: Colors.GREY, label: 'Grey' },
  { id: 'brown', valueColor: Colors.BROWN, label: 'Brown' },
];
