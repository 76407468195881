import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { setModalRejectedOpen, setModalSuccessOpen } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';
import { setLoadingSftpFiles } from '../index';

import type { AppDispatch, RootState } from '../../../../main/store';

export const uploadSftpFiles = createAsyncThunk<
  File,
  File,
  { dispatch: AppDispatch; state: RootState }
>('partners/uploadSftpFiles', async (payload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoadingSftpFiles(true));
    const formData = new FormData();

    formData.append('file', payload);

    await api.put(`/partners/account-validation/upload`, formData);
    dispatch(setLoadingSftpFiles(false));
    dispatch(
      setModalSuccessOpen({
        opened: true,
        title: 'Your file upload was submitted!',
        text: 'You can review and download your uploaded file at any time by returning to File Upload.',
      }),
    );

    return payload;
  } catch (e) {
    setTimeout(() => {
      dispatch(setLoadingSftpFiles(false));
    }, 500);

    setTimeout(() => {
      dispatch(
        setModalRejectedOpen({
          opened: true,
          title: 'File failed to submit',
        }),
      );
    }, 1000);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
