import Typography from '../UI/Typography';

import plaidIcon from './assets/plaid_icon.svg';

const PlaidDescription = () => (
  <div className="bank__plaid__info">
    <div>
      <img src={plaidIcon} alt="Plaid" />
    </div>
    <div className="bank__plaid__info__description">
      <Typography tag="p" colors="text-secondary">
        We partner with{' '}
        <a href="https://plaid.com/" target="bland" rel="noreferrer">
          Plaid
        </a>
        , used by Venmo, Robinhood, Coinbase, and Acorns, to connect your bank.
      </Typography>
      <p className="bank__plaid__info__description__links">
        <a href="https://plaid.com/legal/#consumers" target="bland" rel="noreferrer">
          Privacy Policy
        </a>
        <a href="https://plaid.com/en-eu/safety/" target="bland" rel="noreferrer">
          Security
        </a>
      </p>
    </div>
  </div>
);

export default PlaidDescription;
