import { FormikErrors, FormikTouched } from 'formik';
import React, { useMemo } from 'react';

import { Input, Label } from '../../../../../shared';
import { validatePasswordStrength } from '../../../../../shared/utils/validation';
import { Typography } from '../../../../init-setup/components';
import { SignUpWrapper } from '../../index';
import { FormData } from '../../SignUpForm/SignUpForm';

type DataType = {
  firstName: string;
  lastName: string;
  email: string;
  password: number;
};

type FormicDataTypes = {
  values: Pick<FormData, 'email' | 'password' | 'firstName' | 'lastName'>;
  errors: FormikErrors<DataType>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<DataType>;
};

export default function CreateAcc({
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
}: FormicDataTypes) {
  const [_, passwordStrengthVector] = useMemo(
    () => validatePasswordStrength(values.password),
    [values.password],
  );

  return (
    <div className="new-sign-up__createAcc createAcc">
      <SignUpWrapper title="Welcome to Zirtue’s Partner Portal">
        <Typography
          tag="h4"
          size="h4"
          weight="bold-1"
          colors="text-info"
          textAlign="text-center"
          className="createAcc__title"
        >
          Create your account
        </Typography>
        <div className="createAcc__inputs">
          <div className="createAcc__inputRow">
            <div className="createAcc__inputCol">
              <Label forId="firstName">First name</Label>
              <Input
                type="text"
                id="firstName"
                placeholder="Enter your First name"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                value={values.firstName}
                error={Boolean(errors.firstName) && touched.firstName && errors.firstName}
              />
            </div>
            <div className="createAcc__inputCol">
              <Label forId="lastName">Last name</Label>
              <Input
                type="text"
                id="lastName"
                placeholder="Enter your Last name"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                value={values.lastName}
                error={Boolean(errors.lastName) && touched.lastName && errors.lastName}
              />
            </div>
            {/* Hidden input to prevent Google password manager trigger on lastName input */}
            <input type="password" autoComplete="new-password" style={{ display: 'none' }} />
          </div>
          <div>
            <Label forId="email">your work email</Label>
            <Input
              type="email"
              id="email"
              placeholder="Enter your work email"
              value={values.email}
              disabled
            />
          </div>

          <div>
            <Label forId="password">Password</Label>
            <Input
              type="password"
              id="password"
              placeholder="Create a password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              createMode
              passStrengthVector={passwordStrengthVector}
              error={Boolean(errors.password) && touched.password && errors.password}
            />
          </div>
        </div>
      </SignUpWrapper>
    </div>
  );
}
