import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { DeleteAccount, PersonalDetails, ProfileDetails } from '../components';
import useAccount from '../hooks/useAccount';

const Account = () => {
  const { operations, properties } = useAccount();

  return (
    <DashboardLayout pageTitle="Account">
      <div className="account">
        {properties.partner && properties.role && (
          <>
            <div className="account__container">
              <div className="account__container__profile-wrapper">
                <ProfileDetails {...properties} />
              </div>
              <div className="account__container__profile-wrapper">
                <PersonalDetails />
              </div>
            </div>
            <DeleteAccount />
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Account;
