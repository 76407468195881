import React, { useCallback, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import Popup from 'reactjs-popup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { FundingSource } from '../../../shared/models/FundingSource';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { AlertType, setModalConfirmOpen, addAlert } from '../../../shared/store/modals';
import { getCheckbookError } from '../../../shared/utils';
import { hasRole } from '../../auth/utils';
import { deleteAchNode } from '../../dashboard/store/actions/deleteAchNode';
import { getPaymentMethods } from '../store/actions';

type Props = {
  children: JSX.Element | ((isOpen: boolean) => JSX.Element);
  paymentMethod: FundingSource;
};

export default function QuickPaymentActions({ children, paymentMethod }: Props) {
  const dispatch = useAppDispatch();
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));
  const popupRef = useRef(null);

  const modalConfirmTitle = `Remove account ending in ${paymentMethod.redactedAccountNumber}?`;
  const modalConfirmText =
    'By removing this account you’ll permanently delete it. You can always link this account again or add a new bank account later.';
  const modalConfirmOkText = 'Yes, remove it';
  const modalConfirmCancelText = 'No, don’t remove it';
  const confirmDelPayment = useCallback(async () => {
    const result = await dispatch(
      deleteAchNode({
        id: paymentMethod.uuid,
      }),
    );
    dispatch(setModalConfirmOpen({ opened: false }));

    if (deleteAchNode.fulfilled.match(result)) {
      dispatch(addAlert({ text: 'Bank account succesfully removed', type: AlertType.info }));

      setTimeout(() => dispatch(getPaymentMethods()), 1000);

      if (!isAdminPanel) {
        Amplitude.logEvent(AmplitudeEvents.accountRemoved);
      }
    }

    if (deleteAchNode.rejected.match(result)) {
      dispatch(
        addAlert({
          text: getCheckbookError(result.error.message) || 'Error while deleting account.',
          type: AlertType.error,
        }),
      );
    }
  }, [dispatch, isAdminPanel, paymentMethod.uuid]);

  const handleDeleteAccount = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      if (paymentMethod.billingDetails) {
        dispatch(
          setModalConfirmOpen({
            opened: true,
            title: 'Cannot remove an account marked as inbound',
            text: 'This account is linked to inbound payments. You must set another account as the inbound account before this entry can be removed.',
            okText: 'Okay, got it!',
            showCancel: false,
          }),
        );
      } else {
        dispatch(
          setModalConfirmOpen({
            opened: true,
            title: modalConfirmTitle,
            text: modalConfirmText,
            okText: modalConfirmOkText,
            cancelText: modalConfirmCancelText,
            onConfirm: () => confirmDelPayment(),
          }),
        );
      }
    },
    [dispatch, confirmDelPayment, modalConfirmTitle, paymentMethod.billingDetails],
  );

  const handleClose = () => {
    if (popupRef) {
      // @ts-ignore
      popupRef.current.close();
    }
  };

  return (
    <div className="quick-actions">
      <Popup
        trigger={children}
        position="top right"
        on="click"
        closeOnDocumentClick
        arrow={false}
        ref={popupRef}
      >
        <ul className="quick-actions__menu" onClick={handleClose} role="presentation">
          <li className="quick-actions__menu-item">
            <a
              href="/"
              className="quick-actions__menu-link quick-actions__menu-link--red"
              onClick={handleDeleteAccount}
            >
              <Icon name="trash" classes="quick-actions__menu-icon" />
              Remove
            </a>
          </li>
        </ul>
      </Popup>
    </div>
  );
}
