import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import {
  UsersSearchQueryDto,
  UsersSearchResponseDto,
} from '../../../../shared/models/platform-admin';

export const adminUsersSearch = createAsyncThunk<UsersSearchResponseDto, UsersSearchQueryDto>(
  'platformAdmin/adminUsersSearch',
  async (request) => {
    const { roles = [] } = request;
    const { data } = await api.get<UsersSearchResponseDto>('/platform-admin/users/search', {
      params: {
        ...request,
        roles: roles.length ? roles : undefined,
      },
    });
    return data;
  },
);
