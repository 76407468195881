import { LoanStageDto, LoanUserDataDto } from '../../../shared/models/platform-admin';

export const makeUserName = (userDataDto?: LoanUserDataDto) =>
  userDataDto ? `${userDataDto.firstName} ${userDataDto.lastName}` : '-';

export const makeUserPhone = (userDataDto?: LoanUserDataDto) =>
  userDataDto ? userDataDto.defaultContact?.split(':').pop() : '-';

export const makeStatus = (stage: LoanStageDto) =>
  stage.kind === 'closed' ? `${stage.kind} / ${stage.closureType}` : stage.kind;
