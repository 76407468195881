import cn from 'classnames';
import React from 'react';

import { Icon } from '../../../shared';
import { BillPaymentsSettings } from '../../../shared/models';

type TableHeadProps = {
  sortBy: string;
  sortOrder: 'desc' | 'asc';
  setSortOrder: (value: 'desc' | 'asc') => void;
  setSortBy: (value: string) => void;
  canRefund?: boolean;
  columns: BillPaymentsSettings[];
  headerRowClassname?: string;
};

const BillPaymentsTableHead: React.FC<TableHeadProps> = ({
  setSortOrder,
  setSortBy,
  sortBy,
  sortOrder,
  canRefund,
  columns,
  headerRowClassname,
}: TableHeadProps) => {
  // if (canRefund) {
  //   columns.push({ id: 'reimbursement', name: 'Reimbursement' });
  // }

  const changeSorting = (sortName: string) => {
    let dir: 'desc' | 'asc' = 'asc';
    if (sortBy === sortName) {
      dir = sortOrder === 'asc' ? 'desc' : dir;
      return setSortOrder(dir);
    }
    return setSortBy(sortName);
  };

  return (
    <tr className={cn('table__header-row', headerRowClassname)}>
      {columns.map(({ id, fixed, sortName, name, width }) => (
        <td
          className={`table__cell table__cell--header${fixed ? ' table__cell--fixed' : ''}`}
          style={{ width }}
          key={id}
        >
          {sortName ? (
            <button
              type="button"
              className="table__sort-btn"
              onClick={() => changeSorting(sortName || 'payer.name')}
            >
              {name}
              <Icon
                name={
                  sortBy !== sortName
                    ? 'double-arrow'
                    : `sorting-arrow-${sortOrder === 'asc' ? 'up' : 'down'}`
                }
                classes="table__sort-icon"
              />
            </button>
          ) : (
            name
          )}
        </td>
      ))}
    </tr>
  );
};

export default BillPaymentsTableHead;
