import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { ChecklistData, FlowTypes, setChecklistFinished } from '../../../../features/auth/store';
import { getPaymentMethods } from '../../../../features/finances/store/actions';
import { api } from '../../../../main/network';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { FundingSource } from '../../../models/FundingSource';
import { AlertType, addAlert } from '../../../store/modals';

type FlowType = 'bill-pay' | 'affiliate';

export type ChecklistDto = {
  checkListState: ChecklistData;
};

export type CheckoutListItem = {
  title: string;
  isDone: boolean;
  linkTo: string;
  id: string;
  flowType: string[];
  property: string;
};

const useCheckoutList = () => {
  const { partner } = useAppSelector((state) => state.auth, shallowEqual);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const currentFlow: FlowType =
    partner?.details.flowType === FlowTypes.AFFILIATE_PARTNER ? 'affiliate' : 'bill-pay';

  const initListItems: CheckoutListItem[] = [
    {
      title: 'invite team members',
      isDone: false,
      property: 'step1',
      linkTo: '/team-members',
      id: 'list-item-1',
      flowType: ['affiliate', 'bill-pay'],
    },
    {
      title: 'complete business profile',
      isDone: false,
      property: 'step2',
      linkTo: '/company-profile',
      id: 'list-item-2',
      flowType: ['affiliate', 'bill-pay'],
    },
    {
      title: 'review zirtue in the news',
      isDone: false,
      property: 'step3',
      linkTo: '/payments',
      id: 'list-item-3',
      flowType: ['affiliate'],
    },
    {
      title: 'add zirtue pay',
      isDone: false,
      property: 'step3',
      linkTo: '/integrations',
      id: 'list-item-4',
      flowType: ['bill-pay'],
    },
    {
      title: 'verify micro deposit',
      isDone: false,
      property: 'step4',
      linkTo: '',
      id: 'list-item-5',
      flowType: ['bill-pay'],
    },
  ].filter((el) => el.flowType.includes(currentFlow));

  const [listItems, setListItems] = useState<CheckoutListItem[]>(initListItems);

  const allItemsChecked = (items: CheckoutListItem[]): boolean =>
    items.every((item) => item.isDone);

  const updateItems = (response: ChecklistData, isPlaid?: boolean) => {
    const updatedItems = listItems
      .filter(
        (el) =>
          !(isPlaid && el.flowType.includes('bill-pay') && el.title === 'verify micro deposit'),
      )
      .map((el) => {
        const currentState = el.property ? response[el.property] === '1' : false;
        return { ...el, isDone: currentState };
      });

    if (allItemsChecked(updatedItems)) {
      dispatch(setChecklistFinished(true));
    }

    setListItems(updatedItems);
    setLoading(false);
  };

  const getChecklist = async (accounts: string | FundingSource[]) => {
    const isPlaid = (accounts as FundingSource[]).some(
      (method) => method.verificationFlow === 'plaid',
    );
    try {
      const response = await api.get<ChecklistDto>('/check-list');
      const states = response.data.checkListState;
      if (states) {
        updateItems(states, isPlaid);
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: "Couldn't load checklist",
          type: AlertType.error,
        }),
      );
    }
  };

  const checkItem = async (property: string) => {
    try {
      const response = await api.post<ChecklistDto>('/check-list', { [property]: '1' });
      const states = response.data.checkListState;
      updateItems(states);
      return response.data;
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Error while marking item',
          type: AlertType.error,
        }),
      );
      return undefined;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getPaymentMethods());
        await getChecklist(response.payload!);
      } catch (error) {
        dispatch(
          addAlert({
            text: "Couldn't load checklist",
            type: AlertType.error,
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    operations: { checkItem },
    properties: { listItems, loading },
  };
};

export default useCheckoutList;
