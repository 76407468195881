import React from 'react';

import { useAppSelector } from '../../../../main/store/hooks';
import { selectSelectedLoan } from '../../store/selectors/loans';
import { makeUserName, makeUserPhone } from '../../utils';

const UserDetails = () => {
  const loan = useAppSelector(selectSelectedLoan);
  const { lender, borrower, lenderId, borrowerId } = loan || {};

  return loan ? (
    <div className="card">
      <h2 className="card__title">User Details</h2>
      <table className="user-profile__table">
        <tbody>
          <tr>
            <td colSpan={2}>
              <h3 className="user-profile__sub-title">Borrower Information</h3>
            </td>
          </tr>
          <tr>
            <td>Borrower Full Name</td>
            <td>{makeUserName(borrower)}</td>
          </tr>
          <tr>
            <td>Borrower UUID</td>
            <td>{borrowerId}</td>
          </tr>
          <tr>
            <td>Borrower Phone Number</td>
            <td>{makeUserPhone(borrower)}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h3 className="user-profile__sub-title">Lender Information</h3>
            </td>
          </tr>
          <tr>
            <td>Lender Full Name</td>
            <td>{makeUserName(lender)}</td>
          </tr>
          <tr>
            <td>Lender UUID</td>
            <td>{lenderId}</td>
          </tr>
          <tr>
            <td>Lender Phone Number</td>
            <td>{makeUserPhone(lender)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div>Selected loan was not found.</div>
  );
};

export default UserDetails;
