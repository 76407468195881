import { ReactComponent as FlaskImage } from '../../../assets/images/flask.svg';
import { Icon } from '../../../shared';

interface NotFoundProps {
  message: string;
  buttonTxt: string;
  onClick: () => void;
}
export default function NotFound({ message, buttonTxt, onClick }: NotFoundProps) {
  return (
    <div className="dev-tools__not-found card">
      <section className="dev-tools__not-found-content">
        <div className="dev-tools__not-found-image-box">
          <FlaskImage className="dev-tools__not-found-image" />
        </div>
        <div className="dev-tools__not-found-txt">{message}</div>
        <div className="dev-tools__not-found-button">
          <button
            className="button button--primary-blue button--lg button--block button--not-found"
            type="button"
            onClick={onClick}
          >
            {buttonTxt}
          </button>
        </div>
      </section>
      <footer className="dev-tools__not-found__footer">
        <span className="dev-tools__not-found__footer-text">Want to learn more?</span>
        <a
          href="https://docs.zirtue.com/docs/webhooks2"
          target="_blank"
          className="dev-tools__not-found__footer-link"
          rel="noreferrer"
        >
          <span className="dev-tools__not-found__footer-link-text">Read documentation</span>
          <Icon name="open-new-page" />
        </a>
      </footer>
    </div>
  );
}
