// TODO: improve this
const getVariable = (key: string): string => process.env[`REACT_APP_${key}`] as string;

export const CURRENT_ENV = (): string => getVariable('CURRENT_ENV');
export const CURRENT_DOMAIN = (): string => getVariable('CURRENT_DOMAIN');
export const INTERCOM_URL = (): string => getVariable('INTERCOM_URL');
export const INTERCOM_APP_ID = (): string => getVariable('INTERCOM_APP_ID');
export const SENTRY_DSN = (): string => getVariable('SENTRY_DSN');
export const IS_LOGGER_ENABLED = (): boolean => getVariable('IS_LOGGER_ENABLED') === 'true';
export const IS_DEBUG_ENABLED = (): boolean => getVariable('IS_DEBUG_ENABLED') === 'true';
export const GOOGLE_PLACES = (): string => getVariable('GOOGLE_PLACES');

// UNLEASH
export const UNLEASH_URL = (): string => getVariable('UNLEASH_URL');
export const UNLEASH_API_TOKEN = (): string => getVariable('UNLEASH_API_TOKEN');
