import React from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useOnClickOutside, Icon, UserNavProfile } from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import Permissions from '../../../shared/constants/Permissions';
import { resetAuthToken, resetIntercom, resetUser, setIsAuthenticated } from '../../auth/store';
import { hasRole } from '../../auth/utils';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function UserNav({ avatarUrl, name, type, open, setOpen }: any) {
  const dropdownRef = React.createRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const {
    isAdmin,
    authToken: authInfo,
    finishedSetup,
  } = useAppSelector((state) => state.auth, shallowEqual);

  const canSeeSettings =
    authInfo &&
    finishedSetup &&
    (hasRole(authInfo, Permissions.MEMBERS_MANAGE) ||
      hasRole(authInfo, Permissions.MEMBERS_INVITE) ||
      hasRole(authInfo, Permissions.FINANCES_MANAGE) ||
      hasRole(authInfo, Permissions.ACCESS_DEVELOPER_TOOLS) ||
      isAdmin);

  const handleClickSettings = () => {
    if (
      authInfo &&
      hasRole(authInfo, Permissions.ACCESS_DEVELOPER_TOOLS) &&
      !hasRole(authInfo, Permissions.MEMBERS_MANAGE) &&
      !hasRole(authInfo, Permissions.MEMBERS_INVITE)
    ) {
      return {
        pathname: '/developer-tools/webhooks/configuration',
      };
    }
    return { pathname: '/company-profile' };
  };

  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  if (!open) {
    return null;
  }

  return (
    <div className="user-nav__dropdown" ref={dropdownRef}>
      <div>
        <p className="user-nav__act-as">Settings</p>
        <div className="user-nav__profile-wrapper">
          <UserNavProfile name={name} text={type} icons={avatarUrl} />
        </div>
      </div>
      <ul className="user-nav__dropdown-list">
        {canSeeSettings && (
          <li className="user-nav__dropdown-item">
            <Link to={handleClickSettings} className="user-nav__dropdown-link">
              <Icon name="home" classes="user-nav__dropdown-link-icon" />
              Company
            </Link>
          </li>
        )}
        <li className="user-nav__dropdown-item">
          <Link to="/account" className="user-nav__dropdown-link">
            <Icon name="user" classes="user-nav__dropdown-link-icon" />
            Account
          </Link>
        </li>
        <li className="user-nav__dropdown-item">
          <Link
            to="/"
            className="user-nav__dropdown-link user-nav__dropdown-link--red"
            onClick={() => {
              localStorage.setItem(StoreKeys.isAuthenticated, 'false');
              dispatch(setIsAuthenticated(false));
              dispatch(resetAuthToken());
              dispatch(resetIntercom());
              dispatch(resetUser());

              localStorage.removeItem(StoreKeys.authToken);
            }}
          >
            <Icon name="power" classes="user-nav__dropdown-link-icon" />
            Sign out
          </Link>
        </li>
      </ul>
    </div>
  );
}
