import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AlertType {
  'error' = 'error',
  'success' = 'success',
  'info' = 'info',
  'dismiss' = 'dismiss',
}
export interface IAlertItem {
  id: string;
  text: string;
  type: AlertType;
}

interface ModalsSliceState {
  modalSuccessOpen: boolean;
  modalSuccessTitle?: string;
  modalSuccessText?: string;
  modalRejectedOpen: boolean;
  modalRejectedTitle?: string;
  modalRejectedText?: string;
  modalConfirmOpen: boolean;
  modalConfirmTitle?: string;
  modalConfirmText?: string | string[];
  modalConfirmOkText?: string;
  modalConfirmCancelText?: string;
  modalConfirmShowCancel?: boolean;
  modalConfirmBodyClasses?: string;
  modalConfirmOnConfirm?: () => void;
  modalConfirmImg?: string;
  alertOpen: boolean;
  alertText: string;
  alertType?: AlertType;
  alertItems: IAlertItem[];
}

const initialState: ModalsSliceState = {
  modalSuccessOpen: false,
  modalSuccessTitle: '',
  modalSuccessText: '',
  modalRejectedOpen: false,
  modalRejectedTitle: '',
  modalRejectedText: '',
  modalConfirmOpen: false,
  modalConfirmTitle: '',
  modalConfirmText: '',
  modalConfirmOkText: '',
  modalConfirmCancelText: '',
  modalConfirmOnConfirm: () => null,
  modalConfirmShowCancel: true,
  alertOpen: false,
  alertText: '',
  alertType: AlertType.success,
  alertItems: [],
};

export type ModalSuccessOpen = {
  opened: boolean;
  title?: string;
  text?: string;
};

export type ModalRejectedOpen = {
  opened: boolean;
  title?: string;
  text?: string;
};

export type ModalConfirm = {
  opened: boolean;
  title?: string;
  text?: string | string[];
  okText?: string;
  cancelText?: string;
  bodyClasses?: string;
  img?: string;
  showCancel?: boolean;
  onConfirm?: () => void;
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: initialState as ModalsSliceState,
  reducers: {
    setModalSuccessOpen: (state, action: PayloadAction<ModalSuccessOpen>) => {
      const { opened, title, text } = action.payload;

      state.modalSuccessOpen = opened;
      state.modalSuccessTitle = title;
      state.modalSuccessText = text;
    },
    setModalRejectedOpen: (state, action: PayloadAction<ModalRejectedOpen>) => {
      const { opened, title, text } = action.payload;

      state.modalRejectedOpen = opened;
      state.modalRejectedTitle = title;
      state.modalRejectedText = text;
    },
    setModalConfirmOpen: (state, action: PayloadAction<ModalConfirm>) => {
      const {
        opened,
        title,
        text,
        okText,
        cancelText,
        onConfirm,
        img,
        bodyClasses = '',
        showCancel = true,
      } = action.payload;
      state.modalConfirmOpen = opened;
      state.modalConfirmTitle = title;
      state.modalConfirmText = text;
      state.modalConfirmOkText = okText;
      state.modalConfirmCancelText = cancelText;
      state.modalConfirmShowCancel = showCancel;
      state.modalConfirmBodyClasses = bodyClasses;
      state.modalConfirmOnConfirm = onConfirm;
      state.modalConfirmImg = img;
    },

    addAlert: (state, action: PayloadAction<{ text: string; type?: AlertType }>) => {
      const item = {
        id: Math.random().toString(16),
        text: action.payload.text,
        type: action.payload.type || AlertType.success,
      };
      state.alertItems = [...state.alertItems, item];
    },
    updateAlert: (state) => {
      state.alertItems = state.alertItems.slice(1);
    },
    closeAlert: (state, action: PayloadAction<string>) => {
      state.alertItems = state.alertItems.filter((i) => i.id !== action.payload);
    },
  },
});

export default modalsSlice;
export const { reducer: modalReducer } = modalsSlice;
export const {
  setModalSuccessOpen,
  setModalRejectedOpen,
  setModalConfirmOpen,
  addAlert,
  updateAlert,
  closeAlert,
} = modalsSlice.actions;
