export const setPageSettings = (name: string, key: string, value: unknown) => {
  const settings = localStorage.getItem(`${name}_settings`);

  if (settings) {
    const page = key === 'page' ? value : 1;

    localStorage.setItem(
      `${name}_settings`,
      JSON.stringify({ ...JSON.parse(settings), [key]: value, page }),
    );
  }

  if (!settings) {
    localStorage.setItem(`${name}_settings`, JSON.stringify({ [key]: value }));
  }
};
