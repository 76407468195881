import React from 'react';

import { Icon, Tooltip } from '../../../shared';

type Props = {
  accountNumber: string;
  classes?: string;
  copyValue?: string;
};

export default function TableAccountNumber({ accountNumber, classes, copyValue }: Props) {
  function handleClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    ev.preventDefault();
    ev.stopPropagation();
    navigator.clipboard.writeText(copyValue || accountNumber);
  }

  return (
    <Tooltip text={accountNumber}>
      <span className={`table-account-number ${classes || ''}`}>
        <span className="table-account-number__text">{accountNumber}</span>
        <button
          type="button"
          className="table-account-number__copy-btn"
          onClick={(ev) => handleClick(ev)}
        >
          <Icon name="copy" classes="table-account-number__copy-btn-icon" />
        </button>
      </span>
    </Tooltip>
  );
}
