const codeToError: Record<string, string> = {
  user_exists: 'User already exists',
  invalid_amounts: 'Invalid microdeposit amounts',
  no_attempts_left: 'Maximum verification attempts exceeded',
  routing_only_numbers: 'Routing number must be numeric',
  routing_invalid: 'Invalid routing number',
  routing_only_digits: 'Routing number must be 9 characters',
  account_only_numbers: 'Account number must be numeric',
};

export const getCheckbookError = (message?: string) => {
  if (!message) return '';
  const error = codeToError[message];
  return error || message;
};
