import { useFormik } from 'formik';
import React, { useMemo, useCallback, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Label, Input } from '../../../shared';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { validatePasswordStrength } from '../../../shared/utils/validation';
import { AuthBannerContent } from '../components/AuthBanner';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { forgotPasswordComplete } from '../store/actions';

type ResetPasswordRouteParams = {
  token?: string;
};

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const inviteValidationSchema = yup.object({
  password: yup
    .string()
    .min(8)
    .test('is-strong', 'Password is not strong', (passVal) => {
      const [isValid, _] = validatePasswordStrength(passVal || '');
      return !!isValid;
    })
    .label('Password')
    .required(),
  passwordConfirmation: yup
    .string()
    .label('Password confirmation')
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required(),
});

const ResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { token: resetPasswordToken = '' } = useParams<ResetPasswordRouteParams>();
  const { loading } = useAppSelector((state) => state.auth);
  const [type, setType] = useState<'text' | 'password'>('text');
  const [confirmType, setConfirmType] = useState<'text' | 'password'>('text');

  const onSubmit = useCallback(
    async ({ password }: FormValues) => {
      const result = await dispatch(
        forgotPasswordComplete({ token: resetPasswordToken, password }),
      );

      if (forgotPasswordComplete.fulfilled.match(result)) {
        dispatch(addAlert({ text: 'Password changed' }));
        history.push('/');
      }
      if (forgotPasswordComplete.rejected.match(result)) {
        dispatch(
          addAlert({
            // @ts-ignore
            text: result.payload?.message || result.payload || 'Unknown error',
            type: AlertType.error,
          }),
        );
      }
    },
    [dispatch, history, resetPasswordToken],
  );

  const { errors, handleSubmit, handleChange, handleBlur, values, touched } = useFormik<FormValues>(
    {
      initialValues: {
        password: '',
        passwordConfirmation: '',
      },
      onSubmit,
      validationSchema: inviteValidationSchema,
    },
  );

  const buildErrorText = (field: 'password' | 'passwordConfirmation') =>
    Boolean(errors[field]) && touched[field] && errors[field];

  const [_, passwordStrengthVector] = useMemo(
    () => validatePasswordStrength(values.password),
    [values.password],
  );

  if (!resetPasswordToken) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
        }}
      />
    );
  }

  return (
    <AuthorizationLayout bannerContent={AuthBannerContent.ResetPassword}>
      <form action="#" className="auth-form auth-form--styled" onSubmit={handleSubmit}>
        <h1 className="auth-form__title">Reset password</h1>
        <p className="auth-form__text">
          You recently requested to reset your Zirtue account password. Sometimes changes is good.
        </p>

        <div className="form-group">
          <Label forId="password">New password</Label>
          <Input
            type={type}
            id="password"
            placeholder="Create a password"
            createMode
            passStrengthVector={passwordStrengthVector}
            error={buildErrorText('password')}
            value={values.password}
            onChange={handleChange}
            onFocus={() => setType('password')}
            onBlur={handleBlur}
          />
        </div>

        <div className="form-group">
          <Label forId="passwordConfirmation">Confirm password</Label>
          <Input
            type={confirmType}
            id="passwordConfirmation"
            placeholder="Confirm new password"
            value={values.passwordConfirmation}
            error={buildErrorText('passwordConfirmation')}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => setConfirmType('password')}
          />
        </div>

        <button
          type="submit"
          className="button button--primary-blue button--lg button--block"
          disabled={loading}
        >
          Reset password
        </button>
      </form>
    </AuthorizationLayout>
  );
};

export default ResetPassword;
