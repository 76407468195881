import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Tooltip } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { PartnerMember } from '../../../shared/models';
import { InvitedMember } from '../../../shared/models/PartnerInvitedMembersDto';
import { getSecurityGroupInfo } from '../../../shared/utils/helpers';
import { hasRole, isAdmin } from '../../auth/utils';
import { setModalInviteOpen } from '../store';
import { getCompanyMembers, getInvitedMembers } from '../store/actions';

import InvitedMemberRow from './InvitedMemberRow';
import MemberRow from './MemberRow';

type Props = {
  q?: string;
};

interface IState {
  offset: number;
  currentPage: number;
}

const initState: IState = {
  offset: 0,
  currentPage: 1,
};

const PAGE_SIZE = 25;

const CompanyMembers: React.FC<Props> = ({ q }) => {
  const dispatch = useAppDispatch();
  const { members, invitedMembers, roles } = useAppSelector((state) => state.company, shallowEqual);
  const { authToken: authInfo, user } = useAppSelector((state) => state.auth, shallowEqual);
  const canManage =
    authInfo && (hasRole(authInfo, Permissions.MEMBERS_MANAGE) || isAdmin(authInfo));
  const canInvite =
    authInfo && (hasRole(authInfo, Permissions.MEMBERS_INVITE) || isAdmin(authInfo));
  const [state, setState] = useState<IState>(initState);
  const { currentPage } = state;

  useEffect(() => {
    dispatch(getCompanyMembers());
    dispatch(getInvitedMembers());
  }, [dispatch]);

  const checkMember = (oneMember: PartnerMember | InvitedMember, q: string) => {
    const lowerCaseq = q.toLowerCase().trim();

    if ((oneMember as PartnerMember).uuid) {
      const regularMember = oneMember as PartnerMember;
      const groupUuid = regularMember.securityGroups && regularMember.securityGroups[0];
      const roleInfo = groupUuid && getSecurityGroupInfo(groupUuid, roles);
      const ownerRole =
        user?.uuid === regularMember.uuid ? { name: 'System Admin', color: 'mint50' } : null;

      return (
        regularMember.firstName?.toLowerCase().includes(lowerCaseq) ||
        regularMember.lastName?.toLowerCase().includes(lowerCaseq) ||
        regularMember.middleName?.toLowerCase().includes(lowerCaseq) ||
        regularMember.contacts.find((oneContact) =>
          oneContact.toLowerCase().includes(lowerCaseq),
        ) ||
        (roleInfo && roleInfo.name?.toLowerCase().includes(lowerCaseq)) ||
        (ownerRole && ownerRole.name.toLowerCase().includes(lowerCaseq))
      );
    }

    const invitedMember = oneMember as InvitedMember;
    const groupUuid = invitedMember.securityGroups && invitedMember.securityGroups[0];
    const roleInfo = groupUuid && getSecurityGroupInfo(groupUuid, roles);

    return (
      invitedMember.contactUri.toLowerCase().includes(lowerCaseq) ||
      (roleInfo && roleInfo.name?.toLowerCase().includes(lowerCaseq))
    );
  };

  const [filteredMembers, totalPages] = useMemo(() => {
    let combinedMembers = [...members, ...invitedMembers];

    if (q) {
      combinedMembers = combinedMembers.filter((oneMember) => checkMember(oneMember, q));
    }

    return [
      combinedMembers.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE),
      Math.ceil(combinedMembers.length / PAGE_SIZE),
    ];
  }, [members, invitedMembers, q, currentPage]);

  const changePage = (page: number) => {
    let nextPage: number = page;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(page)) nextPage = 1;
    if (page <= 1) nextPage = 1;
    if (page >= totalPages) nextPage = totalPages;

    setState({
      ...state,
      currentPage: nextPage,
    });
  };

  return (
    <div className="card">
      <div className="card__header-row">
        <div className="card__header-col">
          <h2 className="card__title">Team members</h2>
          <p className="card__text">
            You can manage team members here by clicking any of the fields below.
          </p>
        </div>
        <div className="card__header-col">
          {(canInvite || canManage) && (
            <button
              type="button"
              className="button button--primary-blue button--sm"
              data-testid="invite-button"
              onClick={() => {
                dispatch(setModalInviteOpen(true));
              }}
            >
              <Icon name="plus" classes="button__icon" />
              <span>Invite new members</span>
            </button>
          )}
        </div>
      </div>
      {/* Table */}
      <div className="table-scroll">
        <div className="table-scroll__box">
          <table className="table table--without-border">
            <tbody>
              <tr className="table__header-row">
                <td className="table__cell table__cell--header">Name</td>
                <td className="table__cell table__cell--header">E-mail address</td>
                <td className="table__cell table__cell--header">Status</td>
                <td className="table__cell table__cell--header">Role</td>
                <td className="table__cell table__cell--header table__cell--quick-actions">
                  <Tooltip text="Quick actions">
                    <div className="table-dots table-dots--white">
                      <Icon name="dots" classes="table-dots__icon" />
                    </div>
                  </Tooltip>
                </td>
              </tr>
              {filteredMembers &&
                filteredMembers.map((item) => {
                  const memberUuid = (item as PartnerMember).uuid;
                  const memberInvite = item as InvitedMember;

                  if (memberUuid) {
                    return <MemberRow member={item as PartnerMember} key={memberUuid} />;
                  }

                  return <InvitedMemberRow member={memberInvite} key={memberInvite.contactUri} />;
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-pagination">
        <div className="table-pagination__col table-pagination__col--page-nav">
          <button
            type="button"
            className="table-pagination__button table-pagination__button--prev"
            disabled={currentPage <= 1}
            onClick={() => changePage(currentPage - 1)}
          >
            <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
          </button>
          <div className="table-pagination__center">
            <input
              type="text"
              className="input table-pagination__input"
              value={currentPage}
              onChange={(e) => changePage(+e.target.value)}
            />
            <span className="table-pagination__text">{`of ${totalPages}`}</span>
          </div>
          <button
            type="button"
            className="table-pagination__button table-pagination__button--next"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyMembers;
