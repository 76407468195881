import React from 'react';

import '../../../scss/components/sign-up/NewSignUp.scss';
import { Alert } from '../../../shared';
import { AuthBannerContent } from '../../auth/components/AuthBanner';
import AuthorizationLayout from '../../auth/layout/AuthorizationLayout';
import { SignUpForm } from '../components';

export default function NewSignUp() {
  return (
    <AuthorizationLayout bannerContent={AuthBannerContent.SignUp} alertOff support>
      <div className="new-sign-up">
        <SignUpForm />
      </div>
      <Alert position="top" />
    </AuthorizationLayout>
  );
}
