import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import ZError from '../../../../shared/errors/ZError';

type CloseLoanParams = {
  loanId: string;
  type: 'forgiven' | 'cancelled' | 'rejected';
};

export const closeLoan = createAsyncThunk<
  boolean,
  CloseLoanParams,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('loan/closeLoan', async ({ loanId, type }, { rejectWithValue }) => {
  try {
    await api.post(`/loans/${loanId}/closure`, { type });

    return true;
  } catch (e) {
    const error = new ZError({ internalError: e as Error });
    return rejectWithValue(error.FullTitle);
  }
});
