import React from 'react';

import AuthorizationSuccessLayout from '../layout/AuthorizationSuccessLayout';

export default function SuccessActivate() {
  return (
    <AuthorizationSuccessLayout>
      <h1 className="auth-success__title">You’ve successfully activated your account!</h1>
      <p className="auth-success__text auth-success__text--activate">
        Thank you for taking steps to verify that it’s really you. Now you added an extra layer of
        security to your account.
      </p>
    </AuthorizationSuccessLayout>
  );
}
