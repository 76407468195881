import React from 'react';

import { News } from '../../../../shared/components/NewsStory/components';
import { newsStories } from '../../../../shared/components/NewsStory/constants/newsStories';

export default function RecentNews() {
  return (
    <div className="card">
      <h3 className="loans-overview__recent-news-title">Recent news</h3>
      <div className="loans-overview__recent-news-subtitle">
        Stay up to date with the latest news here at Zirtue!
      </div>

      <div className="news-stories__container">
        {newsStories.length > 0 &&
          newsStories.map((news) => (
            <News
              key={news.id}
              description={news.description}
              id={news.id}
              link={news.link}
              preview={news.preview}
              title={news.title}
            />
          ))}
      </div>
    </div>
  );
}
