import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { setModalVerifyFundingSource } from '../../../../features/finances/store';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import useCheckoutList, { CheckoutListItem } from '../hooks/useCheckoutList';

type ListItemWrapperProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
} & Pick<CheckoutListItem, 'isDone' | 'linkTo' | 'property'> &
  Pick<ReturnType<typeof useCheckoutList>['operations'], 'checkItem'>;

const ListItemWrapper: FC<ListItemWrapperProps> = ({
  linkTo,
  isDone,
  setShowModal,
  children,
  checkItem,
  property,
}) => {
  const { paymentMethods } = useAppSelector((state) => state.finances, shallowEqual);
  const dispatch = useAppDispatch();

  const clickHandler = () => {
    const paymentMethod = paymentMethods.filter((el) => el.allowance !== 'VERIFIED')[0];

    dispatch(setModalVerifyFundingSource(paymentMethod));
    setShowModal(true);
  };

  const linkClickHandler = () => {
    if (property) {
      checkItem(property);
    }
  };

  return linkTo ? (
    <Link
      to={linkTo}
      onClick={linkClickHandler}
      className={classNames('checkout-list__container__list-item', { done: isDone })}
    >
      {children}
    </Link>
  ) : (
    <button
      className={classNames('checkout-list__container__list-item', { done: isDone })}
      onClick={clickHandler}
      type="button"
    >
      {children}
    </button>
  );
};

export default ListItemWrapper;
