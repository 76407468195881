import React, { FC, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { AdminPartnerDto } from '../../../shared/models/platform-admin';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import {
  PartnersTable,
  PartnersTableControls,
  SelectedPartner,
  TablePagination,
} from '../components';
import TabsBlock from '../components/partners/TabsBlock';
import { usePartnersTableSearchEffect } from '../hooks';
import { selectTableItem } from '../store';
import { adminLoanPresetsList, getUserPaymentMethods } from '../store/actions';
import {
  partnersCollectionSelectors,
  selectPartnersPagination,
  selectPartnersState,
} from '../store/selectors/partners';

export const PlatformAdminPartnersPage: FC = () => {
  const dispatch = useAppDispatch();
  const partners = useAppSelector(partnersCollectionSelectors.selectAll);
  const partnersPagination = useAppSelector(selectPartnersPagination);
  const { selectedItemId } = useAppSelector(selectPartnersState);

  const partner = partners.find((item) => item.uuid === selectedItemId);

  usePartnersTableSearchEffect();

  const onPartnerClick = useCallback(({ uuid }: AdminPartnerDto) => {
    dispatch(selectTableItem({ id: uuid, tableName: 'partners' }));
    // @ts-ignore
    dispatch(adminLoanPresetsList(uuid));
    // @ts-ignore
    dispatch(getUserPaymentMethods(uuid));
  }, []);

  return (
    <DashboardLayout pageTitle="Admin Partners">
      <PartnersTableControls />
      <TablePagination tableName="partners" {...partnersPagination} />
      <PartnersTable
        partners={partners}
        selectedId={selectedItemId}
        onPartnerClick={onPartnerClick}
      />
      <TablePagination tableName="partners" {...partnersPagination} />
      <SelectedPartner />
      <TabsBlock selectedItemId={selectedItemId} partner={partner} />
    </DashboardLayout>
  );
};
