import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FileTransferModal } from '../../../shared/models/FileTransferModel';
import { SftpSettingsModel } from '../../../shared/models/platform-admin';
import { Webhook } from '../../../shared/models/Webhook';

import { createPartnerWebhook } from './actions/createPartnerWebhook';
import { createSFTPconfiguration } from './actions/createSFTPconfiguration';
import { deletePartnerWebhook } from './actions/deleteWebhook';
import { getPartnerWebhook } from './actions/getPartnerWebhook';
import { getSftpReportSettings } from './actions/getSftpReportSettings';
import { getSftpSettings } from './actions/getSftpSettings';
import { testPartnerWebhook } from './actions/testWebhook';
import { updatePartnerWebhook } from './actions/updatePartnerWebhook';
import { updateSftpSettings } from './actions/updateSftpSettings';
import { uploadSftpFiles } from './actions/uploadSftpFiles';

type DevToolsSliceState = {
  fileDetail: FileUploadDetail | null;
  fileTransferSettings?: FileTransferModal;
  webhooks: Webhook[];
  sftpSetting: SftpSettingsModel | null;
  isNewWebhook: boolean;
  loading: boolean;
  testWebhookLoading: boolean;
  testWebhookError: boolean;
  filesLoading: boolean;
  accountCreationError: string;
  uploadFileError: string;
  uploadedSftpFiles: File[];
};

export interface FileUploadDetail {
  name: string;
  date: string;
  status: 'success' | 'error';
}
const initialState: DevToolsSliceState = {
  fileDetail: null,
  webhooks: [],
  sftpSetting: null,
  isNewWebhook: false,
  loading: false,
  testWebhookLoading: false,
  filesLoading: false,
  uploadFileError: '',
  uploadedSftpFiles: [],
  testWebhookError: false,
  accountCreationError: '',
};

const devToolsSlice = createSlice({
  name: 'devTools',
  initialState,
  reducers: {
    setFileUploadDetail: (state, action: PayloadAction<FileUploadDetail>) => {
      state.fileDetail = action.payload;
    },
    setCreatedNewWebhook: (state, action: PayloadAction<boolean>) => {
      state.isNewWebhook = action.payload;
    },
    setTestWebhookLoading: (state, action: PayloadAction<boolean>) => {
      state.testWebhookLoading = action.payload;
    },
    setLoadingSftpFiles: (state, action: PayloadAction<boolean>) => {
      state.filesLoading = action.payload;
    },
    setSftpAccountCreationError: (state, action: PayloadAction<string>) => {
      state.accountCreationError = action.payload;
    },
    setUploadFileError: (state, action: PayloadAction<string>) => {
      state.uploadFileError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deletePartnerWebhook.fulfilled, (state) => {
      state.webhooks = [];
    });
    builder.addCase(getSftpSettings.fulfilled, (state, action) => {
      state.sftpSetting = action.payload;
    });
    builder.addCase(getPartnerWebhook.fulfilled, (state, action) => {
      state.webhooks = [action.payload];
    });
    builder.addCase(createPartnerWebhook.fulfilled, (state, action) => {
      state.webhooks = [action.payload];
      state.testWebhookError = false;
      state.isNewWebhook = true;
    });
    builder.addCase(uploadSftpFiles.fulfilled, (state, action) => {
      state.filesLoading = false;
      state.uploadedSftpFiles = [action.payload];
    });
    builder.addCase(updatePartnerWebhook.fulfilled, (state, action) => {
      state.webhooks = [action.payload];
      state.testWebhookError = false;
    });
    builder.addCase(updateSftpSettings.fulfilled, (state, action) => {
      if (state.sftpSetting) {
        state.sftpSetting = {
          ...state.sftpSetting,
          sftp: { ...state.sftpSetting.sftp, password: action.payload.password },
        };
      }
    });
    builder.addCase(testPartnerWebhook.fulfilled, (state) => {
      state.testWebhookLoading = false;
      state.testWebhookError = false;
    });
    builder.addCase(testPartnerWebhook.rejected, (state) => {
      state.testWebhookLoading = false;
      state.testWebhookError = true;
    });
    builder.addCase(createSFTPconfiguration.rejected, (state, action) => {
      state.accountCreationError = action.payload as string;
    });
    builder.addCase(getSftpReportSettings.fulfilled, (state, action) => {
      state.fileTransferSettings = action.payload[action.payload.length - 1];
    });
  },
});

export default devToolsSlice;
export const {
  setFileUploadDetail,
  setCreatedNewWebhook,
  setTestWebhookLoading,
  setLoadingSftpFiles,
  setSftpAccountCreationError,
  setUploadFileError,
} = devToolsSlice.actions;
