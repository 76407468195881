import React, { ReactElement, useEffect, useState } from 'react';

import { useOnClickOutside, Icon } from '../../../shared';
import { Colors } from '../../../shared/constants/Colors';

export type DataRow = {
  color: Colors;
  value: string;
  uuid: string;
};

type InputValue = {
  color: DataRow['color'] | null;
  value: string;
  uuid: string;
};

type Props = {
  placeholder?: string;
  id: string;
  value?: DataRow;
  data: Array<DataRow>;
  disabled?: boolean;
  listPosition?: string;
  error?: string;
  classes?: string;
  onChange?: (value: string) => void;
};

const initialValue = {
  color: null,
  value: '',
  uuid: '',
};

export default function RoleSelect({
  placeholder,
  id,
  value,
  data,
  disabled,
  listPosition = 'absolute',
  classes,
  onChange,
  error,
}: Props) {
  const [inputValue, setInputValue] = useState<InputValue>(initialValue);
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const selectRef = React.createRef<HTMLInputElement>();
  useOnClickOutside(selectRef, () => {
    setOpened(false);
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setValue(val: any) {
    setInputValue(val);
    setOpened(false);
    if (onChange) {
      onChange(val.uuid);
    }
  }
  let listItems: ReactElement[] = [];
  if (data && data.length) {
    listItems = data.map((row: DataRow) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <li
        key={row.value.toString()}
        className={`
          select__option
          ${row.uuid === inputValue.uuid ? 'select__option--selected' : ''}
        `}
        onClick={() => {
          setValue({ color: row.color, value: row.value, uuid: row.uuid });
        }}
      >
        <span className={`role role--${row.color}`}>{row.value}</span>
      </li>
    ));
  }

  return (
    <div
      className={`
        select 
        ${classes || ''}
        ${opened ? ' select--opened' : ''}
        ${disabled ? ' select--disabled' : ''}
        ${error ? ' select--error' : ''}
      `}
      ref={selectRef}
    >
      <div className="select__box">
        {inputValue.color && <span className={`role role--${inputValue.color} select__role`} />}
        <input
          type="text"
          className={`
            input select__input
            ${inputValue.color ? ' select__input--role-selected' : ''}
            ${error ? ' input--error' : ''}
          `}
          value={inputValue.value}
          placeholder={placeholder}
          name={id}
          id={id}
          disabled={disabled}
          onClick={() => {
            setOpened(!opened);
          }}
          readOnly
        />
        <div className="select__chevron">
          <Icon name="chevron" classes="select__chevron-icon" />
        </div>
      </div>

      <ul
        className={`select__option-list select__option-list--roles select__option-list--${listPosition}`}
      >
        {listItems}
      </ul>
      {error && <span className="select__error-text">{error}</span>}
    </div>
  );
}
