import numeral from 'numeral';

import { Icon, Progress } from '../../../../shared';

type LoanOverviewCardProps = {
  progress: number;
  totalAmount: number;
  paidAmount: number;
  paidRemainingAmount: number;
  chartState: number;
  date: string | string[];
};

export default function LoanOverviewCard({
  progress,
  date,
  totalAmount,
  paidAmount,
  paidRemainingAmount,
  chartState,
}: LoanOverviewCardProps) {
  const getVector = (vector: number) => {
    switch (vector) {
      case 0:
        return 'vector-down';
      case 1:
        return 'vector-top';
      default:
        return '';
    }
  };

  return (
    <div className="loan__overview__card">
      <div className="loan__overview__card-title">Loan overview</div>
      <div className="loan__overview__card-day">{date}</div>
      <div className="loan__overview__card-amount">
        <span>
          <span>$</span>
          {numeral(totalAmount).format('0,000.00')}
        </span>

        {getVector(chartState) && <Icon name={getVector(chartState)} />}
      </div>
      <Progress currentStep={257} totalSteps={1030} progress={`${298 * progress}px`} />
      <div className="loan__overview__card__paid-block">
        <span>{`$${numeral(paidAmount).format('0,000.00')} paid back`}</span>
        <span>{`$${numeral(paidRemainingAmount).format('0,000.00')} remaining`}</span>
      </div>
    </div>
  );
}
