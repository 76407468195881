import React, { FC, useState } from 'react';

import { Icon } from '../../../shared';
import { AssetType, SelectedFlow, TAsset } from '../../auth/store';
import previewZirtuePopUpInnerImage from '../assets/zirtue-pop-up-widget-inner.svg';
import previewZirtuePopUpImage from '../assets/zirtue-pop-up-widget.svg';
import previewZirtueExperienceImage from '../assets/zirtue-preview-experience.png';
import previewZirtuePayImage from '../assets/zirtue-preview-pay.svg';

import ModalImagePreview from './ModalImagePreview';

type IntegrationsPreviewProps = {
  activeAsset: TAsset | null;
  selectedFlow: SelectedFlow | null;
  handleToggleIntegrationsPreview: (assetType: AssetType | undefined) => void;
};

const IntegrationsPreview: FC<IntegrationsPreviewProps> = ({
  activeAsset,
  selectedFlow,
  handleToggleIntegrationsPreview,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState('');
  return (
    <>
      <div className={`integrationsPreview ${activeAsset?.isOpen ? 'open' : ''}`}>
        <div className={`integrationsPreview__header ${!activeAsset?.isOpen ? 'closed' : ''}`}>
          {activeAsset?.isOpen ? 'Preview' : ''}
          {/* eslint-disable-next-line */}
          <img
            src="images/dashboard/angle-right-solid.svg"
            alt="angle-right-solid"
            onClick={() => handleToggleIntegrationsPreview(activeAsset?.assetType)}
          />
        </div>
        <div className={`integrationsPreview__body ${!activeAsset?.isOpen ? 'closed' : ''}`}>
          <div className="selectedCampaign__selectedAsset__container__text">
            Here is what your customers will see once they click on the call to action.
          </div>
          {activeAsset?.assetType === AssetType.ZIRTUE_PAY_BUTTON && selectedFlow === 'billpay' ? (
            <div className="integrationsPreview__block">
              <div className="selectedCampaign__selectedAsset__container__title integrationsPreview__title">
                Zirtue Pay
              </div>
              <div className="selectedCampaign__selectedAsset__container__text">
                Here is what your customers will see once they click on the call to action.
              </div>
              {/* eslint-disable-next-line */}
              <div
                className="integrationsPreview__imageBlock"
                onClick={() => {
                  setIsOpen(true);
                  setImage(previewZirtuePayImage);
                }}
              >
                <div className="integrationsPreview__imageBlock__btn">
                  <Icon name="magnify-svgrepo-com" />
                </div>
                <img src={previewZirtuePayImage} alt="Preview" />
              </div>
            </div>
          ) : (
            ''
          )}
          {activeAsset?.assetType === AssetType.ZIRTUE_PAY_BUTTON ||
          activeAsset?.assetType === AssetType.QUICK_LINK ? (
            <div className="integrationsPreview__block">
              <div className="selectedCampaign__selectedAsset__container__title integrationsPreview__title">
                Zirtue experience
              </div>
              <div className="selectedCampaign__selectedAsset__container__text">
                Here is what your customers will see once they click on the call to action.
              </div>
              {/* eslint-disable-next-line */}
              <div
                className="integrationsPreview__imageBlock"
                onClick={() => {
                  setIsOpen(true);
                  setImage(previewZirtueExperienceImage);
                }}
              >
                <div className="integrationsPreview__imageBlock__btn">
                  <Icon name="magnify-svgrepo-com" />
                </div>
                <img src={previewZirtueExperienceImage} alt="Preview" />
              </div>
            </div>
          ) : (
            ''
          )}
          {activeAsset?.assetType === AssetType.BILL_PAY_POPUP ? (
            <>
              <div className="integrationsPreview__block">
                <div className="selectedCampaign__selectedAsset__container__title integrationsPreview__title">
                  Zirtue Pay
                </div>
                <div className="selectedCampaign__selectedAsset__container__text">
                  Here is what your customers will interact with to trigger the pop-up widget.
                </div>
                {/* eslint-disable-next-line */}
                <div
                  className="integrationsPreview__imageBlock"
                  onClick={() => {
                    setIsOpen(true);
                    setImage(previewZirtuePayImage);
                  }}
                >
                  <div className="integrationsPreview__imageBlock__btn">
                    <Icon name="magnify-svgrepo-com" />
                  </div>
                  <img src={previewZirtuePayImage} alt="Preview" />
                </div>
              </div>
              <div className="integrationsPreview__block">
                <div className="selectedCampaign__selectedAsset__container__title integrationsPreview__title">
                  Pop-up widget experience
                </div>
                <div className="selectedCampaign__selectedAsset__container__text">
                  Here is what your customers will see once they click on the call to action.
                </div>
                {/* eslint-disable-next-line */}
                <div
                  className="integrationsPreview__imageBlock"
                  onClick={() => {
                    setIsOpen(true);
                    setImage(previewZirtuePopUpInnerImage);
                  }}
                >
                  <div className="integrationsPreview__imageBlock__btn">
                    <Icon name="magnify-svgrepo-com" />
                  </div>
                  <img src={previewZirtuePopUpImage} alt="Preview" />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <ModalImagePreview isOpen={isOpen} setIsOpen={setIsOpen} image={image} />
    </>
  );
};

export default IntegrationsPreview;
