import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { parseError } from '../../../../shared/utils/helpers';
import { LoanCreationLimitationsDto } from '../../../loans/dtos/LoanCreationLimitationsDto';

export const adminGetLimitations = createAsyncThunk<
  LoanCreationLimitationsDto,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminGetLimitations', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<LoanCreationLimitationsDto>(
      `/platform-admin/partners/limitations/${payload}`,
    );

    return data;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
