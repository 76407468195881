import classNames from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { RefObject, SetStateAction } from 'react';
import Autocomplete from 'react-google-autocomplete';

import { GOOGLE_PLACES } from '../../../../../main/env';
import { useAppSelector } from '../../../../../main/store/hooks';
import { Icon, Input, Label, MaskInput } from '../../../../../shared';
import { Address } from '../../../../../shared/models/AddAchNodeDto';
import { FlowTypes } from '../../../../auth/store';
import { SelectedPlace } from '../../../../finances/modals/ModalAddPaymentMethod';
import { addressFromGooglePlace } from '../../../../finances/store/actions/addAchNode';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { FormData } from '../../MultiStepForm/MultiStepForm';

type DataType = {
  ein: string;
  businessName: string;
  address?: Address;
};

type FormicDataTypes = {
  values: Pick<FormData, 'ein' | 'businessName' | 'address'>;
  errors: FormikErrors<DataType>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<DataType>;
  setFieldValue: (
    field: string,
    value: Address | null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  addressRef: RefObject<HTMLInputElement>;
  setCompanyAddres: React.Dispatch<SetStateAction<string>>;
};

export default function RegisterBusinessForm({
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  addressRef,
  setFieldValue,
  setFieldTouched,
  setCompanyAddres,
}: FormicDataTypes) {
  const handleAddPlace = (place: SelectedPlace, fieldName: string) => {
    const dtoAddress: Address | null = addressFromGooglePlace(place);
    if (dtoAddress === null) return;
    if (dtoAddress) {
      setFieldValue(fieldName, dtoAddress, true);
      setFieldTouched(fieldName);
    }
  };

  const handleChangeAddress = () => {
    setFieldValue('address', '', false);
  };

  const { selectedFlow } = useAppSelector((state) => state.auth);

  return (
    <FormWrapper
      title="Complete the registration requirements"
      description="Share some basic legal information about your business. We'll need this information in order to process transactions."
    >
      <div className="registerBusinessForm">
        <div className="registerBusinessForm__input">
          <Label
            forId="ein"
            infoTitle="EIN"
            infoText="To verify your business, you must enter an EIN (also known as a Federal Tax Identification Number)."
            arrowPosition="center"
          >
            EIN
          </Label>
          <MaskInput
            id="ein"
            mask="99-9999999"
            value={values.ein}
            inputClassName="input input-group__input registerBusinessForm__input"
            placeholder="12-3456789"
            onChange={handleChange}
            onBlur={(e) => handleBlur(e)}
            error={Boolean(errors.ein) && touched.ein && errors.ein}
          />
        </div>
        <div className="registerBusinessForm__input">
          <Label
            forId="lbn"
            infoTitle="Legal business name"
            infoText="Please enter your legal registered business name."
            arrowPosition="center"
          >
            Legal Business Name
          </Label>
          <Input type="text" id="lbn" value={values.businessName} disabled />
        </div>
        <div className="registerBusinessForm__input">
          <Label
            forId="address"
            infoTitle={
              selectedFlow === FlowTypes.LOAN_CREATOR ? 'Mailing address' : 'Primary address'
            }
            infoText="Make sure you enter the complete and exact street address for your profile."
            arrowPosition="center"
          >
            {selectedFlow === FlowTypes.LOAN_CREATOR ? 'MAILING ADDRESS' : 'Primary address'}
          </Label>
          <div className="autocomplete">
            <Autocomplete
              className={classNames(
                'autocomplete__input',
                {
                  'autocomplete__input--error': errors.address && touched.address,
                },
                'registerBusinessForm__input',
              )}
              onKeyDown={(e) => {
                if (addressRef?.current && e.code === 'KeyV' && (e.metaKey || e.ctrlKey)) {
                  addressRef.current.value = ' ';
                  setTimeout(() => {
                    if (addressRef.current) {
                      addressRef.current.value = addressRef.current.value.trim();
                    }
                  }, 10);
                }
              }}
              ref={addressRef}
              apiKey={GOOGLE_PLACES()}
              placeholder="123 Main Street, Apt. 1, Anytown, USA 12345"
              onPlaceSelected={(place) => {
                handleAddPlace(place, 'address');
                setCompanyAddres(place.formatted_address);
              }}
              defaultValue={addressRef.current?.value}
              onSubmit={handleChangeAddress}
              onChange={handleChangeAddress}
              options={{
                types: ['address'],
                language: 'en',
                componentRestrictions: { country: 'usa' },
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
            />
            <div className="autocomplete__icon">
              <Icon name="chevron" />
            </div>
            {errors.address && touched.address && (
              <div className="autocomplete__error">{errors.address}</div>
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
