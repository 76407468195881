import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const deletePartnerWebhook = createAsyncThunk<
  unknown,
  unknown,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/deletePartnerWebhook', async (event, { rejectWithValue }) => {
  try {
    await api.delete(`/partners/webhook`, { data: { event } });

    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
