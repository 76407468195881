import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useLazyEffect } from '../../../shared';
import { adminLoansSearch } from '../store/actions';
import { selectLoansState } from '../store/selectors/loans';

export const useLoansTableSearchEffect = () => {
  const dispatch = useAppDispatch();
  const { page, pageSize, dateRangeFilter, searchQuery, stage, sortOrder, sortField } =
    useAppSelector(selectLoansState);

  useLazyEffect(
    () => {
      dispatch(adminLoansSearch());
    },
    [page, pageSize, searchQuery, dateRangeFilter, stage, sortOrder, sortField],
    300,
  );
};
