export type CompanyRoleDto = {
  color: string;
  creatorId: string;
  members: Array<string>;
  name: string;
  permissions: Array<string>;
  permissionsLocked: boolean;
  uuid: string;
  creatorEmail: string;
};

export type CompanyRole = CompanyRoleDto;

export class CompanyRoleModel {
  public static fromDTO(dto: CompanyRoleDto): CompanyRole {
    return {
      ...dto,
    };
  }
}
