import React from 'react';

import AuthorizationSuccessLayout from '../layout/AuthorizationSuccessLayout';

export default function SuccessRegister() {
  return (
    <AuthorizationSuccessLayout>
      <h1 className="auth-success__title">
        You have successfully registered your business with Zirtue!
      </h1>
      <p className="auth-success__text auth-success__text--register">
        Your application is currently under review, our team will be touch with you soon!
      </p>
    </AuthorizationSuccessLayout>
  );
}
