import { isNil } from 'lodash';
import React, { FC, useEffect, useMemo } from 'react';

import Icon from './Icon';
import Label from './Label';
import Select, { DataItem } from './Select';

interface PaginationProps {
  page: number;
  pageSize: number;
  pagesCount: number;
  total?: number;
  setPage: (pageNumber: number) => void;
  setPageSize: (pageNumber: number) => void;
  pageSizes?: number[];
  controls?: React.ReactNode[];
}

const DEFAULT_SIZES = [8, 16, 32, 64];
export const Pagination: FC<PaginationProps> = ({
  page,
  pageSize,
  pagesCount,
  total,
  setPage,
  setPageSize,
  pageSizes = DEFAULT_SIZES,
  controls,
}) => {
  const pageSizeSelectItems = useMemo<DataItem[]>(
    () => pageSizes?.map((size) => ({ dataId: size, name: `${size}` })),
    [pageSizes],
  );

  // fix-up pageSize just in case. So that it shows a correct select item
  useEffect(() => {
    if (!pageSizes) return;
    if (!pageSizes.includes(pageSize)) setPageSize(pageSizes[0]);
  }, [pageSizes, pageSize, setPageSize]);

  return (
    <div className="table-pagination">
      <div className="table-pagination__col">
        <Label forId="per-page" classes="table-pagination__per-page-label">
          Rows per page
        </Label>
        <Select
          classes="select--no-margin table-pagination__per-page-select"
          id="per-page"
          data={pageSizeSelectItems}
          value={pageSize}
          onChange={setPageSize}
        />

        {controls && controls.length && (
          <div className="table-pagination__controls">{controls}</div>
        )}

        {!isNil(total) && (
          <div className="table-pagination__total">{`Total Results: ${total}`}</div>
        )}
      </div>
      <div className="table-pagination__col table-pagination__col--page-nav">
        <button
          type="button"
          className="table-pagination__button table-pagination__button--prev"
          disabled={page <= 1}
          onClick={() => setPage(page - 1)}
        >
          <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
        </button>
        <div className="table-pagination__center">
          <input
            style={{ width: '60px' }}
            type="text"
            className="input table-pagination__input"
            value={page}
            onChange={(e) => setPage(+e.target.value)}
          />
          <span className="table-pagination__text">{`of ${pagesCount}`}</span>
        </div>
        <button
          type="button"
          className="table-pagination__button table-pagination__button--next"
          onClick={() => setPage(page + 1)}
          disabled={page >= pagesCount}
        >
          <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
        </button>
      </div>
    </div>
  );
};
