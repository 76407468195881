import React, { FC } from 'react';

import { Typography } from '../../init-setup/components';

type AccountTitleProps = {
  text: string;
};

const AccountTitle: FC<AccountTitleProps> = ({ text, children }) => (
  <div>
    <h2 className="account__title">{children}</h2>
    <Typography tag="p" className="account__text">
      {text}
    </Typography>
  </div>
);

export default AccountTitle;
