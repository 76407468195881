import React, { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Tooltip } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { CompanyRole } from '../../../shared/models';
import { getContact } from '../../../shared/utils';
import { hasRole, isAdmin } from '../../auth/utils';
import { setModalRoleOpen } from '../store';
import { getCompanyRoles } from '../store/actions';

import QuickRoleActions from './QuickRoleActions';

const colorType: Record<string, string> = {
  green: 'mint20',
  mint: 'mint50',
  yellow: 'yellow55',
  red: 'red70',
  grey: 'neutral70',
  brown: 'chery24',
};

const colorName: Record<string, string> = {
  green: 'Green',
  mint: 'Mint',
  yellow: 'Yellow',
  red: 'Red',
  grey: 'Grey',
  brown: 'Brown',
};

type IProps = {
  q?: string;
};

const CompanyProfileRoles: React.FC<IProps> = ({ q }: IProps) => {
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((state) => state.company);
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);

  const canManageRoles =
    authInfo && (hasRole(authInfo, Permissions.MEMBERS_MANAGE) || isAdmin(authInfo));

  useEffect(() => {
    if (!roles.length) {
      dispatch(getCompanyRoles());
    }
  }, [dispatch, roles.length]);

  const filteredRoles = useMemo(() => {
    if (!q) {
      return roles;
    }

    return roles.filter(
      (role) =>
        role.name.toLowerCase().includes(q.toLowerCase().trim()) ||
        role.creatorEmail.toLowerCase().includes(q.toLowerCase().trim()),
    );
  }, [roles, q]);

  return (
    <div className="card">
      <div className="card__header-row">
        <div className="card__header-col">
          <h2 className="card__title">Roles</h2>
          <p className="card__text">
            You can use roles to organize team members and customize permissions.
          </p>
        </div>
        <div className="card__header-col">
          {canManageRoles && (
            <button
              type="button"
              className="button button--primary-blue button--sm"
              onClick={() => {
                dispatch(setModalRoleOpen({ isOpen: true, type: 'add' }));
              }}
            >
              <Icon name="plus" classes="button__icon" />
              <span>Add new role</span>
            </button>
          )}
        </div>
      </div>
      {/* Table */}
      <div className="table-scroll">
        <div className="table-scroll__box">
          <table className="table table--without-border">
            <thead>
              {/* Table header row */}
              <tr className="table__header-row">
                <td className="table__cell table__cell--header">Role name</td>
                <td className="table__cell table__cell--header">Members</td>
                <td className="table__cell table__cell--header">Color</td>
                <td className="table__cell table__cell--header">Created by</td>
                {canManageRoles && (
                  <td className="table__cell table__cell--header table__cell--quick-actions">
                    <Tooltip text="Quick actions">
                      <div className="table-dots table-dots--white">
                        <Icon name="dots" classes="table-dots__icon" />
                      </div>
                    </Tooltip>
                  </td>
                )}
              </tr>
            </thead>
            {/* Table data row */}
            <tbody>
              {filteredRoles.map((role: CompanyRole) => {
                const { name, members, color, creatorEmail, uuid } = role;
                return (
                  <tr key={uuid} className="table__data-row">
                    <td className="table__cell table__cell--data table__cell--name">
                      <span className={`role role--${colorType[color]} table-highlight`}>
                        {name}
                      </span>
                    </td>
                    <td className="table__cell table__cell--data">{`Members (${members.length})`}</td>
                    <td className="table__cell table__cell--data">
                      <span className={`role role--${colorType[color]} table-highlight`}>
                        {colorName[color]}
                      </span>
                    </td>
                    <td className="table__cell table__cell--data">{getContact(creatorEmail)}</td>
                    {canManageRoles && (
                      <td className="table__cell table__cell--data table__cell--quick-actions">
                        <QuickRoleActions role={role}>
                          <button type="button" className="table-dots">
                            <Icon name="dots" classes="table-dots__icon" />
                          </button>
                        </QuickRoleActions>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileRoles;
