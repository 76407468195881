import { first } from 'lodash';
import moment from 'moment';

import { RepaymentPlanPayment } from '../dtos/RepaymentPlan';

export const prepareRepaymentDateToDraw = (
  payment: RepaymentPlanPayment,
  loanRepaymentDateFrom: string,
) => {
  let dateText;
  if (payment.history && payment.history.length) {
    const firstPlannedExecutionOn = first(payment.history);
    dateText = moment(firstPlannedExecutionOn?.timestamp).format('MMM DD, YYYY');
  } else if (payment.plannedExecutionOn) {
    dateText = moment(payment.plannedExecutionOn).format('MMM DD, YYYY');
  } else {
    dateText = moment(loanRepaymentDateFrom)
      .add(payment.position - 1, 'M')
      .format('MMM DD, YYYY');
  }
  return dateText;
};
