import React from 'react';

import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { setModalConfirmOpen } from '../store/modals';

import Icon from './Icon';
import Modal from './Modal';

const ModalConfirm: React.FC = () => {
  const {
    modalConfirmOpen,
    modalConfirmTitle,
    modalConfirmText,
    modalConfirmOkText,
    modalConfirmCancelText,
    modalConfirmImg,
    modalConfirmBodyClasses,
    modalConfirmOnConfirm = () => null,
  } = useAppSelector((state) => state.modals);

  const dispatch = useAppDispatch();
  function TextList(rows: string[]) {
    return rows.map((row) => <span className="modal-content__text-row">{row}</span>);
  }

  return (
    <Modal
      opened={modalConfirmOpen}
      handleClose={() => {
        dispatch(setModalConfirmOpen({ opened: false }));
      }}
      classes="modal--lg"
      bodyClasses={modalConfirmBodyClasses}
    >
      <div className="modal-content">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalConfirmOpen({ opened: false }));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}

        {modalConfirmImg ? (
          <div style={{ display: 'flex', justifyContent: 'center', margin: 32 }}>
            <img className="" src={modalConfirmImg} alt="" />
          </div>
        ) : (
          <div className="modal-content__illustration modal-content__illustration--confirm">
            <img
              className="modal-content__illustration-img modal-content__illustration-img--lv1"
              src="/images/dashboard/modals/confirm-lv1.svg"
              alt=""
            />
            <picture>
              <source
                srcSet="/images/dashboard/modals/confirm-lv2@1x.webp 1x, /images/dashboard/modals/confirm-lv2@2x.webp 2x"
                type="image/webp"
              />
              <img
                src="/images/dashboard/modals/confirm-lv2@1x.png"
                srcSet="/images/dashboard/modals/confirm-lv2@2x.png 2x"
                alt=""
                className="modal-content__illustration-img modal-content__illustration-img--lv2"
              />
            </picture>
            <img
              className="modal-content__illustration-img modal-content__illustration-img--lv3"
              src="/images/dashboard/modals/confirm-lv3.svg"
              alt=""
            />
          </div>
        )}

        {/* Content */}
        <h2 className="modal-content__title">{modalConfirmTitle}</h2>
        <p className="modal-content__text">
          {typeof modalConfirmText === 'string' && modalConfirmText}
          {typeof modalConfirmText === 'object' && TextList(modalConfirmText)}
        </p>
        <div className="modal-content__btn-box">
          {modalConfirmCancelText && (
            <button
              type="button"
              className="button button--secondary-blue button--lg modal-content__btn"
              onClick={() => {
                dispatch(setModalConfirmOpen({ opened: false }));
              }}
            >
              {modalConfirmCancelText}
            </button>
          )}

          <button
            type="button"
            className="button button--primary-blue button--lg modal-content__btn"
            onClick={() => {
              dispatch(setModalConfirmOpen({ opened: false }));
              modalConfirmOnConfirm();
            }}
          >
            {modalConfirmOkText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
