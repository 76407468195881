import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch } from '../../../../main/store';
import { Webhook } from '../../../../shared/models/Webhook';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';
import { WebhookFormData } from '../../components/WebhookForm';

export const updatePartnerWebhook = createAsyncThunk<
  Webhook,
  WebhookFormData,
  { rejectValue: string; dispatch: AppDispatch }
>('partners/updatePartnerWebhook', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.patch(`/partners/webhook-configuration`, payload);
    dispatch(addAlert({ text: 'We’ve saved your changes' }));
    return payload;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
