import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { LoanPresetUpdateDto } from '../../../../shared/models/platform-admin/loans/LoanPresetDto';
import { parseError } from '../../../../shared/utils/helpers';

export const adminLoanPresetUpdate = createAsyncThunk<
  string,
  LoanPresetUpdateDto,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminLoanPresetUpdate', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.patch<string>('/platform-admin/loan-presets', payload);

    return data;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
