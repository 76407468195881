import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';

type LoanDeeplinkDto = {
  loanId: string;
};

export const loanShareApi = createAsyncThunk<
  string,
  LoanDeeplinkDto,
  { dispatch: AppDispatch; state: RootState }
>('share', async (payload) => {
  const { data } = await api.get<string>(`/loans/${payload}/share`);

  return data;
});
