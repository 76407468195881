import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { hasRole } from '../../features/auth/utils';
import { useAppSelector } from '../../main/store/hooks';
import Permissions from '../constants/Permissions';
import { Icon } from '../index';
import { Amplitude, AmplitudeEvents } from '../services/amplitude';

type Props = {
  id: string;
  value?: string;
  placeholder?: string;
  classes?: string;
  onClick?: () => void;
  onChange?: (e: string) => void;
};

const Search: React.FC<Props> = ({
  id,
  placeholder,
  classes,
  onClick,
  value,
  onChange = () => null,
}) => {
  const [search, setSearch] = useState('');
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);

  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  const handleClick = () => {
    if (search && !isAdminPanel) {
      Amplitude.logEvent(AmplitudeEvents.paymentsSearched, { text: search });
    }
  };

  return (
    <div className={`search ${classes || ''}`}>
      <input
        type="search"
        name={id}
        id={id}
        className="input search__input"
        placeholder={placeholder || ''}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          setSearch(e.target.value);
          onChange(e.target.value);
        }}
      />
      <button type="button" className="search__button" onClick={handleClick}>
        <Icon name="search" classes="search__icon" />
      </button>
    </div>
  );
};

export default Search;
