import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { Address } from '../../../../shared/models/AddAchNodeDto';

const isValid = (addr: Address): boolean =>
  !(
    isEmpty(addr.city) ||
    isEmpty(addr.country) ||
    isEmpty(addr.line1) ||
    isEmpty(addr.state) ||
    isEmpty(addr.zip)
  );

const addrError = (addr: Address): string => {
  let errorStr = '';

  if (isEmpty(addr.city)) {
    errorStr = 'City';
  } else if (isEmpty(addr.country)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.line1)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.state)) {
    errorStr = 'State';
  } else if (isEmpty(addr.zip)) {
    errorStr = 'Zip';
  }

  return `${errorStr} is missing in this address`;
};

export const firstSchema = yup.object().shape({
  ein: yup
    .string()
    .trim()
    .min(10, 'EIN must be 9 digits long.')
    .label('EIN')
    .required('Please enter a valid EIN'),
  address: yup
    .object()
    .label('Business address')
    .required('Please enter a valid address')
    .test(
      'Business address',
      (val) => {
        if (typeof val.value !== 'object') {
          return '';
        }

        const address = val.value;
        return addrError(address);
      },
      (val) => {
        const address = val as never as Address;
        return typeof address === 'object' && isValid(address);
      },
    ),
});

export const secondSchema = yup.object().shape({
  routingNumber: yup
    .string()
    .trim()
    .min(9, 'A routing number must be 9 digits long.')
    .label('Routing number')
    .required(),
  accountNumber: yup
    .string()
    .trim()
    .min(4, 'An account number must contain 4 - 17 digits.')
    .max(17)
    .label('Account number')
    .required(),
});
