import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Account } from './features/account/pages';
import {
  CheckEmail,
  EmailVerify,
  ForgotPassword,
  Invite,
  ResetPassword,
  SignIn,
  SuccessActivate,
  SuccessRegister,
  Verify,
  VerifySignIn,
} from './features/auth/pages';
import { FlowTypes } from './features/auth/store';
import { getPartner } from './features/auth/store/actions/getPartner';
import { CompanyProfilePage, CompanyRolesPage, TeamMembersPage } from './features/company/pages';
import { Insights } from './features/dashboard/pages';
import {
  FileTransfer,
  FileUpload,
  ModalDocs,
  EditWebhook,
  Webhooks,
} from './features/developer-tools/pages';
import SftpConfigurationPage from './features/developer-tools/pages/SftpConfigurationPage';
import SftpOverviewPage from './features/developer-tools/pages/SftpOverviewPage';
import SftpPage from './features/developer-tools/pages/SftpPage';
import WebhooksInitPage from './features/developer-tools/pages/WebhooksInitPage';
import { Finances } from './features/finances/pages';
import { InitSetup } from './features/init-setup/pages';
import { Integrations } from './features/integrations/pages';
import LoanAnalyticsPage from './features/loans/pages/LoanAnalyticsPage';
import LoanCreationPage from './features/loans/pages/LoanCreationPage';
import LoanOverviewPage from './features/loans/pages/LoanOverviewPage';
import LoanPage from './features/loans/pages/LoanPage';
import LoansPage from './features/loans/pages/LoansPage';
import { BillPayments } from './features/payments/pages';
import {
  PlatformAdminLoansPage,
  PlatformAdminPartnersPage,
  PlatformAdminPaymentsPage,
  PlatformAdminTransactionsPage,
  PlatformAdminUsersPage,
} from './features/platform-admin/pages';
import { PLATFORM_ADMIN_ROUTES } from './features/platform-admin/routes';
import NewSignUp from './features/sign-up/pages/NewSignUp';
import ZirtuePayOverview from './features/zirtue-pay/pages/ZirtuePayOverview';
import { FeatureTogglesNames } from './main/env/feature-toggle-names';
import { useAppSelector } from './main/store/hooks';
import { PermissionsGuard, PermissonsPredicate } from './shared';
import PageNotFound from './shared/components/PageNotFound';
import PrivateRoute from './shared/components/privateRoute';
import Permissions from './shared/constants/Permissions';
import IconsCollection from './shared/utils/icons-collection';

export interface RouteConfigComponentProps<Params extends { [K in keyof Params]?: string }>
  extends RouteComponentProps<Params> {
  route?: RouteConfig | undefined;
}

export interface RouteConfig {
  key?: React.Key | undefined;
  location?: Location | undefined;
  component: React.ElementType;
  path?: string | string[] | undefined;
  exact?: boolean | undefined;
  strict?: boolean | undefined;
  routes?: RouteConfig[] | undefined;
  render?: // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((props: RouteConfigComponentProps<any>) => React.ReactNode) | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: string]: any;
  isPrivate: boolean;
  permissons?: PermissonsPredicate;
  permissionsBypassForAdmin?: boolean;
  permissionsRedirect?: string;
}

const RedirectToPath = () => {
  const { partner, loading } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  useEffect(() => {
    if (!partner && !loading) {
      dispatch(getPartner());
    }
  }, [partner, loading, dispatch]);

  useEffect(() => {
    if (partner) {
      const path =
        partner.details.flowType === FlowTypes.LOAN_CREATOR ? '/loan-overview' : '/payments';
      setRedirectTo(path);
    }
  }, [partner]);

  if (loading || !redirectTo) {
    return <div />;
  }

  return <Redirect to={redirectTo} />;
};

const routePages: RouteConfig[] = [
  {
    path: ['/signin'],
    component: SignIn,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/',
    exact: true,
    component: RedirectToPath,
    isPrivate: true,
  },
  { path: '/signup', component: NewSignUp, isPrivate: false },
  { path: '/signup/:token', component: NewSignUp, isPrivate: false },
  { path: '/forgot-password', component: ForgotPassword, isPrivate: false },
  { path: '/check-email', component: CheckEmail, isPrivate: false },
  { path: '/reset-password/:token', component: ResetPassword, isPrivate: false },
  { path: '/verify', component: Verify, isPrivate: false },
  { path: '/verify-sign-in', component: VerifySignIn, isPrivate: false },
  { path: '/invite/:token', component: Invite, isPrivate: false },
  { path: '/success-register', component: SuccessRegister, isPrivate: true },
  { path: '/success-activate', component: SuccessActivate, isPrivate: true },
  {
    path: '/insights',
    component: Insights,
    isPrivate: true,
    title: 'Dashboard',
    permissionsRedirect: '/access-denied',
  },
  {
    path: '/developer-tools/webhooks/configuration/:id',
    component: EditWebhook,
    isPrivate: true,
    title: 'Developer Tools',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/webhooks/configuration',
    component: Webhooks,
    isPrivate: true,
    title: 'Developer Tools',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/webhooks',
    component: WebhooksInitPage,
    isPrivate: true,
    title: 'Developer Tools',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/sftp/overview',
    component: SftpOverviewPage,
    isPrivate: true,
    title: 'Account creation',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/sftp/configuration',
    component: SftpConfigurationPage,
    isPrivate: true,
    title: 'Configuration',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/sftp/file-upload',
    component: FileUpload,
    isPrivate: true,
    title: 'File upload',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/sftp',
    component: SftpPage,
    isPrivate: true,
    title: 'Sftp Page',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/developer-tools/api-keys/modal/',
    component: ModalDocs,
    isPrivate: true,
    title: 'Modal Keys',
    permissionsRedirect: '/access-denied',
  },
  { path: '/access-denied', component: PageNotFound, isPrivate: true },
  {
    path: '/developer-tools/file-transfer/configuration',
    component: FileTransfer,
    isPrivate: true,
    title: 'Developer Tools',
    permissionsRedirect: '/access-denied',
    permissons: {
      $or: [Permissions.ACCESS_DEVELOPER_TOOLS],
    },
  },
  {
    path: '/dev/icons-collections',
    component: IconsCollection,
    isPrivate: false,
  },
  {
    path: '/company-profile',
    component: CompanyProfilePage,
    isPrivate: true,
    permissionsRedirect: '/access-denied',
    permissionsBypassForAdmin: true,
    permissons: {
      $or: [Permissions.MEMBERS_INVITE, Permissions.MEMBERS_MANAGE, Permissions.FINANCES_MANAGE],
    },
    title: 'Company',
  },
  {
    path: '/roles',
    component: CompanyRolesPage,
    isPrivate: true,
    permissionsRedirect: '/access-denied',
    permissionsBypassForAdmin: true,
    permissons: {
      $or: [Permissions.MEMBERS_INVITE, Permissions.MEMBERS_MANAGE],
    },
    title: 'Company',
  },
  {
    path: '/team-members',
    component: TeamMembersPage,
    isPrivate: true,
    permissionsRedirect: '/access-denied',
    permissionsBypassForAdmin: true,
    permissons: {
      $or: [Permissions.MEMBERS_INVITE, Permissions.MEMBERS_MANAGE],
    },
    title: 'Company',
  },
  { path: '/email-verify', component: EmailVerify, isPrivate: false },
  {
    path: '/init-setup',
    component: InitSetup,
    isPrivate: true,
    permissionsRedirect: '/payments',
    title: 'Init Setup',
    permissons: {
      $or: [Permissions.BILL_PAYMENTS_VIEW],
    },
  },
  // { TODO remove comment
  //   path: '/zirtue-pay-overview',
  //   component: ZirtuePayOverview,
  //   isPrivate: true,
  //   permissons: {
  //     $or: [Permissions.MANAGE_PARTNER_LOANS],
  //   },
  //   permissionsBypassForAdmin: true,
  //   permissionsRedirect: '/access-denied',
  //   title: 'Zirtue Pay Overview',
  // },
  {
    path: '/payments',
    component: BillPayments,
    isPrivate: true,
    permissionsRedirect: '/access-denied',
    title: 'Payments',
    permissons: {
      $or: [Permissions.BILL_PAYMENTS_VIEW],
    },
  },
  {
    path: '/zaal-analytics',
    component: LoanAnalyticsPage,
    isPrivate: true,
    permissons: {
      $or: [Permissions.MANAGE_PARTNER_LOANS],
    },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Overview',
  },
  {
    path: '/loan-overview',
    component: LoanOverviewPage,
    isPrivate: true,
    permissons: {
      $or: [Permissions.MANAGE_PARTNER_LOANS],
    },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Overview',
  },
  {
    path: '/loan-creation',
    component: LoanCreationPage,
    isPrivate: true,
    permissons: {
      $or: [Permissions.MANAGE_PARTNER_LOANS],
    },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Create Loan',
  },
  {
    path: '/loan-details/:id',
    component: LoanPage,
    isPrivate: true,
    permissons: {
      $or: [Permissions.MANAGE_PARTNER_LOANS],
    },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Loan',
  },
  {
    path: '/loan-list',
    component: LoansPage,
    isPrivate: true,
    permissons: {
      $or: [Permissions.MANAGE_PARTNER_LOANS],
    },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Loans',
  },
  {
    path: '/integrations',
    component: Integrations,
    isPrivate: true,
    permissons: { $or: [Permissions.FINANCES_MANAGE, Permissions.FINANCES_VIEW] },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Integrations',
  },
  {
    path: '/account',
    component: Account,
    isPrivate: true,
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Account',
  },
  {
    path: '/finances',
    component: Finances,
    isPrivate: true,
    permissons: { $or: [Permissions.FINANCES_MANAGE, Permissions.FINANCES_VIEW] },
    permissionsBypassForAdmin: true,
    permissionsRedirect: '/access-denied',
    title: 'Bank',
  },
  {
    path: PLATFORM_ADMIN_ROUTES.USERS,
    component: PlatformAdminUsersPage,
    isPrivate: true,
    permissons: { $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT] },
    permissionsBypassForAdmin: false,
    permissionsRedirect: '/access-denied',
    title: 'Admin Users',
    exact: true,
  },
  {
    path: PLATFORM_ADMIN_ROUTES.LOANS,
    component: PlatformAdminLoansPage,
    isPrivate: true,
    permissons: { $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT] },
    permissionsBypassForAdmin: false,
    permissionsRedirect: '/access-denied',
    title: 'Admin Loans',
    exact: true,
  },
  {
    path: PLATFORM_ADMIN_ROUTES.TRANSACTIONS,
    component: PlatformAdminTransactionsPage,
    isPrivate: true,
    permissons: { $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT] },
    permissionsBypassForAdmin: false,
    permissionsRedirect: '/access-denied',
    title: 'Admin Transactions',
  },
  {
    path: PLATFORM_ADMIN_ROUTES.PARTNERS,
    component: PlatformAdminPartnersPage,
    isPrivate: true,
    permissons: { $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT] },
    permissionsBypassForAdmin: false,
    permissionsRedirect: '/access-denied',
    title: 'Admin Partners',
  },
  {
    path: PLATFORM_ADMIN_ROUTES.PAYMENTS,
    component: PlatformAdminPaymentsPage,
    isPrivate: true,
    permissons: { $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT] },
    permissionsBypassForAdmin: false,
    permissionsRedirect: '/access-denied',
    title: 'Admin Bill Payments',
  },
  { path: '*', component: PageNotFound, isPrivate: false },
];

const RouteWithSubRoutes: FC<RouteConfig> = ({
  path,
  routes,
  component: Component,
  isPrivate,
  permissons,
  permissionsBypassForAdmin,
  permissionsRedirect,
  title,
}) => {
  const RouteComponent = isPrivate ? PrivateRoute : Route;
  const render = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: RouteConfigComponentProps<any>) =>
      permissons ? (
        <PermissionsGuard
          permissions={permissons}
          bypassForAdmin={permissionsBypassForAdmin}
          redirect={permissionsRedirect}
          title={title}
        >
          <Component {...props} routes={routes} />
        </PermissionsGuard>
      ) : (
        <Component {...props} routes={routes} />
      ),
    [Component, permissionsBypassForAdmin, permissons, routes],
  );
  return <RouteComponent path={path} render={render} />;
};

export default function Router() {
  return (
    <Switch>
      {routePages.map((value: RouteConfig) => (
        <RouteWithSubRoutes key={`${value.path}`} {...value} />
      ))}
    </Switch>
  );
}
