import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';
import { UpdateNodeDto } from '../../../../shared/models/ValidateAccountDto';
import { parseError } from '../../../../shared/utils/helpers';

export const validateAccount = createAsyncThunk<
  undefined,
  { id: string; nodeInfo: UpdateNodeDto },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('accounts/validateAccount', async ({ id, nodeInfo }, { rejectWithValue }) => {
  try {
    const encodedURI = encodeURIComponent(`${defaultRealm}`);

    await api.patch(`/realms/${encodedURI}/accounts/${id}`, nodeInfo);
    return undefined;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
