import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Label, Modal } from '../../../shared';
import Button from '../../../shared/components/Button';
import { colorList, Colors } from '../../../shared/constants/Colors';
import { CompanyRole } from '../../../shared/models';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { TagArea } from '../components';
import RoleSelect, { DataRow } from '../components/RoleSelect';
import {
  setModalInvitationSentOpen,
  setModalInvitationSentText,
  setModalInviteOpen,
} from '../store';
import { getInvitedMembers, getCompanyMembers, sendInvitaion } from '../store/actions';

type ModalInviteMemberForm = {
  emailList: string;
  role?: string;
};

const getSecurityGroupsList = (
  securityGroups: CompanyRole[],
  showAdminRole: boolean,
): Array<DataRow> => {
  const groups = showAdminRole
    ? securityGroups
    : securityGroups.filter((group) => group.name !== 'Admin');
  return groups.map((oneVal) => {
    const foundColor = colorList.find((oneColor) => oneColor.id === oneVal?.color);
    const dataRow: DataRow = {
      value: oneVal.name,
      color: (foundColor?.valueColor || colorList[0].valueColor) as Colors,
      uuid: oneVal.uuid,
    };

    return dataRow;
  });
};

const validationSchema = yup.object({
  emailList: yup
    .string()
    .test({
      name: 'is-valid-emails',
      test: (passVal, testContext) => {
        if (!passVal) {
          return true;
        }
        const emailRegexp =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        const separated = passVal?.split(',');
        let malformed: string[] = [];

        if (separated.length > 0) {
          malformed = passVal
            ?.split(',')
            .map((oneVal) => oneVal.trim())
            .filter((oneVal) => !emailRegexp.test(oneVal));
        }

        if (malformed.length === 0 && passVal && !emailRegexp.test(passVal)) {
          malformed = [passVal];
        }

        if (malformed.length > 0) {
          return testContext.createError({
            message: `Invalid email: ${malformed[0]}`,
            path: 'emailList',
          });
        }

        return true;
      },
    })
    .label('Emails')
    .required(),
  role: yup.string().label('Role').required(),
});

const errorMessage = (messageCode: string) => {
  switch (messageCode) {
    case 'WRONG_MEMBER_EMAIL_DOMAIN':
      return {
        emailList: 'Member email domain does not match company domain.',
      };
    case 'ALL_EMAILS_ALREADY_REGISTERED':
      return {
        emailList: 'User already exists.',
      };

    default:
      return {
        emailList: 'Unknown error. Please try again.',
      };
  }
};

type Props = {
  classes?: string;
  isUserCompanyAdmin: boolean;
  isUserSystemAdmin: boolean;
};

export default function ModalInviteMember({
  classes,
  isUserCompanyAdmin,
  isUserSystemAdmin,
}: Props) {
  const [loading, setIsLoading] = useState(false);
  const { modalInviteOpen: opened } = useAppSelector((state) => state.company);
  const { roles } = useAppSelector((state) => state.company);
  const dispatch = useAppDispatch();

  const rolesList = getSecurityGroupsList(roles, isUserCompanyAdmin || isUserSystemAdmin);

  const {
    errors,
    handleSubmit,
    values,
    touched,
    setFieldValue,
    resetForm,
    isValid,
    setErrors,
    setFieldTouched,
    setFieldError,
  } = useFormik<ModalInviteMemberForm>({
    initialValues: {
      emailList: '',
      role: '',
    },
    onSubmit: async ({ emailList, role }, { setErrors: setErrorsInSubmit }) => {
      setIsLoading(true);
      const mailsWithSchema = emailList.split(',').map((oneVal) => `mailto:${oneVal.trim()}`);
      const result = await dispatch(sendInvitaion({ emailsUris: mailsWithSchema, role }));

      if (sendInvitaion.fulfilled.match(result)) {
        Amplitude.logEvent(AmplitudeEvents.membersInvited, { numMembers: mailsWithSchema.length });

        setTimeout(() => {
          dispatch(getCompanyMembers());
          dispatch(getInvitedMembers());
        }, 2000);

        dispatch(
          setModalInvitationSentText(
            'We have sent an invitation email to your team members. You can check the status of your invitation on the Team Members page.',
          ),
        );
        dispatch(setModalInvitationSentOpen(true));
        dispatch(setModalInviteOpen(false));
      }
      if (sendInvitaion.rejected.match(result)) {
        if (result.payload?.message) {
          setErrorsInSubmit(errorMessage(result.payload?.message));
        } else {
          dispatch(addAlert({ text: 'Unknown error', type: AlertType.error }));
        }
      }
      setIsLoading(false);
    },
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    resetForm();
    setErrors({});
  }, [opened, resetForm, setErrors]);

  return (
    <Modal
      opened={opened}
      handleClose={() => {
        dispatch(setModalInviteOpen(false));
      }}
      classes={classes}
    >
      <div className="modal-content modal-content--mint-top">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalInviteOpen(false));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--hand">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/invite-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/invite-lv2@1x.webp 1x, images/dashboard/modals/invite-lv2@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/invite-lv2@1x.png"
              srcSet="images/dashboard/modals/invite-lv2@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="images/dashboard/modals/cuff.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Invite a team member</h2>
        <p className="modal-content__text">
          Send an invitation to your colleagues to work together in Zirtue.
        </p>
        {/* Form */}
        <form className="modal-content__form" action="#">
          {/* Form group */}
          <div className="form-group">
            <Label>Emails</Label>
            <TagArea
              id="emailList"
              placeholder="You can copy and paste a list of emails..."
              value={values.emailList}
              onChange={(newValue: string | null) => {
                setFieldTouched('emailList');
                setFieldValue('emailList', newValue);
              }}
              onFocus={() => {
                setFieldError('emailList', undefined);
              }}
              error={errors.emailList && touched.emailList ? errors.emailList : undefined}
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label>Role</Label>
            <RoleSelect
              id="role"
              placeholder="Select a role"
              data={rolesList}
              listPosition="relative"
              value={
                values.role ? rolesList.find((oneVal) => oneVal.uuid === values.role) : undefined
              }
              onChange={(newValue: string) => setFieldValue('role', newValue)}
              error={errors.role && touched.role ? errors.role : undefined}
            />
          </div>
          <Button
            className="button--primary-blue button--lg button--block"
            type="button"
            disabled={!isValid || loading}
            loading={loading}
            icon="plane"
            onClick={handleSubmit}
          >
            Send invitations
          </Button>
        </form>
      </div>
    </Modal>
  );
}
