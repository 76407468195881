import React from 'react';

import { Icon } from '../components';

const collection = [
  'logo',
  'logo-full',
  'attention',
  'eye-close',
  'eye-open',
  'check',
  'check-circle',
  'check-circle-solid',
  'chevron',
  'info',
  'speedometr',
  'users',
  'user',
  'credit-card',
  'terminal',
  'question-circle',
  'home',
  'power',
  'burger',
  'cross',
  'cross-heavy',
  'hand-finger',
  'lightning',
  'lightning-refund',
  'doc-refund',
  'pencil',
  'people',
  'quality-mark',
  'document-piece',
  'dollar-circle',
  'dollar-circle-small',
  'arrow-left',
  'arrow-up-left',
  'lightbulb',
  'trend',
  'copy',
  'double-arrow',
  'sorting-arrow-down',
  'sorting-arrow-up',
  'search',
  'upload',
  'arrow-right-circle',
  'plane',
  'dots',
  'plus',
  'plus-circle',
  'tag-close',
  'ghost',
  'visa-logo',
  'master-card-logo',
  'bank',
  'inbound',
  'star',
  'star-filled',
  'attention-square',
  'info-circle-solid',
  'trash',
  'clockwise',
  'attention-octagon',
  'image',
  'left-arrow',
  'form',
  'right-arrow',
  'filter',
  'webhooks',
  'api-keys',
  'file-transfer',
  'delete',
  'edit',
  'docs',
  'load',
  'refresh',
  'download',
  'arrow',
  'status-success',
  'status-error',
  'spinner',
  'guides',
];
export default function IconsCollection() {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {collection.map((icon) => (
        <div
          key={icon}
          style={{
            width: 60,
            height: 80,
            borderWidth: 1,
            borderColor: '#ccc',
            borderStyle: 'solid',
          }}
        >
          <div
            style={{
              width: 60,
              height: 60,
            }}
          >
            <Icon name={icon} />
          </div>
          <div style={{ fontSize: 12, textAlign: 'center' }}>{icon}</div>
        </div>
      ))}
    </div>
  );
}
