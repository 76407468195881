import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Input, Label, Modal } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { setModalSuccessOpen, addAlert, AlertType } from '../../../shared/store/modals';
import { hasRole } from '../../auth/utils';
import { setModalVerifyPaymentOpen } from '../store';
import { getPaymentMethods, validateAccount } from '../store/actions';

const validationSchema = yup.object({
  firstMicroDeposit: yup
    .number()
    .positive()
    .max(1)
    .typeError('you must specify a number')
    .label('First micro deposit')
    .required(),
  secondMicroDeposit: yup
    .number()
    .positive()
    .max(1)
    .typeError('you must specify a number')
    .label('Second micro deposit')
    .required(),
});

type Props = {
  classes?: string;
};

type VerifyPaymentMethodForm = {
  firstMicroDeposit: string;
  secondMicroDeposit: string;
};

const successTitle = 'Bank account verified!';
const successText =
  'Thank you for taking the time to verify, your bank account is now ready to use. ';

export default function ModalVerifyPaymentMethod({ classes }: Props) {
  const [attempts, setAttempts] = useState<number>(3);

  const {
    modalVerifyPaymentOpen: opened,
    modalVerifyFundingSource,
    loading,
  } = useAppSelector((state) => state.finances);
  const dispatch = useAppDispatch();
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));
  const contentText = `Check your bank statement (ending ••• ${modalVerifyFundingSource?.redactedAccountNumber}) to enter the two deposit amounts in a chronological order.`;

  const submitCallback = async (data: VerifyPaymentMethodForm) => {
    if (!data.firstMicroDeposit || !data.secondMicroDeposit || !modalVerifyFundingSource) {
      return;
    }
    const result = await dispatch(
      validateAccount({
        id: modalVerifyFundingSource.uuid,
        nodeInfo: {
          micro: [Number(data.firstMicroDeposit), Number(data.secondMicroDeposit)],
        },
      }),
    );

    if (validateAccount.fulfilled.match(result)) {
      dispatch(setModalVerifyPaymentOpen(false));

      dispatch(
        setModalSuccessOpen({
          title: successTitle,
          text: successText,
          opened: true,
        }),
      );

      if (!isAdminPanel) {
        Amplitude.logEvent(AmplitudeEvents.accountVerified);
      }

      setTimeout(() => dispatch(getPaymentMethods()), 2000);
    }

    if (validateAccount.rejected.match(result)) {
      setAttempts((prev) => prev - 1);
      dispatch(setModalVerifyPaymentOpen(false));

      if (result.payload) {
        dispatch(addAlert({ text: result.payload, type: AlertType.error }));
      }

      Amplitude.logEvent(AmplitudeEvents.bankAccountVerificationFailed);
    }
  };

  const {
    errors,
    handleChange,
    values,
    touched,
    handleSubmit,
    isValid,
    resetForm,
    validateForm,
    handleBlur,
    setFieldValue,
  } = useFormik<VerifyPaymentMethodForm>({
    initialValues: {
      firstMicroDeposit: '',
      secondMicroDeposit: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: submitCallback,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
  });

  useEffect(() => {
    resetForm();
    validateForm();
  }, [opened, resetForm, validateForm]);

  const handleDepositChange = (e: { target: { value: string; id: string } }) => {
    const { value, id } = e.target;
    const regExp = new RegExp(/^[0-9]+$/g);
    const valueNum = Number(value);

    if ((value.slice(0, 4) === '0.00' && valueNum) || value.split('.').pop()?.length === 1) {
      setFieldValue(id, `0.0${value.slice(-1)}`);
      return;
    }

    if (Number(value.slice(2, 4)) >= 10) {
      return;
    }

    if (Number(value.split('.').shift()) >= 1 && valueNum >= 1 && value.split('.').pop() === '00') {
      valueNum >= 10
        ? setFieldValue(id, `0.0${value.slice(0, 1)}`)
        : setFieldValue(id, `0.0${value.slice(1, 2)}`);

      return;
    }

    if (valueNum > 0) {
      setFieldValue(id, `0.${value.slice(-2)}`);
      return;
    }

    if (value === '.00') {
      setFieldValue(id, '0.00');
    }
  };

  const handleFocus = (e: { target: { id: string } }) => {
    const { id } = e.target;
    // @ts-ignore
    if (values[id] === '') {
      setFieldValue(id, '0.00');
    }
  };

  return (
    <Modal
      opened={opened}
      handleClose={() => {
        dispatch(setModalVerifyPaymentOpen(false));
      }}
      classes={classes}
    >
      <div className="modal-content modal-content--mint-top modal-content--verify-payment-method">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalVerifyPaymentOpen(false));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        <div className="modal-content__illustration modal-content__illustration--verify-payment-method">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/verify-payment-method-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/hand@1x.webp 1x, images/dashboard/modals/hand@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/hand@1x.png"
              srcSet="images/dashboard/modals/hand@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="images/dashboard/modals/cuff.svg"
            alt=""
          />
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv4"
            src="images/dashboard/modals/verify-payment-method-lv4.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Verify bank account</h2>
        <p className="modal-content__text">{contentText}</p>
        <div className="security-attempts">
          <p className="security-attempts__text">
            For security reasons, you will have 3 attempts to enter the deposit amounts correctly.
          </p>
          {attempts < 3 && (
            <span className="security-attempts__counter">
              <Icon name="clockwise" classes="security-attempts__counter-icon" />
              You have {attempts} {attempts === 1 ? 'attempt' : 'attempts'}
            </span>
          )}
        </div>
        {/* Form */}
        <form className="modal-content__form" action="#" onSubmit={handleSubmit}>
          {/* Form group */}
          <div className="form-group">
            <Label forId="firstMicroDeposit">First micro deposit</Label>
            <Input
              id="firstMicroDeposit"
              type="text"
              prependIconName="dollar-circle"
              placeholder="Enter first deposit"
              value={values.firstMicroDeposit}
              onChange={handleDepositChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={
                Boolean(errors.firstMicroDeposit) &&
                touched.firstMicroDeposit &&
                errors.firstMicroDeposit
              }
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="secondMicroDeposit">Second micro deposit</Label>
            <Input
              id="secondMicroDeposit"
              type="text"
              prependIconName="dollar-circle"
              placeholder="Enter second deposit"
              value={values.secondMicroDeposit}
              onChange={handleDepositChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={
                Boolean(errors.secondMicroDeposit) &&
                touched.secondMicroDeposit &&
                errors.secondMicroDeposit
              }
            />
          </div>
          <button
            type="submit"
            className="button button--primary-blue button--lg button--block"
            disabled={!isValid || loading || attempts <= 0}
          >
            <span>Confirm micro deposits</span>
          </button>
        </form>
      </div>
    </Modal>
  );
}
