import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import {
  PartnerMember,
  PartnerMemberDto,
  PartnerMemberModel,
} from '../../../../shared/models/PartnerMemberModel';
import { Amplitude } from '../../../../shared/services/amplitude';
import { parseError } from '../../../../shared/utils/helpers';

export const getSelf = createAsyncThunk<
  PartnerMember,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('user/getSelf', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<PartnerMemberDto>(`/members/self`);

    const response = PartnerMemberModel.fromDTO(data);

    Amplitude.setUser(response.uuid);
    return response;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
