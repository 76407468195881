import React from 'react';
import { Link } from 'react-router-dom';

export enum AuthHeaderContent {
  SignIn = 'signin',
  SignUp = 'signup',
}

type Props = {
  content: AuthHeaderContent;
};

const AuthHeader: React.FC<Props> = ({ content }) => (
  <div className="auth__header">
    {/* {content === 'signup' && (
        <>
          <p className="auth__header-text">
            Don&apos;t have a business account yet?
          </p>
          <Link to="/signup" className="button button--primary-blue button--sm">
            Sign up
          </Link>
        </>
      )} */}
    {content === AuthHeaderContent.SignIn && (
      <>
        <p className="auth__header-text">Already have a business account?</p>
        <Link to="/signin" className="button button--primary-blue button--sm">
          Sign in
        </Link>
      </>
    )}
  </div>
);

export default AuthHeader;
