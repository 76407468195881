import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { hasRole } from '../../../../features/auth/utils';
import { getPaymentMethods, validateAccount } from '../../../../features/finances/store/actions';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Permissions from '../../../constants/Permissions';
import { Amplitude, AmplitudeEvents } from '../../../services/amplitude';
import { AlertType, addAlert, setModalSuccessOpen } from '../../../store/modals';
import Button from '../../Button';
import Input from '../../Input';
import Label from '../../Label';
import useCheckoutList from '../hooks/useCheckoutList';
import useVerifyModal from '../hooks/useVerifyModal';
import { depositsSchemas as validationSchema } from '../schemas';

type FormValues = {
  firstMicroDeposit: string;
  secondMicroDeposit: string;
};

type ModalFormProps = Pick<ReturnType<typeof useVerifyModal>, 'setShowModal'> &
  Partial<Pick<ReturnType<typeof useCheckoutList>['operations'], 'checkItem'>>;

const ModalForm: FC<ModalFormProps> = ({ setShowModal, checkItem }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);

  const successTitle = 'Bank account verified!';
  const successText =
    'Thank you for taking the time to verify, your bank account is now ready to use.';

  const { modalVerifyFundingSource } = useAppSelector((state) => state.finances);

  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  const submitCallback = async (data: FormValues) => {
    if (!data.firstMicroDeposit || !data.secondMicroDeposit || !modalVerifyFundingSource) {
      return;
    }
    setLoading(true);
    try {
      const [validateAccResponse, checkItemResponse] = await Promise.all([
        dispatch(
          validateAccount({
            id: modalVerifyFundingSource.uuid,
            nodeInfo: {
              micro: [Number(data.firstMicroDeposit), Number(data.secondMicroDeposit)],
            },
          }),
        ),
        checkItem && checkItem('step4'),
      ]);

      if (
        validateAccount.fulfilled.match(validateAccResponse) &&
        checkItemResponse?.checkListState
      ) {
        setShowModal(false);
        dispatch(
          setModalSuccessOpen({
            title: successTitle,
            text: successText,
            opened: true,
          }),
        );

        if (!isAdminPanel) {
          Amplitude.logEvent(AmplitudeEvents.accountVerified);
        }

        setTimeout(() => dispatch(getPaymentMethods()), 200);
      }

      if (
        validateAccount.rejected.match(validateAccResponse) ||
        !checkItemResponse?.checkListState
      ) {
        setShowModal(false);
        if (validateAccResponse.payload) {
          dispatch(addAlert({ text: validateAccResponse.payload, type: AlertType.error }));
        }

        Amplitude.logEvent(AmplitudeEvents.bankAccountVerificationFailed);
      }
    } catch (error) {
      dispatch(addAlert({ text: 'Verification Error', type: AlertType.error }));
    } finally {
      setLoading(false);
    }
  };

  const {
    handleBlur,
    errors,
    values,
    touched,
    setFieldValue,
    submitForm,
    setSubmitting,
    setFieldTouched,
    handleSubmit,
  } = useFormik<FormValues>({
    initialValues: {
      firstMicroDeposit: '',
      secondMicroDeposit: '',
    },
    onSubmit: submitCallback,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
  });

  const handleDepositChange = (e: { target: { value: string; id: string } }) => {
    const { value, id } = e.target;
    const valueNum = Number(value);

    if ((value.slice(0, 4) === '0.00' && valueNum) || value.split('.').pop()?.length === 1) {
      setFieldValue(id, `0.0${value.slice(-1)}`);
      return;
    }

    if (Number(value.slice(2, 4)) >= 10) {
      return;
    }

    if (Number(value.split('.').shift()) >= 1 && valueNum >= 1 && value.split('.').pop() === '00') {
      valueNum >= 10
        ? setFieldValue(id, `0.0${value.slice(0, 1)}`)
        : setFieldValue(id, `0.0${value.slice(1, 2)}`);

      return;
    }

    if (valueNum > 0) {
      setFieldValue(id, `0.${value.slice(-2)}`);
      return;
    }

    if (value === '.00') {
      setFieldValue(id, '0.00');
    }
  };

  const handleFocus = (e: { target: { id: string } }) => {
    const { id } = e.target;
    // @ts-ignore
    if (values[id] === '') {
      setFieldValue(id, '0.00');
    }
  };

  return (
    <form className="payment-verify-modal__form" onSubmit={handleSubmit}>
      <div className="form-group">
        <Label forId="firstMicroDeposit">First micro deposit</Label>
        <Input
          id="firstMicroDeposit"
          type="text"
          prependIconName="dollar-circle"
          placeholder="Enter first deposit"
          value={values.firstMicroDeposit}
          onChange={handleDepositChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={
            Boolean(errors.firstMicroDeposit) &&
            touched.firstMicroDeposit &&
            errors.firstMicroDeposit
          }
        />
      </div>
      <div className="form-group">
        <Label forId="secondMicroDeposit">Second micro deposit</Label>
        <Input
          id="secondMicroDeposit"
          type="text"
          prependIconName="dollar-circle"
          placeholder="Enter second deposit"
          value={values.secondMicroDeposit}
          onChange={handleDepositChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={
            Boolean(errors.secondMicroDeposit) &&
            touched.secondMicroDeposit &&
            errors.secondMicroDeposit
          }
        />
      </div>
      <Button
        type="submit"
        className="button button--primary-blue button--lg button--block"
        icon="check"
        disabled={loading}
      >
        Verify micro-deposits
      </Button>
    </form>
  );
};

export default ModalForm;
