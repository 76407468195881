import { useState } from 'react';

import { Input, Label } from '../../../shared';
import { getFirstLetters } from '../helpers';
import { UseAccount } from '../hooks/useAccount';

import { AccountTitle } from '.';

type ProfileDetailsProps = Pick<UseAccount['properties'], 'partner' | 'role'>;

const ProfileDetails = ({ partner, role }: ProfileDetailsProps) => {
  const [rerender] = useState(1);

  return (
    <div className="profile-details">
      <AccountTitle text="You can upload or change your profile picture by clicking below.">
        Profile details
      </AccountTitle>
      <div>
        <div className="form-group mb-8">
          <Label forId="company-name">Company</Label>
          <Input
            type="text"
            id="company-name"
            placeholder="Business name"
            defaultValue={partner?.details.name}
            prependImageUrl={partner?.details.icons?.highRes}
            prependInitials={getFirstLetters(partner?.details.name || '')}
            disabled
            rerender={rerender}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="account-owner" infoText="Your role in current company">
            Your role
          </Label>
          <Input type="text" id="account-owner" defaultValue={role} disabled rerender={rerender} />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
