import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useLazyEffect } from '../../../shared';
import { adminPartnersSearch } from '../store/actions';
import { selectPartnersState } from '../store/selectors/partners';

export const usePartnersTableSearchEffect = () => {
  const dispatch = useAppDispatch();
  const { page, pageSize, searchQuery, sortField, sortOrder } = useAppSelector(selectPartnersState);

  useLazyEffect(
    () => {
      dispatch(adminPartnersSearch());
    },
    [page, pageSize, searchQuery, sortField, sortOrder],
    300,
  );
};
