import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import {
  InvitedMember,
  InvitedMemberModel,
  PartnerInvitedMembersDto,
} from '../../../../shared/models/PartnerInvitedMembersDto';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getInvitedMembers = createAsyncThunk<
  Array<InvitedMember>,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/getInvitedMembers', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<PartnerInvitedMembersDto>(`/invitations`);
    const response = data.invitedMembers.map((item) => InvitedMemberModel.fromDTO(item));

    return response;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
