import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { parseError } from '../../../../shared/utils/helpers';

export type accountRef = {
  realm: string;
  accountId: string;
};

export type LoanSubmissionRequest = {
  scheduleJwt: string;
  isLendLoan: boolean;
  lenderType: string;
  borrowerType: string;
  disbursementTarget?: {
    realm: string;
    accountId: string;
    billingAccountNumber?: string;
  };
  disbursementSource?: {
    realm: string;
    accountId: string;
  };
  repaymentSource?: {
    realm: string;
    accountId: string;
  };
  repaymentTarget?: {
    realm: string;
    accountId: string;
  };
  message?: string;
  reason?: string;
  relationship?: string;
  initialTargetName?: string;
  mediaUrl?: string;
  targetContactUri: string;
  isRepaymentRequest: boolean;
  isCashPickupEnabled: boolean;
  partnerDeeplinkId?: string;
};

export type LoanResponseDto = {
  uuid: string;
};

export const loanSubmissionApi = createAsyncThunk<
  LoanResponseDto,
  LoanSubmissionRequest,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('loans', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.put<LoanResponseDto>('/loans', payload);

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
