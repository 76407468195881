import { ORG_ADMIN, Permissions } from '../../../shared/constants/Permissions';
import { CompanyRole } from '../../../shared/models';

import type { PartnerMember } from '../../../shared/models';
import type { AuthTokenCreateResponseDto } from '../../../shared/models/AuthToken';

export const hasRole = (authTokenResponse: AuthTokenCreateResponseDto, role: string) => {
  const { orgPermissions } = authTokenResponse;
  return orgPermissions && orgPermissions.includes(role);
};

export const isAdmin = (authTokenResponse: AuthTokenCreateResponseDto) => {
  const { orgRoles } = authTokenResponse;
  return orgRoles && orgRoles.includes(ORG_ADMIN);
};

export const isCompanyAdmin = (uuid: string, companyRoles: CompanyRole[]) => {
  const adminRole = (companyRoles || []).find(({ name }) => name === 'Admin');
  return (adminRole?.members || []).includes(uuid);
};

export const isMemberAdmin = (member: PartnerMember) => {
  const { orgRoles } = member;
  return orgRoles && orgRoles.includes(ORG_ADMIN);
};

export const isAdminRole = (token: AuthTokenCreateResponseDto | undefined) => {
  if (token) {
    return (
      hasRole(token, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(token, Permissions.PLATFORM_ADMIN_VIEW)
    );
  }
  return false;
};

export const setCookie = <T>(property: string, value: T, domain: string) => {
  const stringifiedValue = JSON.stringify(value);

  document.cookie = `${property}=${stringifiedValue}; domain=.${domain}; path=/;`;
};

export const getCookie = (value: string) => {
  const cookies = document.cookie;
  const cookieArray = cookies.split(';');
  const storedValue = cookieArray.find((cookie) => cookie.trim().startsWith(`${value}=`));

  if (storedValue) {
    const tokenValue = storedValue.split('=')[1].trim();
    const tokenObject = JSON.parse(tokenValue);
    return tokenObject;
  }

  return null;
};

export const deleteCookie = (property: string) => {
  document.cookie = `${property}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
