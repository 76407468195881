import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import * as yup from 'yup';

import { Input, Label, Icon } from '../../../shared';
import Checkbox from '../../../shared/components/Checkbox';
import Switch from '../../../shared/components/Switch';
import CopyButton from '../../integrations/components/CopyButton';
import { getNormalizedData } from '../helpers/getNormalizedData';
import {
  EventEntity,
  EventKeyEnum,
  billTypeEvents,
  loanTypeEvents,
} from '../helpers/webhookSubEvents';

interface WebhookFormProps {
  onChange: (data: WebhookFormData) => void;
  setEditMode?: () => void;
  setTestMode?: () => void;
  onDelete?: () => void;
  title: string;
  subTitle: string;
  creationMode?: boolean;
  testMode?: boolean;
  hasDisable?: boolean;
  webhookKey?: string;
  cancelButtonName?: string;
  submitButtonName: string;
  webhook?: InitialFromValues;
}

const validationSchema = yup.object({
  name: yup.string().max(150).required('Subscription name is required'),
  enabled: yup.boolean(),
  url: yup.string().max(1500).url('Please enter a valid URL').required('URL is required field'),
  billEvents: yup.boolean(),
  disbursement_started: yup.boolean(),
  disbursement_completed: yup.boolean(),
  loanEvents: yup.boolean(),
  borrower_firstname: yup.boolean(),
  borrower_lastname: yup.boolean(),
  borrower_billing_account: yup.boolean(),
  loan_amount: yup.boolean(),
});

export interface WebhookFormData {
  events: EventEntity[];
  url: string;
  enabled: boolean;
  name: string;
  key?: string;
}

interface InitialValues {
  name: string;
  enabled: boolean;
  url: string;
  billEvents: boolean;
  loanEvents: boolean;
}

interface InitialEventKey {
  [EventKeyEnum.DISBURSEMENT_STARTED]: boolean;
  [EventKeyEnum.DISBURSEMENT_COMPLETED]: boolean;
  [EventKeyEnum.BORROWER_FIRSTNAME]: boolean;
  [EventKeyEnum.BORROWER_LASTNAME]: boolean;
  [EventKeyEnum.BORROWER_BILLING_ACCOUNT]: boolean;
  [EventKeyEnum.LOAN_AMOUNT]: boolean;
}

export type InitialFromValues = InitialValues & InitialEventKey;
const initialValues: InitialFromValues = {
  name: '',
  enabled: false,
  url: '',
  billEvents: false,
  [EventKeyEnum.DISBURSEMENT_STARTED]: false,
  [EventKeyEnum.DISBURSEMENT_COMPLETED]: false,
  loanEvents: false,
  [EventKeyEnum.BORROWER_FIRSTNAME]: true,
  [EventKeyEnum.BORROWER_LASTNAME]: true,
  [EventKeyEnum.BORROWER_BILLING_ACCOUNT]: true,
  [EventKeyEnum.LOAN_AMOUNT]: true,
};

export default function WebhookForm({
  onChange,
  setEditMode,
  setTestMode,
  onDelete,
  title,
  subTitle,
  creationMode = false,
  testMode = false,
  hasDisable = false,
  submitButtonName,
  cancelButtonName,
  webhook,
  webhookKey,
}: WebhookFormProps) {
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
    touched,
    values,
  } = useFormik({
    initialValues: webhook || initialValues,
    onSubmit: async (data) => {
      onChange(getNormalizedData(data, webhookKey));
    },
    validationSchema,
  });

  useEffect(() => {
    if (values.disbursement_started && values.disbursement_completed) {
      setFieldValue('billEvents', true);
    }
    if (!values.disbursement_started || !values.disbursement_completed) {
      setFieldValue('billEvents', false);
    }
  }, [values.disbursement_started, values.disbursement_completed]);

  useEffect(() => {
    if (
      values.borrower_firstname &&
      values.borrower_lastname &&
      values.borrower_billing_account &&
      values.loan_amount
    ) {
      setFieldValue('loanEvents', true);
    }
    if (
      !values.borrower_firstname ||
      !values.borrower_lastname ||
      !values.borrower_billing_account ||
      !values.loan_amount
    ) {
      setFieldValue('loanEvents', false);
    }
  }, [
    values.borrower_firstname,
    values.borrower_lastname,
    values.borrower_billing_account,
    values.loan_amount,
  ]);

  const isEvent = useMemo(
    () => values.disbursement_started || values.disbursement_completed,
    [values.disbursement_started, values.disbursement_completed],
  );

  const handleAllChildEvents = (name: string, value: boolean) => {
    switch (name) {
      case 'billEvents':
        setFieldValue('disbursement_started', value);
        setFieldValue('disbursement_completed', value);
        break;
      default:
        setFieldValue('borrower_firstname', value);
        setFieldValue('borrower_lastname', value);
        setFieldValue('borrower_billing_account', value);
        setFieldValue('loan_amount', value);
        break;
    }
  };

  const handleCancel = () => {
    if (setTestMode && webhook) {
      setTestMode();
      setFieldValue('name', webhook.name);
      setFieldValue('enabled', webhook.enabled);
      setFieldValue('url', webhook.url);
      setFieldValue('disbursement_started', webhook.disbursement_started);
      setFieldValue('disbursement_completed', webhook.disbursement_completed);
    }
  };

  return (
    <div className="dev-tools__creation card">
      <div className="dev-tools__wrap-title">
        <h3 className="dev-tools__title">{title}</h3>
        {!creationMode && (
          <div className="dev-tools__wrap-editBox">
            {setEditMode && testMode && (
              <button className="dev-tools__editButtons" type="button" onClick={setEditMode}>
                <Icon name="pencil" />
                Edit
              </button>
            )}

            <button className="dev-tools__editButtons" type="button" onClick={onDelete}>
              <Icon name="delete-icon" />
              Delete
            </button>
          </div>
        )}
      </div>
      <h4 className="dev-tools__subtitle">{subTitle}</h4>
      <form className="dev-tools__form" onSubmit={handleSubmit}>
        <div className="form-group">
          <Label classes="dev-tools__form-label" forId="name">
            Subscription name or description
          </Label>
          <Input
            type="text"
            id="name"
            value={values.name}
            placeholder="Enter webhook subscription name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name}
            disabled={hasDisable}
          />
        </div>
        <div className="form-group">
          <Label classes="dev-tools__form-label" forId="url">
            Callback URL
          </Label>
          <Input
            type="text"
            id="url"
            value={values.url}
            placeholder="Enter callback URL"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.url && errors.url}
            disabled={hasDisable}
          />
        </div>

        {!creationMode && (
          <>
            <div className="form-group">
              <Label classes="dev-tools__form-label" forId="key">
                <>
                  Webhook key
                  <CopyButton value={webhookKey || ''} hasText />
                </>
              </Label>
              <Input
                type="text"
                id="key"
                value={webhookKey}
                disabled
                classes="dev-tools__form-input"
              />
            </div>
          </>
        )}

        <div className="form-group">
          <Label classes="dev-tools__form-label dev-tools__form-label--status" forId="enabled">
            Subscription Status
          </Label>
          <Switch
            id="enabled"
            onChange={() => {
              setFieldValue('enabled', !values.enabled);
            }}
            checked={values.enabled}
            isShowText
            disabled={hasDisable}
          />
        </div>
        {testMode && (
          <div className="dev-tools__doc">
            <div className="dev-tools__doc-boxDesc">
              <p className="dev-tools__doc-title">Documentation</p>
              <p className="dev-tools__doc-text">
                A webhook subscription enables Zirtue to push real-time notifications to your
                account. Zirtue uses HTTPS to send these notifications as a JSON payload. To learn
                more, see our webhook events overview:
              </p>
            </div>
            <a
              href="https://docs.zirtue.com/docs/webhooks2#webhook-creation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="dev-tools__doc-btn">
                <Icon name="book-open" classes="dev-tools__doc-icon" />
                Read docs
              </button>
            </a>
          </div>
        )}

        {!hasDisable && (
          <section className="dev-tools__events">
            <div className="dev-tools__events-head">
              <div className="dev-tools__events-info">
                <div className="dev-tools__events-title">Subscription webhook events</div>
                <div className="dev-tools__events-txt">
                  Webhook notifications provide a reliable way for Zirtue to notify you of bill
                  payment events.
                </div>
              </div>
            </div>
            {
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label className="dev-tools__events-item">
                <Checkbox
                  name="billEvents"
                  onChange={(e) => {
                    handleChange(e);
                    handleAllChildEvents('billEvents', e.target.checked);
                  }}
                  checked={values.billEvents}
                />
                <div className="dev-tools__events-item-info">
                  <div className="dev-tools__events-item-title">Bill payment events</div>
                  <div className="dev-tools__events-item-txt">
                    Occurs when bill payments are active
                  </div>
                </div>
              </label>
            }
            <div className="dev-tools__events-subItem">
              {Object.keys(billTypeEvents).map((key: string) => {
                const value = values[key as keyof InitialEventKey];
                return (
                  <div key={key} className="dev-tools__events-subItem-wrap">
                    <Checkbox name={key} onChange={handleChange} checked={value} />
                    <p className="dev-tools__events-subItem-txt">{key}</p>
                  </div>
                );
              })}
            </div>
            <div className="dev-tools__events-item-info">
              <div className="dev-tools__events-item-title">Loan Data</div>
              <div className="dev-tools__events-item-txt">
                The following events will be dispatched in conjunction with the Bill Payment events.
              </div>
            </div>
            <div className="dev-tools__events-subItem dev-tools__events-subItem--no-margin">
              {Object.keys(loanTypeEvents).map((key: string) => (
                <div key={key} className="dev-tools__events-subItem-wrap">
                  <p className="dev-tools__events-subItem-txt">{key}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        <div className="line" />
        <div className="dev-tools__control">
          {cancelButtonName && (
            <button
              style={{ marginRight: '16px' }}
              type="button"
              className="button button--outline button--outline--no-border button--lg"
              onClick={handleCancel}
            >
              {cancelButtonName}
            </button>
          )}

          <button
            type="submit"
            className="button button--primary-blue button--lg"
            disabled={(!dirty || !isValid || !isEvent) && !testMode}
          >
            {submitButtonName}
          </button>
        </div>
      </form>
    </div>
  );
}
