import cn from 'classnames';
import { format } from 'date-fns';
import parsePhoneNumber from 'libphonenumber-js';
import numeral from 'numeral';
import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import {
  DateRangeFilter,
  Icon,
  Label,
  Search,
  Select,
  useLazyEffect,
  Tooltip,
  DateRangeTuple,
} from '../../../shared';
import TableCustomizeFilter from '../../../shared/components/TableCustomizeFilter';
import Timeline from '../../../shared/components/Timeline';
import Permissions from '../../../shared/constants/Permissions';
import { BillPayment, MonetaryValueDto, StatusPayment } from '../../../shared/models';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { getContact } from '../../../shared/utils';
import { FlowTypes } from '../../auth/store';
import { hasRole, isAdmin } from '../../auth/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import Notification from '../../loans/components/OverView/Notification';
import {
  BillPaymentsTableHead,
  TableAccountNumber,
  MemberNameFL,
  TableStatus,
  QuickActionsSelect,
} from '../components';
import RefundEventInfo from '../components/RefundEventInfo';
import { ModalRefund, ModalRefunded } from '../modals';
import { setSelectedBillPayment, deleteSelectedBillPayment } from '../store';
import { getBillPayments, exportPaymentsCSV } from '../store/actions';
import { getBillPaymentsFromApi } from '../store/actions/getBillPayments';

const getDate = (date: string) => format(new Date(date), 'MMM dd, yyyy');

const getPaymentDate = (date: string) => format(new Date(date), 'MMM dd, yyyy hh:mm:ss a');

const isValidDate = (d?: string) => {
  if (d === undefined || d === null) {
    return false;
  }
  const parsed = Date.parse(d);

  return !Number.isNaN(parsed);
};
export interface RefundInfo {
  customerName: string;
  billingAccountNumber: string;
  transactionId: string;
  amount: MonetaryValueDto;
}

const mockedPayments = [
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '1',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Rachel Green',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '2',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Phoebe Buffay',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '3',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Chandler Bing',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '4',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Joey Tribbiani',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '5',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Ross Geller',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '6',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Richard Burke',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '7',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Jill Green',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
  {
    ledger: [
      {
        eventId: 'ec9ce077-2a69-4618-afc7-18d437ab980b',
        timestamp: '2024-04-10T05:16:01.019Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'started',
      },
      {
        eventId: '6c14b90f-1895-4685-9f06-67b8b5363824',
        timestamp: '2024-04-10T05:16:08.682Z',
        amount: {
          value: 1000,
          currency: 'USD',
        },
        type: 'succeeded',
      },
    ],
    refundHistory: [],
    uuid: '8',
    createdAt: '2024-04-10T05:16:01.019Z',
    initiatedAt: '2024-04-10T05:16:01.019Z',
    paymentAt: '2024-04-10T05:16:08.682Z',
    biller: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      accountId: 'b58e1cf627b443bcb0204535aaeaf7ae',
    },
    origin: {
      entity: 'loan',
      id: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
      accountNumber: '000123456789',
    },
    payer: {
      id: 'd62d2add-3432-4a17-b895-935efc03a195',
      name: 'Susan Bunch',
      phoneNumber: '+1 214 463 9292',
      email: 'customer@gmail.com',
    },
    amount: {
      value: 1000,
      currency: 'USD',
    },
    status: 'succeeded',
    transactionId: '6a029b2e-cb02-422f-a9b6-bc6b2bff361b',
    batchingStatus: 'unknown',
    preset: {
      partnerId: 'b98bbfe3-daf2-4c9c-9a50-9bea9ea9203a',
      presetId: 'fc186e8f-3285-4db7-9b97-17a6f593abe6',
      feeEnabled: true,
      feeAmountPercentage: 3,
      partnerComissionEnabled: false,
      partnerComissionPercentageAmount: 0,
      skipKYC: true,
    },
    updatedAt: '2024-04-10T05:16:08.682Z',
  },
];

const BillPayments: React.FC = () => {
  const dispatch = useAppDispatch();

  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const {
    billPayments,
    totalBillPayments,
    billPaymentsSettings,
    selectedBillPayment,
    isDeclineFlowBillPaymentsSettings,
  } = useAppSelector((state) => state.payments);
  const flowType = useAppSelector((state) => state.auth.partner?.details.flowType);

  const isAffiliateFlow = flowType === FlowTypes.AFFILIATE_PARTNER;
  const isDeclineFlow = flowType === FlowTypes.DECLINE_FLOW;
  const visibleCols = isDeclineFlow
    ? isDeclineFlowBillPaymentsSettings.filter(
        (el) =>
          (isAffiliateFlow ? el.checked && el.id !== 'accountNumber' : el.checked) &&
          el.id !== 'view',
      )
    : billPaymentsSettings.filter(
        (el) =>
          (isAffiliateFlow ? el.checked && el.id !== 'accountNumber' : el.checked) &&
          el.id !== 'view',
      );

  const canViewPayments =
    authInfo && (hasRole(authInfo, Permissions.BILL_PAYMENTS_VIEW) || isAdmin(authInfo));

  const totalRefundAmount =
    /* eslint-disable no-param-reassign */
    selectedBillPayment?.refundHistory.reduce<number>((acc, item) => {
      if (item.outcome && !item.outcome.failure) {
        acc += item.amount.value;
      }

      if (item.outcome && item.outcome.failure) {
        acc -= item.amount.value;
      }

      return acc;
    }, 0) || 0;

  const canRefund =
    authInfo && (hasRole(authInfo, Permissions.CUSTOMERS_REFUND) || isAdmin(authInfo));

  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  const [countRow, setCountRow] = useState<string>('8');
  const [skip, setSkip] = useState<number>(0);
  const [nbrPage, setNbrPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('initiatedAt');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [searchText, setSearchText] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('all');
  const [periodTime, setPeriodTime] = useState<string[]>([]);
  const [csvData, setCSVData] = useState('');
  const [refundInfo, setRefundInfo] = useState<RefundInfo>();
  const [hasAnyBillPayments, setHasAnyBillPayments] = useState<boolean>(false);
  const [isBillPaymentsLoading, setIsBillPaymentsLoading] = useState<boolean>(false);

  const handleResetTableFilter = () => {
    if (canRefund) {
      // dispatch(disableBillPaymentsSettings({ id: 'reimbursement', isEnable: true }));
    }
  };

  useEffect(() => {
    if (!isAdminPanel) {
      Amplitude.logEvent(AmplitudeEvents.sessionOpened);
    }

    handleResetTableFilter();
    return () => {
      if (!isAdminPanel) {
        Amplitude.logEvent(AmplitudeEvents.sessionClosed);
      }
    };
  }, []);

  const getPayments = () => {
    dispatch(
      getBillPayments({
        searchText,
        status: paymentStatus,
        limit: +countRow,
        skip,
        periodTime: periodTime.length ? periodTime : undefined,
        sortBy,
        sortOrder,
      }),
    );

    if (searchText && !isAdminPanel) {
      Amplitude.logEvent(AmplitudeEvents.paymentsSearched, { text: searchText });
    }
  };

  const totalPages = Math.ceil(totalBillPayments / +countRow) || 1;

  const changePage = (nbr: number) => {
    let nextNbr: number = nbr;
    if (Number.isNaN(nbr)) nextNbr = 1;
    if (nbr <= 1) nextNbr = 1;
    if (nbr >= totalPages) nextNbr = totalPages;

    setNbrPage(nextNbr);
    setSkip((nextNbr - 1) * +countRow);
  };

  const changeCountRow = (cnt: number) => {
    changePage(1);
    setCountRow(`${cnt}`);
  };

  const handleExportCSV = async () => {
    const response = await dispatch(
      exportPaymentsCSV({
        searchText,
        status: paymentStatus,
        limit: +countRow,
        skip,
        periodTime,
        sortBy,
        sortOrder,
      }),
    );

    if (exportPaymentsCSV.fulfilled.match(response)) {
      setCSVData(response.payload);
      Amplitude.logEvent(AmplitudeEvents.refundExported);
    }
  };

  const handleDateFilter = (value: DateRangeTuple) => {
    changePage(1);
    setPeriodTime(value);
  };

  const handleSelectBillPayment = (id: string) => {
    if (id === selectedBillPayment?.uuid) {
      dispatch(deleteSelectedBillPayment(null));
      return;
    }

    const selected = billPayments.find((el) => el.uuid === id);

    if (selected) {
      const { payer, origin, transactionId, amount } = selected;

      setRefundInfo({
        customerName: payer.name,
        billingAccountNumber: origin.accountNumber,
        transactionId,
        amount,
      });
      Amplitude.logEvent(AmplitudeEvents.refundStarted, {
        paymentAmount: amount.value,
        accountNumber: origin.accountNumber,
      });
    }

    dispatch(setSelectedBillPayment(selected));
  };

  const isShowColumn = (id: string) => visibleCols.find((el) => el.id === id);

  const payments = selectedBillPayment ? [selectedBillPayment] : billPayments;

  // @ts-ignore
  useEffect(() => {
    const fetchPayments = async () => {
      setIsBillPaymentsLoading(true);
      try {
        const { data } = await getBillPaymentsFromApi({
          searchText,
          status: paymentStatus,
          limit: +countRow,
          skip,
          sortBy,
          sortOrder: -1,
        });

        if (data.totalPayments === 0) {
          setHasAnyBillPayments(true);
        } else {
          setHasAnyBillPayments(false);
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsBillPaymentsLoading(false);
      }
    };

    fetchPayments();
  }, []);

  useLazyEffect(
    () => {
      getPayments();
    },
    [countRow, skip, sortBy, sortOrder, searchText, paymentStatus, periodTime],
    1000,
  );

  useEffect(() => {
    if (!isAdminPanel) {
      Amplitude.logEvent(AmplitudeEvents.sessionOpened);
    }

    handleResetTableFilter();

    return () => {
      if (!isAdminPanel) {
        Amplitude.logEvent(AmplitudeEvents.sessionClosed);
      }
    };
  }, [isAdminPanel]);

  const renderTableContent = () => {
    if (isBillPaymentsLoading) {
      return (
        <div className="loading-placeholder">
          <div>Loading data, please wait...</div>
        </div>
      );
    }

    if (hasAnyBillPayments && (!isAffiliateFlow || !isDeclineFlow)) {
      return (
        <div className="table-scroll">
          <div>
            <table className="table">
              <BillPaymentsTableHead
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                sortBy={sortBy}
                sortOrder={sortOrder}
                columns={visibleCols}
              />
              <tbody className={cn('table__body')}>
                {mockedPayments.map(
                  ({
                    uuid,
                    payer,
                    amount,
                    status,
                    initiatedAt,
                    origin,
                    batchingStatus,
                  }: BillPayment) => {
                    const instantiatedAtStr = isValidDate(initiatedAt)
                      ? getDate(initiatedAt || '')
                      : '';

                    const statusPayment =
                      batchingStatus && batchingStatus === StatusPayment.SUCCEEDED
                        ? StatusPayment.COMPLETED
                        : status;

                    return (
                      <tr className="table__data-row" key={uuid}>
                        {isShowColumn('customerName') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            <MemberNameFL name={payer.name} color="green" />
                          </td>
                        )}

                        {isShowColumn('accountNumber') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            <TableAccountNumber accountNumber={origin.accountNumber} />
                          </td>
                        )}

                        {isShowColumn('paymentAmount') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            <span
                              className={cn('table-highlight', {
                                'table-highlight__failed-amount': status === StatusPayment.FAILED,
                              })}
                            >
                              <Icon name="dollar-circle-small" classes="table-highlight__icon" />
                              {numeral(amount.value).format('0,000.00')}
                            </span>
                          </td>
                        )}

                        {isShowColumn('initiatedAt') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            {instantiatedAtStr}
                          </td>
                        )}

                        {isShowColumn('phoneNumber') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            {parsePhoneNumber(getContact(payer.phoneNumber))?.formatInternational()}
                          </td>
                        )}

                        {isShowColumn('email') && (
                          <td
                            className="table__cell table__cell--data table__cell--billPaynemts table__cell--email"
                            style={{ width: '14.3%' }}
                          >
                            <Tooltip text={getContact(payer.email)}>
                              <span>{getContact(payer.email)}</span>
                            </Tooltip>
                          </td>
                        )}

                        {isShowColumn('paymentStatus') && (
                          <td className="table__cell table__cell--data table__cell--billPaynemts">
                            <TableStatus type={statusPayment} />
                          </td>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (billPayments.length === 0) {
      return (
        <div className="table__no-data">
          <Icon name="warning-triangle" classes="no-data__icon" />
          <div className="table__no-data__title">No data to show</div>
          <div className="table__no-data__text">
            There are no available records to
            <br />
            display for the selected date range.
          </div>
        </div>
      );
    }

    if (isAffiliateFlow || isDeclineFlow) {
      return (
        <div className="table-scroll table-scroll--with-fixed-col">
          <div className="table-scroll__box">
            <table className="table">
              <tbody className="table__body">
                <BillPaymentsTableHead
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  canRefund={!!canRefund}
                  columns={visibleCols}
                />
                {payments.map(
                  ({
                    uuid,
                    payer,
                    amount,
                    status,
                    initiatedAt,
                    paymentAt,
                    origin,
                    transactionId,
                    batchingStatus,
                  }: BillPayment) => {
                    const paymentAtStr = isValidDate(paymentAt)
                      ? getPaymentDate(paymentAt || '')
                      : '';

                    const instantiatedAtStr = isValidDate(initiatedAt)
                      ? getDate(initiatedAt || '')
                      : '';

                    const statusPayment =
                      batchingStatus && batchingStatus === StatusPayment.SUCCEEDED
                        ? StatusPayment.COMPLETED
                        : status;

                    return (
                      <tr className="table__data-row" key={uuid}>
                        {isShowColumn('customerName') && (
                          <td className="table__cell table__cell--data table__cell--fixed">
                            <MemberNameFL name={payer.name} color="green" />
                          </td>
                        )}
                        {isShowColumn('accountNumber') && (
                          <td className="table__cell table__cell--data">
                            <TableAccountNumber accountNumber={origin.accountNumber} />
                          </td>
                        )}
                        {isShowColumn('dateOfBirth') && (
                          <td className="table__cell table__cell--data">&nbsp;</td>
                        )}
                        {isShowColumn('address') && (
                          <td className="table__cell table__cell--data">&nbsp;</td>
                        )}
                        {isShowColumn('phoneNumber') && (
                          <td className="table__cell table__cell--data">
                            {/* {formatPhoneNumber(getContact(payer.phoneNumber))} */}
                            {parsePhoneNumber(getContact(payer.phoneNumber))?.formatInternational()}
                          </td>
                        )}
                        {isShowColumn('email') && (
                          <td className="table__cell table__cell--data table__cell--email">
                            <Tooltip text={getContact(payer.email)}>
                              <span>{getContact(payer.email)}</span>
                            </Tooltip>
                          </td>
                        )}
                        {isShowColumn('requestApproved') && (
                          <td className="table__cell table__cell--data">{instantiatedAtStr}</td>
                        )}
                        {isShowColumn('transactionId') && (
                          <td className="table__cell table__cell--data">{transactionId}</td>
                        )}
                        {isShowColumn('paymentAmount') && (
                          <td className="table__cell table__cell--data">
                            <span
                              className={cn('table-highlight', {
                                'table-highlight__failed-amount': status === StatusPayment.FAILED,
                              })}
                            >
                              <Icon name="dollar-circle-small" classes="table-highlight__icon" />
                              {numeral(amount.value).format('0,000.00')}
                            </span>
                          </td>
                        )}
                        {isShowColumn('paymentStatus') && (
                          <td className="table__cell table__cell--data">
                            <TableStatus type={statusPayment} />
                          </td>
                        )}
                        {isShowColumn('paymentDate') && (
                          <td className="table__cell table__cell--data">{paymentAtStr}</td>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return (
      <div className="table-scroll">
        <div>
          <table
            className="table"
            style={{ display: billPayments.length === 0 ? 'block' : 'table' }}
          >
            <BillPaymentsTableHead
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              sortBy={sortBy}
              sortOrder={sortOrder}
              columns={visibleCols}
              headerRowClassname={billPayments.length === 0 ? 'table__header-row--flex' : ''}
            />
            <tbody
              className={cn('table__body', billPayments.length === 0 ? 'table__body__isEmpty' : '')}
            >
              {payments.map(
                ({
                  uuid,
                  payer,
                  amount,
                  status,
                  initiatedAt,
                  origin,
                  batchingStatus,
                }: BillPayment) => {
                  const instantiatedAtStr = isValidDate(initiatedAt)
                    ? getDate(initiatedAt || '')
                    : '';

                  const statusPayment =
                    batchingStatus && batchingStatus === StatusPayment.SUCCEEDED
                      ? StatusPayment.COMPLETED
                      : status;

                  return (
                    <tr className="table__data-row" key={uuid}>
                      {isShowColumn('customerName') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          <MemberNameFL name={payer.name} color="green" />
                        </td>
                      )}

                      {isShowColumn('accountNumber') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          <TableAccountNumber accountNumber={origin.accountNumber} />
                        </td>
                      )}

                      {isShowColumn('paymentAmount') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          <span
                            className={cn('table-highlight', {
                              'table-highlight__failed-amount': status === StatusPayment.FAILED,
                            })}
                          >
                            <Icon name="dollar-circle-small" classes="table-highlight__icon" />
                            {numeral(amount.value).format('0,000.00')}
                          </span>
                        </td>
                      )}

                      {isShowColumn('initiatedAt') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          {instantiatedAtStr}
                        </td>
                      )}

                      {isShowColumn('phoneNumber') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          {parsePhoneNumber(getContact(payer.phoneNumber))?.formatInternational()}
                        </td>
                      )}

                      {isShowColumn('email') && (
                        <td
                          className="table__cell table__cell--data table__cell--billPaynemts table__cell--email"
                          style={{ width: '14.3%' }}
                        >
                          <Tooltip text={getContact(payer.email)}>
                            <span>{getContact(payer.email)}</span>
                          </Tooltip>
                        </td>
                      )}

                      {isShowColumn('paymentStatus') && (
                        <td className="table__cell table__cell--data table__cell--billPaynemts">
                          <TableStatus type={statusPayment} />
                        </td>
                      )}
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout pageTitle={canViewPayments ? 'Payments' : ''}>
      {canViewPayments && (
        <>
          <h2 className="main-title">Insights</h2>

          <div className="customers">
            {hasAnyBillPayments && (!isAffiliateFlow || !isDeclineFlow) ? <Notification /> : ''}
            <div className="table-filter">
              <div className="table-filter__col table-filter__col--select">
                <Select
                  classes="table-filter__payment select--no-margin"
                  id="filter-payment"
                  data={[
                    { name: 'Loan status (All)', dataId: 'all' },
                    { name: 'Payment processed', dataId: 'succeeded' },
                    { name: 'Payment pending', dataId: 'started' },
                    { name: 'Payment failed', dataId: 'failed' },
                    { name: 'Submitted', dataId: 'refund_requested' },
                  ]}
                  value={paymentStatus}
                  totalItems={totalBillPayments}
                  onChange={(val: string) => {
                    setPaymentStatus(val);
                    changePage(1);
                  }}
                />
              </div>
              <div className="table-filter__col table-filter__col--search">
                <Search
                  id="filter-search"
                  classes="table-filter__search"
                  placeholder="Search for a name, account number or email address"
                  value={searchText}
                  onChange={(value: string) => {
                    setSearchText(value);
                    changePage(1);
                  }}
                />
              </div>
              <div className="table-filter__col">
                <DateRangeFilter
                  classes="table-filter__date-filter"
                  onChangePeriod={(value) => handleDateFilter(value)}
                />
              </div>
              <div className="table-filter__col">
                <TableCustomizeFilter onReset={handleResetTableFilter} />
              </div>
              <div className="table-filter__col">
                <QuickActionsSelect
                  data={csvData}
                  text="Quick actions"
                  onExportCSV={handleExportCSV}
                />
              </div>
            </div>
            {/* Table */}
            {renderTableContent()}
            {/* Pagination */}
            {!selectedBillPayment && (
              <div className="table-pagination">
                <div className="table-pagination__col">
                  <Label forId="per-page" classes="table-pagination__per-page-label">
                    Rows per page
                  </Label>
                  <Select
                    classes="select--no-margin table-pagination__per-page-select"
                    id="per-page"
                    data={[
                      { dataId: '8', name: '8' },
                      { dataId: '16', name: '16' },
                      { dataId: '32', name: '32' },
                      { dataId: '64', name: '64' },
                    ]}
                    value={countRow}
                    onChange={changeCountRow}
                  />
                </div>
                <div className="table-pagination__col table-pagination__col--page-nav">
                  <button
                    type="button"
                    className="table-pagination__button table-pagination__button--prev"
                    disabled={nbrPage <= 1}
                    onClick={() => changePage(nbrPage - 1)}
                  >
                    <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
                  </button>
                  <div className="table-pagination__center">
                    <input
                      type="text"
                      className="input table-pagination__input"
                      value={nbrPage}
                      onChange={(e) => changePage(+e.target.value)}
                    />
                    <span className="table-pagination__text">{`of ${totalPages}`}</span>
                  </div>
                  <button
                    type="button"
                    className="table-pagination__button table-pagination__button--next"
                    onClick={() => changePage(nbrPage + 1)}
                    disabled={nbrPage >= totalPages}
                  >
                    <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
                  </button>
                </div>
              </div>
            )}

            {selectedBillPayment && (
              <>
                <Tabs className="tabs tabs--payments">
                  <TabList className="tabs__list">
                    <Tab
                      className="tabs__item"
                      selectedClassName="tabs__item--selected"
                      onClick={() => Amplitude.logEvent(AmplitudeEvents.transactionHistoryOpened)}
                    >
                      Transaction history
                    </Tab>
                    <Tab
                      className="tabs__item"
                      selectedClassName="tabs__item--selected"
                      onClick={() => Amplitude.logEvent(AmplitudeEvents.refundHistoryOpened)}
                    >
                      Refund history
                    </Tab>
                  </TabList>
                  <TabPanel className="tabs__panel">
                    <div className="card">Transaction history not found</div>
                  </TabPanel>
                  <TabPanel className="tabs__panel">
                    {selectedBillPayment && (
                      <div className="row">
                        <div className="col customers__col">
                          <div className="card">
                            <h2 className="card__title">Transaction timeline</h2>
                            <Timeline />
                          </div>
                        </div>
                        {refundInfo && <RefundEventInfo data={refundInfo} />}
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </>
            )}
          </div>
        </>
      )}
      <ModalRefund
        pageSettings={{
          searchText,
          status: paymentStatus,
          limit: +countRow,
          skip,
          periodTime,
          sortBy,
          sortOrder,
        }}
        totalRefundAmount={totalRefundAmount}
      />
      <ModalRefunded />
    </DashboardLayout>
  );
};

export default BillPayments;
