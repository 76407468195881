import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { PartnerMember } from '../../../../shared/models';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const updCompanyRole = createAsyncThunk<
  undefined,
  { uuid: string; name?: string; color?: string; permissions?: string[]; members: PartnerMember[] },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/updRole', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.put(`/security/groups/${payload.uuid}`, payload);

    dispatch(addAlert({ text: 'Save changes successfully' }));

    return undefined;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
