import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch } from '../../../main/store/hooks';
import { Label, Input, Alert } from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { AuthBannerContent } from '../components/AuthBanner';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { forgotPasswordStart } from '../store/actions';

const validationSchema = yup.object({
  email: yup.string().email().label('Partner Member Email').required(),
});

type FormValues = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const onSubmit = useCallback(
    async ({ email }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      const result = await dispatch(forgotPasswordStart({ email }));

      if (forgotPasswordStart.fulfilled.match(result)) {
        localStorage.setItem(StoreKeys.email, result.payload.email);
        push('/check-email');
      }

      if (forgotPasswordStart.rejected.match(result)) {
        if (result.payload?.message) {
          setErrors({ email: result.payload?.message });
        } else {
          dispatch(addAlert({ text: 'Unknown error', type: AlertType.error }));
        }
      }
    },
    [dispatch, push],
  );

  const { errors, handleChange, handleSubmit, handleBlur, values, touched, dirty, isValid } =
    useFormik<FormValues>({
      initialValues: {
        email: '',
      },
      onSubmit,
      validationSchema,
    });

  return (
    <AuthorizationLayout bannerContent={AuthBannerContent.ForgotPassword}>
      <form action="#" className="auth-form auth-form--styled" onSubmit={handleSubmit}>
        <h1 className="auth-form__title">Forgot password</h1>
        <p className="auth-form__text">
          Enter the email address you used to&nbsp;sign&nbsp;up, and we’ll send you an email
          with&nbsp;instructions to reset your password.
        </p>

        <div className="form-group">
          <Label forId="email">Email</Label>
          <Input
            type="email"
            id="email"
            placeholder="Enter your email address"
            error={
              Boolean(errors.email) && touched.email && 'Oops! This e-mail seems to be incorrect.'
            }
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <button
          data-testid="submit"
          type="submit"
          className="button button--primary-blue button--lg button-block"
          disabled={!dirty || !isValid}
        >
          Send me a reset link
        </button>
      </form>
    </AuthorizationLayout>
  );
};

export default ForgotPassword;
