import React from 'react';

import { LoanStatus, StatusInTable, StatusPayment } from '../../../shared/models';
import { LoanStage } from '../../loans/dtos/LoanTypes';
import { getLoanStatus, getLoanStatusFromStage } from '../../loans/helpers/getLoanStatus';

type Props = {
  type: string;
  marker?: string;
  classes?: string;
  stage?: LoanStage;
};

const getType = (type: string) => {
  switch (type) {
    case StatusPayment.STARTED:
      return StatusInTable.PENDING;
    case StatusPayment.SUCCEEDED:
    case StatusPayment.REFUND_REQUESTED:
      return StatusInTable.PROCESSED;
    case StatusPayment.FAILED:
      return StatusInTable.FAILED;
    case StatusPayment.COMPLETED:
      return StatusInTable.COMPLETED;
    default:
      return type;
  }
};

const getTypeHandler = (type: string, marker: string, stage?: LoanStage) => {
  switch (marker) {
    case 'marker':
      return getType(type);
    case 'loan':
      if (stage) {
        return getLoanStatusFromStage(stage);
      }
      return getLoanStatus(type as LoanStatus);
    default:
      return getType(type);
  }
};

const TableStatus: React.FC<Props> = ({ type, classes, marker = 'marker', stage }: Props) => {
  const renderType = getTypeHandler(type, marker, stage);

  return (
    <div className={`table-status ${classes || ''}`}>
      <div
        className={`table-status__${marker} table-status__${marker}--${renderType
          .replaceAll(' ', '-')
          .toLocaleLowerCase()}`}
      />
      {renderType}
    </div>
  );
};

export default TableStatus;
