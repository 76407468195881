interface OverViewList {
  title: string;
  listType?: string;
  borderTop?: boolean;
  items: Array<{
    text: string;
    icon?: string;
    tooltipTitle?: string;
    tooltipText?: string;
  }>;
}

export const overViewList: Array<OverViewList> = [
  {
    title: 'What you need to know',
    items: [
      {
        text: 'The setup is straightforward with minimal input required from your side.',
      },
      {
        text: "By sharing this data, you can enhance the end-user's onboarding and loan creation process with Zirtue.",
      },
      {
        text: 'It also reduces payment errors, ensures the user has the correct billing account number, and prevents payments from going to the wrong account.',
      },
      {
        text: 'To start streaming data to the Zirtue app via SFTP, you need to first set up your account, review the SFTP configurations such as hostname and port number, and enable reporting.',
      },
    ],
  },
  {
    title: 'What data am I required to provide?',
    items: [
      {
        text: 'Account_number',
        icon: 'icon',
        tooltipTitle: 'Account number',
        tooltipText:
          'Use the account or invoice number, whichever is directly associated with the payment. This will be used for account validation and to identify the payment in the remittance file provided at the time of payment.',
      },
      {
        text: 'First_name',
      },
      {
        text: 'Last_name',
      },
    ],
  },
  {
    title: 'What’s next',
    listType: 'numeric',
    borderTop: true,
    items: [
      {
        text: 'Setup your account',
      },
      {
        text: 'Review the SFTP configurations',
      },
      {
        text: 'Upload files',
      },
    ],
  },
];
