import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { CompanyUpdateData } from '../../../../shared';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const updateCompany = createAsyncThunk<
  undefined,
  CompanyUpdateData,
  { dispatch: AppDispatch; state: RootState }
>('company/updateCompany', async (payload, { rejectWithValue }) => {
  try {
    const { file, ...rest } = payload;

    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    Object.keys(rest).forEach((k: string) => {
      // @ts-ignore
      formData.append(k, rest[k]);
    });

    await api.put(`/partners`, formData);

    return undefined;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
