import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FundingSource } from '../../../shared/models/FundingSource';

import { addAchNode, getPaymentMethods, validateAccount } from './actions';

export type PaymentItem = {
  name: string;
  ending: string;
  expires: string;
  type: string;
  isVerified: boolean;
};

type FinancesSliceState = {
  paymentMethods: FundingSource[];
  loading: boolean;
  modalAddPaymentOpen: boolean;
  modalVerifyPaymentOpen: boolean;
  modalVerifyFundingSource?: FundingSource;
};

const initialState: FinancesSliceState = {
  paymentMethods: [],
  loading: true,
  modalAddPaymentOpen: false,
  modalVerifyPaymentOpen: false,
};

const financesSlice = createSlice({
  name: 'finances',
  initialState: initialState as FinancesSliceState,
  reducers: {
    setPaymentMethods: (state, action: PayloadAction<FundingSource[]>) => {
      state.paymentMethods = action.payload;
    },
    setModalAddPaymentOpen: (state, action: PayloadAction<boolean>) => {
      state.modalAddPaymentOpen = action.payload;
    },
    setModalVerifyPaymentOpen: (state, action: PayloadAction<boolean>) => {
      state.modalVerifyPaymentOpen = action.payload;
    },
    setModalVerifyFundingSource: (state, action: PayloadAction<FundingSource>) => {
      state.modalVerifyFundingSource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
      state.loading = false;
    });
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethods.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(validateAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(validateAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(validateAccount.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addAchNode.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addAchNode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAchNode.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default financesSlice;
export const {
  setPaymentMethods,
  setModalAddPaymentOpen,
  setModalVerifyPaymentOpen,
  setModalVerifyFundingSource,
} = financesSlice.actions;
