import React, { useState, useRef, useEffect } from 'react';

import Tag from './Tag';

type Props = {
  id: string;
  placeholder: string;
  classes?: string;
  onChange?: (value: string | null) => void;
  value: string;
  error?: string;
  onFocus?: () => void;
};

export default function TagArea({
  id,
  placeholder,
  classes,
  onChange,
  value,
  error,
  onFocus,
}: Props) {
  const areaRef = useRef<HTMLTextAreaElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [valueList, setValueList] = useState<string[]>([]);
  const [active, setActive] = useState(false);
  const [empty, setEmpty] = useState(true);
  // useEffect(() => {}, [active]);
  function handleFocus() {
    setActive(true);
    if (areaRef.current) {
      areaRef.current.value = valueList.join(', ');
      areaRef.current.style.height = '5px';
      areaRef.current.style.height = `${areaRef.current.scrollHeight}px`;
      if (listRef.current) {
        listRef.current.style.height = '175px';
      }
    }

    if (onFocus) {
      onFocus();
    }
  }
  function handleBlur() {
    setActive(false);
    if (areaRef.current && areaRef.current.value.length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
      if (areaRef.current && listRef.current) {
        listRef.current.style.height = '';
        areaRef.current.style.height = `${listRef.current.scrollHeight}px`;
      }
    }

    if (onChange) {
      onChange(valueList.join(', '));
    }
  }
  function handleChange() {
    let newList: Array<string> = [];

    if (areaRef.current && areaRef.current.value.length) {
      newList = areaRef.current.value
        .replace(/,\s*$/, '')
        .split(/,|\n/)
        .map((v) => v.trim())
        .filter((v) => v !== '');
    }

    setValueList(newList);
  }
  function handleInput() {
    if (areaRef.current) {
      areaRef.current.style.height = '5px';
      areaRef.current.style.height = `${areaRef.current.scrollHeight}px`;
    }
  }
  function handleTagClose(index: number) {
    const newList = valueList.filter((v, i) => i !== index);
    setValueList(newList);
    if (onChange) {
      onChange(newList.join(', '));
    }
  }

  useEffect(() => {
    if (value && value.length) {
      setValueList(
        value
          .replace(/,\s*$/, '')
          .split(/,|\n/)
          .map((v) => v.trim())
          .filter((v) => v !== ''),
      );
    } else {
      setValueList([]);
    }
  }, [value, setValueList]);

  return (
    <>
      <div
        className={`tag-area 
        ${active ? ' tag-area--active' : ''}
        ${empty ? ' tag-area--empty' : ''}
        ${error ? ' tag-area--error' : ''}
        ${classes || ''}
      `}
      >
        <div className="tag-area__box">
          <div className="tag-area__content">
            <textarea
              ref={areaRef}
              id={id}
              className="tag-area__textarea"
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              onInput={handleInput}
            />
            <ul ref={listRef} className="tag-area__list">
              {valueList.map((v, i) => (
                <Tag
                  key={i.toString()}
                  value={v}
                  onClose={() => {
                    handleTagClose(i);
                  }}
                  classes="tag-area__item"
                />
              ))}
            </ul>
          </div>
        </div>
        {error && <span className="tag-area__error-text">{error}</span>}
      </div>
    </>
  );
}
