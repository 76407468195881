import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { ServerError } from '../../../../shared/types';

export const forgotPasswordStart = createAsyncThunk<
  { email: string },
  { email: string },
  { dispatch: AppDispatch; rejectValue: ServerError; state: RootState }
>('auth/forgotPasswordStart', async (payload, { rejectWithValue }) => {
  try {
    const { email } = payload;
    await api.post('/auth/forgot-password/start', {
      emailUri: `mailto:${email}`,
    });

    return { email };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
