import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { DateFilter, DateRangeFilter, DateRangeTuple, Search, Select } from '../../../../shared';
import { DataItem } from '../../../../shared/components/Select';
import {
  UserRoleString,
  UsersSearchDateRangeField,
} from '../../../../shared/models/platform-admin';
import { UsersOnboardingType } from '../../../../shared/models/platform-admin/users/UsersOnboardingType';
import { UsersVerificationStatus } from '../../../../shared/models/platform-admin/users/UsersVerificationStatus';
import {
  setUsersDateFilterField,
  setUsersRolesFilter,
  setTableSearchQuery,
  setTableDateFilter,
  setUserOnboardingType,
  setUserVerificationStatus,
  setTableDateRangeSettings,
} from '../../store';
import { selectUsersState } from '../../store/selectors/users';

const ROLES: DataItem[] = Object.values(UserRoleString).map<DataItem>((role) => ({
  name: role,
  dataId: role,
}));

const DATE_RANGE_FIELDS: DataItem[] = Object.values(UsersSearchDateRangeField).map<DataItem>(
  (fieldName) => ({
    name: fieldName,
    dataId: fieldName,
  }),
);

const ONBOARDING_TYPES: DataItem[] = [
  { name: 'None', dataId: '' },
  ...Object.values(UsersOnboardingType).map<DataItem>((type) => ({
    name: type,
    dataId: type,
  })),
];

const VERIFICATION_STATUS: DataItem[] = [
  { name: 'None', dataId: '' },
  ...Object.values(UsersVerificationStatus).map<DataItem>((fieldName) => ({
    name: fieldName,
    dataId: fieldName,
  })),
];

export const UsersTableControls: FC = () => {
  const [isRolesDisabled, setIsRolesDisabled] = useState(false);
  const [roles, setRoles] = useState('');
  const dispatch = useAppDispatch();
  const {
    searchQuery,
    rolesFilter,
    dateRangeFilter,
    dateRangeFilterField,
    dateRangeSettings,
    onboardType,
    verificationStatus,
  } = useAppSelector(selectUsersState);

  const onSearchChange = (val: string) =>
    dispatch(setTableSearchQuery({ tableName: 'users', query: val }));

  const onRoleChange = (val: string) => {
    setRoles(val);
    const roles = val.split(',');
    // @ts-ignore
    dispatch(setUsersRolesFilter(val ? roles : null));
  };

  const onDateFilterChange = (val: DateRangeTuple) =>
    dispatch(setTableDateFilter({ range: val, tableName: 'users' }));

  const onDateFilterFieldChange = (val: UsersSearchDateRangeField) =>
    dispatch(setUsersDateFilterField(val));

  const onOnboardingTypeChange = (val: UsersOnboardingType) => {
    dispatch(setUserOnboardingType(val));
  };

  const onVerificationStatusChange = (val: UsersVerificationStatus) => {
    if (!val && roles) {
      onRoleChange(roles);
    }
    setIsRolesDisabled(Boolean(val));
    dispatch(setUserVerificationStatus(val));
  };

  useEffect(() => {
    if (verificationStatus) {
      setIsRolesDisabled(true);
    }
  }, []);

  return (
    <div className="table-filter">
      <div className="table-filter__col table-filter__col--search">
        <Search
          id="filter-search"
          classes="table-filter__search"
          placeholder="Search users"
          value={searchQuery}
          onChange={onSearchChange}
        />
      </div>

      <div className="table-filter__col">
        <Select
          classes="table-filter__payment select--no-margin"
          id="filter-payment"
          data={ROLES}
          placeholder="Filter by Role"
          value={rolesFilter || null}
          onChange={onRoleChange}
          multiSelect
          disabled={isRolesDisabled}
        />
      </div>
      <div className="table-filter__col">
        <DateRangeFilter
          onChangePeriod={onDateFilterChange}
          dateRangeFilter={dateRangeFilter}
          settings={dateRangeSettings}
          onChangeRangeSettings={(settings) =>
            dispatch(
              setTableDateRangeSettings({
                tableName: 'users',
                dateRangeSettings: settings,
              }),
            )
          }
        />
      </div>
      <div className="table-filter__col">
        <Select
          classes="table-filter__payment select--no-margin"
          id="filter-payment"
          data={DATE_RANGE_FIELDS}
          value={dateRangeFilterField || null}
          onChange={onDateFilterFieldChange}
        />
      </div>
      <div className="table-filter__col">
        <Select
          classes="table-filter__payment select--no-margin"
          id="onboarding-type"
          data={ONBOARDING_TYPES}
          placeholder="Onboarding Filters"
          value={onboardType}
          onChange={onOnboardingTypeChange}
        />
      </div>
      <div className="table-filter__col">
        <Select
          classes="table-filter__payment select--no-margin"
          id="verification-status"
          data={VERIFICATION_STATUS}
          placeholder="Verification Filters"
          value={verificationStatus}
          onChange={onVerificationStatusChange}
          // disabled={!rolesFilter}
        />
      </div>
    </div>
  );
};
