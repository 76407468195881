import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';

import { useAppDispatch } from '../../../../main/store/hooks';
import { Input, MaskInput } from '../../../../shared';
import { FormWrapper } from '../../../init-setup/components';
import { setPhone } from '../../store';

type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

type FormicDataTypes = {
  values: FormData;
  errors: FormikErrors<FormData>;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
};

export default function SelectBorrowerForm({
  errors,
  handleBlur,
  touched,
  values,
  setFieldValue,
}: FormicDataTypes) {
  const dispatch = useAppDispatch();

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.id, e.target.value);
  };

  return (
    <FormWrapper>
      <div className="loans__selectBorrowerForm">
        <div className="loans__selectBorrowerForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">Who are you lending to?</h2>
            <div className="loanTypeForm__subtitle">Enter the borrower’s contact info.</div>
          </div>
          <div className="loans__selectBorrowerForm__input-groups">
            <div className="form-group">
              <div className="loans__selectBorrowerForm__input-label">First name</div>
              <Input
                type="text"
                id="firstName"
                placeholder="First name"
                value={values.firstName}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                error={Boolean(errors.firstName) && touched.firstName && errors.firstName}
              />
            </div>
            <div className="form-group">
              <div className="loans__selectBorrowerForm__input-label">Last name</div>
              <Input
                type="text"
                id="lastName"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                error={Boolean(errors.lastName) && touched.lastName && errors.lastName}
              />
            </div>
            <div className="form-group">
              <div className="form-group__row form-group__row--phone-code profile-edit__phone-group">
                <div className="form-group__row-item">
                  <div className="loans__selectBorrowerForm__input-label">Phone number</div>
                  <MaskInput
                    mask="(999) 999 - 9999"
                    id="phone"
                    inputClassName="input input-group__input"
                    placeholder="(XXX) XXX - XXXX"
                    value={values.phone}
                    onChange={(e) => {
                      // @ts-ignore
                      handleFieldChange(e);
                      // @ts-ignore
                      dispatch(setPhone(e.target.value));
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.phone) && touched.phone && errors.phone}
                    haveFlag
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="loans__selectBorrowerForm__input-label">Email address</div>
              <Input
                type="text"
                id="email"
                value={values.email}
                defaultValue={values.email}
                placeholder="Contact’s email address"
                onChange={handleFieldChange}
                onBlur={handleBlur}
                error={Boolean(errors.email) && touched.email && errors.email}
              />
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
