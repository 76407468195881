import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoanResponse } from '..';
import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export type LoansSearchQueryDto = {
  total?: number;
  skip?: number;
  limit?: number;
  searchText?: string;
  dateStart?: string;
  dateEnd?: string;
};

export interface LoansSearchQuery {
  searchText: string;
  stage: string;
  reason: string;
  limit: number;
  skip: number;
  sortField: string;
  sortOrder: 'desc' | 'asc';
  periodTime: string[];
}

const orderEnum = {
  desc: 'DESC',
  asc: 'ASC',
};

export const getLoans = createAsyncThunk<
  LoanResponse,
  LoansSearchQuery,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('getLoans', async (payload, { rejectWithValue }) => {
  try {
    const { searchText, stage, limit, skip, periodTime, sortField, sortOrder, reason } = payload;
    const { data } = await api.get<LoanResponse>('/loans/search', {
      params: <LoansSearchQueryDto>{
        searchText,
        dateStart: periodTime[0],
        dateEnd: periodTime[1],
        limit,
        stage,
        reason: reason.toLocaleLowerCase(),
        skip,
        sortField,
        sortOrder: orderEnum[sortOrder],
      },
    });

    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
