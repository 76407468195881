export enum FundingSourceAllowance {
  credit = 'CREDIT',
  creditAndDebit = 'CREDIT-AND-DEBIT',
  depositOnly = 'DEPOSIT_ONLY',
  verified = 'VERIFIED',
}

export enum FundingSourceRail {
  ACH = 'ACH',
  INT = 'INT',
  RPPS = 'RPPS',
  SYN = 'SYN',
  CARDS = 'cards',
}

export enum Realms {
  synapse = 'synapse-keyset00',
  checkbook = 'checkbook-keyset00',
  fiserv = 'fiserv-keyset00',
  moneyGram = 'moneygram-keyset00',
}

export type FundingSourceDTO = {
  uuid: string;
  realm?: string; // 'synapse-keyset00'
  rail: FundingSourceRail;
  institution?: string;
  iconUrl: string; // 'https://example.com/icons/bank-logo.png'
  redactedAccountNumber: string; // '2981'
  allowance: FundingSourceAllowance;
  billingDetails?: {
    accountNumberSchema: string;
    iconUrl: string;
    publicName: string;
  };
  expiration: string;
  verificationFlow: 'microdeposits' | 'plaid';
  isActive?: boolean;
  routingNumber?: string;
  owner?: string;
};

export type FundingSource = FundingSourceDTO;

export class FundingSourceModel {
  public static fromDTO(dto: FundingSourceDTO): FundingSource {
    return {
      iconUrl: dto.iconUrl,
      institution: dto.institution,
      rail: dto.rail,
      realm: dto.realm,
      redactedAccountNumber: dto.redactedAccountNumber,
      uuid: dto.uuid,
      allowance: dto.allowance,
      billingDetails: dto.billingDetails,
      expiration: dto.expiration,
      verificationFlow: dto.verificationFlow,
      isActive: dto.isActive,
    };
  }

  public static fromDTOArray(dto: Array<FundingSourceDTO>): Array<FundingSource> {
    return dto.map((item) => FundingSourceModel.fromDTO(item));
  }

  public static isVerified = (fundingSource: FundingSourceDTO): boolean =>
    !(
      fundingSource.rail === FundingSourceRail.ACH &&
      fundingSource.allowance === FundingSourceAllowance.depositOnly
    );
}
