import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../../main/store/hooks';

const useAccount = () => {
  const { partner, user } = useAppSelector(
    (state) => ({ ...state.auth, ...state.company }),
    shallowEqual,
  );
  const [role, setRole] = useState('');

  const { roles } = useAppSelector((state) => state.company);

  useEffect(() => {
    if (user && roles.length > 0 && user.securityGroups.length > 0) {
      const userRole = roles.find((role) => user.securityGroups[0] === role.uuid);
      userRole && setRole(userRole.name);
    }

    if (user && user.orgRoles.includes('ORG_ADMIN')) {
      setRole('Admin');
    }
  }, [user, roles]);

  return {
    properties: { role, partner },
    operations: {},
  };
};

export default useAccount;

export type UseAccount = ReturnType<typeof useAccount>;
