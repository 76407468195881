import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { shallowEqual } from 'react-redux';

import { FlowTypes, setCurrentStepSetup } from '../../../../features/auth/store';
import { getPartner } from '../../../../features/auth/store/actions';
import { completeOnboarding } from '../../../../features/auth/store/actions/completeOnboarding';
import { api } from '../../../../main/network';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { defaultRealm } from '../../../constants/DefaultRealm';
import { AlertType, addAlert } from '../../../store/modals';
import { PlaidRequestBody } from '../PlaidButton';

type LinkTokenDta = {
  linkToken: string;
  expiratin: string;
};

type usePlaidButtonProps = {
  requestBody: PlaidRequestBody;
  next: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  companyAddres?: string;
};

const usePlaidButton = ({
  requestBody,
  loading,
  next,
  setLoading,
  companyAddres,
}: usePlaidButtonProps) => {
  const businessName = useAppSelector((state) => state.auth.partner?.details.name, shallowEqual);
  const { selectedFlow } = useAppSelector((state) => state.auth);

  const [linkToken, setLinkToken] = useState('');
  const dispatch = useAppDispatch();

  const updatePartner = async (data: { postalAddress: string }) => {
    try {
      await api.put('/partners', data);
      dispatch(getPartner());
      return true;
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Error while creating account.',
          type: AlertType.error,
        }),
      );
    } finally {
      setLoading(false);
    }
    return false;
  };

  async function determineFlow(flowType: keyof typeof FlowTypes) {
    if (companyAddres) await updatePartner({ postalAddress: companyAddres });
    const response = await dispatch(completeOnboarding({ flowType }));
    if (completeOnboarding.fulfilled.match(response)) {
      next();
      dispatch(setCurrentStepSetup(4));
    }

    if (completeOnboarding.rejected.match(response)) {
      dispatch(
        addAlert({
          text:
            response.payload ||
            'There is an error occurring when attempting to select the flowtype.',
          type: AlertType.error,
        }),
      );
    }
  }

  const exchangePublickToken = async (data: PlaidRequestBody) => {
    setLoading(true);
    try {
      const encodedURI = encodeURIComponent(`${defaultRealm}`);
      const response = await api.put(`/realms/${encodedURI}/accounts`, data);
      if (response.data) {
        determineFlow(selectedFlow);
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: 'There is an error occurring when adding bank account',
          type: AlertType.error,
        }),
      );
    }
    setLoading(false);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (publicToken, metaData) => {
      const requestData: PlaidRequestBody = {
        ...requestBody,
        publicToken,
        accounts: metaData.accounts,
        businessName,
      };
      exchangePublickToken(requestData);
    },
  });

  const getLinkToken = async () => {
    try {
      const response = await api.post<LinkTokenDta>('/plaid/link-token');
      setLinkToken(response.data.linkToken);
    } catch (error) {
      dispatch(
        addAlert({
          text: 'There is an error occurring when adding banc account',
          type: AlertType.error,
        }),
      );
    }
  };

  useEffect(() => {
    getLinkToken();
  }, []);

  return {
    operations: {
      open,
    },
    properties: {},
  };
};

export default usePlaidButton;
