import newsPreviewOne from '../assets/news-preview-1.svg';
import newsPreviewTwo from '../assets/news-preview-2.svg';
import newsPreviewThree from '../assets/news-preview-3.svg';
import newsPreviewFour from '../assets/news-preview-4.svg';
import newsPreviewFive from '../assets/news-preview-5.svg';

export type NewsStoryType = {
  title: string;
  description: string;
  link: string;
  preview: string;
  id: string;
};

export const newsStories: NewsStoryType[] = [
  {
    id: 'news-story-1',
    preview: newsPreviewOne,
    title: 'WFAA: Shaping DFW Presented by Cadillac',
    link: 'https://www.wfaa.com/video/news/local/outreach/shaping-dfw/shaping-dfw-dennis-cail-of-zirtue/287-e810d92d-ecd6-4f3f-88c3-b750ec42cef6',
    description:
      "Bullzerk's Dan Bradley is putting Dallas on the map… well, he’s putting Dallas on a shirt, and selling lots of them. Hear how Bullzerk continues to make Cool Dallas Sh**t",
  },
  {
    id: 'news-story-2',
    preview: newsPreviewTwo,
    title: "Real Simple: Zirtue Presented with Real Simple's Smart Money Award",
    link: 'https://www.realsimple.com/smart-money-awards-2023-7560855',
    description:
      "Bullzerk's Dan Bradley is putting Dallas on the map… well, he’s putting Dallas on a shirt, and selling lots of them. Hear how Bullzerk continues to make Cool Dallas Sh**t.",
  },
  {
    id: 'news-story-3',
    preview: newsPreviewThree,
    title: 'Inc. 5 Black Founders Working to Make the Financial System More Equitable',
    link: 'https://www.inc.com/gabrielle-bienasz/black-founders-financial-equity-wealth-gap.html',
    description:
      'Black Americans face barriers in the financial system, from buying a home to building credit. Kelly Ifill, founder of Guava, was featured by ...',
  },
  {
    id: 'news-story-4',
    preview: newsPreviewFour,
    title: 'Dallas Innovates: Zirtue and TruStage Debut Unique Loan Insurance Partnership',
    link: 'https://dallasinnovates.com/dallas-lending-app-debuts-unique-loan-insurance-a-safety-net-for-p2p-lenders/',
    description:
      'TruStage formerly was known as CUNA Mutual Group. Zirtue said that via the collaboration, the peer-to-peer lending platform will help protect ...',
  },
  {
    id: 'news-story-5',
    preview: newsPreviewFive,
    title: 'Dallas Business Journal: Moneygram and Zirtue Partner to Drive Financial Inclusion',
    link: 'https://www.bizjournals.com/dallas/news/2023/06/21/moneygram-zirtue.html',
    description:
      "Two Dallas fintechs have joined forces to extend their reach among unbanked and underbanked borrowers. Zirtue, a startup for relationship-based lending, said Wednesday that it's partnering with money-transfer powerhouse MoneyGram International to enable these consumers to borrow funds from their loved ones.",
  },
];
