export type AuthTokenCreateResponseDto = {
  accessToken: string;
  refreshToken: string;
  expireDate: string;
  useId?: string;
  orgRoles: Array<string>;
  scopes: Array<string>;
  userRoles: Array<string>;
  orgPermissions: Array<string>;
  orgId: string;
  orgDraft?: boolean;
};

export type AuthTokenCreateResponse = AuthTokenCreateResponseDto;

export class AuthTokenCreateModel {
  public static fromDTO(dto: AuthTokenCreateResponseDto): AuthTokenCreateResponse {
    return {
      ...dto,
    };
  }
}
