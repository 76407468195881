import cn from 'classnames';
import { getMonth, getYear, format, parse, isValid } from 'date-fns';
import range from 'lodash/range';
import React, { RefObject, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import { Icon } from '../../../../shared';

interface IProps {
  loanContainerRef: RefObject<HTMLDivElement>;
  id: string;
  disabled?: boolean;
  classes?: string;
  onChange: (newValue: string) => void;
  value: string;
  dateMask?: string;
  minDate: Date;
  maxDate: Date;
  iconName?: string;
  useCurrentYearAsStart?: boolean;
}

const RepaymentDateInput: React.FC<IProps> = ({
  loanContainerRef,
  id,
  disabled,
  classes,
  onChange,
  value,
  dateMask = 'MM/dd/yyyy',
  minDate,
  maxDate,
  iconName,
  useCurrentYearAsStart = false,
}: IProps) => {
  const [startDate, setStartDate] = useState<Date | null>(
    value ? parse(value, dateMask, new Date()) : null,
  );
  const [error, setError] = useState('');
  const [inputDate, setInputDate] = useState<string>(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const [endYear, setEndYear] = useState<number>(getYear(new Date()));

  const calculateYearsRange = () => {
    if (useCurrentYearAsStart && minDate) {
      const startYear = getYear(minDate);
      return range(startYear, endYear + 1, 1);
    }
    const currentYear = getYear(new Date());
    const startYear = useCurrentYearAsStart ? currentYear : 1800;
    return range(startYear, currentYear + 1, 1);
  };

  const years = calculateYearsRange();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const CustomCalendarHeader = ({
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) => (
    <div className="react-datepicker__header-nav">
      <span className="react-datepicker__header-month">{months[getMonth(date)]}</span>
      <select
        value={getYear(date)}
        onChange={({ target: { value: newVal } }) => changeYear(Number(newVal))}
        className="react-datepicker__header-year-select"
      >
        {years.map((option) => (
          <option key={option.toString()} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="react-datepicker__header-btn-box">
        <button
          type="button"
          className="react-datepicker__month-nav-btn react-datepicker__month-nav-btn--prev"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Icon name="chevron" classes="react-datepicker__month-nav-icon" />
        </button>
        <button
          type="button"
          className="react-datepicker__month-nav-btn react-datepicker__month-nav-btn--next"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Icon name="chevron" classes="react-datepicker__month-nav-icon" />
        </button>
      </div>
    </div>
  );

  const handleChange = (date: Date) => {
    setStartDate(date);
    const formattedDate = format(date, dateMask);
    setInputDate(formattedDate);
    setIsOpen(false);
    setError('');
    onChange(formattedDate);
  };

  const handleBlur = () => {
    setIsOpen(false);
    if (!inputDate?.match(/^\d{2}\/\d{2}\/\d{4}$/) || !isValid(startDate)) {
      setInputDate(value);
      if (value) setStartDate(parse(value, dateMask, new Date()));
      onChange(value);
    } else {
      setError('');
    }
  };

  useEffect(() => {
    if (value) {
      setInputDate(value);
      setStartDate(new Date(value));
    }
  }, [value]);

  useEffect(() => {
    if (useCurrentYearAsStart && minDate) {
      setEndYear(getYear(maxDate));
    }
  }, [minDate, useCurrentYearAsStart]);

  useEffect(() => {
    if (isOpen && loanContainerRef.current) {
      loanContainerRef.current.scrollTo({
        top: loanContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [isOpen, loanContainerRef]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const popperElement = document.querySelector('.react-datepicker-popper');
      const tabLoopElement = document.querySelector('.react-datepicker__tab-loop');

      if (popperElement && tabLoopElement) {
        if (popperElement.getAttribute('data-placement') === 'top-start') {
          // @ts-ignore
          tabLoopElement.style.position = 'fixed';
        } else {
          // @ts-ignore
          tabLoopElement.style.position = '';
        }
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={`select input-group ${isOpen ? 'select--opened' : ''} ${
        disabled ? 'select--disabled' : ''
      }
        ${error ? 'select--error' : ''}`}
    >
      <div className="select__box input-group__input-box">
        <DatePicker
          className="input select__input input-group__input"
          selected={startDate}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={dateMask}
          disabled={disabled}
          open={isOpen}
          onCalendarClose={() => setIsOpen(false)}
          onChange={handleChange}
          onFocus={() => {
            setIsOpen(true);
          }}
          onBlur={handleBlur}
          renderCustomHeader={CustomCalendarHeader}
          customInput={
            <InputMask
              mask="99/99/9999"
              // @ts-ignore
              maskChar={null}
              value={inputDate}
              onBlur={handleBlur}
              className="input select__input"
              placeholder="MM/dd/yyyy"
              disabled={disabled}
            />
          }
          fixedHeight
        />
        <div className={cn('select__chevron', iconName ? 'select__calendar' : '')}>
          <Icon name={iconName || 'chevron'} classes="select__chevron-icon" />
        </div>
      </div>
      {error && <span className="input-group__error-text">{error}</span>}
    </div>
  );
};

export default RepaymentDateInput;
