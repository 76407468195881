import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { BillPayment, BillPaymentDto, BillPaymentModel } from '../../../../shared/models';
import { PaymentsPageSettings } from '../../../../shared/models/PaymentMethodModel';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

const orderEnum = {
  desc: -1,
  asc: 1,
};

export const getAllBillPayments = createAsyncThunk<
  { allBillPayments: Array<BillPayment>; allTotalBillPayments: number },
  PaymentsPageSettings,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('dashboard/getAllBillPayments', async (payload, { rejectWithValue }) => {
  const { searchText, status, limit, skip, periodTime, sortBy, sortOrder } = payload;
  try {
    const { data } = await api.get<{ payments: Array<BillPaymentDto>; totalPayments: number }>(
      `/bill-payments`,
      {
        params: {
          skip,
          limit,
          searchText,
          statuses: status === 'all' ? [] : [status],
          initiatedFrom: periodTime ? periodTime[0] : undefined,
          initiatedTo: periodTime ? periodTime[1] : undefined,
          sortBy,
          sortOrder: orderEnum[sortOrder],
          showAll: true,
        },
      },
    );

    const response = data.payments.map((item) => BillPaymentModel.fromDTO(item));

    return { allBillPayments: response, allTotalBillPayments: data.totalPayments };
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
