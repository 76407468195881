import { useCallback, useState, useEffect } from 'react';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Modal, ModalConfirm, ModalSuccess } from '../../../shared';
import Button from '../../../shared/components/Button';
import ModalRejected from '../../../shared/components/ModalRejected';
import { setModalConfirmOpen } from '../../../shared/store/modals';
import { download } from '../../../shared/utils/helpers';
import FileUploadArea from '../components/FileUploadArea';
import FileUploadItem from '../components/FileUploadItem';
import DevToolsLayout from '../layout/DevToolsLayout';
import { setLoadingSftpFiles } from '../store';
import { uploadSftpFiles } from '../store/actions/uploadSftpFiles';

export default function FileUpload() {
  const { filesLoading, uploadedSftpFiles } = useAppSelector((state: RootState) => state.devTools);
  const dispatch = useAppDispatch();

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setFile(null);
  }, [uploadedSftpFiles]);

  const handleChangeAcceptedFiles = useCallback((fileProp: File) => {
    setFile(fileProp);
  }, []);

  const handleRejectedFiles = useCallback(() => {
    if (file) {
      dispatch(uploadSftpFiles(file));
    }
  }, [file, dispatch]);

  const handleUploadFiles = () => {
    dispatch(
      setModalConfirmOpen({
        opened: true,
        title: 'Ready to submit?',
        text: 'Please take a moment to review the file and ensure that it is in the correct format',
        okText: 'Submit',
        cancelText: 'Return to upload',
        onConfirm: handleRejectedFiles,
      }),
    );
  };

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">File upload</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">File upload</h2>

      <div className="validation-account card">
        <h3 className="upload-file__title">Example file</h3>
        <h4 className="upload-file__subtitle">Download this .csv file to get started</h4>
        <Button
          type="button"
          className="button button--outline-gray button--lg validationPreview__download-btn"
          icon="file-csv"
          onClick={() => {
            download('/files/account_validation.csv', 'account_validation.csv');
          }}
        >
          Download file
        </Button>

        <h3 className="upload-file__title">Upload files</h3>
        <h4 className="upload-file__subtitle">You can upload the .csv format up 50MB</h4>

        <FileUploadArea
          title="Select or drag and drop your file here."
          onChangeAcceptedFiles={handleChangeAcceptedFiles}
        />

        {(file || !!uploadedSftpFiles.length) && (
          <h3 className="upload-file__title">Recently uploaded</h3>
        )}

        {file && <FileUploadItem key={file.name} type="csv" file={file} />}

        {uploadedSftpFiles.map((entity) => (
          <FileUploadItem key={entity.name} type="csv" file={entity} showUploadedIcon />
        ))}

        <div
          style={{ marginTop: 14, paddingTop: 14, borderTop: '1px solid #f0f1f5' }}
          className="dev-tools__control"
        >
          <button
            style={{ width: '254px', fontSize: 16 }}
            type="submit"
            className="button button--primary-blue button--lg"
            onClick={handleUploadFiles}
            disabled={!file}
          >
            Submit upload
          </button>
        </div>
      </div>
      <ModalConfirm />
      <Modal
        handleClose={() => dispatch(setLoadingSftpFiles(false))}
        opened={filesLoading}
        classes="modal--lg"
      >
        <div
          className="modal-content"
          style={{
            height: 460,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
          }}
        >
          <button
            type="button"
            className="modal-content__close"
            onClick={() => {
              dispatch(setLoadingSftpFiles(false));
            }}
          >
            <Icon name="cross" classes="modal-content__close-icon" />
          </button>
          <div className="loader" />
        </div>
      </Modal>
      <ModalSuccess />
      <ModalRejected onConfirm={handleRejectedFiles} />
    </DevToolsLayout>
  );
}
