import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../main/store';
import { loansAdapter } from '../entityAdapters';

export const loansCollectionSelectors = loansAdapter.getSelectors<RootState>(
  (state) => state.platformAdmin.loans.collection,
);

const selectRoot = (root: RootState) => root;

export const selectLoansState = (root: RootState) => root.platformAdmin.loans;

export const selectLoansPagination = createSelector(
  [selectLoansState],
  ({ total, page, pageSize, pagesCount }) => ({ total, page, pageSize, pagesCount }),
);

export const selectLoanById = (root: RootState, id?: string) =>
  id ? loansCollectionSelectors.selectById(root, id) : undefined;

export const selectSelectedLoanId = (root: RootState) => root.platformAdmin.loans.selectedItemId;

export const selectSelectedLoan = createSelector(
  [selectRoot, selectSelectedLoanId],
  selectLoanById,
);
