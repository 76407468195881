import { format, formatDistanceToNow } from 'date-fns';

import { useAppDispatch } from '../../../main/store/hooks';
import useSelectButton, {
  IntegrationsTabs,
} from '../../../shared/components/UI/SelectButton/hooks/useSelectButton';
import { LoanPresetDto } from '../../../shared/models/platform-admin/loans/LoanPresetDto';
import { setSelectedCampaign } from '../../auth/store';
import useIntegrations from '../hooks/useIntegrations';

type RequestContainerProps = Pick<
  ReturnType<typeof useIntegrations>['properties'],
  'presets' | 'hasQuickLink'
> &
  Pick<ReturnType<typeof useSelectButton>['properties'], 'selected' | 'selectedFlow'>;

export default function RequestContainer({
  selectedFlow,
  selected,
  presets,
  hasQuickLink,
}: RequestContainerProps) {
  const isQuickLinkSelected = selected === IntegrationsTabs['quick-link'];
  const getDate = (date: string) => format(new Date(date), 'MMM dd, yyyy');
  const timeAgo = (dateString: string) => {
    const date = new Date(dateString);
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });

    return timeAgo;
  };

  const dispatch = useAppDispatch();

  const handleSelectCampaign = (el: LoanPresetDto, campaignName: string) => {
    dispatch(setSelectedCampaign({ ...el, displayName: el.displayName || campaignName }));
  };

  return (
    <>
      {isQuickLinkSelected && (
        <div className="linksContainer">
          {hasQuickLink ? (
            <div className="presetsList">
              <div className="presetsList__head">
                <div className="presetsList__row presetsList__head__row">
                  <div className="presetsList__row presetsList__left padding-none">
                    <div className="presetsList__column presetsList__head__column">Name</div>
                    <div className="presetsList__column presetsList__head__column">Created</div>
                    <div className="presetsList__column presetsList__head__column">Modified</div>
                    <div className="presetsList__column presetsList__head__column">Status</div>
                  </div>
                  <div className="presetsList__row presetsList__rigth padding-none" />
                </div>
              </div>
              <div className="presetsList__body">
                {presets
                  // @ts-ignore
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((el, index) => (
                    <div className="presetsList__row" key={el.uuid}>
                      <div className="presetsList__row presetsList__left">
                        <div className="presetsList__column preset__name">
                          {el.displayName || `Campaign ${index + 1}`}
                        </div>
                        <div className="presetsList__column preset__date">
                          {el.createdAt ? getDate(el.createdAt) : ''}
                        </div>
                        <div className="presetsList__column preset__date">
                          {el.updatedAt ? timeAgo(el.updatedAt) : ''}
                        </div>
                        <div className="presetsList__column preset__status">
                          <div
                            className={`preset__status__marker preset__status__marker--${el.state}`}
                          />
                          {el.state}
                        </div>
                      </div>
                      <div className="presetsList__row presetsList__rigth">
                        <button
                          type="button"
                          className="button button--outline-gray button--lg button-block presetsList__button"
                          onClick={() => handleSelectCampaign(el, `Campaign ${index + 1}`)}
                        >
                          <img src="images/dashboard/eye.svg" alt="" />
                          View
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="integrations__noPresets">
              <div className="integrations__noPresets__iconBlock">
                <img src="images/dashboard/advertising.svg" alt="" />
              </div>
              <div className="integrations__noPresets__title">You have no campaigns yet</div>
              <div className="integrations__noPresets__descr">
                Connect with your account manager to get your campaign up and running
              </div>
              <div className="integrations__noPresets__footer">
                <div className="integrations__noPresets__footer__text">
                  Want to learn more?
                  <a
                    className="integrations__noPresets__footer__link"
                    target="_blank"
                    href="https://docs.zirtue.com/docs/loan-toolkit"
                    rel="noreferrer"
                  >
                    Read documentation
                    <img src="images/dashboard/popout.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
