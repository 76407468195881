import React from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { setShowVerifyBanner } from '../store/dashboardSlice';

export const DashboardVerifyBanner = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="dashboard-verify-banner">
      <div className="dashboard-verify-banner__text">Your bank account has not been verified</div>
      <Link to="/finances" className="dashboard-verify-banner__link">
        Verify bank account
      </Link>

      <button
        type="button"
        className="banner__close dashboard-verify-banner__close"
        onClick={() => dispatch(setShowVerifyBanner(false))}
      >
        <Icon name="tag-close" />
      </button>
    </div>
  );
};
