import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { DateInput, Icon, Input, Label } from '../../../shared';
import Modal from '../../../shared/components/Modal';
import Radio from '../../../shared/components/Radio';
import { BillPayment } from '../../../shared/models';
import { PaymentsPageSettings } from '../../../shared/models/PaymentMethodModel';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { setModalRefundOpen, setRefundEventIndex, setSelectedBillPayment } from '../store';
import { billPaymentRefund, getBillPayments } from '../store/actions';

interface IProps {
  classes?: string;
  pageSettings?: PaymentsPageSettings;
  totalRefundAmount?: number;
}

const validationSchema = yup.object({
  amount: yup
    .mixed()
    .test('amountValue', 'Please enter correct value', (value: number, context: unknown) => {
      // @ts-ignore
      if (value > 0 && value <= context.parent.total) {
        return true;
      }
      return false;
    }),

  reason: yup.string().label('Reason').required(),
  paymentDate: yup.string().label('Payment date').required(),
});

type FormType = {
  total: number;
  amount: number;
  reason: string;
  paymentDate: string;
};

enum RefundType {
  FULL_REFUND = 'Full refund',
  PARTIAL_REFUND = 'Partial refund',
}

const ModalRefund: React.FC<IProps> = ({ classes, pageSettings, totalRefundAmount }: IProps) => {
  const [refundType, setRefundType] = useState<string>(RefundType.FULL_REFUND);
  const [isCustom, setIsCustom] = useState(false);
  const [isFullDisabled, setIsFullDisabled] = useState<RefundType.FULL_REFUND | null>(null);

  const { modalRefundOpen: opened, selectedBillPayment } = useAppSelector(
    (state) => state.payments,
  );
  const { refundInfo } = useAppSelector((state) => state.payments, shallowEqual);
  const dispatch = useAppDispatch();

  const remainingAmount =
    refundInfo?.amount?.value && totalRefundAmount
      ? refundInfo?.amount?.value - totalRefundAmount
      : refundInfo?.amount?.value;

  const handleCloseModal = () => {
    setIsCustom(false);
    setRefundType(RefundType.FULL_REFUND);
    dispatch(setModalRefundOpen({ open: false }));
  };

  const {
    errors,
    handleChange,
    handleBlur,
    values,
    touched,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    validateForm,
    resetForm,
  } = useFormik<FormType>({
    initialValues: {
      total: 0,
      amount: refundInfo?.amount?.value || 0,
      reason: '',
      paymentDate: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: async ({ reason, paymentDate, amount }: FormType) => {
      const response = await dispatch(
        billPaymentRefund({
          submittedBy: refundInfo?.submittedBy || '',
          customerName: refundInfo?.customerName || '',
          billingAccountNumber: refundInfo?.billingAccountNumber || '',
          paymentId: refundInfo?.paymentId || '',
          transactionId: refundInfo?.transactionId || '',
          amount: {
            value: amount,
            currency: 'USD',
          },
          reason,
          paymentDate: new Date(paymentDate).toISOString(),
        }),
      );

      if (billPaymentRefund.fulfilled.match(response) && pageSettings) {
        const resp = await dispatch(getBillPayments(pageSettings));

        if (getBillPayments.fulfilled.match(resp) && selectedBillPayment) {
          const { payload } = resp;

          const current = payload.billPayments.find(
            (el: BillPayment) => el.uuid === selectedBillPayment.uuid,
          );

          dispatch(setRefundEventIndex(0));
          dispatch(setSelectedBillPayment(current));
        }

        Amplitude.logEvent(AmplitudeEvents.refundSubmitted, { amount, refundType });
      }

      if (billPaymentRefund.rejected.match(response)) {
        const { payload } = response;
        dispatch(addAlert({ text: payload || 'Something went wrong', type: AlertType.error }));
      }
      handleCloseModal();
    },
    validationSchema,
  });

  const handleChangeType = (value: string) => {
    setRefundType(value);

    if (value === RefundType.FULL_REFUND) {
      setIsCustom(false);
      setFieldValue('amount', Number(refundInfo?.amount?.value), true);
    }
  };

  const handleSwithCustom = () => {
    setIsCustom(!isCustom);
  };

  useEffect(() => {
    resetForm();
    validateForm();
    if (refundInfo?.paymentDate) {
      setFieldValue('paymentDate', refundInfo?.paymentDate, true);
    }

    if (refundInfo?.amount?.value) {
      setFieldValue('amount', Number(refundInfo?.amount?.value), true);
      setFieldValue('total', Number(refundInfo?.amount?.value), false);
    }

    if (totalRefundAmount) {
      setRefundType(RefundType.PARTIAL_REFUND);
      setIsFullDisabled(RefundType.FULL_REFUND);
      setFieldValue('amount', remainingAmount, true);
      setFieldValue('total', remainingAmount, false);
    }

    if (!opened) {
      setRefundType(RefundType.FULL_REFUND);
      setIsFullDisabled(null);
    }
  }, [opened]);

  return (
    <Modal opened={opened} handleClose={handleCloseModal} classes={classes}>
      <div className="modal-content modal-content--mint-top">
        <button type="button" className="modal-content__close" onClick={handleCloseModal}>
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--hand-with-coin">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="../images/dashboard/modals/refund-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="../images/dashboard/modals/hand@1x.webp 1x, ../images/dashboard/modals/hand@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="../images/dashboard/modals/hand@1x.png"
              srcSet="../images/dashboard/modals/hand@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="../images/dashboard/modals/refund-lv3.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Refund a customer</h2>
        <p className="modal-content__text">
          Submit a refund form to Zirtue of a customer&apos;s account.
        </p>
        {/* Form */}
        <form className="modal-content__form" action="#" onSubmit={handleSubmit}>
          {/* Form group */}
          <div className="form-group form-group--separator">
            <div className="form-group">
              <Label forId="name">Refund type</Label>
              <Radio
                id="refund-type"
                items={Object.values(RefundType)}
                value={refundType}
                onChange={(e) => handleChangeType(e.target.value)}
                disabled={isFullDisabled}
              />
            </div>

            {/* Form group */}
            {refundType === RefundType.PARTIAL_REFUND && (
              <div className="form-group">
                <Label forId="name">Amount to refund</Label>
                <Radio
                  id="amount-refund"
                  items={[`Refund remaning amount ($${remainingAmount})`, 'Custom amount']}
                  value={`Refund remaning amount ($${remainingAmount})`}
                  onChange={handleSwithCustom}
                />
              </div>
            )}
            {isCustom && refundType === RefundType.PARTIAL_REFUND && (
              <div className="form-group form-group--refund">
                <Input
                  id="amount"
                  type="number"
                  prependIconName="dollar-circle"
                  placeholder="Enter an amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isHideNumControls
                  error={Boolean(touched.amount) && errors.amount}
                />
              </div>
            )}
            {/* Form group */}
            {refundType === RefundType.FULL_REFUND && (
              <div className="form-group">
                <Label forId="amount-to-refund">Amount to refund</Label>
                <Input
                  id="amount-value"
                  type="text"
                  prependIconName="dollar-circle"
                  placeholder="Enter an amount"
                  value={refundInfo?.amount?.value}
                  disabled
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="name">Customer name</Label>
            <Input type="text" id="name" disabled value={refundInfo?.customerName} />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="account-number">Account number</Label>
            <Input
              type="text"
              id="accountNumber"
              disabled
              value={refundInfo?.billingAccountNumber}
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label
              forId="transaction-id"
              infoText="The transaction id for the initial transaction."
            >
              Transaction ID
            </Label>
            <Input type="text" id="transactionId" disabled value={refundInfo?.transactionId} />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="paymentDate">Payment date</Label>
            <DateInput
              id="paymentDate"
              disabled
              value={values.paymentDate}
              onChange={(newValue: string) => {
                setFieldValue('paymentDate', newValue, true);
              }}
              error={errors.paymentDate}
              dateMask="MMM dd, yyyy hh:mm:ss a"
            />
          </div>

          {/* Form group */}
          <div className="form-group">
            <Label
              forId="reason-for-refund"
              infoText="The reason the refund was submitted. Please exclude any sensitive information."
            >
              Reason for refund
            </Label>
            <Input
              type="textarea"
              id="reason"
              placeholder="Tell us why you would like to refund this customer"
              value={values.reason}
              onChange={handleChange}
              error={Boolean(touched.reason) && errors.reason}
            />
          </div>
          <button
            type="submit"
            className="button button--primary-blue button--lg button--block"
            disabled={!isValid || !dirty}
          >
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalRefund;
