export enum LoanStageKind {
  binding = 'binding',
  acceptance = 'acceptance',
  disbursement = 'disbursement',
  repayment = 'repayment',
  closed = 'closed',
}

export type LoanBindingStageDto = {
  kind: LoanStageKind.binding;
  currentPaymentIndex: number;
};

export type LoanAcceptanceStageDto = {
  kind: LoanStageKind.acceptance;
  currentPaymentIndex: number;
  retry: boolean;
  failedTransferId?: string;
  failedConvenienceTransferId?: string;
  awaitingConvenienceTransferId?: string;
  awaitingTransferId?: string;
};

export type LoanDisbursementStageDto = {
  kind: LoanStageKind.disbursement;
  currentPaymentIndex: number;
  retry: boolean;
  failedTransferId?: string;
  failedConvenienceTransferId?: string;
  awaitingConvenienceTransferId?: string;
  awaitingTransferId?: string;
};

export type LoanRepaymentStageDto = {
  kind: LoanStageKind.repayment;
  currentPaymentIndex: number;
  currentPaymentFailureCount: number;
  scheduledFor: string;
  gracePeriod?: { enabled: boolean; manualRepaymentAllowed: boolean };
};

export enum LoanClosureType {
  cancelled = 'cancelled',
  rejected = 'rejected',
  forgiven = 'forgiven',
  paidOut = 'paid_out',
  deactivated = 'deactivated',
  declined = 'declined',
}

export type LoanClosedStageDto = {
  kind: LoanStageKind.closed;
  currentPaymentIndex: number;
  closureType: LoanClosureType;
};

export type LoanStage =
  | LoanBindingStageDto
  | LoanAcceptanceStageDto
  | LoanDisbursementStageDto
  | LoanRepaymentStageDto
  | LoanClosedStageDto;

export enum LoanStatus {
  pending = 'PENDING',
  active = 'ACTIVE',
  forgiven = 'FORGIVEN',
  paidOff = 'PAID OFF',
  declined = 'DECLINED',
  cancelled = 'CANCELLED',
}

export type LoanUserDto = {
  uuid: string;
  redactedUsername: string;
  redactedPhoneNum?: string;
};
