import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CURRENT_ENV } from '../../../../main/env';
import { api } from '../../../../main/network';
import { useAppDispatch } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import StoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import useMultistepForm from '../../../../shared/hooks/useMultistepForm';
import {
  AuthTokenCreateModel,
  AuthTokenCreateResponseDto,
} from '../../../../shared/models/AuthToken';
import { Amplitude, AmplitudeEvents } from '../../../../shared/services/amplitude';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';
import { setAuthToken } from '../../../auth/store';
import { setCookie } from '../../../auth/utils';
import { Progress, Typography } from '../../../init-setup/components';
import { COMPANY_SIZE, Industry } from '../../constants';
import { COMPANY_SIZE_VALUE } from '../../constants/selection';
import { firstSchema, secondSchema, thirdSchema } from '../FormSchema';
import { CreateAcc, RegisterBusiness, RegisterSubdomain } from '../SignUpFormSteps';

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  businessName: string;
  industry: Industry | string;
  companySize: keyof typeof COMPANY_SIZE | '';
  subdomain: string;
};

const INITIAL_DATA: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  businessName: '',
  companySize: '',
  industry: '',
  subdomain: '',
};

type AcceptInvitationData = {
  password: string;
  token: string;
  ownerFirstName: string;
  ownerLastName: string;
};

type SubdomainAndBussinesData = {
  frontEndBasePath: string;
  industry: string;
  businessName: string;
  businessSize: string;
};

export default function SignUpForm() {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [businessSize, setBusinessSize] = useState<COMPANY_SIZE_VALUE>(COMPANY_SIZE_VALUE.SMALL);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const inviteToken = location.pathname.replace('/signup/', '');
  const postfixDomain = CURRENT_ENV() === 'dev' ? `dev.portal.zirtue.com` : 'portal.zirtue.com';
  const params = new URL(String(window.location)).searchParams;
  const email = params.get('email')?.replace('mailto:', '');

  useEffect(() => {
    if (!email || !inviteToken) history.push('/signin');
  }, [email, history, inviteToken]);

  function currentValidation(index: number) {
    const arr = [firstSchema, secondSchema, thirdSchema];
    const validation = arr[index];
    return validation;
  }

  async function checkSubdomain(subdomain: string) {
    setLoading(true);
    try {
      const response = await api.get(`/partners/validate-domain?domain=${subdomain}`);
      if (response.data) {
        const newError = 'Subdomain is already taken';
        throw newError;
      }
      return true;
    } catch (error) {
      if (typeof error === 'string') {
        dispatch(
          addAlert({
            text: error || 'Error while creating subdomain.',
            type: AlertType.error,
          }),
        );
      }
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  }
  async function registerBussines(obj: SubdomainAndBussinesData) {
    setLoading(true);

    try {
      await api.post(`/partners/set-domain`, obj);
      Amplitude.logEvent(AmplitudeEvents.ppBusinessRegistered, {
        businessName: obj.businessName,
        industry: obj.industry,
        size: businessSize,
      });
      return true;
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Error while registering business.',
          type: AlertType.error,
        }),
      );
      return false;
    }
  }

  const updatePartner = async (data: { businessEmailUri: string }) => {
    try {
      await api.put('/partners', data);
      return true;
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Error while creating account.',
          type: AlertType.error,
        }),
      );
    } finally {
      setLoading(false);
    }
    return false;
  };

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setErrors,
    handleSubmit,
    isValid,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: INITIAL_DATA,
    validateOnChange: currentStepIndex <= 1,
    validationSchema: currentValidation(currentStepIndex),
    // eslint-disable-next-line
    onSubmit: onSubmitHandler,
  });

  const propsData = {
    values,
    handleChange,
    handleBlur,
    validateField,
    touched,
    errors,
    setErrors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  const { step, steps, next, isFirstStep, isLastStep } = useMultistepForm({
    steps: [
      <CreateAcc {...propsData} />,
      <RegisterSubdomain {...propsData} />,
      <RegisterBusiness {...propsData} />,
    ],
    setCurrentStepIndex,
    currentStepIndex,
  });

  async function acceptInvitationHandler(invitation: AcceptInvitationData) {
    try {
      setLoading(true);
      const { data } = await api.post<AuthTokenCreateResponseDto>(
        '/invitations/accept',
        invitation,
      );
      const response = AuthTokenCreateModel.fromDTO(data);
      dispatch(setAuthToken(response));
      setCookie(StoreKeys.refreshToken, data.refreshToken, postfixDomain); //* store refreshToken in cookies

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = parseError(error);
        dispatch(
          addAlert({
            text: errorMessage || 'Error while creating account.',
            type: AlertType.error,
          }),
        );
      }
      throw new Error('Error while creating account.');
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitHandler() {
    const subdomain = values.subdomain.trim().toLocaleLowerCase();
    if (currentStepIndex === 0 && isValid) {
      const objRequest = {
        ownerFirstName: values.firstName.trim(),
        ownerLastName: values.lastName.trim(),
        token: inviteToken,
        password: values.password,
      };
      const isRequestSuccess = await acceptInvitationHandler(objRequest);
      Amplitude.logEvent(AmplitudeEvents.ppUserInfo, {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
      });

      if (!isRequestSuccess) {
        return;
      }

      next();
    }
    if (currentStepIndex === 1 && isValid) {
      const isRequestSuccess = await checkSubdomain(subdomain);

      if (!isRequestSuccess) {
        return;
      }

      next();
    }
    if (currentStepIndex === 2 && isValid) {
      const objRequest = {
        frontEndBasePath: `${subdomain}.${postfixDomain}`,
        businessName: values.businessName.trim(),
        businessSize: values.companySize,
        industry: values.industry,
      };

      const isRequestSuccess = await registerBussines(objRequest);
      const partnerRequest = await updatePartner({ businessEmailUri: `mailto:${email}` });

      if (!isRequestSuccess || !partnerRequest) {
        return;
      }
      window.location.href = `https://${subdomain}.${postfixDomain}/verify-sign-in`;
    }
  }

  let buttonText;

  if (isLastStep) {
    buttonText = 'Access the Partner Portal';
  } else if (isFirstStep) {
    buttonText = 'Create account';
  } else {
    buttonText = 'Next';
  }

  useEffect(() => {
    setFieldValue('email', email);
  }, [email, setFieldValue]);

  useEffect(() => {
    switch (values.companySize) {
      case 'SMALL':
        setBusinessSize(COMPANY_SIZE_VALUE.SMALL);
        break;
      case 'MEDIUM':
        setBusinessSize(COMPANY_SIZE_VALUE.MEDIUM);
        break;
      case 'LARGE':
        setBusinessSize(COMPANY_SIZE_VALUE.LARGE);
        break;
      case 'EXTRA_LARGE':
        setBusinessSize(COMPANY_SIZE_VALUE.EXTRA_LARGE);
        break;
      default:
        setBusinessSize(COMPANY_SIZE_VALUE.SMALL);
    }
  }, [values.companySize]);

  return (
    <div className="new-sign-up__signUpForm signUpForm">
      <div>
        <Progress
          currentStep={currentStepIndex}
          totalSteps={steps.length - 1}
          classes="sign-up-progress"
        />
        <Typography tag="p" size="body-sm" colors="text-tertiary" textAlign="text-center">
          Step {currentStepIndex + 1} of {steps.length}
        </Typography>
      </div>
      <form onSubmit={handleSubmit} className="signUpForm__form" autoComplete="off">
        <>{step}</>
        <div>
          <Button
            disabled={loading}
            type="submit"
            className="button--primary-blue signUpForm__button"
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
}
