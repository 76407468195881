import { FC } from 'react';

import Modal from '../../Modal';
import useCheckoutList from '../hooks/useCheckoutList';
import useVerifyModal from '../hooks/useVerifyModal';

import { ModalForm, ModalHeader } from '.';

type PaymentVerifyProps = Pick<ReturnType<typeof useVerifyModal>, 'setShowModal' | 'showModal'> &
  Partial<Pick<ReturnType<typeof useCheckoutList>['operations'], 'checkItem'>>;

const PaymentVerifyModal: FC<PaymentVerifyProps> = ({ setShowModal, showModal, checkItem }) => (
  <Modal handleClose={setShowModal} opened={showModal}>
    <div className="payment-verify-modal">
      <ModalHeader setShowModal={setShowModal} />
      <ModalForm setShowModal={setShowModal} checkItem={checkItem} />
    </div>
  </Modal>
);

export default PaymentVerifyModal;
