import classNames from 'classnames';
import { FormikErrors } from 'formik';
import React, { FC } from 'react';

import { PartnersDeeplinksFormType } from '../../../../shared/models/platform-admin/loans/LoanPresetDto';

interface PartnersDeeplinksDetailsButtonsProps {
  // eslint-disable-next-line
  options: any;
  // eslint-disable-next-line
  name: string;
  // eslint-disable-next-line
  secondName?: string;
  // eslint-disable-next-line
  value: any;
  // eslint-disable-next-line
  setFieldValue: (
    field: string,
    // eslint-disable-next-line
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<PartnersDeeplinksFormType>>;
  disabled?: boolean;
}

export const PartnersDeeplinksDetailsButtons: FC<PartnersDeeplinksDetailsButtonsProps> = ({
  options,
  name,
  secondName,
  value,
  setFieldValue,
  disabled,
}) => {
  // eslint-disable-next-line
  const handleToggleValue = (option: any) => {
    setFieldValue(`${name}`, option.answer);
    setFieldValue(`${secondName}`, option.answer);
  };

  return (
    <div className="partners-deeplinks__details-buttons">
      {/* @ts-ignore */}
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <button
            type="button"
            onClick={() => handleToggleValue(option)}
            className={classNames('partners-deeplinks__details-button', {
              'partners-deeplinks__details-button-isActive': option.answer === value,
              'partners-deeplinks__details-button-disabled': disabled,
            })}
            disabled={disabled}
          >
            {option.label}
          </button>
          {index !== options.length - 1 && (
            <div className="partners-deeplinks__details-button-dot">·</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
