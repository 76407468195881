import { capitalize } from 'lodash';
import React, { useRef } from 'react';
import Popup from 'reactjs-popup';

import { useAppDispatch } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import { FundingSource, FundingSourceRail } from '../../../../shared/models/FundingSource';
import { addAlert, AlertType, setModalConfirmOpen } from '../../../../shared/store/modals';
import { deleteUserPaymentMethod, getUserPaymentMethods } from '../../store/actions';

type Props = {
  children: JSX.Element | ((isOpen: boolean) => JSX.Element);
  userId: string;
  paymentMethod: FundingSource;
};

export const RemovePaymentMethodPopover = ({ children, userId, paymentMethod }: Props) => {
  const dispatch = useAppDispatch();
  const popupRef = useRef(null);

  const { uuid, rail } = paymentMethod;
  const isFiserv = rail === FundingSourceRail.CARDS;
  const paymentName = isFiserv ? 'card' : 'account';

  const modalConfirmTitle = `Remove ${paymentName} ending in ${paymentMethod.redactedAccountNumber}?`;
  const modalConfirmText = `By removing this ${paymentName} you’ll permanently delete it. You can always link this ${paymentName} again or add a new ${paymentName} later.`;
  const modalConfirmOkText = 'Yes, remove it';
  const modalConfirmCancelText = 'No, don’t remove it';

  const handleClose = () => {
    if (popupRef) {
      // @ts-ignore
      popupRef.current.close();
    }
  };

  const confirmDeleteAccount = async () => {
    const result = await dispatch(deleteUserPaymentMethod({ userId, accountId: uuid, rail }));
    dispatch(setModalConfirmOpen({ opened: false }));

    if (deleteUserPaymentMethod.fulfilled.match(result)) {
      dispatch(
        addAlert({ text: `${capitalize(paymentName)} successfully removed`, type: AlertType.info }),
      );
      setTimeout(() => dispatch(getUserPaymentMethods(userId)), 1000);
    } else if (deleteUserPaymentMethod.rejected.match(result)) {
      dispatch(
        addAlert({
          text: result.payload || `Error while deleting ${paymentName}.`,
          type: AlertType.error,
        }),
      );
    }
  };

  const handleDeleteAccount = () => {
    dispatch(
      setModalConfirmOpen({
        opened: true,
        title: modalConfirmTitle,
        text: modalConfirmText,
        okText: modalConfirmOkText,
        cancelText: modalConfirmCancelText,
        onConfirm: () => confirmDeleteAccount(),
      }),
    );
  };

  return (
    <div className="quick-actions">
      <Popup
        trigger={children}
        position="top right"
        on="click"
        closeOnDocumentClick
        arrow={false}
        ref={popupRef}
      >
        <ul className="quick-actions__menu" onClick={handleClose} role="presentation">
          <li className="quick-actions__menu-item">
            <button
              type="button"
              className="quick-actions__menu-link quick-actions__menu-link--red"
              onClick={handleDeleteAccount}
            >
              <Icon name="trash" classes="quick-actions__menu-icon" />
              Remove
            </button>
          </li>
        </ul>
      </Popup>
    </div>
  );
};
