export type PartnerMemberDto = {
  uuid: string;
  version: number;
  firstName: string;
  lastName: string;
  middleName: string;
  contacts: Array<string>;
  orgRoles: Array<string>;
  securityGroups: Array<string>;
};

export type PartnerMembersDto = {
  members: Array<PartnerMemberDto>;
};

export type PartnerMember = PartnerMemberDto;
export type PartnerMembers = PartnerMembersDto;

export class PartnerMemberModel {
  public static fromDTO(dto: PartnerMemberDto): PartnerMember {
    return {
      ...dto,
    };
  }
}
