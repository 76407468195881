import React from 'react';

import { Icon, Modal } from '../../../shared';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  image: string;
};

export default function ModalImagePreview({ isOpen, setIsOpen, image }: TProps) {
  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--lg"
      bodyClasses={`integrationsPreview__modalBody ${
        image.includes('zirtue-pop-up-widget')
          ? 'integrationsPreview__modalBody__image__pop-up'
          : ''
      }`}
    >
      <div className="integrationsPreview__modalBody__container">
        {/* eslint-disable-next-line */}
        <div className="integrationsPreview__modalBody__btn" onClick={() => setIsOpen(false)}>
          <Icon name="close-gray" />
        </div>
        <img src={image} alt="Preview" className="integrationsPreview__modalBody__image" />
      </div>
    </Modal>
  );
}
