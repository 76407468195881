import classNames from 'classnames';
import React, { FC } from 'react';

interface SwitchProps {
  id: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (id: string) => void;
  isShowText?: boolean;
}

const Switch: FC<SwitchProps> = ({
  id,
  checked,
  disabled = false,
  onChange,
  isShowText = false,
}) => {
  const handleChange = () => {
    if (disabled) return;

    onChange(id);
  };

  return (
    <div className={classNames('toggle', { 'toggle--txt': isShowText })}>
      <input
        type="checkbox"
        id={id}
        className="toggle__input"
        checked={checked}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        className={classNames('toggle__label', { 'toggle__label--disabled': disabled })}
      >
        {isShowText ? (
          <div className={classNames('toggle__txt', { 'toggle__txt--off': !checked })}>
            {checked ? 'ON' : 'OFF'}
          </div>
        ) : (
          ''
        )}
      </label>
    </div>
  );
};

export default Switch;
