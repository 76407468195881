import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../main/store';
import { transactionsAdapter } from '../entityAdapters/transactionsAdapter';

export const transactionsCollectionSelectors = transactionsAdapter.getSelectors<RootState>(
  (state) => state.platformAdmin.transactions.collection,
);

const selectRoot = (root: RootState) => root;

export const selectTransactionsState = (root: RootState) => root.platformAdmin.transactions;

export const selectTransactionsPagination = createSelector(
  [selectTransactionsState],
  ({ total, page, pageSize, pagesCount }) => ({ total, page, pageSize, pagesCount }),
);

export const selectTransactionById = (root: RootState, id?: string) =>
  id ? transactionsCollectionSelectors.selectById(root, id) : undefined;

export const selectSelectedTransactionId = (root: RootState) =>
  root.platformAdmin.transactions.selectedItemId;

export const selectSelectedLoan = createSelector(
  [selectRoot, selectSelectedTransactionId],
  selectTransactionById,
);
