/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns';
import { last } from 'lodash';
import moment from 'moment';
import { useMemo, useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import { LoanDetailsModel } from '../../../../shared/models/platform-admin/loans/LoanModel';
import { intercomAPI } from '../../../../shared/services/intercom';
import { checkIsPhone } from '../../../../shared/utils';
import { setShowIntercom } from '../../../dashboard/store/dashboardSlice';
import { LoanStageKind, LoanStatus } from '../../dtos/LoanTypes';
import { PaymentHistoricalEntryType, PaymentStatuses } from '../../dtos/RepaymentPlanDto';
import { prepareRepaymentPlanNew } from '../../helpers/prepareRepaymentPlanNew';
import { useRepaymentRequestLoan } from '../../hooks/useRepaymentRequestLoan';
import ModalShare from '../ModalShare/ModalShare';

import LoanOverviewCard from './LoanOverviewCard';
import LoanStatusCard from './LoanStatusCard';

export default function LoanOverviewSection() {
  const dispatch = useAppDispatch();
  const [showStatusCard, setShowStatusCard] = useState(true);
  const { loanDetails } = useAppSelector((state) => state.loans);
  const loanSummaryObject = useRepaymentRequestLoan();
  const [isModalShareOpen, setIsModalShareOpen] = useState(false);

  const username = loanDetails?.borrower.redactedUsername;
  const initialTargetName = loanDetails?.terms.description.initialTargetName;
  const displayBorrowerName = username
    ? !checkIsPhone(username)
      ? username
      : initialTargetName
    : '';

  const {
    payments,
    beginningBalance,
    loanStage,
    scheduleDueDate,
    schedulePayments,
    percent,
    contactGivenNameFull,
  } = loanSummaryObject;

  const { showIntercom } = useAppSelector((state) => state.dashboard);

  const paidPaymentsAmount = payments
    ? payments
        .filter((p) => p.history.some((h) => h.type === PaymentHistoricalEntryType.succeededOn))
        .map((p) => p.amount)
        .reduce((a, b) => a + b, 0)
    : 0;

  const loanStatus = LoanDetailsModel.Status(loanDetails);

  const paidOffOrForgiven = loanStatus === LoanStatus.forgiven || loanStatus === LoanStatus.paidOff;

  const graceManualRepaymentAllowed = useMemo(() => {
    if (loanStage && loanStage.kind === LoanStageKind.repayment && loanStage.gracePeriod)
      return loanStage.gracePeriod?.manualRepaymentAllowed;
    return false;
  }, [loanStage]);

  const repaymentDate = scheduleDueDate
    ? format(new Date(scheduleDueDate), 'MMM dd, yyyy')
    : `${moment().add(1, 'month').format('MMM DD, yyyy')} - ${moment()
        .add(schedulePayments?.length ?? 1, 'month')
        .format('MMM DD, yyyy')}`;

  const repaymentPlanType = scheduleDueDate ? 'One-time' : 'Monthly';

  const formattedRepaymentDate = useMemo(() => {
    if (repaymentPlanType === 'Monthly') return repaymentDate;
    return [repaymentDate];
  }, [repaymentDate, repaymentPlanType]);

  const showProgress =
    loanStatus && [LoanStatus.declined, LoanStatus.cancelled].indexOf(loanStatus) < 0;

  const plan = useMemo(
    () => (loanStage && payments ? prepareRepaymentPlanNew(loanStage, payments) : undefined),
    [loanStage, payments],
  );

  const nextRepaymentDate = useMemo(() => {
    if (plan) {
      const autoPayPayment = plan.find(({ status }) => status === PaymentStatuses.autoPay);

      return autoPayPayment?.payment.plannedExecutionOn;
    }

    return undefined;
  }, [plan]);

  const missedPayment = useMemo(
    () =>
      last(
        plan?.filter(
          (item) =>
            (item.status === PaymentStatuses.missed || item.status === PaymentStatuses.failed) &&
            item.payment.isCurrent,
        ),
      ),
    [plan],
  );

  const hasInProgress = useMemo(
    () =>
      !!plan?.filter((item) => item.status === PaymentStatuses.inProgress && item.payment.isCurrent)
        .length,
    [plan],
  );

  const gracePeriodEnabled = useMemo(() => {
    if (loanStage && loanStage.kind === LoanStageKind.repayment && loanStage.gracePeriod)
      return loanStage.gracePeriod?.enabled;
    return false;
  }, [loanStage]);

  const hideStatusCard = useCallback(() => {
    setShowStatusCard(false);
  }, []);

  const chartState = useMemo(() => {
    if (loanDetails && plan) {
      const currentPaymentIndex =
        loanDetails?.stage?.currentPaymentIndex > 0
          ? loanDetails?.stage?.currentPaymentIndex - 1
          : 0;

      if (
        currentPaymentIndex === 0 &&
        (plan[currentPaymentIndex]?.status === PaymentStatuses.autoPay ||
          plan[currentPaymentIndex]?.status === PaymentStatuses.inProgress)
      ) {
        return 2;
      }

      return plan[currentPaymentIndex]?.status === PaymentStatuses.completed && !gracePeriodEnabled
        ? 1
        : 0;
    }
    return 0;
  }, [loanDetails, plan, gracePeriodEnabled]);

  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };

  return (
    <>
      <div className="loan__overview">
        <div className="loan__overview__get-help">
          <div
            className="loan__overview__get-help--text"
            style={{ cursor: 'pointer' }}
            onClick={clickSupport}
          >
            <Icon name="question-circle" />
            <span>Get help</span>
          </div>
          <button
            type="button"
            style={{ position: 'static' }}
            className="modal-content__close loans__modal__close loans__link__close"
            onClick={() => setIsModalShareOpen(true)}
          >
            <Icon name="share" classes="modal-content__close-icon" />
          </button>
        </div>
        <div className="loan__overview__main">
          <LoanOverviewCard
            progress={showProgress && percent ? percent : 0}
            date={formattedRepaymentDate}
            totalAmount={beginningBalance || 0}
            paidAmount={paidPaymentsAmount}
            paidRemainingAmount={
              !paidOffOrForgiven && beginningBalance ? beginningBalance - paidPaymentsAmount : 0
            }
            chartState={chartState}
          />
          {showStatusCard && (
            <LoanStatusCard
              status={loanStatus}
              showMissedPayment={graceManualRepaymentAllowed && !!missedPayment}
              showFailedPayment={
                gracePeriodEnabled && !graceManualRepaymentAllowed && !hasInProgress
              }
              borrowerName={contactGivenNameFull?.split(' ')[0]}
              nextRepaymentDate={
                nextRepaymentDate ? format(new Date(nextRepaymentDate), 'MMM dd') : ''
              }
              hideCard={hideStatusCard}
            />
          )}
        </div>
      </div>
      <ModalShare
        isOpen={isModalShareOpen}
        setIsOpen={setIsModalShareOpen}
        displayBorrowerName={displayBorrowerName}
      />
    </>
  );
}
