import React from 'react';

interface RateCardProps {
  title: string;
  percent: number;
}
export default function RateCard({ title, percent }: RateCardProps) {
  return (
    <div style={{ padding: '24px 21px' }} className="card">
      <h4 className="loans-overview__metric-section__title-secondary">{title}</h4>
      <span className="loans-overview__metric-section__small-section-value">{percent}%</span>
    </div>
  );
}
