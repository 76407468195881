type Event = {
  name: EventKeyEnum;
};

enum EventTypeEnum {
  LOAN = 'LOAN',
  BILL_PAYMENT = 'BILL_PAYMENT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum EventKeyEnum {
  DISBURSEMENT_STARTED = 'disbursement_started',
  DISBURSEMENT_COMPLETED = 'disbursement_completed',
  BORROWER_FIRSTNAME = 'borrower_firstname',
  BORROWER_LASTNAME = 'borrower_lastname',
  BORROWER_BILLING_ACCOUNT = 'borrower_billing_account',
  LOAN_AMOUNT = 'loan_amount',
}

export type BillEvent = Event & {
  name: EventKeyEnum.DISBURSEMENT_STARTED | EventKeyEnum.DISBURSEMENT_COMPLETED;
};

export type LoanDataEvent = Event & {
  name:
    | EventKeyEnum.BORROWER_FIRSTNAME
    | EventKeyEnum.BORROWER_LASTNAME
    | EventKeyEnum.BORROWER_BILLING_ACCOUNT
    | EventKeyEnum.LOAN_AMOUNT;
};

export const billTypeEvents: EventType = {
  [EventKeyEnum.DISBURSEMENT_STARTED]: {
    type: EventTypeEnum.BILL_PAYMENT,
    key: EventKeyEnum.DISBURSEMENT_STARTED.toUpperCase(),
    enabled: false,
  },
  [EventKeyEnum.DISBURSEMENT_COMPLETED]: {
    type: EventTypeEnum.BILL_PAYMENT,
    key: EventKeyEnum.DISBURSEMENT_COMPLETED.toUpperCase(),
    enabled: false,
  },
};

export interface EventEntity {
  type: EventTypeEnum;
  key: string;
  enabled: boolean;
}
interface EventType {
  [key: string]: EventEntity;
}

export const loanTypeEvents: EventType = {
  [EventKeyEnum.BORROWER_FIRSTNAME]: {
    type: EventTypeEnum.LOAN,
    key: EventKeyEnum.BORROWER_FIRSTNAME.toUpperCase(),
    enabled: false,
  },
  [EventKeyEnum.BORROWER_LASTNAME]: {
    type: EventTypeEnum.LOAN,
    key: EventKeyEnum.BORROWER_LASTNAME.toUpperCase(),
    enabled: false,
  },
  [EventKeyEnum.BORROWER_BILLING_ACCOUNT]: {
    type: EventTypeEnum.LOAN,
    key: EventKeyEnum.BORROWER_BILLING_ACCOUNT.toUpperCase(),
    enabled: false,
  },
  [EventKeyEnum.LOAN_AMOUNT]: {
    type: EventTypeEnum.LOAN,
    key: EventKeyEnum.LOAN_AMOUNT.toUpperCase(),
    enabled: false,
  },
};
