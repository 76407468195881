import { MonetaryValueDto } from './models';

export type AuthTokenMfaCreateDto = {
  emailUri: string;
  password: string;
};

export type AuthTokenCreateDto = {
  mfaToken: string;
  code: string;
};

export type InviteData = {
  inviteToken: string;
  password: string;
};

export type CompanyUpdateData = {
  file?: File;
  businessEmailUri?: string;
  businessPhoneUri?: string;
  formationDate?: string;
  businessType?: string;
  industry?: string;
  postalAddress?: string;
  billingAddress?: string;
  ein?: string;
};

export type TErrorMessage = {
  title: string;
  message: string;
  xReqId: string;
};

export type RefundInfo = {
  submittedBy: string;
  submittedByName?: string;
  customerName: string;
  billingAccountNumber: string;
  transactionId: string;
  amount?: MonetaryValueDto;
  paymentDate?: string;
  reason?: string;
  paymentId?: string;
  photo?: string;
  type?: string;
};

export type ServerError = {
  message: string;
  statusCode: string;
};

export type IntercomState = {
  appID: string;
  // eslint-disable-next-line camelcase
  user_hash?: string;
  // eslint-disable-next-line camelcase
  user_id?: string;
  name?: string;
  email?: string;
};

export type DateRangeTuple = [string, string];

export type DateRangeSettings = {
  type: DateRangeType;
  timeUnits: DateRangeTimeUnits;
  value: number;
};

export enum DateRangeType {
  relative = 'relative',
  specific = 'specific',
}

export enum DateRangeTimeUnits {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
}
