import React, { FC } from 'react';

import { useAppSelector } from '../../../../main/store/hooks';
import { JSONView } from '../../../../shared';
import { selectSelectedLoan } from '../../store/selectors/loans';

export const SelectedLoan: FC = () => {
  const loan = useAppSelector(selectSelectedLoan);
  return (
    <div className="card">
      <JSONView data={loan} />
    </div>
  );
};
