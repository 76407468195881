import React, { ChangeEvent } from 'react';

interface CheckboxProps {
  checked?: boolean;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const Checkbox = ({ onChange, checked, name }: CheckboxProps) => (
  <div className="checkbox">
    <input
      id={name}
      name={name}
      type="checkbox"
      className="checkbox__input"
      onChange={onChange}
      checked={checked}
    />
    <label htmlFor={name} className="checkbox__label">
      &#0;
    </label>
  </div>
);

export default Checkbox;
