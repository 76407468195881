import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';
import { setTestWebhookLoading } from '../index';

import type { AppDispatch, RootState } from '../../../../main/store';

export const testPartnerWebhook = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/testPartnerWebhook', async (_, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setTestWebhookLoading(true));
    await api.put(`/partners/test-webhook-upload`);
    dispatch(addAlert({ text: 'Webhook successfully sent' }));
    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    dispatch(addAlert({ text: 'Failed to send webhook', type: AlertType.error }));
    return rejectWithValue(message);
  }
});
