import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input, Label, Modal, Search } from '../../../../shared';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { setTableSearchQuery } from '../../store';
import { adminInvitePartner } from '../../store/actions/adminInvitePartner';
import { selectPartnersState } from '../../store/selectors/partners';

const emailRegExp =
  /^[a-zA-Z0-9](?!.*?[._+\-!$*&?#%^]{2})[a-zA-Z0-9._+\-!$*&?#%^]*[a-zA-Z0-9]@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?<!\.)$/;

const validationSchema = yup.object({
  email: yup.string().trim().matches(emailRegExp, 'Email must be a valid email').required(),
});

export const PartnersTableControls: FC = () => {
  const dispatch = useAppDispatch();
  const { searchQuery } = useAppSelector(selectPartnersState);
  const [isShowModal, setIsShowModal] = useState(false);

  const onSearchChange = (val: string) =>
    dispatch(setTableSearchQuery({ query: val, tableName: 'partners' }));

  const handleShowModal = (val: boolean) => {
    setIsShowModal(val);
  };

  const { errors, values, touched, isValid, handleChange, handleSubmit, handleBlur, resetForm } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        const { email } = values;

        const res = await dispatch(adminInvitePartner(email));

        // @ts-ignore
        if (res?.error?.message) {
          dispatch(
            addAlert({
              // @ts-ignore
              text: res.payload.error,
              type: AlertType.error,
            }),
          );
        }
        if (res?.payload === true) {
          resetForm();
          handleShowModal(false);
        }
      },
    });

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__col table-filter__col--search">
          <Search
            id="filter-search"
            classes="table-filter__search"
            placeholder="Search Partners"
            value={searchQuery}
            onChange={onSearchChange}
          />
        </div>
      </div>
      <div className="table-filter__controls">
        <button
          type="button"
          className="button button--sm button--primary-blue"
          onClick={() => handleShowModal(true)}
        >
          <span className="button__icon">
            <Icon name="plus" />
          </span>
          Add new partner
        </button>
      </div>

      <Modal
        opened={isShowModal}
        handleClose={() => {
          resetForm();
          handleShowModal(false);
        }}
      >
        <div className="modal-content modal-content--add-partner">
          <h3>Add new Zirtue partner</h3>
          <p>
            Adding a new partner will send a partner portal registration email to the address
            provided below. Please ensure that the email address used is for the primary admin of
            the partner portal.
          </p>
          <form action="#" onSubmit={handleSubmit}>
            <div className="form-group">
              <Label forId="email">Partner admin email</Label>
              <Input
                type="text"
                id="email"
                value={values.email}
                placeholder="example@example.com"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.email) && touched.email && errors.email}
              />
            </div>
            <button
              type="submit"
              className="button button--primary-blue button--lg button--block"
              disabled={!isValid}
            >
              Add partner
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
