import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { FlowTypes } from '..';
import { api } from '../../../../main/network';
import { Address } from '../../../../shared/models/AddAchNodeDto';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

type OnboardingBusinessData = {
  businessName: string;
  ein: string;
  principalAddress: Address;
  routingNumber: string;
  accountNumber: string;
};

type CreateAsyncThunkType = {
  dispatch: AppDispatch;
  rejectValue: string;
  state: RootState;
};
type ResponseType = keyof typeof FlowTypes;
type PayloadType = {
  flowType: keyof typeof FlowTypes;
  data?: OnboardingBusinessData;
};
export const completeOnboarding = createAsyncThunk<ResponseType, PayloadType, CreateAsyncThunkType>(
  'auth/completeOnboarding',
  async (payload, { rejectWithValue }) => {
    try {
      const requestData =
        payload.flowType === FlowTypes.AFFILIATE_PARTNER
          ? { flowType: payload.flowType }
          : { ...payload.data, flowType: payload.flowType };
      await api.post('/partners/complete-onboarding', requestData);
      const response = payload.flowType;
      return response as ResponseType;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = parseError(error);
        return rejectWithValue(errorMessage);
      }
      throw error;
    }
  },
);
