import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { TransactionsSearchQueryDto } from '../../../../shared/models/platform-admin/transactions/TransactionsSearchQueryDto';
import { TransactionsSearchResponseDto } from '../../../../shared/models/platform-admin/transactions/TransactionsSearchResponseDto';
import { selectTransactionsState } from '../selectors/transactions';

export const adminTransactionsSearch = createAsyncThunk<
  TransactionsSearchResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>('platformAdmin/adminTransactionsSearch', async (_, { getState }) => {
  const {
    page,
    pageSize,
    searchQuery,
    dateRangeFilter = [],
    transferStatus,
    sortField,
    sortOrder,
  } = selectTransactionsState(getState());
  const [dateStart, dateEnd] = dateRangeFilter || [];

  const { data } = await api.get<TransactionsSearchResponseDto>(
    '/platform-admin/transfers/search',
    {
      params: <TransactionsSearchQueryDto>{
        skip: (page - 1) * pageSize,
        limit: pageSize,
        callbackId: searchQuery.replace('+', ''),
        dateStart,
        dateEnd,
        transferStatus,
        sortField,
        sortOrder,
      },
    },
  );

  return data;
});
