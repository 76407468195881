import React, { useCallback } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { useAppSelector } from '../../main/store/hooks';

const PrivateRoute: React.FC<RouteProps> = ({ render, ...rest }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const renderBody = useCallback(
    (props: RouteComponentProps) =>
      isAuthenticated && render ? (
        render(props)
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
          }}
        />
      ),
    [render, isAuthenticated],
  );

  return <Route {...rest} render={renderBody} />;
};

export default PrivateRoute;
