import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { RefundInfo } from '../../../../shared';
import { PartnerMemberDto } from '../../../../shared/models';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getSubmittedByName = createAsyncThunk<
  string,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('dashboard/getSubmittedByName', async (submittedBy, { rejectWithValue }) => {
  try {
    const response = await api.get(`/members/${submittedBy}`);
    const { firstName, lastName, contacts } = response.data as PartnerMemberDto;
    const email = contacts && contacts.find((i) => i.includes('mailto:'))?.replace('mailto:', '');
    const submittedName = firstName && lastName ? `${firstName} ${lastName}` : email || submittedBy;

    return submittedName;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
