import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../main/store';
import { usersAdapter } from '../entityAdapters';

export const usersCollectionSelectors = usersAdapter.getSelectors<RootState>(
  (state) => state.platformAdmin.users.collection,
);

const selectRoot = (root: RootState) => root;

export const selectUsersState = (root: RootState) => root.platformAdmin.users;

export const selectUserById = (root: RootState, id?: string) =>
  id ? usersCollectionSelectors.selectById(root, id) : undefined;

export const selectSelectedUserId = (root: RootState) => root.platformAdmin.users.selectedItemId;

export const selectSelectedUser = createSelector(
  [selectRoot, selectSelectedUserId],
  selectUserById,
);

export const selectUsersPagination = createSelector(
  [selectUsersState],
  ({ total, page, pageSize, pagesCount }) => ({ total, page, pageSize, pagesCount }),
);
