import React from 'react';

import { useAppSelector } from '../../../main/store/hooks';
import AuthBannerLayout from '../layout/AuthBannerLayout';

export enum AuthBannerContent {
  SignIn = 'signin',
  SignUp = 'signup',
  CreateAccount = 'create-account',
  ForgotPassword = 'forgot-password',
  CheckEmail = 'check-email',
  ResetPassword = 'reset-password',
  Verify = 'verify',
  Invite = 'invite',
}

type Props = {
  content: AuthBannerContent;
};

type BannerContentProps = {
  title?: string;
  text?: string;
  containerStyle: string;
  background: { src: string; className: string }[];
  pictureSourceSet: string;
  pictureImgSrc: string;
  pictureImgSrcSet: string;
  pictureClassName: string;
  foreground?: { src: string; className: string }[];
};

const BannerContent: React.FC<BannerContentProps> = ({
  title,
  text,
  containerStyle,
  background,
  pictureSourceSet,
  pictureImgSrc,
  pictureImgSrcSet,
  pictureClassName,
  foreground,
}) => (
  <>
    {title && <h2 className="auth-banner__title">{title}</h2>}
    {text && <p className="auth-banner__text">{text}</p>}
    <div className={`auth-illustration ${containerStyle}`}>
      {background.map(({ src, className }, index) => (
        <img
          key={`${src}-${index}`}
          src={src}
          alt=""
          className={`auth-illustration__img ${className}`}
        />
      ))}
      <picture>
        <source srcSet={pictureSourceSet} type="image/webp" />
        <img
          src={pictureImgSrc}
          srcSet={pictureImgSrcSet}
          alt=""
          className={`auth-illustration__img ${pictureClassName}`}
        />
      </picture>
      {foreground?.map(({ src, className }) => (
        <img key={src} src={src} alt="" className={`auth-illustration__img ${className}`} />
      ))}
    </div>
  </>
);

const AuthBanner: React.FC<Props> = ({ content }) => {
  const { step, email } = useAppSelector((state) => state.auth);
  const stepFourText = `We sent your confirmation email to ${email}. Once you confirm, you’ll be able to log into The Partner Portal -- your one-stop shop for all things Zirtue.`;
  return (
    <AuthBannerLayout>
      {/* Sign in */}
      {content === AuthBannerContent.SignIn && (
        <BannerContent
          title="Nice to see you again."
          text="Integrate Zirtue as an alternative payment solution to increase revenue, and create a better payment experience."
          containerStyle="auth-illustration--woman-with-notebook"
          background={[
            { src: '/images/auth/banner/circle1.svg', className: 'auth-illustration__img--lv1' },
            { src: '/images/auth/banner/shape3.svg', className: 'auth-illustration__img--lv2' },
          ]}
          pictureSourceSet="/images/auth/banner/woman-with-notebook@1x.webp 1x, /images/auth/banner/woman-with-notebook@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/woman-with-notebook@1x.png"
          pictureImgSrcSet="/images/auth/banner/woman-with-notebook@2x.png 2x"
          pictureClassName="auth-illustration__img--lv3"
        />
      )}
      {/* Sign up */}
      {content === AuthBannerContent.SignUp && (
        <BannerContent
          title="Make payments easier."
          text="Integrate Zirtue as an alternative payment solution to increase revenue, and create a better payment experience."
          containerStyle="auth-illustration--two-women"
          background={[]}
          pictureSourceSet="/images/auth/banner/section-image-01.webp, /images/auth/banner/section-image-01.webp"
          pictureImgSrc="/images/auth/banner/section-image-01.png"
          pictureImgSrcSet="/images/auth/banner/section-image-01.png"
          pictureClassName="auth-illustration__img--lv3"
        />
      )}
      {/* Create account */}
      {content === AuthBannerContent.CreateAccount && (
        <>
          {(step === 1 || step === 2) && (
            <BannerContent
              title="Boost revenue."
              text="Offering Zirtue as a payment option helps companies turn at-risk payments into revenue -- ultimately reducing bad corporate debt."
              containerStyle="auth-illustration--man-with-notebook"
              background={[]}
              pictureSourceSet="/images/auth/banner/section-image-02.webp, /images/auth/banner/section-image-02.webp"
              pictureImgSrc="/images/auth/banner/section-image-02.png"
              pictureImgSrcSet="/images/auth/banner/section-image-02.png"
              pictureClassName="auth-illustration__img--lv3"
            />
          )}
          {step === 3 && (
            <BannerContent
              title="Build trust."
              text="Offering Zirtue as a payment option helps companies create a more-equitable payment experience -- baking ESG programming into their business."
              containerStyle="auth-illustration--woman-with-notes"
              background={[]}
              pictureSourceSet="/images/auth/banner/section-image-03.webp, /images/auth/banner/section-image-03.webp"
              pictureImgSrc="/images/auth/banner/section-image-03.png"
              pictureImgSrcSet="/images/auth/banner/section-image-03.png"
              pictureClassName="auth-illustration__img--lv3"
            />
          )}
          {step === 4 && (
            <BannerContent
              title="Check your inbox"
              text={stepFourText}
              containerStyle="auth-illustration--party"
              background={[]}
              pictureSourceSet="/images/auth/banner/section-image-04.webp, /images/auth/banner/section-image-04.webp"
              pictureImgSrc="/images/auth/banner/image-4-test.png"
              pictureImgSrcSet="/images/auth/banner/image-4-test.png"
              pictureClassName="auth-illustration__img--lv4"
            />
          )}
        </>
      )}
      {/* Forgot password */}
      {content === AuthBannerContent.ForgotPassword && (
        <BannerContent
          title="We all forget things sometimes."
          text="Contact our support team here if you have any questions or trouble resetting your password."
          containerStyle="auth-illustration--brain"
          background={[
            { src: '/images/auth/banner/circle2.svg', className: 'auth-illustration__img--lv1' },
            { src: '/images/auth/banner/shape2.svg', className: 'auth-illustration__img--lv2' },
          ]}
          pictureSourceSet="/images/auth/banner/brain@1x.webp 1x, /images/auth/banner/brain@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/brain@1x.png"
          pictureImgSrcSet="/images/auth/banner/brain@2x.png 2x"
          pictureClassName="auth-illustration__img--lv3"
        />
      )}
      {/* Check email */}
      {content === AuthBannerContent.CheckEmail && (
        <BannerContent
          containerStyle="auth-illustration--letter"
          background={[
            { src: '/images/auth/banner/circle2.svg', className: 'auth-illustration__img--lv1' },
            { src: '/images/auth/banner/shape4.svg', className: 'auth-illustration__img--lv2' },
          ]}
          pictureSourceSet="/images/auth/banner/letter@1x.webp 1x, /images/auth/banner/letter@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/letter@1x.png"
          pictureImgSrcSet="/images/auth/banner/letter@2x.png 2x"
          pictureClassName="auth-illustration__img--lv3"
          foreground={[
            {
              src: '/images/auth/banner/shape7.svg',
              className: 'auth-illustration__img--lv4',
            },
          ]}
        />
      )}
      {/* Reset password */}
      {content === AuthBannerContent.ResetPassword && (
        <BannerContent
          title="Change is good."
          text="Contact our support team here if you have any questions or resetting your password."
          containerStyle="auth-illustration--painter"
          background={[
            {
              src: '/images/auth/banner/circle2.svg',
              className: 'auth-illustration__img--lv1',
            },
            {
              src: '/images/auth/banner/shape5.svg',
              className: 'auth-illustration__img--lv2',
            },
          ]}
          pictureSourceSet="/images/auth/banner/painter@1x.webp 1x, /images/auth/banner/painter@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/painter@1x.png"
          pictureImgSrcSet="/images/auth/banner/painter@2x.png 2x"
          pictureClassName="auth-illustration__img--lv3"
        />
      )}
      {/* Verify */}
      {content === AuthBannerContent.Verify && (
        <BannerContent
          title="Verify that it’s you."
          text="To ensure the safety of your account, we’ll send a verification code to the email and phone number you used to sign up to Zirtue."
          containerStyle="auth-illustration--lock"
          background={[
            { src: '/images/auth/banner/circle2.svg', className: 'auth-illustration__img--lv1' },
          ]}
          pictureSourceSet="/images/auth/banner/lock@1x.webp 1x, /images/auth/banner/lock@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/lock@1x.png"
          pictureImgSrcSet="/images/auth/banner/lock@2x.png 2x"
          pictureClassName="auth-illustration__img--lv2"
          foreground={[
            {
              src: '/images/auth/banner/shape2.svg',
              className: 'auth-illustration__img--lv3',
            },
          ]}
        />
      )}
      {/* Invite */}
      {content === AuthBannerContent.Invite && (
        <BannerContent
          title="Nice to meet you."
          text="You’ve been invited to a team on Zirtue. Sign up to accept."
          containerStyle="auth-illustration--woman-with-mobile"
          background={[
            {
              src: '/images/auth/banner/circle3.svg',
              className: 'auth-illustration__img--lv1',
            },
            {
              src: '/images/auth/banner/shape2.svg',
              className: 'auth-illustration__img--lv2',
            },
          ]}
          pictureSourceSet="/images/auth/banner/woman-with-mobile@1x.webp 1x, /images/auth/banner/woman-with-mobile@2x.webp 2x"
          pictureImgSrc="/images/auth/banner/woman-with-mobile@1x.png"
          pictureImgSrcSet="/images/auth/banner/woman-with-mobile@2x.png 2x"
          pictureClassName="auth-illustration__img--lv3"
        />
      )}
    </AuthBannerLayout>
  );
};

export default AuthBanner;
