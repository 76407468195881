import { FormikErrors, FormikTouched } from 'formik';
import React, { useState } from 'react';

import { Input, Label, Select } from '../../../../../shared';
import { DataItem } from '../../../../../shared/components/Select';
import { Industry } from '../../../constants';
import { COMPANY_SIZE } from '../../../constants/selection';
import { SignUpWrapper } from '../../index';
import { FormData } from '../../SignUpForm/SignUpForm';

type DataType = {
  businessName: string;
  industry: number;
  companySize: number;
};
type FormicDataTypes = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  errors: FormikErrors<DataType>;
  values: Pick<FormData, 'businessName' | 'industry' | 'companySize'>;
  touched: FormikTouched<DataType>;
  setErrors: (errors: FormikErrors<FormData>) => void;
  setFieldValue: (
    field: string,
    value: string | null,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
};

export default function RegisterBusiness({
  errors,
  validateField,
  setFieldValue,
  handleChange,
  setErrors,
  touched,
  values,
}: FormicDataTypes) {
  const [activateValidationAfterOnBlur, setActivateValidationAfterOnBlur] = useState(false);
  const industryData = Object.values(Industry).map<DataItem>((i) => ({ name: i, dataId: i }));
  const companySizeData = Object.keys(COMPANY_SIZE).map<DataItem>((key) => ({
    name: COMPANY_SIZE[key as keyof typeof COMPANY_SIZE],
    dataId: key,
  }));

  // @ts-ignore
  const handleError = (id: string) => Boolean(errors[id]) && touched[id] && errors[id];

  const handleChangeField = (
    fieldName: 'businessName' | 'industry' | 'companySize',
    value: string,
  ) => {
    const newError = { ...errors };
    delete newError[fieldName];
    setErrors({ ...newError });
    setFieldValue(fieldName, value, false);
  };

  const createValidationTimeout = (
    fieldName: 'businessName' | 'industry' | 'companySize',
    validateField: (name: string) => void,
  ) => {
    const timeout = setTimeout(() => {
      validateField(fieldName);
      clearTimeout(timeout);
    }, 100);
  };

  const handleChangeBusinessName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (activateValidationAfterOnBlur) {
      createValidationTimeout('businessName', validateField);
    }
    handleChange(e);
  };

  const handleChangeCompanySize = (arg: keyof typeof COMPANY_SIZE) => {
    handleChangeField('companySize', arg.toString());
  };
  const handleChangeIndustry = (arg: keyof typeof Industry) => {
    handleChangeField('industry', arg.toString());
  };
  const handleBlurBusinessNameError = () => {
    setActivateValidationAfterOnBlur(true);
    validateField('businessName');
  };

  const handleBlurIndustryError = () => {
    createValidationTimeout('industry', validateField);
  };
  const handleBlurCompanySizeError = () => {
    createValidationTimeout('companySize', validateField);
  };

  return (
    <SignUpWrapper
      title="Register your business with Zirtue"
      description="Enter your business details below. We'll use this information to create your business [profile/account] in the Partner Portal."
    >
      <div className="new-sign-up__registerBusiness registerBusiness">
        <div className="form-group">
          <Label forId="businessName">Business name</Label>
          <Input
            type="text"
            id="businessName"
            placeholder="Zirtue sends payments to this name"
            value={values.businessName}
            onChange={handleChangeBusinessName}
            onBlur={handleBlurBusinessNameError}
            error={Boolean(errors.businessName) && touched.businessName && errors.businessName}
          />
        </div>
        <div className="form-group">
          <Label forId="industry">Industry</Label>
          <Select
            id="industry"
            placeholder="Enter business industry"
            data={industryData}
            value={values.industry}
            onChange={handleChangeIndustry}
            onBlur={handleBlurIndustryError}
            error={handleError('industry')}
          />
        </div>
        <div className="form-group">
          <Label forId="companySize">Company size</Label>
          <Select
            id="companySize"
            placeholder="Enter company size"
            data={companySizeData}
            value={values.companySize}
            onChange={handleChangeCompanySize}
            onBlur={handleBlurCompanySizeError}
            error={handleError('companySize')}
          />
        </div>
      </div>
    </SignUpWrapper>
  );
}
