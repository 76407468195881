import React from 'react';

import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { setModalRejectedOpen } from '../store/modals';

import Icon from './Icon';
import Modal from './Modal';

const ModalRejected = ({ onConfirm }: { onConfirm: () => void }) => {
  const { modalRejectedOpen, modalRejectedText, modalRejectedTitle } = useAppSelector(
    (state) => state.modals,
  );

  const dispatch = useAppDispatch();
  return (
    <Modal
      opened={modalRejectedOpen}
      handleClose={() => {
        dispatch(setModalRejectedOpen({ opened: false }));
      }}
      classes="modal--lg"
    >
      <div className="modal-content modal-content--invitation-sent">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalRejectedOpen({ opened: false }));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--success">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="/images/dashboard/modals/rejected.png"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">{modalRejectedTitle}</h2>
        <p className="modal-content__text">{modalRejectedText}</p>
        <div className="modal-content__btn-box">
          <button
            type="button"
            className="button button--primary-blue button--lg modal-content__btn"
            onClick={() => {
              dispatch(setModalRejectedOpen({ opened: false }));
              onConfirm();
            }}
          >
            Try again
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRejected;
