import React, { FC } from 'react';

import { Icon } from '../../../../shared';
import { DataTable, DataTableColumnConfig } from '../../../../shared/components/DataTable';
import { AdminPartnerDto } from '../../../../shared/models/platform-admin';
import { formatDate } from '../../../../shared/utils';

const handleCopyClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

const CopyBtn = ({ uuid }: AdminPartnerDto) => (
  <span className="table-account-number table-account-number--w100">
    <span className="table-account-number__text">{uuid}</span>
    <button
      className="table-account-number__copy-btn"
      type="button"
      onClick={() => handleCopyClipboard(uuid)}
    >
      <Icon name="copy" />
    </button>
  </span>
);

const COLUMNS: DataTableColumnConfig<AdminPartnerDto>[] = [
  {
    id: 'uuid',
    title: 'Partner uuid',
    valueProducer: CopyBtn,
  },
  {
    id: 'details.name',
    title: 'Partner name',
    valueProducer: (row) => row.details?.name,
    sort: true,
  },
  {
    id: 'createdAt',
    title: 'Registration date',
    valueProducer: (row) => formatDate(row.createdAt),
    sort: true,
  },
  {
    id: 'total',
    title: 'Total no. of deeplinks',
    valueProducer: (row) => row.deeplinksCount,
  },
  {
    id: 'deeplinks-clicks',
    title: 'Total no. of deeplink clicks',
  },
];

const getId = (loan: AdminPartnerDto) => loan.uuid;

interface PartnersTableProps {
  partners: AdminPartnerDto[];
  selectedId?: string;
  onPartnerClick: (row: AdminPartnerDto) => unknown;
}

// eslint-disable-next-line
export const PartnersTable: FC<PartnersTableProps> = ({ partners, onPartnerClick, selectedId }) => {
  return (
    <DataTable
      name="partners"
      columns={COLUMNS}
      data={partners}
      getId={getId}
      selectedId={selectedId}
      onRowClick={onPartnerClick}
    />
  );
};
