import classNames from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Permissions, { ORG_MBR_DEACTIVATED } from '../../../shared/constants/Permissions';
import { PartnerMember } from '../../../shared/models';
import { getSecurityGroupInfo } from '../../../shared/utils/helpers';
import { hasRole, isAdmin } from '../../auth/utils';
import { setModalDeactivateOpen } from '../store';

import MemberNameAvatar from './MemberNameAvatar';
import QuickActions from './QuickActions';

type Props = {
  member: PartnerMember;
};

const MemberRow = ({ member: item }: Props) => {
  const dispatch = useAppDispatch();

  const { roles } = useAppSelector((state) => state.company, shallowEqual);
  const { authToken: authInfo, user } = useAppSelector((state) => state.auth, shallowEqual);
  const canManage =
    authInfo && (hasRole(authInfo, Permissions.MEMBERS_MANAGE) || isAdmin(authInfo));
  const deactivated = item.orgRoles && item.orgRoles.includes(ORG_MBR_DEACTIVATED);
  const groupUuid = item.securityGroups && item.securityGroups[0];
  const roleInfo = groupUuid && getSecurityGroupInfo(groupUuid, roles);
  const ownerRole = user?.uuid === item.uuid ? { name: 'System Admin', color: 'mint50' } : null;

  const getMemberEmail = (contacts: string[]) => {
    const email = contacts.find((i) => i.includes('mailto:'));

    if (email) {
      return email.replace('mailto:', '');
    }

    return '';
  };

  return (
    <tr className={classNames('table__data-row', { 'table__data-row--deactivated': deactivated })}>
      <td className="table__cell table__cell--data table__cell--name">
        <MemberNameAvatar name={item.firstName || 'Member'} />
      </td>
      <td className="table__cell table__cell--data">
        {item.contacts && getMemberEmail(item.contacts)}
      </td>
      <td className="table__cell table__cell--data table__cell--status">
        <span className="table-highlight">{deactivated ? 'Deactivated' : 'Active'}</span>
      </td>
      <td className="table__cell table__cell--data">
        {roleInfo && (
          <span className={`role role--${roleInfo.color} table-highlight`}>{roleInfo.name}</span>
        )}
        {ownerRole && (
          <span className={`role role--${ownerRole.color} table-highlight`}>{ownerRole.name}</span>
        )}
      </td>
      <td className="table__cell table__cell--data table__cell--quick-actions">
        {canManage && (
          <QuickActions
            actions={[
              {
                id: 'deactivate',
                text: deactivated ? 'Activate' : 'Deactivate',
                iconName: 'power',
                additionalClass: deactivated
                  ? 'quick-actions__menu-link--green'
                  : 'quick-actions__menu-link--red',
              },
            ]}
            onPress={(id: string) => {
              switch (id) {
                case 'deactivate':
                  dispatch(
                    setModalDeactivateOpen({
                      isOpen: true,
                      memberId: item.uuid,
                      activate: deactivated,
                    }),
                  );
                  break;

                default:
                  break;
              }
            }}
          >
            <button type="button" className="table-dots">
              <Icon name="dots" classes="table-dots__icon" />
            </button>
          </QuickActions>
        )}
      </td>
    </tr>
  );
};

export default React.memo(MemberRow);
