import { useFormik } from 'formik';
import numeral from 'numeral';
import { useEffect } from 'react';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../../../main/store/hooks';
import { Input, Label } from '../../../../../shared';
import Switch from '../../../../../shared/components/Switch';
import { AlertType, addAlert } from '../../../../../shared/store/modals';
import { getLimitations } from '../../../../loans/store/actions/getLimitations';
import { adminGetLimitations } from '../../../store/actions/adminGetLimitations';
import { adminPatchLimitations } from '../../../store/actions/adminPatchLimitations';

const validationSchema = yup.object().shape({
  accountLimit: yup.number(),
  creationLimit: yup.number(),
  feeAmount: yup.number(),
  loanLimit: yup.string().max(999999999, 'revise input ...and be reasonable'),
  accountLimitEnabled: yup.boolean(),
  creationLimitEnabled: yup.boolean(),
  min: yup.number().min(2).max(yup.ref('max')).required('Required'),
  max: yup.number().min(yup.ref('min')).max(36).required('Required'),
  minOneTime: yup
    .number()
    .min(2, 'Min must be greater than 2')
    .max(yup.ref('maxOneTime'), 'Review input and revise')
    .required('Required'),
  maxOneTime: yup
    .number()
    .min(yup.ref('minOneTime'), 'Review input and revise')
    .max(36, 'Max must be less than 36')
    .required('Required'),
});

interface LoanCreationTabProps {
  selectedItemId?: string;
}

export default function LoanCreationTab({ selectedItemId }: LoanCreationTabProps) {
  const { partnerLimitations } = useAppSelector((state) => state.platformAdmin);
  if (!partnerLimitations) {
    return null;
  }
  console.log('partnerLimitations', partnerLimitations);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedItemId) {
      dispatch(adminGetLimitations(selectedItemId));
    }
  }, [selectedItemId]);

  const initialValues = {
    accountLimit: partnerLimitations.bankAccounts.value,
    creationLimit: partnerLimitations.loansCount.value,
    feeAmount: partnerLimitations.serviceFeePercent,
    loanLimit: partnerLimitations.maxLoanAmount,
    min: partnerLimitations.monthlyPayback.min,
    max: partnerLimitations.monthlyPayback.max,
    minOneTime: partnerLimitations.onetimePayback.min,
    maxOneTime: partnerLimitations.onetimePayback.max,
    accountLimitEnabled: partnerLimitations.bankAccounts.unlimited,
    creationLimitEnabled: partnerLimitations.loansCount.unlimited,
  };

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
    touched,
    values,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (data) => {
      if (!selectedItemId) {
        return;
      }
      dispatch(
        adminPatchLimitations({
          selectedItemId,
          formData: {
            bankAccounts: { value: data.accountLimit, unlimited: data.accountLimitEnabled },
            minLoanAmount: 2,
            maxLoanAmount: data.loanLimit,
            loansCount: { value: data.creationLimit, unlimited: data.creationLimitEnabled },
            serviceFeePercent: data.feeAmount,
            monthlyPayback: { min: data.min, max: data.max },
            onetimePayback: { min: data.minOneTime, max: data.maxOneTime },
          },
        }),
      );
    },
    validationSchema,
  });

  const onClickSubmitButtonHandler = () => {
    if (!isValid) {
      dispatch(
        addAlert({
          text: 'Please enter a valid value.',
          type: AlertType.dismiss,
        }),
      );

      return;
    }

    handleSubmit();
  };

  return (
    <div className="card">
      <form onSubmit={handleSubmit}>
        <div className="partners-tabs__line-input-wrapper">
          <Label classes="partners-tabs__form-label" forId="accountLimit">
            Implement bank account limits:
          </Label>

          <div className="partners-tabs__inputs-wrapper">
            <Input
              type="number"
              id="accountLimit"
              value={values.accountLimit}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.accountLimit && errors.accountLimit}
              isHideNumControls
              disabled={values.accountLimitEnabled}
            />
            <div className="partners-tabs__unlimited-text">Unlimited?</div>
            <Switch
              id="accountLimitEnabled"
              onChange={() => {
                setFieldValue('accountLimitEnabled', !values.accountLimitEnabled);
              }}
              checked={values.accountLimitEnabled}
              isShowText
            />
          </div>
        </div>

        <div className="partners-tabs__line-input-wrapper partners-tabs__inputs-wrapper--mr">
          <Label classes="partners-tabs__form-label" forId="loanLimit">
            Implement loan limits:
          </Label>
          <div className="partners-tabs__inputs-wrapper">
            <Input
              type="text"
              id="loanLimit"
              prependIconName="dollar-circle"
              value={numeral(values.loanLimit.toString()).format('0,000')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.loanLimit && errors.loanLimit}
              isHideNumControls
            />
          </div>
        </div>

        <div className="partners-tabs__line-input-wrapper">
          <Label classes="partners-tabs__form-label" forId="creationLimit">
            Implement loan creation limits:
          </Label>

          <div className="partners-tabs__inputs-wrapper">
            <Input
              type="number"
              id="creationLimit"
              value={values.creationLimit}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.creationLimit && errors.creationLimit}
              isHideNumControls
              disabled={values.creationLimitEnabled}
            />
            <div className="partners-tabs__unlimited-text">Unlimited?</div>
            <Switch
              id="creationLimitEnabled"
              onChange={() => {
                setFieldValue('creationLimitEnabled', !values.creationLimitEnabled);
              }}
              checked={values.creationLimitEnabled}
              isShowText
            />
          </div>
        </div>

        <div className="partners-tabs__line-input-wrapper partners-tabs__inputs-wrapper--mr">
          <Label classes="partners-tabs__form-label" forId="feeAmount">
            Service fee amount (%):
          </Label>
          <div className="partners-tabs__inputs-wrapper">
            <Input
              type="number"
              id="feeAmount"
              value={values.feeAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.feeAmount && errors.feeAmount}
              isHideNumControls
            />
          </div>
        </div>

        <div className="partners-tabs__line-input-wrapper">
          <Label classes="partners-tabs__form-label" forId="min">
            Payback period (Monthly):
          </Label>
          <div className="partners-tabs__inputs-wrapper partners-tabs__inputs-wrapper--small-inputs">
            <Input
              type="number"
              id="min"
              value={values.min}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.min && errors.min}
              isHideNumControls
              showError={false}
              showAttentionErrorIcon={false}
            />
            min
            <Input
              type="number"
              id="max"
              value={values.max}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.max && errors.max}
              isHideNumControls
              showError={false}
              showAttentionErrorIcon={false}
            />
            max
          </div>
        </div>

        <div className="partners-tabs__line-input-wrapper">
          <Label classes="partners-tabs__form-label" forId="minOneTime">
            One-time
          </Label>
          <div className="partners-tabs__inputs-wrapper partners-tabs__inputs-wrapper--small-inputs">
            <Input
              type="number"
              id="minOneTime"
              value={values.minOneTime}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.minOneTime && errors.minOneTime}
              isHideNumControls
              showError={false}
              showAttentionErrorIcon={false}
            />
            min
            <Input
              type="number"
              id="maxOneTime"
              value={values.maxOneTime}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.maxOneTime && errors.maxOneTime}
              isHideNumControls
              showError={false}
              showAttentionErrorIcon={false}
            />
            max
          </div>
        </div>

        <div className="partners-tabs__submit-wrapper">
          <button
            type="button"
            className={`button button--primary-blue button--lg ${
              !isValid && 'button--like-disabled'
            }`}
            onClick={onClickSubmitButtonHandler}
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
}
