import React from 'react';

import { FormWrapper } from '../../../init-setup/components';
import calendar from '../../assets/loan-repayment-requests/interface-calendar-1.svg';
import face from '../../assets/loan-repayment-requests/interface-user-face.svg';
import umbrella from '../../assets/loan-repayment-requests/shopping-business-insurance-umbrella.svg';

export const LoanRepaymentRequests = () => (
  <FormWrapper>
    <div className="loanTypeForm loans__loanRepaymentRequests">
      <div className="loans__loanRepaymentRequests__container">
        <div className="loanTypeForm__head">
          <h2 className="loanTypeForm__title">Repayment requests</h2>
          <div className="loanTypeForm__subtitle">
            We manage the repayment of unpaid
            <br />
            loans so that you don’t have to.
          </div>
        </div>
        <div className="loans__loanRepaymentRequests__body">
          <div className="loans__loanRepaymentRequests__item">
            <div className="loans__loanRepaymentRequests__item__image">
              <img src={face} alt="face" />
            </div>
            <div className="loans__loanRepaymentRequests__item__descr">
              <div className="loans__loanRepaymentRequests__item__title">Choose a borrower</div>
              <div className="loans__loanRepaymentRequests__item__subtitle">
                Enter their contact info.
              </div>
            </div>
          </div>
          <div className="loans__loanRepaymentRequests__divider" />
          <div className="loans__loanRepaymentRequests__item">
            <div className="loans__loanRepaymentRequests__item__image">
              <img src={umbrella} alt="umbrella" />
            </div>
            <div className="loans__loanRepaymentRequests__item__descr">
              <div className="loans__loanRepaymentRequests__item__title">
                Add remaining loan amount
              </div>
              <div className="loans__loanRepaymentRequests__item__subtitle">
                This is the amount the borrower should pay back.
              </div>
            </div>
          </div>
          <div className="loans__loanRepaymentRequests__divider" />
          <div className="loans__loanRepaymentRequests__item">
            <div className="loans__loanRepaymentRequests__item__image">
              <img src={calendar} alt="calendar" />
            </div>
            <div className="loans__loanRepaymentRequests__item__descr">
              <div className="loans__loanRepaymentRequests__item__title">Choose repayment plan</div>
              <div className="loans__loanRepaymentRequests__item__subtitle">
                One-time or monthly repayment plan. Whatever best fits your needs.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FormWrapper>
);
