import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  show?: boolean;
  onClick: () => void;
};

export default function Backdrop({ show = false, onClick }: Props) {
  const [backdropClasses, setBackdropClasses] = useState('');

  const handleClick = useCallback(() => onClick(), [onClick]);

  useEffect(() => {
    if (show) {
      setBackdropClasses('backdrop--will-toggle');
      setTimeout(() => {
        setBackdropClasses('backdrop--show');
      }, 100);
    } else {
      setBackdropClasses('backdrop--will-toggle');
      setTimeout(() => {
        setBackdropClasses('');
      }, 200);
    }
  }, [show]);

  return (
    <>
      <div
        aria-hidden="true"
        className={`backdrop ${backdropClasses || ''}`}
        onClick={handleClick}
        data-testid="backdrop-id"
      />
    </>
  );
}
