import classNames from 'classnames';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Modal } from '../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { setModalDeactivateOpen } from '../store';
import { getCompanyMembers, deactivateMember } from '../store/actions';

const ModalDeactivateMember: React.FC = () => {
  const {
    modalDeactivateOpen: { isOpen, memberId, activate },
  } = useAppSelector((state) => state.company);
  const dispatch = useAppDispatch();

  const handleDeactivateClick = useCallback(async () => {
    if (!memberId) {
      return;
    }

    const result = await dispatch(deactivateMember({ memberId, active: activate }));

    if (deactivateMember.fulfilled.match(result)) {
      if (!activate) {
        Amplitude.logEvent(AmplitudeEvents.memberDeactivated);
      }

      dispatch(setModalDeactivateOpen({ isOpen: false }));
      dispatch(addAlert({ text: `User ${activate ? 'activated' : 'deactivated'}` }));
      dispatch(getCompanyMembers());
    }

    if (deactivateMember.rejected.match(result)) {
      dispatch(setModalDeactivateOpen({ isOpen: false }));
      dispatch(addAlert({ text: 'Deactivate user error', type: AlertType.error }));
    }
  }, [memberId, dispatch, activate]);

  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        dispatch(setModalDeactivateOpen({ isOpen: false }));
      }}
      classes="modal--lg"
    >
      <div className="modal-content modal-content--deactivate-member">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalDeactivateOpen({ isOpen: false }));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--deactivate">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/deactivate-member-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/deactivate-member-lv2@1x.webp 1x, images/dashboard/modals/deactivate-member-lv2@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/deactivate-member-lv2@1x.png"
              srcSet="images/dashboard/modals/deactivate-member-lv2@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
        </div>
        {/* Content */}
        <h2
          className={classNames('modal-content__title', { 'modal-content__title--mb': activate })}
        >
          {activate ? 'Activate team member?' : 'Deactivate team member?'}
        </h2>
        {!activate && (
          <p className="modal-content__text">
            Deactivating a team member is permanent and will remove their access to all data. Are
            you sure you want to deactivate?
          </p>
        )}
        <div className="modal-content__btn-box">
          <button
            type="button"
            className="button button--primary-blue button--lg modal-content__btn"
            onClick={handleDeactivateClick}
          >
            {activate ? 'Activate' : 'Deactivate'}
          </button>
          <button
            type="button"
            className="button button--secondary-blue button--lg modal-content__btn"
            onClick={() => {
              dispatch(setModalDeactivateOpen({ isOpen: false }));
            }}
          >
            {activate ? 'No, don’t activate' : 'No, don’t deactivate'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeactivateMember;
