import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import {
  LoanPresetCreateDto,
  LoanPresetCreatedDto,
} from '../../../../shared/models/platform-admin/loans/LoanPresetDto';
import { parseError } from '../../../../shared/utils/helpers';

import { adminPartnersSearch } from './adminPartnersSearch';

export const adminLoanPresetCreate = createAsyncThunk<
  LoanPresetCreatedDto,
  LoanPresetCreateDto,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminLoanPresetCreate', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.put<LoanPresetCreatedDto>('/platform-admin/loan-presets', payload);

    // @ts-ignore
    dispatch(adminPartnersSearch());
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
