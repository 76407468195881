import React, { FC } from 'react';

import { DataTable, DataTableColumnConfig } from '../../../../shared/components/DataTable';
import { UserDto } from '../../../../shared/models/platform-admin';
import { getContact, formatDate, formatRoles } from '../../../../shared/utils';

const getEmail = ({ defaultContacts }: UserDto) =>
  defaultContacts?.filter((c) => c.startsWith('mailto:'))?.map(getContact);

const getPhone = ({ defaultContacts }: UserDto) =>
  defaultContacts?.filter((c) => c.startsWith('tel:'))?.map(getContact);

interface UsersTableProps {
  users: UserDto[];
  selectedUserId?: string;
  onUserClick?: (user: UserDto) => unknown;
}

const COLUMNS: DataTableColumnConfig<UserDto>[] = [
  {
    id: 'uuid',
    title: 'uuid',
    copyButton: true,
  },
  {
    id: 'firstName',
    title: 'First Name',
    sort: true,
  },
  {
    id: 'lastName',
    title: 'Last Name',
    sort: true,
  },
  {
    id: 'email',
    title: 'Email',
    valueProducer: getEmail,
  },
  {
    id: 'phone',
    title: 'Phone',
    valueProducer: getPhone,
  },
  {
    id: 'roles',
    title: 'Roles',
    valueProducer: ({ roles }) => formatRoles(roles),
  },
  {
    id: 'onboarding.onboardFlowType',
    title: 'Onboarding Type',
    valueProducer: ({ onboarding }) => onboarding && onboarding.onboardFlowType,
    sort: true,
  },
  {
    id: 'onboardingLoan',
    title: 'Onboarding Loan',
    valueProducer: ({ onboarding }) => onboarding && onboarding.loanId,
  },
];

const getId = (user: UserDto) => user.uuid;

export const UsersTable: FC<UsersTableProps> = ({ users, selectedUserId, onUserClick }) => (
  <DataTable
    name="users"
    columns={COLUMNS}
    data={users}
    getId={getId}
    onRowClick={onUserClick}
    selectedId={selectedUserId}
  />
);
