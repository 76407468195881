import { useCallback } from 'react';

import { LoanAnalyticsDto } from '../../store';
import LineChartSection from '../Charts/LineChartSection';
import RateCard from '../Charts/RateCard';
import TotalCard from '../Charts/TotalCard';

import { Days } from './DayTab';
import Filters from './Filters';
import PieChart from './PieChart';

interface OverviewMetricsProps {
  loanAnalytics: LoanAnalyticsDto;
  handleFilterChange: (value: { reason: string; periodTime: string[] }) => void;
}

export default function OverviewMetrics({
  loanAnalytics: {
    totalLoansCreated,
    totalLoansAccepted,
    totalLoansRepaid,
    delinquencyRate,
    reasonsForLoans,
  },
  handleFilterChange,
}: OverviewMetricsProps) {
  const getPieChartData = useCallback((data: { [key: string]: string | number }[], key: string) => {
    const labels: string[] = [];
    const values: number[] = [];

    data.forEach((item) => {
      const name = item[key] as string | undefined;
      if (name) {
        const nameReplaces = name.replaceAll('_', ' ') as string;
        labels.push(nameReplaces.charAt(0).toUpperCase() + nameReplaces.slice(1));
        values.push(item.count as number);
      }
    });

    return { labels, data: values };
  }, []);

  return (
    <div style={{ marginBottom: 24 }} className="card card--shadow">
      <Filters isReasonLabel={false} disabled={false} onChange={handleFilterChange} />
      <section className="loans-overview__metric-section">
        {reasonsForLoans.length > 0 && (
          <div style={{ padding: '24px 16px 0 26px' }} className="card">
            <h3 className="loans-overview__metric-section__title">Reasons for loans</h3>
            <PieChart data={getPieChartData(reasonsForLoans, 'reason')} />
          </div>
        )}

        <div>
          <LineChartSection title="Total loans created" value={totalLoansCreated} percent={25} />
          <TotalCard title="Total loans repaid" value={totalLoansRepaid} percent={6} />
        </div>

        <div>
          <LineChartSection title="Total loans accepted" value={totalLoansAccepted} percent={12} />
          <RateCard title="Delinquency rate" percent={delinquencyRate} />
        </div>
      </section>
    </div>
  );
}
