import React from 'react';

import { DateFilter, Icon } from '../../../shared';
import LogService from '../../../shared/services/logging';
import { DashboardChart, Indicator } from '../components';
import DashboardLayout from '../layout/DashboardLayout';

export default function Insights() {
  return (
    <DashboardLayout pageTitle="Dashboard">
      <div className="insights">
        <div className="row">
          <div className="col insights__chart-col">
            <div className="insights__chart-box">
              <div className="insights__chart-top">
                <div className="insights__chart-top-wrapper">
                  <div className="insights__icon-box">
                    <Icon name="dollar-circle" classes="insights__icon" />
                  </div>
                  <div className="insights__chart-title-box">
                    <p className="insights__subtitle">Total revenue</p>
                    <p className="insights__title">$72,000</p>
                  </div>
                </div>
                <DateFilter
                  classes="insights__chart-date-filter"
                  onChangePeriod={(value) => LogService.logDebug(value)}
                />
              </div>
              <div className="insights__chart">
                <DashboardChart />
              </div>
            </div>
          </div>
          <div className="col insights__indicators-col">
            <DateFilter
              classes="insights__chart-indicator-filter"
              onChangePeriod={(value) => LogService.logDebug(value)}
            />
            {/* Indicator */}
            <Indicator
              title="Total clicks"
              value="14,000"
              difference="+55%"
              icon="hand-finger"
              classes="insights__indicator"
            />
            {/* Indicator */}
            <Indicator
              title="Average clicks per day"
              value="24"
              difference="+12%"
              icon="speedometr"
              classes="insights__indicator"
            />
            {/* Indicator */}
            <Indicator
              title="Total installs "
              value="$72,000"
              icon="lightning"
              classes="insights__indicator"
            />
            {/* Indicator */}
            <Indicator
              title="New users"
              value="72,000"
              icon="people"
              classes="insights__indicator"
            />
            {/* Indicator */}
            <Indicator
              title="Accepted requets"
              value="62"
              icon="quality-mark"
              classes="insights__indicator"
            />
            {/* Indicator */}
            <Indicator
              title="Bills paid"
              value="53,000"
              icon="document-piece"
              classes="insights__indicator"
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
