import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import {
  LoansSearchQueryDto,
  LoansSearchResponseDto,
} from '../../../../shared/models/platform-admin';
import { selectLoansState } from '../selectors/loans';

export const adminLoansSearch = createAsyncThunk<
  LoansSearchResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>('platformAdmin/adminLoansSearch', async (_, { getState }) => {
  const {
    page,
    pageSize,
    searchQuery,
    dateRangeFilter = [],
    stage,
    sortField,
    sortOrder,
  } = selectLoansState(getState());
  const [dateStart, dateEnd] = dateRangeFilter || [];

  const { data } = await api.get<LoansSearchResponseDto>('/platform-admin/loans/search', {
    params: <LoansSearchQueryDto>{
      skip: (page - 1) * pageSize,
      limit: pageSize,
      searchText: searchQuery.replace('+', ''),
      dateStart,
      dateEnd,
      stage,
      sortField,
      sortOrder,
    },
  });

  return data;
});
