import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Alert } from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { AuthBannerContent } from '../components/AuthBanner';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { forgotPasswordStart } from '../store/actions';

const RETRY_DELAY = 30; // in seconds

const CheckEmail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.auth);
  const { push } = useHistory();

  const [secondsRemaining, setSecondsRemaining] = useState(RETRY_DELAY);
  const timer = useRef<NodeJS.Timeout>();

  const reSendLink = async () => {
    const emailAddress = email || localStorage.getItem(StoreKeys.email);

    if (emailAddress) {
      // @ts-ignore
      const result = await dispatch(forgotPasswordStart({ email: emailAddress }));

      if (forgotPasswordStart.fulfilled.match(result)) {
        setSecondsRemaining(RETRY_DELAY);
      }

      if (forgotPasswordStart.rejected.match(result)) {
        dispatch(
          addAlert({
            text: result.payload?.message || 'Unknown error',
            type: AlertType.error,
          }),
        );
      }
    } else {
      push('/forgot-password');
    }
  };

  useEffect(() => {
    const tick = () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
        // @ts-ignore
        timer.current = setTimeout(() => tick(), 1000);
      }
    };

    // @ts-ignore
    timer.current = setTimeout(() => tick(), 1000);

    // @ts-ignore
    return () => clearTimeout(timer.current);
  }, [secondsRemaining]);

  return (
    <AuthorizationLayout bannerContent={AuthBannerContent.CheckEmail}>
      <form action="#" className="auth-form auth-form--styled">
        <h1 className="auth-form__title">You’ve got mail.</h1>
        <p className="auth-form__text">
          {`We’ve sent you an email to ${email} with instructions to reset your password.`}
        </p>

        <button
          type="button"
          className="button button--primary-blue button--lg button-block"
          onClick={reSendLink}
          disabled={secondsRemaining > 0}
        >
          {secondsRemaining <= 0
            ? 'Resend link'
            : `You can resend link in ${secondsRemaining} seconds.`}
        </button>
      </form>
    </AuthorizationLayout>
  );
};

export default CheckEmail;
