import { useState, useCallback, useEffect } from 'react';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Input, Label } from '../../../shared';
import ModalImagePreview from '../../integrations/components/ModalImagePreview';
import SftpResetPassword from '../components/SftpResetPassword';
import ValidationPreview from '../components/ValidationPreview';
import DevToolsLayout from '../layout/DevToolsLayout';
import { getSftpSettings } from '../store/actions/getSftpSettings';

const SftpConfigurationPage = () => {
  const dispatch = useAppDispatch();
  const { sftpSetting } = useAppSelector((state: RootState) => state.devTools);

  const [isOpen, setIsOpen] = useState(true);
  const [isZoomModal, setIsZoomModal] = useState(false);
  const [isResetPasswordPage, setIsResetPasswordPage] = useState(false);

  useEffect(() => {
    dispatch(getSftpSettings());
  }, []);

  const handleToggleValidationPreview = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleZoomModal = useCallback(() => {
    setIsZoomModal(!isZoomModal);
  }, [isZoomModal]);

  const handleResetPasswordPage = useCallback(() => {
    setIsResetPasswordPage(!isResetPasswordPage);
  }, [isResetPasswordPage]);

  if (isResetPasswordPage) {
    return <SftpResetPassword handleResetPasswordPage={handleResetPasswordPage} />;
  }

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Configuration</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">Configuration</h2>
      <div className="validation__wrapper">
        <div className={`validation card ${!isOpen && 'open'}`}>
          <h3 className="validation-account__title">SFTP setup</h3>
          <h4 className="validation-account__subtitle">Review the SFTP configurations below.</h4>
          <form className="validation-account__form">
            <div className="validation-account__password-wrapper">
              <div className="form-group">
                <Label forId="Hostname">Hostname</Label>
                <Input type="text" id="Hostname" value={sftpSetting?.sftp?.host} disabled />
              </div>

              <div className="form-group">
                <Label forId="passwordConfirmation">Port number</Label>
                <Input
                  type="number"
                  id="passwordConfirmation"
                  value={sftpSetting?.sftp?.port}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <Label forId="name">Username</Label>
              <Input type="text" id="name" value={sftpSetting?.sftp?.userName} disabled />
            </div>
            <div className="validation-account__reset-password">
              <div className="form-group">
                <Label forId="password">Password</Label>
                <Input type="text" id="password" value="********" disabled />
              </div>

              <div
                className="validation-account__reset"
                onClick={handleResetPasswordPage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleZoomModal();
                  }
                }}
                tabIndex={0}
                role="button"
              >
                <Icon name="arrow-reset" classes="validation-account__reset-icon" />
                Reset
              </div>
            </div>

            <div className="form-group">
              <Label forId="Path">Path</Label>
              <Input type="text" id="Path" value="/account-validation-files" disabled />
            </div>
          </form>
        </div>
        <ValidationPreview
          handleToggleValidationPreview={handleToggleValidationPreview}
          isOpen={isOpen}
          toggleZoomModal={toggleZoomModal}
        />
      </div>
      <ModalImagePreview
        isOpen={isZoomModal}
        setIsOpen={setIsZoomModal}
        image="/images/dashboard/modals/zirtue-preview-experience-3.png"
      />
    </DevToolsLayout>
  );
};

export default SftpConfigurationPage;
