import classNames from 'classnames';
import { format, isDate, parse } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, ChangeEvent, FC } from 'react';
import Autocomplete from 'react-google-autocomplete';
import * as yup from 'yup';

import { GOOGLE_PLACES } from '../../../main/env';
import { useAppDispatch } from '../../../main/store/hooks';
import {
  CompanyUpdateData,
  DateInput,
  Input,
  Label,
  Icon,
  Select,
  MaskInput,
} from '../../../shared';
import { DataItem } from '../../../shared/components/Select';
import { Partner } from '../../../shared/models/PartnerModel';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { FlowTypes } from '../../auth/store';
import { getPartner } from '../../auth/store/actions';
import { Industry } from '../../sign-up/constants';
import { updateCompany } from '../store/actions/updateCompany';

function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yyyy', new Date());

  return parsedDate;
}

type FormValues = {
  businessType: string;
  industry: string;
  postalAddress: string;
  billingAddress: string;
  postalAddressId?: string;
  billingAddressId?: string;
  ein?: string;
};

type Props = {
  partner: Partner;
};

const industryData = Object.values(Industry).map<DataItem>((i) => ({ name: i, dataId: i }));

const CompanyDetailsInfoEdit: FC<Props> = ({ partner }: Props) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    formationDate: yup
      .date()
      .transform(parseDateString)
      .max(new Date())
      .label('Formation Date')
      .typeError('Please, fill the date correctly'),
    businessType: yup.string().min(3).label('Business Type'),
    industry: yup.string().min(3).label('Business Industry'),
    postalAddress: yup.string().test('is-postal', 'Postal address is not valid', (val) => {
      const isValid = val === undefined || (typeof val === 'string' && val.length > 3);

      return isValid;
    }),
    billingAddress: yup.string().test('is-billing', 'Billing address is not valid', (val) => {
      const isValid = val === undefined || (typeof val === 'string' && val.length > 3);

      return isValid;
    }),
    ein: yup.string().trim().min(9, 'EIN must be 9 digits long.').label('EIN'),
  });

  const updCompany = async (req: CompanyUpdateData) => {
    const result = await dispatch(updateCompany(req));

    if (updateCompany.fulfilled.match(result)) {
      dispatch(addAlert({ text: 'Save changes successfully' }));

      setTimeout(async () => {
        await dispatch(getPartner());
      }, 2000);
    }

    if (updateCompany.rejected.match(result)) {
      dispatch(addAlert({ text: 'Error while adding account.', type: AlertType.error }));
    }
  };

  const onSubmit = useCallback(
    async (v: FormValues, submitProps: FormikHelpers<FormValues>) => {
      const req: CompanyUpdateData = {};

      if (partner?.details.businessType !== v.businessType) {
        req.businessType = v.businessType;
      }
      if (partner?.details.ein !== v.ein) {
        req.ein = v.ein;
      }
      if (partner?.details.industry !== v.industry) {
        req.industry = v.industry;
      }
      if (partner?.details.postalAddress !== v.postalAddress) {
        req.postalAddress = v.postalAddress;
      }
      if (partner?.details.billingAddress !== v.billingAddress) {
        req.billingAddress = v.billingAddress;
      }

      await updCompany(req);
      submitProps.resetForm({ values: v });
    },
    [updCompany, partner],
  );

  const {
    handleBlur,
    errors,
    values,
    touched,
    setFieldValue,
    isValid,
    dirty,
    submitForm,
    setSubmitting,
    validateField,
    setErrors,
    isSubmitting,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: {
      businessType: partner.details.businessType || '',
      industry: partner.details.industry || '',
      postalAddress: partner.details.postalAddress || '',
      billingAddress: partner.details.billingAddress || '',
      ein: partner.details.ein || '',
    },
    onSubmit,
    validationSchema,
    validateOnChange: true,
  });

  const fieldChangeNew = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSubmitting(false);
    setFieldValue(target.id, target.value.replaceAll('-', ''));
  };

  const handleChangePostalAddress = () => {
    setFieldValue('postalAddress', '', true);
  };

  const handleAddPlace = (
    { formatted_address: formattedAddress }: { formatted_address: string; place_id: string },
    fieldName: string,
  ) => {
    setFieldValue(fieldName, formattedAddress, true);
    setFieldTouched(fieldName);
  };

  const createValidationTimeout = (
    fieldName: 'industry',
    validateField: (name: string) => void,
  ) => {
    const timeout = setTimeout(() => {
      validateField(fieldName);
      clearTimeout(timeout);
    }, 100);
  };

  // @ts-ignore
  const handleError = (id: string) => Boolean(errors[id]) && touched[id] && errors[id];

  const handleChangeField = (fieldName: 'industry', value: string) => {
    const newError = { ...errors };
    delete newError[fieldName];
    setErrors({ ...newError });
    setFieldValue(fieldName, value, false);
  };
  const handleChangeIndustry = (arg: keyof typeof Industry) => {
    handleChangeField('industry', arg.toString());
  };

  const handleBlurIndustryError = () => {
    createValidationTimeout('industry', validateField);
  };

  const NOTP2P = partner?.details.flowType !== FlowTypes.AFFILIATE_PARTNER;

  return (
    <div className="col profile-edit__col-details">
      <form className="card">
        <h2 className="card__title">Company details</h2>
        <p className="card__text">
          You can update and edit company details by clicking any of the fields below
        </p>
        {/* Intentional comment, may be used in the future */}

        {/* <div className="form-group mb-8">
          <Label forId="business-type">Business type</Label>
          <Input
            type="text"
            id="businessType"
            placeholder="Business type"
            value={values.businessType}
            onChange={fieldChangeNew}
            onBlur={handleBlur}
            error={Boolean(errors.businessType) && touched.businessType && errors.businessType}
          />
        </div> */}
        <div className="form-group mb-8">
          <Label forId="ein">EIN</Label>
          <MaskInput
            id="ein"
            mask="99-9999999"
            value={values.ein}
            inputClassName="input input-group__input"
            placeholder={NOTP2P ? '' : '11-0000000'}
            onChange={fieldChangeNew}
            onBlur={handleBlur}
            disabled={NOTP2P}
            error={Boolean(errors.ein) && touched.ein && errors.ein}
          />
        </div>
        <div className="form-group">
          <Label forId="industry">Industry</Label>
          <Select
            id="industry"
            placeholder="Enter business industry"
            data={industryData}
            value={values.industry}
            onChange={handleChangeIndustry}
            onBlur={handleBlurIndustryError}
            error={handleError('industry')}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="postal-address">Corporate address</Label>
          <div className="autocomplete">
            <Autocomplete
              className={classNames('autocomplete__input', {
                'autocomplete__input--error': errors.postalAddress && touched.postalAddress,
              })}
              apiKey={GOOGLE_PLACES()}
              placeholder="Corporate address"
              defaultValue={values.postalAddress}
              onPlaceSelected={(place) => handleAddPlace(place, 'postalAddress')}
              onChange={handleChangePostalAddress}
              options={{
                types: ['street_address'],
                language: 'en',
                componentRestrictions: { country: 'usa' },
              }}
              onBlur={handleBlur('postalAddress')}
            />
            <div className="autocomplete__icon">
              <Icon name="chevron" />
            </div>
            {errors.postalAddress && touched.postalAddress && (
              <div className="autocomplete__error">{errors.postalAddress}</div>
            )}
          </div>
        </div>
        {/* Intentional comment, may be used in future */}
        {/* <div className="form-group mb-8">
          <Label forId="billing-address">Billing address</Label>
          <div className="autocomplete">
            <Autocomplete
              className={classNames('autocomplete__input', {
                'autocomplete__input--error': errors.billingAddress && touched.billingAddress,
              })}
              apiKey={GOOGLE_PLACES()}
              placeholder="Billing address"
              onPlaceSelected={(place) => handleAddPlace(place, 'billingAddress')}
              onChange={handleChangeBillingAddress}
              defaultValue={values.billingAddress}
              options={{
                types: ['street_address'],
                language: 'en',
                componentRestrictions: { country: 'usa' },
              }}
              onBlur={handleBlur('billingAddress')}
            />
            <div className="autocomplete__icon">
              <Icon name="chevron" />
            </div>
            {errors.billingAddress && touched.billingAddress && (
              <div className="autocomplete__error">{errors.billingAddress}</div>
            )}
          </div>
        </div> */}
        <button
          type="submit"
          className="button button--primary-blue button--lg button--block"
          onClick={(e) => {
            e.preventDefault();
            submitForm();
          }}
          disabled={!(isValid && dirty) || isSubmitting}
        >
          Save changes
        </button>
      </form>
    </div>
  );
};

export default CompanyDetailsInfoEdit;
