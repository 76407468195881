import React from 'react';

import { CheckoutListItem } from '../hooks/useCheckoutList';

const CheckoutListProgressBar = ({ listItems }: { listItems: CheckoutListItem[] }) => {
  const completedItems = listItems.filter((item) => item.isDone).length;
  const totalItems = listItems.length;

  const progressPercentage = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;

  const progressStyle = {
    width: `${progressPercentage}%`,
  };

  return (
    <div className="checkout-list__progress-bar">
      <div className="checkout-list__progress-bar__progress" style={progressStyle} />
    </div>
  );
};

export default CheckoutListProgressBar;
