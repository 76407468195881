import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { api } from '../../../main/network';
import { useAppDispatch } from '../../../main/store/hooks';
import {
  LoanPresetDto,
  LoanPresetSearchResponseDto,
} from '../../../shared/models/platform-admin/loans/LoanPresetDto';
import { AlertType, addAlert } from '../../../shared/store/modals';

const useIntegrations = () => {
  const [presets, setPresets] = useState<LoanPresetDto[]>([]);
  const dispatch = useAppDispatch();
  const getPresets = async () => {
    try {
      const response = await api.get<LoanPresetSearchResponseDto>('/loan-presets/list');
      setPresets(response.data.presets);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          addAlert({
            text: "Couldn't load Deep Links",
            type: AlertType.error,
          }),
        );
      }
    }
  };

  const hasQuickLink = presets.length !== 0;

  useEffect(() => {
    getPresets();
  }, []);

  return {
    operations: {},
    properties: {
      presets,
      hasQuickLink,
    },
  };
};

export default useIntegrations;
