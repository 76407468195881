import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { ServerError } from '../../../../shared';

import type { AppDispatch, RootState } from '../../../../main/store';

export const sendInvitaion = createAsyncThunk<
  undefined,
  { emailsUris: Array<string>; role?: string },
  { dispatch: AppDispatch; rejectValue: ServerError; state: RootState }
>('company/deactivateMember', async ({ emailsUris, role }, { rejectWithValue }) => {
  try {
    await api.post(`/invitations`, {
      securityGroups: [role],
      membersEmailsUris: emailsUris,
      roles: [],
    });

    return undefined;
  } catch (e) {
    // @ts-ignore
    return rejectWithValue(e.response.data);
  }
});
