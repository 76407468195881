import { format } from 'date-fns';
import React, { FC } from 'react';

import { Icon } from '../../../../shared';
import { DataTableColumnConfig } from '../../../../shared/components/DataTable';
import { DataTableHeader } from '../../../../shared/components/DataTable/DataTableHeader';
import { TransferDto } from '../../../../shared/models/platform-admin/transactions/TransferDto';
import { formatDate } from '../../../../shared/utils';
import { AdminTableName } from '../../store';

import TableTransferStatus from './TableTransferStatus';

interface TransactionsTableProps {
  transactions: TransferDto[];
  selectedTransactionId?: string;
  onTransactionClick?: (loan: TransferDto) => unknown;
  name?: AdminTableName;
}

const handleCopyClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

const CopyBtn = ({ loanId }: TransferDto) => (
  <span className="table-account-number table-account-number--w100">
    <span className="table-account-number__text">{loanId}</span>
    <button
      className="table-account-number__copy-btn"
      type="button"
      onClick={() => handleCopyClipboard(loanId)}
    >
      <Icon name="copy" />
    </button>
  </span>
);

const COLUMNS: DataTableColumnConfig<TransferDto>[] = [
  {
    id: 'loanId',
    title: 'Loan ID',
    valueProducer: CopyBtn,
  },
  {
    id: 'AMOUNT',
    title: 'Amount',
    valueProducer: ({ amount }) => `${amount?.value + amount?.currency || '-'}`,
    sort: true,
  },
  {
    id: 'transferStatus',
    title: 'Transfer Status',
    valueProducer: ({ transferStatus }) => transferStatus,
  },
  {
    id: 'CREATED_AT',
    title: 'Transfer creation',
    valueProducer: ({ createdAt }) => formatDate(createdAt),
    sort: true,
  },
];

const getId = (transfer: TransferDto) => transfer.uuid;

const getCreatedDate = (date: string) => format(new Date(date), 'MM/dd/yyyy, h:mm a');

const isValidDate = (d?: string) => {
  if (d === undefined || d === null) {
    return false;
  }
  const parsed = Date.parse(d);

  return !Number.isNaN(parsed);
};

function handleCopy(loanId: string) {
  navigator.clipboard.writeText(loanId);
}

export const TransactionsTable: FC<TransactionsTableProps> = ({
  transactions,
  selectedTransactionId,
  onTransactionClick,
  name = 'transactions',
}) => (
  <>
    <div className="table-scroll">
      <div className="table-scroll__box">
        <table className="table">
          <DataTableHeader columns={COLUMNS} name={name} />
          {transactions.map(({ uuid, loanId, amount, transferStatus, createdAt }: TransferDto) => {
            const createAtStr = isValidDate(createdAt) ? getCreatedDate(createdAt || '') : '';

            return (
              <tr className="table__data-row" key={uuid}>
                <td className="table__cell table__cell--data">
                  <div className="table-loan-id">
                    <span className="table-account-number__text">{loanId}</span>
                    <button
                      type="button"
                      className="table-account-number__copy-btn"
                      onClick={() => handleCopy(loanId)}
                    >
                      <Icon name="copy" classes="table-account-number__copy-btn-icon" />
                    </button>
                  </div>
                </td>
                <td className="table__cell table__cell--data">
                  {`${amount?.value || ''} ${amount?.currency || '-'}`}
                </td>
                <td className="table__cell table__cell--data">
                  <TableTransferStatus type={transferStatus || '-'} />
                </td>
                <td className="table__cell table__cell--data">{createAtStr}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  </>
);
