import differenceInDays from 'date-fns/differenceInDays';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import * as yup from 'yup';

import { Icon, useOnClickOutside } from '../index';
import { DateRangeTuple } from '../types';
import { getTimePeriod } from '../utils';

export type PrdItem = {
  val: number;
  name: string;
};

type IProps = {
  inputId?: string;
  classes?: string;
  onChangePeriod: (arg: DateRangeTuple) => void;
  period?: DateRangeTuple;
  includeAllTime?: boolean;
};

type FormType = {
  cstDateFrom: string;
  cstDateTo: string;
};

const patternDate = new RegExp(
  /(?=\d)^(?:(?!(?:10\D(?:0?[5-9]|1[0-4])\D(?:1582))|(?:0?9\D(?:0?[3-9]|1[0-3])\D(?:1752)))((?:0?[13578]|1[02])|(?:0?[469]|11)(?!\/31)(?!-31)(?!\.31)|(?:0?2(?=.?(?:(?:29.(?!000[04]|(?:(?:1[^0-6]|[2468][^048]|[3579][^26])00))(?:(?:(?:\d\d)(?:[02468][048]|[13579][26])(?!\x20BC))|(?:00(?:42|3[0369]|2[147]|1[258]|09)\x20BC))))))|(?:0?2(?=.(?:(?:\d\D)|(?:[01]\d)|(?:2[0-8])))))([-./])(0?[1-9]|[12]\d|3[01])\2(?!0000)((?=(?:00(?:4[0-5]|[0-3]?\d)\x20BC)|(?:\d{4}(?!\x20BC)))\d{4}(?:\x20BC)?)(?:$|(?=\x20\d)\x20))?((?:(?:0?[1-9]|1[012])(?::[0-5]\d){0,2}(?:\x20[aApP][mM]))|(?:[01]\d|2[0-3])(?::[0-5]\d){1,2})?$/,
);

const validationSchema = yup.object({
  cstDateFrom: yup.string().label('Payment date').required(),
  cstDateTo: yup.string().label('Payment date').required(),
});

type MIProps = {
  value: string;
  onChange: (arg: string) => void;
  error?: string;
};

const MaskInput: React.FC<MIProps> = ({ value, onChange, error }: MIProps) => (
  <InputMask
    mask="99\/99\/9999"
    // @ts-ignore
    maskChar=" "
    className="input select__date-range-input"
    placeholder="mm/dd/yyyy"
    onChange={(e) => onChange(e.target.value)}
    value={value}
  />
);

const DateFilter: React.FC<IProps> = ({
  inputId = 'DateFilter',
  classes = '',
  onChangePeriod,
  period,
  includeAllTime = false,
}) => {
  const [inputValue, setInputValue] = useState(7);
  const [opened, setOpened] = useState(false);
  const selectRef = React.createRef<HTMLInputElement>();

  function setValue(val: number) {
    setInputValue(val);
    setOpened(false);
  }

  const { errors, values, handleSubmit, setFieldValue } = useFormik<FormType>({
    initialValues: {
      cstDateFrom: '',
      cstDateTo: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: async ({ cstDateFrom, cstDateTo }: any) => {
      if (cstDateFrom.match(patternDate) && cstDateTo.match(patternDate)) {
        const timeFrom = new Date(cstDateFrom);
        const timeTo = new Date(cstDateTo);
        timeFrom.setHours(0, 0, 0, 0);
        timeTo.setHours(23, 59, 59, 999);

        onChangePeriod([timeFrom.toISOString(), timeTo.toISOString()]);
        setValue(0);
        setOpened(false);
      }
    },
    validationSchema,
  });

  useOnClickOutside(selectRef, () => {
    setOpened(false);
  });

  // update from outside value
  useEffect(() => {
    if (!period) return;
    const [from, to] = period;
    const diff = differenceInDays(new Date(to), new Date(from));
    setValue(diff);
  }, [period]);

  const listData: PrdItem[] = [
    { val: 7, name: 'Last 7 days' },
    { val: 30, name: 'Last 30 days' },
    { val: 1, name: 'Today' },
    { val: 0, name: 'Custom date' },
  ];

  if (includeAllTime) listData.push({ val: 365 * 20, name: 'All Time' });

  const listItems = listData.map(({ val, name }: PrdItem) =>
    !val ? null : (
      <li
        key={val}
        aria-hidden="true"
        className={`select__date-option ${
          inputValue === val ? 'select__date-option--selected' : ''
        }`}
        onClick={() => {
          setValue(val);
          onChangePeriod(getTimePeriod(val));
        }}
      >
        {name}
        <Icon name="check" classes="select__date-option-icon" />
      </li>
    ),
  );

  // FIXME: At least for controlled mode (when the date range value provided from outside)
  //  - component shows "Last 7 Dats" for custom range
  //  - component shows "Custom date" when "Today" is selected
  //  see this behaviour on PlatformAdminUsersPage.tsx
  const inputDisplayValue = listData.find((item) => item.val === inputValue)?.name;

  return (
    <div
      className={`select select--date-filter select--no-margin ${classes}${
        opened ? ' select--opened' : ''
      }`}
      ref={selectRef}
    >
      <div className="select__box">
        <p className="select__date-input-prefix">Show:</p>
        <input
          type="text"
          className="input select__input"
          value={inputDisplayValue}
          name={inputId}
          id={inputId}
          readOnly
          onClick={() => {
            setOpened(!opened);
          }}
        />
        <div className="select__chevron">
          <Icon name="chevron" classes="select__chevron-icon" />
        </div>
      </div>
      <div className="select__option-list">
        <ul className="select__date-option-list">{listItems}</ul>
        <div className="select__date-range">
          <p className="select__date-range-title">Custom date</p>
          <div className="select__date-range-form">
            <MaskInput
              value={values.cstDateFrom}
              onChange={(newValue: string) => {
                setFieldValue('cstDateFrom', newValue, true);
                handleSubmit();
              }}
              error={errors.cstDateFrom}
            />
            <Icon name="arrow-left" classes="select__date-range-icon" />
            <MaskInput
              onChange={(newValue: string) => {
                setFieldValue('cstDateTo', newValue, true);
                handleSubmit();
              }}
              value={values.cstDateTo}
              error={errors.cstDateTo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
