import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { RefundInfo } from '../../../../shared';
import { setModalSuccessOpen } from '../../../../shared/store/modals';
import { getTimePeriod } from '../../../../shared/utils';
import { parseError } from '../../../../shared/utils/helpers';

import { getBillPayments } from './getBillPayments';

import type { AppDispatch, RootState } from '../../../../main/store';

export const billPaymentRefund = createAsyncThunk<
  boolean,
  RefundInfo,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('dashboard/billPaymentRefund', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.post(`/bill-payments/${payload.paymentId}/refunds`, payload);

    dispatch(
      setModalSuccessOpen({
        opened: true,
        title: 'Refund form submitted!',
        text: 'Your refund form has been successfully submitted. We’ll review and update the status based on your submission.',
      }),
    );

    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
