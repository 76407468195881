import classNames from 'classnames';
import { range } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const FIELD_COUNT = 6;

type CodeVerifyProps = {
  // eslint-disable-next-line no-unused-vars
  onChange?: (values: string[]) => void;
  onEnterPressed?: () => void;
  error?: boolean;
  verified?: boolean;
};

const CodeVerify = ({ onChange, onEnterPressed, verified = false, error }: CodeVerifyProps) => {
  const inputRefs = useRef(new Array(FIELD_COUNT));
  const [values, setValues] = useState(['', '', '', '', '', '']);

  const callbackRef = useCallback(
    (node: HTMLInputElement, index: number) => {
      if (node) {
        inputRefs.current[index] = node;
      }
    },
    [inputRefs],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (index >= 0 && (event.key === 'Backspace' || event.key === 'Delete')) {
        const newValues = [...values];
        newValues[index] = '';
        setValues(newValues);
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }

      if (index < inputRefs.current.length && !Number.isNaN(parseInt(event.key, 10))) {
        const newValues = [...values];
        newValues[index] = event.key;
        setValues(newValues);
        if (index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }
      }

      if (event.key === 'Enter') {
        if (onEnterPressed) {
          onEnterPressed();
        }
      }
    },
    [onEnterPressed, values],
  );

  const rendeerElem = useCallback(
    (index: number) => (
      <input
        type="text"
        key={String(index)}
        className="code-verify__input input"
        value={values[index]}
        ref={(node: HTMLInputElement) => callbackRef(node, index)}
        onFocus={(event) => event.target.select()}
        onKeyDown={(event) => handleKeyDown(event, index)}
        onChange={(event) => event}
        maxLength={1}
      />
    ),
    [callbackRef, handleKeyDown, values],
  );

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [values, onChange]);

  useEffect(() => {
    if (inputRefs.current) {
      inputRefs.current[0].focus();
    }
  }, [inputRefs]);

  return (
    <div className="code-verify">
      <div
        className={classNames('code-verify__row', {
          'code-verify--error': error,
          'code-verify--completed': verified,
        })}
      >
        {range(0, 3).map((index) => rendeerElem(index))}
        <div className="code-verify__completed" />
        {range(3, FIELD_COUNT).map((index) => rendeerElem(index))}
      </div>
    </div>
  );
};

export default React.memo(CodeVerify);
