import { useHistory } from 'react-router';

import { useAppDispatch } from '../../../main/store/hooks';
import { setToolkitMenuOpen } from '../../dashboard/store/dashboardSlice';

export default function SftpPage() {
  const dispatch = useAppDispatch();
  dispatch(setToolkitMenuOpen(true));
  const history = useHistory();
  history.push('/developer-tools/sftp/overview');

  return null;
}
