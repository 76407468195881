import { createAsyncThunk } from '@reduxjs/toolkit';
import React from 'react';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { ClientId } from '../../../../shared/models/PartnerModel';
import { parseError } from '../../../../shared/utils/helpers';

export const setClientId = createAsyncThunk<
  ClientId,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('user/setClientId', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<ClientId>(`/partners/client-id`, {});
    return data;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
