import React, { useCallback, useEffect, useState } from 'react';
import Intercom from 'react-intercom';

import { setAuthToken, setIsAuthenticated, setMfaToken } from './features/auth/store';
import { getIntercomInfo, getPartner, getSelf } from './features/auth/store/actions';
import { getChecklist } from './features/auth/store/actions/getChecklist';
import { hasRole } from './features/auth/utils';
import { getCompanyRoles } from './features/company/store/actions';
import { getPaymentMethods } from './features/finances/store/actions';
import { useAppDispatch, useAppSelector } from './main/store/hooks';
import Router from './Router';
import StoreKeys from './shared/constants/AsyncStoreKeys';
import Permissions from './shared/constants/Permissions';
import { Amplitude } from './shared/services/amplitude';
import { intercomAPI } from './shared/services/intercom';

Amplitude.init(process.env.REACT_APP_AMPLITUDE || '');

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [appInitialized, setAppInitialized] = useState(false);
  const { isAuthenticated, intercom, user, authToken, finishedSetup } = useAppSelector(
    (state) => state.auth,
  );

  const restoreState = useCallback(() => {
    const mfaToken = localStorage.getItem(StoreKeys.mfaToken);
    if (mfaToken) {
      dispatch(setMfaToken(mfaToken));
    }
    const isAuthenticatedStorage = localStorage.getItem(StoreKeys.isAuthenticated);
    if (isAuthenticatedStorage !== null) {
      dispatch(setIsAuthenticated(JSON.parse(isAuthenticatedStorage)));
    }
  }, [dispatch]);

  const loadOrGenerateToken = useCallback(async () => {
    const storedAuthToken = localStorage.getItem(StoreKeys.authToken);

    if (storedAuthToken) {
      const storedToken = JSON.parse(storedAuthToken);
      dispatch(setAuthToken(storedToken));
    }
  }, [dispatch]);

  const initApp = useCallback(async () => {
    restoreState();
    await loadOrGenerateToken();

    if (isAuthenticated && !user) {
      await dispatch(getSelf());
      await dispatch(getPartner());
      await dispatch(getCompanyRoles());
    }

    localStorage.setItem(StoreKeys.isAuthenticated, String(isAuthenticated));
  }, [dispatch, loadOrGenerateToken, restoreState, isAuthenticated, user]);

  useEffect(() => {
    initApp().then(() => setAppInitialized(true));
  }, [initApp]);

  //  eslint-disable-next-line react-hooks/exhaustive-deps
  const loadIntercom = async () => {
    await dispatch(getIntercomInfo());
    intercomAPI('update');
  };

  useEffect(() => {
    if (finishedSetup && user) {
      dispatch(getChecklist());
    }
  }, [finishedSetup, user]);

  useEffect(() => {
    if (isAuthenticated && authToken?.accessToken && user && !intercom.user_hash) {
      loadIntercom();
    }

    const isAdmin =
      authToken &&
      (hasRole(authToken, Permissions.PLATFORM_ADMIN_EDIT) ||
        hasRole(authToken, Permissions.PLATFORM_ADMIN_VIEW));

    if (isAdmin) {
      document.documentElement.classList.add('admin');
    } else {
      document.documentElement.classList.remove('admin');
    }
  }, [user, isAuthenticated, authToken?.accessToken, loadIntercom, intercom.user_hash]);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  if (!appInitialized) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Router />
      <Intercom {...intercom} hide_default_launcher />
    </>
  );
};

export default App;
