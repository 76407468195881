import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import {
  Icon,
  Input,
  Label,
  Modal,
  PermissionsGuard,
  PermissonsPredicate,
  Search,
} from '../../../shared';
import Button from '../../../shared/components/Button';
import { ColorItem, colorList } from '../../../shared/constants/Colors';
import { Permissions } from '../../../shared/constants/Permissions';
import { PartnerMember } from '../../../shared/models';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { getContact } from '../../../shared/utils';
import { refreshToken } from '../../auth/store/actions/refreshToken';
import { setModalRoleOpen } from '../store';
import {
  addCompanyRole,
  getCompanyMembers,
  getCompanyRoles,
  updCompanyRole,
  updCompanyRoleFromMember,
} from '../store/actions';

interface IProps {
  classes?: string;
  isUserCompanyAdmin: boolean;
  isUserSystemAdmin: boolean;
}

export interface PermissionItem {
  id: Permissions;
  name: string;
  description: string;
  condition: boolean;
  permissionsGuard?: PermissonsPredicate;
  disabled?: boolean;
}

const initPermList: PermissionItem[] = [
  {
    id: Permissions.BILL_PAYMENTS_VIEW,
    name: 'View bill payments data',
    description: 'Allows members to view data of bill payments.',
    condition: false,
  },
  {
    id: Permissions.CUSTOMERS_REFUND,
    name: 'Refund customer',
    description: 'Allows members to refund customers and view refund submissions.',
    condition: false,
  },
  {
    id: Permissions.FINANCES_MANAGE,
    name: 'Manage finances',
    description: 'Allows members view, edit and export finances.',
    condition: false,
  },
  {
    id: Permissions.MEMBERS_INVITE,
    name: 'Invite team members',
    description: 'Allows members to invite new team members.',
    condition: false,
  },
  {
    id: Permissions.MEMBERS_MANAGE,
    name: 'Manage team members and roles',
    description:
      'Allows members to invite, edit access and deactivate team members, also allows members to add, edit and delete roles.',
    condition: false,
  },
  {
    id: Permissions.PLATFORM_ADMIN_VIEW,
    name: 'Zirtue Admin Area view access',
    description: ' Allows members to view Zirtue Admin Area',
    condition: false,
    permissionsGuard: { $and: [Permissions.PLATFORM_ADMIN_EDIT] },
  },
  {
    id: Permissions.PLATFORM_ADMIN_EDIT,
    name: 'Zirtue Admin Area Edit access',
    description: ' Allows members to edit Zirtue Admin Area',
    condition: false,
    permissionsGuard: { $and: [Permissions.PLATFORM_ADMIN_EDIT] },
  },
  {
    id: Permissions.ACCESS_DEVELOPER_TOOLS,
    name: 'Access developer tools',
    description: 'Enables users to view and manage portal integration options',
    condition: false,
  },
];
interface InitialValues {
  name: string;
  color: string;
  permissions: string[];
  members: PartnerMember[];
}

const initialValues: InitialValues = {
  name: '',
  color: 'green',
  permissions: [],
  members: [],
};

const validationSchema = yup.object({
  name: yup.string().min(3).label('Role Name').required(),
  color: yup.string().label('color').required(),
});

const ModalRole: React.FC<IProps> = ({
  classes,
  isUserCompanyAdmin,
  isUserSystemAdmin,
}: IProps) => {
  const [loading, setIsLoading] = useState(false);

  const {
    members,
    modalRoleOpen: opened,
    modalRoleType,
    modalRoleData,
  } = useAppSelector((state) => state.company);

  const { name, permissionsLocked } = modalRoleData || {};
  const isCompanyAdminRole = name === 'Admin';
  const allowMemberEditing = isCompanyAdminRole ? isUserCompanyAdmin || isUserSystemAdmin : true;

  const dispatch = useAppDispatch();

  const [permList, setPermList] = useState(initPermList);
  const [permError, setPermError] = useState(false);
  const [searchMembersText, setSearchMembersText] = useState<string>('');

  const permissionsRemitFiles = [
    Permissions.BILL_PAYMENTS_VIEW,
    Permissions.BILL_PAYMENTS_EXPORT,
    Permissions.ACCESS_DEVELOPER_TOOLS,
  ];

  useEffect(() => {
    setPermError(false);
    const permissions = permList.filter((item) => item.condition).map((item) => item.id);
    permissions.length > 0 ? setPermError(false) : setPermError(true);
  }, [permList]);

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    touched,
    setFieldValue,
    resetForm,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    onSubmit: async (params, { setErrors }) => {
      const permissions = permList.filter((item) => item.condition).map((item) => item.id);

      if (permissions.length) {
        setIsLoading(true);

        let result;

        if (modalRoleType === 'add') {
          result = await dispatch(addCompanyRole({ ...params, permissions }));
          Amplitude.logEvent(AmplitudeEvents.roleAdded, { role: params.name });
        } else {
          result = await dispatch(
            updCompanyRole({ ...params, permissions, uuid: modalRoleData?.uuid || '' }),
          );
          Amplitude.logEvent(AmplitudeEvents.memberRoleChanged, { role: params.name });
        }

        if (addCompanyRole.rejected.match(result) || updCompanyRole.rejected.match(result)) {
          if (result.payload) {
            dispatch(addAlert({ text: result.payload, type: AlertType.error }));
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        closeModalAndClearForm();
        setIsLoading(false);

        setTimeout(() => {
          dispatch(getCompanyRoles());
          dispatch(getCompanyMembers());
          dispatch(refreshToken());
        }, 1000);
      }
    },
    validationSchema,
  });

  const closeModalAndClearForm = () => {
    dispatch(setModalRoleOpen({ isOpen: false }));
    resetForm();
    setPermList(initPermList);
    setSearchMembersText('');
  };

  const changePermissions = ({ target: { id, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setPermList(permList.map((item) => (item.id === id ? { ...item, condition: checked } : item)));
  };

  useEffect(() => {
    if (!members.length) {
      dispatch(getCompanyMembers());
    }
  }, [dispatch, members.length]);

  const deleteRoleFromMember = async (memberId: string) => {
    await dispatch(updCompanyRoleFromMember({ memberId }));
    setTimeout(() => {
      dispatch(getCompanyRoles());
      dispatch(getCompanyMembers());
    }, 1000);
  };

  const addMemberFromList = async (member: PartnerMember) => {
    let newMembers: PartnerMember[] = values.members;

    if (values.members.map((m) => m.uuid).includes(member.uuid)) {
      newMembers = newMembers.filter((mr) => mr.uuid !== member.uuid);
      await deleteRoleFromMember(member.uuid);
    } else {
      newMembers = [...newMembers, member];
      if (modalRoleType === 'edit') {
        await dispatch(
          updCompanyRoleFromMember({ memberId: member.uuid, groupId: modalRoleData?.uuid }),
        );
      }
    }

    setTimeout(() => {
      dispatch(getCompanyRoles());
      dispatch(getCompanyMembers());
    }, 1000);
    setFieldValue('members', newMembers);
  };

  useEffect(() => {
    if (modalRoleData && opened && modalRoleType === 'edit') {
      setFieldValue('name', modalRoleData.name).then(() => {
        validateForm();
      });
      setFieldValue('color', modalRoleData.color);
      setFieldValue(
        'members',
        members.filter((m) => modalRoleData.members.includes(m.uuid)),
      );

      const initPermissions = permList.map((el) =>
        modalRoleData.permissions.includes(el.id)
          ? { ...el, condition: true }
          : { ...el, condition: false },
      );

      const isCheckedAccessRemit = initPermissions.some(
        (el) => permissionsRemitFiles.includes(el.id) && el.condition,
      );

      const permissions = initPermissions.map((el) =>
        el.id === Permissions.ACCESS_REMIT_FILE_LOGS && isCheckedAccessRemit
          ? { ...el, disabled: true, condition: true }
          : el,
      );
      setPermList(permissions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRoleData, opened]);

  const renderMembers = (renderMembersList: PartnerMember[], isEdit = false) =>
    renderMembersList.map((member: PartnerMember) => {
      const { firstName, lastName, uuid, contacts } = member;
      return (
        <div key={`s${uuid}`} className="modal-role__member-row">
          {isEdit ? null : (
            <div className="checkbox modal-role__checkbox">
              <input
                type="checkbox"
                className="checkbox__input"
                id={`s${uuid}`}
                onChange={() => addMemberFromList(member)}
                checked={values.members.map((m) => m.uuid).includes(uuid)}
              />
              <label htmlFor={`s${uuid}`} className="checkbox__label">
                &#0;
              </label>
            </div>
          )}
          <div className="modal-role__avatar-box">
            <div className="modal-role__avatar-wrapper">
              {/* <img src={image} className="modal-role__avatar" alt="" /> */}
              <Icon name="ghost" classes="member-name__avatar-placeholder" />
            </div>
          </div>
          <div className="modal-role__member-text">
            <p className="modal-role__title">{`${firstName || 'Member'} ${lastName || ''}`}</p>
            <p className="modal-role__text-gray">{contacts && getContact(contacts[0])}</p>
          </div>
          {isEdit && allowMemberEditing && (
            <button
              type="button"
              className="modal-role__remove-member"
              onClick={() => deleteRoleFromMember(uuid)}
            >
              <Icon name="cross-heavy" classes="modal-role__remove-member-icon" />
            </button>
          )}
        </div>
      );
    });

  const addedMembers = members
    ? members.filter(
        (m) => m.securityGroups && m.securityGroups.includes(modalRoleData?.uuid || ''),
      )
    : [];

  const filteredMembers = useMemo(() => {
    if (!searchMembersText) {
      return members;
    }

    const loCase = searchMembersText.toLowerCase().trim();

    return members.filter(
      (oneMember) =>
        oneMember.firstName?.toLowerCase().includes(loCase) ||
        oneMember.lastName?.toLowerCase().includes(loCase) ||
        oneMember.middleName?.toLowerCase().includes(loCase) ||
        oneMember.contacts.find((oneContact) => oneContact.toLowerCase().includes(loCase)),
    );
  }, [members, searchMembersText]);

  return (
    <Modal
      opened={opened}
      handleClose={() => {
        closeModalAndClearForm();
      }}
      classes={classes}
    >
      <div className="modal-content modal-content--mint-top modal-content--modal-role">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            closeModalAndClearForm();
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--hand">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/invite-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/invite-lv2@1x.webp 1x, images/dashboard/modals/invite-lv2@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/invite-lv2@1x.png"
              srcSet="images/dashboard/modals/invite-lv2@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="images/dashboard/modals/cuff.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">
          {modalRoleType === 'add' ? 'Add a new role' : 'Edit role'}
        </h2>
        <p className="modal-content__text">
          {modalRoleType === 'add'
            ? 'Define a new role to manage team members and permissions easier.'
            : 'Edit this role by clicking any of the fields below. The changes you make to the role will now affect all members with this role.'}
        </p>
        {/* Form */}
        <form className="modal-content__form" action="/">
          <div className="modal-role__section">
            {/* Form group */}
            <div className="form-group">
              <Label forId="role">Role name</Label>
              <Input
                id="name"
                type="text"
                placeholder="New role"
                onChange={handleChange}
                error={Boolean(errors.name) && touched.name && errors.name}
                value={values.name}
                disabled={permissionsLocked}
              />
            </div>
            <div className="modal-role__group">
              <p className="modal-role__header modal-role__header--mo-margin">Role color</p>
              <p className="modal-role__text-gray">
                Select a color to distinguish between different roles
              </p>
            </div>
            {/* Color list */}
            <div className="color-list">
              {colorList.map(({ id, valueColor }: ColorItem) => (
                <div className="color-list__group" key={id}>
                  <input
                    type="radio"
                    id={id}
                    name="color"
                    className="color-list__input"
                    value={valueColor}
                    onChange={(e) => setFieldValue('color', e.target.id)}
                    checked={id === values.color}
                  />
                  <label
                    htmlFor={id}
                    className={`color-list__circle color-list__circle--${valueColor}`}
                  >
                    &#0;
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-role__section">
            {/* Permission list */}
            <p className="modal-role__header">Permissions</p>
            <div className="modal-role__permission-list">
              {permList.map(
                ({
                  id,
                  name,
                  description,
                  condition,
                  permissionsGuard,
                  disabled,
                }: PermissionItem) => (
                  <PermissionsGuard key={id} permissions={permissionsGuard} bypassForAdmin={false}>
                    <div className="modal-role__permission-item" key={id}>
                      <div className="modal-role__permission-text">
                        <p className="modal-role__title">{name}</p>
                        <p className="modal-role__text-gray">{description}</p>
                      </div>
                      <div className="toggle">
                        <input
                          type="checkbox"
                          id={id}
                          className="toggle__input"
                          checked={condition}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            changePermissions(e);
                          }}
                          disabled={disabled || permissionsLocked}
                        />
                        <label htmlFor={id} className="toggle__label">
                          &#0;
                        </label>
                      </div>
                    </div>
                  </PermissionsGuard>
                ),
              )}
            </div>
          </div>

          {allowMemberEditing && (
            <>
              <p className="modal-role__header">Add members to this role</p>
              <div className="modal-role__search">
                <Search
                  id="modal-role-search"
                  placeholder="Search for a name or email..."
                  value={searchMembersText}
                  onChange={setSearchMembersText}
                />
              </div>
            </>
          )}

          <div className="modal-role__member-list">
            {allowMemberEditing && (
              <div className="modal-role__member-list-scroll-box">
                {searchMembersText ? renderMembers(filteredMembers) : null}
              </div>
            )}
            <p className="modal-role__header">
              {modalRoleType === 'add'
                ? `All team members (${members.length})`
                : `Added team members (${addedMembers.length})`}
            </p>
            <div className="modal-role__member-list-scroll-box">
              {renderMembers(
                modalRoleType !== 'add' ? addedMembers : members,
                modalRoleType !== 'add',
              )}
            </div>
          </div>

          {permError && (
            <div className="modal-role__message-box">
              <p className="modal-role__message">
                <Icon name="attention-octagon" classes="modal-role__message-icon" />
                <span className="modal-role__message-text">
                  Cannot create a role without assigned permissions.
                </span>
              </p>
            </div>
          )}

          <Button
            className="button--primary-blue button--lg button--block"
            loading={loading}
            disabled={!isValid || permError}
            onClick={() => {
              handleSubmit();
            }}
          >
            {modalRoleType === 'add' ? 'Add new role' : 'Save changes'}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalRole;
