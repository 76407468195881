import React from 'react';

import { PartnersDeeplinks } from '../PartnersDeeplinks';

export default function CampaignModificationTab() {
  return (
    <div className="card">
      <PartnersDeeplinks />
    </div>
  );
}
