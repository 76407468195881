import React, { FC } from 'react';

import { Icon } from '../../../../shared';
import { DataTable, DataTableColumnConfig } from '../../../../shared/components/DataTable';
import { LoanDto } from '../../../../shared/models/platform-admin';
import { formatDate } from '../../../../shared/utils';
import { AdminTableName } from '../../store';
import { makeStatus, makeUserName, makeUserPhone } from '../../utils';

interface LoansTableProps {
  loans: LoanDto[];
  selectedLoanId?: string;
  onLoanClick?: (loan: LoanDto) => unknown;
  name?: AdminTableName;
}

const handleCopyClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

const CopyBtn = ({ uuid }: LoanDto) => (
  <span className="table-account-number table-account-number--w100">
    <span className="table-account-number__text">{uuid}</span>
    <button
      className="table-account-number__copy-btn"
      type="button"
      onClick={() => handleCopyClipboard(uuid)}
    >
      <Icon name="copy" />
    </button>
  </span>
);

const COLUMNS: DataTableColumnConfig<LoanDto>[] = [
  {
    id: 'uuid',
    title: 'uuid',
    valueProducer: CopyBtn,
  },
  {
    id: 'isLendLoan',
    title: 'Lender Initiated',
    valueProducer: ({ isLendLoan }) => (isLendLoan ? 'yes' : 'no'),
    sort: true,
  },
  {
    id: 'lenderName',
    title: 'Lender Name',
    valueProducer: ({ lender }) => makeUserName(lender),
  },
  {
    id: 'borrowerName',
    title: 'Borrower Name',
    valueProducer: ({ borrower }) => makeUserName(borrower),
  },
  {
    id: 'borrowerPhone',
    title: 'Borrower Phone',
    valueProducer: ({ borrower }) => makeUserPhone(borrower),
  },
  {
    id: 'stage',
    title: 'Status',
    valueProducer: ({ stage }) => makeStatus(stage),
    sort: true,
  },
  {
    id: 'terms.amount.value',
    title: 'Value',
    valueProducer: ({ amount }) => `${amount.value} ${amount.currency}`,
    sort: true,
  },
  {
    id: 'stage.scheduledFor',
    title: 'Next Payment Date',
    valueProducer: ({ stage }) => (stage.scheduledFor ? formatDate(stage.scheduledFor) : '-'),
    sort: true,
  },
  {
    id: 'createdAt',
    title: 'Created At',
    valueProducer: ({ createdAt }) => formatDate(createdAt),
    sort: true,
  },
];

const getId = (loan: LoanDto) => loan.uuid;

export const LoansTable: FC<LoansTableProps> = ({
  loans,
  selectedLoanId,
  onLoanClick,
  name = 'loans',
}) => (
  <DataTable
    name={name}
    columns={COLUMNS}
    getId={getId}
    data={loans}
    onRowClick={onLoanClick}
    selectedId={selectedLoanId}
  />
);
