import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';

import { Input, Label } from '../../../../../shared';
import { FormData } from '../../SignUpForm/SignUpForm';
import SignUpWrapper from '../../SignUpWrapper/SignUpWrapper';

type DataType = {
  subdomain: string;
};

type RegisterSubdomain = {
  values: Pick<FormData, 'subdomain'>;
  errors: FormikErrors<DataType>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<DataType>;
};

export default function RegisterSubdomain({
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
}: RegisterSubdomain) {
  return (
    <SignUpWrapper title="Setup your business portal domain">
      <div className="registerSubdomain">
        <div className="registerSubdomain__inputRow">
          <div className="registerSubdomain__inputCol">
            <Label forId="subdomain">Custom url</Label>
            <Input
              type="text"
              id="subdomain"
              placeholder="Insert your Subdomain"
              onChange={handleChange}
              onBlur={(e) => handleBlur(e)}
              value={values.subdomain}
              error={Boolean(errors.subdomain) && touched.subdomain && errors.subdomain}
            />
          </div>
          <div className="registerSubdomain__inputCol">
            <Input
              type="text"
              id="exampleSubdomain"
              placeholder="partner.zirtue.com"
              value="portal.zirtue.com"
              disabled
            />
          </div>
        </div>
      </div>
    </SignUpWrapper>
  );
}
