import { useFlag } from '@unleash/proxy-client-react';
import { format } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { FeatureTogglesNames } from '../../../main/env/feature-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label, Select } from '../../../shared';
import Button from '../../../shared/components/Button';
import { AssetType, FlowTypes, SelectedFlow, setAssets, setSelectedAsset } from '../../auth/store';
import { setClientId } from '../../auth/store/actions/setClientId';

import SelectedCampaignAsset from './SelectedCampaignAsset';

type FormValues = {
  campaignName: string;
  createdAt: string;
  status: string;
  createdBy: string;
};

type TProps = {
  selectedFlow: SelectedFlow | null;
  responseClientId: string;
};

enum SelectFlow {
  AFFILIATE = 'affiliate',
  BILLPAY = 'billpay',
}

export default function SelectedCampaign({ selectedFlow, responseClientId }: TProps) {
  const getDate = (date: string) => format(new Date(date), 'MMM dd, yyyy');
  const selectedCampaign = useAppSelector((store) => store.auth.campaign);
  const assets = useAppSelector((store) => store.auth.assets);
  const activeAsset = useAppSelector((store) => store.auth.selectedAsset);
  const partner = useAppSelector((store) => store.auth.partner);
  const PARTNER_DBP_BILL_PAY_POPUP = useFlag(FeatureTogglesNames.PARTNER_DBP_BILL_PAY_POPUP);
  const PARTNER_P2P_BILL_PAY_POPUP = useFlag(FeatureTogglesNames.PARTNER_P2P_BILL_PAY_POPUP);

  const { user } = useAppSelector((state) => ({ ...state.auth, ...state.company }), shallowEqual);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (v: FormValues, submitProps: FormikHelpers<FormValues>) => {
      // TO DO
      console.log('');
    },
    [user],
  );

  const { handleBlur, errors, values, touched, setFieldValue, setSubmitting, handleSubmit } =
    useFormik<FormValues>({
      initialValues: {
        campaignName: selectedCampaign?.displayName || '',
        createdAt: selectedCampaign?.createdAt ? getDate(selectedCampaign?.createdAt) : '' || '',
        status: selectedCampaign?.state || '',
        createdBy: `${`${user?.firstName} ${user?.lastName}`}` || '',
      },
      onSubmit,
    });

  const fieldChangeNew = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSubmitting(false);
    setFieldValue(target.id, target.value);
  };

  const handleToggleIntegrationsPreview = (assetType: AssetType | undefined) => {
    const updatedAssets = assets.map((asset) => {
      if (asset.assetType === assetType) {
        return { ...asset, isOpen: !asset.isOpen };
      }
      return asset;
    });

    dispatch(setAssets(updatedAssets));

    if (activeAsset && activeAsset.assetType === assetType) {
      dispatch(setSelectedAsset({ ...activeAsset, isOpen: !activeAsset.isOpen }));
    }
  };

  const NOTP2P = partner?.details.flowType !== FlowTypes.AFFILIATE_PARTNER;
  const isBillPopUp =
    (NOTP2P && PARTNER_DBP_BILL_PAY_POPUP) || (!NOTP2P && PARTNER_P2P_BILL_PAY_POPUP);

  return (
    <div className="selectedCampaign">
      <form onSubmit={handleSubmit}>
        <h3 className="selectedCampaign__title">
          Overview
          {/* <Button
            type="submit"
            className="button button--primary-blue button--xs"
            disabled
            icon="disk"
          >
            Save changes
          </Button> */}
        </h3>
        <div className="selectedCampaign__form">
          {/* <div className="selectedCampaign__form__item">
            <Label forId="campaignName">Campaign name</Label>
            <Input
              classes="selectedCampaign__form__item__input"
              type="text"
              id="campaignName"
              placeholder="Campaign name"
              value={values.campaignName}
              onChange={fieldChangeNew}
              onBlur={handleBlur}
              error={Boolean(errors.campaignName) && touched.campaignName && errors.campaignName}
            />
          </div> */}
          <div className="selectedCampaign__form__item">
            <Label forId="createdAt">Created</Label>
            <Input
              classes="selectedCampaign__form__item__input"
              type="text"
              id="createdAt"
              placeholder="Created"
              value={values.createdAt}
              onChange={fieldChangeNew}
              onBlur={handleBlur}
              error={Boolean(errors.createdAt) && touched.createdAt && errors.createdAt}
              disabled
            />
          </div>
          <div className="selectedCampaign__form__item">
            <Label forId="status">Status</Label>
            <Select
              classes="select--no-margin"
              id="status"
              data={[{ name: 'Active', dataId: 'active' }]}
              value={values.status}
              onChange={(e) => console.log(e)}
              disabled
            />
          </div>
          {/* <div className="selectedCampaign__form__item">
            <Label forId="createdBy">Created by</Label>
            <Input
              classes="selectedCampaign__form__item__input"
              type="text"
              id="createdBy"
              defaultValue={values.createdBy}
              placeholder="Created by"
              disabled
            />
          </div> */}
        </div>
        <h3 className="selectedCampaign__title">
          Campaign assets ({selectedFlow === SelectFlow.BILLPAY ? '3' : '2'})
        </h3>
        <div className="selectedCampaign__assets">
          {assets
            .filter((el) =>
              selectedFlow === SelectFlow.BILLPAY ? el.title : !el.title.includes('Button'),
            )
            .filter(
              (el) =>
                el.assetType !== AssetType.BILL_PAY_POPUP ||
                (isBillPopUp && el.assetType === AssetType.BILL_PAY_POPUP),
            )
            .map((el, i) => (
              // eslint-disable-next-line
              <div
                key={i}
                className={`selectedCampaign__assets__item ${
                  selectedFlow === SelectFlow.AFFILIATE ? 'selectedCampaign__assets__item__two' : ''
                } ${
                  activeAsset?.assetType === el.assetType
                    ? 'selectedCampaign__assets__item__active'
                    : ''
                }`}
                onClick={() => dispatch(setSelectedAsset(el))}
              >
                <div className="selectedCampaign__assets__item__left">
                  <img src={el.iconSrc} alt={el.title} />
                </div>
                <div className="selectedCampaign__assets__item__right">
                  <div className="selectedCampaign__assets__item__title">{el.title}</div>
                  <div className="selectedCampaign__assets__item__text">{el.text}</div>
                </div>
              </div>
            ))}
        </div>
        {activeAsset?.assetType === AssetType.ZIRTUE_PAY_BUTTON ||
        activeAsset?.assetType === AssetType.QUICK_LINK ||
        activeAsset?.assetType === AssetType.BILL_PAY_POPUP ? (
          <SelectedCampaignAsset
            selectedCampaign={selectedCampaign}
            activeAsset={activeAsset}
            selectedFlow={selectedFlow}
            handleToggleIntegrationsPreview={handleToggleIntegrationsPreview}
            responseClientId={responseClientId}
          />
        ) : (
          ''
        )}
      </form>
    </div>
  );
}
