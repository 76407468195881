import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { LoanPresetSearchResponseDto } from '../../../../shared/models/platform-admin/loans/LoanPresetDto';
import { parseError } from '../../../../shared/utils/helpers';

export const adminLoanPresetsList = createAsyncThunk<
  LoanPresetSearchResponseDto,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminLoanPresetsList', async (partnerId, { rejectWithValue }) => {
  try {
    const { data } = await api.get<LoanPresetSearchResponseDto>(
      `/platform-admin/loan-presets/list/${partnerId}`,
    );
    return data;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
