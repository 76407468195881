import { TErrorMessage } from '..';

export const errorsMap: Record<string, Partial<TErrorMessage>> = {
  PHONE_NOT_FOUND_404: {
    title: 'The phone number you entered did not match our records.',
    message: 'Please double-check and try again.',
  },
  EMAIL_NOT_FOUND_404: {
    title: 'The email you entered did not match our records.',
    message: 'Please double-check and try again.',
  },
  WRONG_VERIFICATION_CODE_403: {
    title: 'Your verification code is incorrect.',
    message: 'Please enter your verification code again.',
  },
  WRONG_VERIFICATION_CODE_412: {
    title: 'Your verification code is incorrect.',
    message: 'Please enter your verification code again.',
  },
  PHONE_TAKEN_412: {
    title: 'Another account with this phone number already exists.',
    message: 'Please login or enter a new number.',
  },
  EMAIL_TAKEN_412: {
    title: 'Another account with this email already exists.',
    message: 'Please login or enter a new email.',
  },
  MFA_TOKEN_EXPIRED_403: {
    title: 'Verification code expired.',
    message: 'Please return to the login screen and try again.',
  },
  VERIFICATION_CODE_EXPIRED_412: {
    title: 'Verification code expired.',
    message: 'Please return to the login screen and try again.',
  },
  VERIFICATION_CODE_EXPIRED_403: {
    title: 'Verification code expired.',
    message: 'Please return to the login screen and try again.',
  },
  LIMIT_REACHED_412: {
    title: 'Maximum accounts limit reached.',
    message: 'Users may link up to two bank accounts.',
  },
  BAD_ROUTING_NUM_412: {
    title: 'Your routing number appears to be incorrect.',
    message: 'Please re-enter and try again.',
  },
  INVALID_MICRODEPOSIT_412: {
    title: 'Your micro deposits appear to be incorrect.',
    message: 'Please re-enter and try again.',
  },
  TOKEN_IS_NOT_VALID_403: {
    title: 'Access token has expired. Please resubmit with a new access token.',
  },
  TOKEN_EXPIRED_401: {
    title: 'Access token has expired. Please resubmit with a new access token.',
  },
  WRONG_MEMBER_EMAIL_DOMAIN_400: {
    title: 'Please enter an email address that contains the registered company domain.',
  },
  ROUTING_INVALID_412: {
    title: 'Please double check your routing number and try again.',
  },
  NO_ATTEMPTS_LEFT_412: {
    title: 'Please double check your micro-deposit amounts and try again.',
  },
  INVALID_AMOUNTS_412: {
    title: 'Please double check your micro-deposit amounts and try again.',
  },
  AWAITING_ANOTHER_REFUND_412: {
    title: 'Another refund process pending',
  },
  INVITATION_ALREADY_ACCEPTED_412: {
    title: 'Invitation already accepted',
  },
  ALREADY_REGISTERED_412: {
    title: 'A partner with this domain already registered',
  },
  INVITATION_EXPIRED_412: {
    title: 'Invitation expired',
    message:
      "It appears that your invitation link isn't valid any more. Please contact your account administrator and request another invite.",
  },
  DUPLICATE_SECURITY_GROUP_NAME_409: {
    title: 'A role with this name already exists',
  },
  CANT_DELETE_ACCOUNT_IN_USE_400: {
    title: 'Cannot delete a payment method in use',
  },
};
