import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useLazyEffect } from '../../../shared';
import { adminLoan } from '../store/actions';
import { selectLoansState } from '../store/selectors/loans';

export const useSelectedTableSearchEffect = () => {
  const dispatch = useAppDispatch();
  const { page, pageSize, dateRangeFilter, searchQuery, stage, sortOrder, sortField } =
    useAppSelector((state) => state.platformAdmin.selectedItemLoan);

  useLazyEffect(
    () => {
      dispatch(adminLoan());
    },
    [page, pageSize, searchQuery, dateRangeFilter, stage, sortOrder, sortField],
    300,
  );
};
