import React from 'react';

type Props = {
  currentStep: number;
  totalSteps: number;
  classes?: string;
  progressTitle?: string;
  progresClass?: string;
};
const Progress: React.FC<Props> = ({
  currentStep,
  totalSteps,
  classes,
  progressTitle,
  progresClass,
}) => {
  const applyInlineStyle = {
    width: `calc(${+((currentStep + 1) / (totalSteps + 1)).toFixed(2) * 100}% + ${
      currentStep === 0 ? 10 : 0
    }px)`,
  };

  return (
    <div className={`progress ${classes || ''}`}>
      <div className="progress__bar">
        <div className={`progress__bar-value ${progresClass || ''}`} style={applyInlineStyle} />
      </div>
      <p className="progress__title">{progressTitle}</p>
    </div>
  );
};
export default Progress;
