import axios, { AxiosError } from 'axios';

import { TErrorMessage } from '..';
import { colorList } from '../constants/Colors';
import { errorsMap } from '../constants/errorsMap';
import { CompanyRole } from '../models';
import LogService from '../services/logging';

type ErrorData = {
  message: string;
  status: number;
  statusCode: string;
  errors: Record<string, unknown>;
};

export type TRegisteredEvent = {
  eventSent: boolean;
};

export const delay = (ms: number): Promise<unknown> => new Promise((res) => setTimeout(res, ms));

export const parsePredefinedError = (key: string): Partial<TErrorMessage> | null =>
  key in errorsMap ? errorsMap[key] : null;

export const parseError = (error: Error): string => {
  const err = error as AxiosError;
  if (err.response) {
    // client received an error response (5xx, 4xx)
    const { response } = err;
    if (response.data) {
      const { data, headers, status } = response;
      const { message, statusCode, errors } = data as ErrorData;
      const reqId = headers['x-request-id'];

      if (message && (statusCode || status)) {
        const errorKey = `${message.toUpperCase()}_${statusCode || status}`;
        const customError = parsePredefinedError(errorKey);
        if (customError && reqId) {
          customError.xReqId = reqId;
          const foundError = JSON.stringify(customError);
          if (foundError) {
            return foundError;
          }
        }

        if (customError && customError.title) {
          return customError.message
            ? JSON.stringify({ title: customError.title, message: customError.message })
            : customError.title;
        }
      }

      const messageAcc: string[] = [];
      const errorObject = errors || data;

      Object.keys(errorObject).forEach((errorKey) => {
        const internalMessage = Array.isArray(errorObject[errorKey])
          ? (errorObject[errorKey] as Array<unknown>).join()
          : JSON.stringify(errorObject[errorKey]);
        try {
          messageAcc.push(internalMessage);
        } catch (e) {
          LogService.logDebug(e);
        }
      });
      messageAcc.push(reqId);
      return messageAcc.join(' ');
    }
  }
  if (err.request) {
    // client never received a response, or request never left
    return 'Server is not available';
  }
  // anything else
  return error.message;
};

export const getSecurityGroupInfo = (
  groupUuid: string,
  securityGroups: CompanyRole[],
): { name?: string; color?: string } => {
  const foundGroup = securityGroups.find((oneGroup) => oneGroup.uuid === groupUuid);
  const foundColor = colorList.find((oneColor) => oneColor.id === foundGroup?.color);

  return { name: foundGroup?.name, color: foundColor?.valueColor };
};

export const download = (url: string, name: string) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    // @ts-ignore
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
