import { AxiosError, AxiosResponse } from 'axios';

type BaseErrorParams = {
  title?: string;
  message?: string;
  internalError?: Error | AxiosError;
};

class ZError extends Error {
  public title?: string;

  public internalError?: Error | AxiosError;

  public method?: string;

  public url?: string;

  public status?: string;

  public error?: string;

  public date?: string;

  public xRequestId?: string;

  // public xClientDeviceId?: string;

  public response?: AxiosResponse;

  public requestHeaders?: Record<string, string>;

  public responseHeaders?: Record<string, string>;

  public isAxiosError?: boolean;

  // message has a priority over error.message
  constructor({ message, internalError, title }: BaseErrorParams) {
    let ttl;
    let msg;
    let method;
    let url;
    let status;
    let date;
    let xRequestId;
    // let xClientDeviceId;
    let error;
    let response;
    let requestHeaders;
    let responseHeaders;
    let isThisAxiosError;

    if (internalError) {
      const axiosError = internalError as AxiosError;
      if (axiosError.isAxiosError) {
        isThisAxiosError = true;
        const { config } = axiosError;
        msg = axiosError.message;
        method = config.method;
        url = `${config.baseURL}${config.url}`;
        status = axiosError.code;
        requestHeaders = config.headers;

        if (axiosError.response) {
          response = axiosError.response;
          status = String(response.status);
          if (response.headers) {
            const { headers } = response;
            responseHeaders = headers;
            date = headers.date;
            xRequestId = headers['x-request-id'];
          }
          if (response.data) {
            const { data } = response;
            const d = data as { statusCode?: string; message?: string; error?: string };
            if (Object.prototype.hasOwnProperty.call(d, 'statusCode')) {
              status = d.statusCode;
            }
            if (Object.prototype.hasOwnProperty.call(d, 'message')) {
              msg = d.message;
              // if (data.message in errorMessages) {
              //   // @ts-ignore
              //   msg = errorMessages[data.message].message;
              //   // @ts-ignore
              //   ttl = errorMessages[data.message].title;
              // }
            }
            if (Object.prototype.hasOwnProperty.call(d, 'error')) {
              error = d.error;
            }
          }
        }
      }
    }
    if (message) {
      msg = message;
    }
    if (title) {
      ttl = title;
    }
    if (!msg && internalError && internalError.message) msg = internalError.message;
    if (msg && status) msg = `${msg}: ${status}`;
    super(msg);
    this.name = 'ZError';
    this.title = ttl;
    this.date = date ?? new Date().toUTCString();
    this.internalError = internalError;
    this.method = method;
    this.url = url;
    this.status = status;
    this.xRequestId = xRequestId;
    // this.xClientDeviceId = xClientDeviceId;
    this.error = error;
    this.requestHeaders = requestHeaders;
    this.responseHeaders = responseHeaders;
    this.isAxiosError = isThisAxiosError;
    Object.setPrototypeOf(this, ZError.prototype);
    if (Error.captureStackTrace) Error.captureStackTrace(this, this.constructor);
  }

  public get Extras() {
    const extras: Record<string, unknown> = {};
    const untrackedParts: string[] = [];
    Object.keys(this).forEach((key) => {
      try {
        if (key === 'internalError') {
          extras[key] = this[key];
        } else {
          // @ts-ignore
          extras[key] = JSON.stringify(this[key], undefined, 2);
        }
      } catch {
        untrackedParts.push(key);
      }
    });
    extras.untrackedParts = JSON.stringify(untrackedParts, undefined, 2);
    return extras;
  }

  public get Fingerprint() {
    return [this.method ?? '', this.url ?? '', this.status ?? ''];
  }

  public get Title() {
    return this.title ?? this.error ?? this.message;
  }

  public get Message() {
    if ([this.title, this.error].includes(this.Title)) {
      return `${this.message}\n${this.xRequestId}\n${this.date}`;
    }
    return `${this.xRequestId}\n${this.date}`;
  }

  public get FullTitle() {
    if (this.isAxiosError) return `${this.Title}\n${this.Message}`;
    // This format is using when you are trying to create error like new ZError({internalError: new Error('Test error')});
    return `${this.Title}\n${this.date}`;
  }

  public toJSON() {
    return {
      date: this.date,
      title: this.title,
      message: this.message,
      method: this.method,
      url: this.url,
      status: this.status,
      'x-request-id': this.xRequestId,
      // 'x-client-device-id': this.xClientDeviceId,
      error: this.error,
      requestHeaders: this.requestHeaders,
      responseHeaders: this.responseHeaders,
    };
  }

  public toString() {
    return JSON.stringify(this.toJSON(), undefined, 2);
  }
}

export default ZError;
