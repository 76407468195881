import React, { Dispatch, FC, SetStateAction } from 'react';

import Icon from '../../Icon';
import Typography from '../../UI/Typography';
import { CheckoutListItem } from '../hooks/useCheckoutList';

type CheckoutListHeaderProps = {
  toggleDropdown: boolean;
  setToggleDropdown: Dispatch<SetStateAction<boolean>>;
  listItems: CheckoutListItem[];
};

const CheckoutListHeader: FC<CheckoutListHeaderProps> = ({
  setToggleDropdown,
  toggleDropdown,
  listItems,
}) => {
  const chevronStyle = {
    transform: toggleDropdown ? 'rotate(0)' : 'rotate(-90deg)',
    transition: 'transform 0.3s ease',
  };

  const stepsLeft = listItems.filter((item) => !item.isDone).length;
  const stepCounter =
    stepsLeft > 0 ? `${stepsLeft} step${stepsLeft > 1 ? 's' : ''} left` : 'All steps are finished';

  return (
    <div className="checkout-list__header">
      <button
        onClick={() => setToggleDropdown((prev) => !prev)}
        type="button"
        className="checkout-list__dropdown-button"
      >
        <span style={chevronStyle}>
          <Icon name="chevron" classes="checkout-list__dropdown-button__icon" />
        </span>
        <div className="checkout-list__dropdown_title">
          <Typography tag="p" size="title-2">
            quick start quide
          </Typography>
        </div>
      </button>
      <p className="checkout-list__steps-count">{stepCounter}</p>
    </div>
  );
};

export default CheckoutListHeader;
