import * as Sentry from '@sentry/react';

import { IS_LOGGER_ENABLED } from '../../main/env';
import ZError from '../errors/ZError';

export default class LogService {
  private static readonly isLogEnabled: boolean = IS_LOGGER_ENABLED();

  public static logDebug = (...params: unknown[]): void => {
    if (LogService.isLogEnabled) {
      // eslint-disable-next-line no-console
      console.log('[LOG_DEBUG]', ...params);
    }
  };

  public static logWarn = (...params: unknown[]): void => {
    if (LogService.isLogEnabled) {
      // eslint-disable-next-line no-console
      console.warn('[LOG_WARN]', ...params);
    }
  };

  public static logError = (error: ZError): void => {
    Sentry.captureException(error, (scope) => {
      scope.setFingerprint(error.Fingerprint);
      scope.setExtras(error.Extras);
      // if (error.xClientDeviceId) scope.setTag(x-client-device-id, error.xClientDeviceId);

      if (error.xRequestId) scope.setTag('x-request-id', error.xRequestId);

      LogService.logDebug(error.toString());
      return scope;
    });
  };
}
