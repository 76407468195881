import React, { useState, useEffect } from 'react';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { ModalConfirm } from '../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import FileTransferConfig from '../components/FileTransferConfig';
import FileTransferSetup from '../components/FileTransferSetup';
import NotFound from '../components/NotFound';
import DevToolsLayout from '../layout/DevToolsLayout';
import { getSftpReportSettings } from '../store/actions/getSftpReportSettings';

export default function FileTransfer() {
  const [isShowEditor, setIsShowEditor] = useState(false);
  const { fileTransferSettings } = useAppSelector((state: RootState) => state.devTools);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSftpReportSettings());
  }, [isShowEditor]);

  return (
    <DevToolsLayout>
      {!fileTransferSettings && !isShowEditor && (
        <NotFound
          message="You have no file transfer setup. Zirtue’s API lets you receive batch payment records from Zirtue using SFTP and FTP protocols."
          buttonTxt="Setup file transfer"
          onClick={() => {
            setIsShowEditor(true);
            Amplitude.logEvent(AmplitudeEvents.fileTransferIntegrationStarted);
          }}
        />
      )}

      {isShowEditor && <FileTransferSetup onChange={() => setIsShowEditor(false)} />}

      {fileTransferSettings && !isShowEditor && (
        <FileTransferConfig onChange={() => setIsShowEditor(true)} />
      )}
      <ModalConfirm />
    </DevToolsLayout>
  );
}
