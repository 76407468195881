import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PartnerMember, CompanyRole } from '../../../shared/models';
import { InvitedMember } from '../../../shared/models/PartnerInvitedMembersDto';

import { getCompanyMembers, getCompanyRoles, getInvitedMembers } from './actions';

type CompanySliceState = {
  members: Array<PartnerMember>;
  invitedMembers: Array<InvitedMember>;
  q?: string;
  roles: Array<CompanyRole>;
  modalInviteOpen: boolean;
  modalRoleOpen: boolean;
  modalRoleType: 'add' | 'edit';
  modalRoleData?: CompanyRole;
  modalInvitationSentOpen: boolean;
  modaInvitationSentText: string;
  modalDeactivateOpen: {
    isOpen: boolean;
    memberId?: string;
    activate: boolean;
  };
};

const initialState: CompanySliceState = {
  members: [],
  invitedMembers: [],
  q: '',
  roles: [],
  modalInviteOpen: false,
  modalRoleOpen: false,
  modalRoleType: 'add',
  modalInvitationSentOpen: false,
  modaInvitationSentText: '',
  modalDeactivateOpen: {
    isOpen: false,
    activate: false,
  },
};

export const companySlice = createSlice({
  name: 'company',
  initialState: initialState as CompanySliceState,
  reducers: {
    setModalInviteOpen: (state, action: PayloadAction<boolean>) => {
      state.modalInviteOpen = action.payload;
    },
    setModalRoleOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; type?: 'add' | 'edit'; role?: CompanyRole }>,
    ) => {
      state.modalRoleOpen = action.payload.isOpen;
      state.modalRoleType = action.payload.type || 'add';
      if (action.payload.role) state.modalRoleData = action.payload.role;
    },
    setModalInvitationSentOpen: (state, action: PayloadAction<boolean>) => {
      state.modalInvitationSentOpen = action.payload;
    },
    setModalInvitationSentText: (state, action: PayloadAction<string>) => {
      state.modaInvitationSentText = action.payload;
    },
    setModalDeactivateOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; memberId?: string; activate?: boolean }>,
    ) => {
      state.modalDeactivateOpen = { ...action.payload, activate: !!action.payload.activate };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyMembers.fulfilled, (state, action) => {
      state.members = action.payload;
    });
    builder.addCase(getInvitedMembers.fulfilled, (state, action) => {
      state.invitedMembers = action.payload;
    });
    builder.addCase(getCompanyRoles.fulfilled, (state, action) => {
      if (action.payload) {
        Object.assign(state, { roles: action.payload });
      }
    });
  },
});

export default companySlice;
export const { reducer: companyReducer } = companySlice;
export const {
  setModalInviteOpen,
  setModalRoleOpen,
  setModalInvitationSentOpen,
  setModalInvitationSentText,
  setModalDeactivateOpen,
} = companySlice.actions;
