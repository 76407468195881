import React, { FC } from 'react';

import { useAppSelector } from '../../../../main/store/hooks';
import { JSONView } from '../../../../shared';
import { selectSelectedPartner } from '../../store/selectors/partners';

export const SelectedPartner: FC = () => {
  const data = useAppSelector(selectSelectedPartner);
  return (
    <div>
      <JSONView data={data} />
    </div>
  );
};
