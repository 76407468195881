import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';
import { FundingSource, FundingSourceModel } from '../../../../shared/models/FundingSource';
import { parseError } from '../../../../shared/utils/helpers';

export const getPaymentMethods = createAsyncThunk<
  Array<FundingSource>,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('finances/getPaymentMethods', async (payload, { rejectWithValue }) => {
  try {
    const encodedURI = encodeURIComponent(`${defaultRealm}`);
    const { data } = await api.get<Array<FundingSource>>(`/realms/${encodedURI}/accounts`);
    return FundingSourceModel.fromDTOArray(data);
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
