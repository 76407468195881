import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import {
  AdminPartnersSearchQueryDto,
  AdminPartnersSearchResponseDto,
} from '../../../../shared/models/platform-admin';
import { selectPartnersState } from '../selectors/partners';

export const adminPartnersSearch = createAsyncThunk<
  AdminPartnersSearchResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>('platformAdmin/adminPartnersSearch', async (_, { getState }) => {
  const { page, pageSize, searchQuery, sortOrder, sortField } = selectPartnersState(getState());

  const { data } = await api.get<AdminPartnersSearchResponseDto>(
    '/platform-admin/partners/search',
    {
      params: <AdminPartnersSearchQueryDto>{
        skip: (page - 1) * pageSize,
        limit: pageSize,
        searchText: searchQuery.replace('+', ''),
        sortField,
        sortOrder,
      },
    },
  );

  return data;
});
