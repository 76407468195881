import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const deactivateMember = createAsyncThunk<
  undefined,
  { memberId: string; active: boolean },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/deactivateMember', async ({ memberId, active }, { rejectWithValue }) => {
  try {
    await api.put(`/members/${memberId}/active`, { active });

    return undefined;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
