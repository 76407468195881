import * as yup from 'yup';

const deeplinkDetailsSchema = yup.object({
  useFiona: yup.boolean().required().nullable(),
  skipKYC: yup.boolean().required().nullable(),
  type: yup.string().required(),
  feeEnabled: yup.boolean().nullable().required(),
  whoIsPaying: yup
    .string()
    .nullable()
    .when('feeEnabled', (type, schema) =>
      type === true ? schema.required() : schema.notRequired(),
    ),
  feeAmountPercentage: yup
    .number()
    .nullable()
    .when('feeEnabled', (type, schema) =>
      type === true ? schema.required() : schema.notRequired(),
    ),
  flowType: yup
    .string()
    .nullable()
    .when('type', (type, schema) =>
      type === 'affiliate' ? schema.required() : schema.notRequired(),
    ),
  partnerComissionEnabled: yup
    .boolean()
    .nullable()
    .when('type', (type, schema) =>
      type === 'affiliate' ? schema.required() : schema.notRequired(),
    ),
  partnerComissionPercentageAmount: yup
    .number()
    .nullable()
    .when('partnerComissionEnabled', {
      is: true,
      then: (schema) =>
        schema.when('type', (type, schema) =>
          type === 'affiliate'
            ? schema.required('partnerComissionPercentageAmount is required')
            : schema.notRequired(),
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export { deeplinkDetailsSchema };
