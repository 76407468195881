import {
  BillPaymentTransactionSummaryState,
  LoanDetailsDto,
} from '../../../../features/loans/dtos/LoanDetailsDto';
import {
  LoanClosureType,
  LoanStage,
  LoanStageKind,
  LoanStatus as LoanStatusDto,
} from '../../../../features/loans/dtos/LoanTypes';
import { RepaymentPlan, RepaymentPlanModel } from '../../../../features/loans/dtos/RepaymentPlan';
import { FundingSource } from '../../FundingSource';

export const getProgressFromDates = (dto: LoanDetailsDto): number => {
  const paymentPlan = dto.terms.plan;
  const loanStage = dto.stage;
  const totalAmount = dto.terms.plan.payments[0].beginningBalance;

  if (loanStage.kind === LoanStageKind.closed) {
    if (
      loanStage.closureType === LoanClosureType.cancelled ||
      loanStage.closureType === LoanClosureType.deactivated ||
      loanStage.closureType === LoanClosureType.rejected
    ) {
      return 0;
    }

    if (
      loanStage.closureType === LoanClosureType.forgiven ||
      loanStage.closureType === LoanClosureType.paidOut
    )
      return 1;
  }

  if (loanStage.kind === LoanStageKind.repayment) {
    const { payments } = paymentPlan;
    if (!payments) return 0;

    const currentIndex = Number.isNaN(loanStage.currentPaymentIndex)
      ? 0
      : loanStage.currentPaymentIndex;

    const loanAmountLeft = payments
      .slice(currentIndex)
      .map((p) => p.amount)
      .reduce((a, b) => a + b);
    const calcResult = 1 - Number((loanAmountLeft / totalAmount).toFixed(2));
    return calcResult;
  }
  return 0;
};

export enum LoanStatus {
  BINDING = 'binding',
  ACCEPTANCE = 'acceptance',
  DISBURSEMENT = 'disbursement',
  RETRY = 'retry',
  REPAYMENT = 'repayment',
  GRACE_PERIOD = 'grace_period',
  PAID_OFF = 'paid_off',
  FORGIVEN = 'forgiven',
  DEACTIVATED = 'deactivated',
  DECLINED = 'declined',
  CANCELED = 'cancelled',
}

export type LoanUserDto = {
  uuid: string;
  redactedUsername: string;
  redactedPhoneNum?: string;
};

type LoanUser = LoanUserDto;

export type LoanDetails = {
  version: number;
  createdAt: string; // 2021-05-28T13:07:19.333Z
  createdBy: LoanUser;
  isLendLoan: boolean;
  boundAt: string; // 2021-05-28T13:07:19.333Z
  terms: {
    description: {
      message: string;
      mediaUrl: string; // 'https://example.com/uploads/loans/00000000-0000-0000-0000-000000000000.jpg'
      reason: string;
      relationship: string;
      initialTargetName: string;
    };
    disbursementTarget?: FundingSource & {
      billingAccountNumber?: string;
    };
    amount: {
      value: number;
      currency: string; // USD
    };
    plan: RepaymentPlan;
    dueDate: string;
  };
  stage: LoanStage;
  repaymentDateFrom: string; // '2021-10-02T00:00:00.000Z';
  repaymentDateTo: string; // '2021-10-02T00:00:00.000Z';
  firstPaymentDate: string; // '2021-10-02T00:00:00.000Z';
  nextPaymentDate: string; // '2021-08-01T07:39:49.210Z';
  targetContactUri: string;
  uuid: string; // 00000000-0000-0000-0000-000000000000
  isRepaymentRequest: boolean;
  borrower: LoanUser;
  borrowerType: string;
  lender: LoanUser;
  disbursedAt?: string; // 2021-05-28T13:07:19.333Z
  closedAt?: string; // 2021-05-28T13:07:19.333Z
  deactivatedAt?: string; // 2021-05-28T13:07:19.333Z
  movedToRepaymentAt?: string; // 2021-05-28T13:07:19.333Z
  dueDate?: string;
  processingSince: string;
  disbursementSource: FundingSource;
  repaymentSource: FundingSource;
  repaymentTarget: FundingSource;
  billPaymentTransactionSummaryState?: BillPaymentTransactionSummaryState;
  isOneTime: boolean;
  status: 'Active' | 'Pending' | 'Cancel';
  percent: number;
  isCashPickupEnabled?: boolean;
};

export class LoanDetailsModel {
  public static fromDTO(dto: LoanDetailsDto): LoanDetails {
    const repaymentModel = RepaymentPlanModel.fromDTO(dto.terms.plan);
    repaymentModel.payments = repaymentModel.payments.map((planPayment) =>
      RepaymentPlanModel.addPaymentInfo(planPayment, dto.stage),
    );
    let status: 'Pending' | 'Active' | 'Cancel' = 'Pending';
    if (
      [LoanStageKind.binding, LoanStageKind.acceptance, LoanStageKind.disbursement].includes(
        dto.stage.kind,
      )
    ) {
      status = 'Pending';
    } else if ([LoanStageKind.repayment].includes(dto.stage.kind)) {
      status = 'Active';
    } else {
      status = 'Cancel';
    }

    return {
      ...dto,
      status,
      percent: getProgressFromDates(dto),
      terms: {
        ...dto.terms,
        plan: repaymentModel,
      },
      isOneTime: dto.terms.plan.payments.length === 1,
    };
  }

  public static Status = (loan?: LoanDetails): LoanStatusDto | undefined => {
    if (loan) {
      if (
        [LoanStageKind.binding, LoanStageKind.acceptance, LoanStageKind.disbursement].includes(
          loan.stage.kind,
        )
      )
        return LoanStatusDto.pending;
      if (loan.stage.kind === LoanStageKind.repayment) return LoanStatusDto.active;
      if (loan.stage.kind === LoanStageKind.closed) {
        if (loan.stage.closureType === LoanClosureType.forgiven) return LoanStatusDto.forgiven;
        if (loan.stage.closureType === LoanClosureType.paidOut) return LoanStatusDto.paidOff;
        if (loan.stage.closureType === LoanClosureType.rejected) return LoanStatusDto.declined;
        if (
          loan.stage.closureType === LoanClosureType.cancelled ||
          loan.stage.closureType === LoanClosureType.deactivated
        )
          return LoanStatusDto.cancelled;
      }
    }
    return undefined;
  };
}
