import { startOfDay } from 'date-fns';

import { DateRangeTuple } from '../types';

export const getTimePeriod = (days: number): DateRangeTuple => {
  const today = new Date();
  let oldDate = new Date();

  if (days === 1) {
    oldDate = startOfDay(today);
  } else {
    oldDate.setDate(today.getDate() - days);
  }

  return [oldDate.toISOString(), today.toISOString()];
};

export function getDaysDifference(date1: string, date2: string) {
  // Parse the date strings into Date objects
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate the difference in milliseconds
  const diffInMs = Number(d1) - Number(d2);

  // Convert milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  // Return the difference in days
  return Math.round(diffInDays);
}
