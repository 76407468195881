import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { CodeVerify } from '../components';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { authWithSecret, getPartner, startLogin } from '../store/actions';
import { isAdminRole } from '../utils';

const RETRY_DELAY = 30; // in seconds

const errorMessage = (messageCode: string) => {
  switch (messageCode) {
    case 'MFA_TOKEN_MALFORMED':
      return 'Verification problem. Try to relogin.';

    case 'WRONG_VERIFICATION_CODE':
      return 'Wrong verification code.';

    case 'VERIFICATION_CODE_EXPIRED':
      return 'Verification code expired.';

    case 'MFA_TOKEN_EXPIRED':
      return 'Your login session expired.';

    case 'MEMBER_DEACTIVATED':
      return 'Member deactivated. Contact administrator.';

    default:
      return 'Verification problem. Try to relogin.';
  }
};

export default function EmailVerify() {
  const [code, setCode] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [codeVerified, setCodeVerified] = useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = useState(RETRY_DELAY);
  const dispatch = useAppDispatch();
  const { loading, auth, authCreateDto } = useAppSelector((state) => state.auth);
  const history = useHistory();

  const timer = useRef<NodeJS.Timeout>();

  const handleChangeCode = useCallback((newCode: string[]) => {
    setCode(newCode);
  }, []);

  const resendCode = useCallback(
    async (event) => {
      event.preventDefault();
      if (!authCreateDto) {
        return;
      }
      const result = await dispatch(startLogin(authCreateDto));

      if (startLogin.fulfilled.match(result)) {
        dispatch(addAlert({ text: 'Verification code sent' }));
      }

      if (startLogin.rejected.match(result)) {
        dispatch(addAlert({ text: 'Resend code error' }));
      }
      setSecondsRemaining(RETRY_DELAY);
    },
    [authCreateDto, dispatch],
  );

  const [codeReady, codeString] = useMemo(() => {
    const ready = code.find((item: string) => item === '') === undefined;
    const resultValue = code.join('');

    return [ready, resultValue];
  }, [code]);

  const handleNexClick = useCallback(async () => {
    if (auth?.mfaToken) {
      const result = await dispatch(authWithSecret({ mfaToken: auth?.mfaToken, code: codeString }));
      if (authWithSecret.fulfilled.match(result)) {
        setHasError(false);
        setCodeVerified(true);
        dispatch(getPartner());

        const isAdminPanel = result.payload && isAdminRole(result.payload);

        if (isAdminPanel) {
          history.replace('/platform-admin/users');
        } else {
          history.replace('/init-setup');
          Amplitude.logEvent(AmplitudeEvents.emailVerificationSuccess);
        }
      }

      if (authWithSecret.rejected.match(result)) {
        const errorText = result.payload?.message
          ? errorMessage(result.payload.message)
          : 'Verification error';

        dispatch(addAlert({ text: errorText, type: AlertType.error }));

        setHasError(true);
        Amplitude.logEvent(AmplitudeEvents.emailVerificationFailed);
      }
    }
  }, [auth, codeString, dispatch, history]);

  useEffect(() => {
    const tick = () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
        // @ts-ignore
        timer.current = setTimeout(() => tick(), 1000);
      }
    };

    // @ts-ignore
    timer.current = setTimeout(() => tick(), 1000);

    // @ts-ignore
    return () => clearTimeout(timer.current);
  }, [secondsRemaining]);

  const notReady = !codeReady || loading || !auth?.mfaToken;

  const handleEnterPressed = () => {
    if (!notReady) {
      handleNexClick();
    }
  };

  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignUp}
      bannerContent={AuthBannerContent.Verify}
    >
      <form action="#" className="auth-form">
        <h2 className="auth-form__subtitle">Enter verification code sent to your email address</h2>
        <CodeVerify
          verified={codeVerified}
          error={hasError}
          onChange={handleChangeCode}
          onEnterPressed={handleEnterPressed}
        />
        <div className="auth-form__link-box auth-form__link-box--resend-code">
          {secondsRemaining <= 0 ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className="auth-form__link" onClick={resendCode}>
              Resend code
            </a>
          ) : (
            <span className="auth-form__resend-text">
              {`You can resend code in ${secondsRemaining} seconds.`}
            </span>
          )}
        </div>

        <button
          type="button"
          className="button button--primary-blue button--lg button--block"
          disabled={notReady}
          onClick={handleNexClick}
          data-testid="submit-button"
        >
          Next
        </button>
      </form>
    </AuthorizationLayout>
  );
}
