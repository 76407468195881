import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label, Select, Progress } from '../../../shared';
import LogService from '../../../shared/services/logging';
import { CodeVerify } from '../components';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { setStep } from '../store';

export default function Verify() {
  const step = useAppSelector((state) => state.auth.step);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setStep(1));
  }, [dispatch]);
  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignUp}
      bannerContent={AuthBannerContent.Verify}
    >
      <Progress
        currentStep={step || 0}
        totalSteps={4}
        classes="auth__progress"
        progressTitle={`Step ${step} of 4`}
      />
      {step === 1 && (
        <form action="#" className="auth-form">
          <h2 className="auth-form__subtitle">Verify your phone number</h2>
          {/* Form group */}
          <div className="form-group">
            <Label forId="company-phone">Your phone number</Label>
            <div className="form-group__row form-group__row--phone-code">
              <div className="form-group__row-item form-group__row-item--phone-code">
                <Select
                  id="company-phone-code"
                  haveFlag
                  data={[
                    { dataId: '1', name: '+1' },
                    { dataId: '2', name: '+2' },
                    { dataId: '3', name: '+3' },
                  ]}
                  value="1"
                  disabled
                  classes="select--small"
                  onChange={(e) => LogService.logDebug(e)}
                />
              </div>
              <div className="form-group__row-item">
                <Input
                  type="tel"
                  id="company-phone"
                  value="202-555-0156"
                  placeholder="Add phone number"
                  disabled
                />
              </div>
            </div>
          </div>
          {/* Button */}
          <button
            type="button"
            className="button button--primary-blue button--lg button--block auth-form__btn--verify"
            onClick={() => {
              dispatch(setStep(2));
            }}
          >
            Send code
          </button>
        </form>
      )}
      {step === 2 && (
        <form action="#" className="auth-form">
          <h2 className="auth-form__subtitle">Enter verification code sent to +1 202-555-0156</h2>
          <CodeVerify />
          <div className="auth-form__link-box auth-form__link-box--resend-code">
            <Link to="/" className="auth-form__link">
              Resend code
            </Link>
          </div>
          {/* Button */}
          <button
            type="button"
            className="button button--primary-blue button--lg button--block"
            onClick={() => {
              dispatch(setStep(3));
            }}
          >
            Next
          </button>
        </form>
      )}
      {step === 3 && (
        <form action="#" className="auth-form">
          <h2 className="auth-form__subtitle">Verify your email</h2>
          {/* Form group */}
          <div className="form-group">
            <Label forId="email">Your email</Label>
            <Input type="email" id="email" placeholder="julia@greencompany.com" disabled />
          </div>
          {/* Button */}
          <button
            type="button"
            className="button button--primary-blue button--lg button--block auth-form__btn--verify"
            onClick={() => {
              dispatch(setStep(4));
            }}
          >
            Send code
          </button>
        </form>
      )}
      {step === 4 && (
        <form action="#" className="auth-form">
          <h2 className="auth-form__subtitle">
            Enter verification code sent to julia@greencompany.com
          </h2>
          <CodeVerify />
          <div className="auth-form__link-box auth-form__link-box--resend-code">
            <a href="/" className="auth-form__link">
              Resend code
            </a>
          </div>
          <button type="button" className="button button--primary-blue button--lg button--block">
            Next
          </button>
        </form>
      )}
    </AuthorizationLayout>
  );
}
