import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { Partner, PartnerDto, PartnerModel } from '../../../../shared/models/PartnerModel';
import { parseError } from '../../../../shared/utils/helpers';

export const getPartner = createAsyncThunk<
  Partner,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('user/getPartner', async (payload, { rejectWithValue, getState }) => {
  try {
    const { data } = await api.get<PartnerDto>(`/partners/self`);

    return PartnerModel.fromDTO(data);
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
