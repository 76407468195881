import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Modal } from '../../../shared';
import { setModalInvitationSentOpen } from '../store';

export default function ModalInvitationSent() {
  const { modalInvitationSentOpen: opened, modaInvitationSentText } = useAppSelector(
    (state) => state.company,
  );
  const dispatch = useAppDispatch();
  return (
    <Modal
      opened={opened}
      handleClose={() => {
        dispatch(setModalInvitationSentOpen(false));
      }}
      classes="modal--lg"
    >
      <div className="modal-content modal-content--invitation-sent">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalInvitationSentOpen(false));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--envelope">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/invitation-sent-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/invitation-sent-lv2@1x.webp 1x, images/dashboard/modals/invitation-sent-lv2@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/invitation-sent-lv2@1x.png"
              srcSet="images/dashboard/modals/invitation-sent-lv2@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Invitation sent!</h2>
        <p className="modal-content__text">{modaInvitationSentText}</p>
        <div className="modal-content__btn-box">
          <button
            type="button"
            className="button button--primary-blue button--lg modal-content__btn"
            onClick={() => {
              dispatch(setModalInvitationSentOpen(false));
            }}
          >
            Got it
          </button>
        </div>
      </div>
    </Modal>
  );
}
