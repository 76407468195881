import cn from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input } from '../../../../shared';
import { FormWrapper } from '../../../init-setup/components';
import { TLoanReasonType, setLoanReasonType } from '../../store';

const reasonTypes = [
  { id: 0, title: 'Auto', image: 'auto' },
  { id: 1, title: 'Business', image: 'business' },
  { id: 2, title: 'Credit Card', image: 'creditCard' },
  { id: 3, title: 'Education', image: 'education' },
  { id: 4, title: 'Emergency', image: 'emergency' },
  { id: 5, title: 'Entertainment', image: 'entertainment' },
  { id: 6, title: 'Food', image: 'food' },
  { id: 7, title: 'Insurance', image: 'insurance' },
  { id: 8, title: 'Medical', image: 'medical' },
  { id: 9, title: 'Travel', image: 'travel' },
  { id: 10, title: 'Rent', image: 'rent' },
  { id: 11, title: 'Utilities', image: 'utilities' },
  { id: 12, title: 'Other', image: 'other-2' },
];

type FormData = {
  loanReasonType: string | undefined;
};

type FormikDataTypes = {
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldError: (field: string, message: string) => void;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

const ReasonTypeSelectItem = ({
  type,
  isSelected,
  isFocused,
  onSelect,
  onRef,
}: {
  type: TLoanReasonType;
  isSelected: boolean;
  isFocused: boolean;
  onSelect: () => void;
  onRef: (el: HTMLButtonElement | null) => void;
}) => (
  <button
    type="button"
    ref={onRef}
    className={cn(
      'loans__selectLoanReasonTypeForm__selectItem',
      isSelected && 'loans__selectLoanReasonTypeForm__selectItem--active',
      isFocused && 'loans__selectLoanReasonTypeForm__selectItem--focused',
    )}
    onClick={onSelect}
    tabIndex={0}
  >
    <div className="loans__selectLoanReasonTypeForm__selectItem__left">
      <div className="loans__selectLoanReasonTypeForm__selectItem__imageBlock">
        <Icon name={type.image} />
      </div>
      <div className="loans__selectLoanReasonTypeForm__selectItem__title">{type.title}</div>
    </div>
    <div className="loans__selectLoanReasonTypeForm__selectItem__dot" />
  </button>
);

export default function SelectReasonTypeForm({
  currentStepIndex,
  errors,
  handleBlur,
  touched,
  values,
  setFieldError,
  setFieldValue,
  setFieldTouched,
}: FormikDataTypes) {
  const { loanReasonType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<TLoanReasonType | undefined>();
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [keyboardNav, setKeyboardNav] = useState<boolean>(false);
  const reasonTypeRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleChangeType = (selectedReason: TLoanReasonType, viaKeyboard = false) => {
    if (selectedReason.title !== 'Other') {
      setFieldValue('loanReasonType', selectedReason.title);
    } else {
      setFieldValue('loanReasonType', 'other-custom');
      setFieldTouched('loanReasonType', false);
    }
    dispatch(setLoanReasonType(selectedReason));
    setValue(selectedReason);
    if (viaKeyboard) {
      setFocusedIndex(reasonTypes?.findIndex((rt) => rt.id === selectedReason.id));
      setKeyboardNav(true);
    } else {
      setFocusedIndex(null);
      setKeyboardNav(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      const newIndex = focusedIndex !== null ? (focusedIndex + 1) % reasonTypes?.length : 0;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      const newIndex =
        focusedIndex !== null
          ? (focusedIndex - 1 + reasonTypes?.length) % reasonTypes?.length
          : reasonTypes?.length - 1;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'Enter' && focusedIndex !== null) {
      handleChangeType(reasonTypes[focusedIndex], true);
    }
  };

  const handleBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    setFieldTouched('loanReasonType', true);
    if (values.loanReasonType === 'other-custom') {
      setFieldError('loanReasonType', 'Please select a reason type');
    }
  };

  useEffect(() => {
    if (keyboardNav && focusedIndex !== null) {
      reasonTypeRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex, keyboardNav]);

  useEffect(() => {
    if (loanReasonType) {
      setValue(loanReasonType);
    }
  }, [currentStepIndex, loanReasonType]);

  return (
    <FormWrapper>
      {/* eslint-disable-next-line */}
      <div className="loans__selectLoanReasonTypeForm" onKeyDown={handleKeyDown} tabIndex={-1}>
        <div className="loans__selectLoanReasonTypeForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">What&lsquo;s the reason for the loan?</h2>
            <div className="loanTypeForm__subtitle">Please select one option that applies.</div>
          </div>
          <div className="loans__selectLoanReasonTypeForm__body">
            <div className="loans__selectLoanReasonTypeForm__selectItems">
              {reasonTypes.map((type, index) => (
                <ReasonTypeSelectItem
                  key={type.id}
                  type={type}
                  isSelected={value?.id === type.id}
                  isFocused={focusedIndex === index}
                  onSelect={() => handleChangeType(type)}
                  // eslint-disable-next-line no-return-assign
                  onRef={(el) => (reasonTypeRefs.current[index] = el)}
                />
              ))}
            </div>
            {value?.title === 'Other' && (
              <div className="loans__selectLoanReasonTypeForm__input">
                <Input
                  type="text"
                  id="loanReasonType"
                  maxLength={50}
                  placeholder="Please specify the reason"
                  value={values.loanReasonType === 'other-custom' ? '' : values.loanReasonType}
                  onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('loanReasonType', true);
                    setFieldValue(target.id, target.value);
                  }}
                  onBlur={handleBlurCustom}
                  error={
                    Boolean(errors.loanReasonType) &&
                    touched.loanReasonType &&
                    'Please enter the reason'
                  }
                  showAttentionErrorIcon={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
