import { useState } from 'react';

import { SelectedFlow } from '../../../../../features/auth/store';

export const IntegrationsTabs = {
  affiliate: 'loan creation pop-up',
  billpay: 'bill pay pop-up',
  'quick-link': 'quick-link',
} as const;

export type IntegrationTabsType = typeof IntegrationsTabs[keyof typeof IntegrationsTabs];

export type SelectButtonResultReturned = {
  operations: { onSelect: (item: IntegrationTabsType) => void };
  properties: {
    selected: IntegrationTabsType;
    tabs: IntegrationTabsType[];
    selectedFlow: SelectedFlow | null;
  };
};

type useSelectButtonProps = {
  selectedFlow: SelectedFlow | null;
};

const useSelectButton = ({ selectedFlow }: useSelectButtonProps) => {
  const isFlowAvailable = selectedFlow !== null;
  const popupTab = IntegrationsTabs[selectedFlow!];
  const quicklinkTab = IntegrationsTabs['quick-link'];

  const tabs = isFlowAvailable ? [quicklinkTab, popupTab] : [];
  const [selected, setSelected] = useState<IntegrationTabsType>(tabs[0]);
  const isSecondTab = selected === 'loan creation pop-up' || selected === 'bill pay pop-up';
  const onSelect = (item: IntegrationTabsType) => {
    setSelected(item);
  };

  return {
    operations: { onSelect },
    properties: { selected, tabs, selectedFlow, isSecondTab },
  };
};

export default useSelectButton;
