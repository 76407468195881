import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import Button from '../../../shared/components/Button';
import { LoanPresetDto } from '../../../shared/models/platform-admin/loans/LoanPresetDto';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { AssetType, SelectedFlow, TAsset } from '../../auth/store';
import { quicklinkButton } from '../constants/quicklinkButton';

import IntegrationsPreview from './IntegrationsPreview';
import RequestCode from './RequestCode';

type TProps = {
  selectedCampaign: LoanPresetDto | undefined;
  activeAsset: TAsset | null;
  selectedFlow: SelectedFlow | null;
  handleToggleIntegrationsPreview: (assetType: AssetType | undefined) => void;
  responseClientId: string;
};

export default function SelectedCampaignAsset({
  selectedCampaign,
  activeAsset,
  selectedFlow,
  handleToggleIntegrationsPreview,
  responseClientId,
}: TProps) {
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);

      dispatch(
        addAlert({
          text: 'Copied to clipboard',
          type: AlertType.success,
        }),
      );
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Could not copy to clipboard',
          type: AlertType.error,
        }),
      );
    }
  };

  const updateSize = () => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [isPopUpOpen, activeAsset?.assetType, window.innerWidth, window.innerHeight]);

  const animation = useSpring({
    marginTop: isPopUpOpen ? '16px' : '0px',
    height: isPopUpOpen ? `${contentHeight}px` : '0px',
    opacity: isPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 320, friction: 40 },
  });

  const widgetSourceCode =
    '<script src="https://modal.prod.zirtue.com/bundle.min.js" type="text/javascript"></script>';

  const widgetAnchorHTMLCode = '<div id="zirtue-modal"></div>';

  const widgetInitializationCode = `
<script>
  const instance = ZirtueModal.init({
      clientID: "${responseClientId}", 
      accountId,
      amountDue,
      state: "${selectedCampaign?.state}",
      link: "${selectedCampaign?.link}",
      shortlink: "${selectedCampaign?.shortLink}",
      displayName: "${selectedCampaign?.displayName}",
      settings: {
        "type": "${selectedCampaign?.settings?.type}",
        "feeEnabled": ${selectedCampaign?.settings?.feeEnabled},
        "feeAmountPercentage": ${selectedCampaign?.settings?.feeAmountPercentage},
        "whoIsPaying": "${selectedCampaign?.settings?.whoIsPaying}",
        "flowType": "${selectedCampaign?.settings?.flowType}",
        "partnerComissionEnabled": ${selectedCampaign?.settings?.partnerComissionEnabled},
        "partnerComissionPercentageAmount": ${selectedCampaign?.settings?.partnerComissionPercentageAmount},
      },
      skipKYC: ${selectedCampaign?.skipKYC},
      useFiona: ${selectedCampaign?.useFiona},
  })
  instance.open();
</script>
`;

  return (
    <div className="selectedCampaign__selectedAsset">
      <div className="selectedCampaign__selectedAsset__title selectedCampaign__selectedAsset__title__popup ">
        {activeAsset?.assetType !== AssetType.BILL_PAY_POPUP ? activeAsset?.title : 'Pop-up'}
        {activeAsset?.assetType === AssetType.QUICK_LINK ? ' (1)' : ''}
      </div>
      {activeAsset?.assetType === AssetType.ZIRTUE_PAY_BUTTON ? (
        <div
          className={`selectedCampaign__selectedAsset__container ${
            !activeAsset?.isOpen ? 'full' : ''
          }`}
        >
          <div className="selectedCampaign__selectedAsset__container__left">
            <img src={activeAsset.iconSrcInner} alt={activeAsset.title} />
          </div>
          <div className="selectedCampaign__selectedAsset__container__right">
            <div className="selectedCampaign__selectedAsset__container__payButton">
              <div className="selectedCampaign__selectedAsset__container__payButton__section">
                <div className="selectedCampaign__selectedAsset__container__payButton__section__num">
                  1
                </div>
                <div className="selectedCampaign__selectedAsset__container__payButton__section__content">
                  <div className="selectedCampaign__selectedAsset__container__title">
                    Code snippet
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__text">
                    Add this code snippet to the part of the page where you want Zirtue Pay button
                    to appear. You can configure your custom button.
                  </div>
                  <RequestCode code={quicklinkButton(selectedCampaign?.shortLink || '')} />
                  <div className="requestCode__linkBlock">
                    <div className="requestCode__divider" />
                    <div className="selectedCampaign__selectedAsset__container__title">
                      Your link
                    </div>
                    <div className="selectedCampaign__selectedAsset__container__text">
                      You can also copy and paste this link in any part of the page to enable
                      Zirtue.
                    </div>
                    <div className="requestCode__linkBlock__link">
                      <div className="requestCode__linkBlock__link__left">Copy link</div>
                      <div className="requestCode__linkBlock__link__right">
                        <div className="requestCode__linkBlock__link__text">
                          {selectedCampaign?.shortLink}
                        </div>
                        {/* eslint-disable-next-line */}
                        <img
                          className="requestCode__linkBlock__link__icon"
                          src="images/dashboard/copy-gray.svg"
                          alt="copy-gray"
                          onClick={() => copyTextToClipboard(selectedCampaign?.shortLink || '')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selectedCampaign__selectedAsset__container__payButton__section">
                <div className="selectedCampaign__selectedAsset__container__payButton__section__num">
                  2
                </div>
                <div className="selectedCampaign__selectedAsset__container__payButton__section__content">
                  <div className="selectedCampaign__selectedAsset__container__title">
                    Button Preview
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__text">
                    Insert this code snippet into the section of the page where you want the Zirtue
                    Pay button to be displayed. Color configurations must adhere to Zirtue&lsquo;s
                    brand guidelines.
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__buttonBlock">
                    <Button className="button button--primary-blue button--sm" icon="zirtue-logo">
                      Zirtue Pay
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {activeAsset?.assetType === AssetType.QUICK_LINK ? (
        <div className="selectedCampaign__selectedAsset__cards">
          <div className="selectedCampaign__selectedAsset__card">
            <div className="selectedCampaign__selectedAsset__card__left">
              <img src={activeAsset?.iconSrcInner} alt={activeAsset?.title} />
            </div>
            <div className="selectedCampaign__selectedAsset__card__right">
              <div className="selectedCampaign__selectedAsset__card__title">Quick Link 1</div>
              <div className="selectedCampaign__selectedAsset__card__linkBlock__link">
                <div className="selectedCampaign__selectedAsset__card__linkBlock__link__left">
                  Active
                </div>
                <div className="selectedCampaign__selectedAsset__card__linkBlock__link__right">
                  <div className="selectedCampaign__selectedAsset__card__linkBlock__link__text">
                    {selectedCampaign?.shortLink}
                  </div>
                  {/* eslint-disable-next-line */}
                  <img
                    className="requestCode__linkBlock__link__icon"
                    src="images/dashboard/copy-gray.svg"
                    alt="copy-gray"
                    onClick={() => copyTextToClipboard(selectedCampaign?.shortLink || '')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {activeAsset?.assetType === AssetType.BILL_PAY_POPUP ? (
        <div
          className={`selectedCampaign__selectedAsset__container ${
            !activeAsset?.isOpen ? 'full' : ''
          }`}
        >
          <div className="selectedCampaign__selectedAsset__container__left">
            <img src={activeAsset.iconSrcInner} alt={activeAsset.title} />
          </div>
          <div className="selectedCampaign__selectedAsset__container__right selectedCampaign__selectedAsset__container__right__popUp">
            {/* eslint-disable-next-line */}
            <h2
              className="selectedCampaign__selectedAsset__container__right__popUp__title"
              onClick={() => {
                if (activeAsset?.assetType === AssetType.BILL_PAY_POPUP) {
                  setIsPopUpOpen(!isPopUpOpen);
                }
              }}
            >
              Pop-up widget
              <img
                className={isPopUpOpen ? 'isOpen' : ''}
                src="images/dashboard/angle-right-solid.svg"
                alt="angle-right-solid"
              />
            </h2>
            <animated.div
              style={animation}
              className="selectedCampaign__selectedAsset__container__right__popUp__innerBody"
              ref={contentRef}
            >
              <div className="selectedCampaign__selectedAsset__container__popUp__section">
                <div className="selectedCampaign__selectedAsset__container__popUp__section__num">
                  1
                </div>
                <div className="selectedCampaign__selectedAsset__container__popUp__section__content">
                  <div className="selectedCampaign__selectedAsset__container__title">
                    Widget source code
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__text">
                    Paste this Javascript source code call into the
                    <code className="selectedCampaign__selectedAsset__container__code">
                      {' <head> '}
                    </code>
                    of every page in order to enable the Zirtue experience.
                  </div>
                  <RequestCode code={widgetSourceCode} />
                </div>
              </div>
              <div className="selectedCampaign__selectedAsset__container__popUp__section">
                <div className="selectedCampaign__selectedAsset__container__popUp__section__num">
                  2
                </div>
                <div className="selectedCampaign__selectedAsset__container__popUp__section__content">
                  <div className="selectedCampaign__selectedAsset__container__title">
                    Widget anchor HTML
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__text">
                    The anchor HTML to which the Javascript will attach the modal.
                  </div>
                  <RequestCode code={widgetAnchorHTMLCode} />
                </div>
              </div>
              <div className="selectedCampaign__selectedAsset__container__popUp__section">
                <div className="selectedCampaign__selectedAsset__container__popUp__section__num">
                  3
                </div>
                <div className="selectedCampaign__selectedAsset__container__popUp__section__content">
                  <div className="selectedCampaign__selectedAsset__container__title">
                    Widget initialization code
                  </div>
                  <div className="selectedCampaign__selectedAsset__container__text">
                    This code block initiates our Zirtue modal and then calls the open() function to
                    cause the popup action, presenting it to the user. You can place this in a
                    function and call that function with the Zirtue Pay button on click action.
                  </div>
                  <RequestCode code={widgetInitializationCode} />
                </div>
              </div>
            </animated.div>
          </div>
        </div>
      ) : (
        ''
      )}
      <IntegrationsPreview
        activeAsset={activeAsset}
        selectedFlow={selectedFlow}
        handleToggleIntegrationsPreview={handleToggleIntegrationsPreview}
      />
    </div>
  );
}
