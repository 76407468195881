import React, { useState, useEffect, useRef, FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  getBillPaymentsSettings,
  changeBillPaymentsSettings,
  resetBillPaymentsSettings,
} from '../../features/payments/store/index';
import { useAppSelector } from '../../main/store/hooks';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

import Icon from './Icon';
import Switch from './Switch';
import Tooltip from './Tooltip';

interface TableCustomizeFilterProps {
  onReset: () => void;
}

const TableCustomizeFilter: FC<TableCustomizeFilterProps> = ({ onReset }) => {
  const [open, setOpen] = useState(false);
  const { billPaymentsSettings } = useAppSelector((state) => state.payments);
  const dispatch = useDispatch();
  const elemRef = useRef<HTMLDivElement>(null);
  const isWindowsOs = navigator.userAgent.indexOf('Windows') !== -1;

  useEffect(() => {
    dispatch(getBillPaymentsSettings(null));
  }, []);

  const handleChange = (id: string) => {
    dispatch(changeBillPaymentsSettings({ id }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    dispatch(resetBillPaymentsSettings(null));
    onReset();
    handleClose();
  };

  useOnClickOutside(elemRef, handleClose);

  return (
    <div className="table-customize-filter" ref={elemRef}>
      <Tooltip
        text="Customize"
        position="bottom center"
        arrowPosition={isWindowsOs ? 'filter' : 'center'}
      >
        <button
          type="button"
          className="table-customize-filter__btn"
          onClick={() => setOpen(!open)}
        >
          <Icon name="filter" />
        </button>
      </Tooltip>

      {open && (
        <div className="table-customize-filter__box">
          <div className="table-customize-filter__head">
            Customize columns
            <button type="button" className="table-customize-filter__close" onClick={handleClose}>
              <Icon name="cross" />
            </button>
          </div>
          <div className="table-customize-filter__body">
            <ul className="table-customize-filter__list">
              {billPaymentsSettings.map((item) => (
                <li className="table-customize-filter__item" key={item.id}>
                  <span>{item.name}</span>
                  <Switch
                    id={item.id}
                    checked={item.checked}
                    disabled={item.disabled}
                    onChange={handleChange}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="table-customize-filter__footer">
            <button type="button" className="table-customize-filter__reset" onClick={handleReset}>
              Revert to default
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCustomizeFilter;
