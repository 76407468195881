import { findLastIndex } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import Button from '../../../../shared/components/Button';
import { LoanClosureType, LoanStage, LoanStageKind } from '../../dtos/LoanTypes';
import { RepaymentPlanPayment } from '../../dtos/RepaymentPlan';
import { PaymentStatuses } from '../../dtos/RepaymentPlanDto';

import LoanScheduleItem from './LoanScheduleItem';

const NUM_ITEMS_COLLAPSED = 4;

interface LoanScheduleProps {
  payments: Array<{ status: PaymentStatuses; payment: RepaymentPlanPayment }>;
  loanRepaymentDateFrom?: string;
  drawFail?: boolean;
  drawFailSystemIssue?: boolean;
  loanStage?: LoanStage;
}
export default function LoanSchedule({
  payments,
  loanRepaymentDateFrom,
  drawFail,
  drawFailSystemIssue,
  loanStage,
}: LoanScheduleProps) {
  const [isCollapsed, setIsCollapsed] = useState(payments.length > 4);
  const [extraHeight, setExtraHeight] = useState(0);
  const [collapsedIndex, setCollapsedIndex] = useState(0);

  const selectRef = React.createRef<HTMLButtonElement>();

  const btnTitle = isCollapsed ? 'Show all' : 'Show less';

  const btnSeeAllVisible = payments.length > 4;

  const handleBtnPress = () => {
    setIsCollapsed((value) => !value);
    const select = selectRef?.current;
    const scrollTop = window.scrollY;
    if (select && isCollapsed) {
      const spaceTop = select.offsetTop - scrollTop;
      const spaceBottom = window.innerHeight - (spaceTop + select.offsetHeight + 200);
      if (window.scrollY) {
        setExtraHeight(395);
        return;
      }
      const paymentsHeight = (payments.length - 1) * 90;
      if (paymentsHeight < spaceBottom) {
        setExtraHeight(0);
        return;
      }
      setExtraHeight(spaceBottom);
      return;
    }
    setExtraHeight(0);
  };

  useLayoutEffect(() => {
    if (
      (loanStage && loanStage.kind === LoanStageKind.repayment) ||
      (loanStage &&
        loanStage.kind === LoanStageKind.closed &&
        [LoanClosureType.forgiven, LoanClosureType.paidOut].indexOf(loanStage.closureType) >= 0)
    ) {
      const upcomingIndex = payments.findIndex(
        (p) =>
          (p.status === PaymentStatuses.missed && p.payment.isCurrent) ||
          p.status === PaymentStatuses.autoPay ||
          p.status === PaymentStatuses.inProgress,
      );
      if (upcomingIndex === 0 || upcomingIndex === 1) {
        setCollapsedIndex(0);
      } else if (
        payments[upcomingIndex - 2] &&
        [PaymentStatuses.failed, PaymentStatuses.missed, PaymentStatuses.completed].includes(
          payments[upcomingIndex - 2].status,
        ) &&
        upcomingIndex - 2 + NUM_ITEMS_COLLAPSED <= payments.length
      ) {
        setCollapsedIndex(upcomingIndex - 2);
      } else {
        setCollapsedIndex(payments.length - NUM_ITEMS_COLLAPSED);
      }
    }
  }, [loanStage, payments]);

  const collapsedItems = useMemo(
    () => payments.slice(collapsedIndex, collapsedIndex + NUM_ITEMS_COLLAPSED),
    [payments, collapsedIndex],
  );

  const items = isCollapsed ? collapsedItems : payments;

  const isMissedButFailed = useCallback(
    (item: { status: PaymentStatuses; payment: RepaymentPlanPayment }) =>
      item.status === PaymentStatuses.failed && item.payment.isCurrent && drawFail,
    [drawFail],
  );

  const isOneTime = payments.length === 1;

  const lastMissedIndex = findLastIndex(
    payments,
    (value) => !value.payment.isCurrent && value.status === PaymentStatuses.failed,
  );

  const hasFailedPayment = drawFail || drawFailSystemIssue;

  return (
    <>
      <ul
        style={{ height: isCollapsed ? 'auto' : extraHeight ? `${extraHeight}px` : 'auto' }}
        className={`loan__schedule ${isCollapsed ? '' : 'loan__schedule--extra-height'}`}
      >
        {items.map((item, index) => (
          <LoanScheduleItem
            key={index}
            payment={item.payment}
            status={isMissedButFailed(item) ? PaymentStatuses.missed : item.status}
            isOneTime={isOneTime}
            loanRepaymentDateFrom={loanRepaymentDateFrom}
            drawFail={drawFail && item.payment.isCurrent}
            drawFailSystemIssue={drawFailSystemIssue && item.payment.isCurrent}
            loanStage={loanStage}
            drawMissed={index === lastMissedIndex && !hasFailedPayment}
          />
        ))}
      </ul>
      {btnSeeAllVisible ? (
        <Button
          ref={selectRef}
          onClick={handleBtnPress}
          className="loan__schedule__collapse-button"
        >
          {btnTitle}
        </Button>
      ) : null}
    </>
  );
}
