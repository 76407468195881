import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import StoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import {
  AuthTokenCreateModel,
  AuthTokenCreateResponseDto,
  AuthTokenCreateResponse,
} from '../../../../shared/models/AuthToken';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const createAnonim = createAsyncThunk<
  AuthTokenCreateResponse,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('auth/createAnonim', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<AuthTokenCreateResponseDto>('/users', {
      browserId: payload,
    });
    const response = AuthTokenCreateModel.fromDTO(data);
    localStorage.setItem(StoreKeys.authToken, JSON.stringify(response));

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
