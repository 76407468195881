import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { RootState } from '../../../main/store';
import { useAppDispatch } from '../../../main/store/hooks';
import { Input, Label, Select } from '../../../shared';
import Switch from '../../../shared/components/Switch';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { updateSftpReportSettings } from '../store/actions/updateSftpReportSettings';

interface FileTransferSetupProps {
  onChange: () => void;
}

const dataProtocol = [
  { name: 'SFTP (Secure File Transfer Protocol)', dataId: 'sftp' },
  // { name: 'FTP (File Transfer Protocol)', dataId: 'ftp' },
];

const validationSchemaInit = yup.object({
  hostname: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
  path: yup.string().required(),
});

const validEmail = yup.string().email();

const validationSchemaReport = yup.object({
  hostname: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
  path: yup.string().required(),
  report: yup.string().test('recipient list', 'Email is invalid', (value) => {
    const emails = value?.split(',').map((el) => el.trim());
    return !!emails?.every((e) => validEmail.isValidSync(e)) || !value;
  }),
});

export default function FileTransferSetup({ onChange }: FileTransferSetupProps) {
  const [checked, setChecked] = useState(false);
  const [protocol, setProtocol] = useState<'sftp' | 'ftp'>('sftp');
  const [validationSchema, setValidationSchema] = useState(validationSchemaInit);
  const dispatch = useAppDispatch();
  const { fileTransferSettings } = useSelector((state: RootState) => state.devTools);

  const { errors, handleSubmit, handleChange, values, touched, setFieldValue, setFieldError } =
    useFormik({
      initialValues: {
        hostname: '',
        port: 21,
        username: '',
        password: '',
        path: '',
        report: '',
      },
      onSubmit: async (values) => {
        // @ts-ignore
        const transferProtocol = dataProtocol.find((el) => el.dataId === protocol).name;
        const { hostname, port, username, password, path, report } = values;
        const recipients = report ? report.split(',').map((i) => i.trim()) : [];

        const response = await dispatch(
          updateSftpReportSettings({
            enabled: true,
            sftp: {
              host: hostname,
              port: port.toString(),
              userName: username,
              password,
              path,
            },
            reportRecipients: {
              attachments: checked,
              enabled: Boolean(recipients.length),
              recipients,
            },
          }),
        );

        if (fileTransferSettings) {
          Amplitude.logEvent(AmplitudeEvents.fileTransferIntegrationEditCompleted, {
            emailEdited: isEqual(
              recipients,
              fileTransferSettings.config.reportRecipients.recipients,
            )
              ? 'n'
              : 'y',
            email: report,
          });
        } else {
          Amplitude.logEvent(AmplitudeEvents.fileTransferIntegrationCompleted, {
            fileReporting: checked ? 'y' : 'n',
            email: report,
          });
        }

        if (updateSftpReportSettings.fulfilled.match(response)) {
          onChange();
        }
      },
      validationSchema,
    });

  useEffect(() => {
    if (fileTransferSettings) {
      const { host, userName, password, path, port } = fileTransferSettings.config.sftp;
      const { recipients, attachments } = fileTransferSettings.config.reportRecipients;
      setFieldValue('hostname', host, true);
      setFieldValue('username', userName, true);
      setFieldValue('password', password, true);
      setFieldValue('path', path, true);
      setFieldValue('port', port, false);
      setChecked(attachments);
      setFieldValue('report', recipients.join(', '), attachments);

      if (attachments) {
        setValidationSchema(validationSchemaReport);
      }
    }
  }, []);

  const buildErrorText = (field: 'hostname' | 'username' | 'password' | 'path' | 'report') =>
    Boolean(errors[field]) && touched[field] && errors[field];

  const handleSwitchReport = () => {
    setValidationSchema(checked ? validationSchemaInit : validationSchemaReport);

    if (checked) {
      setFieldValue('report', values.report, false);
      setFieldError('report', undefined);
    }

    setChecked(!checked);
  };

  return (
    <div className="dev-tools">
      <div className="dev-tools__config-title">Configuration</div>
      <h3 className="dev-tools__title dev-tools__title--center">Setup file transfer</h3>
      <h4 className="dev-tools__subtitle dev-tools__subtitle--center">
        Transfer files with SFTP and FTP connections, and orchestrate it all with our API.
      </h4>
      <h4 className="dev-tools__subtitle dev-tools__subtitle--mb-sm">Configuration</h4>
      <p className="dev-tools__txt">
        Select which type of data integration you want to include to get file access, file transfer,
        and file management functionalities.&nbsp;
        {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" className="dev-tools__link">
            Learn more
          </a>
        }
      </p>
      <form onSubmit={handleSubmit}>
        <div className="dev-tools__select-box">
          <div className="dev-tools__select-box-name">Type</div>
          <div className="dev-tools__select-control">
            <Select
              id="protocol"
              value={protocol}
              placeholder=""
              classes="select--button select--no-margin"
              onChange={(e) => setProtocol(e)}
              data={dataProtocol}
            />
          </div>
        </div>

        <div className="dev-tools__block">
          <div className="dev-tools__row">
            <div className="dev-tools__col">
              <div className="form-group">
                <Label forId="hostname">Hostname</Label>
                <Input
                  type="text"
                  id="hostname"
                  value={values.hostname}
                  placeholder="Enter hostname"
                  onChange={handleChange}
                  error={buildErrorText('hostname')}
                />
              </div>
            </div>
            <div className="dev-tools__col">
              <div className="form-group">
                <Label forId="port">Port number</Label>
                <Input
                  type="number"
                  id="port"
                  value={values.port}
                  classes="input-group--shadow"
                  onChange={handleChange}
                  min={1}
                />
              </div>
            </div>
          </div>

          <div className="dev-tools__row">
            <div className="dev-tools__col">
              <div className="form-group">
                <Label forId="username">Username</Label>
                <Input
                  type="text"
                  id="username"
                  placeholder="Enter username"
                  value={values.username}
                  onChange={handleChange}
                  error={buildErrorText('username')}
                />
              </div>
            </div>
            <div className="dev-tools__col">
              <div className="form-group">
                <Label forId="password">Password</Label>
                <Input
                  type="password"
                  id="password"
                  value={values.password}
                  placeholder="Enter password"
                  passwordTypeBtn="text"
                  onChange={handleChange}
                  error={buildErrorText('password')}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <Label forId="path">Path</Label>
            <Input
              type="text"
              id="path"
              value={values.path}
              placeholder="Enter path"
              error={buildErrorText('path')}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="dev-tools__block">
          <div className="form-group">
            <Label forId="report">Receive reports (.xlsx) to</Label>
            <Input
              type="text"
              id="report"
              value={values.report}
              disabled={!checked}
              placeholder="Enter email address"
              error={buildErrorText('report')}
              onChange={handleChange}
            />
          </div>

          <div className="form-group form-group--reporting">
            <Label forId="reporting">Reporting</Label>
            <Switch id="reporting" onChange={handleSwitchReport} checked={checked} isShowText />
          </div>
        </div>
        <div className="dev-tools__controls">
          <button className="button button--sm button--primary-blue" type="submit">
            Setup file transfer
          </button>
        </div>
      </form>
    </div>
  );
}
