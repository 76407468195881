import cn from 'classnames';
import { format } from 'date-fns';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { formatDollarAmount } from '../../../../shared/utils';
import { getPaymentMethods } from '../../../finances/store/actions';
import { FormWrapper } from '../../../init-setup/components';
import trustage from '../../assets/trustage.svg';
import { LoanBorrowerType, PlanType } from '../../store';
import { FormData as FormDataLoans } from '../LoanCreationForm/LoanCreationForm';
import ModalLoanCoverage from '../ModalLoanCoverage/ModalLoanCoverage';
import ModalServiceFee from '../ModalServiceFee/ModalServiceFee';
import ModalWhatNext from '../ModalWhatNext/ModalWhatNext';

type FormikDataTypes = {
  values: FormDataLoans;
};

export default function LoanSummaryForm({ values }: FormikDataTypes) {
  const formatDate = (date: Date): string => format(new Date(date || ''), 'MMMM d, yyyy');
  const dispatch = useAppDispatch();

  const { loanBorrowerType, loanRepaymentMonths, paymentScheduleDetailed } = useAppSelector(
    (state) => state.loans,
  );

  const firstPayment = paymentScheduleDetailed.payments[0];
  const finalPayment =
    paymentScheduleDetailed.payments[paymentScheduleDetailed.payments.length - 1];

  const [isModalServiceFeeOpen, setIsModalServiceFeeOpen] = useState(false);
  const [isModalLoanCoverageOpen, setIsModalLoanCoverageOpen] = useState(false);
  const [isModalWhatNextOpen, setIsModalWhatNextOpen] = useState(false);

  const [isLoansDetailsPopUpOpen, setIsLoansDetailsPopUpOpen] = useState(false);
  const [isLoansRepaymentPopUpOpen, setIsLoansRepaymentPopUpOpen] = useState(false);
  const [isLoansSummaryPopUpOpen, setIsLoansSummaryPopUpOpen] = useState(false);

  const [loansDetailsHeight, setLoansDetailsHeight] = useState(0);
  const [loansRepaymentHeight, setLoansRepaymentHeight] = useState(0);
  const [loansSummaryHeight, setLoansSummaryHeight] = useState(0);

  const loansDetailsRef = useRef<HTMLDivElement>(null);
  const loansRepaymentRef = useRef<HTMLDivElement>(null);
  const loansSummaryRef = useRef<HTMLDivElement>(null);

  const loansDetailsAnimation = useSpring({
    marginTop: isLoansDetailsPopUpOpen ? '0' : '0px',
    height: isLoansDetailsPopUpOpen ? `${loansDetailsHeight}px` : '0px',
    opacity: isLoansDetailsPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const loansRepaymentAnimation = useSpring({
    marginTop: isLoansRepaymentPopUpOpen ? '0' : '0px',
    height: isLoansRepaymentPopUpOpen ? `${loansRepaymentHeight}px` : '0px',
    opacity: isLoansRepaymentPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });
  const loansSummaryAnimation = useSpring({
    marginTop: isLoansSummaryPopUpOpen ? '0' : '0px',
    height: isLoansSummaryPopUpOpen ? `${loansSummaryHeight}px` : '0px',
    opacity: isLoansSummaryPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  useEffect(() => {
    if (loansDetailsRef.current) {
      setLoansDetailsHeight(loansDetailsRef.current.scrollHeight);
    }
  }, [isLoansDetailsPopUpOpen]);

  useEffect(() => {
    if (loansRepaymentRef.current) {
      setLoansRepaymentHeight(loansRepaymentRef.current.scrollHeight);
    }
  }, [isLoansRepaymentPopUpOpen]);

  useEffect(() => {
    if (loansSummaryRef.current) {
      setLoansSummaryHeight(loansSummaryRef.current.scrollHeight);
    }
  }, [isLoansSummaryPopUpOpen]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getPaymentMethods());
  }, []);

  return (
    <>
      <FormWrapper>
        <div className="loans__selectLoanSummaryForm">
          <div className="loans__selectLoanSummaryForm__container">
            <div className="loanTypeForm__head">
              <h2 className="loanTypeForm__title">Review your loan offer</h2>
              <div className="loanTypeForm__subtitle">
                If everything looks correct, click Create offer.
                <br />
                We will give you a link that you can directly share with them.
              </div>
            </div>
            <div className="loans__selectLoanSummaryForm__body">
              {loanBorrowerType === LoanBorrowerType.EMPLOYEE ? (
                <div className="loans__selectLoanSummaryForm__loansCoverageBlock">
                  <div className="loans__selectLoanSummaryForm__loansCoverageBlock__body">
                    <div className="loans__selectLoanSummaryForm__loansCoverageBlock__descr">
                      <div className="loans__selectLoanSummaryForm__loansCoverageBlock__title">
                        Your loan is covered
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansCoverageBlock__subtitle">
                        We teamed up with TruStage™ to provide Payment Guard Insurance at no
                        additional cost to you.
                      </div>
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansCoverageBlock__iconBlock">
                      <img src={trustage} alt="trustage" />
                    </div>
                  </div>
                  <div className="loans__selectLoanSummaryForm__loansCoverageBlock__footer">
                    <Button
                      type="button"
                      className="button button--primary-blue button--lg button--block loans__selectLoanSummaryForm__loansCoverageBlock__button"
                      onClick={() => setIsModalLoanCoverageOpen(true)}
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="loans__selectLoanSummaryForm__loansDetails loans__selectLoanSummaryForm__loansDetails--hasFooter">
                <div className="loans__selectLoanSummaryForm__loansDetails__body loans__selectLoanSummaryForm__loansDetails__body--hasFooter">
                  <div
                    className={cn(
                      'loans__selectLoanSummaryForm__loansDetails__row',
                      !isLoansDetailsPopUpOpen
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--mb16'
                        : 'loans__selectLoanSummaryForm__loansDetails__row--mb32',
                    )}
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">
                      Lending to
                    </div>
                    <button
                      type="button"
                      className={cn(
                        'loans__selectLoanSummaryForm__loansDetails__accordion',
                        isLoansDetailsPopUpOpen
                          ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                          : '',
                      )}
                      onClick={() => {
                        setIsLoansDetailsPopUpOpen(!isLoansDetailsPopUpOpen);
                      }}
                    >
                      <div className="loans__selectLoanSummaryForm__loansDetails__value">
                        {`${values.firstName} ${values.lastName}`}
                        <Icon name="chevron-down" />
                      </div>
                    </button>
                  </div>
                  <animated.div
                    style={loansDetailsAnimation}
                    ref={loansDetailsRef}
                    className="loans__selectLoanSummaryForm__loansDetails__innerBody"
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__row">
                      <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                        Phone number
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                        {`+1 ${values.phone}`}
                      </div>
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansDetails__row">
                      <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                        Relationship
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                        {values.loanBorrowerType === LoanBorrowerType.EMPLOYEE
                          ? 'Employee / Team Member'
                          : 'Business Entity'}
                      </div>
                    </div>
                  </animated.div>
                  <div
                    className={cn(
                      'loans__selectLoanSummaryForm__loansDetails__row',
                      !isLoansDetailsPopUpOpen
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--mt16'
                        : 'loans__selectLoanSummaryForm__loansDetails__row--mt32',
                    )}
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">
                      Reason for loan
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                      {values.loanReasonType}
                    </div>
                  </div>
                </div>
                <div className="loans__selectLoanSummaryForm__loansDetails__footer">
                  <div className="loans__selectLoanSummaryForm__loansDetails__footer__iconBlock">
                    <Icon name="lightbulb-blue" />
                  </div>
                  <div className="loans__selectLoanSummaryForm__loansDetails__footer__descr">
                    <div className="loans__selectLoanSummaryForm__loansDetails__footer__text">
                      Not sure what happens next?
                    </div>
                    <button
                      type="button"
                      className="loans__selectLoanSummaryForm__loansDetails__footer__link"
                      onClick={() => setIsModalWhatNextOpen(true)}
                    >
                      Learn more
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="loans__selectLoanSummaryForm__title">Repayment details</h3>
              <div
                className={cn(
                  'loans__selectLoanSummaryForm__loansDetails',
                  values.loanPaymentType === PlanType.MONTHLY
                    ? 'loans__selectLoanSummaryForm__loansDetails--hasFooter'
                    : '',
                )}
              >
                <div
                  className={cn(
                    'loans__selectLoanSummaryForm__loansDetails__body',
                    values.loanPaymentType === PlanType.MONTHLY
                      ? 'loans__selectLoanSummaryForm__loansDetails__body--detailsBlock'
                      : 'loans__selectLoanSummaryForm__loansDetails__body--hasFooter',
                  )}
                >
                  <div
                    className={cn(
                      'loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mb32',
                      values.loanPaymentType === PlanType.MONTHLY
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding'
                        : '',
                    )}
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">Type</div>
                    <div
                      className={cn(
                        'loans__selectLoanSummaryForm__loansDetails__value',
                        values.loanPaymentType === PlanType.MONTHLY
                          ? 'loans__selectLoanSummaryForm__loansDetails__value--second'
                          : '',
                      )}
                    >
                      {values.loanPaymentType === PlanType.MONTHLY ? 'Monthly' : 'One-time'}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'loans__selectLoanSummaryForm__loansDetails__row',
                      isLoansRepaymentPopUpOpen
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--mb32'
                        : '',
                      values.loanPaymentType === PlanType.MONTHLY
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding'
                        : '',
                    )}
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">
                      {values.loanPaymentType === PlanType.MONTHLY
                        ? 'Repayment schedule'
                        : 'Repayment date'}
                    </div>
                    {values.loanPaymentType === PlanType.MONTHLY ? (
                      <button
                        type="button"
                        className={cn(
                          'loans__selectLoanSummaryForm__loansDetails__accordion',
                          isLoansRepaymentPopUpOpen
                            ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                            : '',
                        )}
                        onClick={() => {
                          setIsLoansRepaymentPopUpOpen(!isLoansRepaymentPopUpOpen);
                        }}
                      >
                        <div className="loans__selectLoanSummaryForm__loansDetails__value">
                          {`${loanRepaymentMonths} Months`}
                          <Icon name="chevron-down" />
                        </div>
                      </button>
                    ) : (
                      <div
                        className={cn(
                          'loans__selectLoanSummaryForm__loansDetails__accordion',
                          isLoansRepaymentPopUpOpen
                            ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                            : '',
                        )}
                      >
                        <div className="loans__selectLoanSummaryForm__loansDetails__value">
                          {values.loanRepaymentDate ? formatDate(values.loanRepaymentDate) : ''}
                        </div>
                      </div>
                    )}
                  </div>
                  <animated.div
                    style={loansRepaymentAnimation}
                    ref={loansRepaymentRef}
                    className="loans__selectLoanSummaryForm__loansDetails__innerBody"
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__infoBlock">
                      <div className="loans__selectLoanSummaryForm__loansDetails__footer__iconBlock">
                        <Icon name="sparkle-blue" />
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansDetails__footer__descr">
                        <div className="loans__selectLoanSummaryForm__loansDetails__footer__text">
                          Sit back and relax while Auto-Pay takes care of the rest. Your borrower
                          can always make payments ahead of schedule.
                        </div>
                      </div>
                    </div>
                    <div
                      className={cn(
                        'loans__selectLoanSummaryForm__loansDetails__row',
                        values.loanPaymentType === PlanType.MONTHLY
                          ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding'
                          : '',
                      )}
                    >
                      <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                        First payment due
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                        {firstPayment.date}
                      </div>
                    </div>
                    <div
                      className={cn(
                        'loans__selectLoanSummaryForm__loansDetails__row',
                        values.loanPaymentType === PlanType.MONTHLY
                          ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding'
                          : '',
                      )}
                    >
                      <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                        Final payment due
                      </div>
                      <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                        {finalPayment.date}
                      </div>
                    </div>
                  </animated.div>
                </div>
                {values.loanPaymentType === PlanType.MONTHLY ? (
                  ''
                ) : (
                  <div className="loans__selectLoanSummaryForm__loansDetails__footer">
                    <div className="loans__selectLoanSummaryForm__loansDetails__footer__iconBlock">
                      <Icon name="sparkle-blue" />
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansDetails__footer__descr">
                      <div className="loans__selectLoanSummaryForm__loansDetails__footer__text">
                        Sit back and relax while Auto-Pay takes care of the rest. Your borrower can
                        always make payments ahead of schedule.
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <h3 className="loans__selectLoanSummaryForm__title">Payment summary</h3>
              <div className="loans__selectLoanSummaryForm__loansDetails loans__selectLoanSummaryForm__loansDetails__mb96">
                <div className="loans__selectLoanSummaryForm__loansDetails__body">
                  <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mb32">
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">
                      Loan amount
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                      {formatDollarAmount(values.loanAmount)}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'loans__selectLoanSummaryForm__loansDetails__row',
                      isLoansSummaryPopUpOpen
                        ? 'loans__selectLoanSummaryForm__loansDetails__row--mb32'
                        : '',
                    )}
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">Fees</div>
                    <button
                      type="button"
                      className={cn(
                        'loans__selectLoanSummaryForm__loansDetails__accordion',
                        isLoansSummaryPopUpOpen
                          ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                          : '',
                      )}
                      onClick={() => {
                        setIsLoansSummaryPopUpOpen(!isLoansSummaryPopUpOpen);
                      }}
                    >
                      <div className="loans__selectLoanSummaryForm__loansDetails__value">
                        {formatDollarAmount(values.loanTotalFeeAmount)}
                        <Icon name="chevron-down" />
                      </div>
                    </button>
                  </div>
                  <animated.div
                    style={loansSummaryAnimation}
                    ref={loansSummaryRef}
                    className="loans__selectLoanSummaryForm__loansDetails__innerBody"
                  >
                    <div className="loans__selectLoanSummaryForm__loansDetails__row">
                      <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                        Service fee
                      </div>
                      {/* eslint-disable-next-line */}
                      <div
                        className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value"
                        onClick={() => setIsModalServiceFeeOpen(true)}
                      >
                        {formatDollarAmount(values.loanServiceFeeAmount)}
                        <Icon
                          name="icon-info"
                          classes="loans__selectAmountForm__summaryBlock__label__icon"
                        />
                      </div>
                    </div>
                    {loanBorrowerType === LoanBorrowerType.EMPLOYEE ? (
                      <div className="loans__selectLoanSummaryForm__loansDetails__row">
                        <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                          Loan coverage
                          <div className="loans__selectAmountForm__summaryBlock__label__badge">
                            <div className="loans__selectAmountForm__summaryBlock__label__badge__text">
                              NEW
                            </div>
                          </div>
                        </div>
                        {/* eslint-disable-next-line */}
                      <div
                          className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value"
                          onClick={() => setIsModalLoanCoverageOpen(true)}
                        >
                          {formatDollarAmount(values.loanCoverageAmount)}
                          <Icon
                            name="icon-info"
                            classes="loans__selectAmountForm__summaryBlock__label__icon"
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </animated.div>
                  <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mt32">
                    <div className="loans__selectLoanSummaryForm__loansDetails__label">
                      Total repayment
                    </div>
                    <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                      {formatDollarAmount(values.loanTotalAmount)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
      <ModalServiceFee isOpen={isModalServiceFeeOpen} setIsOpen={setIsModalServiceFeeOpen} />
      <ModalLoanCoverage isOpen={isModalLoanCoverageOpen} setIsOpen={setIsModalLoanCoverageOpen} />
      <ModalWhatNext
        isOpen={isModalWhatNextOpen}
        setIsOpen={setIsModalWhatNextOpen}
        firstName={values.firstName}
      />
    </>
  );
}
