import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const delCompanyRole = createAsyncThunk<
  undefined,
  { uuid: string },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/deleteRole', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.delete(`/security/groups/${payload.uuid}`);

    dispatch(addAlert({ text: 'Role successfully deleted' }));

    return undefined;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
