import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from '../../../shared';

interface FileUploadAreaProps {
  title?: string;
  label?: string;
  labelColor?: string;
  className?: string;
  onChangeAcceptedFiles: (files: File) => void;
}
const FileUploadArea = ({
  title,
  label,
  labelColor,
  className = '',
  onChangeAcceptedFiles,
}: FileUploadAreaProps) => {
  const [rejectedFile, setRejectedFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      onChangeAcceptedFiles(acceptedFiles[0]);
      setRejectedFile(null);
    }
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length) {
      setRejectedFile(rejectedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: 50000000,
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={`file-upload ${className} ${isDragActive && 'file-upload--active'} ${
          rejectedFile && 'file-upload--rejected'
        }`}
      >
        <div className="file-upload__img">
          {label && (
            <span className="file-upload__label" style={{ backgroundColor: labelColor }}>
              {label}
            </span>
          )}
          <Icon name="file-upload" />
        </div>
        {title && <h4 className="file-upload__title">{title}</h4>}
        <input {...getInputProps()} />
      </div>

      {rejectedFile && <div className="file-upload__error-text">Upload failed</div>}
    </>
  );
};

export default FileUploadArea;
