import React, { Dispatch, FC, SetStateAction } from 'react';

import Icon from '../../Icon';
import useCheckoutList, { CheckoutListItem } from '../hooks/useCheckoutList';

import ListItemWrapper from './ListItemWrapper';

type ListItemProps = { setShowModal: Dispatch<SetStateAction<boolean>> } & Pick<
  ReturnType<typeof useCheckoutList>['operations'],
  'checkItem'
> &
  Pick<CheckoutListItem, 'isDone' | 'linkTo' | 'title' | 'property'>;

const ListItem: FC<ListItemProps> = ({
  isDone,
  linkTo,
  title,
  setShowModal,
  checkItem,
  property,
}) => (
  <li>
    <ListItemWrapper
      linkTo={linkTo}
      isDone={isDone}
      setShowModal={setShowModal}
      checkItem={checkItem}
      property={property}
    >
      <span className="checkout-list__container__list-item__checkmark">
        {isDone && (
          <Icon name="check" classes="checkout-list__container__list-item__checkmark__icon" />
        )}
      </span>
      <p className="checkout-list__container__list-item__title">{title}</p>
    </ListItemWrapper>
  </li>
);

export default ListItem;
