export enum TransferStatus {
  PAID = 'PAID',
  FAILED = 'FAILED',
  IN_PROCESS = 'IN_PROCESS',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type AmountDto = {
  currency: string;
  value: number;
  processingFee: number;
};

export type TransferDto = {
  uuid: string;
  loanId: string;
  amount: AmountDto;
  transferStatus: TransferStatus;
  createdAt: string;
};
