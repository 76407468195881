import React from 'react';

import { useAppDispatch } from '../../../../main/store/hooks';
import { Icon, Modal } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { intercomAPI } from '../../../../shared/services/intercom';
import { setShowIntercom } from '../../../dashboard/store/dashboardSlice';
import avatarHand from '../../assets/team-member-avatar-hand.svg';
import avatar from '../../assets/team-member-avatar.png';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModalServiceFee({ isOpen, setIsOpen }: TProps) {
  const dispatch = useAppDispatch();

  const clickSupport = () => {
    intercomAPI('show');
    dispatch(setShowIntercom(true));
  };

  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--md"
    >
      <div className="loans__modal">
        <button
          type="button"
          className="modal-content__close loans__modal__close"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="icon-cancel" classes="modal-content__close-icon" />
        </button>
        <h2 className="loans__modal__title">Service fee</h2>
        <div className="loans__modal__body">
          <div className="loans__modal__text">
            We charge a small fee to cover costs and provide the best service possible.
          </div>
          <div className="loans__modal__text">
            This one-time fee is paid to Zirtue by the Lender and repaid by the Borrower on the
            agreed upon repayment schedule.
          </div>
          <div className="loans__modal__supportBlock">
            <div className="loans__modal__supportBlock__left">
              <div className="loans__modal__supportBlock__avatar">
                <img src={avatar} alt="team-member-avatar" />
              </div>
              <div className="loans__modal__supportBlock__avatar__hand">
                <img src={avatarHand} alt="team-member-avatar-hand" />
              </div>
            </div>
            <div className="loans__modal__supportBlock__right">
              <div className="loans__modal__subtitle">Got a question?</div>
              <div className="loans__modal__text">
                Jump on a conversation with one of our
                {/* eslint-disable-next-line */}
                <span 
                  className="loans__modal__link"
                  onClick={() => {
                    setIsOpen(false);
                    clickSupport();
                  }}
                >
                  Support Team
                </span>
                members.
              </div>
            </div>
          </div>
        </div>
        <div className="loans__modal__footer">
          <Button
            type="button"
            className="button button--primary-blue button--lg button--block"
            onClick={() => setIsOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
}
