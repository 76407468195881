import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label } from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { AuthBannerContent } from '../components/AuthBanner';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { startLogin } from '../store/actions';

const emailValidation = yup
  .string()
  .email('Invalid email address or password. Please try again.')
  .typeError('Invalid email address or password. Please try again.')
  .label('Business Email')
  .required('Invalid email address or password. Please try again.');

const passwordValidation = yup
  .string()
  .typeError('Invalid email address or password. Please try again.')
  .label('Password')
  .required('Invalid email address or password. Please try again.');

const validationSchema = yup.object({
  email: emailValidation,
  password: passwordValidation,
});

const errorMessage = (messageCode: string) => {
  if (messageCode[0] === 'Password is short') {
    return { email: 'Invalid email address or password. Please try again.' };
  }

  switch (messageCode) {
    case 'Verification code does not match user password':
      return {
        email: 'Invalid email address or password. Please try again.',
      };

    case 'Partner not found':
      return {
        email: 'Invalid email address or password. Please try again.',
      };

    case 'EMAIL_NOT_FOUND':
      return {
        email: 'Invalid email address or password. Please try again.',
      };

    default:
      return {
        email: 'Invalid email address or password. Please try again.',
      };
  }
};

type FormValues = {
  email: string;
  password: string;
};

export default function SignIn() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loading, auth } = useAppSelector((state) => state.auth);

  const onSubmit = useCallback(
    async ({ email, password }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      const result = await dispatch(
        startLogin({
          emailUri: `mailto:${email}`,
          password,
        }),
      );
      if (startLogin.fulfilled.match(result)) {
        history.push('/email-verify');

        if (!window.location.host.includes('admin')) {
          Amplitude.logEvent(AmplitudeEvents.signedIn);
        }
      }

      if (startLogin.rejected.match(result)) {
        if (result.payload?.message) {
          setErrors(errorMessage(result.payload?.message));
        } else {
          dispatch(addAlert({ text: 'Unknown error', type: AlertType.error }));
        }
      }
    },
    [dispatch, history],
  );

  const { errors, handleSubmit, handleChange, handleBlur, values, touched, isValid, dirty } =
    useFormik<FormValues>({
      initialValues: {
        password: '',
        email: '',
      },
      onSubmit,
      validationSchema,
    });

  useEffect(() => {
    if (auth.mfaToken) {
      localStorage.setItem(StoreKeys.mfaToken, auth.mfaToken);
    }
  }, [auth, auth.mfaToken]);

  return (
    <AuthorizationLayout bannerContent={AuthBannerContent.SignIn}>
      <form action="#" className="auth-form auth-form--styled" onSubmit={handleSubmit}>
        <h1 className="auth-form__title">Sign in</h1>
        <div className="form-group">
          <Label forId="email">Email</Label>
          <Input
            type="email"
            id="email"
            data-testid="data-email"
            placeholder="Enter your email address"
            error={Boolean(errors.email) && touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-group">
          <Label forId="password">Password</Label>
          <Input
            type="password"
            id="password"
            data-testid="data-password"
            placeholder="Enter your password"
            error={Boolean(errors.password) && touched.password && errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="auth-form__link-box auth-form__link-box--forgot-password">
          <Link to="/forgot-password" className="auth-form__link">
            Forgot password?
          </Link>
        </div>
        <button
          type="submit"
          data-testid="submit-button"
          className="button button--lg button--primary-blue button--block"
          disabled={loading || !dirty || !isValid}
        >
          Sign in
        </button>
      </form>
    </AuthorizationLayout>
  );
}
