import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch } from '../../../../main/store';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';

type FormValues = {
  sftp: {
    userName: string;
    password: string;
  };
};

export const createSFTPconfiguration = createAsyncThunk<
  FormValues,
  FormValues,
  { rejectValue: string; dispatch: AppDispatch }
>('partners/createSFTPconfiguration', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.put(`/partners/sftp-settings/account-validation-files`, payload);
    dispatch(addAlert({ text: 'Credentials saved' }));
    return payload;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data.message);
  }
});
