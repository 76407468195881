import React from 'react';

import { Typography } from '../../../init-setup/components';

type SignUpWrapperProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export default function SignUpWrapper({ title, description, children }: SignUpWrapperProps) {
  return (
    <div className="new-sign-up__signUpWrapper">
      <div>
        <Typography
          tag="h6"
          size="title-3"
          weight="regular"
          colors="text-emphasis"
          textAlign="text-center"
        >
          {title}
        </Typography>
        {description && (
          <Typography tag="p" size="body" colors="text-secondary" textAlign="text-center">
            {description}
          </Typography>
        )}
      </div>
      <div className="new-sign-up__steps">{children}</div>
    </div>
  );
}
