import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import { intercomAPI } from '../../../../shared/services/intercom';
import { setShowIntercom } from '../../../dashboard/store/dashboardSlice';
import { LoanStatus } from '../../dtos/LoanTypes';
import ModalLoanCoverage from '../ModalLoanCoverage/ModalLoanCoverage';

interface LoanStatusCardProps {
  status: LoanStatus | string | undefined;
  showMissedPayment?: boolean;
  showFailedPayment?: boolean;
  borrowerName?: string;
  nextRepaymentDate?: string;
  hideCard: () => void;
}

interface StatusData {
  title: string;
  text: string;
  icon: string;
  buttonName: string;
  onClick: () => void;
}

export default function LoanStatusCard({
  status = LoanStatus.forgiven,
  showMissedPayment,
  showFailedPayment = false,
  borrowerName,
  nextRepaymentDate,
  hideCard,
}: LoanStatusCardProps) {
  const dispatch = useAppDispatch();
  const { showIntercom } = useAppSelector((state) => state.dashboard);
  const { loanDetails } = useAppSelector((state) => state.loans);

  const deactivated = !!loanDetails?.deactivatedAt;

  const [isModalLoanCoverageOpen, setIsModalLoanCoverageOpen] = useState(false);

  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };

  const statusData: {
    [key: string]: StatusData;
  } = {
    deactivated: {
      title: 'Loan deactivated',
      text: 'Looks like this loan was deactivated. Please contact support for more information.',
      icon: 'megaphone_red_dot',
      buttonName: 'Support',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: clickSupport,
    },
    [LoanStatus.paidOff]: {
      title: 'Your loan is covered',
      text: 'We teamed up with TruStage™ to provide Payment Guard Insurance at no additional cost to you.',
      icon: 'trustage',
      buttonName: 'Learn more',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => setIsModalLoanCoverageOpen(true),
    },
    late: {
      title: 'Late payment!',
      text: 'We’ll send Tom a reminder with the amount owned to pay you back instantly.',
      icon: 'loan-support',
      buttonName: 'Get help',
      onClick: clickSupport,
    },
    [LoanStatus.active]: {
      title: 'Loan on track!',
      text: `${borrowerName} is currently on schedule, show them some love! Next repayment is on ${nextRepaymentDate}.`,
      icon: 'party-popper-button',
      buttonName: 'Got it',
      onClick: hideCard,
    },
    failed: {
      title: 'Connect with support',
      text: 'Verify your payment method and connect with our support team if there are no issues on your end.',
      icon: 'connect-loan-support',
      buttonName: 'Support',
      onClick: clickSupport,
    },
    missed: {
      title: 'Do you have a question?',
      text: 'You can always contact our friendly team for support, we’re happy to help you out.',
      icon: 'loan-question-support',
      buttonName: 'Get help',
      onClick: clickSupport,
    },
    [LoanStatus.forgiven]: {
      title: 'Loan forgiven!',
      text: `Your support has greatly impacted ${borrowerName}’s journey towards financial freedom.`,
      icon: 'party-popper-button',
      buttonName: 'Got it',
      onClick: hideCard,
    },
  };

  const isErrorState = !!status === showFailedPayment || deactivated;

  let card: StatusData = {
    title: '',
    text: '',
    icon: '',
    buttonName: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
  };
  if (deactivated) {
    card = statusData.deactivated;
  } else if (showMissedPayment) {
    card = statusData.missed;
  } else if (showFailedPayment) {
    card = statusData.failed;
  } else {
    card = statusData[status] || statusData[LoanStatus.active];
  }
  const { title, text, icon, buttonName, onClick } = card;

  return (
    <>
      <div className={`loan__overview__card ${isErrorState && 'loan__overview__card--issue'}`}>
        {/* <span className="loan__overview__card-tab loan__overview__card-tab--active" />
      <span className="loan__overview__card-tab" /> */}
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {isErrorState && <Icon name="status-error-2" />}
          <div
            className={`loan__overview__status-title ${
              isErrorState && 'loan__overview__status-title--issue'
            }`}
          >
            {title}
          </div>
        </div>

        <div>
          <p className="loan__overview__status-text">{text}</p>
        </div>
        <Icon name={icon} />
        <button
          type="button"
          onClick={onClick}
          className={`button button--primary-blue button--lg button--block ${
            isErrorState && 'loan__overview__status-issue-button'
          }`}
        >
          {buttonName}
        </button>
      </div>
      <ModalLoanCoverage isOpen={isModalLoanCoverageOpen} setIsOpen={setIsModalLoanCoverageOpen} />
    </>
  );
}
