import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';
import { AddAchNodeDto, Address } from '../../../../shared/models/AddAchNodeDto';
import { parseError } from '../../../../shared/utils/helpers';
import { SelectedPlace } from '../../modals/ModalAddPaymentMethod';

import type { AppDispatch, RootState } from '../../../../main/store';

export const addAchNode = createAsyncThunk<
  undefined,
  { achNode: AddAchNodeDto },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('accounts/addAchNode', async ({ achNode }, { rejectWithValue }) => {
  try {
    const encodedURI = encodeURIComponent(`${defaultRealm}`);

    await api.put(`/realms/${encodedURI}/accounts`, achNode);
    return undefined;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});

type TNullablePartial<T> = { [P in keyof T]?: T[P] | null };

export function addressFromGooglePlace(place: SelectedPlace): Address | null {
  const address: TNullablePartial<Address> = {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  };

  let streetNumber: string | null = null;
  let street: string | null = null;
  let cityInSublocality = null;

  if (
    typeof place === 'object' &&
    typeof place.address_components === 'object' &&
    Array.isArray(place.address_components)
  ) {
    place.address_components.forEach((c) => {
      if (typeof c === 'object' && typeof c.types === 'object' && Array.isArray(c.types)) {
        c.types.forEach((t) => {
          switch (t) {
            case 'postal_code':
              if (address.zip === null) {
                if (typeof c.long_name !== 'undefined') {
                  address.zip = c.long_name;
                } else if (typeof c.short_name !== 'undefined') {
                  address.zip = c.short_name;
                }
              }
              break;
            case 'country':
              if (address.country === null) {
                address.country = c.short_name;
              }
              break;
            case 'administrative_area_level_1':
              if (address.state === null) {
                if (typeof c.long_name !== 'undefined') {
                  address.state = c.long_name;
                } else if (typeof c.short_name !== 'undefined') {
                  address.state = c.short_name;
                }
              }
              break;
            case 'locality':
              if (address.city === null) {
                if (typeof c.long_name !== 'undefined') {
                  address.city = c.long_name;
                } else if (typeof c.short_name !== 'undefined') {
                  address.city = c.short_name;
                }
              }
              break;
            case 'street_number':
              if (streetNumber === null) {
                if (typeof c.long_name !== 'undefined') {
                  streetNumber = c.long_name;
                } else if (typeof c.short_name !== 'undefined') {
                  streetNumber = c.short_name;
                }
              }
              break;
            case 'route':
              if (street === null) {
                if (typeof c.short_name !== 'undefined') {
                  street = c.short_name;
                } else if (typeof c.long_name !== 'undefined') {
                  street = c.long_name;
                }
              }
              break;
            case 'subpremise':
              if (typeof c.short_name !== 'undefined') {
                address.line2 = c.short_name;
              } else if (typeof c.long_name !== 'undefined') {
                address.line2 = c.long_name;
              }

              break;
            case 'sublocality':
              if (typeof c.short_name !== 'undefined') {
                cityInSublocality = c.short_name;
              } else if (typeof c.long_name !== 'undefined') {
                cityInSublocality = c.long_name;
              }

              break;
            default:
              break;
          }
        });
      }
    });

    if (streetNumber === null) {
      address.line1 = street;
    } else {
      address.line1 = street ? `${streetNumber} ${street}` : streetNumber;
    }

    return {
      line1: address.line1 ?? '',
      line2: address.line2 ?? undefined,
      city: address.city ?? cityInSublocality ?? '',
      state: address.state ?? '',
      zip: address.zip ?? '',
      country: address.country ?? '',
    };
  }

  return null;
}
