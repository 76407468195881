export enum LoanControlActionType {
  BEGIN_ACCEPTANCE = 'BEGIN_ACCEPTANCE',
  SUCCEED_ACCEPTANCE = 'SUCCEED_ACCEPTANCE',
  FAIL_ACCEPTANCE = 'FAIL_ACCEPTANCE',
  BEGIN_PAYMENT = 'BEGIN_PAYMENT',
  SUCCEED_PAYMENT = 'SUCCEED_PAYMENT',
  FAIL_PAYMENT = 'FAIL_PAYMENT',
  CANCEL_LOAN = 'CANCEL_LOAN',
  REJECT_LOAN = 'REJECT_LOAN',
  FORGIVE_LOAN = 'FORGIVE_LOAN',
  DEACTIVATE_LOAN = 'DEACTIVATE_LOAN',
  EDIT_MESSAGE = 'EDIT_MESSAGE',
  SET_DISBURSEMENT_SOURCE = 'SET_DISBURSEMENT_SOURCE',
  SET_DISBURSEMENT_TARGET = 'SET_DISBURSEMENT_TARGET',
  SET_REPAYMENT_SOURCE = 'SET_REPAYMENT_SOURCE',
  SET_REPAYMENT_TARGET = 'SET_REPAYMENT_TARGET',
}

export enum LoanControlActionTypeRetryRepayment {
  BEGIN_ACCEPTANCE = 'BEGIN_ACCEPTANCE',
  SUCCEED_ACCEPTANCE = 'SUCCEED_ACCEPTANCE',
  FAIL_ACCEPTANCE = 'FAIL_ACCEPTANCE',
  BEGIN_PAYMENT = 'BEGIN_PAYMENT',
  SUCCEED_PAYMENT = 'SUCCEED_PAYMENT',
  FAIL_PAYMENT = 'FAIL_PAYMENT',
  CANCEL_LOAN = 'CANCEL_LOAN',
  REJECT_LOAN = 'REJECT_LOAN',
  FORGIVE_LOAN = 'FORGIVE_LOAN',
  DEACTIVATE_LOAN = 'DEACTIVATE_LOAN',
  EDIT_MESSAGE = 'EDIT_MESSAGE',
  SET_DISBURSEMENT_SOURCE = 'SET_DISBURSEMENT_SOURCE',
  SET_DISBURSEMENT_TARGET = 'SET_DISBURSEMENT_TARGET',
  SET_REPAYMENT_SOURCE = 'SET_REPAYMENT_SOURCE',
  SET_REPAYMENT_TARGET = 'SET_REPAYMENT_TARGET',
  RETRY_REPAYMENT = 'RETRY_REPAYMENT',
}
