import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { PartnerAccountModel } from '../../../../shared/models/PartnerAccountModel';
import { PartnerCreateAccountModel } from '../../../../shared/models/PartnerCreateAccountModel';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const createAccount = createAsyncThunk<
  PartnerCreateAccountModel,
  PartnerAccountModel,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('auth/createAccount', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<PartnerCreateAccountModel>(
      '/partners/complete-registration',
      payload,
    );

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
