import { format } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import numeral from 'numeral';
import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import {
  DateRangeFilter,
  DateRangeTuple,
  Icon,
  Label,
  Search,
  Select,
  useLazyEffect,
} from '../../../shared';
import Button from '../../../shared/components/Button';
import { LoanStatus } from '../../../shared/models';
import { getContact, getTimePeriod, getDaysDifference } from '../../../shared/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { MemberNameFL, TableAccountNumber, TableStatus } from '../../payments/components';
import EmptyState from '../components/Loans/EmptyState';
import LoansTableHead from '../components/Loans/LoansTableHead';
import { LoanStage, LoanStageKind } from '../dtos/LoanTypes';
import { getLoanStatus, getLoanStatusFilter } from '../helpers/getLoanStatus';
import {
  setTableLimit,
  setTableLoanStatus,
  setTableLoanStatusConverted,
  setTableNbrPage,
  setTableReason,
  setTableSearchText,
  setTableSkip,
  setTableSortField,
  setTableSortOrder,
} from '../store';
import { getLoans } from '../store/actions/getLoans';

const loanReasons = [
  { name: 'Any', dataId: 'any' },
  { name: 'Auto', dataId: 'Auto' },
  { name: 'Business', dataId: 'Business' },
  { name: 'Credit Card', dataId: 'Credit Card' },
  { name: 'Education', dataId: 'Education' },
  { name: 'Emergency', dataId: 'Emergency' },
  { name: 'Entertainment', dataId: 'Entertainment' },
  { name: 'Food', dataId: 'Food' },
  { name: 'Insurance', dataId: 'Insurance' },
  { name: 'Medical', dataId: 'Medical' },
  { name: 'Travel', dataId: 'Travel' },
  { name: 'Rent', dataId: 'Rent' },
  { name: 'Utilities', dataId: 'Utilities' },
  // { name: 'Other', dataId: 'Other' },
];

export default function LoansPage() {
  const dispatch = useAppDispatch();
  const { partner } = useAppSelector((state) => state.auth);
  const {
    loans,
    loansLoading,
    loansTableSettings: {
      searchText,
      sortBy,
      sortOrder,
      countRow,
      loanStatus,
      loanStatusConverted,
      loanReason,
      // periodTime,
      skip,
      nbrPage,
    },
  } = useAppSelector((state) => state.loans);
  const history = useHistory();

  const [periodTime, setPeriodTime] = useState<string[]>(getTimePeriod(365));
  const isHasFilters =
    !!searchText ||
    (!!loanStatus && loanStatus !== 'any') ||
    (!!loanReason && loanReason !== 'any') ||
    getDaysDifference(periodTime[1], periodTime[0]) !== 365;

  useEffect(() => {
    dispatch(
      getLoans({
        searchText,
        stage: loanStatusConverted,
        reason: loanReason,
        limit: +countRow,
        skip,
        periodTime,
        sortField: sortBy,
        sortOrder,
      }),
    );
  }, []);

  const getPayments = () => {
    dispatch(
      getLoans({
        searchText,
        stage: loanStatusConverted === 'any' ? '' : loanStatusConverted,
        limit: +countRow,
        skip,
        periodTime,
        sortField: sortBy,
        sortOrder,
        reason: loanReason === 'any' ? '' : loanReason,
      }),
    );
  };

  const loanStatuses = useMemo(() => {
    const data = Object.values(LoanStatus).map((status) => ({
      dataId: status,
      name: getLoanStatus(status),
    }));
    return [{ name: 'Any', dataId: 'any' }, ...data];
  }, []);

  // function to get last 5 digits from uuid
  const getLastFiveDigits = (uuid: string) => uuid.slice(-5);

  useLazyEffect(
    getPayments,
    [
      dispatch,
      countRow,
      skip,
      nbrPage,
      sortBy,
      sortOrder,
      searchText,
      loanStatusConverted,
      loanReason,
      periodTime,
    ],
    1000,
  );

  const totalPages = Math.ceil(loans.total / +countRow) || 1;

  const changePage = (nbr: number) => {
    let nextNbr: number = nbr;
    if (Number.isNaN(nbr)) nextNbr = 1;
    if (nbr <= 1) nextNbr = 1;
    if (nbr >= totalPages) nextNbr = totalPages;

    dispatch(setTableNbrPage(nextNbr));
    dispatch(setTableSkip((nextNbr - 1) * +countRow));
  };

  const changeCountRow = (cnt: number) => {
    changePage(1);
    dispatch(setTableLimit(`${cnt}`));
  };

  const handleDateFilter = (value: DateRangeTuple) => {
    changePage(1);
    // dispatch(setTablePeriodTime(value));
    setPeriodTime(value);
  };

  const handleClickTableRow = (userId: string) => {
    history.push(`/loan-details/${userId}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatPhoneNumber = (stage: LoanStage, targetContactUri: string, borrower: any): string => {
    try {
      if (stage.kind === LoanStageKind.binding) {
        return parsePhoneNumber(getContact(targetContactUri))?.formatInternational() || '';
      }
      if (borrower?.redactedPhoneNum?.length > 5) {
        return parsePhoneNumber(borrower?.redactedPhoneNum)?.formatInternational() || '';
      }
    } catch (error) {
      return borrower?.redactedPhoneNum;
    }
    return '';
  };

  return (
    <DashboardLayout>
      {partner && (
        <>
          <div className="loans-overview__title-wrapper">
            <h2 className="main-title">Loans</h2>
            <Button
              className="button button--primary-blue button--lg loans-overview__create-button"
              icon="plus"
              onClick={() => {
                history.push('/loan-creation');
              }}
            >
              Create loan
            </Button>
          </div>
          <div style={{ marginBottom: 24 }} className="card">
            <div className="table-filter table-filter--no-wrap">
              <div
                style={{ minWidth: 260 }}
                className="table-filter__col table-filter__col--search table-filter__col--loans"
              >
                <Search
                  id="filter-search"
                  classes="table-filter__search"
                  placeholder="Search for a borrower, loan ID, email..."
                  value={searchText}
                  onChange={(value: string) => {
                    dispatch(setTableSearchText(value));
                    changePage(1);
                  }}
                />
              </div>
              <div className="table-filter__col table-filter__col--select table-filter__col--loans">
                <div className="form-group">
                  <Label forId="filter-payment">Loan Status</Label>
                  <Select
                    classes="table-filter__payment select--no-margin"
                    id="filter-payment"
                    status
                    data={loanStatuses}
                    placeholder="Enter Loan Status"
                    value={loanStatus}
                    totalItems={loans.total}
                    onChange={(val: string) => {
                      dispatch(setTableLoanStatus(val));
                      dispatch(setTableLoanStatusConverted(getLoanStatusFilter(val as LoanStatus)));
                      changePage(1);
                    }}
                  />
                </div>
              </div>
              <div className="table-filter__col table-filter__col--select table-filter__col--loans">
                <div className="form-group">
                  <Label forId="filter-payment">Reason for loan</Label>
                  <Select
                    classes="table-filter__payment select--no-margin"
                    id="reason-payment"
                    status
                    data={loanReasons}
                    placeholder="Enter Reason for loan"
                    value={loanReason}
                    totalItems={loans.total}
                    onChange={(val: string) => {
                      dispatch(setTableReason(val));
                      changePage(1);
                    }}
                  />
                </div>
              </div>
              <div className="table-filter__col table-filter__col--loans table-filter__col--loans__dateRange">
                <DateRangeFilter
                  classes="table-filter__date-filter loans-filter"
                  onChangePeriod={(value) => handleDateFilter(value)}
                />
                {/* <DateRangeFilter
                  classes="table-filter__date-filter"
                  dateRangeFilter={[periodTime[0], periodTime[1]]}
                  onChangePeriod={(value) => handleDateFilter(value)}
                /> */}
              </div>
            </div>
            {/* Table */}
            <div className="table-scroll">
              <div style={{ position: 'relative' }} className="table-scroll__box">
                <table className="table">
                  <tbody className="table__body">
                    <LoansTableHead
                      setSortBy={(value) => {
                        dispatch(setTableSortField(value));
                      }}
                      setSortOrder={(value) => {
                        dispatch(setTableSortOrder(value));
                      }}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                    />
                    {!loansLoading &&
                      loans.loans.map(
                        ({
                          borrower,
                          uuid,
                          amount,
                          stage,
                          createdAt,
                          targetContactUri,
                          initialTargetName,
                        }) => (
                          <tr
                            className="table__data-row"
                            key={uuid}
                            onClick={() => handleClickTableRow(uuid)}
                          >
                            <td className="table__cell table__cell--data">
                              <MemberNameFL
                                name={
                                  stage.kind === LoanStageKind.binding
                                    ? initialTargetName
                                    : borrower?.redactedUsername
                                }
                                color="blue"
                              />
                            </td>
                            <td className="table__cell table__cell--data">
                              <TableAccountNumber
                                accountNumber={getLastFiveDigits(uuid)}
                                copyValue={uuid}
                              />
                            </td>
                            <td className="table__cell table__cell--data">
                              <span className="table-highlight">
                                <Icon name="dollar-circle-small" classes="table-highlight__icon" />
                                {numeral(amount?.value).format('0,000.00')}
                              </span>
                            </td>
                            <td className="table__cell table__cell--data">
                              {createdAt && format(new Date(createdAt), 'MM-dd-yyyy')}
                            </td>
                            <td className="table__cell table__cell--data">
                              {formatPhoneNumber(stage, targetContactUri, borrower)}
                            </td>
                            <td className="table__cell table__cell--data table__cell--email">
                              {borrower?.redactedEmail}
                            </td>
                            <td className="table__cell table__cell--data">
                              <TableStatus stage={stage} marker="loan" type={stage.kind} />
                            </td>
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
                {loansLoading && <div style={{ margin: 'auto' }} className="loader" />}
                {!loansLoading && !loans.loans.length && <EmptyState isEmpty={!isHasFilters} />}
              </div>
            </div>
            <div className="table-pagination">
              <div className="table-pagination__col">
                <Label forId="per-page" classes="table-pagination__per-page-label">
                  Rows per page
                </Label>
                <Select
                  classes="select--no-margin table-pagination__per-page-select"
                  id="per-page"
                  data={[
                    { dataId: '8', name: '8' },
                    { dataId: '16', name: '16' },
                    { dataId: '32', name: '32' },
                    { dataId: '64', name: '64' },
                  ]}
                  value={countRow}
                  onChange={changeCountRow}
                  additionalOffsetTop={100}
                />
              </div>
              <div className="table-pagination__col table-pagination__col--page-nav">
                <button
                  type="button"
                  className="table-pagination__button table-pagination__button--prev"
                  disabled={nbrPage <= 1}
                  onClick={() => changePage(nbrPage - 1)}
                >
                  <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
                </button>
                <div className="table-pagination__center">
                  <input
                    type="text"
                    className="input table-pagination__input"
                    value={nbrPage}
                    onChange={(e) => changePage(+e.target.value)}
                  />
                  <span className="table-pagination__text">{`of ${totalPages}`}</span>
                </div>
                <button
                  type="button"
                  className="table-pagination__button table-pagination__button--next"
                  onClick={() => changePage(nbrPage + 1)}
                  disabled={nbrPage >= totalPages}
                >
                  <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}
