import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../../../main/network';
import { AppDispatch } from '../../../../main/store';
import { LoanDirectControlRequestDto } from '../../../../shared/models/platform-admin';
import { parseError } from '../../../../shared/utils/helpers';

import { adminLoansSearch } from './adminLoansSearch';

export const adminLoanDirectControl = createAsyncThunk<
  undefined,
  LoanDirectControlRequestDto & { id: string },
  { dispatch: AppDispatch; rejectValue: string }
>('platformAdmin/loanDirectControl', async ({ id, ...data }, { dispatch, rejectWithValue }) => {
  try {
    await api.post(`/platform-admin/loans/${id}/control`, data);
    // refresh the current search on success
    dispatch(adminLoansSearch());
    return undefined;
  } catch (error) {
    return rejectWithValue(parseError(error as Error));
  }
});
