import React from 'react';

import Typography from '../../UI/Typography';
import { NewsStoryType } from '../constants/newsStories';

const News = ({ description, link, preview, title }: NewsStoryType) => (
  <div className="news-stories__container__news">
    <div className="news-stories__container__news__preview">
      <img src={preview} alt={title} />
    </div>
    <div className="news-stories__container__news__content">
      <Typography tag="h4" weight="bold" className="news-stories__container__news__title">
        {title}
      </Typography>
      <Typography
        tag="p"
        colors="text-secondary"
        className="news-stories__container__news__description"
      >
        {description}
      </Typography>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="news-stories__container__news__read-more-link"
      >
        read more
      </a>
    </div>
  </div>
);

export default News;
