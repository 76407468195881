import React from 'react';

import { Typography } from '../../init-setup/components';

const DeleteAccount = () => (
  <div className="delete-account">
    <Typography tag="h2" weight="bold" className="account__title">
      Delete account
    </Typography>
    <Typography tag="p" className="account__text">
      To delete your Zirtue account, send an email to getintouch@zitue.com requesting removal.
    </Typography>
  </div>
);

export default DeleteAccount;
