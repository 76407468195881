import React from 'react';

import CompanyDetailsInfoEdit from './CompanyDetailsInfoEdit';
import CompanyGeneralInfoEdit from './CompanyGeneralInfoEdit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CompanyProfileEdit: React.FC<{ partner: any }> = ({ partner }: any) => (
  <div className="row profile-edit">
    <CompanyGeneralInfoEdit partner={partner} />
    <CompanyDetailsInfoEdit partner={partner} />
  </div>
);

export default CompanyProfileEdit;
