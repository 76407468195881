import { createAsyncThunk } from '@reduxjs/toolkit';

import { INTERCOM_URL } from '../../../../main/env';
import { api } from '../../../../main/network';
import { getContact } from '../../../../shared/utils';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getIntercomInfo = createAsyncThunk<
  // eslint-disable-next-line camelcase
  { name: string; user_id?: string; email: string; user_hash: string },
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('auth/getIntercomInfo', async (payload, { rejectWithValue, getState }) => {
  try {
    const { user } = getState().auth;
    const { data } = await api.post<string>(INTERCOM_URL(), { platform: 'web' });

    return {
      user_id: user?.uuid,
      email: getContact(user?.contacts[0]),
      name: `${user?.firstName || 'Partner'} ${user?.lastName || 'Member'}`,
      user_hash: data,
    };
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
