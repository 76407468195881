import classNames from 'classnames';
import numeral from 'numeral';
import React, { useState } from 'react';

import { setRefundEventIndex } from '../../features/payments/store';
import { RootState } from '../../main/store';
import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { RefundHistoryEntryDto } from '../models';
import { Amplitude, AmplitudeEvents } from '../services/amplitude';
import { formatToCDT } from '../utils';

const getStatus = (item: RefundHistoryEntryDto) => {
  if (item.outcome && item.outcome.failure) {
    return <span className="timeline__status timeline__status--failed">Refund Failed</span>;
  }

  if (item.outcome) {
    return <span className=" timeline__status timeline__status--completed">Refund Submitted</span>;
  }

  return <span className="timeline__status">Refund Pending</span>;
};

const Timeline = () => {
  const [checkedId, setCheckedId] = useState(0);
  const { selectedBillPayment } = useAppSelector((state: RootState) => state.payments);
  const dispatch = useAppDispatch();

  const handleClick = (index: number, refund: RefundHistoryEntryDto) => {
    setCheckedId(index);
    dispatch(setRefundEventIndex(index));
    Amplitude.logEvent(AmplitudeEvents.submittedRefundClicked, {
      paymentAmount: refund.amount.value,
      accountNumber: selectedBillPayment?.origin.accountNumber,
      refundStatus: getStatus(refund).props.children,
    });
  };

  return (
    <div className="timeline">
      <div className="timeline__box">
        {selectedBillPayment &&
          selectedBillPayment.refundHistory.map((i, index) => (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
            <div
              className={classNames('timeline__item', {
                'timeline__item--last': index === selectedBillPayment.refundHistory.length - 1,
                'timeline__item--first': index === 0,
                'timeline__item--checked': index === checkedId,
              })}
              key={`${i.refundId}${i.timestamp}`}
              onClick={() => handleClick(index, i)}
              role="button"
            >
              <div className="timeline__date">{formatToCDT(i.timestamp)}</div>
              <div className="timeline__bubble" />
              <div className="timeline__info">
                <span className="timeline__amount">
                  ${numeral(i.amount.value).format('0,000.00')}
                </span>
                {getStatus(i)}
              </div>
            </div>
          ))}

        {!selectedBillPayment?.refundHistory.length && <div>Refund history not found</div>}
      </div>
    </div>
  );
};
export default Timeline;
