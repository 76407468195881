import React, { FC, useCallback } from 'react';

import { useAppDispatch } from '../../../../main/store/hooks';
import { Pagination } from '../../../../shared';
import { AdminTableName, setTablePage, setTablePageSize } from '../../store';

interface TablePaginationProps {
  page: number;
  pageSize: number;
  pagesCount: number;
  total?: number;
  tableName: AdminTableName;
  controls?: React.ReactNode[];
}

export const TablePagination: FC<TablePaginationProps> = ({
  tableName,
  page,
  pageSize,
  pagesCount,
  total,
  controls,
}) => {
  const dispatch = useAppDispatch();
  const setPage = useCallback(
    (page: number) => {
      dispatch(setTablePage({ page, tableName }));
    },
    [dispatch, tableName],
  );
  const setPageSize = useCallback(
    (size: number) => {
      dispatch(setTablePageSize({ size, tableName }));
    },
    [dispatch, tableName],
  );

  return (
    <Pagination
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      pagesCount={pagesCount}
      total={total}
      controls={controls}
    />
  );
};
