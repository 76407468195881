import { createAsyncThunk } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { LoansSearchResponseDto } from '../../../../shared/models/platform-admin';
import { selectUsersState } from '../selectors/users';

export const adminLoan = createAsyncThunk<
  LoansSearchResponseDto,
  void,
  { dispatch: AppDispatch; state: RootState }
>('platformAdmin/adminLoan', async (_, { getState }) => {
  const { selectedItemId } = selectUsersState(getState());
  const { selectedItemLoan } = getState().platformAdmin;

  const { page, pageSize, sortField, sortOrder } = selectedItemLoan;

  const { data } = await api.get<LoansSearchResponseDto>('/platform-admin/loans/search', {
    params: <{ searchText: string }>{
      searchText: selectedItemId,
      dateStart: addDays(new Date(), -365),
      dateEnd: new Date(),
      limit: pageSize,
      skip: (page - 1) * pageSize,
      sortField,
      sortOrder,
    },
  });

  return data;
});
