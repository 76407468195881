import { useDispatch } from 'react-redux';

import { Icon } from '../../../shared';
import { addAlert, AlertType } from '../../../shared/store/modals';

const CopyButton = ({ value, hasText }: { value: string; hasText?: boolean }) => {
  const dispatch = useDispatch();

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);

      dispatch(
        addAlert({
          text: 'Copied to clipboard',
          type: AlertType.success,
        }),
      );
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Could not copy to clipboard',
          type: AlertType.error,
        }),
      );
    }
  };

  return (
    <button className="copy-button" type="button" onClick={() => copyTextToClipboard(value)}>
      <Icon name="copy" classes="copy-button__icon" />
      {hasText && <span className="copy-button__text">Copy to clipboard</span>}
    </button>
  );
};

export default CopyButton;
