import React, { MouseEventHandler } from 'react';

import { Icon } from '../../../shared';

type Props = {
  value: string;
  onClose: MouseEventHandler;
  classes?: string;
};

export default function Tag({ value, onClose, classes }: Props) {
  return (
    <li className={`tag ${classes || ''}`}>
      <span className="tag__value">{value}</span>
      <button type="button" className="tag__close" onClick={onClose}>
        <Icon name="tag-close" classes="tag__close-icon" />
      </button>
    </li>
  );
}
