import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import {
  LoanDetails,
  LoanDetailsModel,
} from '../../../../shared/models/platform-admin/loans/LoanModel';
import { parseError } from '../../../../shared/utils/helpers';
import { LoanDetailsDto } from '../../dtos/LoanDetailsDto';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getLoanDetails = createAsyncThunk<
  LoanDetails,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('getLoanDetails', async (loanId, { rejectWithValue }) => {
  try {
    const { data } = await api.get<LoanDetailsDto>(`/loans/${loanId}`);
    return LoanDetailsModel.fromDTO(data);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
