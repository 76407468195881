import React from 'react';

import { Icon } from '..';

const Spinner = () => (
  <div className="spinner">
    <div className="spinner__icon">
      <Icon name="spinner" />
    </div>
  </div>
);

export default Spinner;
