import { useDispatch } from 'react-redux';

import Button from '../../../shared/components/Button';
import { AlertType, addAlert } from '../../../shared/store/modals';

type TProps = {
  code: string;
};

const RequestCode = ({ code }: TProps) => {
  const valueToCopy = code;
  const dispatch = useDispatch();

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);

      dispatch(
        addAlert({
          text: 'Copied to clipboard',
          type: AlertType.success,
        }),
      );
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Could not copy to clipboard',
          type: AlertType.error,
        }),
      );
    }
  };

  const lines = valueToCopy?.trim().split('\n');

  const shouldTruncate = lines.length > 6;

  const displayLines = shouldTruncate
    ? [...lines.slice(0, 5), '....', lines[lines.length - 1]]
    : lines;

  return (
    <div className="requestCode">
      <div className="requestCode__codeContainer">
        <pre className="requestCode__codeBlock">{displayLines?.join('\n')}</pre>
      </div>
      <Button
        className="button button--primary-blue button--sm"
        icon="copy-embed"
        onClick={() => copyTextToClipboard(valueToCopy)}
      >
        Copy code
      </Button>
    </div>
  );
};

export default RequestCode;
