import React from 'react';

import { ReactComponent as DividerSvg } from '../../../../assets/images/divider.svg';
import { Icon } from '../../../../shared';
import { FundingSource, FundingSourceRail } from '../../../../shared/models/FundingSource';
import { formatExpirationDate } from '../../../../shared/utils';

import { RemovePaymentMethodPopover } from './RemovePaymentMethodPopover';

type Props = {
  userId: string;
  paymentMethods: FundingSource[];
};

type PaymentDisplayInfo = {
  icon: string;
  name: string;
};

const getDisplayInfoFundingSource = (paymentMethod: FundingSource): PaymentDisplayInfo => {
  const { institution, rail } = paymentMethod;
  if (institution === 'VISA') return { icon: 'visa-logo', name: 'Visa' };
  if (institution === 'MAST') return { icon: 'master-card-logo', name: 'MasterCard' };
  if (rail === FundingSourceRail.CARDS) return { icon: 'credit-card', name: 'Card' };
  return { icon: 'bank', name: 'Bank account' };
};

const getPaymentMethodRow = (userId: string, paymentMethod: FundingSource) => {
  const { redactedAccountNumber, expiration, rail } = paymentMethod;
  const { icon, name } = getDisplayInfoFundingSource(paymentMethod);
  return (
    <tr className="payment-method-row" key={paymentMethod.uuid}>
      <td>
        <div className="payment-method-row__icon-box">
          <Icon name={icon} />
        </div>
        {`${name} ending in ${redactedAccountNumber}`}
        {rail === FundingSourceRail.CARDS && (
          <>
            <DividerSvg className="payment-method-row__divider" />
            {`Expires on ${formatExpirationDate(expiration)}`}
          </>
        )}
      </td>
      <td>
        <RemovePaymentMethodPopover userId={userId} paymentMethod={paymentMethod}>
          <button type="button" className="table-dots">
            <Icon name="dots" classes="table-dots__icon" />
          </button>
        </RemovePaymentMethodPopover>
      </td>
    </tr>
  );
};

export const PaymentMethodWidget = ({ userId, paymentMethods = [] }: Props) => (
  <div className="card">
    <h2 className="card__title">Payment Methods</h2>
    {!paymentMethods.length ? (
      'User has not added any payment methods'
    ) : (
      <table style={{ width: '100%' }}>
        <tbody>
          {paymentMethods.map((paymentMethod) => getPaymentMethodRow(userId, paymentMethod))}
        </tbody>
      </table>
    )}
  </div>
);
