import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { FundingSourceRail } from '../../../../shared/models/FundingSource';
import { parseError } from '../../../../shared/utils/helpers';

export const deleteUserPaymentMethod = createAsyncThunk<
  undefined,
  { userId: string; accountId: string; rail: string },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/deleteUserPaymentMethod', async (payload, { rejectWithValue }) => {
  try {
    const { userId, accountId, rail } = payload;
    const realm = rail === FundingSourceRail.CARDS ? 'fiserv' : 'checkbook';
    await api.delete(`/realms/${encodeURIComponent(realm)}/users/${userId}/accounts/${accountId}`);
    return undefined;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
