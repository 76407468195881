import classNames from 'classnames';
import { noop } from 'lodash';
import React, { PropsWithChildren } from 'react';

import { DataTableColumnConfig } from './DataTableColumnConfig';
import { DataTableCell } from './DataTableRowCell';

interface DataTableRowProps<TRowType> {
  columns: DataTableColumnConfig<TRowType>[];
  row: TRowType;
  onClick?: (row: TRowType) => unknown;
  selected?: boolean;
}

export function DataTableRow<TRowType>({
  columns,
  row,
  onClick = noop,
  selected,
}: PropsWithChildren<DataTableRowProps<TRowType>>) {
  const rowClassName = classNames('table__data-row', {
    'table__data-row--selected': selected,
  });
  return (
    <tr className={rowClassName} onClick={() => onClick(row)}>
      {columns.map((column) => (
        <DataTableCell column={column} row={row} key={`${column.id}`} />
      ))}
    </tr>
  );
}
