import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label } from '../../../shared';
import DevToolsLayout from '../layout/DevToolsLayout';
import { setSftpAccountCreationError } from '../store';
import { createSFTPconfiguration } from '../store/actions/createSFTPconfiguration';

export type FormValues = {
  userName: string;
  password: string;
  passwordConfirmation: string;
};

const inviteValidationSchema = yup.object({
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z0-9_.-]*[a-zA-Z0-9]$/,
      'Username must contain only alphanumeric characters and the characters _, -, . and the last character must be a letter or a number',
    )
    .max(10)
    .required('Configuration userName is required'),
  password: yup
    .string()
    .min(7, 'Password is not strong - must be at least 7 characters')
    .max(15)
    .label('Password')
    .required(),
  passwordConfirmation: yup
    .string()
    .min(7, 'Password is not strong - must be at least 7 characters')
    .max(15)
    .label('Password confirmation')
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required(),
});

const ValidationAccountCreationPage = ({ onBack }: { onBack: () => void }) => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { accountCreationError } = useAppSelector((state: RootState) => state.devTools);

  const onSubmit = ({ userName, password }: FormValues) => {
    dispatch(createSFTPconfiguration({ sftp: { userName, password } })).then((response) => {
      if (createSFTPconfiguration.fulfilled.match(response)) {
        push('/developer-tools/sftp/configuration');
      }
    });
  };

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
    isValid,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      userName: '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit,
    validationSchema: inviteValidationSchema,
  });

  useEffect(() => {
    if (accountCreationError) {
      setFieldError('userName', accountCreationError);
      dispatch(setSftpAccountCreationError(''));
    }
  }, [accountCreationError]);

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item" onClick={onBack}>
          <img src="/images/dashboard/arrow-tail-blue.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Account Validation</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Account Creation</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">Account Creation</h2>
      <div className="validation-account card">
        <h3 className="validation-account__title">Setup your account credentials</h3>
        <h4 className="validation-account__subtitle">
          A username and password will be required to setup and access files. Please register below:
        </h4>
        <form className="validation-account__form" onSubmit={handleSubmit} autoComplete="off">
          <div className="form-group">
            <Label forId="name">Username</Label>
            <Input
              type="text"
              id="userName"
              autoComplete={false}
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.userName && errors.userName}
            />
          </div>

          <div className="validation-account__password-wrapper">
            <div className="form-group">
              <Label forId="password">Password</Label>
              <Input
                type="password"
                id="password"
                autoComplete={false}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
              />
            </div>

            <div className="form-group">
              <Label forId="passwordConfirmation">Confirm password</Label>
              <Input
                type="password"
                id="passwordConfirmation"
                autoComplete={false}
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.passwordConfirmation && errors.passwordConfirmation}
              />
            </div>
          </div>

          <div className="line" />
          <div className="dev-tools__control">
            <button
              style={{ marginRight: '16px' }}
              type="button"
              className="button button--outline button--outline--no-border button--lg"
              onClick={onBack}
            >
              Back
            </button>

            <button
              style={{ width: '124px' }}
              type="submit"
              className="button button--primary-blue button--lg"
              disabled={!dirty || !isValid}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </DevToolsLayout>
  );
};

export default ValidationAccountCreationPage;
