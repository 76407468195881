import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import ModalConfirm from '../../../shared/components/ModalConfirm';
import Permissions from '../../../shared/constants/Permissions';
import { getPartner } from '../../auth/store/actions';
import { hasRole, isCompanyAdmin } from '../../auth/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { CompanyProfileEdit } from '../components';
import {
  ModalInviteMember,
  ModalInvitationSent,
  ModalDeactivateMember,
  ModalRole,
} from '../modals';

import { CompanyProfileProps } from './CompanyProfile';

const CompanyProfilePage = ({ location }: CompanyProfileProps) => {
  const {
    partner,
    isAdmin,
    authToken: authInfo,
    user,
    roles,
  } = useAppSelector((state) => ({ ...state.auth, ...state.company }), shallowEqual);

  const dispatch = useAppDispatch();
  const isUserCompanyAdmin = user?.uuid ? isCompanyAdmin(user?.uuid, roles) : false;

  const canSeeCompany =
    authInfo &&
    (hasRole(authInfo, Permissions.MEMBERS_MANAGE) ||
      hasRole(authInfo, Permissions.MEMBERS_INVITE) ||
      hasRole(authInfo, Permissions.FINANCES_MANAGE) ||
      isAdmin);

  useEffect(() => {
    dispatch(getPartner());
  }, []);

  return (
    <DashboardLayout pageTitle="Company Profile">
      <div className="profile">
        {canSeeCompany &&
          (partner?.uuid ? <CompanyProfileEdit partner={partner} /> : <>Loading...</>)}

        <ModalInviteMember isUserCompanyAdmin={isUserCompanyAdmin} isUserSystemAdmin={isAdmin} />
        <ModalInvitationSent />
        <ModalDeactivateMember />
        <ModalRole isUserCompanyAdmin={isUserCompanyAdmin} isUserSystemAdmin={isAdmin} />
        <ModalConfirm />
      </div>
    </DashboardLayout>
  );
};

export default CompanyProfilePage;
