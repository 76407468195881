import React from 'react';

import { Icon } from '../../../../shared';
import { BillPaymentsSettings } from '../../../../shared/models';

type TableHeadProps = {
  sortBy: string;
  sortOrder: 'desc' | 'asc';
  setSortOrder: (value: 'desc' | 'asc') => void;
  setSortBy: (value: string) => void;
};

interface TableHeadColumn {
  id: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  fixed?: boolean;
  sortName?: string;
}

const LoansTableHead: React.FC<TableHeadProps> = ({
  setSortOrder,
  setSortBy,
  sortBy,
  sortOrder,
}: TableHeadProps) => {
  const columns: TableHeadColumn[] = [
    { id: 'name', name: 'Borrower’s name', checked: true },
    { id: 'uuid', name: 'Last 5 digits of the Loan’s ID  ', checked: true },
    {
      id: 'principalAmount',
      name: 'Principal amount',
      checked: true,
      sortName: 'terms.amount.value',
    },
    { id: 'date', name: 'Date created', checked: true, sortName: 'createdAt' },
    { id: 'phoneNumber', name: 'Phone number', checked: true },
    { id: 'email', name: 'Borrower’s email address', checked: true },
    { id: 'status', name: 'Loan status', checked: true },
  ];
  const changeSorting = (sortName: string) => {
    let dir: 'desc' | 'asc' = 'asc';
    if (sortBy === sortName) {
      dir = sortOrder === 'asc' ? 'desc' : dir;
      return setSortOrder(dir);
    }
    return setSortBy(sortName);
  };

  const getName = (id: string, name: string) => {
    if (id === 'uuid') {
      return (
        <>
          <span style={{}}>Last 5 digits of</span>

          <br />

          <span>the Loan&apos;s ID</span>
        </>
      );
    }

    return name;
  };

  return (
    <tr className="table__header-row">
      {columns.map(({ id, fixed, sortName, name }) => (
        <td className={`table__cell table__cell--header `} key={id}>
          {sortName ? (
            <button
              type="button"
              className="table__sort-btn"
              onClick={() => changeSorting(sortName || 'payer.name')}
            >
              {name}
              <Icon
                name={
                  sortBy !== sortName
                    ? 'double-arrow'
                    : `sorting-arrow-${sortOrder === 'asc' ? 'up' : 'down'}`
                }
                classes="table__sort-icon"
              />
            </button>
          ) : (
            getName(id, name)
          )}
        </td>
      ))}
    </tr>
  );
};

export default LoansTableHead;
