import * as yup from 'yup';

import { validatePasswordStrength } from '../../../shared/utils/validation';

const passwordSchema = yup.object({
  password: yup
    .string()
    .min(8)
    .test('is-strong', 'Password is not strong', (passVal) => {
      const [isValid, _] = validatePasswordStrength(passVal || '');
      return !!isValid;
    })
    .required(),
  passwordConfirm: yup.string().test('passwords-match', 'Passwords do not match', function (value) {
    return this.parent.password === value;
  }),
});

export { passwordSchema };
