import React from 'react';

import { StatusInTable } from '../../../../shared/models';
import { TransferStatus } from '../../../../shared/models/platform-admin';

type Props = {
  type: string;
  classes?: string;
};

const getTypeName = (type: string) => {
  switch (type) {
    case TransferStatus.IN_PROCESS:
      return 'In Progress';
    case TransferStatus.PAID:
      return 'Paid';
    case TransferStatus.FAILED:
      return 'Failed';
    case TransferStatus.UNRECOGNIZED:
      return 'UNRECOGNIZED';
    default:
      return type;
  }
};

const getTypeClassname = (type: string) => {
  switch (type) {
    case TransferStatus.IN_PROCESS:
      return StatusInTable.PENDING;
    case TransferStatus.PAID:
      return StatusInTable.PROCESSED;
    case TransferStatus.FAILED:
      return StatusInTable.FAILED;
    default:
      return type;
  }
};

const TableTransferStatus: React.FC<Props> = ({ type, classes }: Props) => {
  const renderTypeName = getTypeName(type);
  const renderClassname = getTypeClassname(type);
  return (
    <div className={`table-status ${classes || ''}`}>
      <div className={`table-status__marker table-status__marker--${renderClassname}`} />
      {renderTypeName}
    </div>
  );
};

export default TableTransferStatus;
