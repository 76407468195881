import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FlowTypes } from '../../../features/auth/store';
import { useAppSelector } from '../../../main/store/hooks';
import ModalSuccess from '../ModalSuccess';

import {
  CheckoutListHeader,
  CheckoutListProgressBar,
  ListItem,
  PaymentVerifyModal,
} from './components';
import useCheckoutList from './hooks/useCheckoutList';
import useVerifyModal from './hooks/useVerifyModal';

const CheckoutList = () => {
  const history = useHistory();

  const {
    properties: { listItems, loading },
    operations: { checkItem },
  } = useCheckoutList();

  const { partner } = useAppSelector((state) => state.auth);

  const [toggleDropdown, setToggleDropdown] = useState(true);
  const { showModal, setShowModal } = useVerifyModal();

  useEffect(() => {
    if (partner?.details.flowType === FlowTypes.LOAN_CREATOR) {
      history.push('/loan-overview');
    }
  }, []);

  if (loading) return null;

  return (
    <div className="checkout-list">
      <CheckoutListHeader
        setToggleDropdown={setToggleDropdown}
        toggleDropdown={toggleDropdown}
        listItems={listItems}
      />
      <CheckoutListProgressBar listItems={listItems} />
      <PaymentVerifyModal setShowModal={setShowModal} showModal={showModal} checkItem={checkItem} />
      <ModalSuccess />
      <div className={classNames('checkout-list__container', { close: !toggleDropdown })}>
        <ul>
          {listItems.length > 0 &&
            listItems.map((item) => (
              <ListItem
                key={item.id}
                isDone={item.isDone}
                linkTo={item.linkTo}
                title={item.title}
                setShowModal={setShowModal}
                checkItem={checkItem}
                property={item.property}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default CheckoutList;
