export type AddAchNodeDto = {
  type: NodeType;
  routingNumber?: string;
  accountNumber?: string;
  taxId?: string;
  address?: Address;
};

export type Address = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export enum NodeType {
  ACH = 'ACH-US',
  INTERCHANGE = 'INTERCHANGE-US',
}
