import React, { ReactElement, useEffect, useState } from 'react';

import Backdrop from './Backdrop';

type Props = {
  children: ReactElement;
  opened: boolean;
  handleClose?: (v: boolean) => void;
  classes?: string;
  bodyClasses?: string;
};

export default function Modal({ children, opened, handleClose, classes, bodyClasses }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [shownModal, setShownModal] = useState(false);
  useEffect(() => {
    if (opened) {
      setShowModal(true);
      setTimeout(() => {
        setShownModal(true);
      }, 200);
      document.documentElement.style.overflow = 'hidden';
    } else {
      setShownModal(false);
      setTimeout(() => {
        setShowModal(false);
      }, 200);
      document.documentElement.style.overflow = 'visible';
    }
  }, [opened]);
  return (
    <div
      className={`modal${showModal ? ' modal--show ' : ''}${shownModal ? ' modal--shown ' : ''} ${
        classes || ''
      }`}
    >
      <Backdrop
        show={opened}
        onClick={() => {
          if (handleClose) {
            handleClose(false);
          }
        }}
      />
      <div className={`modal__body ${bodyClasses || ''}`}>{children}</div>
    </div>
  );
}
