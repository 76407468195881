export const validatePasswordStrength = (password: string): [boolean, boolean[]] => {
  const validArray = [false, false, false, false];

  validArray[0] = password.length >= 8;

  validArray[1] = password.length - password.replace(/[A-Z]/g, '').length > 0;

  validArray[2] = password.length - password.replace(/[0-9]/g, '').length > 0;

  validArray[3] = password.length - password.replace(/\W|_/g, '').length > 0;

  const result = validArray.reduce((accum, item) => accum && item, true);

  return [result, validArray];
};
