import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { NotificationRangeDto } from '../../../../shared/models/NotificationRangeDto';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const updateSftpReportSettings = createAsyncThunk<
  boolean,
  NotificationRangeDto,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/updateSftpReportSettings', async (payload, { rejectWithValue }) => {
  try {
    await api.put(`/partners/sftp-report-settings`, payload);
    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
