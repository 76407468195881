import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useLazyEffect } from '../../../shared';
import { adminTransactionsSearch } from '../store/actions/adminTransactionsSearch';
import { selectTransactionsState } from '../store/selectors/transactions';

export const useTransactionsTableSearchEffect = () => {
  const dispatch = useAppDispatch();
  const { page, pageSize, dateRangeFilter, searchQuery, transferStatus, sortOrder, sortField } =
    useAppSelector(selectTransactionsState);

  useLazyEffect(
    () => {
      dispatch(adminTransactionsSearch());
    },
    [page, pageSize, searchQuery, dateRangeFilter, transferStatus, sortOrder, sortField],
    300,
  );
};
