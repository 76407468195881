import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';
import { FundingSourceDTO } from '../../../../shared/models/FundingSource';
import { LoanDetails } from '../../../../shared/models/platform-admin/loans/LoanModel';
import { formatDollarAmount, getContact, roundToTwoDecimals } from '../../../../shared/utils';
import { FormWrapper } from '../../../init-setup/components';
import bank from '../../assets/bank.svg';
import card from '../../assets/card.svg';
import trustage from '../../assets/trustage.svg';
import { LoanClosureType, LoanStageKind } from '../../dtos/LoanTypes';
import { setIsLoanShareScreenShow } from '../../store';
import { acceptLoan } from '../../store/actions/acceptLoan';
import { LoanSubmissionConfirmation } from '../LoanSubmissionConfirmation/LoanSubmissionConfirmation';
import ModalCancelLoan from '../ModalCancelLoan/ModalCancelLoan';
import ModalLoanCoverage from '../ModalLoanCoverage/ModalLoanCoverage';
import ModalServiceFee from '../ModalServiceFee/ModalServiceFee';
import ModalShare from '../ModalShare/ModalShare';

type TProps = {
  loanDetails: LoanDetails | undefined;
  activePaymentMethod: FundingSourceDTO | undefined;
  displayBorrowerName: string | undefined;
};

export default function LoanSummaryStatus({
  loanDetails,
  activePaymentMethod,
  displayBorrowerName,
}: TProps) {
  const formattedDate = (isoDate: string) => format(parseISO(isoDate), 'MMMM d, yyyy');

  const dispatch = useAppDispatch();
  const { isLoanShareScreenShow } = useAppSelector((state) => state.loans);

  const [isModalServiceFeeOpen, setIsModalServiceFeeOpen] = useState(false);
  const [isModalLoanCoverageOpen, setIsModalLoanCoverageOpen] = useState(false);
  const [isModalShareOpen, setIsModalShareOpen] = useState(false);
  const [isModalCancelLoanOpen, setIsModalCancelLoanOpen] = useState(false);
  const [isConfirmPaymentMethod, setIsConfirmPaymentMethod] = useState(false);

  const [isLoansDetailsPopUpOpen, setIsLoansDetailsPopUpOpen] = useState(false);
  const [isLoansRepaymentPopUpOpen, setIsLoansRepaymentPopUpOpen] = useState(false);
  const [isLoansSummaryPopUpOpen, setIsLoansSummaryPopUpOpen] = useState(false);

  const [loansDetailsHeight, setLoansDetailsHeight] = useState(0);
  const [loansRepaymentHeight, setLoansRepaymentHeight] = useState(0);
  const [loansSummaryHeight, setLoansSummaryHeight] = useState(0);

  const [statusName, setStatusName] = useState('Created');
  const [statusDescription, setStatusDescription] = useState(() => (
    <>
      {/* eslint-disable-next-line */}
      Your contact hasn&lsquo;t signed up yet. Use the{' '}
      <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link">
        Share Loan
        {/* eslint-disable-next-line */}
      </span>{' '}
      button (see above) to resend loan details.
    </>
  ));
  const [statusRetryDescription, setStatusRetryDescription] = useState(() => (
    <>
      Your payment method failed.
      {/* eslint-disable-next-line */}
      <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed">
        {' '}
        Please retry.
      </span>
    </>
  ));
  const [statusAmount, setStatusAmount] = useState(<></>);

  const loansDetailsRef = useRef<HTMLDivElement>(null);
  const loansRepaymentRef = useRef<HTMLDivElement>(null);
  const loansSummaryRef = useRef<HTMLDivElement>(null);

  const loansDetailsAnimation = useSpring({
    marginTop: isLoansDetailsPopUpOpen ? '0' : '0px',
    height: isLoansDetailsPopUpOpen ? `${loansDetailsHeight}px` : '0px',
    opacity: isLoansDetailsPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const loansRepaymentAnimation = useSpring({
    marginTop: isLoansRepaymentPopUpOpen ? '0' : '0px',
    height: isLoansRepaymentPopUpOpen ? `${loansRepaymentHeight}px` : '0px',
    opacity: isLoansRepaymentPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const loansSummaryAnimation = useSpring({
    marginTop: isLoansSummaryPopUpOpen ? '0' : '0px',
    height: isLoansSummaryPopUpOpen ? `${loansSummaryHeight}px` : '0px',
    opacity: isLoansSummaryPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const isFailed = useCallback(() => {
    if (
      loanDetails &&
      (loanDetails.stage.kind === LoanStageKind.acceptance ||
        loanDetails.stage.kind === LoanStageKind.disbursement)
    ) {
      const principalFailed =
        !loanDetails?.stage.awaitingTransferId && !!loanDetails?.stage.failedTransferId;
      const convenienceFailed =
        !loanDetails?.stage.awaitingConvenienceTransferId &&
        !!loanDetails?.stage.failedConvenienceTransferId;
      const isInProcess =
        !!loanDetails.stage.retry &&
        principalFailed &&
        !!loanDetails?.stage.awaitingConvenienceTransferId &&
        !loanDetails?.stage.failedConvenienceTransferId;
      return [principalFailed, convenienceFailed, isInProcess];
    }
    return [false, false];
  }, [loanDetails]);

  const [isPrincipalFailed, isFailedConvenienceFee, isInProcess] = useMemo(
    () => isFailed(),
    [isFailed],
  );

  const isColorRed = useMemo(() => {
    switch (loanDetails?.stage.kind) {
      case LoanStageKind.disbursement:
        if (
          loanDetails?.stage.awaitingTransferId ||
          loanDetails?.stage.awaitingConvenienceTransferId
        ) {
          return false;
        }
        if (loanDetails?.stage.failedTransferId || loanDetails?.stage.failedConvenienceTransferId) {
          return true;
        }
        return false;
      case LoanStageKind.acceptance:
        return !!loanDetails?.stage.retry;
      default:
        return false;
    }
  }, [loanDetails]);

  const isColorBlue =
    loanDetails?.stage.kind === LoanStageKind.acceptance &&
    loanDetails?.stage.awaitingConvenienceTransferId;

  const isFooterHidden = useMemo(() => {
    switch (loanDetails?.stage.kind) {
      case LoanStageKind.disbursement:
        if (
          loanDetails?.stage.awaitingTransferId ||
          loanDetails?.stage.awaitingConvenienceTransferId
        ) {
          return true;
        }
        if (loanDetails?.stage.failedTransferId || loanDetails?.stage.failedConvenienceTransferId) {
          return false;
        }
        return true;
      case LoanStageKind.acceptance:
        return !!loanDetails?.stage.awaitingConvenienceTransferId;
      case LoanStageKind.closed:
        return (
          loanDetails.stage.closureType === LoanClosureType.rejected ||
          loanDetails.stage.closureType === LoanClosureType.cancelled ||
          loanDetails.stage.closureType === LoanClosureType.deactivated
        );
      default:
        return false;
    }
  }, [loanDetails]);

  const isShowCancelButton = useMemo(() => {
    switch (loanDetails?.stage.kind) {
      case LoanStageKind.disbursement:
        return isPrincipalFailed && !isConfirmPaymentMethod;
      case LoanStageKind.acceptance:
        return isPrincipalFailed && !isConfirmPaymentMethod;
      default:
        return false;
    }
  }, [loanDetails, isConfirmPaymentMethod, isPrincipalFailed]);

  const amount = loanDetails?.terms.amount.value;
  const serviceFee = loanDetails?.terms?.plan?.convenienceFee?.value?.amount
    ? roundToTwoDecimals(
        Number(loanDetails?.terms.amount.value) *
          (loanDetails?.terms?.plan?.convenienceFee?.value?.amount / 100),
      )
    : 0;
  const totalFeeAmount = serviceFee + 0;
  const totalAmount = roundToTwoDecimals(Number(loanDetails?.terms.amount.value) + totalFeeAmount);

  useEffect(() => {
    if (loansDetailsRef.current) {
      setLoansDetailsHeight(loansDetailsRef.current.scrollHeight);
    }
  }, [isLoansDetailsPopUpOpen]);

  useEffect(() => {
    if (loansRepaymentRef.current) {
      setLoansRepaymentHeight(loansRepaymentRef.current.scrollHeight);
    }
  }, [isLoansRepaymentPopUpOpen]);

  useEffect(() => {
    if (loansSummaryRef.current) {
      setLoansSummaryHeight(loansSummaryRef.current.scrollHeight);
    }
  }, [isLoansSummaryPopUpOpen]);

  useEffect(() => {
    const updateStatusName = () => {
      if (!loanDetails) return 'Created';

      switch (loanDetails.stage.kind) {
        case LoanStageKind.binding:
          return 'Created';
        case LoanStageKind.disbursement:
          if (
            loanDetails?.stage.awaitingTransferId ||
            loanDetails?.stage.awaitingConvenienceTransferId
          ) {
            return 'Payment processing';
          }
          if (
            loanDetails?.stage.failedTransferId ||
            loanDetails?.stage.failedConvenienceTransferId
          ) {
            return 'Transfer issue';
          }

          return 'Payment processing';
        case LoanStageKind.acceptance:
          if (loanDetails?.stage.awaitingConvenienceTransferId) {
            return 'Payment processing';
          }

          if (loanDetails.stage.retry) {
            return 'Transfer issue';
          }

          return 'Pending';

        case LoanStageKind.repayment:
          if (loanDetails?.stage?.gracePeriod?.enabled) {
            return 'Repayment late';
          }
          return 'Active';
        case LoanStageKind.closed:
          switch (loanDetails.stage.closureType) {
            case LoanClosureType.rejected:
              return 'Declined';
            case LoanClosureType.cancelled:
              return 'Canceled';
            case LoanClosureType.forgiven:
              return 'Forgiven';
            case LoanClosureType.paidOut:
              return 'Paid off';
            case LoanClosureType.deactivated:
              return 'Deactivated';
            default:
              return 'Created';
          }
        default:
          return 'Created';
      }
    };

    const updateStatusDescription = () => {
      if (!loanDetails) {
        return (
          <>
            {/* eslint-disable-next-line */}
            Your contact hasn&lsquo;t signed up yet. Use the{' '}
            <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link">
              Share Loan
              {/* eslint-disable-next-line */}
            </span>{' '}
            button (see above) to resend loan details.
          </>
        );
      }

      switch (loanDetails.stage.kind) {
        case LoanStageKind.binding:
          return (
            <>
              {/* eslint-disable-next-line */}
              Your contact hasn&lsquo;t signed up yet. Use the{' '}
              <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link">
                Share Loan
                {/* eslint-disable-next-line */}
              </span>{' '}
              button (see above) to resend loan details.
            </>
          );
        case LoanStageKind.disbursement:
          if (
            loanDetails?.stage.awaitingTransferId ||
            loanDetails?.stage.awaitingConvenienceTransferId
          ) {
            return (
              <>
                Both parties have agreed to the terms of the loan, and we are currently processing
                the funds. If you have any questions, please feel free to contact our support team.
              </>
            );
          }
          if (
            loanDetails?.stage.failedTransferId ||
            loanDetails?.stage.failedConvenienceTransferId
          ) {
            return (
              <>
                {/* eslint-disable-next-line */}
                The terms have been accepted, but the payment has been declined. Please tap{' '}
                <span
                  className={cn(
                    'loans__selectLoanSummaryStatus__loansDetails__footer__link',
                    isColorRed
                      ? 'loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed loans__selectLoanSummaryStatus__loansDetails__footer__link--bold'
                      : '',
                  )}
                >
                  RETRY
                  {/* eslint-disable-next-line */}
                </span>{' '}
                for more information.
              </>
            );
          }

          return (
            <>
              Both parties have agreed to the terms of the loan, and we are currently processing the
              funds. If you have any questions, please feel free to contact our support team.
            </>
          );
        case LoanStageKind.acceptance:
          if (
            loanDetails?.stage.awaitingTransferId ||
            loanDetails?.stage.awaitingConvenienceTransferId
          ) {
            return (
              <>
                Both parties have agreed to the terms of the loan, and we are currently processing
                the funds. If you have any questions, please feel free to contact our support team.
              </>
            );
          }

          return loanDetails.stage.retry ? (
            <>
              {/* eslint-disable-next-line */}
              The terms have been accepted, but the payment has been declined. Please tap{' '}
              <span
                className={cn(
                  'loans__selectLoanSummaryStatus__loansDetails__footer__link',
                  isColorRed
                    ? 'loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed loans__selectLoanSummaryStatus__loansDetails__footer__link--bold'
                    : '',
                )}
              >
                RETRY
                {/* eslint-disable-next-line */}
              </span>{' '}
              for more information.
            </>
          ) : (
            <>Your contact has signed up, but they have not yet accepted the terms of the loan.</>
          );
        case LoanStageKind.repayment:
          return <>Loan in repayment. See schedule below.</>;
        case LoanStageKind.closed:
          switch (loanDetails.stage.closureType) {
            case LoanClosureType.rejected:
              return (
                <>
                  {/* eslint-disable-next-line */}
                  Your loan offer was declined on {/* eslint-disable-next-line */}
                  {loanDetails?.closedAt ? formattedDate(loanDetails?.closedAt) : ''}.
                </>
              );
            case LoanClosureType.cancelled:
              return <>You have canceled this loan.</>;
            case LoanClosureType.forgiven:
              return <>Need help? Reach out to our Support Team</>;
            case LoanClosureType.paidOut:
              return <>Need help? Reach out to our Support Team</>;
            case LoanClosureType.deactivated:
              return (
                <>
                  {/* eslint-disable-next-line */}
                  Your loan offer was deactivated on {/* eslint-disable-next-line */}
                  {loanDetails?.closedAt ? formattedDate(loanDetails?.closedAt) : ''}.
                </>
              );
            default:
              return (
                <>
                  {/* eslint-disable-next-line */}
                  Your contact hasn&lsquo;t signed up yet. Use the{' '}
                  <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link">
                    Share Loan
                    {/* eslint-disable-next-line */}
                  </span>{' '}
                  button (see above) to resend loan details.
                </>
              );
          }
        default:
          return (
            <>
              {/* eslint-disable-next-line */}
              Your contact hasn&lsquo;t signed up yet. Use the{' '}
              <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link">
                Share Loan
                {/* eslint-disable-next-line */}
              </span>{' '}
              button (see above) to resend loan details.
            </>
          );
      }
    };

    const updateStatusRetryDescription = () => {
      if (isPrincipalFailed && !isFailedConvenienceFee) {
        return (
          <>
            The loan&apos;s service fee has been paid but the principal amount remains outstanding.
            {/* eslint-disable-next-line */}
            <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed">
              {' '}
              Please retry.
            </span>
          </>
        );
      }

      if (!isPrincipalFailed && isFailedConvenienceFee) {
        return (
          <>
            The loan&apos;s principal amount has been paid but the service fee remains outstanding.
            Repayments will not begin until the service fee is settled.
            {/* eslint-disable-next-line */}
          <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed">
              {' '}
              Please retry.
            </span>
          </>
        );
      }

      if (isPrincipalFailed && isFailedConvenienceFee) {
        return (
          <>
            Your payment method failed.
            {/* eslint-disable-next-line */}
            <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed">
              {' '}
              Please retry.
            </span>
          </>
        );
      }

      return (
        <>
          Your payment method failed.
          {/* eslint-disable-next-line */}
          <span className="loans__selectLoanSummaryStatus__loansDetails__footer__link loans__selectLoanSummaryStatus__loansDetails__footer__link--isColorRed">
            {' '}
            Please retry.
          </span>
        </>
      );
    };

    const updateStatusAmount = () => {
      if (isPrincipalFailed && !isFailedConvenienceFee) {
        return <>{formatDollarAmount(amount)}</>;
      }

      if (!isPrincipalFailed && isFailedConvenienceFee) {
        return <>{formatDollarAmount(serviceFee)}</>;
      }

      if (isPrincipalFailed && isFailedConvenienceFee) {
        return <>{formatDollarAmount(totalAmount)}</>;
      }

      return <>{formatDollarAmount(totalAmount)}</>;
    };

    setStatusName(updateStatusName());
    setStatusDescription(updateStatusDescription());
    setStatusRetryDescription(updateStatusRetryDescription());
    setStatusAmount(updateStatusAmount());
  }, [loanDetails]);

  return (
    <>
      {isLoanShareScreenShow ? (
        <LoanSubmissionConfirmation displayBorrowerName={displayBorrowerName} />
      ) : (
        <div className="loans__multiStepForm__form">
          <div
            className={cn(
              'multiStepForm__step loans__multiStepForm__form__container',
              isFooterHidden || isInProcess
                ? 'loans__multiStepForm__form__container--withoutFooter'
                : '',
            )}
          >
            {isConfirmPaymentMethod ? (
              <FormWrapper>
                <div className="loans__selectLoanSummaryStatus">
                  <div className="loans__selectLoanSummaryStatus__container">
                    <div className="loanTypeForm__head">
                      <div className="loans__selectLoanSummaryStatus__cardBlock">
                        <img src={card} alt="card" />
                      </div>
                      <h2 className="loanTypeForm__title">Confirm payment method</h2>
                    </div>
                    <div className="loans__selectLoanSummaryStatus__body">
                      <div className="loans__selectLoanSummaryStatus__loansDetails">
                        <div className="loans__selectLoanSummaryStatus__loansDetails__head">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__subtitle">
                            Loan details
                          </div>
                        </div>
                        <div className="loans__selectLoanSummaryStatus__loansDetails__body">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--mb32">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Borrower
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                              {displayBorrowerName}
                            </div>
                          </div>
                          <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Amount
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                              {statusAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="loans__selectLoanSummaryStatus__loansDetails loans__selectLoanSummaryStatus__loansDetails--hasFooter"
                        style={{
                          marginTop: '24px',
                        }}
                      >
                        <div className="loans__selectLoanSummaryStatus__loansDetails__head">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__subtitle">
                            Payment method
                          </div>
                        </div>
                        <div className="loans__selectLoanSummaryStatus__loansDetails__body loans__selectLoanSummaryStatus__loansDetails__body--hasFooter">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__bankAccountBlock">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__bankAccountBlock__icon">
                              <img src={bank} alt="bank" />
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__bankAccountBlock__info">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__bankAccountBlock__label">
                                Account
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__bankAccountBlock__value">
                                {/* eslint-disable-next-line */}
                                  Ending in {activePaymentMethod?.redactedAccountNumber}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="loans__selectLoanSummaryStatus__loansDetails__footer loans__selectLoanSummaryStatus__loansDetails__footer--isColorRed">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock--isColorRed">
                            <Icon name="feedback-red" />
                          </div>
                          <div className="loans__selectLoanSummaryStatus__loansDetails__footer__descr loans__selectLoanSummaryStatus__loansDetails__footer__descr--isColorRed">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__footer__text loans__selectLoanSummaryStatus__loansDetails__footer__text--isColorRed">
                              {statusRetryDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormWrapper>
            ) : (
              <FormWrapper>
                <div className="loans__selectLoanSummaryStatus">
                  {(loanDetails?.stage.kind === LoanStageKind.closed &&
                    loanDetails?.stage?.closureType === LoanClosureType.rejected) ||
                  (loanDetails?.stage.kind === LoanStageKind.closed &&
                    loanDetails?.stage?.closureType === LoanClosureType.cancelled) ||
                  (loanDetails?.stage.kind === LoanStageKind.closed &&
                    loanDetails?.stage?.closureType === LoanClosureType.deactivated) ? (
                    ''
                  ) : (
                    <button
                      type="button"
                      className="modal-content__close loans__modal__close loans__link__close"
                      onClick={() => setIsModalShareOpen(true)}
                    >
                      <Icon name="share" classes="modal-content__close-icon" />
                    </button>
                  )}
                  <div className="loans__selectLoanSummaryStatus__container">
                    <div className="loanTypeForm__head">
                      <h1 className="loans__selectLoanSummaryStatus__baseName">
                        {displayBorrowerName?.slice(0, 1)}
                      </h1>
                      <h2 className="loanTypeForm__title">Loan offer summary</h2>
                    </div>
                    <div className="loans__selectLoanSummaryStatus__body">
                      {loanDetails?.borrowerType === 'user' ? (
                        <div className="loans__selectLoanSummaryStatus__loansCoverageBlock">
                          <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__body">
                            <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__descr">
                              <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__title">
                                Your loan is covered
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__subtitle">
                                We teamed up with TruStage™ to provide Payment Guard Insurance at no
                                additional cost to you.
                              </div>
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__iconBlock">
                              <img src={trustage} alt="trustage" />
                            </div>
                          </div>
                          <div className="loans__selectLoanSummaryStatus__loansCoverageBlock__footer">
                            <Button
                              type="button"
                              className="button button--primary-blue button--lg button--block loans__selectLoanSummaryStatus__loansCoverageBlock__button"
                              onClick={() => setIsModalLoanCoverageOpen(true)}
                            >
                              Learn more
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="loans__selectLoanSummaryStatus__loansDetails loans__selectLoanSummaryStatus__loansDetails--hasFooter">
                        <div className="loans__selectLoanSummaryStatus__loansDetails__body loans__selectLoanSummaryStatus__loansDetails__body--hasFooter">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Current loan status
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                              {statusName}
                            </div>
                          </div>
                        </div>
                        <div
                          className={cn(
                            'loans__selectLoanSummaryStatus__loansDetails__footer',
                            isColorBlue
                              ? 'loans__selectLoanSummaryStatus__loansDetails__footer--isColorBlue'
                              : '',
                            isColorRed
                              ? 'loans__selectLoanSummaryStatus__loansDetails__footer--isColorRed'
                              : '',
                          )}
                        >
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock',
                              isColorBlue
                                ? 'loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock--isColorBlue'
                                : '',
                              isColorRed
                                ? 'loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock--isColorRed'
                                : '',
                            )}
                          >
                            {isColorBlue ? (
                              <Icon name="lightbulb-blue" />
                            ) : (
                              <Icon name={isColorRed ? 'lightbulb-red' : 'lightbulb-blue'} />
                            )}
                          </div>
                          <div className="loans__selectLoanSummaryStatus__loansDetails__footer__descr">
                            <div
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__footer__text',
                                isColorBlue
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__footer__text--isColorBlue'
                                  : '',
                                isColorRed
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__footer__text--isColorRed'
                                  : '',
                              )}
                            >
                              {statusDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="loans__selectLoanSummaryStatus__loansDetails"
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <div className="loans__selectLoanSummaryStatus__loansDetails__body">
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__row',
                              !isLoansDetailsPopUpOpen
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--mb16'
                                : 'loans__selectLoanSummaryStatus__loansDetails__row--mb32',
                            )}
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Lending to
                            </div>
                            <button
                              type="button"
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__accordion',
                                isLoansDetailsPopUpOpen
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__accordion--active'
                                  : '',
                              )}
                              onClick={() => {
                                setIsLoansDetailsPopUpOpen(!isLoansDetailsPopUpOpen);
                              }}
                            >
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                                {displayBorrowerName}
                                <Icon name="chevron-down" />
                              </div>
                            </button>
                          </div>
                          <animated.div
                            style={loansDetailsAnimation}
                            ref={loansDetailsRef}
                            className="loans__selectLoanSummaryStatus__loansDetails__innerBody"
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                Phone number
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                                {loanDetails
                                  ? loanDetails?.stage?.kind === LoanStageKind.binding
                                    ? parsePhoneNumber(
                                        getContact(loanDetails?.targetContactUri),
                                      )?.formatInternational()
                                    : loanDetails?.borrower.redactedPhoneNum
                                  : ''}
                              </div>
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                Relationship
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                                {loanDetails?.borrowerType === 'user'
                                  ? 'Employee / Team Member'
                                  : 'Business Entity'}
                              </div>
                            </div>
                          </animated.div>
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__row',
                              !isLoansDetailsPopUpOpen
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--mt16'
                                : 'loans__selectLoanSummaryStatus__loansDetails__row--mt32',
                            )}
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Reason for loan
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                              {loanDetails?.terms.description.reason}
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 className="loans__selectLoanSummaryStatus__title">Repayment details</h3>
                      <div
                        className={cn(
                          'loans__selectLoanSummaryStatus__loansDetails',
                          !loanDetails?.terms.dueDate
                            ? 'loans__selectLoanSummaryStatus__loansDetails--hasFooter'
                            : '',
                        )}
                      >
                        <div
                          className={cn(
                            'loans__selectLoanSummaryStatus__loansDetails__body',
                            !loanDetails?.terms.dueDate
                              ? 'loans__selectLoanSummaryStatus__loansDetails__body--detailsBlock'
                              : 'loans__selectLoanSummaryStatus__loansDetails__body--hasFooter',
                          )}
                        >
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--mb32',
                              !loanDetails?.terms.dueDate
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--hasPadding'
                                : '',
                            )}
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Type
                            </div>
                            <div
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__value',
                                !loanDetails?.terms.dueDate
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__value--second'
                                  : '',
                              )}
                            >
                              {!loanDetails?.terms.dueDate ? 'Monthly' : 'One-time'}
                            </div>
                          </div>
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__row',
                              isLoansRepaymentPopUpOpen
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--mb32'
                                : '',
                              !loanDetails?.terms.dueDate
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--hasPadding'
                                : '',
                            )}
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              {!loanDetails?.terms.dueDate
                                ? 'Repayment schedule'
                                : 'Repayment date'}
                            </div>
                            {!loanDetails?.terms.dueDate ? (
                              <button
                                type="button"
                                className={cn(
                                  'loans__selectLoanSummaryStatus__loansDetails__accordion',
                                  isLoansRepaymentPopUpOpen
                                    ? 'loans__selectLoanSummaryStatus__loansDetails__accordion--active'
                                    : '',
                                )}
                                onClick={() => {
                                  setIsLoansRepaymentPopUpOpen(!isLoansRepaymentPopUpOpen);
                                }}
                              >
                                <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                                  {`${loanDetails?.terms.plan.payments.length} Months`}
                                  <Icon name="chevron-down" />
                                </div>
                              </button>
                            ) : (
                              <div
                                className={cn(
                                  'loans__selectLoanSummaryStatus__loansDetails__accordion',
                                  isLoansRepaymentPopUpOpen
                                    ? 'loans__selectLoanSummaryStatus__loansDetails__accordion--active'
                                    : '',
                                )}
                              >
                                <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                                  {loanDetails?.terms.dueDate
                                    ? formattedDate(loanDetails?.terms.dueDate)
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <animated.div
                            style={loansRepaymentAnimation}
                            ref={loansRepaymentRef}
                            className="loans__selectLoanSummaryStatus__loansDetails__innerBody"
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__infoBlock">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock">
                                <Icon name="sparkle-blue" />
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__footer__descr">
                                <div className="loans__selectLoanSummaryStatus__loansDetails__footer__text">
                                  Sit back and relax while Auto-Pay takes care of the rest. Your
                                  borrower can always make payments ahead of schedule.
                                </div>
                              </div>
                            </div>
                            <div
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__row',
                                !loanDetails?.terms.dueDate
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__row--hasPadding'
                                  : '',
                              )}
                            >
                              <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                First payment due
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                                {loanDetails?.repaymentDateFrom
                                  ? formattedDate(loanDetails?.repaymentDateFrom)
                                  : ''}
                              </div>
                            </div>
                            <div
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__row',
                                !loanDetails?.terms.dueDate
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__row--hasPadding'
                                  : '',
                              )}
                            >
                              <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                Final payment due
                              </div>
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                                {loanDetails?.repaymentDateTo
                                  ? formattedDate(loanDetails?.repaymentDateTo)
                                  : ''}
                              </div>
                            </div>
                          </animated.div>
                        </div>
                        {!loanDetails?.terms.dueDate ? (
                          ''
                        ) : (
                          <div className="loans__selectLoanSummaryStatus__loansDetails__footer">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock">
                              <Icon name="sparkle-blue" />
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__footer__descr">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__footer__text">
                                Sit back and relax while Auto-Pay takes care of the rest. Your
                                borrower can always make payments ahead of schedule.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <h3 className="loans__selectLoanSummaryStatus__title">Payment summary</h3>
                      <div className="loans__selectLoanSummaryStatus__loansDetails loans__selectLoanSummaryStatus__loansDetails__mb96">
                        <div className="loans__selectLoanSummaryStatus__loansDetails__body">
                          <div className="loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--mb32">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Loan amount
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                              {formatDollarAmount(amount)}
                            </div>
                          </div>
                          <div
                            className={cn(
                              'loans__selectLoanSummaryStatus__loansDetails__row',
                              isLoansSummaryPopUpOpen
                                ? 'loans__selectLoanSummaryStatus__loansDetails__row--mb32'
                                : '',
                            )}
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Fees
                            </div>
                            <button
                              type="button"
                              className={cn(
                                'loans__selectLoanSummaryStatus__loansDetails__accordion',
                                isLoansSummaryPopUpOpen
                                  ? 'loans__selectLoanSummaryStatus__loansDetails__accordion--active'
                                  : '',
                              )}
                              onClick={() => {
                                setIsLoansSummaryPopUpOpen(!isLoansSummaryPopUpOpen);
                              }}
                            >
                              <div className="loans__selectLoanSummaryStatus__loansDetails__value">
                                {formatDollarAmount(totalFeeAmount)}
                                <Icon name="chevron-down" />
                              </div>
                            </button>
                          </div>
                          <animated.div
                            style={loansSummaryAnimation}
                            ref={loansSummaryRef}
                            className="loans__selectLoanSummaryStatus__loansDetails__innerBody"
                          >
                            <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                              <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                Service fee
                              </div>
                              {/* eslint-disable-next-line */}
                                <div
                                className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value"
                                onClick={() => setIsModalServiceFeeOpen(true)}
                              >
                                {formatDollarAmount(serviceFee)}
                                <Icon
                                  name="icon-info"
                                  classes="loans__selectAmountForm__summaryBlock__label__icon"
                                />
                              </div>
                            </div>
                            {loanDetails?.borrowerType === 'user' ? (
                              <div className="loans__selectLoanSummaryStatus__loansDetails__row">
                                <div className="loans__selectLoanSummaryStatus__loansDetails__label loans__selectLoanSummaryStatus__loansDetails__label">
                                  Loan coverage
                                  <div className="loans__selectAmountForm__summaryBlock__label__badge">
                                    <div className="loans__selectAmountForm__summaryBlock__label__badge__text">
                                      NEW
                                    </div>
                                  </div>
                                </div>
                                {/* eslint-disable-next-line */}
                                  <div
                                  className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value"
                                  onClick={() => setIsModalLoanCoverageOpen(true)}
                                >
                                  {formatDollarAmount(0)}
                                  <Icon
                                    name="icon-info"
                                    classes="loans__selectAmountForm__summaryBlock__label__icon"
                                  />
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </animated.div>
                          <div className="loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--mt32">
                            <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                              Total repayment
                            </div>
                            <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                              {formatDollarAmount(totalAmount)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormWrapper>
            )}
            <ModalServiceFee isOpen={isModalServiceFeeOpen} setIsOpen={setIsModalServiceFeeOpen} />
            <ModalLoanCoverage
              isOpen={isModalLoanCoverageOpen}
              setIsOpen={setIsModalLoanCoverageOpen}
            />
            <ModalShare
              isOpen={isModalShareOpen}
              setIsOpen={setIsModalShareOpen}
              displayBorrowerName={displayBorrowerName}
            />
            <ModalCancelLoan
              isOpen={isModalCancelLoanOpen}
              setIsOpen={setIsModalCancelLoanOpen}
              loanId={loanDetails?.uuid}
            />
          </div>
          {isFooterHidden || isInProcess ? (
            ''
          ) : (
            <div className="loans__footer">
              <div className="loans__footer__right loans__footer__right--retry">
                {isConfirmPaymentMethod ? (
                  ''
                ) : (
                  <div className="loans__footer__totalAmount">
                    <div className="loans__footer__totalAmount__label">You’ve offered</div>
                    <div className="loans__footer__totalAmount__value">
                      {formatDollarAmount(totalAmount)}
                    </div>
                  </div>
                )}
                {isShowCancelButton ? (
                  <Button
                    type="button"
                    className="button button--secondary-blue button--sm loans__button loans__button--secondary"
                    onClick={() => setIsModalCancelLoanOpen(true)}
                  >
                    Cancel loan
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  type="button"
                  className="button--primary-blue loans__button"
                  onClick={async () => {
                    if (isConfirmPaymentMethod) {
                      try {
                        const result = await dispatch(
                          // @ts-ignore
                          acceptLoan({
                            loanId: loanDetails?.uuid ? loanDetails?.uuid : '',
                            body: {
                              repaymentTarget: {
                                realm: defaultRealm,
                                accountId: activePaymentMethod?.uuid
                                  ? activePaymentMethod?.uuid
                                  : '',
                              },
                              disbursementSource: {
                                realm: defaultRealm,
                                accountId: activePaymentMethod?.uuid
                                  ? activePaymentMethod?.uuid
                                  : '',
                              },
                            },
                          }),
                        );

                        setTimeout(() => {
                          dispatch(setIsLoanShareScreenShow(true));
                        }, 1000);
                      } catch (error) {
                        console.error('Failed to accept loan:', error);
                      }
                    } else if (isPrincipalFailed || isFailedConvenienceFee) {
                      setIsConfirmPaymentMethod(true);
                    } else {
                      setIsModalCancelLoanOpen(true);
                    }
                  }}
                >
                  {isConfirmPaymentMethod || isPrincipalFailed || isFailedConvenienceFee
                    ? 'Retry'
                    : 'Cancel loan'}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
