import React from 'react';

import { Icon } from '../../../shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Indicator({ title, value, difference, icon, classes }: any) {
  return (
    <div className={`indicator ${classes || ''}`}>
      <div className="indicator__content-col">
        <p className="indicator__title">{title}</p>
        <p className="indicator__value">
          {value}
          {difference && <span className="indicator__difference">{difference}</span>}
        </p>
      </div>
      <div className="indicator__icon-box">
        <Icon name={icon} classes="indicator__icon" />
      </div>
    </div>
  );
}
