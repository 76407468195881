import { format } from 'date-fns';

import { ReactComponent as CSVIcon } from '../../../assets/images/CSV.svg';
import { ReactComponent as TXTIcon } from '../../../assets/images/TXT.svg';
import { Icon } from '../../../shared';

interface FileUploadItemProps {
  type: 'csv' | 'txt';
  file: File;
  showUploadedIcon?: boolean;
}

function bytesToMB(bytes: number) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

function downloadFile(filename: string, data: string) {
  const element = document.createElement('a');
  element.setAttribute('href', data);
  element.setAttribute('download', filename);
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  return true;
}

function convertToFileData(filename: string, file: File) {
  const reader = new FileReader();
  reader.onload = function (event) {
    if (event.target) {
      const fileData = event.target.result as string;
      // Call the download function with the file data
      downloadFile(filename, fileData);
    }
  };
  reader.readAsDataURL(file);
}

const FileUploadItem = ({ type, file, showUploadedIcon }: FileUploadItemProps) => (
  <div className="file">
    <div className="file__icon">{type === 'csv' ? <CSVIcon /> : <TXTIcon />}</div>
    <div className="file__info">
      <div className="file__title">{file.name}</div>
      <div className="file__box">
        <div className="file__date">{format(new Date(), 'dd MMM, yyyy, hh:mm  a')}</div>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <div className="file__size"> {bytesToMB(file.size)} MB</div>
        <button
          className="file__button file__button--download"
          type="button"
          onClick={() => convertToFileData(file.name, file)}
        >
          <Icon name="download" />
        </button>
      </div>
    </div>

    {showUploadedIcon && (
      <div className="file__controls">
        <Icon name="check" classes="svg-icon" />
      </div>
    )}
  </div>
);

export default FileUploadItem;
