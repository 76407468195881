import { FormikErrors, FormikTouched } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';

import { Label, MaskInput } from '../../../../../shared';
import PlaidButton from '../../../../../shared/components/PlaidButton/PlaidButton';
import PlaidDescription from '../../../../../shared/components/PlaidButton/PlaidDescription';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { FormData } from '../../MultiStepForm/MultiStepForm';

type DataType = {
  routingNumber: number;
  accountNumber: number;
};

type FormicDataTypes = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  errors: FormikErrors<DataType>;
  values: Pick<FormData, 'routingNumber' | 'accountNumber' | 'address' | 'ein'>;
  touched: FormikTouched<DataType>;
  next: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  companyAddres: string;
};

export default function ConnectBankAccForm({
  handleChange,
  handleBlur,
  validateField,
  errors,
  touched,
  values,
  loading,
  next,
  setLoading,
  companyAddres,
}: FormicDataTypes) {
  return (
    <FormWrapper
      title="Connect a bank account"
      description="Enter your businesses bank account information. This is where we'll direct payments."
    >
      <div className="connectBankAccForm">
        <div className="connectBankAccForm__input-group">
          <Label forId="routingNumber">Bank Routing Number</Label>
          <MaskInput
            id="routingNumber"
            mask="999999999"
            value={values.routingNumber}
            inputClassName="input input-group__input"
            placeholder="000000000"
            onChange={handleChange}
            onBlur={(e) => handleBlur(e)}
            error={Boolean(errors.routingNumber) && touched.routingNumber && errors.routingNumber}
          />
        </div>
        <div className="connectBankAccForm__input-group">
          <Label forId="accountNumber">Bank Account Number</Label>
          <MaskInput
            id="accountNumber"
            mask="99999999999999999"
            value={values.accountNumber}
            inputClassName="input input-group__input"
            placeholder="000000000000"
            onChange={handleChange}
            onBlur={(e) => handleBlur(e)}
            error={Boolean(errors.accountNumber) && touched.accountNumber && errors.accountNumber}
          />
        </div>
        <span className="connectBankAccForm__divider">or</span>
        <PlaidButton
          className="connectBankAccForm__plaid-button button button--secondary-blue button--lg"
          address={values.address}
          ein={values.ein.replace(/\D/g, '')}
          loading={loading}
          next={next}
          setLoading={setLoading}
          companyAddres={companyAddres}
        />
        <PlaidDescription />
      </div>
    </FormWrapper>
  );
}
