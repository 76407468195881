import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import { setFinishedSetup } from '../../../auth/store';
import { getPartner } from '../../../auth/store/actions';
import LastStepScreen from '../../assets/LastStepScreen.png';
import FormWrapper from '../FormWrapper/FormWrapper';

export default function LastStep() {
  const loading = useAppSelector((state) => state.auth.loading);
  const dispatch = useAppDispatch();

  return (
    <FormWrapper title="">
      <div className="init-setup__selectionFlow selectionFlow">
        <div className="init-setup__footer--complete">
          <div className="init-setup__footer--complete__imageBlock">
            <div className="init-setup__footer--complete__imageBlock__text">
              <div className="init-setup__footer--complete__imageBlock__title">
                Your business is registered
              </div>
              <div className="init-setup__footer--complete__imageBlock__subtitle">
                On track to drive community engagement,
                <br />
                one loan at a time.
              </div>
            </div>
            <img src={LastStepScreen} alt="" />
          </div>
          <div className="init-setup__complete-text">
            Now that you&lsquo;ve successfully registered your business, you can access the Quick
            Start guide to add team members, assign roles, integrate and access communications to
            drive awareness and payments.
          </div>
          <Button
            disabled={loading}
            type="button"
            className="button--primary-blue init-setup__button init-setup__button--complete"
            onClick={() => {
              // @ts-ignore
              dispatch(getPartner());
              dispatch(setFinishedSetup(true));
            }}
          >
            Go to dashboard
          </Button>
        </div>
      </div>
    </FormWrapper>
  );
}
