import { LoanStatus } from '../../../shared/models/platform-admin/loans/LoanModel';
import { LoanStage, LoanStageKind } from '../dtos/LoanTypes';

export const getLoanStatus = (status: LoanStatus) => {
  switch (status) {
    case LoanStatus.BINDING:
      return 'Created';
    case LoanStatus.DISBURSEMENT:
      return 'Payment processing';
    case LoanStatus.ACCEPTANCE:
      return 'Pending';
    case LoanStatus.RETRY:
      return 'Transfer issue';
    case LoanStatus.REPAYMENT:
      return 'Active';
    case LoanStatus.DECLINED:
      return 'Declined';
    case LoanStatus.CANCELED:
      return 'Canceled';
    case LoanStatus.FORGIVEN:
      return 'Forgiven';
    case LoanStatus.PAID_OFF:
      return 'Paid off';
    case LoanStatus.DEACTIVATED:
      return 'Deactivated';
    case LoanStatus.GRACE_PERIOD:
      return 'Repayment late';
    default:
      return status;
  }
};

export const getLoanStatusFilter = (status: LoanStatus) => {
  switch (status) {
    case LoanStatus.BINDING:
      return LoanStatus.BINDING;
    case LoanStatus.DISBURSEMENT:
      return 'custom/payment_processing';
    case LoanStatus.ACCEPTANCE:
      return LoanStatus.ACCEPTANCE;
    case LoanStatus.RETRY:
      return 'custom/transfer_issue';
    case LoanStatus.REPAYMENT:
      return LoanStatus.REPAYMENT;
    case LoanStatus.DECLINED:
      return 'closed/rejected';
    case LoanStatus.CANCELED:
      return `closed/${LoanStatus.CANCELED}`;
    case LoanStatus.FORGIVEN:
      return `closed/${LoanStatus.FORGIVEN}`;
    case LoanStatus.PAID_OFF:
      return 'closed/paid_out';
    case LoanStatus.DEACTIVATED:
      return `closed/${LoanStatus.DEACTIVATED}`;
    case LoanStatus.GRACE_PERIOD:
      return 'repayment/grace_period';
    default:
      return status;
  }
};

export const getLoanStatusFromStage = (stage: LoanStage) => {
  switch (stage.kind) {
    case LoanStageKind.binding:
      return 'Created';
    case LoanStageKind.acceptance:
      if (stage.awaitingTransferId || stage.awaitingConvenienceTransferId) {
        return 'Payment processing';
      }
      if (stage.failedTransferId || stage.failedConvenienceTransferId) {
        return 'Transfer issue';
      }
      return 'Pending';
    case LoanStageKind.disbursement:
      if (stage.awaitingTransferId || stage.awaitingConvenienceTransferId) {
        return 'Payment processing';
      }
      if (stage.failedTransferId || stage.failedConvenienceTransferId) {
        return 'Transfer issue';
      }
      return 'Payment processing';
    case LoanStageKind.repayment:
      if (stage?.gracePeriod?.enabled) {
        return 'Repayment late';
      }
      return 'Active';
    case LoanStageKind.closed:
      if (stage.closureType === 'forgiven') {
        return 'Forgiven';
      }
      if (stage.closureType === 'paid_out') {
        return 'Paid off';
      }
      if (stage.closureType === 'deactivated') {
        return 'Deactivated';
      }
      if (stage.closureType === 'rejected') {
        return 'Declined';
      }
      return 'Canceled';
    default:
      return stage;
  }
};
