import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import { intercomAPI } from '../../../../shared/services/intercom';
import { setShowIntercom } from '../../../dashboard/store/dashboardSlice';

interface EmptyStateProps {
  isEmpty: boolean;
}
export default function EmptyState({ isEmpty }: EmptyStateProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showIntercom } = useAppSelector((state) => state.dashboard);
  const iconName = isEmpty ? 'squares' : 'warning-triangle';
  const text = isEmpty
    ? 'This table will automatically update once you create a loan.'
    : 'There are no available records to display for the selected date range.';

  const handleCreateLoan = () => {
    history.push('/loan-creation');
  };

  const handleGetHelp = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };
  return (
    <div className="loans-overview__empty-state">
      <Icon name={iconName} />
      <div className="loans-overview__empty-state__title">No data to show</div>
      <div className="loans-overview__empty-state__text">{text}</div>
      {isEmpty && (
        <div style={{ marginTop: 24 }}>
          <button
            style={{ width: 140, height: 36, borderRadius: 8, marginRight: 12 }}
            type="button"
            className="button button--lg button--primary-blue"
            onClick={handleCreateLoan}
          >
            Create loan
          </button>
          <button
            style={{ width: 140, height: 36, borderRadius: 8 }}
            type="button"
            className="button button--secondary-blue button--lg"
            onClick={handleGetHelp}
          >
            Get help
          </button>
        </div>
      )}
    </div>
  );
}
