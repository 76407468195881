import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { DateRangeFilter, DateRangeTuple, Search, Select } from '../../../../shared';
import {
  setTableSearchQuery,
  setTableDateFilter,
  setTableStageFilter,
  setTableDateRangeSettings,
} from '../../store';
import { selectLoansState } from '../../store/selectors/loans';

const loanStatus = [
  { name: 'All', dataId: '' },
  { name: 'Binding', dataId: 'binding' },
  { name: 'Acceptance', dataId: 'acceptance' },
  { name: 'Disbursement', dataId: 'disbursement' },
  { name: 'Repayment', dataId: 'repayment' },
  { name: 'Closed / rejected', dataId: 'closed/rejected' },
  { name: 'Closed / cancelled', dataId: 'closed/cancelled' },
  { name: 'Closed / forgiven', dataId: 'closed/forgiven' },
  { name: 'Closed / paid out', dataId: 'closed/paid_out' },
  { name: 'Closed / deactivated', dataId: 'closed/deactivated' },
];

export const LoansTableControls: FC = () => {
  const dispatch = useAppDispatch();
  const { searchQuery, dateRangeFilter, isReset, stage, dateRangeSettings } =
    useAppSelector(selectLoansState);

  const onSearchChange = (val: string) => {
    dispatch(setTableSearchQuery({ query: val, tableName: 'loans' }));
  };

  const onDateFilterChange = (val: DateRangeTuple) => {
    dispatch(setTableDateFilter({ range: val, tableName: 'loans' }));
  };

  const handleLoanStatusChange = (val: string) => {
    dispatch(setTableStageFilter({ stage: val, tableName: 'loans' }));
  };

  return (
    <div className="table-filter">
      <div className="table-filter__col table-filter__col--search">
        <Search
          id="filter-search"
          classes="table-filter__search"
          placeholder="Search Loans"
          value={searchQuery}
          onChange={onSearchChange}
        />
      </div>

      <div className="table-filter__col">
        <DateRangeFilter
          classes="table-filter__date-filter"
          onChangePeriod={onDateFilterChange}
          dateRangeFilter={dateRangeFilter}
          settings={dateRangeSettings}
          onChangeRangeSettings={(settings) =>
            dispatch(
              setTableDateRangeSettings({
                tableName: 'loans',
                dateRangeSettings: settings,
              }),
            )
          }
        />
      </div>
      <div className="table-filter__col">
        <Select
          classes="table-filter__payment select--no-margin"
          id="filter-payment"
          data={loanStatus}
          placeholder="Loan status"
          value={stage}
          onChange={handleLoanStatusChange}
          isReset={isReset}
        />
      </div>
    </div>
  );
};
