import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '../../../shared';

interface MobileMenuItemProps {
  linkTo: string;
  title: string;
  iconName: string;
  exact?: boolean;
  external?: boolean;
}

export const MobileMenuItem: FC<MobileMenuItemProps> = ({
  iconName,
  title,
  linkTo,
  exact = true,
  external,
}) => (
  <li className="mobile-menu__nav-item">
    {!external ? (
      <NavLink
        to={linkTo}
        className="mobile-menu__nav-link"
        activeClassName="mobile-menu__nav-link--active"
        exact={exact}
      >
        <Icon name={iconName} classes="mobile-menu__nav-icon" />
        {title}
      </NavLink>
    ) : (
      <a href={linkTo} className="mobile-menu__nav-link" target="_blank" rel="noreferrer">
        <Icon name={iconName} classes="mobile-menu__nav-icon" />
        {title}
      </a>
    )}
  </li>
);
