import { endOfDay, startOfDay, addDays, addWeeks, addMonths, lightFormat, format } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import * as yup from 'yup';

import { Icon, Select, useOnClickOutside, Input } from '../index';
import { DateRangeSettings, DateRangeTimeUnits, DateRangeTuple, DateRangeType } from '../types';

type IProps = {
  inputId?: string;
  classes?: string;
  placeholder?: string;
  onChangePeriod: (arg: DateRangeTuple) => void;
  dateRangeFilter?: DateRangeTuple;
  settings?: DateRangeSettings;
  onChangeRangeSettings?: (type: DateRangeSettings) => void;
  disabled?: boolean;
  showDays?: number;
};

type FormType = {
  lastAmount: number;
  cstDateFrom: string;
  cstDateTo: string;
};

const specificValidationSchema = yup.object({
  lastAmount: yup.number().positive().required(),
  cstDateFrom: yup
    .mixed()
    .test((value) => {
      if (value) {
        const [month, day, year] = value.split(' ').join('/').split('/');
        return (
          Number(month) >= 1 &&
          Number(month) <= 12 &&
          Number(day) >= 1 &&
          Number(day) <= 31 &&
          Number(year) <= new Date().getFullYear() &&
          Number(year) >= 1800
        );
      }
      return false;
    })
    .label('Payment date from')
    .required(),
  cstDateTo: yup
    .mixed()
    .test((value) => {
      if (value) {
        const [month, day, year] = value.split(' ').join('/').split('/');
        return (
          Number(month) >= 1 &&
          Number(month) <= 12 &&
          Number(day) >= 1 &&
          Number(day) <= 31 &&
          Number(year) <= new Date().getFullYear() &&
          Number(year) >= 1800
        );
      }
      return false;
    })
    .label('Payment date from')
    .required(),
});

const relativeValidationSchema = yup.object({
  lastAmount: yup.number().positive().required(),
});
interface SendData {
  startDate: Date;
  endDate: Date;
  lastAmount: number;
  range: DateRangeTimeUnits;
}

const DateRangeFilter: React.FC<IProps> = ({
  inputId = 'DateRangeFilter',
  classes = '',
  onChangePeriod,
  dateRangeFilter,
  settings,
  onChangeRangeSettings,
  disabled = false,
  placeholder,
  showDays = 365,
}) => {
  const btnRef = useRef<HTMLButtonElement>();
  const [opened, setOpened] = useState(false);
  const datepickerRef = React.createRef<HTMLInputElement>();
  const [schema, setSchema] = useState(relativeValidationSchema);
  const [isSelectRange, setIsSelectRange] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>();
  const [typeRange, setTypeRange] = useState<DateRangeType>(DateRangeType.relative);
  const [range, setRange] = useState<DateRangeTimeUnits>(DateRangeTimeUnits.days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(addDays(new Date(), 0));
  const [maxDate, setMaxDate] = useState<Date>(addDays(new Date(), -1));
  const [lastSendData, setLastSendData] = useState<SendData | null>(null);

  const setCurrentRange = (start: Date, end: Date) => {
    const startMonth = format(start, 'MMM');
    const endMonth = format(end, 'MMM');
    const startDay = lightFormat(start, 'd');
    const endDay = lightFormat(end, 'd');
    const startYear = lightFormat(start, 'y');
    const endYear = lightFormat(end, 'y');

    const compareMonths = (start: string, end: string) => (start !== end ? `${end} ` : '');
    const compareYears = (start: string, end: string) => (start !== end ? `, ${start}` : '');
    const getEndDate =
      !compareMonths(startMonth, endMonth) && startDay === endDay ? '' : `${endDay},`;
    const isOneDay = startMonth === endMonth && startYear === endYear && startDay === endDay;
    const result = isOneDay
      ? `${startMonth} ${startDay}, ${startYear}`
      : `${startMonth} ${startDay}${compareYears(startYear, endYear)} - ${compareMonths(
          startMonth,
          endMonth,
        )}${getEndDate} ${endYear}`;
    setCurrentValue(result);
  };

  const setDateValue = (start: Date, end: Date) => {
    setMinDate(start);
    setStartDate(start);
    setMaxDate(end);
    setEndDate(end);
  };

  const { errors, values, handleSubmit, setFieldValue } = useFormik<FormType>({
    initialValues: {
      lastAmount: showDays,
      cstDateFrom: '',
      cstDateTo: '',
    },
    onSubmit: () => {
      if (typeRange === DateRangeType.relative) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const [start, end] = getRangeDays();
        setDateValue(start, end);

        if (start && end) {
          setCurrentRange(start, end);
          setOpened(false);
          setLastSendData({ startDate: start, endDate: end, lastAmount: values.lastAmount, range });
          onChangePeriod([startOfDay(start).toISOString(), endOfDay(end).toISOString()]);
        }
      }

      if (typeRange === DateRangeType.specific && startDate && endDate) {
        setCurrentRange(startDate, endDate);
        setOpened(false);
        setLastSendData({ startDate, endDate, lastAmount: values.lastAmount, range });
        onChangePeriod([startOfDay(startDate).toISOString(), endOfDay(endDate).toISOString()]);
      }

      if (onChangeRangeSettings) {
        onChangeRangeSettings({
          type: typeRange,
          value: Number(values.lastAmount),
          timeUnits: range,
        });
      }
    },
    validationSchema: schema,
  });

  useOnClickOutside(datepickerRef, () => {
    setOpened(false);
  });

  const setFocus = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.focus();
    }
  };

  const getRangeDays = (value = '') => {
    const amount = +value || values.lastAmount;
    let start = new Date();
    const end = endOfDay(new Date());

    if (range === DateRangeTimeUnits.days) {
      start = addDays(new Date(), -(amount - 1));
    }

    if (range === DateRangeTimeUnits.weeks) {
      start = addWeeks(new Date(), -amount);
    }

    if (range === DateRangeTimeUnits.months) {
      start = addMonths(new Date(), -amount);
    }

    return [start, end];
  };

  const handleChangeDateFilter = (value: DateRangeType, date?: DateRangeTuple) => {
    setTypeRange(value);

    if (value === DateRangeType.specific) {
      setIsSelectRange(true);
      setMaxDate(endOfDay(new Date()));
      setMinDate(null);
      setSchema(specificValidationSchema);

      if (startDate && endDate) {
        setFieldValue('cstDateFrom', lightFormat(startDate, 'MM dd yyyy'));
        setFieldValue('cstDateTo', lightFormat(endDate, 'MM dd yyyy'));
      }

      if (date) {
        const [start, end] = date;
        setFieldValue('cstDateFrom', lightFormat(new Date(start), 'MM dd yyyy'));
        setFieldValue('cstDateTo', lightFormat(new Date(end), 'MM dd yyyy'));
      }
    }

    if (value === DateRangeType.relative) {
      setIsSelectRange(false);
      setMaxDate(addDays(new Date(), -1));
      setMinDate(addDays(new Date(), 0));
      setSchema(relativeValidationSchema);
    }
  };

  const onChangeDatePicker = (dates: Date[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setFieldValue('cstDateFrom', lightFormat(start, 'MM dd yyyy'));
      setFieldValue('cstDateTo', lightFormat(end, 'MM dd yyyy'));
      setFocus();
    }
  };

  const handleChangeDateFields = (name: string, value: string) => {
    const isValidDate = Boolean(value.split('/').join('').trim());
    setFieldValue(name, value);

    if (name && isValidDate) {
      let cstDateFrom;
      let cstDateTo;

      if (name === 'cstDateFrom') {
        cstDateFrom = value;
        cstDateTo = values.cstDateTo;
      }

      if (name === 'cstDateTo') {
        cstDateTo = value;
        cstDateFrom = values.cstDateFrom;
      }

      if (cstDateFrom && cstDateTo) {
        const start = new Date(cstDateFrom.split(' ').join('/'));
        const end = new Date(cstDateTo.split(' ').join('/'));
        setMinDate(start);

        setTimeout(() => {
          setMinDate(null);
        });

        if (start <= end) {
          setStartDate(start);
          setEndDate(end);
        }
      }
    }
  };

  const handleReset = () => {
    if (lastSendData) {
      const { startDate, endDate, range, lastAmount } = lastSendData;
      setStartDate(startDate);
      setEndDate(endDate);
      setRange(range);
      setFieldValue('lastAmount', lastAmount);
      setFieldValue('cstDateFrom', lightFormat(startDate, 'MM dd yyyy'));
      setFieldValue('cstDateTo', lightFormat(endDate, 'MM dd yyyy'));
    }
    setOpened(false);
  };

  const handleChangeRelativeValue = (value: string) => {
    if (Number(value) <= 1000) {
      setFieldValue('lastAmount', value);
      const [start, end] = getRangeDays(value);
      setDateValue(start, end);
    }
  };

  useEffect(() => {
    const [start, end] = getRangeDays();
    setDateValue(start, end);
  }, [range]);

  useEffect(() => {
    if (dateRangeFilter && dateRangeFilter.length) {
      const [start, end] = dateRangeFilter;
      setDateValue(new Date(start), new Date(end));
      setCurrentRange(new Date(start), new Date(end));

      if (settings) {
        handleChangeDateFilter(settings.type, dateRangeFilter);

        if (settings.type === DateRangeType.relative) {
          setRange(settings.timeUnits);
          setFieldValue('lastAmount', settings.value);
        }
      }
    } else if (!disabled) {
      setCurrentRange(addDays(new Date(), -showDays), endOfDay(new Date()));
    }
  }, [dateRangeFilter, settings]);

  const dateRange = [
    { name: 'Relative dates', dataId: DateRangeType.relative },
    { name: 'Specific dates', dataId: DateRangeType.specific },
  ];

  const timeUnits = [
    { name: 'Days', dataId: DateRangeTimeUnits.days },
    { name: 'Weeks', dataId: DateRangeTimeUnits.weeks },
    { name: 'Months', dataId: DateRangeTimeUnits.months },
  ];

  return (
    <div
      className={`datepicker datepicker--date-filter datepicker--no-margin ${classes}${
        opened ? ' datepicker--opened' : ''
      }`}
      ref={datepickerRef}
    >
      <form onSubmit={handleSubmit}>
        <div className="datepicker__box">
          {disabled ? (
            <p style={{ color: '#8592AD' }} className="datepicker__date-input-prefix">
              {placeholder}
            </p>
          ) : (
            <p className="datepicker__date-input-prefix">Show:</p>
          )}

          <input
            type="text"
            className={`input datepicker__input ${disabled ? 'datepicker__input--disabled' : ''}`}
            value={currentValue}
            name={inputId}
            id={inputId}
            readOnly
            disabled={disabled}
            onClick={() => {
              setOpened(!opened);
            }}
          />
          <div className={`datepicker__chevron ${disabled ? 'datepicker__input--disabled' : ''}`}>
            <Icon name="chevron" classes="datepicker__chevron-icon" />
          </div>
        </div>

        <div className="datepicker__options">
          <div className="datepicker__calendar">
            <DatePicker
              previousMonthButtonLabel={<Icon name="left-arrow" />}
              nextMonthButtonLabel={<Icon name="right-arrow" />}
              selected={startDate}
              // @ts-ignore
              onChange={onChangeDatePicker}
              startDate={startDate}
              endDate={endDate}
              selectsRange={isSelectRange}
              minDate={minDate}
              maxDate={maxDate}
              monthsShown={2}
              inline
              showPreviousMonths
              shouldCloseOnSelect={false}
              focusSelectedMonth
            />
          </div>

          <div className="datepicker__option-section">
            <div className="form-group">
              <label htmlFor="dateRange" className="label">
                date range
              </label>
              <Select
                title="Date range"
                id="dateRange"
                classes="select--date-range"
                value={typeRange}
                data={dateRange}
                onChange={(value) => handleChangeDateFilter(value)}
              />
            </div>
            {typeRange === 'relative' && (
              <div className="datepicker__relative">
                <span className="datepicker__relative-txt">Last</span>
                <Input
                  type="number"
                  id="relative-value"
                  classes="input-group--no-margin input-group--sm"
                  value={values.lastAmount}
                  onChange={(e) => {
                    handleChangeRelativeValue(e.target.value);
                  }}
                  onBlur={setFocus}
                  max={1000}
                  isHideNumControls
                />
                <Select
                  title="Time units"
                  id="timeUnits"
                  classes="select--relative-dates"
                  value={range}
                  data={timeUnits}
                  onChange={(value) => {
                    setRange(value);
                    setFocus();
                  }}
                />
              </div>
            )}

            {typeRange === 'specific' && (
              <div className="datepicker__date-range-form">
                <div className="datepicker__date-range-item">
                  <label htmlFor="dateRange" className="label">
                    start
                  </label>
                  <InputMask
                    mask="99\/99\/9999"
                    // @ts-ignore
                    maskChar=" "
                    className="input datepicker__date-range-input"
                    placeholder="mm/dd/yyyy"
                    value={values.cstDateFrom}
                    onChange={(e) => handleChangeDateFields('cstDateFrom', e.target.value)}
                  />
                </div>
                <div className="datepicker__date-range-separator">to</div>
                <div className="datepicker__date-range-item">
                  <label htmlFor="dateRange" className="label">
                    end
                  </label>
                  <InputMask
                    mask="99\/99\/9999"
                    // @ts-ignore
                    maskChar=" "
                    className="input datepicker__date-range-input"
                    placeholder="mm/dd/yyyy"
                    value={values.cstDateTo}
                    onChange={(e) => handleChangeDateFields('cstDateTo', e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="datepicker__controls">
              <button
                type="button"
                className="datepicker__btn datepicker__btn--cancel"
                onClick={handleReset}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="datepicker__btn datepicker__btn--apply"
                disabled={
                  Boolean(errors.lastAmount) ||
                  Boolean(errors.cstDateFrom) ||
                  Boolean(errors.cstDateTo)
                }
                // @ts-ignore
                ref={btnRef}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DateRangeFilter;
