import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { RefundInfo } from '../../../../shared';
import { PartnerMemberDto } from '../../../../shared/models';
import { parseError } from '../../../../shared/utils/helpers';
import { setModalRefundedOpen } from '../index';

import type { AppDispatch, RootState } from '../../../../main/store';

export const setModalSubmittedOpen = createAsyncThunk<
  boolean,
  RefundInfo,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('dashboard/setModalRefundedOpen', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const {
      submittedBy,
      customerName,
      billingAccountNumber,
      transactionId,
      amount,
      paymentDate,
      reason,
      paymentId,
    } = payload;
    const response = await api.get(`/members/${submittedBy}`);
    const { firstName, lastName, contacts } = response.data as PartnerMemberDto;
    const email = contacts && contacts.find((i) => i.includes('mailto:'))?.replace('mailto:', '');

    const submittedName = firstName && lastName ? `${firstName} ${lastName}` : email || submittedBy;

    dispatch(
      setModalRefundedOpen({
        open: true,
        rInfo: {
          submittedBy: submittedName,
          customerName,
          billingAccountNumber,
          transactionId,
          amount,
          paymentDate,
          reason,
          paymentId,
        },
      }),
    );
    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
