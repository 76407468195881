import { FlowTypes } from '../../features/auth/store';
import { COMPANY_SIZE, Industry } from '../../features/sign-up/constants';

export type PartnerDto = {
  uuid: string;
  version: number;
  details: PartnerDetailsDto;
};

export type PartnerIconsDto = {
  lowRes: string;
  highRes: string;
  original: string;
};

export type PartnerDetailsDto = {
  name: string;
  emailUri: string;
  phoneUri: string;
  urlFriendlyName?: string;
  icons?: PartnerIconsDto;
  formationDate?: string;
  businessType?: string;
  industry?: string;
  postalAddress?: string;
  billingAddress?: string;
  clientId?: string;
  flowType?: keyof typeof FlowTypes | '';
  businessSize?: keyof typeof COMPANY_SIZE | '';
  ein: string;
};

export type Partner = PartnerDto;

export type ClientId = { clientId: string };

export class PartnerModel {
  public static fromDTO(dto: PartnerDto): Partner {
    return {
      ...dto,
    };
  }
}
