import cn from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input } from '../../../../shared';
import { FormWrapper } from '../../../init-setup/components';
import { TLoanBusinessType, setLoanBusinessType } from '../../store';

const businessTypes = [
  { id: 0, title: 'Administration', image: 'administration' },
  { id: 1, title: 'Agriculture, Farming', image: 'agriculture' },
  { id: 2, title: 'Arts, Entertainment and Recreation', image: 'artsEntertainment' },
  { id: 3, title: 'Construction', image: 'construction' },
  { id: 4, title: 'Educational Services', image: 'educationalServices' },
  { id: 5, title: 'Finance or Insurance', image: 'financeOrInsurance' },
  { id: 6, title: 'Healthcare and Social Assistance', image: 'healthcareAndSocialAssistance' },
  { id: 7, title: 'Information, Technology', image: 'informationTechnology' },
  { id: 8, title: 'Manufacturing, Wholesale', image: 'manufacturingWholesale' },
  { id: 9, title: 'Scientific and Technical Services', image: 'scientificAndTechnicalServices' },
  { id: 10, title: 'Real Estate, Rental and Leasing', image: 'realEstate' },
  { id: 11, title: 'Retail Trade', image: 'realTrade' },
  { id: 12, title: 'Transportation and Warehousing', image: 'transportationAndWarehousing' },
  { id: 13, title: 'Other', image: 'other' },
];

type FormData = {
  loanBusinessType: string;
};

type FormikDataTypes = {
  currentStepIndex: number;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldError: (field: string, message: string) => void;
  setFieldValue: (
    field: string,
    value: null | string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

const BusinessTypeSelectItem = ({
  type,
  isSelected,
  isFocused,
  onSelect,
  onRef,
}: {
  type: TLoanBusinessType;
  isSelected: boolean;
  isFocused: boolean;
  onSelect: () => void;
  onRef: (el: HTMLButtonElement | null) => void;
}) => (
  <button
    type="button"
    ref={onRef}
    className={cn(
      'loans__selectLoanTypeBusinessForm__selectItem',
      isSelected && 'loans__selectLoanTypeBusinessForm__selectItem--active',
      isFocused && 'loans__selectLoanTypeBusinessForm__selectItem--focused',
    )}
    onClick={onSelect}
    tabIndex={0}
  >
    <div className="loans__selectLoanTypeBusinessForm__selectItem__left">
      <div className="loans__selectLoanTypeBusinessForm__selectItem__imageBlock">
        <Icon name={type.image} />
      </div>
      <div className="loans__selectLoanTypeBusinessForm__selectItem__title">{type.title}</div>
    </div>
    <div className="loans__selectLoanTypeBusinessForm__selectItem__dot" />
  </button>
);

export default function SelectBusinessTypeForm({
  currentStepIndex,
  errors,
  setFieldError,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormikDataTypes) {
  const { loanBusinessType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<TLoanBusinessType | undefined>();
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [keyboardNav, setKeyboardNav] = useState<boolean>(false);
  const businessTypeRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleChangeType = (selectedBusiness: TLoanBusinessType, viaKeyboard = false) => {
    if (selectedBusiness.title !== 'Other') {
      setFieldValue('loanBusinessType', selectedBusiness.title);
    } else {
      setFieldValue('loanBusinessType', 'other-custom');
      setFieldTouched('loanBusinessType', false);
    }
    dispatch(setLoanBusinessType(selectedBusiness));
    setValue(selectedBusiness);
    if (viaKeyboard) {
      setFocusedIndex(businessTypes.findIndex((rt) => rt.id === selectedBusiness.id));
      setKeyboardNav(true);
    } else {
      setFocusedIndex(null);
      setKeyboardNav(false);
    }
  };

  const handleBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    setFieldTouched('loanBusinessType', true);
    if (values.loanBusinessType === 'other-custom') {
      setFieldError('loanBusinessType', 'Please select a business type');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      const newIndex = focusedIndex !== null ? (focusedIndex + 1) % businessTypes.length : 0;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      const newIndex =
        focusedIndex !== null
          ? (focusedIndex - 1 + businessTypes.length) % businessTypes.length
          : businessTypes.length - 1;
      setFocusedIndex(newIndex);
      setKeyboardNav(true);
    } else if (event.key === 'Enter' && focusedIndex !== null) {
      handleChangeType(businessTypes[focusedIndex], true);
    }
  };

  useEffect(() => {
    if (keyboardNav && focusedIndex !== null) {
      businessTypeRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex, keyboardNav]);

  useEffect(() => {
    if (loanBusinessType) {
      setValue(loanBusinessType);
    }
  }, [currentStepIndex, loanBusinessType]);

  return (
    <FormWrapper>
      {/* eslint-disable-next-line */}
      <div className="loans__selectLoanTypeBusinessForm" onKeyDown={handleKeyDown} tabIndex={-1}>
        <div className="loans__selectLoanTypeBusinessForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">What best describes the business type?</h2>
            <div className="loanTypeForm__subtitle">Select one option that applies</div>
          </div>
          <div className="loans__selectLoanTypeBusinessForm__body">
            <div className="loans__selectLoanTypeBusinessForm__selectItems">
              {businessTypes.map((type, index) => (
                <BusinessTypeSelectItem
                  key={type.id}
                  type={type}
                  isSelected={value?.id === type.id}
                  isFocused={focusedIndex === index}
                  onSelect={() => handleChangeType(type)}
                  // eslint-disable-next-line
                  onRef={(el) => (businessTypeRefs.current[index] = el)}
                />
              ))}
            </div>
            {value?.title === 'Other' && (
              <div className="loans__selectLoanTypeBusinessForm__input">
                <Input
                  type="text"
                  id="loanBusinessType"
                  maxLength={50}
                  placeholder="Please specify the reason"
                  value={values.loanBusinessType === 'other-custom' ? '' : values.loanBusinessType}
                  onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('loanBusinessType', true);
                    setFieldValue(target.id, target.value);
                  }}
                  onBlur={handleBlurCustom}
                  error={
                    Boolean(errors.loanBusinessType) &&
                    touched.loanBusinessType &&
                    'Please enter the reason'
                  }
                  showAttentionErrorIcon={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
