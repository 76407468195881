import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { FlagProvider } from '@unleash/proxy-client-react';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './scss/main.scss';

import App from './App';
import { CURRENT_ENV, SENTRY_DSN, UNLEASH_URL, UNLEASH_API_TOKEN } from './main/env';
import { injectStoreIntoAxios } from './main/network';
import { store } from './main/store';
import reportWebVitals from './reportWebVitals';
import { ModalSuccess } from './shared';

injectStoreIntoAxios(store);

export const browserHistory = createBrowserHistory();

Sentry.init({
  dsn: SENTRY_DSN(),
  integrations: [new Integrations.BrowserTracing()],

  environment: CURRENT_ENV(),

  tracesSampleRate: 1.0,
});

const config = {
  url: UNLEASH_URL(), // The URL of your Unleash Proxy
  clientKey: UNLEASH_API_TOKEN(), // The client key for your application
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: 'Zirtue Web Portal', // The name of your application
};

ReactDOM.render(
  <React.StrictMode>
    <FlagProvider config={config}>
      <Provider store={store}>
        <Router history={browserHistory}>
          <App />
          <ModalSuccess />
        </Router>
      </Provider>
    </FlagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
