import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AuthTokenMfaCreateDto, ServerError } from '../../../../shared';
import {
  AuthTokenTokenMfaCreateResponseDto,
  AuthTokenTokenMfaCreate,
  AuthTokenTokenMfaCreateModel,
} from '../../../../shared/models/AuthModel';

import type { AppDispatch, RootState } from '../../../../main/store';

export const startLogin = createAsyncThunk<
  AuthTokenTokenMfaCreate,
  AuthTokenMfaCreateDto,
  { dispatch: AppDispatch; rejectValue: ServerError; state: RootState }
>('auth/login', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<AuthTokenTokenMfaCreateResponseDto>('/auth/token/mfa', payload);
    return AuthTokenTokenMfaCreateModel.fromDTO(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
