import { format } from 'date-fns';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon } from '../../../../shared';
import { intercomAPI } from '../../../../shared/services/intercom';
import { setShowIntercom } from '../../../dashboard/store/dashboardSlice';

interface CardSectionProps {
  accountNumber: string;
  deactivatedDate?: string;
}
export default function CardSection({ accountNumber, deactivatedDate }: CardSectionProps) {
  const dispatch = useAppDispatch();
  const { showIntercom } = useAppSelector((state) => state.dashboard);

  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };
  return (
    <>
      <div className="loan__bank-card-primary-section">
        {deactivatedDate ? (
          <div className="loan__bank-card-deactivated-section">
            Current loan status <span>Deactivated</span>
          </div>
        ) : (
          <div className="loan__bank-card-secondary-section">
            <Icon name="bank-card" />
            <div className="loan__bank-card-secondary-section__content">
              <span>Bank Account</span>
              <span>
                Ending in&nbsp;
                {accountNumber}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="loan__bank-card-primary-section-footer">
        <Icon name="blue-lamp" />
        {deactivatedDate ? (
          <div>
            {`This loan has been deactivated on ${format(
              new Date(deactivatedDate),
              'MMMM dd, yyyy',
            )}.`}
          </div>
        ) : (
          <div>
            Need help? Reach out to our &nbsp;
            <span role="button" tabIndex={0} onClick={clickSupport} onKeyDown={clickSupport}>
              Support Team
            </span>
          </div>
        )}
      </div>
    </>
  );
}
