import React, { useRef } from 'react';
import Popup from 'reactjs-popup';

import { useAppDispatch } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { CompanyRole } from '../../../shared/models';
import { setModalConfirmOpen, addAlert } from '../../../shared/store/modals';
import { setModalRoleOpen } from '../store';
import { getCompanyRoles, delCompanyRole } from '../store/actions';

interface IProps {
  children: JSX.Element;
  role: CompanyRole;
}

const QuickRoleActions: React.FC<IProps> = ({ children, role }: IProps) => {
  const dispatch = useAppDispatch();
  const { uuid } = role;
  const modalConfirmTitle = 'Delete role?';
  const modalConfirmText =
    'You can’t undo this action. If you delete this role, it will affect all members assigned to the role.';
  const modalConfirmOkText = 'Yes, delete this role';
  const modalConfirmCancelText = 'No, don’t delete this role';
  const popupRef = useRef(null);

  const confirmDelRole = () => {
    dispatch(delCompanyRole({ uuid }));
    setTimeout(() => dispatch(getCompanyRoles()), 3000);
  };

  const handleClose = () => {
    if (popupRef) {
      // @ts-ignore
      popupRef.current.close();
    }
  };

  return (
    <div className="quick-actions">
      <Popup
        trigger={children}
        position="top right"
        on="click"
        closeOnDocumentClick
        arrow={false}
        ref={popupRef}
      >
        <ul className="quick-actions__menu" onClick={handleClose} role="presentation">
          <li className="quick-actions__menu-item">
            <a
              href="/"
              className="quick-actions__menu-link quick-actions__menu-link--blue"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setModalRoleOpen({ isOpen: true, type: 'edit', role }));
              }}
            >
              <Icon name="pencil" classes="quick-actions__menu-icon" />
              Edit role
            </a>
          </li>
          <li className="quick-actions__menu-item">
            <a
              href="/"
              className="quick-actions__menu-link quick-actions__menu-link--red"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                e.preventDefault();
                dispatch(addAlert({ text: 'Role successfully deleted' }));
                dispatch(
                  setModalConfirmOpen({
                    opened: true,
                    title: modalConfirmTitle,
                    text: modalConfirmText,
                    okText: modalConfirmOkText,
                    cancelText: modalConfirmCancelText,
                    onConfirm: () => confirmDelRole(),
                  }),
                );
              }}
            >
              <Icon name="trash" classes="quick-actions__menu-icon" />
              Delete role
            </a>
          </li>
        </ul>
      </Popup>
    </div>
  );
};

export default QuickRoleActions;
