import { last } from 'lodash';

import { LoanClosureType, LoanStage, LoanStageKind } from '../dtos/LoanTypes';
import { RepaymentPlanPayment } from '../dtos/RepaymentPlan';
import { PaymentHistoricalEntryType, PaymentStatuses } from '../dtos/RepaymentPlanDto';

export const prepareRepaymentPlanNew = (stage: LoanStage, payments: RepaymentPlanPayment[]) => {
  const isLoanWithFailedAttempts = stage.kind === LoanStageKind.repayment;

  return payments.map((p, index, array) => {
    let status: PaymentStatuses;
    const skippedExists =
      p.history.filter((item) => item.type === PaymentHistoricalEntryType.skippedOn).length > 0;

    const failedAttemptsExists =
      isLoanWithFailedAttempts &&
      p.history.some((item) => item.type === PaymentHistoricalEntryType.failedOn);

    const lastPaymentHistory = last(p.history);
    if (lastPaymentHistory?.type === PaymentHistoricalEntryType.succeededOn) {
      status = PaymentStatuses.completed;
    } else if (lastPaymentHistory?.type === PaymentHistoricalEntryType.failedOn || p.failed) {
      status = PaymentStatuses.failed;
    } else if (lastPaymentHistory?.type === PaymentHistoricalEntryType.executedOn) {
      status = PaymentStatuses.inProgress;
    } else if (skippedExists || failedAttemptsExists) {
      status = PaymentStatuses.missed;
    } else if (
      p.plannedExecutionOn ||
      (index > 0 &&
        array[index - 1].history.some((h) => h.type === PaymentHistoricalEntryType.failedOn) &&
        !(stage.kind === LoanStageKind.closed && stage.closureType === LoanClosureType.forgiven))
    ) {
      status = PaymentStatuses.autoPay;
    } else {
      status = PaymentStatuses.autoPay;
    }
    return { status, payment: p };
  });
};
