import { useFormik } from 'formik';
import React, { useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch } from '../../../main/store/hooks';
import { Input, Label, Modal, ModalConfirm } from '../../../shared';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { setModalConfirmOpen } from '../../../shared/store/modals';
import { validatePasswordStrength } from '../../../shared/utils/validation';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { clearAuthState, setEmail } from '../store';
import { acceptInvitation } from '../store/actions';

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8)
    .test('is-strong', 'Password is not strong', (passVal) => {
      const [isValid, _] = validatePasswordStrength(passVal || '');
      return !!isValid;
    })
    .required(),
});

export default function SignUp() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const inviteToken = location.pathname.replace('/signup/', '');

  const params = new URL(String(window.location)).searchParams;
  const email = params.get('email')?.replace('mailto:', '');

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = useFormik({
    validationSchema,
    initialValues: {
      password: '',
    },
    onSubmit: async ({ password }) => {
      const result = await dispatch(acceptInvitation({ inviteToken, password }));
      dispatch(setEmail(email));

      if (acceptInvitation.fulfilled.match(result)) {
        history.push('/create-account');
      }

      if (acceptInvitation.rejected.match(result) && result.payload) {
        const { title, message: text } = JSON.parse(result.payload);

        dispatch(
          setModalConfirmOpen({
            opened: true,
            title,
            text,
            okText: 'Ok, got it',
            showCancel: false,
            onConfirm: () => history.push('/signin'),
          }),
        );
      }
    },
  });

  const [_, passwordStrengthVector] = useMemo(
    () => validatePasswordStrength(values.password),
    [values.password],
  );

  useEffect(() => {
    localStorage.setItem(StoreKeys.isAuthenticated, 'false');
    dispatch(clearAuthState());
    localStorage.removeItem(StoreKeys.authToken);
  }, []);

  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignUp}
      bannerContent={AuthBannerContent.SignUp}
    >
      <form action="#" className="auth-form auth-form--styled" onSubmit={handleSubmit}>
        <div className="auth-form__block">
          <h1 className="auth-form__welcome">Welcome to Zirtue&#39;s Partner Portal</h1>
          <h2 className="auth-form__title title__line_height">
            Create your Zirtue partner account
          </h2>
          {/* Form group */}
          <div className="form-group">
            <Label forId="email">your work email</Label>
            <Input
              type="email"
              id="email"
              placeholder="Enter your work email"
              onChange={handleChange}
              value={email}
              disabled
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="password">Password</Label>
            <Input
              type="password"
              id="password"
              placeholder="Create a password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              createMode
              passStrengthVector={passwordStrengthVector}
              error={Boolean(errors.password) && touched.password && errors.password}
            />
          </div>
        </div>
        <button
          type="submit"
          className="button button--primary-blue button--lg button--block"
          disabled={!isValid}
        >
          Sign up
        </button>
      </form>
      <ModalConfirm />
    </AuthorizationLayout>
  );
}
