import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch } from '../../../../main/store';
import { ServerError } from '../../../../shared';

export const adminInvitePartner = createAsyncThunk<
  boolean,
  string,
  { dispatch: AppDispatch; rejectValue: ServerError }
>('platformAdmin/adminInvitePartner', async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`/platform-admin/partners/invite`, {
      ownerEmailUri: `mailto:${payload}`,
    });

    return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
