import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { ReactComponent as BankSvg } from '../../../assets/images/dashboard/bank.svg';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Alert, ModalConfirm, ModalSuccess } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { FlowTypes } from '../../auth/store';
import { hasRole, isAdmin } from '../../auth/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { PaymentRow } from '../components';
import { ModalAddPaymentMethod, ModalVerifyPaymentMethod } from '../modals';
import { setModalAddPaymentOpen } from '../store';

export default function Finances() {
  const dispatch = useAppDispatch();
  const { paymentMethods, loading } = useAppSelector((state) => state.finances, shallowEqual);
  const { authToken: authInfo, partner } = useAppSelector((state) => state.auth, shallowEqual);

  const canManage =
    authInfo && (hasRole(authInfo, Permissions.FINANCES_MANAGE) || isAdmin(authInfo));

  const isAddPaymentMethodDisabled =
    partner?.details.flowType === FlowTypes.LOAN_CREATOR && paymentMethods.length >= 1;

  return (
    <DashboardLayout pageTitle="Bank">
      <div className="finances">
        {paymentMethods.length > 0 && (
          <div className="card">
            <div className="card__header-row">
              <div className="card__header-col">
                <h2 className="card__title">Linked bank accounts</h2>
                <p className="card__text">
                  You can manage your linked bank accounts by clicking any of the fields below.
                </p>
              </div>
              <div className="card__header-col">
                {canManage && (
                  <button
                    type="button"
                    className="button button--primary-blue button--sm"
                    onClick={() => {
                      dispatch(setModalAddPaymentOpen(true));
                      Amplitude.logEvent(AmplitudeEvents.bankAccountFormOpened);
                    }}
                    disabled={isAddPaymentMethodDisabled}
                  >
                    <Icon name="plus" classes="button__icon" />
                    <span>Add payment method</span>
                  </button>
                )}
              </div>
            </div>
            <div className="wrap">
              {paymentMethods.map((item) => (
                <PaymentRow item={item} key={item.uuid} />
              ))}
            </div>
          </div>
        )}
        {paymentMethods.length <= 0 && !loading && (
          <div className="finances-empty">
            <h2 className="finances-empty__title">You have no bank accounts linked</h2>
            <p className="finances-empty__text">Verification can take up to 2 - 5 business days.</p>
            <BankSvg className="finances-empty__image" />

            {canManage && (
              <button
                type="button"
                className="button button--primary-blue button--lg finances-empty__button"
                onClick={() => {
                  dispatch(setModalAddPaymentOpen(true));
                  Amplitude.logEvent(AmplitudeEvents.bankAccountFormOpened);
                }}
              >
                Add bank account
              </button>
            )}
          </div>
        )}

        <ModalAddPaymentMethod />
        <ModalVerifyPaymentMethod />
        <ModalSuccess />
        <ModalConfirm />
      </div>
    </DashboardLayout>
  );
}
