import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { defaultRealm } from '../../../../shared/constants/DefaultRealm';

export const deleteAchNode = createAsyncThunk<
  undefined,
  { id: string },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('accounts/deleteAchNode', async ({ id }, { rejectWithValue }) => {
  try {
    const encodedURI = encodeURIComponent(`${defaultRealm}`);

    await api.delete(`/realms/${encodedURI}/accounts/${id}`);
    return undefined;
  } catch (e) {
    // @ts-ignore
    return rejectWithValue(e.response.data);
  }
});
