import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Input, Select } from '../../../../shared';
import { DataItem } from '../../../../shared/components/Select';
import { LoanControlActionType } from '../../../../shared/models/platform-admin';
import { LoanControlActionTypeRetryRepayment } from '../../../../shared/models/platform-admin/loans/LoanControlActionType';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { adminLoanDirectControl } from '../../store/actions';
import { selectSelectedLoan } from '../../store/selectors/loans';

const ACTION_TYPES: DataItem[] = Object.values(LoanControlActionType).map((action) => ({
  name: action,
  dataId: action,
}));
const ACTION_TYPE_WITH_RETRY_REPAYMENT: DataItem[] = Object.values(
  LoanControlActionTypeRetryRepayment,
).map((action) => ({
  name: action,
  dataId: action,
}));

const DRY_RUN_OPTIONS: DataItem[] = [
  { name: 'yes', dataId: 'true' },
  { name: 'no', dataId: 'false' },
];

const REALM_OPTIONS: DataItem[] = [{ name: 'synapse', dataId: 'synapse-keyset00' }];

const isMoneyTransferInitiationAction = (action: LoanControlActionType) =>
  [LoanControlActionType.BEGIN_ACCEPTANCE, LoanControlActionType.BEGIN_PAYMENT].includes(action);

const isTransferLockingAction = (action: LoanControlActionType) =>
  [
    LoanControlActionType.SUCCEED_ACCEPTANCE,
    LoanControlActionType.FAIL_ACCEPTANCE,
    LoanControlActionType.SUCCEED_PAYMENT,
    LoanControlActionType.FAIL_PAYMENT,
  ].includes(action);

const isAccountRefChangingAction = (action: LoanControlActionType) =>
  [
    LoanControlActionType.SET_DISBURSEMENT_SOURCE,
    LoanControlActionType.SET_DISBURSEMENT_TARGET,
    LoanControlActionType.SET_REPAYMENT_SOURCE,
    LoanControlActionType.SET_REPAYMENT_TARGET,
  ].includes(action);

const isEditMessageAction = (action: LoanControlActionType) =>
  action === LoanControlActionType.EDIT_MESSAGE;

export const SelectedLoanDirectControl: FC = () => {
  const dispatch = useAppDispatch();
  const loan = useAppSelector(selectSelectedLoan);

  const [actionType, setActionType] = useState(LoanControlActionType.EDIT_MESSAGE);
  const [dryRun, setDryRun] = useState<'true' | 'false'>('true');
  const [transferId, setTransferId] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [intent, setIntent] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [realm, setRealm] = useState<string>('synapse-keyset00');

  // Fool protection.
  useEffect(() => setDryRun('true'), [actionType]);

  const applyChanges = async () => {
    if (!loan?.uuid) return;
    try {
      await dispatch(
        // @ts-ignore
        adminLoanDirectControl({
          id: loan?.uuid,
          accountRef: { accountId, realm },
          dryRun: dryRun === 'true',
          intent,
          kind: actionType,
          message,
          transferId,
        }),
      );

      dispatch(
        addAlert({
          text: 'Action confirmed, processing!',
          type: AlertType.success,
        }),
      );
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Action denied, rejected!',
          type: AlertType.error,
        }),
      );
    }
  };

  if (!loan) return <></>;

  return (
    <div className="card">
      <h2 className="card__title" style={{ marginBottom: '2rem' }}>
        Loan Direct Control
      </h2>

      <div className="form-group">
        <label htmlFor="action-type" className="label ">
          Action Type
        </label>
        <Select
          id="action-type"
          data={
            loan?.stage.gracePeriod?.manualRepaymentAllowed === false
              ? ACTION_TYPE_WITH_RETRY_REPAYMENT
              : ACTION_TYPES
          }
          placeholder="Select Action"
          value={actionType}
          onChange={setActionType}
        />
      </div>

      {isMoneyTransferInitiationAction(actionType) && (
        <div className="form-group">
          <label htmlFor="dry-run" className="label">
            Dry run
          </label>
          <Select id="dry-run" data={DRY_RUN_OPTIONS} value={dryRun} onChange={setDryRun} />
        </div>
      )}

      {isAccountRefChangingAction(actionType) && (
        <>
          <div className="form-group">
            <label htmlFor="account-id" className="label">
              Account Id
            </label>
            <Input
              id="account-id"
              value={accountId}
              placeholder="Account ID"
              type="text"
              onChange={(e) => setAccountId(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="realm" className="label">
              Realm
            </label>
            <Select id="realm" data={REALM_OPTIONS} value={realm} onChange={setRealm} />
          </div>
        </>
      )}

      {isTransferLockingAction(actionType) && (
        <div className="form-group">
          <label htmlFor="transfer-id" className="label">
            Transfer Id
          </label>
          <Input
            id="transfer-id"
            value={transferId}
            placeholder="Transfer Id"
            type="text"
            onChange={(e) => setTransferId(e.target.value)}
          />
        </div>
      )}

      {isEditMessageAction(actionType) && (
        <div className="form-group">
          <label htmlFor="message" className="label">
            Message
          </label>
          <Input
            id="message"
            value={message}
            placeholder="Message"
            type="text"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      )}

      <div className="form-group">
        <label htmlFor="intent" className="label">
          Intent (optional)
        </label>
        <Input
          id="intent"
          value={intent}
          placeholder="Intent"
          type="textarea"
          onChange={(e) => setIntent(e.target.value)}
        />
      </div>

      <button
        className="button button--sm button--primary-blue button--block"
        type="button"
        onClick={applyChanges}
      >
        Apply
      </button>

      <div className="form-group" />
    </div>
  );
};
