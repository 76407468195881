import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert } from '../../../shared/store/modals';
import DeleteModal from '../components/DeleteModal';
import NotFound from '../components/NotFound';
import { Subscriptions } from '../components/Subscriptions';
import WebhookForm from '../components/WebhookForm';
import DevToolsLayout from '../layout/DevToolsLayout';
import { setCreatedNewWebhook } from '../store';
import { createPartnerWebhook } from '../store/actions/createPartnerWebhook';
import { deletePartnerWebhook } from '../store/actions/deleteWebhook';
import { getPartnerWebhook } from '../store/actions/getPartnerWebhook';

export default function Webhooks() {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isShowEditor, setIsShowEditor] = useState(false);
  const { webhooks, isNewWebhook } = useAppSelector((state: RootState) => state.devTools);
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const handleEditRedirect = useCallback(() => {
    dispatch(setCreatedNewWebhook(false));
    push(`/developer-tools/webhooks/configuration/${webhooks[0]?.key}`);
  }, [webhooks]);

  const handleShowEditor = () => {
    setIsShowEditor(true);
    Amplitude.logEvent(AmplitudeEvents.webhookIntegrationStarted);
  };

  const handleDeleteModal = useCallback(() => {
    setIsDeleteModal(!isDeleteModal);
  }, [isDeleteModal]);

  const isExistWebhooks = useMemo(() => Boolean(webhooks && webhooks.length), [webhooks]);

  const handleCreateWebhook = useCallback((data) => {
    dispatch(createPartnerWebhook(data)).then(async () => {
      dispatch(getPartnerWebhook());
    });
  }, []);

  useEffect(() => {
    dispatch(getPartnerWebhook());
  }, []);

  useEffect(() => {
    if (isNewWebhook && webhooks.length && webhooks[0]?.key) {
      handleEditRedirect();
    }
  }, [isNewWebhook, webhooks]);

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-blue.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Webhooks</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">
            {isExistWebhooks && !isShowEditor ? 'Subscriptions' : 'Configuration'}
          </span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">
        {isExistWebhooks && !isShowEditor ? 'Subscriptions' : 'Configuration'}
      </h2>
      {!isShowEditor && !isExistWebhooks && (
        <NotFound
          message="You have no webhook subscriptions setup. Zirtue’s webhook subscription API sends
        notifications to your app using webhooks."
          buttonTxt="Create webhook subscription"
          onClick={handleShowEditor}
        />
      )}
      {isShowEditor && !isExistWebhooks && (
        <WebhookForm
          onChange={handleCreateWebhook}
          setEditMode={handleEditRedirect}
          title="Create your webhook subscription"
          subTitle="Use webhook subscription to get real-time updates"
          submitButtonName="Create webhook subscription"
          creationMode
        />
      )}
      {isExistWebhooks && !isShowEditor && (
        <Subscriptions onDelete={handleDeleteModal} onRedirect={handleEditRedirect} />
      )}
      <DeleteModal
        onCancel={() => {
          handleDeleteModal();
        }}
        onDelete={() => {
          dispatch(deletePartnerWebhook('partner-webhooks'));
          dispatch(addAlert({ text: 'Webhook subscription successfully deleted' }));
          handleDeleteModal();
        }}
        isDeleteModal={isDeleteModal}
      />
    </DevToolsLayout>
  );
}
