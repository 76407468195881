import React, { FC, useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Spinner from '../../../shared/components/DataTable/Spinner';
import { UserDto } from '../../../shared/models/platform-admin';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { SelectedUser, TablePagination, UsersTable, UsersTableControls } from '../components';
import { useUsersTableSearchEffect } from '../hooks';
import { selectTableItem } from '../store';
import { adminUsersSearch } from '../store/actions';
import {
  selectUsersPagination,
  selectUsersState,
  usersCollectionSelectors,
} from '../store/selectors/users';

export const PlatformAdminUsersPage: FC = () => {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersCollectionSelectors.selectAll);
  const usersPagination = useAppSelector(selectUsersPagination);

  const { total, ...restUsersPagination } = usersPagination;
  const { selectedItemId } = useAppSelector(selectUsersState);

  useUsersTableSearchEffect();

  const onUserClick = useCallback(
    ({ uuid }: UserDto) => dispatch(selectTableItem({ id: uuid, tableName: 'users' })),
    [dispatch],
  );
  const handleRefresh = async () => {
    const req = JSON.parse(localStorage.getItem('users_settings') || '');

    if (req) {
      setRefresh(true);
      const response = await dispatch(adminUsersSearch(req));

      if (adminUsersSearch.fulfilled.match(response)) {
        setRefresh(false);
      }
    }
  };
  return (
    <DashboardLayout pageTitle="Admin Users">
      <UsersTableControls />

      <TablePagination
        tableName="users"
        {...usersPagination}
        controls={[
          <button className="button button--outline-gray" type="button" onClick={handleRefresh}>
            <Icon name="refresh" classes="button__icon" />
            Refresh
          </button>,
        ]}
      />
      {refresh && <Spinner />}
      {!refresh && (
        <UsersTable users={users} selectedUserId={selectedItemId} onUserClick={onUserClick} />
      )}
      <TablePagination tableName="users" {...restUsersPagination} />

      <SelectedUser />
    </DashboardLayout>
  );
};
