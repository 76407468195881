import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ChecklistData } from '..';
import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

type CreateAsyncThunkType = {
  dispatch: AppDispatch;
  rejectValue: string;
  state: RootState;
};

type ChecklistDto = {
  checkListState: ChecklistData;
};

export const getChecklist = createAsyncThunk<ChecklistData, void, CreateAsyncThunkType>(
  'auth/getChecklist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<ChecklistDto>(`/check-list`);
      return response.data.checkListState as ChecklistData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = parseError(error);
        return rejectWithValue(errorMessage);
      }
      throw error;
    }
  },
);
