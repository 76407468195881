import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../../../main/store/hooks';
import { Icon, Modal } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import redLight from '../../assets/red-light.svg';
import { closeLoan } from '../../store/actions/closeLoan';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loanId: string | undefined;
};

export default function ModalCancelLoan({ isOpen, setIsOpen, loanId }: TProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleCloseLoan = async () => {
    try {
      // @ts-ignore
      await dispatch(closeLoan({ loanId, type: 'cancelled' }));
      dispatch(
        addAlert({
          text: 'You have canceled this loan.',
          type: AlertType.success,
        }),
      );
      history.push('/loan-list');
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Failed to cancel the loan. Please try again.',
          type: AlertType.error,
        }),
      );
      console.error('Error closing the loan:', error);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--md"
    >
      <div className="loans__modal">
        <button
          type="button"
          className="modal-content__close loans__modal__close"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="icon-cancel" classes="modal-content__close-icon" />
        </button>
        <div className="loans__modal__iconCenter">
          <img src={redLight} alt="redLight" />
        </div>
        <h2 className="loans__modal__title">Cancel loan?</h2>
        <div className="loans__modal__body">
          <div className={cn('loans__modal__text loans__modal__text--isCancelLoan')}>
            Are you sure you want to cancel this loan? Keep in mind that you won’t be able to undo
            this action.
          </div>
        </div>
        <div className={cn('loans__modal__footer loans__modal__footer--isCancelLoan')}>
          <Button
            type="button"
            className="button button--secondary-blue button--lg button--block"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Discard
          </Button>
          <Button
            type="button"
            className="button button--warning-red button--lg button--block"
            onClick={handleCloseLoan}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
