import React, { Dispatch, FC, SetStateAction } from 'react';

import hand from '../../../../features/init-setup/assets/ProvideUniqueTools.svg';
import Icon from '../../Icon';

type ModalHeaderProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ModalHeader: FC<ModalHeaderProps> = ({ setShowModal }) => (
  <div className="payment-verify-modal__header">
    <div className="payment-verify-modal__background" />
    <img src={hand} alt="Verify account" className="payment-verify-modal__image" />
    <h2>Verify account</h2>
    <p>
      To verify your account, enter the micro-deposits below. If you don’t see them, they should
      arrive in 1-3 business days.
    </p>
    <button
      type="button"
      className="payment-verify-modal__close-button"
      onClick={() => setShowModal(false)}
    >
      <Icon name="cross" />
    </button>
  </div>
);

export default ModalHeader;
