import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { NotificationRangeDto } from '../../../../shared/models/NotificationRangeDto';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const testSftpUpload = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/testSftpUpload', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState().devTools.fileTransferSettings;
    if (state) {
      const { enabled, reportRecipients, sftp } = state.config;

      const { data } = await api.put(`/partners/sample-report`, {
        enabled,
        reportRecipients,
        sftp,
      });
    }
    return true;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
