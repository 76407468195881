import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { FileTransferModal } from '../../../../shared/models/FileTransferModel';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getSftpReportSettings = createAsyncThunk<
  FileTransferModal[],
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/getSftpReportSettings', async (_, { rejectWithValue }) => {
  try {
    const { data } = await api.get<FileTransferModal[]>(`/partners/sftp-report-settings`);
    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
