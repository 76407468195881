import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { closeAlert, IAlertItem } from '../store/modals';

import Icon from './Icon';

const iconsList = {
  success: 'check-circle-solid',
  info: 'info-circle-solid',
  error: 'attention-square',
  dismiss: 'dismiss',
};

interface AlertProps {
  item: IAlertItem;
}

export default function AlertItem({ item }: AlertProps) {
  const [shownAlert, setShownAlert] = useState(false);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setShownAlert(false);
    setTimeout(() => {
      dispatch(closeAlert(item.id));
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => {
      setShownAlert(true);
    }, 400);
  }, []);

  return (
    <div className={classNames(`alert`, { 'alert--shown': shownAlert })}>
      <Icon name={iconsList[item.type]} classes={`alert__icon alert__icon--${item.type}`} />
      <p className="alert__text">{item.text}</p>

      <button type="button" className="alert__close" onClick={handleClose}>
        {item.type === 'dismiss' ? (
          <span>Dismiss</span>
        ) : (
          <Icon name="cross" classes="alert__close-icon" />
        )}
      </button>
    </div>
  );
}
