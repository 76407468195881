import React from 'react';

import { Icon } from '../../../../shared';

export default function Notification() {
  return (
    <div className="loans-overview__notification">
      <Icon name="exclamation-circle" />
      <div style={{ marginLeft: 11 }}>
        <div className="loans-overview__notification__title">You&apos;re viewing demo content</div>
        <div className="loans-overview__notification__subtitle">
          When your members begin generating loan activity, their data will replace the demo content
          below
        </div>
      </div>
    </div>
  );
}
