import { Webhook } from '../../../shared/models/Webhook';
import { InitialFromValues, WebhookFormData } from '../components/WebhookForm';

import { loanTypeEvents, billTypeEvents, EventEntity, EventKeyEnum } from './webhookSubEvents';

export const getNormalizedData = (
  data: InitialFromValues,
  webhookKey?: string,
): WebhookFormData => {
  const eventTypes = { ...loanTypeEvents, ...billTypeEvents };
  const events: EventEntity[] = [];

  Object.entries(data).forEach((item) => {
    if (eventTypes[item[0]]) {
      const enabled = item[1];
      events.push({ ...eventTypes[item[0]], enabled });
    }
  });

  return {
    name: data.name,
    url: data.url,
    enabled: data.enabled,
    key: webhookKey,
    events,
  };
};

export const normalizeWebhook = (webhook: Webhook): InitialFromValues => {
  const { name, enabled, url } = webhook;

  const initialValues = {
    name,
    enabled,
    url,
    billEvents: false,
    loanEvents: false,
    disbursement_started: false,
    disbursement_completed: false,
    [EventKeyEnum.BORROWER_FIRSTNAME]: false,
    [EventKeyEnum.BORROWER_LASTNAME]: false,
    [EventKeyEnum.BORROWER_BILLING_ACCOUNT]: false,
    [EventKeyEnum.LOAN_AMOUNT]: false,
  };

  webhook.events.forEach((event) => {
    switch (event.key.toLocaleLowerCase()) {
      case EventKeyEnum.DISBURSEMENT_STARTED:
        initialValues[EventKeyEnum.DISBURSEMENT_STARTED] = event.enabled;
        break;
      case EventKeyEnum.DISBURSEMENT_COMPLETED:
        initialValues[EventKeyEnum.DISBURSEMENT_COMPLETED] = event.enabled;
        break;
      case EventKeyEnum.BORROWER_FIRSTNAME:
        initialValues[EventKeyEnum.BORROWER_FIRSTNAME] = event.enabled;
        break;
      case EventKeyEnum.BORROWER_LASTNAME:
        initialValues[EventKeyEnum.BORROWER_LASTNAME] = event.enabled;
        break;
      case EventKeyEnum.BORROWER_BILLING_ACCOUNT:
        initialValues[EventKeyEnum.BORROWER_BILLING_ACCOUNT] = event.enabled;
        break;
      case EventKeyEnum.LOAN_AMOUNT:
        initialValues[EventKeyEnum.LOAN_AMOUNT] = event.enabled;
        break;

      default:
        break;
    }
  });

  return initialValues;
};
