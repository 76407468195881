import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input } from '../../../../shared';
import { formatDollarAmount, roundToTwoDecimals } from '../../../../shared/utils';
import { FormWrapper } from '../../../init-setup/components';
import { LoanBorrowerType } from '../../store';
import ModalLoanCoverage from '../ModalLoanCoverage/ModalLoanCoverage';
import ModalServiceFee from '../ModalServiceFee/ModalServiceFee';

type FormData = {
  loanAmount: number | undefined;
  loanServiceFeeAmount: number;
  loanCoverageAmount: number;
  loanTotalFeeAmount: number;
  loanTotalAmount: number;
};

type FormikDataTypes = {
  currentStepIndex: number;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  values: FormData;
  errors: FormikErrors<FormData>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<Element>) => void;
  touched: FormikTouched<FormData>;
  setFieldValue: (
    field: string,
    value: null | string | number | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export default function SelectAmountForm({
  currentStepIndex,
  validateField,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: FormikDataTypes) {
  const { loanBorrowerType, limitations } = useAppSelector((state) => state.loans);
  const [isModalServiceFeeOpen, setIsModalServiceFeeOpen] = useState(false);
  const [isModalLoanCoverageOpen, setIsModalLoanCoverageOpen] = useState(false);

  const updateAmounts = useCallback(
    (amount: number) => {
      const serviceFee = roundToTwoDecimals(amount * (limitations.serviceFeePercent / 100));
      const totalFeeAmount = serviceFee + values.loanCoverageAmount;
      const totalAmount = roundToTwoDecimals(amount + totalFeeAmount);

      setFieldValue('loanServiceFeeAmount', serviceFee);
      setFieldValue('loanTotalFeeAmount', totalFeeAmount);
      setFieldValue('loanTotalAmount', totalAmount);
    },
    [limitations.serviceFeePercent, setFieldValue, values.loanCoverageAmount],
  );

  const handleAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);

      setFieldValue('loanAmount', newValue).then(() => {
        validateField('loanAmount');
        if (newValue > limitations.maxLoanAmount || newValue < limitations.minLoanAmount) {
          setFieldTouched('loanAmount', true, true);
        }
      });

      updateAmounts(newValue);
    },
    [
      setFieldValue,
      validateField,
      setFieldTouched,
      limitations.maxLoanAmount,
      limitations.minLoanAmount,
      updateAmounts,
    ],
  );

  useEffect(() => {
    if (
      (values.loanAmount && values.loanAmount > limitations.maxLoanAmount) ||
      (values.loanAmount && values.loanAmount < limitations.minLoanAmount)
    ) {
      setTimeout(() => {
        setFieldTouched('loanAmount', true, true);
      }, 0);
    }
  }, [currentStepIndex, errors.loanAmount]);

  return (
    <>
      <FormWrapper>
        <div className="loans__selectAmountForm">
          <div className="loans__selectAmountForm__container">
            <div className="loanTypeForm__head">
              <h2 className="loanTypeForm__title">How much would you like to lend?</h2>
              <div className="loanTypeForm__subtitle">
                {/* eslint-disable-next-line */}
                Lend as little as {formatDollarAmount(limitations.minLoanAmount, false)} and as much as {formatDollarAmount(limitations.maxLoanAmount, false)}.
              </div>
            </div>
            <div className="loans__selectAmountForm__body">
              <div className="loans__selectAmountForm__inputBlock">
                <Input
                  type="money"
                  id="loanAmount"
                  value={values.loanAmount}
                  placeholder="Enter amount"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    handleAmountChange(e);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.loanAmount) && touched.loanAmount && errors.loanAmount}
                  isHideNumControls
                />
              </div>
              <div className="loans__selectAmountForm__infoBlock">
                <div className="loans__selectAmountForm__label">breakdown</div>
                <div className="loans__selectAmountForm__summaryBlocks">
                  <div className="loans__selectAmountForm__summaryBlock">
                    <div className="loans__selectAmountForm__summaryBlock__label">Loan amount</div>
                    <div className="loans__selectAmountForm__summaryBlock__price">
                      {formatDollarAmount(values.loanAmount)}
                    </div>
                  </div>
                  <div className="loans__selectAmountForm__divider" />
                  <div className="loans__selectAmountForm__summaryBlock">
                    <div className="loans__selectAmountForm__summaryBlock__label">
                      Pre-paid service fee
                      {/* eslint-disable-next-line */}
                      <div onClick={() => setIsModalServiceFeeOpen(true)}>
                        <Icon
                          name="icon-info"
                          classes="loans__selectAmountForm__summaryBlock__label__icon"
                        />
                      </div>
                    </div>
                    <div className="loans__selectAmountForm__summaryBlock__price">
                      {formatDollarAmount(values.loanServiceFeeAmount)}
                    </div>
                  </div>
                  {loanBorrowerType === LoanBorrowerType.EMPLOYEE && (
                    <>
                      <div className="loans__selectAmountForm__divider" />
                      <div className="loans__selectAmountForm__summaryBlock">
                        <div className="loans__selectAmountForm__summaryBlock__left">
                          <div className="loans__selectAmountForm__summaryBlock__label">
                            Loan coverage
                            <div className="loans__selectAmountForm__summaryBlock__label__badge">
                              <div className="loans__selectAmountForm__summaryBlock__label__badge__text">
                                NEW
                              </div>
                            </div>
                            {/* eslint-disable-next-line */}
                            <div onClick={() => setIsModalLoanCoverageOpen(true)}>
                              <Icon
                                name="icon-info"
                                classes="loans__selectAmountForm__summaryBlock__label__icon"
                              />
                            </div>
                          </div>
                          <div className="loans__selectAmountForm__summaryBlock__text">
                            Active Zirtue loans are covered by TruStage™ Payment Guard Insurance.
                          </div>
                        </div>
                        <div className="loans__selectAmountForm__summaryBlock__price">
                          {formatDollarAmount(values.loanCoverageAmount)}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="loans__selectAmountForm__divider" />
                  <div className="loans__selectAmountForm__summaryBlock">
                    <div className="loans__selectAmountForm__summaryBlock__label">
                      Total to repay
                    </div>
                    <div className="loans__selectAmountForm__summaryBlock__price">
                      {formatDollarAmount(values.loanTotalAmount)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
      <ModalServiceFee isOpen={isModalServiceFeeOpen} setIsOpen={setIsModalServiceFeeOpen} />
      <ModalLoanCoverage isOpen={isModalLoanCoverageOpen} setIsOpen={setIsModalLoanCoverageOpen} />
    </>
  );
}
