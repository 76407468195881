export type AuthTokenTokenMfaCreateResponseDto = {
  mfaToken: string;
};

export type AuthTokenTokenMfaCreate = AuthTokenTokenMfaCreateResponseDto;

export class AuthTokenTokenMfaCreateModel {
  public static fromDTO(
    dto: AuthTokenTokenMfaCreateResponseDto,
  ): AuthTokenTokenMfaCreateResponseDto {
    return {
      ...dto,
    };
  }
}
