import * as yup from 'yup';

import { validatePasswordStrength } from '../../../../shared/utils/validation';

enum ValidationMessages {
  noSpace = 'Spaces between words are prohibited',
  specialSymbols = 'Special symbols are not allowed',
  validCharsSubdomain = 'Only alpha-numeric characters and hyphens allowed',
  isStrong = 'Password is not strong',
  required = 'This field is required',
  subdomainRequired = 'Subdomain is required',
  firstNameRequired = "Name can't be empty",
  lastNameRequired = "Last name can't be empty",
  businessNameRequired = 'Business name is required',
  companySizeRequired = 'Company size is required',
  industryNameRequired = 'Industry name is required',
  noSpaceBeforeAfterDash = 'Spaces before or after a hyphen are not allowed.',
  noConsecutiveSpaces = 'Two consecutive spaces are not allowed.',
  noConsecutiveDashes = 'Two consecutive dashes are not allowed.',
  firstCharValid = 'Please avoid using numbers and special characters',
  lastCharValid = 'Please avoid using numbers and special characters',
  restCharsValid = 'Please avoid using numbers and special characters',
  startEndWithAlphaNumeric = 'Subdomain must begin and end with an alpha-numeric.',
  maxSubdomainLength = 'Subdomain length cannot be greater than 63',
  noHyphenStartEnd = 'Subdomain cannot begin or end with hyphen',
  noConsecutiveHyphens = 'Consecutive hyphens are not allowed.',
}

enum Actions {
  noSpace = 'no-spaces-in-middle',
  validCharsSubdomain = 'valid-chars-subdomain',
  isStrong = 'is-strong',
  specialSymbols = 'special-symbols',
  noConsecutiveSpaces = 'no-consecutive-spaces-in-middle',
  noSpaceBeforeAfterDash = 'no-space-before-dash-in-middle',
  noConsecutiveDashes = 'no-consecutive-dashes',
  firstCharValid = 'first-char-valid',
  lastCharValid = 'last-char-valid',
  restCharsValid = 'rest-chars-valid',
  maxPartLength = 'max-part-length',
  startEndAlphaNumeric = 'start-end-alpha-numeric',
  maxSubdomainLength = 'max-subdomain-length',
  noHyphenStartEnd = 'no-hyphen-start-end',
  noConsecutiveHyphens = 'no-consecutive-hyphens',
}

const Reg = {
  noSpace: /\s/,
  noSymbols: /^[^!@#$%^&*()_+=|}{\]["';:/?.,~]*$/,
  noConsecutiveSpaces: /\s\s/,
  noConsecutiveDashes: /^[^-]*(-[^-]+)?[^-]*$/,
  noSpaceBeforeAfterDash: /^(?!.*\s-)(?!.*-\s)[a-zA-Z\s-]*$/,
  subdomainValidation: /^[a-zA-Z0-9-]+$/,
  firstChar: /^[a-zA-Z]/,
  lastChar: /[a-zA-Z]$/,
  restChars: /^[a-zA-Z\s-]*[a-zA-Z]$/,
  startEndWithAlphaNumeric: /^[A-Za-z0-9](?:[A-Za-z0-9\]{0,61}[A-Za-z0-9])?/,
};

const tests = {
  noSpace: (value: string | undefined) => !Reg.noSpace.test(value as string),
  specialSymbols: (value: string | undefined) => Reg.noSymbols.test(value as string),
  restCharsValid: (value: string | undefined) => {
    if (!value) return true;
    const restCharsValid = Reg.restChars.test(value);
    return restCharsValid;
  },
  firstCharValid: (value: string | undefined) => {
    if (!value) return true;
    const firstCharValid = Reg.firstChar.test(value);
    return firstCharValid;
  },
  lastCharValid: (value: string | undefined) => {
    if (!value) return true;
    const lastCharValid = Reg.lastChar.test(value);
    return lastCharValid;
  },
  noSpaceBeforeAfterDash: (value: string | undefined) => {
    if (!value) return true;
    return Reg.noSpaceBeforeAfterDash.test(value);
  },
  noConsecutiveSpaces: (value: string | undefined) => {
    if (!value) return true;
    return !Reg.noConsecutiveSpaces.test(value);
  },
  noConsecutiveDashes: (value: string | undefined) => {
    if (!value) return true;
    return Reg.noConsecutiveDashes.test(value);
  },
  validCharsSubdomain: (value: string | undefined) => {
    if (!value) return true;
    const firstCharValid = Reg.subdomainValidation.test(value);
    return firstCharValid;
  },
  isStrong: (passVal: string | undefined) => {
    const [isValid] = validatePasswordStrength(passVal || '');
    return !!isValid;
  },
  startEndWithAlphaNumeric: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => Reg.startEndWithAlphaNumeric.test(part));
  },
  maxSubdomainLength: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => part.length <= 63);
  },
  noHyphenStartEnd: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => !/^-|-$/.test(part));
  },
  noConsecutiveHyphens: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => !/--/.test(part));
  },
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegExp =
  /^[a-zA-Z0-9](?!.*?[._+\-!$*&?#%^]{2})[a-zA-Z0-9._+\-!$*&?#%^]*[a-zA-Z0-9]@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?<!\.)$/;

const nameRegExp = /^[a-zA-Z-_' ]+$/;

export const loanTypeSchema = yup.object().shape({
  loanType: yup.string().trim().required(),
});

export const loanRepaymentRequestsSchema = yup.object().shape({
  loanRepaymentRequests: yup.boolean().required(),
});

export const borrowerSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(nameRegExp, 'Please avoid using numbers and special characters')
    .test(Actions.specialSymbols, ValidationMessages.specialSymbols, tests.specialSymbols)
    .required(ValidationMessages.firstNameRequired)
    .min(2, 'Name is too short'),
  lastName: yup
    .string()
    .trim()
    .matches(nameRegExp, 'Please avoid using numbers and special characters')
    .test(Actions.specialSymbols, ValidationMessages.specialSymbols, tests.specialSymbols)
    .required(ValidationMessages.lastNameRequired)
    .min(2, 'Last name is too short'),
  phone: yup
    .string()
    .required("Phone number can't be empty")
    .min(16, 'Please enter a valid phone number'),
  email: yup
    .string()
    .trim()
    .required('Please enter a valid email address (e.g., jessica@gmail.com)')
    .matches(emailRegExp, 'Please enter a valid email address (e.g., jessica@gmail.com)'),
});

export const loanBorrowerTypeSchema = yup.object().shape({
  loanBorrowerType: yup.string().trim().required(),
});

export const loanBusinessTypeSchema = yup.object().shape({
  loanBusinessType: yup
    .string()
    .trim()
    .notOneOf(['other-custom'], 'This value is not allowed')
    .required(),
});

export const createLoanAmountSchema = (minLimit: number, maxLimit: number) =>
  yup.object({
    loanAmount: yup
      .number()
      .typeError('Please enter a valid amount')
      .required('Please enter a valid amount')
      .min(minLimit, 'Please enter a valid amount')
      .max(maxLimit, 'Please enter a valid amount')
      // eslint-disable-next-line no-restricted-globals
      .transform((value) => (isNaN(value) ? undefined : value)),
  });

export const loanReasonTypeSchema = yup.object().shape({
  loanReasonType: yup
    .string()
    .trim()
    .notOneOf(['other-custom'], 'This value is not allowed')
    .required(),
});

export const loanPaymentTypeSchema = yup.object().shape({
  loanPaymentType: yup.string().trim().required(),
});
