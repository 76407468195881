import React from 'react';

type Props = {
  currentStep: number;
  totalSteps: number;
  classes?: string;
  progressTitle?: string;
  progress?: string;
};

const Progress: React.FC<Props> = ({
  currentStep,
  totalSteps,
  classes,
  progressTitle,
  progress,
}) => {
  const width =
    progress === undefined
      ? `calc(${(currentStep / totalSteps) * 100}% - ${44 / (totalSteps - currentStep + 1)}px)`
      : progress;
  return (
    <div className={`progress ${classes || ''}`}>
      <div className="progress__bar">
        <div
          className="progress__bar-value"
          style={{
            width,
          }}
        />
      </div>
      <p className="progress__title">{progressTitle}</p>
    </div>
  );
};
export default Progress;
