import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import LoanCreationForm from '../components/LoanCreationForm/LoanCreationForm';
import { resetLoanStates } from '../store';
import { getLimitations } from '../store/actions/getLimitations';

export default function LoanCreationPage() {
  const dispatch = useAppDispatch();
  const { partner } = useAppSelector((state) => state.auth);

  useEffect(() => {
    // @ts-ignore
    dispatch(getLimitations());

    return () => {
      dispatch(resetLoanStates());
    };
  }, []);

  return <DashboardLayout>{partner && <LoanCreationForm />}</DashboardLayout>;
}
