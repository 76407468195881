import { createAsyncThunk } from '@reduxjs/toolkit';

import { setSelectedDeeplinkPreset } from '..';
import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { LoanPresetDto } from '../../../../shared/models/platform-admin/loans/LoanPresetDto';
import { parseError } from '../../../../shared/utils/helpers';

export const adminLoanPresetById = createAsyncThunk<
  LoanPresetDto,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminLoanPresetById', async (id, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await api.get<LoanPresetDto>(`/platform-admin/loan-presets/${id}`);
    dispatch(setSelectedDeeplinkPreset(data));

    return data;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
