import numeral from 'numeral';
import { useMemo } from 'react';

import { Icon } from '../../../../shared';
import { LoanClosureType, LoanStage, LoanStageKind } from '../../dtos/LoanTypes';
import { RepaymentPlanPayment } from '../../dtos/RepaymentPlan';
import { PaymentStatuses } from '../../dtos/RepaymentPlanDto';
import { prepareRepaymentDateToDraw } from '../../helpers/prepareRepaymentDateToDraw';

import LoanScheduleWarningBlock from './LoanScheduleWarningBlock';

type Props = {
  payment: RepaymentPlanPayment;
  status: PaymentStatuses;
  isOneTime: boolean;
  loanRepaymentDateFrom?: string;
  drawFail?: boolean;
  drawFailSystemIssue?: boolean;
  loanStage?: LoanStage;
  drawMissed?: boolean;
};

const missedLenderTitle = `We have not yet received payment from the borrower. We're working to get them back on track.`;
const titleFailSystemIssue = `There's an issue with this transfer. Please contact customer support for more info.`;
const titleFailedLender = `Uh oh! The borrower failed to make a payment. Loan extended.`;

export default function LoanScheduleItem({
  payment,
  status,
  isOneTime,
  loanRepaymentDateFrom,
  drawFail,
  drawFailSystemIssue,
  loanStage,
  drawMissed,
}: Props) {
  const dateToDraw = useMemo(
    () => (loanRepaymentDateFrom ? prepareRepaymentDateToDraw(payment, loanRepaymentDateFrom) : ''),
    [loanRepaymentDateFrom, payment],
  );

  const isForgiven = useMemo(
    () =>
      loanStage?.kind === LoanStageKind.closed &&
      loanStage?.closureType === LoanClosureType.forgiven,
    [loanStage?.kind],
  );

  const statusIconToDraw = useMemo(() => {
    switch (status) {
      case PaymentStatuses.autoPay:
        return isForgiven ? 'status-indicator-completed' : 'ellipse';

      case PaymentStatuses.completed:
        return 'status-indicator-completed';

      case PaymentStatuses.inProgress:
        return isForgiven ? 'status-indicator-completed' : 'status-indicator-progress';

      case PaymentStatuses.missed:
        return 'status-indicator-late';

      case PaymentStatuses.failed:
        return 'status-indicator-missed';

      default:
        return isForgiven ? 'status-indicator-completed' : 'ellipse';
    }
  }, [isForgiven, status]);

  const statusToDraw = useMemo(() => {
    if (isForgiven && (status === PaymentStatuses.autoPay || status === PaymentStatuses.inProgress))
      return PaymentStatuses.forgiven;
    if (status === PaymentStatuses.missed) return PaymentStatuses.late;
    if (status === PaymentStatuses.failed) return PaymentStatuses.missed;
    return status;
  }, [isForgiven, status]);

  const missedTitle = useMemo(() => {
    if (drawFail) return missedLenderTitle;
    return null;
  }, [drawFail]);

  const failedTitle = useMemo(() => {
    if (drawFailSystemIssue) return titleFailSystemIssue;
    return null;
  }, [drawFailSystemIssue]);

  const missedSubtitleToDraw = useMemo(() => {
    if (
      (drawFail || failedTitle) &&
      (status === PaymentStatuses.missed || status === PaymentStatuses.failed)
    )
      return missedTitle || failedTitle;
    if (drawMissed) return titleFailedLender;
    return null;
  }, [drawFail, drawMissed, failedTitle, missedTitle, status]);

  return (
    <li className="loan__schedule__item">
      <div>
        <div className="loan__schedule__item__content-wrapper">
          <div className="loan__schedule__item__icon">
            <Icon name="computer" />
          </div>
          <div className="loan__schedule__item__content">
            <span>{dateToDraw}</span>
            <div>
              <Icon name={statusIconToDraw} />
              <span>{statusToDraw}</span>
            </div>
          </div>
        </div>
        <span>{`$${numeral(payment.amount).format('0,000.00')}`}</span>
      </div>
      {missedSubtitleToDraw && <LoanScheduleWarningBlock text={missedSubtitleToDraw} />}
    </li>
  );
}
