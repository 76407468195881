import { useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { ModalConfirm } from '../../../../shared';
import { FundingSourceDTO } from '../../../../shared/models/FundingSource';
import { LoanDetailsModel } from '../../../../shared/models/platform-admin/loans/LoanModel';
import { setModalConfirmOpen } from '../../../../shared/store/modals';
import { LoanStageKind, LoanStatus } from '../../dtos/LoanTypes';
import { PaymentStatuses } from '../../dtos/RepaymentPlanDto';
import { prepareRepaymentPlanNew } from '../../helpers/prepareRepaymentPlanNew';
import { useRepaymentRequestLoan } from '../../hooks/useRepaymentRequestLoan';
import { closeLoan } from '../../store/actions/closeLoan';
import { getLoanDetails } from '../../store/actions/getLoanDetails';

import CardSection from './CardSection';
import ForgiveSchedule from './ForgiveSchedule';
import LoanSchedule from './LoanSchedule';

export default function RepaymentPlan({
  activePaymentMethod,
}: {
  activePaymentMethod: FundingSourceDTO | undefined;
}) {
  const { id: loanId } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { loanDetails } = useAppSelector((state) => state.loans);
  const loanSummaryObject = useRepaymentRequestLoan();
  const { accountNumber, loanStage, payments, repaymentDateFrom } = loanSummaryObject;
  const [loading, setLoading] = useState(false);

  const plan = useMemo(
    () => (loanStage && payments ? prepareRepaymentPlanNew(loanStage, payments) : undefined),
    [loanStage, payments],
  );

  const gracePeriodEnabled = useMemo(() => {
    if (loanStage && loanStage.kind === LoanStageKind.repayment && loanStage.gracePeriod)
      return loanStage.gracePeriod?.enabled;
    return false;
  }, [loanStage]);

  const graceManualRepaymentAllowed = useMemo(() => {
    if (loanStage && loanStage.kind === LoanStageKind.repayment && loanStage.gracePeriod)
      return loanStage.gracePeriod?.manualRepaymentAllowed;
    return false;
  }, [loanStage]);

  const loanStatus = LoanDetailsModel.Status(loanDetails);

  const repaymentActionsEnabled = useMemo(() => loanStatus === LoanStatus.active, [loanStatus]);

  const handleForgive = useCallback(async () => {
    setLoading(true);
    const response = await dispatch(closeLoan({ loanId, type: 'forgiven' }));
    if (typeof response.payload === 'boolean') {
      setTimeout(() => {
        dispatch(getLoanDetails(loanId));
      }, 1000);

      setTimeout(() => {
        dispatch(
          setModalConfirmOpen({
            opened: true,
            title: 'Loan forgiven!',
            text: 'Your loan status will be updated soon.',
            okText: 'Got it',
            img: '/images/dashboard/modals/gotIt.png',
            bodyClasses: 'modal__body--w-582',
          }),
        );
      }, 1500);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [loanId]);

  const hasInProgress = useMemo(
    () =>
      !!plan?.filter((item) => item.status === PaymentStatuses.inProgress && item.payment.isCurrent)
        .length,
    [plan],
  );

  return (
    <>
      <div className="loan__bank-card">
        <h2 className="loan__card-title">Payment method</h2>
        <CardSection accountNumber={activePaymentMethod?.redactedAccountNumber || ''} />
        <h2 style={{ marginTop: 24, marginBottom: 12 }} className="loan__card-title">
          Schedule
        </h2>
        <LoanSchedule
          payments={plan || []}
          loanRepaymentDateFrom={repaymentDateFrom}
          drawFail={gracePeriodEnabled && graceManualRepaymentAllowed}
          drawFailSystemIssue={gracePeriodEnabled && !graceManualRepaymentAllowed}
          loanStage={loanStage}
        />
        {repaymentActionsEnabled ? (
          <ForgiveSchedule onForgive={handleForgive} inProgress={hasInProgress} />
        ) : null}
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%' }} className="loader" />
          </div>
        )}
      </div>

      <ModalConfirm />
    </>
  );
}
