// eslint-disable-next-line import/order
import React, { ComponentProps } from 'react';
import '../../../../scss/components/UI/Typography.scss';

// eslint-disable-next-line import/order
import classNames from 'classnames';

type TypographyProps = {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'small' | 'em' | 'strong' | 'p';
  className?: string;
  size?:
    | 'body'
    | 'body-sm'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'title-1'
    | 'title-md'
    | 'title-2'
    | 'title-3'
    | 'large-title'
    | 'large-title-2';
  weight?: 'bold' | 'regular' | 'light' | 'medium' | 'bold-1';
  textAlign?: 'text-center' | 'text-left' | 'text-right';
  decoration?: 'dotted' | 'underline' | 'dashed' | 'line-through';
  colors?:
    | 'text-primary'
    | 'text-white'
    | 'text-disabled'
    | 'text-secondary'
    | 'text-tertiary'
    | 'text-emphasis'
    | 'text-base'
    | 'text-icon'
    | 'text-info'
    | 'text-overlay';
  children: React.ReactNode;
} & ComponentProps<'p'>;

const Typography: React.FC<TypographyProps> = ({
  tag = 'h6',
  weight = 'regular',
  size,
  decoration,
  colors,
  textAlign = 'text-left',
  children,
  className,
  ...rest
}) => {
  const classes = classNames(size, colors, weight, textAlign, decoration, className);
  const Tag = tag;

  return (
    <Tag className={classes} {...rest}>
      {children}
    </Tag>
  );
};

export default Typography;
