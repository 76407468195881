import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { Webhook } from '../../../../shared/models/Webhook';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getPartnerWebhook = createAsyncThunk<
  Webhook,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/getPartnerWebhook', async (_, { rejectWithValue }) => {
  try {
    const { data } = await api.get<Webhook>(`/partners/webhook-configuration`);

    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
