import { addDays, endOfDay } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Spinner from '../../../shared/components/DataTable/Spinner';
import AsyncStoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { TransferDto } from '../../../shared/models/platform-admin/transactions/TransferDto';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { TablePagination } from '../components';
import { TransactionsTable } from '../components/transactions/TransactionsTable';
import { TransactionsTableControls } from '../components/transactions/TransactionsTableControls';
import { useTransactionsTableSearchEffect } from '../hooks';
import { selectTableItem, setTableDateFilter, setTableSearchQuery } from '../store';
import { adminTransactionsSearch } from '../store/actions';
import {
  selectTransactionsPagination,
  selectTransactionsState,
  transactionsCollectionSelectors,
} from '../store/selectors/transactions';

export const PlatformAdminTransactionsPage: FC = () => {
  const [refresh, setRefresh] = useState(false);

  const dispatch = useAppDispatch();
  const transactions = useAppSelector(transactionsCollectionSelectors.selectAll);
  const transactionsPagination = useAppSelector(selectTransactionsPagination);

  const { total, ...restTransactionsPagination } = transactionsPagination;
  const { selectedItemId } = useAppSelector(selectTransactionsState);

  useTransactionsTableSearchEffect();

  const onTransactionClick = useCallback(
    ({ uuid }: TransferDto) => dispatch(selectTableItem({ id: uuid, tableName: 'transactions' })),
    [dispatch],
  );

  const handleRefresh = async () => {
    setRefresh(true);
    // @ts-ignore
    const response = await dispatch(adminTransactionsSearch());

    if (adminTransactionsSearch.fulfilled.match(response)) {
      setRefresh(false);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem(AsyncStoreKeys.userForTransaction);
    localStorage.removeItem(AsyncStoreKeys.userForTransaction);
    if (userId) {
      dispatch(setTableSearchQuery({ query: userId, tableName: 'transactions' }));
      dispatch(
        setTableDateFilter({
          range: [addDays(new Date(), -365).toISOString(), endOfDay(new Date()).toISOString()],
          tableName: 'transactions',
        }),
      );
    }
  }, [dispatch]);

  return (
    <DashboardLayout pageTitle="Admin Transactions">
      <TransactionsTableControls />

      <TablePagination
        tableName="transactions"
        {...transactionsPagination}
        controls={[
          <button className="button button--outline-gray" type="button" onClick={handleRefresh}>
            <Icon name="refresh" classes="button__icon" />
            Refresh
          </button>,
        ]}
      />

      {refresh && <Spinner />}
      {!refresh && (
        <TransactionsTable
          transactions={transactions}
          selectedTransactionId={selectedItemId}
          onTransactionClick={onTransactionClick}
        />
      )}
      <TablePagination tableName="transactions" {...restTransactionsPagination} />
    </DashboardLayout>
  );
};
