import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../../shared';

interface IProps {
  children: ReactNode;
}
const AuthBannerLayout: React.FC<IProps> = ({ children }: IProps) => {
  const [isShowLink, setIsShowLink] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    setIsShowLink(path.includes('reset-password') || path.includes('forgot-password'));
  }, []);

  return (
    <div className="auth-banner">
      <div className="auth-banner__box">
        {/* Top */}
        {isShowLink ? (
          <Link className="auth-banner__top auth-banner__top--link" to="/signin">
            <div className="auth-banner__logo">
              <Icon name="logo-full" />
            </div>
            <p className="auth-banner__top-text">For Businesses</p>
          </Link>
        ) : (
          <div className="auth-banner__top">
            <div className="auth-banner__logo">
              <Icon name="logo-full" />
            </div>
            <p className="auth-banner__top-text">For Businesses</p>
          </div>
        )}
        {/* Content */}
        <div className="auth-banner__content">{children}</div>
        {/* Partners */}
        <ul className="auth-banner__partners">
          <li className="auth-banner__partners-item">
            <img
              src="/images/auth/partners/att.svg"
              className="auth-banner__partners-logo"
              alt=""
            />
          </li>
          <li className="auth-banner__partners-item">
            <img
              src="/images/auth/partners/reliant.svg"
              className="auth-banner__partners-logo"
              alt=""
            />
          </li>
          <li className="auth-banner__partners-item">
            <img
              src="/images/auth/partners/southwestern.svg"
              className="auth-banner__partners-logo"
              alt=""
            />
          </li>
          <li className="auth-banner__partners-item">
            <img
              src="/images/auth/partners/mastercard.svg"
              className="auth-banner__partners-logo"
              alt=""
            />
          </li>
          <li className="auth-banner__partners-item">
            <img
              src="/images/auth/partners/centene.svg"
              className="auth-banner__partners-logo"
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthBannerLayout;
