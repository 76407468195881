import { configureStore, ThunkAction, Action, combineReducers, AnyAction } from '@reduxjs/toolkit';

import authSlice from '../../features/auth/store';
import companySlice from '../../features/company/store';
import dashboardSlice from '../../features/dashboard/store/dashboardSlice';
import devToolsSlice from '../../features/developer-tools/store';
import financesSlice from '../../features/finances/store';
import loansSlice from '../../features/loans/store';
import paymentsSlice from '../../features/payments/store';
import platformAdminSlice from '../../features/platform-admin/store';
import { UsersVerificationStatus } from '../../shared/models/platform-admin';
import { UserRolesMatch } from '../../shared/models/platform-admin/users/UsersRolesMatch';
import modalsSlice from '../../shared/store/modals';
import { setPageSettings } from '../../shared/utils';

const localStorageMiddleware = () => (next: unknown) => (action: Action) => {
  // @ts-ignore
  const { payload } = next(action);
  const {
    setTableSearchQuery,
    setTableDateFilter,
    setTableStageFilter,
    setTablePage,
    setTablePageSize,
    setTableSortField,
    setTableSortOrder,
    setUsersDateFilterField,
    setUsersRolesFilter,
    setUserOnboardingType,
    setUserVerificationStatus,
    setTableDateRangeSettings,
  } = platformAdminSlice.actions;

  const { setTablePaymentsDateFilter, setTablePaymentsDateRangeSettings } = paymentsSlice.actions;
  const {
    setTableSearchText,
    setTableLoanStatus,
    setTableLoanStatusConverted,
    setTableReason,
    setTableLimit,
    setTableNbrPage,
    setTableSkip,
    setTableSortField: setTableSortFieldLoans,
    setTableSortOrder: setTableSortOrderLoans,
    setTablePeriodTime,
  } = loansSlice.actions;

  if (setTableSearchQuery.match(action)) {
    setPageSettings(payload.tableName, 'query', payload.query);
  }

  if (setTableDateFilter.match(action)) {
    const [dateStart, dateEnd] = payload.range;
    setPageSettings(payload.tableName, 'dateStart', dateStart);
    setPageSettings(payload.tableName, 'dateEnd', dateEnd);
  }

  if (setTableStageFilter.match(action)) {
    setPageSettings(payload.tableName, 'status', payload.stage);
  }

  if (setTablePage.match(action)) {
    setPageSettings(payload.tableName, 'page', payload.page);
  }

  if (setTablePageSize.match(action)) {
    setPageSettings(payload.tableName, 'size', payload.size);
  }

  if (setTableSortField.match(action)) {
    setPageSettings(payload.tableName, 'sortField', payload.sortField);
    setPageSettings(payload.tableName, 'sortOrder', 'DESC');
  }

  if (setTableSortOrder.match(action)) {
    setPageSettings(payload.tableName, 'sortOrder', payload.sortOrder);
  }

  if (setUsersDateFilterField.match(action)) {
    setPageSettings('users', 'rangeField', payload);
  }

  if (setUsersRolesFilter.match(action)) {
    setPageSettings('users', 'rolesFilter', payload || []);
  }

  if (setUserOnboardingType.match(action)) {
    setPageSettings('users', 'onboardType', payload);
  }

  if (setUserVerificationStatus.match(action)) {
    setPageSettings('users', 'verificationStatus', payload);
  }

  if (setTableDateRangeSettings.match(action)) {
    setPageSettings(payload.tableName, 'dateRangeSettings', payload.dateRangeSettings);
  }

  if (setTablePaymentsDateFilter.match(action)) {
    const [dateStart, dateEnd] = action.payload;
    setPageSettings('billPayments', 'dateStart', dateStart);
    setPageSettings('billPayments', 'dateEnd', dateEnd);
  }

  if (setTablePaymentsDateRangeSettings.match(action)) {
    setPageSettings('billPayments', 'dateRangeSettings', action.payload);
  }

  if (setTableSearchText.match(action)) {
    setPageSettings('zaal_loans', 'searchText', payload);
  }

  if (setTableLoanStatus.match(action)) {
    setPageSettings('zaal_loans', 'loanStatus', payload);
  }

  if (setTableLoanStatusConverted.match(action)) {
    setPageSettings('zaal_loans', 'loanStatusConverted', payload);
  }

  if (setTableReason.match(action)) {
    setPageSettings('zaal_loans', 'loanReason', payload);
  }

  if (setTableLimit.match(action)) {
    setPageSettings('zaal_loans', 'countRow', payload);
  }

  if (setTableNbrPage.match(action)) {
    setPageSettings('zaal_loans', 'nbrPage', payload);
  }

  if (setTableSkip.match(action)) {
    setPageSettings('zaal_loans', 'skip', payload);
  }

  if (setTableSortFieldLoans.match(action)) {
    setPageSettings('zaal_loans', 'sortBy', payload);
  }

  if (setTableSortOrderLoans.match(action)) {
    setPageSettings('zaal_loans', 'sortOrder', payload);
  }

  if (setTablePeriodTime.match(action)) {
    setPageSettings('zaal_loans', 'periodTime', payload);
  }
};

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  modals: modalsSlice.reducer,
  finances: financesSlice.reducer,
  company: companySlice.reducer,
  payments: paymentsSlice.reducer,
  platformAdmin: platformAdminSlice.reducer,
  devTools: devToolsSlice.reducer,
  loans: loansSlice.reducer,
});

const rootReducer = (state: ReturnType<typeof combinedReducer> | undefined, action: AnyAction) => {
  if (action.type === 'auth/resetAuthToken') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(localStorageMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
