import React, { FC } from 'react';
import ReactJson from 'react-json-view';

interface UserJsonViewProps {
  data: unknown;
}

export const JSONView: FC<UserJsonViewProps> = ({ data }) => {
  if (!data) return <></>;
  return (
    <ReactJson
      style={{
        fontSize: '.8rem',
        backgroundColor: 'white',
        padding: '1rem',
        border: '1px solid #eee',
        borderRadius: 5,
      }}
      src={data as Record<string, unknown>}
      indentWidth={2}
      displayObjectSize={false}
      displayDataTypes={false}
    />
  );
};
