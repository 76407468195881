import React from 'react';

import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { setModalSuccessOpen } from '../store/modals';

import Icon from './Icon';
import Modal from './Modal';

const ModalSuccess = () => {
  const { modalSuccessOpen, modalSuccessText, modalSuccessTitle } = useAppSelector(
    (state) => state.modals,
  );

  const dispatch = useAppDispatch();
  return (
    <Modal
      opened={modalSuccessOpen}
      handleClose={() => {
        dispatch(setModalSuccessOpen({ opened: false }));
      }}
      classes="modal--lg"
    >
      <div className="modal-content modal-content--invitation-sent">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalSuccessOpen({ opened: false }));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--success">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="/images/dashboard/modals/confirm-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="/images/dashboard/modals/success-lv2@1x.webp 1x, /images/dashboard/modals/success-lv2@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="/images/dashboard/modals/success-lv2@1x.png"
              srcSet="/images/dashboard/modals/success-lv2@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="/images/dashboard/modals/success-lv3.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">{modalSuccessTitle}</h2>
        <p className="modal-content__text">{modalSuccessText}</p>
        <div className="modal-content__btn-box">
          <button
            type="button"
            className="button button--primary-blue button--lg modal-content__btn"
            onClick={() => {
              dispatch(setModalSuccessOpen({ opened: false }));
            }}
          >
            Got it
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
