import cn from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import InputMask from 'react-input-mask';

import Icon from './Icon';

export const isPossiblyPhone = /^\d{1,11}$/;
export const isPossiblyPhoneWithPlus = /^\+[0-9()\-. ]*$/;

export const checkIsPhone = (value: string) =>
  isPossiblyPhone.test(value) || isPossiblyPhoneWithPlus.test(value);

type Props = {
  id: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  value?: string;
  disabled?: boolean;
  classes?: string;
  inputClassName?: string;
  mask: string;
  haveFlag?: boolean;
};

const MaskInput: React.FC<Props> = (props: Props) => {
  const {
    error,
    classes,
    disabled,
    id,
    placeholder,
    value,
    onChange,
    inputClassName,
    mask,
    onBlur,
    haveFlag,
  } = props;
  return (
    <div
      className={`
    input-group
    ${error ? ' input-group--error' : ''}
    ${disabled ? ' input-group--disabled' : ''}
   
    ${classes ? ` ${classes}` : ''}`}
    >
      <div className="input-group__input-box">
        {haveFlag ? (
          <div className="input-group__prefix-flag">
            <Icon name="usa-flag" />
            <div className="input-group__prefix-flag__num">+1</div>
          </div>
        ) : (
          ''
        )}
        <InputMask
          id={id}
          mask={mask}
          // @ts-ignore
          maskChar={null}
          className={cn(inputClassName, haveFlag ? 'input__haveFlag' : '')}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <div className="input-group__icon-list">
          <div className="input-group__icon-box input-group__icon-box--error">
            <Icon name="attention" classes="input-group__icon" />
          </div>
        </div>
      </div>
      <span className="input-group__error-text">{error}</span>
    </div>
  );
};

export default MaskInput;
