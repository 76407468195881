import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import {
  setTableSortField,
  setTableSortOrder,
  AdminTableName,
} from '../../../features/platform-admin/store';
import { RootState } from '../../../main/store';
import { useAppDispatch } from '../../../main/store/hooks';
import { SortOrder } from '../../models/SortOrder';
import Icon from '../Icon';

import { DataTableColumnConfig } from './DataTableColumnConfig';

interface DataTableHeaderProps<TRowType> {
  columns: DataTableColumnConfig<TRowType>[];
  name: AdminTableName;
}

export function DataTableHeader<TRowType>({
  columns,
  name,
}: PropsWithChildren<DataTableHeaderProps<TRowType>>) {
  const { sortField, sortOrder } = useSelector((state: RootState) => state.platformAdmin[name]);
  const dispatch = useAppDispatch();

  const changeSorting = (id: string | number | symbol) => {
    if (id !== sortField) {
      dispatch(setTableSortField({ sortField: String(id), tableName: name }));
      return;
    }

    const order = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    dispatch(setTableSortOrder({ sortOrder: order, tableName: name }));
  };

  return (
    <tr className="table__header-row">
      {columns.map(({ title, id, fixed, sort }) => (
        <td
          className={classNames(`table__cell`, `table__cell--header`, {
            'table__cell--fixed': fixed,
          })}
          key={id as string}
        >
          {sort ? (
            <button type="button" className="table__sort-btn" onClick={() => changeSorting(id)}>
              {title}
              <Icon
                name={
                  sortField !== id
                    ? 'double-arrow'
                    : `sorting-arrow-${sortOrder === 'ASC' ? 'up' : 'down'}`
                }
                classes="table__sort-icon"
              />
            </button>
          ) : (
            title
          )}
        </td>
      ))}
    </tr>
  );
}
