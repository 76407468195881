import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { BillPayment, BillPaymentDto, BillPaymentModel } from '../../../../shared/models';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

const orderEnum = {
  desc: -1,
  asc: 1,
};

export const exportPaymentsCSV = createAsyncThunk<
  string,
  {
    searchText: string;
    status: string;
    limit: number;
    skip: number;
    periodTime: string[];
    sortBy: string;
    sortOrder: 'desc' | 'asc';
  },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
  // @ts-ignore
>('dashboard/exportPaymentsCSV', async (payload, { rejectWithValue }) => {
  const { searchText, status, limit, skip, periodTime, sortBy, sortOrder } = payload;
  try {
    const { data } = await api.get<{ payments: Array<BillPaymentDto>; totalPayments: number }>(
      `/bill-payments`,
      {
        params: {
          csv: true,
          skip,
          limit,
          searchText,
          statuses: status === 'all' ? [] : [status],
          initiatedFrom: periodTime[0],
          initiatedTo: periodTime[1],
          sortBy,
          sortOrder: orderEnum[sortOrder],
        },
      },
    );
    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
