enum StoreKeys {
  mfaToken = 'mfaToken',
  authToken = 'authToken',
  isAuthenticated = 'isAuthenticated',
  paymentsSettings = 'payment_settings',
  email = 'email',
  userForLoan = 'userForLoan',
  userForTransaction = 'userForTransaction',
  refreshToken = 'refreshToken',
}

export default StoreKeys;
