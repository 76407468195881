import Slider, { SliderProps } from 'rc-slider';
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

import 'rc-slider/assets/index.css';
import 'react-tooltip/dist/react-tooltip.css';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { setLoanRepaymentMonths, setLoanRepaymentMonthsValue } from '../../store';

interface Props {
  setShowAllTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RepaymentMonthSlider = ({ setShowAllTerms }: Props) => {
  const { Handle } = Slider;

  const dispatch = useAppDispatch();
  const { loanRepaymentMonthsValue, limitations } = useAppSelector((state) => state.loans);

  const generateSliderStepToRepaymentMonth = () => {
    const mapping: Record<number, number> = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= limitations.monthlyPayback.max - limitations.monthlyPayback.min; i++) {
      mapping[i] = i + limitations.monthlyPayback.min;
    }
    return mapping;
  };

  const SliderStepToRepaymentMonth = generateSliderStepToRepaymentMonth();

  const convertStepsToMonths = (step: number): number => SliderStepToRepaymentMonth[step];

  const handleChangeEvent = (n: number) => {
    dispatch(setLoanRepaymentMonthsValue(n));
    dispatch(setLoanRepaymentMonths(convertStepsToMonths(n)));
  };

  useEffect(() => {
    setShowAllTerms(false);
  }, [loanRepaymentMonthsValue]);

  const customHandle = (props: SliderProps) => (
    <>
      <Tooltip
        style={{
          color: 'white',
          backgroundColor: '#006acc',
          borderRadius: '24px',
          fontSize: '14px',
          padding: '4px 12px',
          opacity: '1',
        }}
        id="slider-tooltip"
        place="top"
        isOpen
        noArrow
      />
      <Handle
        value={loanRepaymentMonthsValue}
        {...props}
        data-tooltip-id="slider-tooltip"
        data-tooltip-content={`${convertStepsToMonths(loanRepaymentMonthsValue)} months`}
      />
    </>
  );

  return (
    <Slider
      min={0}
      max={limitations.monthlyPayback.max - limitations.monthlyPayback.min}
      step={1}
      value={loanRepaymentMonthsValue}
      onChange={handleChangeEvent}
      handle={customHandle}
    />
  );
};
