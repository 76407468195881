import React from 'react';

import { Tooltip } from '../../../shared';

type Props = {
  name: string;
  color: 'green' | 'orange' | 'gray' | 'pink' | 'blue';
  classes?: string;
};

export default function MemberNameFL({ name, color, classes }: Props) {
  const firstLetters = name
    .trim()
    .split(' ')
    .map((word: string) => word[0])
    .splice(0, 2)
    .join('');
  return (
    <Tooltip text={name}>
      <div className={`member-name ${classes || ''}`}>
        <div className={`member-name__first-letters member-name__first-letters--${color}`}>
          {firstLetters}
        </div>
        <div className="member-name__text">{name}</div>
      </div>
    </Tooltip>
  );
}
