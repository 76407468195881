import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Input, Label } from '../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';

const validationSchema = yup.object({
  minValue: yup.number().positive('Minimum validation must be a positive number'),
  maxValue: yup.number().positive('Maximum validation must be a positive number'),
});

const ValidationRules = () => {
  const { values, handleChange, handleBlur, touched, errors, handleSubmit, isValid } = useFormik({
    initialValues: {
      minValue: 0,
      maxValue: 0,
      expression: '',
    },
    validationSchema,
    onSubmit: () => {
      //
    },
  });

  return (
    <div className="row profile-edit">
      <div className="col profile-edit__col-general">
        <div className="card">
          <div className="card__header">
            <h2 className="card__title">Validation Rules</h2>
            <p className="card__text">You can edit your user identification constrains below.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Label
                forId="minValue"
                infoText="Minimum length of characters needed to validate an identification number (e.g. Account #/Policy #/Claim #)."
              >
                Minimum validation length
              </Label>
              <Input
                id="minValue"
                placeholder="Minimum validation length"
                type="number"
                value={values.minValue}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  Amplitude.logEvent(AmplitudeEvents.minValidationLengthClicked, {
                    minLength: values.minValue,
                  });
                }}
                isHideNumControls
                error={touched.minValue && errors.minValue}
              />
            </div>
            <div className="form-group">
              <Label
                forId="maxValue"
                infoText="Maximum length of characters needed to validate an identification number (e.g. Account #/Policy #/Claim #)."
              >
                Maximum validation length
              </Label>
              <Input
                id="maxValue"
                placeholder="Maximum validation length"
                type="number"
                isHideNumControls
                value={values.maxValue}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  Amplitude.logEvent(AmplitudeEvents.maxValidationLengthClicked, {
                    maxLength: values.maxValue,
                  });
                }}
                error={touched.maxValue && errors.maxValue}
              />
            </div>
            <div className="form-group">
              <Label forId="expression">Regular Expression</Label>
              <Input
                id="expression"
                placeholder="Regular Expression"
                type="textarea"
                value={values.expression}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  Amplitude.logEvent(AmplitudeEvents.paymentSettingsRegExClicked, {
                    regEx: values.expression,
                  });
                }}
                error={touched.expression && errors.expression}
              />
            </div>
            <button
              className="button button--primary-blue button--lg button--block"
              type="submit"
              disabled={!isValid}
            >
              Save changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ValidationRules;
