import React, { ReactNode } from 'react';

import DashboardLayout from '../../dashboard/layout/DashboardLayout';

interface DevToolsLayoutProps {
  children?: ReactNode;
}

const DevToolsLayout = ({ children }: DevToolsLayoutProps) => (
  <DashboardLayout pageTitle="Developer Tools">
    <div className="dev-tools">
      <div className="dev-tools__container">
        <div className="dev-tools__content">{children}</div>
      </div>
    </div>
  </DashboardLayout>
);

export default DevToolsLayout;
