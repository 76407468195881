import { Dispatch, SetStateAction } from 'react';
import { PlaidAccount } from 'react-plaid-link';

import { Address } from '../../models/AddAchNodeDto';
import Button from '../Button';

import usePlaidButton from './hooks/usePlaidButton';

export type PlaidRequestBody = {
  address: Address | undefined;
  taxId: string;
  type: 'PLAID_PROC';
  publicToken?: string;
  accounts: PlaidAccount[];
  businessName?: string;
};

type PlaidButtonProps = {
  className?: string;
  address: Address | undefined;
  ein: string;
  next: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  companyAddres: string;
};

const PlaidButton = ({
  className,
  address,
  ein,
  loading,
  next,
  setLoading,
  companyAddres,
}: PlaidButtonProps) => {
  const plaidRequest: PlaidRequestBody = {
    address,
    taxId: ein,
    type: 'PLAID_PROC',
    accounts: [],
  };
  const {
    operations: { open },
  } = usePlaidButton({ requestBody: plaidRequest, loading, next, setLoading, companyAddres });

  return (
    <Button type="button" className={className} onClick={open as () => void} disabled={loading}>
      Add a bank account instantly via Plaid
    </Button>
  );
};

export default PlaidButton;
