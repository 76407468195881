import format from 'date-fns/format';
import { FC } from 'react';

import { RootState } from '../../../main/store';
import { useAppSelector } from '../../../main/store/hooks';
import Button from '../../../shared/components/Button';

interface SubscriptionsProps {
  onDelete: () => void;
  onRedirect: () => void;
}

export const Subscriptions: FC<SubscriptionsProps> = ({ onDelete, onRedirect }) => {
  const { webhooks } = useAppSelector((state: RootState) => state.devTools);

  return (
    <div>
      <table className="table table--without-border">
        <thead>
          <tr className="table__header-row">
            <td className="table__cell table__cell--header">Name</td>
            <td className="table__cell table__cell--header">Created</td>
            <td className="table__cell table__cell--header">Status</td>
            <td className="table__cell table__cell--header">&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {webhooks?.map(({ name, createdAt, enabled }) => (
            <tr key={name} className="table__data-row table__data-row--no-hover">
              <td className="table__cell table__cell--nowrap table__cell--data table__cell--name">
                {name}
              </td>
              <td className="table__cell table__cell--data">
                {createdAt && format(new Date(createdAt), 'MMMM d, yyyy')}
              </td>
              <td className="table__cell table__cell--data">
                {enabled ? (
                  <span className="webhook webhook--mint50 table-highlight">Active</span>
                ) : null}
              </td>
              <td className="table__cell table__cell--data table__cell--right">
                <Button
                  type="button"
                  className="button button--outline-gray button--lg subscription__buttons"
                  onClick={onRedirect}
                >
                  Configure
                </Button>
                <Button
                  type="button"
                  className="button button--outline-gray button--lg subscription__buttons"
                  onClick={onDelete}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
