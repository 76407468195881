import React from 'react';

import { Icon } from '../../../shared';

type Props = {
  name: string;
  avatar?: string;
  classes?: string;
};

export default function MemberNameAvatar({ name, avatar, classes }: Props) {
  return (
    <div className={`member-name ${classes || ''}`}>
      <div className="member-name__avatar-box">
        <div className="member-name__avatar-wrapper">
          {avatar && <img src={avatar} className="member-name__avatar" alt="" />}
          {!avatar && <Icon name="ghost" classes="member-name__avatar-placeholder" />}
        </div>
      </div>
      {name}
    </div>
  );
}
