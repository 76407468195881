import LogService from './logging';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const intercomAPI = (...args: any): void => {
  if (window.Intercom) {
    window.Intercom.apply(null, args);
  } else {
    LogService.logWarn('Intercom not initialized yet');
  }
};
