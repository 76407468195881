import classNames from 'classnames';
import throttle from 'lodash/throttle';
import React, { forwardRef, useMemo } from 'react';

import Icon from './Icon';

interface ButtonProps {
  children?: string;
  type?: 'button' | 'submit';
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  iconclassName?: string;
  withThrottle?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick = () => {},
      disabled,
      loading,
      type = 'button',
      icon,
      iconclassName,
      withThrottle,
    },
    ref,
  ) => {
    const handleClick = useMemo(() => {
      if (withThrottle) {
        return throttle(onClick, 1000, { trailing: false });
      }
      return onClick;
    }, [onClick, withThrottle]);

    return (
      <button
        className={classNames(`button ${className}`, { 'button--loading': loading })} // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={handleClick}
        disabled={disabled}
        ref={ref}
      >
        {icon && !loading && <Icon name={icon} classes={`button__icon ${iconclassName}`} />}
        {loading && (
          <span className="button__icon button__icon--spinner">
            <Icon name="spinner" />
          </span>
        )}
        {children}
      </button>
    );
  },
);

export default Button;
