import cn from 'classnames';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Modal } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import sparkles from '../../assets/sparkles.svg';
import zirtueLogo from '../../assets/zirtue-logo.svg';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayBorrowerName: string | undefined;
};

export default function ModalShare({ isOpen, setIsOpen, displayBorrowerName }: TProps) {
  const { loanDeeplink, loanDetails } = useAppSelector((state) => state.loans);

  const dispatch = useAppDispatch();

  const [copyButtonText, setCopyButtonText] = useState('Copy');

  const messageBody = `Hey, ${displayBorrowerName?.split(' ')[0]}
  \nBelow is a link to the payment details via Zirtue. It's secure and repayments are automated. Take a look and let me know your thoughts.
  \nLink: ${loanDeeplink}`;

  const QRCodeText = `sms://${loanDetails?.borrower.redactedPhoneNum};&body=${encodeURIComponent(
    messageBody,
  )}`;

  const copyLoanDeeplink = () => {
    function handleSuccess() {
      dispatch(
        addAlert({
          text: 'Link copied to clipboard. Paste it anywhere to share!',
          type: AlertType.success,
        }),
      );
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy');
      }, 20000);
    }

    function fallbackCopyTextToClipboard(text: string) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '1px';
      textArea.style.height = '1px';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          handleSuccess();
        }
      } catch (err) {
        console.log(err);
      }

      document.body.removeChild(textArea);
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(loanDeeplink).then(handleSuccess, () => {
        fallbackCopyTextToClipboard(loanDeeplink);
      });
    } else {
      fallbackCopyTextToClipboard(loanDeeplink);
    }
  };

  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--md"
    >
      <div className="loans__modal">
        <button
          type="button"
          className="modal-content__close loans__modal__close"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="icon-cancel" classes="modal-content__close-icon" />
        </button>
        <div className="loans__link">
          <div className="loans__link__sparklessBlock">
            <img src={sparkles} alt="sparkles" />
          </div>
          <h1 className="loans__link__title">Loan link ready to share!</h1>
          <div className="loans__link__subtitle">
            {/* eslint-disable-next-line */}
            By sharing this link, {displayBorrowerName?.split(' ')[0]} has a higher chance of viewing your loan.
          </div>
          <div className={cn('loans__link__body loans__link__body--isModal')}>
            <div className="loans__link__body__title">Scan QR to share via SMS</div>
            <div className="loans__link__body__qrcodeBlock">
              <QRCode
                size={130}
                style={{ height: 'auto', maxWidth: '130px', width: '100%', padding: '10px' }}
                value={QRCodeText}
              />
            </div>
            <div className="loans__link__divider" />
            <div className="loans__link__body__subtitle">Copy and paste link</div>
            <button
              type="button"
              className="loans__link__body__linkButton"
              onClick={(e) => {
                e.stopPropagation();
                copyLoanDeeplink();
              }}
            >
              <img src={zirtueLogo} alt="zirtueLogo" />
              {loanDeeplink}
            </button>
            <Button
              type="button"
              className="button button--primary-blue button--lg button--block loans__selectLoanSummaryForm__loansCoverageBlock__button"
              onClick={copyLoanDeeplink}
            >
              {copyButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
