import classNames from 'classnames';
import { get } from 'lodash';
import React, { PropsWithChildren } from 'react';

import Icon from '../Icon';

import { DataTableColumnConfig } from './DataTableColumnConfig';

interface DataTableRowCellProps<TRowType> {
  column: DataTableColumnConfig<TRowType>;
  row: TRowType;
}

export function DataTableCell<TRowType>({
  column,
  row,
}: PropsWithChildren<DataTableRowCellProps<TRowType>>) {
  const { id, fixed, valueProducer, copyButton } = column;
  const value = valueProducer ? valueProducer(row) : get(row, id);
  const cellClassNames = classNames(`table__cell`, `table__cell--data`, {
    'table__cell--fixed': fixed,
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <td className={cellClassNames} key={`${id}`}>
      {value ?? '-'}

      {copyButton && (
        <button type="button" className="table__copy-btn" onClick={handleCopy}>
          <Icon name="copy" />
        </button>
      )}
    </td>
  );
}
