import React, { useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';

import { useOnClickOutside, Icon } from '../../../shared';

type Props = {
  data: string;
  text: string;
  classes?: string;
  onExportCSV: () => void;
};

export default function QuickActionsSelect({ data, text, classes, onExportCSV }: Props) {
  const [opened, setOpened] = useState(false);
  const exportBtnRef = useRef();
  const selectRef = React.createRef<HTMLInputElement>();
  useOnClickOutside(selectRef, () => {
    setOpened(false);
  });

  useEffect(() => {
    if (data && data.length && exportBtnRef.current) {
      setTimeout(() => {
        // @ts-ignore
        exportBtnRef.current.link.click();
      }, 500);
    }
  }, [data]);

  return (
    <div
      className={`
        quick-actions-select 
        ${classes || ''}
        ${opened ? ' quick-actions-select--opened' : ''}`}
      ref={selectRef}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="quick-actions-select__box"
        onClick={() => {
          setOpened(!opened);
        }}
      >
        <span className="quick-actions-select__text">{text}</span>
        <div className="quick-actions-select__chevron">
          <Icon name="chevron" classes="quick-actions-select__chevron-icon" />
        </div>
      </div>
      {opened && (
        <div className="quick-actions-select__list">
          {/* <button type="button" className="quick-actions-select__option" onClick={onExportCSV}>
            <Icon name="upload" classes="quick-actions-select__option-icon" />
            Export data
          </button>
          {data && (
            <CSVLink
              filename="payments.csv"
              data={data}
              asyncOnClick
              onClick={onExportCSV}
              // @ts-ignore
              ref={exportBtnRef}
            />
          )} */}
          <a
            className="quick-actions-select__option"
            href="https://share.hsforms.com/1z958JiC4RN-rZ1Zps6T2eQ4bm6e"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              name="form"
              classes="quick-actions-select__option-icon quick-actions-select__option-icon--form"
            />
            Payment support form
          </a>
        </div>
      )}
    </div>
  );
}
