import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { resetAuthToken, setAuthToken } from '../../features/auth/store';
import StoreKeys from '../../shared/constants/AsyncStoreKeys';
import { AuthTokenCreateModel, AuthTokenCreateResponseDto } from '../../shared/models/AuthToken';
import { CURRENT_DOMAIN } from '../env';

import type { store as MainStore } from '../store';

export const REFRESH_TOKEN_URL = 'auth/token/refresh';

let store: typeof MainStore;

export const injectStoreIntoAxios = (injectedStore: typeof MainStore): void => {
  store = injectedStore;
};

export const api = axios.create({
  baseURL: CURRENT_DOMAIN(),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

api.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    const token: string | undefined = store.getState().auth?.authToken?.accessToken;

    if (token && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    } else {
      // TODO: LogService.logError(e);
    }

    return newConfig;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config;
    const refreshUrl = `${CURRENT_DOMAIN()}/${REFRESH_TOKEN_URL}`;

    if (
      (error?.response?.status === 403 || error?.response?.status === 401) &&
      originalRequest.url === refreshUrl
    ) {
      store.dispatch(resetAuthToken());
      localStorage.removeItem(StoreKeys.authToken);

      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const storedToken = store.getState().auth.authToken;

      if (storedToken) {
        try {
          const res: AxiosResponse<AuthTokenCreateResponseDto> = await api.post(refreshUrl, {
            refreshToken: storedToken.refreshToken,
          });

          if (res.status === 201) {
            const { data } = res;
            const token = AuthTokenCreateModel.fromDTO(data);
            localStorage.setItem(StoreKeys.mfaToken, JSON.stringify(token));

            store.dispatch(setAuthToken(token));
            localStorage.setItem(StoreKeys.authToken, JSON.stringify(token));

            originalRequest.headers = originalRequest.headers ?? {};
            originalRequest.headers.Authorization = `Bearer ${token.accessToken}`;
            return api(originalRequest);
          }
        } catch (errorCough) {
          return Promise.reject(errorCough);
        }
      }
    }

    return Promise.reject(error);
  },
);
