import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Icon } from '../../../../../shared';
import { AdminPartnerDto } from '../../../../../shared/models/platform-admin';

import CampaignModificationTab from './CampaignModificationTab';
import LoanCreationTab from './LoanCreationTab';

interface ITabsBlockProps {
  selectedItemId?: string;
  partner?: AdminPartnerDto;
}
export default function TabsBlock({ selectedItemId, partner }: ITabsBlockProps) {
  if (!partner || !partner?.details?.flowType) return null;
  const showLoanCreationTab = partner.details.flowType === 5 || partner.details.flowType === 6;
  const showCampaignModificationTab = partner.details.flowType === 1;

  return (
    <div>
      <Tabs className="tabs tabs--payments">
        <TabList className="tabs__list">
          {showLoanCreationTab && (
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              Loan Creation Modification
            </Tab>
          )}
          {showCampaignModificationTab && (
            <Tab className="tabs__item" selectedClassName="tabs__item--selected">
              ZP/DP Campaign Modification
            </Tab>
          )}
        </TabList>
        {showLoanCreationTab && (
          <TabPanel className="tabs__panel">
            <div className="partners-tabs__note">
              <Icon name="glyph" />
              <span>
                Note: Changes to the settings below will only affect the Loan Creator and Payment
                Arrangement products.
              </span>
            </div>
            <LoanCreationTab selectedItemId={selectedItemId} />
          </TabPanel>
        )}
        {showCampaignModificationTab && (
          <TabPanel className="tabs__panel">
            <div className="partners-tabs__note">
              <Icon name="glyph" />
              <span>
                Note: The changes below pertain solely to Campaigns, and Campaigns are exclusively
                linked to the Zirtue Pay and Decline Flow products.
              </span>
            </div>
            <CampaignModificationTab />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}
