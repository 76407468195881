import { addMinutes, format, parse } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';

export const formatDate = (dateString?: string) => dateString && format(new Date(dateString), 'Pp');

export const formatExpirationDate = (expString?: string) => {
  if (!expString) return '';
  const date = parse(expString, 'mmyy', new Date());
  return format(date, 'MMM yyyy');
};

export const formatRoles = (roles: string[]) => `[ ${roles.map((r) => `"${r}"`).join(', ')} ]`;

export const formatToCDT = (value: string, pattern = 'PPpp') => {
  const date = utcToZonedTime(new Date(value), 'America/Matamoros');
  return `${tzFormat(date, pattern, { timeZone: 'America/Matamoros' })} CDT`;
};

export const formatToUTC = (value: string, pattern = 'PPpp') =>
  format(addMinutes(new Date(value), new Date(value).getTimezoneOffset()), pattern);

export const formatBoolean = (val: boolean) => (val ? 'Yes' : 'No');

export const roundToTwoDecimals = (val: number) => Math.round(val * 100) / 100;

export const formatDollarAmount = (val?: number, includeFraction = true) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: includeFraction ? 2 : 0,
    maximumFractionDigits: includeFraction ? 2 : 0,
  }).format(val || 0);

export const formatCurrency = (val: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(val);

export const formatDateForLoan = (date: Date): string =>
  format(new Date(date || ''), 'MMMM d, yyyy');

export const isPossiblyPhone = /^\d{1,11}$/;
export const isPossiblyPhoneWithPlus = /^\+[0-9()\-. ]*$/;

export const checkIsPhone = (value: string) =>
  isPossiblyPhone.test(value) || isPossiblyPhoneWithPlus.test(value);
