import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const updCompanyRoleFromMember = createAsyncThunk<
  undefined,
  { memberId: string; groupId?: string },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/updRole', async (payload, { rejectWithValue }) => {
  const { groupId, memberId } = payload;
  try {
    const securityGroups = groupId ? [groupId] : [];
    await api.put(`/members/${memberId}/security-groups`, {
      securityGroups,
    });

    return undefined;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
