import React, { useState } from 'react';

const useVerifyModal = () => {
  const [showModal, setShowModal] = useState(false);

  return {
    showModal,
    setShowModal,
    openModal: () => {
      console.log('run open');
      setShowModal(true);
    },
    closeModal: () => setShowModal(false),
    toggleModal: () => setShowModal((prev) => !prev),
  };
};

export default useVerifyModal;
