import { useFormik } from 'formik';
import { useCallback } from 'react';
import * as yup from 'yup';

import { useAppDispatch } from '../../../main/store/hooks';
import { Input, Label } from '../../../shared';
import DevToolsLayout from '../layout/DevToolsLayout';
import { UpdateSftpFormValues, updateSftpSettings } from '../store/actions/updateSftpSettings';

const inviteValidationSchema = yup.object({
  password: yup
    .string()
    .min(7, 'Password is not strong - must be at least 7 characters')
    .max(20)
    .label('Password')
    .required(),
  passwordConfirmation: yup
    .string()
    .min(7, 'Password is not strong - must be at least 7 characters')
    .max(20)
    .label('Password confirmation')
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required(),
});

interface SftpResetPasswordProps {
  handleResetPasswordPage: () => void;
}

const SftpResetPassword = ({ handleResetPasswordPage }: SftpResetPasswordProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = (data: UpdateSftpFormValues) => {
    dispatch(updateSftpSettings(data)).then(() => {
      handleResetPasswordPage();
    });
  };

  const onBack = useCallback(() => {
    handleResetPasswordPage();
  }, []);

  const { errors, handleSubmit, handleChange, handleBlur, values, touched, dirty, isValid } =
    useFormik<UpdateSftpFormValues>({
      initialValues: {
        password: '',
        passwordConfirmation: '',
      },
      onSubmit,
      validationSchema: inviteValidationSchema,
    });

  return (
    <DevToolsLayout>
      <div className="breadcrumb">
        <button type="button" className="breadcrumb__item">
          <span className="breadcrumb__text">Developer Toolkit</span>
          <img src="/images/dashboard/arrow-head-blue.svg" alt="arrow-head" />
        </button>
        <button type="button" className="breadcrumb__item">
          <img src="/images/dashboard/arrow-tail-gray.svg" alt="arrow-tail" />
          <span className="breadcrumb__text">Configuration</span>
          <img src="/images/dashboard/arrow-head-gray.svg" alt="arrow-head" />
        </button>
      </div>
      <h2 className="main-title">Configuration</h2>
      <div className="validation__wrapper">
        <div className="validation-account card">
          <h3 className="validation-account__title">Reset your password</h3>
          <h4 className="validation-account__subtitle">
            If you&apos;re having trouble resetting your password, please connect with support or
            your Zirtue account rep.
          </h4>
          <form className="validation-account__form" onSubmit={handleSubmit}>
            <div className="form-group">
              <Label forId="password">Enter new password</Label>
              <Input
                type="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
              />
            </div>

            <div className="form-group">
              <Label forId="passwordConfirmation">Confirm new password</Label>
              <Input
                type="password"
                id="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.passwordConfirmation && errors.passwordConfirmation}
              />
            </div>

            <div className="line" />
            <div className="dev-tools__control">
              <button
                style={{ marginRight: '16px' }}
                type="button"
                className="button button--outline button--outline--no-border button--lg"
                onClick={onBack}
              >
                Back
              </button>

              <button
                style={{ width: '136px', fontSize: 12 }}
                type="submit"
                className="button button--primary-blue button--lg"
                disabled={!dirty || !isValid}
              >
                Change password
              </button>
            </div>
          </form>
        </div>
      </div>
    </DevToolsLayout>
  );
};

export default SftpResetPassword;
