import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Alert, Icon } from '../../../shared';
import { intercomAPI } from '../../../shared/services/intercom';
import { setShowIntercom } from '../../dashboard/store/dashboardSlice';
import { AuthBanner, AuthHeader } from '../components';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';

type Props = {
  children: ReactNode;
  headerContent?: AuthHeaderContent;
  support?: boolean;
  bannerContent: AuthBannerContent;
  alertOff?: boolean;
};

const currentYear = new Date().getFullYear();

const AuthorizationLayout: React.FC<Props> = ({
  children,
  support = false,
  alertOff = false,
  headerContent,
  bannerContent,
}) => {
  const { showIntercom } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(showIntercom));
  };
  return (
    <section className="auth">
      <AuthBanner content={bannerContent} />
      <div className="auth__content">
        {headerContent && <AuthHeader content={headerContent} />}
        {children}
        <ul className="auth__bottom">
          <li className="auth__bottom-item">© Zirtue {currentYear}</li>
          <li className="auth__bottom-item">
            <a
              href="https://www.zirtue.com/legal/privacy-policy"
              target="_blank"
              className="auth__bottom-link"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="auth__bottom-item">
            <a
              href="https://www.zirtue.com/legal/terms"
              target="_blank"
              className="auth__bottom-link"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
        </ul>
        {support && (
          <button type="button" className="auth__content-support" onClick={clickSupport}>
            <Icon name="social" />
          </button>
        )}
      </div>
      {!alertOff && <Alert />}
    </section>
  );
};

export default AuthorizationLayout;
