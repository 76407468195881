import classNames from 'classnames';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { shallowEqual } from 'react-redux';
import * as yup from 'yup';

import { GOOGLE_PLACES } from '../../../main/env';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, Label, Modal, MaskInput } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { Address, NodeType } from '../../../shared/models/AddAchNodeDto';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { AlertType, addAlert, setModalSuccessOpen } from '../../../shared/store/modals';
import { getCheckbookError } from '../../../shared/utils';
import { hasRole } from '../../auth/utils';
import { setModalAddPaymentOpen } from '../store';
import { getPaymentMethods, addAchNode } from '../store/actions';
import { addressFromGooglePlace } from '../store/actions/addAchNode';

const isValid = (addr: Address): boolean =>
  !(
    isEmpty(addr.city) ||
    isEmpty(addr.country) ||
    isEmpty(addr.line1) ||
    isEmpty(addr.state) ||
    isEmpty(addr.zip)
  );

const addrError = (addr: Address): string => {
  let errorStr = '';

  if (isEmpty(addr.city)) {
    errorStr = 'City';
  } else if (isEmpty(addr.country)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.line1)) {
    errorStr = 'Country';
  } else if (isEmpty(addr.state)) {
    errorStr = 'State';
  } else if (isEmpty(addr.zip)) {
    errorStr = 'Zip';
  }

  return `${errorStr} is missing in this address`;
};

const validationSchema = yup.object({
  address: yup
    .object()
    .label('Business address')
    .required()
    .test(
      'Business address',
      (val) => {
        if (typeof val.value !== 'object') {
          return '';
        }

        const address = val.value;
        return addrError(address);
      },
      (val) => {
        const address = val as never as Address;
        return typeof address === 'object' && isValid(address);
      },
    ),
  taxId: yup
    .string()
    .trim()
    .min(10, 'A business tax id must be 9 digits long.')
    .label('Business tax id')
    .required(),
  accountNumber: yup
    .string()
    .trim()
    .min(4, 'An account number must contain 4 - 17 digits.')
    .max(22)
    .label('Account number')
    .required(),
  routingNumber: yup
    .string()
    .trim()
    .min(11, 'A routing number must be 9 digits long.')
    .label('Routing number')
    .required(),
});

type Props = {
  classes?: string;
};

export type SelectedPlace = {
  address_components: Array<{ long_name: string; short_name: string; types: Array<string> }>;
  formatted_address: string;
};

type AddPaymentMethodForm = {
  address?: Address;
  taxId: string;
  accountNumber: string;
  routingNumber: string;
};

const successTitle = 'Bank account added!';
const successText =
  'To verify your bank account, you will see two micro deposits (both less than $1) in your account over the next 1-3 business days.';

export default function ModalAddPaymentMethod({ classes }: Props) {
  const { modalAddPaymentOpen: opened, loading } = useAppSelector((state) => state.finances);
  const dispatch = useAppDispatch();
  const addressRef = useRef<HTMLInputElement>(null);
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const isAdminPanel =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  const contentText = `To verify your bank account, you'll see two deposits and two
      withdrawals on your bank statement over the next three days, each $1
      or less.`;

  const submitFormCallback = async (data: AddPaymentMethodForm) => {
    const result = await dispatch(
      addAchNode({
        achNode: {
          type: NodeType.ACH,
          routingNumber: data.routingNumber.trim().split(' ').join(''),
          accountNumber: data.accountNumber.trim().split(' ').join(''),
          taxId: data.taxId.replace('-', '').trim().split(' ').join(''),
          address: data.address,
        },
      }),
    );
    dispatch(setModalAddPaymentOpen(false));

    if (addAchNode.fulfilled.match(result)) {
      dispatch(
        setModalSuccessOpen({
          title: successTitle,
          text: successText,
          opened: true,
        }),
      );

      if (!isAdminPanel) {
        Amplitude.logEvent(AmplitudeEvents.accountAdded);
      }

      setTimeout(() => dispatch(getPaymentMethods()), 2000);
    }

    if (addAchNode.rejected.match(result)) {
      dispatch(
        addAlert({
          text: getCheckbookError(result.payload) || 'Error while adding account.',
          type: AlertType.error,
        }),
      );
    }
  };

  const {
    dirty,
    errors,
    handleChange,
    values,
    touched,
    handleSubmit,
    isValid,
    resetForm,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormik<AddPaymentMethodForm>({
    initialValues: {
      accountNumber: '',
      routingNumber: '',
      taxId: '',
      address: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: submitFormCallback,
    validationSchema,
    validateOnChange: true,
  });

  useEffect(() => {
    if (!opened && addressRef.current) {
      addressRef.current.value = '';
    }

    resetForm();
  }, [opened, resetForm]);

  const handleAddPlace = (place: SelectedPlace, fieldName: string) => {
    const dtoAddress: Address | null = addressFromGooglePlace(place);

    if (dtoAddress) {
      setFieldValue(fieldName, dtoAddress, true);
      setFieldTouched(fieldName);
    }
  };

  const handleChangeAddress = () => {
    setFieldValue('address', '', false);
  };

  return (
    <Modal
      opened={opened}
      handleClose={() => {
        dispatch(setModalAddPaymentOpen(false));
      }}
      classes={classes}
    >
      <div className="modal-content modal-content--mint-top">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalAddPaymentOpen(false));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        <div className="modal-content__illustration modal-content__illustration--add-payment-method">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="images/dashboard/modals/add-payment-method-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="images/dashboard/modals/hand@1x.webp 1x, images/dashboard/modals/hand@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/dashboard/modals/hand@1x.png"
              srcSet="images/dashboard/modals/hand@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="images/dashboard/modals/cuff.svg"
            alt=""
          />
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv4"
            src="images/dashboard/modals/add-payment-method-lv4.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Add bank account</h2>
        <p className="modal-content__text">{contentText}</p>
        {/* Form */}
        <form className="modal-content__form" action="#" onSubmit={handleSubmit}>
          <div className="form-group">
            <Label forId="address">Billing address</Label>
            <div className="autocomplete">
              <Autocomplete
                className={classNames('autocomplete__input', {
                  'autocomplete__input--error': errors.address && touched.address,
                })}
                onKeyDown={(e) => {
                  // hack for ctr + v case
                  if (addressRef?.current && e.code === 'KeyV' && (e.metaKey || e.ctrlKey)) {
                    addressRef.current.value = ' ';
                    setTimeout(() => {
                      if (addressRef.current) {
                        addressRef.current.value = addressRef.current.value.trim();
                      }
                    }, 10);
                  }
                }}
                ref={addressRef}
                apiKey={GOOGLE_PLACES()}
                placeholder="Enter your business address"
                onPlaceSelected={(place) => handleAddPlace(place, 'address')}
                onChange={handleChangeAddress}
                options={{
                  types: ['address'],
                  language: 'en',
                  componentRestrictions: { country: 'usa' },
                }}
                onBlur={() => {
                  handleBlur('address');
                  Amplitude.logEvent(AmplitudeEvents.bankAccountBillingAddressClicked);
                }}
              />
              <div className="autocomplete__icon">
                <Icon name="chevron" />
              </div>
              {errors.address && touched.address && (
                <div className="autocomplete__error">{errors.address}</div>
              )}
            </div>
          </div>
          <div className="form-group">
            <Label
              forId="taxId"
              infoText="Zirtue's banking partner requires you to provide your Business Tax ID or Employer Identification Number. We will only ask for this information once."
            >
              Business Tax ID (EIN)
            </Label>
            <MaskInput
              id="taxId"
              mask="99-9999999"
              value={values.taxId}
              inputClassName="input input-group__input"
              placeholder="11-0000000"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                Amplitude.logEvent(AmplitudeEvents.bankAccountTaxIdClicked);
              }}
              error={Boolean(errors.taxId) && touched.taxId && errors.taxId}
            />
          </div>
          <div className="form-group">
            <div className="form-group">
              <Label forId="accountNumber">account number</Label>
              <MaskInput
                id="accountNumber"
                mask="999 999 999 999 999 99"
                value={values.accountNumber}
                inputClassName="input input-group__input"
                placeholder="000 000 000 000"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  Boolean(errors.accountNumber) && touched.accountNumber && errors.accountNumber
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-group">
              <Label forId="routingNumber">routing number</Label>
              <MaskInput
                id="routingNumber"
                mask="999 999 999"
                value={values.routingNumber}
                inputClassName="input input-group__input"
                placeholder="000 000 000"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  Boolean(errors.routingNumber) && touched.routingNumber && errors.routingNumber
                }
              />
            </div>
          </div>

          <button
            type="submit"
            className="button button--primary-blue button--lg button--block"
            disabled={!isValid || loading || !dirty}
          >
            <span>Add payment account</span>
          </button>
        </form>
      </div>
    </Modal>
  );
}
