import React from 'react';

import { Icon } from '../../../../shared';

interface LoanScheduleWarningBlockProps {
  text: string;
}
export default function LoanScheduleWarningBlock({ text }: LoanScheduleWarningBlockProps) {
  return (
    <div className="loan__schedule__warning">
      <Icon name="emoji-red-light" />
      {text}
    </div>
  );
}
