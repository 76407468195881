import React from 'react';

import { Icon, Modal } from '../../../../shared';
import Button from '../../../../shared/components/Button';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  firstName: string | undefined;
};

export default function ModalWhatNext({ isOpen, setIsOpen, firstName }: TProps) {
  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--md"
    >
      <div className="loans__modal">
        <button
          type="button"
          className="modal-content__close loans__modal__close"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="icon-cancel" classes="modal-content__close-icon" />
        </button>
        <h2 className="loans__modal__title">What happens next?</h2>
        <div className="loans__modal__body">
          <ol className="loans__modal__list">
            <li className="loans__modal__list__item">
              {/* eslint-disable-next-line */}
              We will create the details of your loan with {firstName}. We will also give you a link
              that you can directly share with them.
            </li>
            <li className="loans__modal__list__item">
              Once your loan is accepted, repayments will begin according to the schedule.
            </li>
            <li className="loans__modal__list__item">
              You will receive event-based notifications to monitor the progress of the loan.
            </li>
          </ol>
        </div>
        <div className="loans__modal__footer">
          <Button
            type="button"
            className="button button--primary-blue button--lg button--block"
            onClick={() => setIsOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
}
