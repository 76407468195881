import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../main/store/hooks';
import { updateAlert } from '../store/modals';

import AlertItem from './AlertItem';

interface AlertProps {
  position?: 'center' | 'bottom' | 'custom' | 'top';
}

export default function Alert({ position = 'bottom' }: AlertProps) {
  const [amount, setAmount] = useState(0);
  const [pos, setPos] = useState('0px');
  const dispatch = useAppDispatch();
  const { alertItems } = useAppSelector((state) => state.modals);

  useEffect(() => {
    if (alertItems) {
      setAmount(alertItems.length);

      if (alertItems.length > amount) {
        const positionPx = position === 'top' ? '-340px' : '-40px';
        setPos(positionPx);

        setTimeout(() => {
          setPos('0px');
        }, 400);

        setTimeout(() => {
          dispatch(updateAlert());
        }, 5000);
      }
    }
  }, [alertItems]);

  return (
    <div
      className={`alert-wrap alert-wrap--${position}`}
      style={{ transform: `translateY(${pos})` }}
    >
      {alertItems && alertItems.map((item) => <AlertItem key={item.id} item={item} />)}
    </div>
  );
}
