import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ChecklistData, SelectedFlow } from '..';
import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

type CreateAsyncThunkType = {
  dispatch: AppDispatch;
  rejectValue: string;
  state: RootState;
};
type PayloadType = {
  flowType: SelectedFlow;
};

type ChecklistDto = {
  checkListState: ChecklistData;
};

export const selectChecklistFlow = createAsyncThunk<
  ChecklistData,
  PayloadType,
  CreateAsyncThunkType
>('auth/selectChecklistFlow', async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get<ChecklistDto>(`/check-list?type=${payload.flowType}`);
    return response.data.checkListState as ChecklistData;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = parseError(error);
      return rejectWithValue(errorMessage);
    }
    throw error;
  }
});
