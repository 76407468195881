import { useAppDispatch } from '../../../../main/store/hooks';
import { setModalConfirmOpen } from '../../../../shared/store/modals';

interface ForgiveScheduleProps {
  onForgive: () => void;
  inProgress: boolean;
}

export default function ForgiveSchedule({ onForgive, inProgress }: ForgiveScheduleProps) {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      setModalConfirmOpen({
        opened: true,
        title: 'Forgive loan?',
        text: 'You can help borrowers out by forgiving the entire amount of their loan. Keep in mind that you won’t be able to undo this action.',
        okText: 'Forgive',
        cancelText: 'Cancel',
        img: '/images/dashboard/modals/forgive.png',
        onConfirm: onForgive,
      }),
    );
  };
  return (
    <>
      <div
        className={`loan__forgive-schedule ${inProgress ? 'loan__forgive-schedule--disabled' : ''}`}
      >
        <h3>Forgive loan</h3>
        <p>
          Forgiveness can be a gift. Maintain strong, supportive relationships while helping each
          other financially.
        </p>
        <button
          className="button button--lg"
          onClick={handleClick}
          type="button"
          disabled={inProgress}
        >
          Forgive now
        </button>
      </div>
    </>
  );
}
