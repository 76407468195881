import AnalyticsSection from './AnalyticsSection';
import LineChart from './LineChart';

interface LineChartSectionProps {
  title: string;
  value?: number;
  percent: number;
  cardMinWidth?: number;
}
export default function LineChartSection({
  title,
  value = 0,
  percent,
  cardMinWidth = 360,
}: LineChartSectionProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 24,
        marginBottom: 41,
        minWidth: cardMinWidth,
        height: 182,
      }}
      className="card"
    >
      <AnalyticsSection title={title} value={value} percent={percent} />
      <LineChart />
    </div>
  );
}
