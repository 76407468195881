import React, { ReactElement, useState } from 'react';

type UseMultistepFormProps = {
  steps: ReactElement[];
  currentStepIndex: number;
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>;
};

export default function useMultistepForm({
  steps,
  currentStepIndex,
  setCurrentStepIndex,
}: UseMultistepFormProps) {
  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }
  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) {
        return i;
      }
      return i - 1;
    });
  }
  function goto(index: number) {
    setCurrentStepIndex(index);
  }
  return {
    step: steps[currentStepIndex],
    currentStepIndex,
    goto,
    back,
    next,
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    isRequestStep: currentStepIndex === steps.length - 2,
  };
}
