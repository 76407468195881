import React, { useState } from 'react';

import { Icon, JSONView } from '../../../../shared';
import { CognitoDto } from '../../../../shared/models/platform-admin';
import { formatDate } from '../../../../shared/utils';

type Props = {
  cognito?: CognitoDto;
};

export const CognitoWidget = ({ cognito = {} }: Props) => {
  const { verificationStatus, latestFlowSession } = cognito;
  const { updatedAt, status } = latestFlowSession || {};

  const [showFullData, setShowFullData] = useState(false);

  const toggleFullDataDisplay = () => {
    setShowFullData(!showFullData);
  };

  return (
    <div className="card">
      <h2 className="card__title">Cognito</h2>
      <table className="user-profile__table">
        <tbody>
          <tr>
            <td>Verification Status:</td>
            <td>{verificationStatus || '-'}</td>
          </tr>
          <tr>
            <td>Flow Session Status:</td>
            {status ? (
              <td>
                <button
                  type="button"
                  className="user-profile__table__button"
                  onClick={toggleFullDataDisplay}
                >
                  {status}
                  <Icon name="chevron" classes="user-profile__table__icon" />
                </button>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
          {showFullData && (
            <tr>
              <td colSpan={3} style={{ fontWeight: '400', textTransform: 'none' }}>
                <JSONView data={latestFlowSession} />
              </td>
            </tr>
          )}
          <tr>
            <td>Updated:</td>
            <td>{formatDate(updatedAt) || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
