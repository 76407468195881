/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import range from 'lodash/range';

import { getMonth, getYear, format } from 'date-fns';

import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import Icon from './Icon';

interface IProps {
  id: string;
  disabled?: boolean;
  classes?: string;
  // eslint-disable-next-line
  onChange?: (newValue: string) => void;
  error?: string;
  value?: string;
  dateMask?: string;
}

const DateInput: React.FC<IProps> = ({
  id,
  disabled,
  classes,
  onChange,
  error,
  value,
  dateMask = 'MM/dd/yyyy',
}: IProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [inputDate, setInputDate] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const years = range(1800, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Custom calendar header
  const CustomCalendarHeader = ({
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) => (
    <div className="react-datepicker__header-nav">
      <span className="react-datepicker__header-month">{months[getMonth(date)]}</span>
      <select
        value={getYear(date)}
        onChange={({ target: { value: newVal } }) => changeYear(Number(newVal))}
        className="react-datepicker__header-year-select"
      >
        {years.map((option) => (
          <option key={option.toString()} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="react-datepicker__header-btn-box">
        <button
          type="button"
          className="react-datepicker__month-nav-btn react-datepicker__month-nav-btn--prev"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Icon name="chevron" classes="react-datepicker__month-nav-icon" />
        </button>
        <button
          type="button"
          className="react-datepicker__month-nav-btn react-datepicker__month-nav-btn--next"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Icon name="chevron" classes="react-datepicker__month-nav-icon" />
        </button>
      </div>
    </div>
  );
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    setStartDate(date);
    const formattedDate = format(date, dateMask);

    setInputDate(formattedDate);
    if (onChange) {
      onChange(formattedDate);
    }
  };

  const inputValRef = useRef(value);

  const handleInputMaskChange: React.ChangeEventHandler<HTMLInputElement> = (valFormMask) => {
    const {
      target: { value: targetVal },
    } = valFormMask;
    const parsed = Date.parse(targetVal);

    if (!Number.isNaN(parsed)) {
      inputValRef.current = targetVal;
    }
  };

  const handleInputMaskBlur = () => {
    if (inputValRef.current) {
      setStartDate(new Date(inputValRef.current));
      if (onChange) {
        onChange(inputValRef.current);
      }
    }
  };

  const MaskInput = () => (
    <InputMask
      mask="99\/99\/9999"
      // @ts-ignore
      maskChar=" "
      className="input select__input"
      placeholder="mm/dd/yyyy"
      defaultValue={inputDate}
      id={id}
      disabled={disabled}
      onChange={handleInputMaskChange}
      onBlur={handleInputMaskBlur}
    />
  );

  useEffect(() => {
    if (value) {
      setInputDate(value);
      setStartDate(new Date(value));
    }
  }, [value]);

  return (
    <>
      <div className={`select input-group ${isOpen ? 'select--opened' : ''} ${disabled ? 'select--disabled' : ''}`}>
        <div className="select__box input-group__input-box"> 
          <DatePicker
            selected={startDate}
            maxDate={new Date()}
            closeOnScroll={(e) => e.target === document}
            renderCustomHeader={CustomCalendarHeader}
            disabled={disabled}
            className="input select__input input-group__input"
            onChange={(date: Date) => {
              handleChange(date);
              setIsOpen(false);
            }}
            onFocus={() => {
              setIsOpen(true);
            }}
            onBlur={() => {
              setIsOpen(false);
            }}
            dateFormat={dateMask}
          />
          <div className="select__chevron">
            <Icon name="chevron" classes="select__chevron-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DateInput;
