import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';
import { LoanCreationLimitationsDto } from '../../../loans/dtos/LoanCreationLimitationsDto';

export const adminPatchLimitations = createAsyncThunk<
  LoanCreationLimitationsDto,
  { selectedItemId: string; formData: LoanCreationLimitationsDto },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>(
  'platformAdmin/adminPatchLimitations',
  async ({ selectedItemId, formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.patch<LoanCreationLimitationsDto>(
        `/platform-admin/partners/limitations/${selectedItemId}`,
        formData,
      );
      dispatch(addAlert({ text: 'Revisions saved' }));

      return data;
    } catch (e) {
      // @ts-ignore
      const message = parseError(e);
      return rejectWithValue(message);
    }
  },
);
