import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { RootState } from '../../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input, JSONView, ModalConfirm } from '../../../../shared';
import { Progress } from '../../../../shared/components';
import { AMPLITUDE_PROJECT_ID } from '../../../../shared/constants/Amplitude';
import AsyncStoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import { UserDto, UserRoleString } from '../../../../shared/models/platform-admin';
import { addAlert, AlertType } from '../../../../shared/store/modals';
import { formatDate, formatRoles } from '../../../../shared/utils';
import { useSelectedTableSearchEffect } from '../../hooks';
import { resetSelectLoan } from '../../store';
import { adminUserActiveControl, getUserPaymentMethods } from '../../store/actions';
import { selectLoansState } from '../../store/selectors/loans';
import { selectSelectedUser } from '../../store/selectors/users';
import { TablePagination } from '../common/TablePagination';
import { LoansTable } from '../loans/LoansTable';

import { CognitoWidget } from './CognitoWidget';
import { PaymentMethodWidget } from './PaymentMethodWidget';

export const SelectedUser = () => {
  const [isRawData, setIsRawData] = useState(false);
  const [isShowLoans, setIsShowLoan] = useState(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectSelectedUser);

  useEffect(() => {
    if (user) dispatch(getUserPaymentMethods(user.uuid));
  }, [dispatch, user]);

  const paymentMethods = useAppSelector(
    (state: RootState) => state.platformAdmin.users.selectedUserPaymentMethods,
  );

  const { city, countryCode, postalCode, street, subdivision } = user?.address || {};
  const address = user?.address
    ? `${street} ${city}, ${subdivision} ${postalCode} ${countryCode}`
    : null;
  const { day, month, year } = user?.dateOfBirth || {};
  const dob = user?.dateOfBirth ? format(new Date(year!, month!, day!), 'MMMM dd, yyyy') : null;
  const loans = useAppSelector((state: RootState) => state.platformAdmin.selectedItemLoan);

  const { selectedItemId } = useAppSelector(selectLoansState);

  const copyClipboard = (value: string, field: string) => {
    navigator.clipboard.writeText(value);
    dispatch(addAlert({ text: `${field} was successfully copied`, type: AlertType.info }));
  };

  const getEmail = ({ defaultContacts }: UserDto) =>
    defaultContacts.find((el) => el.indexOf('mailto:') !== -1)?.replace('mailto:', '') || '';

  const getPhone = ({ defaultContacts }: UserDto) =>
    defaultContacts?.find((c) => c.startsWith('tel:'))?.replace('tel:', '');

  const copyButton = (value: string, field: string) => (
    <div className="user-profile__copy-field">
      {value}
      <button
        className="button button--link user-profile__copy-btn"
        type="button"
        onClick={() => copyClipboard(value, field)}
      >
        <span className="button__icon">
          <Icon name="copy" />
        </span>
      </button>
    </div>
  );
  useEffect(() => {
    setIsRawData(false);
    setIsShowLoan(false);
    dispatch(resetSelectLoan());
  }, [dispatch, user]);

  useSelectedTableSearchEffect();

  const handleGetLoan = () => {
    localStorage.setItem(AsyncStoreKeys.userForLoan, user?.uuid ?? '');
    window.open(`/platform-admin/loans`, '_blank', 'noopener,noreferrer');
  };

  const handleSwitch = () => {
    setIsRawData(!isRawData);
    setIsShowLoan(false);
  };

  const handleDeactivate = () => {
    if (user) {
      const isDeactivated = user.roles.includes(UserRoleString.DEACTIVATED);

      dispatch(adminUserActiveControl({ isActive: isDeactivated, userId: user.uuid }));
    }
  };

  if (!user) {
    return <div />;
  }

  return (
    <div className="user-profile">
      <h3 className="user-profile__title">Zirtue User Profile</h3>
      <div className="user-profile__id">
        UUID:&nbsp;
        {user.uuid}
      </div>
      <div className="user-profile__menu">
        <a
          href={`https://analytics.amplitude.com/Zirtue/project/${AMPLITUDE_PROJECT_ID}/search/user_id=${user.uuid}`}
          target="_blank"
          className="user-profile__menu-item"
          rel="noreferrer"
        >
          Amplitude
        </a>
        <a
          href="https://playground.cognitohq.com/login"
          target="_blank"
          className="user-profile__menu-item"
          rel="noreferrer"
        >
          Cognito
        </a>
        <button className="user-profile__menu-item" type="button" onClick={handleSwitch}>
          {isRawData ? 'View User Profile' : 'View Raw Data'}
        </button>

        <button className="user-profile__menu-item" type="button" onClick={handleGetLoan}>
          View Loans
        </button>

        <button
          type="button"
          className="button button--primary-blue button--sm user-profile__menu-last"
          onClick={handleDeactivate}
        >
          {user.roles.includes(UserRoleString.DEACTIVATED) ? 'Activate' : 'Deactivate'}
        </button>
      </div>

      {loans &&
        isShowLoans &&
        (loans.collection.length ? (
          <>
            <LoansTable
              name="selectedItemLoan"
              loans={loans.collection}
              selectedLoanId={selectedItemId}
            />
            {loans && <TablePagination tableName="selectedItemLoan" {...loans} />}
          </>
        ) : (
          'Transactions not found'
        ))}

      {!isRawData && !isShowLoans && (
        <>
          <div className="row user-profile__row">
            <div className="col user-profile__col">
              <div className="card">
                <h2 className="card__title">User Profile</h2>
                <table className="user-profile__table">
                  <tbody>
                    <tr>
                      <td>Id:</td>
                      <td>{copyButton(user.uuid, 'Id')}</td>
                    </tr>
                    <tr>
                      <td>Name:</td>
                      <td>
                        {user.firstName && user.lastName
                          ? copyButton(`${user.firstName} ${user.lastName}`, 'Name')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of Birth:</td>
                      <td>{dob || '-'}</td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>{address || '-'}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{getEmail(user) ? copyButton(getEmail(user), 'Email') : '-'}</td>
                    </tr>
                    <tr>
                      <td>Phone:</td>
                      <td>{getPhone(user) ? copyButton(getPhone(user)!, 'Phone') : '-'}</td>
                    </tr>
                    <tr>
                      <td>Onboarding Type:</td>
                      <td>{user.onboarding?.onboardFlowType || '-'}</td>
                    </tr>
                    <tr>
                      <td>Creation date:</td>
                      <td>{formatDate(user.createdAt)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col user-profile__col">
              <div className="card">
                <h2 className="card__title">Account Status</h2>

                <table className="user-profile__table">
                  <tbody>
                    <tr>
                      <td>Authentication Status:</td>
                      <td>{formatRoles(user.roles)}</td>
                    </tr>
                    <tr>
                      <td>Deactivation Date:</td>
                      <td>{user.deactivatedAt || '-'}</td>
                    </tr>
                    <tr>
                      <td>Deletion Date:</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row user-profile__row">
            <div className="col user-profile__col">
              <div className="card">
                <h2 className="card__title">Loan Permissions</h2>

                <table className="user-profile__table">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="user-profile__table-title">
                        <h3 className="user-profile__sub-title">Active Loan Limits</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>Bill Pay Loan Limit:</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Custom Loan Limit:</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="user-profile__table-title">
                        <h3 className="user-profile__sub-title">
                          Default Network Transaction Limits
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>Daily Limit:</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Monthly Limit:</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="user-profile__table-title">
                        <h3 className="user-profile__sub-title">
                          Custom Transaction Limits
                          <span className="user-profile__sub-txt">
                            (Please be mindful of Checkbook transaction limits before adjusting
                            these values!)
                          </span>
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>Daily Limit:</td>
                      <td>
                        <Input
                          id="custom-daily"
                          type="text"
                          classes="input-group--no-margin input-group--table"
                          disabled
                          value="$30"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Monthly Limit:</td>
                      <td>
                        <Input
                          id="custom-monthly"
                          type="text"
                          classes="input-group--no-margin input-group--table"
                          disabled
                          value="$5000"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="user-profile__table-title">
                        <h3 className="user-profile__sub-title">Loan Allocations</h3>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Daily Usage -<span className="user-profile__add-info"> $55 / $5000</span>
                        <Progress
                          currentStep={55}
                          totalSteps={5000}
                          classes="user-profile__progress"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Monthly Usage -
                        <span className="user-profile__add-info"> $2500 / $5000</span>
                        <Progress
                          currentStep={2500}
                          totalSteps={5000}
                          classes="user-profile__progress"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col user-profile__col">
              <div style={{ marginBottom: '16px' }}>
                <CognitoWidget cognito={user.cognito} />
              </div>
              <PaymentMethodWidget userId={user.uuid} paymentMethods={paymentMethods} />
            </div>
          </div>
        </>
      )}

      {isRawData && !isShowLoans && (
        <div className="user-profile__raw">
          <div className="card">
            <h2 className="card__title">Raw Data</h2>
            <JSONView data={user} />
          </div>
        </div>
      )}
      <ModalConfirm />
    </div>
  );
};
