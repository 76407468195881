import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import ZError from '../../../../shared/errors/ZError';
import { LoanDisbursementDto } from '../../dtos/LoanDisbursementDto';

export type AcceptLoanParams = {
  loanId: string;
  body: LoanDisbursementDto;
};

export const acceptLoan = createAsyncThunk<
  null,
  AcceptLoanParams,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('loan/acceptLoan', async ({ loanId, body }, { rejectWithValue }) => {
  try {
    await api.post(`/loans/${loanId}/disbursement`, body, {});

    return null;
  } catch (e) {
    const error = new ZError({ internalError: e as Error });
    return rejectWithValue(error.FullTitle);
  }
});
