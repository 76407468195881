import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import { api } from '../../../main/network';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Input, Label } from '../../../shared';
import Button from '../../../shared/components/Button';
import { AlertType, addAlert } from '../../../shared/store/modals';
import { getContact } from '../../../shared/utils';
import { personalDetailsSchema as validationSchema } from '../schemas';

import { AccountTitle } from '.';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const PersonalDetails = () => {
  const { partner, user } = useAppSelector(
    (state) => ({ ...state.auth, ...state.company }),
    shallowEqual,
  );

  const dispatch = useAppDispatch();

  const updateAccountSettings = async ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) => {
    try {
      const response = await api.put('/members/account', {
        firstName,
        lastName,
      });
      if (response.status === 200) {
        dispatch(addAlert({ text: 'Save changes successfully' }));
      }
    } catch (error) {
      dispatch(addAlert({ text: 'Error while saving changes.', type: AlertType.error }));
    }
  };

  const onSubmit = useCallback(
    async (v: FormValues, submitProps: FormikHelpers<FormValues>) => {
      await updateAccountSettings({ firstName: v.firstName, lastName: v.lastName });
      submitProps.resetForm({ values: v });
    },
    [partner],
  );

  const {
    handleBlur,
    errors,
    values,
    touched,
    setFieldValue,
    isValid,
    dirty,
    submitForm,
    setSubmitting,
    isSubmitting,
    setFieldTouched,
    handleSubmit,
  } = useFormik<FormValues>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: getContact(user?.contacts[0]),
    },
    onSubmit,
    validationSchema,
    validateOnChange: true,
  });

  const fieldChangeNew = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSubmitting(false);
    setFieldValue(target.id, target.value);
  };

  return (
    <div className="personal-details">
      <AccountTitle text="You can update and edit your personal details by clicking any of the fields below">
        Personal details
      </AccountTitle>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-8">
          <Label forId="firstName">First name</Label>
          <Input
            type="text"
            id="firstName"
            placeholder="First name"
            value={values.firstName}
            onChange={fieldChangeNew}
            onBlur={handleBlur}
            error={Boolean(errors.firstName) && touched.firstName && errors.firstName}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="lastName">Last name</Label>
          <Input
            type="text"
            id="lastName"
            placeholder="Last name"
            value={values.lastName}
            onChange={fieldChangeNew}
            onBlur={handleBlur}
            error={Boolean(errors.lastName) && touched.lastName && errors.lastName}
          />
        </div>
        <div className="form-group mb-8">
          <Label forId="email">Email</Label>
          <Input
            type="text"
            id="email"
            defaultValue={values.email}
            placeholder="Account owner"
            disabled
          />
        </div>
        <Button
          type="submit"
          className="button button--primary-blue button--lg button--block"
          disabled={!(isValid && dirty) || isSubmitting}
        >
          Save changes
        </Button>
      </form>
    </div>
  );
};

export default PersonalDetails;
