import { useState, useEffect } from 'react';

import { DateRangeFilter, DateRangeTuple, Label, Select } from '../../../../shared';
import { getTimePeriod } from '../../../../shared/utils';

const mockData = [
  { dataId: 'Any', name: 'Any' },
  { dataId: 'Auto', name: 'Auto' },
  { dataId: 'Business', name: 'Business' },
  { dataId: 'Credit Card', name: 'Credit Card' },
  { dataId: 'Education', name: 'Education' },
  { dataId: 'Emergency', name: 'Emergency' },
  { dataId: 'Entertainment', name: 'Entertainment' },
  { dataId: 'Food', name: 'Food' },
  { dataId: 'Insurance', name: 'Insurance' },
  { dataId: 'Medical', name: 'Medical' },
  { dataId: 'Travel', name: 'Travel' },
  { dataId: 'Rent', name: 'Rent' },
  { dataId: 'Utilities', name: 'Utilities' },
  { dataId: 'Other', name: 'Other' },
];

interface FiltersProps {
  isReasonLabel?: boolean;
  disabled?: boolean;
  onChange?: (value: { reason: string; periodTime: string[] }) => void;
}
export default function Filters({ isReasonLabel = true, disabled = true, onChange }: FiltersProps) {
  const [reason, setReason] = useState('');
  const [periodTime, setPeriodTime] = useState<string[]>(getTimePeriod(30));

  useEffect(() => {
    if (onChange) {
      onChange({ reason: reason === 'Any' ? '' : reason, periodTime });
    }
  }, [reason, periodTime, onChange]);

  const handleDateFilter = (value: DateRangeTuple) => {
    setPeriodTime(value);
  };

  return (
    <div style={{ display: 'flex', alignItems: isReasonLabel ? 'center' : 'baseline', gap: 15 }}>
      <div style={{ width: 340 }}>
        <div className="form-group">
          {isReasonLabel && <Label forId="category">Reason for loan</Label>}
          <Select
            id="category"
            placeholder="Reason for loan"
            data={mockData}
            value={reason}
            classes="select--small"
            onChange={(e) => setReason(e)}
            disabled={disabled}
          />
        </div>
      </div>
      <div style={{ marginBottom: 0 }} className="table-filter__col">
        <DateRangeFilter
          classes="table-filter__date-filter"
          onChangePeriod={(value) => handleDateFilter(value)}
          placeholder="Select date"
          disabled={disabled}
          showDays={30}
        />
      </div>
    </div>
  );
}
