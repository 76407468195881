import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { SftpSettingsModel } from '../../../../shared/models/platform-admin';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getSftpSettings = createAsyncThunk<
  SftpSettingsModel,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/getSftpSettings', async (_, { rejectWithValue }) => {
  try {
    const { data } = await api.get<SftpSettingsModel>(
      `/partners/sftp-settings/account-validation-files`,
    );

    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
