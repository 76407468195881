import * as yup from 'yup';

import { validatePasswordStrength } from '../../../../shared/utils/validation';

enum ValidationMessages {
  noSpace = 'Spaces between words are prohibited',
  specialSymbols = 'Special symbols are not allowed',
  validCharsSubdomain = 'Only alpha-numeric characters and hyphens allowed',
  isStrong = 'Password is not strong',
  required = 'This field is required',
  subdomainRequired = 'Subdomain is required',
  firstNameRequired = 'First Name is required',
  lastNameRequired = 'Last Name is required',
  businessNameRequired = 'Business name is required',
  companySizeRequired = 'Company size is required',
  industryNameRequired = 'Industry name is required',
  noSpaceBeforeAfterDash = 'Spaces before or after a hyphen are not allowed.',
  noConsecutiveSpaces = 'Two consecutive spaces are not allowed.',
  noConsecutiveDashes = 'Two consecutive dashes are not allowed.',
  firstCharValid = 'First character is not valid',
  lastCharValid = 'Last character is not valid',
  restCharsValid = 'Only English alphabet characters are allowed',
  startEndWithAlphaNumeric = 'Subdomain must begin and end with an alpha-numeric.',
  maxSubdomainLength = 'Subdomain length cannot be greater than 63',
  noHyphenStartEnd = 'Subdomain cannot begin or end with hyphen',
  noConsecutiveHyphens = 'Consecutive hyphens are not allowed.',
}

enum Actions {
  noSpace = 'no-spaces-in-middle',
  validCharsSubdomain = 'valid-chars-subdomain',
  isStrong = 'is-strong',
  specialSymbols = 'special-symbols',
  noConsecutiveSpaces = 'no-consecutive-spaces-in-middle',
  noSpaceBeforeAfterDash = 'no-space-before-dash-in-middle',
  noConsecutiveDashes = 'no-consecutive-dashes',
  firstCharValid = 'first-char-valid',
  lastCharValid = 'last-char-valid',
  restCharsValid = 'rest-chars-valid',
  maxPartLength = 'max-part-length',
  startEndAlphaNumeric = 'start-end-alpha-numeric',
  maxSubdomainLength = 'max-subdomain-length',
  noHyphenStartEnd = 'no-hyphen-start-end',
  noConsecutiveHyphens = 'no-consecutive-hyphens',
}

const Reg = {
  noSpace: /\s/,
  noSymbols: /^[^!@#$%^&*()_+=|}{\]["';:/?.,~]*$/,
  noConsecutiveSpaces: /\s\s/,
  noConsecutiveDashes: /^[^-]*(-[^-]+)?[^-]*$/,
  noSpaceBeforeAfterDash: /^(?!.*\s-)(?!.*-\s)[a-zA-Z\s-]*$/,
  subdomainValidation: /^[a-zA-Z0-9-]+$/,
  firstChar: /^[a-zA-Z]/,
  lastChar: /[a-zA-Z]$/,
  restChars: /^[a-zA-Z\s-]*[a-zA-Z]$/,
  startEndWithAlphaNumeric: /^[A-Za-z0-9](?:[A-Za-z0-9\]{0,61}[A-Za-z0-9])?/,
};

const tests = {
  noSpace: (value: string | undefined) => !Reg.noSpace.test(value as string),
  specialSymbols: (value: string | undefined) => Reg.noSymbols.test(value as string),
  restCharsValid: (value: string | undefined) => {
    if (!value) return true;
    const restCharsValid = Reg.restChars.test(value);
    return restCharsValid;
  },
  firstCharValid: (value: string | undefined) => {
    if (!value) return true;
    const firstCharValid = Reg.firstChar.test(value);
    return firstCharValid;
  },
  lastCharValid: (value: string | undefined) => {
    if (!value) return true;
    const lastCharValid = Reg.lastChar.test(value);
    return lastCharValid;
  },
  noSpaceBeforeAfterDash: (value: string | undefined) => {
    if (!value) return true;
    return Reg.noSpaceBeforeAfterDash.test(value);
  },
  noConsecutiveSpaces: (value: string | undefined) => {
    if (!value) return true;
    return !Reg.noConsecutiveSpaces.test(value);
  },
  noConsecutiveDashes: (value: string | undefined) => {
    if (!value) return true;
    return Reg.noConsecutiveDashes.test(value);
  },
  validCharsSubdomain: (value: string | undefined) => {
    if (!value) return true;
    const firstCharValid = Reg.subdomainValidation.test(value);
    return firstCharValid;
  },
  isStrong: (passVal: string | undefined) => {
    const [isValid] = validatePasswordStrength(passVal || '');
    return !!isValid;
  },
  startEndWithAlphaNumeric: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => Reg.startEndWithAlphaNumeric.test(part));
  },
  maxSubdomainLength: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => part.length <= 63);
  },
  noHyphenStartEnd: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => !/^-|-$/.test(part));
  },
  noConsecutiveHyphens: (value: string | undefined) => {
    if (!value) return true;
    const subdomainParts = value.split('.');
    return subdomainParts.every((part) => !/--/.test(part));
  },
};

export const firstSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .test(Actions.firstCharValid, ValidationMessages.firstCharValid, tests.firstCharValid)
    .test(Actions.lastCharValid, ValidationMessages.lastCharValid, tests.lastCharValid)
    .test(Actions.restCharsValid, ValidationMessages.restCharsValid, tests.restCharsValid)
    .test(
      Actions.noConsecutiveDashes,
      ValidationMessages.noConsecutiveDashes,
      tests.noConsecutiveDashes,
    )
    .test(Actions.specialSymbols, ValidationMessages.specialSymbols, tests.specialSymbols)
    .required(ValidationMessages.firstNameRequired),
  lastName: yup
    .string()
    .trim()
    .test(Actions.firstCharValid, ValidationMessages.firstCharValid, tests.firstCharValid)
    .test(Actions.lastCharValid, ValidationMessages.lastCharValid, tests.lastCharValid)
    .test(Actions.restCharsValid, ValidationMessages.restCharsValid, tests.restCharsValid)
    .test(
      Actions.noConsecutiveDashes,
      ValidationMessages.noConsecutiveDashes,
      tests.noConsecutiveDashes,
    )
    .test(Actions.specialSymbols, ValidationMessages.specialSymbols, tests.specialSymbols)
    .required(ValidationMessages.lastNameRequired),
  password: yup
    .string()
    .min(8)
    .test(Actions.isStrong, ValidationMessages.isStrong, tests.isStrong)
    .required(),
});

export const thirdSchema = yup.object().shape({
  businessName: yup.string().trim().required(ValidationMessages.businessNameRequired),
  industry: yup.string().trim().required(ValidationMessages.industryNameRequired),
  companySize: yup.string().trim().required(ValidationMessages.companySizeRequired),
});

export const secondSchema = yup.object().shape({
  subdomain: yup
    .string()
    .trim()
    .test(
      Actions.maxSubdomainLength,
      ValidationMessages.maxSubdomainLength,
      tests.maxSubdomainLength,
    )
    .test(
      Actions.startEndAlphaNumeric,
      ValidationMessages.startEndWithAlphaNumeric,
      tests.startEndWithAlphaNumeric,
    )
    .test(Actions.noHyphenStartEnd, ValidationMessages.noHyphenStartEnd, tests.noHyphenStartEnd)
    .test(
      Actions.noConsecutiveHyphens,
      ValidationMessages.noConsecutiveHyphens,
      tests.noConsecutiveHyphens,
    )
    .test(
      Actions.validCharsSubdomain,
      ValidationMessages.validCharsSubdomain,
      tests.validCharsSubdomain,
    )
    .required(ValidationMessages.subdomainRequired),
});
