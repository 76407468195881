export enum LoanPresetStateEnum {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  DELETED = 'deleted',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum LoanPresetCreateTypeEnum {
  DBP = 'dbp',
  AFFILIATE = 'affiliate',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum LoanPresetCreateWhoIsPayingEnum {
  PARTNER = 'partner',
  END_USER = 'end_user',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum LoanPresetCreateFlowTypeEnum {
  REQUEST = 'request',
  OFFER = 'offer',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type PartnerDeepLinkSettings = {
  type: PartnerDeepLinkTypes;
  feeEnabled: boolean;
  whoIsPaying: PayingSide;
  feeAmountPercentage: number;
  flowType?: FlowType;
  partnerComissionEnabled?: boolean;
  partnerComissionPercentageAmount?: number;
};

export type LoanPresetDto = {
  uuid: string;
  partnerId: string;
  state: LoanPresetStateEnum;
  link?: string;
  shortLink?: string;
  displayName?: string;
  settings?: PartnerDeepLinkSettings;
  useFiona: boolean;
  skipKYC: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type LoanPresetSearchResponseDto = {
  total: number;
  presets: LoanPresetDto[];
};

export type LoanPresetUpdateDto = {
  uuid: string;
  displayName?: string;
  type: PartnerDeepLinkTypes;
  feeEnabled?: boolean;
  whoIsPaying?: LoanPresetCreateWhoIsPayingEnum;
  feeAmountPercentage: number;
  flowType?: LoanPresetCreateFlowTypeEnum;
  partnerComissionEnabled?: boolean;
  partnerComissionPercentageAmount?: number;
  useFiona: boolean;
  skipKYC: boolean;
};

export type LoanPresetCreateDto = {
  partnerId: string;
  displayName?: string;
  type: LoanPresetCreateTypeEnum;
  feeEnabled: boolean;
  whoIsPaying: LoanPresetCreateWhoIsPayingEnum;
  feeAmountPercentage: number;
  flowType?: LoanPresetCreateFlowTypeEnum;
  partnerComissionEnabled?: boolean;
  partnerComissionPercentageAmount?: number;
  useFiona: boolean;
  skipKYC: boolean;
};

export type LoanPresetCreatedDto = {
  uuid: string;
  link: string;
  shortLink: string;
};

export enum PayingSide {
  PARTNER = 0,
  END_USER = 1,
}

export enum LoanType {
  PERSONAL_LOANS = 'Generate Personal Loans',
  DIRECT_BILL_PAY_LOANS = 'Generate Direct Bill Pay Loans',
}

export enum PartnerDeepLinkTypes {
  DBP = 'dbp',
  AFFILIATE = 'affiliate',
}

export enum FlowType {
  LOAN_OFFER = 'Loan offer',
  LOAN_REQUEST = 'Loan request',
}

export enum DeeplinkStatus {
  NO_GENERATED = 0,
  GENERATED = 1,
  ACTIVATED = 2,
}

export enum DeeplinkAlert {
  GENERATE = 'New campaign generated!',
  ACTIVATE = 'Campaign activated!',
  UPDATE = 'Campaign updated!',
}

export type PartnersDeeplinksFormType = {
  uuid: string;
  displayName?: string;
  type: PartnerDeepLinkTypes | string;
  feeEnabled: boolean | null;
  whoIsPaying: PayingSide | null;
  feeAmountPercentage: number | string;
  flowType?: string | null;
  partnerComissionEnabled?: boolean | null | undefined;
  partnerComissionPercentageAmount?: number | string | undefined;
  directBillPay?: {
    type: PartnerDeepLinkTypes | null;
    feeEnabled: boolean | null;
    whoIsPaying: PayingSide | null;
    feeAmountPercentage: number | string;
  };
  affiliate?: {
    type: PartnerDeepLinkTypes | null;
    flowType?: FlowType | LoanPresetCreateFlowTypeEnum | null;
    feeEnabled: boolean | null;
    whoIsPaying: PayingSide | null;
    feeAmountPercentage: number | string;
    partnerComissionEnabled: boolean | null | undefined;
    partnerComissionPercentageAmount: number | string | undefined;
  };
  useFiona: boolean | null;
  skipKYC: boolean | null;
};
