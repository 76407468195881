import React, { useCallback, useRef } from 'react';
import Popup from 'reactjs-popup';

import { Icon } from '../../../shared';

type Action = {
  id: string;
  text: string;
  iconName?: string;
  additionalClass?: string;
};

export type QuickActionsProps = {
  onPress?: (id: string) => void;
  actions: Array<Action>;
};

const QuickActions: React.FC<QuickActionsProps> = ({
  // @ts-ignore
  children,
  onPress,
  actions,
}: QuickActionsProps) => {
  const popupRef = useRef(null);

  const handleActionClick = useCallback(
    (id: string) => {
      if (onPress) {
        onPress(id);
      }
    },
    [onPress],
  );

  const handleClose = () => {
    if (popupRef) {
      // @ts-ignore
      popupRef.current.close();
    }
  };

  return (
    <div className="quick-actions">
      <Popup
        trigger={children}
        position="top right"
        on="click"
        closeOnDocumentClick
        arrow={false}
        ref={popupRef}
      >
        <ul className="quick-actions__menu" role="presentation" onClick={handleClose}>
          {actions.map((action) => (
            <li className="quick-actions__menu-item" key={action.id}>
              <a
                href="/"
                className={`quick-actions__menu-link ${action.additionalClass}`}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) => {
                  e.preventDefault();
                  handleActionClick(action.id);
                }}
              >
                {action.iconName && (
                  <Icon name={action.iconName} classes="quick-actions__menu-icon" />
                )}
                {action.text}
              </a>
            </li>
          ))}
        </ul>
      </Popup>
    </div>
  );
};

export default QuickActions;
