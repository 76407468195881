import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { PaymentScheduleDetailedDto, PlanType } from '../index';

type GetScheduleRequest = {
  type: PlanType | string;
  amount: number;
  currency: string;
  numOfPayments?: number;
  oneTimeDueDays?: number;
  isBillPay: boolean;
  isRepaymentRequest?: boolean;
  partnerDeeplinkId?: string;
};

export const scheduleApi = createAsyncThunk<
  PaymentScheduleDetailedDto,
  GetScheduleRequest,
  { dispatch: AppDispatch; state: RootState }
>('schedule', async (payload) => {
  const { data } = await api.get<PaymentScheduleDetailedDto>('/schedule', {
    params: payload,
  });

  return data;
});
