import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../main/store';
import { partnersAdapter } from '../entityAdapters';

export const partnersCollectionSelectors = partnersAdapter.getSelectors<RootState>(
  (state) => state.platformAdmin.partners.collection,
);

const selectRoot = (root: RootState) => root;

export const selectPartnersState = (root: RootState) => root.platformAdmin.partners;

export const selectPartnersPagination = createSelector(
  [selectPartnersState],
  ({ total, page, pageSize, pagesCount }) => ({ total, page, pageSize, pagesCount }),
);

export const selectPartnerById = (root: RootState, id?: string) =>
  id ? partnersCollectionSelectors.selectById(root, id) : undefined;

export const selectSelectedPartnerId = (root: RootState) =>
  root.platformAdmin.partners.selectedItemId;

export const selectSelectedPartner = createSelector(
  [selectRoot, selectSelectedPartnerId],
  selectPartnerById,
);
