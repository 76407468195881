import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { ServerError } from '../../../../shared';
import StoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import {
  AuthTokenCreateModel,
  AuthTokenCreateResponse,
  AuthTokenCreateResponseDto,
} from '../../../../shared/models/AuthToken';

import type { AppDispatch, RootState } from '../../../../main/store';

export const refreshToken = createAsyncThunk<
  AuthTokenCreateResponse,
  undefined,
  { dispatch: AppDispatch; rejectValue: ServerError; state: RootState }
>('auth/refreshToken', async (payload, { rejectWithValue, getState }) => {
  try {
    const { data } = await api.post<AuthTokenCreateResponseDto>('/auth/token/refresh', {
      refreshToken: getState().auth.authToken?.refreshToken,
    });
    const response = AuthTokenCreateModel.fromDTO(data);
    localStorage.setItem(StoreKeys.authToken, JSON.stringify(response));

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
