import React from 'react';

import { useAppSelector } from '../../../../main/store/hooks';
import { FeeType, LoanType, RepaymentType } from '../../../../shared/models/platform-admin';
import { formatBoolean, formatDate, roundToTwoDecimals } from '../../../../shared/utils';
import { selectSelectedLoan } from '../../store/selectors/loans';
import { makeStatus, makeUserName } from '../../utils';

const LoanSummary = () => {
  const loan = useAppSelector(selectSelectedLoan);
  const {
    processingFee,
    convenienceFee,
    totalFeeAmount,
    amount,
    nextRepaymentAmount,
    loanType,
    repaymentType,
    creator,
  } = loan || {};

  const { value: loanAmount = 0, currency: loanCurrency } = amount || {};
  const { value: feeAmount = 0 } = totalFeeAmount || {};
  const totalAmount = roundToTwoDecimals(loanAmount + feeAmount);

  const displayLoanType = loanType === LoanType.BILL_PAY ? 'Direct Bill Pay' : 'P2P';
  const displayRepaymentType = repaymentType === RepaymentType.ONE_TIME ? 'One time' : 'Monthly';

  return loan ? (
    <div className="card">
      <h2 className="card__title">Loan Summary</h2>
      <table className="user-profile__table">
        <tbody>
          <tr>
            <td>Loan UUID</td>
            <td>{loan.uuid}</td>
          </tr>
          <tr>
            <td>Total Amount</td>
            <td>{`${totalAmount} ${loanCurrency}`}</td>
          </tr>
          <tr>
            <td>Loan Amount</td>
            <td>{`${loanAmount} ${loanCurrency}`}</td>
          </tr>
          <tr>
            <td>Fee Amount</td>
            <td>{totalFeeAmount ? `${totalFeeAmount.value} ${totalFeeAmount.currency}` : '-'}</td>
          </tr>
          <tr>
            <td>Processing Fee Amount</td>
            <td>
              {processingFee?.value
                ? `${processingFee.value.amount} ${
                    processingFee.value.kind === FeeType.FIXED ? loanCurrency : '%'
                  }`
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Convenience Fee Amount</td>
            <td>
              {convenienceFee
                ? `${convenienceFee.value.amount} ${
                    convenienceFee.value.kind === FeeType.FIXED ? loanCurrency : '%'
                  }`
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Fee Enabled</td>
            <td>{formatBoolean(loan.feeEnabled)}</td>
          </tr>
          <tr>
            <td> Loan Status</td>
            <td>{makeStatus(loan.stage)}</td>
          </tr>
          <tr>
            <td>Loan Type</td>
            <td>{displayLoanType}</td>
          </tr>
          <tr>
            <td>Lender Initiated</td>
            <td>{formatBoolean(loan.isLendLoan)}</td>
          </tr>
          <tr>
            <td>Repayment Request</td>
            <td>{formatBoolean(!!loan.isRepaymentRequest)}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h3 className="user-profile__sub-title">Loan Request</h3>
            </td>
          </tr>
          <tr>
            <td>Created By</td>
            <td>{makeUserName(creator)}</td>
          </tr>
          <tr>
            <td>Loan Request Created</td>
            <td>{formatDate(loan.createdAt)}</td>
          </tr>
          <tr>
            <td>Loan Request Accepted</td>
            <td>-</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h3 className="user-profile__sub-title">Repayment Plan</h3>
            </td>
          </tr>
          <tr>
            <td>Repayment Type</td>
            <td>{displayRepaymentType || '-'}</td>
          </tr>
          <tr>
            <td>Repayment Duration</td>
            <td>{loan.repaymentDurationMonths}</td>
          </tr>
          <tr>
            <td>Repayment Start</td>
            <td>{formatDate(loan.repaymentDateFrom) || '-'}</td>
          </tr>
          <tr>
            <td>Repayment End Date</td>
            <td>{formatDate(loan.repaymentDateTo) || '-'}</td>
          </tr>
          <tr>
            <td>Next Repayment Amount</td>
            <td>
              {nextRepaymentAmount
                ? `${nextRepaymentAmount.value} ${nextRepaymentAmount.currency}`
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Next Repayment Date</td>
            <td>{formatDate(loan.nextPaymentDate) || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div>Selected loan was not found.</div>
  );
};

export default LoanSummary;
