import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import Button from '../../../shared/components/Button';
import { getTimePeriod } from '../../../shared/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import OverviewMetrics from '../components/LoanAnalytics/OverviewMetrics';
import Filters from '../components/OverView/Filters';
import { getLoansAnalytics } from '../store/actions/getLoansAnalytics';

export default function LoanAnalyticsPage() {
  const dispatch = useAppDispatch();
  const { loanAnalytics } = useAppSelector((state) => state.loans);
  const { partner } = useAppSelector((state) => state.auth);
  const history = useHistory();

  const handleFilterChange = useCallback(({ reason, periodTime }) => {
    dispatch(
      // @ts-ignore
      getLoansAnalytics({
        reason,
        periodTime,
        types: [
          'TOTAL_LOANS_CREATED',
          'TOTAL_LOANS_ACCEPTED',
          'DISBURSEMENT',
          'TOTAL_LOANS_REPAID',
          'DELINQUENCY_RATE',
          'REASONS_FOR_LOANS',
          'BORROWER_TYPE',
        ],
      }),
    );
  }, []);

  useEffect(() => {
    handleFilterChange({ reason: 'ANY', periodTime: getTimePeriod(30) });
  }, []);

  if (!loanAnalytics) {
    return null;
  }

  return (
    <DashboardLayout>
      {partner && (
        <>
          <div className="loans-overview__title-wrapper">
            <h2 className="main-title">Analytics</h2>
            <div style={{ position: 'absolute', top: -10, right: 130 }}>
              <Filters isReasonLabel={false} disabled={false} onChange={handleFilterChange} />
            </div>
            <Button
              className="button button--primary-blue button--lg loans-overview__create-button"
              icon="plus"
              onClick={() => {
                history.push('/loan-creation');
              }}
            >
              Create loan
            </Button>
          </div>

          <OverviewMetrics loanAnalytics={loanAnalytics} />
        </>
      )}
    </DashboardLayout>
  );
}
