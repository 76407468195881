import React from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { InvitedMember } from '../../../shared/models/PartnerInvitedMembersDto';
import { setModalSuccessOpen, AlertType, addAlert } from '../../../shared/store/modals';
import { getSecurityGroupInfo } from '../../../shared/utils/helpers';
import { hasRole, isAdmin } from '../../auth/utils';
import { setModalInvitationSentOpen, setModalInvitationSentText } from '../store';
import { sendInvitaion } from '../store/actions';

import MemberNameAvatar from './MemberNameAvatar';
import QuickActions from './QuickActions';

type Props = {
  member: InvitedMember;
};

const InvitedMemberRow = ({ member: item }: Props) => {
  const dispatch = useAppDispatch();

  const { roles } = useAppSelector((state) => state.company, shallowEqual);
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const canManage =
    authInfo && (hasRole(authInfo, Permissions.MEMBERS_MANAGE) || isAdmin(authInfo));
  const groupUuid = item.securityGroups && item.securityGroups[0];
  const roleInfo = groupUuid && getSecurityGroupInfo(groupUuid, roles);

  const resendInvite = async () => {
    const result = await dispatch(
      sendInvitaion({ emailsUris: item.contacts, role: item.securityGroups[0] }),
    );

    if (sendInvitaion.fulfilled.match(result)) {
      dispatch(setModalInvitationSentText(''));
      dispatch(setModalInvitationSentOpen(true));
    }
    if (sendInvitaion.rejected.match(result)) {
      dispatch(addAlert({ text: 'Invitation sending error.', type: AlertType.error }));
    }
  };

  return (
    <tr className="table__data-row">
      <td className="table__cell table__cell--data table__cell--name">
        <MemberNameAvatar name="Team member" />
      </td>
      <td className="table__cell table__cell--data">
        {item.contactUri ? item.contactUri.replace('mailto:', '') : ''}
      </td>
      <td className="table__cell table__cell--data">
        <span className="table-highlight">Invitation Sent</span>
      </td>
      <td className="table__cell table__cell--data">
        {roleInfo && (
          <span className={`role role--${roleInfo.color} table-highlight`}>{roleInfo.name}</span>
        )}
      </td>
      <td className="table__cell table__cell--data table__cell--quick-actions">
        {canManage && (
          <QuickActions
            actions={[
              {
                id: 'resend',
                text: 'Resend invite',
                iconName: 'plane',
                additionalClass: 'quick-actions__menu-link--blue',
              },
            ]}
            onPress={(id: string) => {
              switch (id) {
                case 'resend':
                  resendInvite();
                  break;

                default:
                  break;
              }
            }}
          >
            <button type="button" className="table-dots">
              <Icon name="dots" classes="table-dots__icon" />
            </button>
          </QuickActions>
        )}
      </td>
    </tr>
  );
};

export default React.memo(InvitedMemberRow);
