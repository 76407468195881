import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { parseError } from '../../../../shared/utils/helpers';
import { LoanCreationLimitationsDto } from '../../dtos/LoanCreationLimitationsDto';

export const getLimitations = createAsyncThunk<
  LoanCreationLimitationsDto,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('partners/getLimitations', async (_, { rejectWithValue, getState }) => {
  try {
    const { data } = await api.get<LoanCreationLimitationsDto>(`/partners/limitations`);

    return data;
  } catch (e) {
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
