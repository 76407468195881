import * as yup from 'yup';

const depositsSchemas = yup.object({
  firstMicroDeposit: yup
    .number()
    .positive()
    .max(1)
    .typeError('you must specify a number')
    .label('First micro deposit')
    .required(),
  secondMicroDeposit: yup
    .number()
    .positive()
    .max(1)
    .typeError('you must specify a number')
    .label('Second micro deposit')
    .required(),
});

export { depositsSchemas };
