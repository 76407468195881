import { useAppSelector } from '../../../../main/store/hooks';
import { FlowTypes } from '../../../auth/store';
import { ReactComponent as BlueDot } from '../../assets/blue-dot.svg';
import { ReactComponent as GrayDot } from '../../assets/gray-dot.svg';

export default function SelectionFlowSteps() {
  const { currentStepSetup, selectedFlow } = useAppSelector((state) => state.auth);

  const initBillPaySetupSteps = [
    {
      id: 1,
      name: 'Get started',
    },
    {
      id: 2,
      name: 'Business information',
    },
    {
      id: 3,
      name: 'Bank account',
    },
    {
      id: 4,
      name: 'Launch',
    },
  ];

  const initAffiliatePartnerSetupSteps = [
    {
      id: 1,
      name: 'Get started',
    },
    {
      id: 2,
      name: 'Launch',
    },
  ];

  return (
    <div className="selectionFlow__stepsBlock">
      {selectedFlow === FlowTypes.AFFILIATE_PARTNER
        ? initAffiliatePartnerSetupSteps.map((el) => (
            <div key={el.id} className="selectionFlow__stepsBlock__step">
              <div className="selectionFlow__stepsBlock__step__marker">
                {currentStepSetup === el.id ? <BlueDot /> : <GrayDot />}
              </div>
              <div className="selectionFlow__stepsBlock__step__info">
                <div className="selectionFlow__stepsBlock__step__number">
                  Step
                  <span> {el.id}</span>
                </div>
                <div
                  className={`selectionFlow__stepsBlock__step__name ${
                    currentStepSetup === el.id
                      ? 'selectionFlow__stepsBlock__step__name--active'
                      : ''
                  }`}
                >
                  {el.name}
                </div>
              </div>
            </div>
          ))
        : initBillPaySetupSteps.map((el) => (
            <div key={el.id} className="selectionFlow__stepsBlock__step">
              <div className="selectionFlow__stepsBlock__step__marker">
                {currentStepSetup === el.id ? <BlueDot /> : <GrayDot />}
              </div>
              <div className="selectionFlow__stepsBlock__step__info">
                <div className="selectionFlow__stepsBlock__step__number">
                  Step
                  <span> {el.id}</span>
                </div>
                <div
                  className={`selectionFlow__stepsBlock__step__name ${
                    currentStepSetup === el.id
                      ? 'selectionFlow__stepsBlock__step__name--active'
                      : ''
                  }`}
                >
                  {el.name}
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}
