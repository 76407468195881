import React from 'react';

interface UserNavProfile {
  name: string;
  text: string;
  small?: boolean;
  icons: string;
}

export const UserNavProfile: React.FC<UserNavProfile> = ({ name, text, small = false, icons }) => (
  <div className="user-nav-profile">
    <img
      src={icons}
      alt={name}
      className={small ? 'user-nav-profile__avatar--small' : 'user-nav-profile__avatar'}
    />
    <div className="user-nav-profile__data">
      <p className={small ? 'user-nav-profile__name--small' : 'user-nav-profile__name'}>{name}</p>
      <p className="user-nav-profile__type">{text}</p>
    </div>
  </div>
);
