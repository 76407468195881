import classNames from 'classnames';
import React, { useState, ChangeEventHandler, ChangeEvent, useEffect } from 'react';

interface RadioProps {
  id: string;
  items: string[];
  value: string;
  disabled?: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
}
const Radio = ({ id, onChange, items, value, disabled }: RadioProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <>
      {items.map((i) => (
        <div key={i}>
          <label className={classNames('radio', { 'radio--disabled': i === disabled })}>
            <input
              type="radio"
              name={id}
              className="radio__input"
              value={i}
              onChange={handleChange}
              checked={i === currentValue && i !== disabled}
              disabled={i === disabled}
            />
            <span className="radio__label">{i}</span>
          </label>
        </div>
      ))}
    </>
  );
};

export default Radio;
