import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { api } from '../../../main/network';
import { useAppDispatch } from '../../../main/store/hooks';
import StoreKeys from '../../../shared/constants/AsyncStoreKeys';
import { AuthTokenCreateModel, AuthTokenCreateResponseDto } from '../../../shared/models/AuthToken';
import { AuthBannerContent } from '../components/AuthBanner';
import { AuthHeaderContent } from '../components/AuthHeader';
import AuthorizationLayout from '../layout/AuthorizationLayout';
import { setIsAuthenticated } from '../store';
import { deleteCookie, getCookie } from '../utils';

const VerifySignIn = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const refreshAuthToken = async (refreshToken: string) => {
    try {
      const { data } = await api.post<AuthTokenCreateResponseDto>('/auth/token/refresh', {
        refreshToken,
      });
      const response = AuthTokenCreateModel.fromDTO(data);
      localStorage.setItem(StoreKeys.authToken, JSON.stringify(response));
      localStorage.setItem(StoreKeys.isAuthenticated, 'true');
      dispatch(setIsAuthenticated(true));
      deleteCookie(StoreKeys.refreshToken);

      history.push('/init-setup');
    } catch (error) {
      history.push('/signin');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const refreshToken = getCookie(StoreKeys.refreshToken);

    if (refreshToken) {
      refreshAuthToken(refreshToken);
    } else {
      history.push('/signin');
    }
  }, []);

  return (
    <AuthorizationLayout
      headerContent={AuthHeaderContent.SignUp}
      bannerContent={AuthBannerContent.Verify}
    >
      <div>{loading && 'Redirecting to dashboard...'}</div>
    </AuthorizationLayout>
  );
};

export default VerifySignIn;
