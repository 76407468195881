import React, { PropsWithChildren } from 'react';

import { AdminTableName } from '../../../features/platform-admin/store';

import { DataTableColumnConfig } from './DataTableColumnConfig';
import { DataTableHeader } from './DataTableHeader';
import { DataTableRow } from './DataTableRow';

interface DataTableProps<TRowType> {
  columns: DataTableColumnConfig<TRowType>[];
  data: TRowType[];
  getId: (o: TRowType) => string;
  onRowClick?: (row: TRowType) => unknown;
  selectedId?: string;
  name: AdminTableName;
}

export function DataTable<TRowType>(props: PropsWithChildren<DataTableProps<TRowType>>) {
  const { columns, data, getId, onRowClick, selectedId, name } = props;

  return (
    <div className="table-scroll">
      <div className="table-scroll__box">
        <table className="table">
          <tbody className="table__body">
            <DataTableHeader columns={columns} name={name} />
            {data.map((row, i) => (
              <DataTableRow
                key={getId(row)}
                columns={columns}
                row={row}
                onClick={onRowClick}
                selected={getId(row) === selectedId}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
