import React from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { DateInput, Icon, Input, Label } from '../../../shared';
import Modal from '../../../shared/components/Modal';
import { setModalRefundedOpen } from '../store';

type Props = {
  classes?: string;
};

export default function ModalRefunded({ classes }: Props) {
  const { modalRefundedOpen: opened } = useAppSelector((state) => state.payments);
  const { refundInfo } = useAppSelector((state) => state.payments, shallowEqual);
  const dispatch = useAppDispatch();

  return (
    <Modal
      opened={opened}
      handleClose={() => {
        dispatch(setModalRefundedOpen({ open: false }));
      }}
      classes={classes}
    >
      <div className="modal-content modal-content--mint-top">
        <button
          type="button"
          className="modal-content__close"
          onClick={() => {
            dispatch(setModalRefundedOpen({ open: false }));
          }}
        >
          <Icon name="cross" classes="modal-content__close-icon" />
        </button>
        {/* Illustration */}
        <div className="modal-content__illustration modal-content__illustration--hand-with-coin">
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv1"
            src="../images/dashboard/modals/refund-lv1.svg"
            alt=""
          />
          <picture>
            <source
              srcSet="../images/dashboard/modals/hand@1x.webp 1x, ../images/dashboard/modals/hand@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="../images/dashboard/modals/hand@1x.png"
              srcSet="../images/dashboard/modals/hand@2x.png 2x"
              alt=""
              className="modal-content__illustration-img modal-content__illustration-img--lv2"
            />
          </picture>
          <img
            className="modal-content__illustration-img modal-content__illustration-img--lv3"
            src="../images/dashboard/modals/refund-lv3.svg"
            alt=""
          />
        </div>
        {/* Content */}
        <h2 className="modal-content__title">Customer refund</h2>
        <p className="modal-content__text">Submission form of a customer’s refund.</p>
        {/* Form */}
        <form className="modal-content__form" action="/">
          {/* Form group */}
          <div className="form-group">
            <Label forId="name">Submitted by</Label>
            <Input
              type="text"
              id="name"
              value={refundInfo?.submittedBy}
              prependImageUrl={refundInfo?.photo} // TODO: Needs to change after update RefundInfo model
              isShowImage
              disabled
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="name">customer name</Label>
            <Input type="text" id="name" value={refundInfo?.customerName} disabled />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="account-number">Account number</Label>
            <Input
              type="text"
              id="account-number"
              value={refundInfo?.billingAccountNumber}
              disabled
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label
              forId="transaction-id"
              infoText="The transaction id for the initial transaction."
            >
              Transaction ID
            </Label>
            <Input type="text" id="transaction-id" value={refundInfo?.transactionId} disabled />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="payment-date">Payment date</Label>
            <DateInput
              id="payment-date"
              disabled
              value={refundInfo?.paymentDate}
              dateMask="MMM dd, yyyy hh:mm:ss a"
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label forId="amount-to-refund">Amount to refund</Label>
            <Input
              id="amount-to-refund"
              type="text"
              prependIconName="dollar-circle"
              placeholder="Enter an amount"
              value={`${refundInfo?.amount?.value}`}
              disabled
            />
          </div>
          {/* Form group */}
          <div className="form-group">
            <Label
              forId="reason-for-refund"
              infoText="The reason the refund was submitted. Please exclude any sensitive information."
            >
              Reason for refund
            </Label>
            <Input
              type="textarea"
              id="amount-to-refund"
              placeholder="Tell us why you would like to refund this customer"
              value={refundInfo?.reason}
              disabled
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
