import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { useLazyEffect } from '../../../shared';
import { UserRoleString, UsersVerificationStatus } from '../../../shared/models/platform-admin';
import { UserRolesMatch } from '../../../shared/models/platform-admin/users/UsersRolesMatch';
import { adminUsersSearch } from '../store/actions';
import { selectUsersState } from '../store/selectors/users';

export const useUsersTableSearchEffect = () => {
  const dispatch = useAppDispatch();
  const {
    page,
    pageSize,
    searchQuery,
    rolesFilter,
    dateRangeFilter,
    dateRangeFilterField,
    dateRangeSettings,
    sortField,
    sortOrder,
    onboardType,
    verificationStatus,
  } = useAppSelector(selectUsersState);

  let status: UserRolesMatch | undefined;
  let roles: UserRoleString[] | undefined;

  switch (verificationStatus) {
    case UsersVerificationStatus.PARTIALLY_VERIFIED:
      status = UserRolesMatch.XOR;
      roles = [UserRoleString.EMAIL_VERIFIED, UserRoleString.PHONE_VERIFIED];
      break;
    case UsersVerificationStatus.FULLY_VERIFIED:
      status = UserRolesMatch.ALL;
      roles = [UserRoleString.EMAIL_VERIFIED, UserRoleString.PHONE_VERIFIED];
      break;
    case UsersVerificationStatus.GUEST:
      status = UserRolesMatch.ONLY;
      roles = [UserRoleString.GUEST];
      break;
    default:
      status;
      roles;
      break;
  }

  useLazyEffect(
    () => {
      const [dateStart, dateEnd] = dateRangeFilter || [];
      const data = {
        skip: (page - 1) * pageSize,
        limit: pageSize,
        searchText: searchQuery.replace('+', ''),
        roles: roles || rolesFilter,
        dateStart,
        dateEnd,
        rangeField: dateRangeFilterField,
        sortField,
        sortOrder,
        onboardType,
        rolesMatch: status,
      };
      dispatch(adminUsersSearch(data));

      if (!localStorage.getItem('users_settings')) {
        localStorage.setItem('users_settings', JSON.stringify({ ...data, dateRangeSettings }));
      }
    },
    [
      page,
      pageSize,
      searchQuery,
      rolesFilter,
      dateRangeFilter,
      dateRangeFilterField,
      sortField,
      sortOrder,
      onboardType,
      verificationStatus,
    ],
    300,
  );
};
