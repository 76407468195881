import React from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { FundingSource, FundingSourceModel } from '../../../shared/models/FundingSource';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { hasRole, isAdmin } from '../../auth/utils';
import { setModalVerifyFundingSource, setModalVerifyPaymentOpen, PaymentItem } from '../store';

import QuickPaymentActions from './QuickPaymentActions';

type PaymentProps = {
  item: FundingSource;
};

export default function PaymentRow({ item }: PaymentProps) {
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const dispatch = useAppDispatch();
  const canManage =
    authInfo && (hasRole(authInfo, Permissions.FINANCES_MANAGE) || isAdmin(authInfo));

  return (
    <div className="payment-row">
      <div className="payment-row__box">
        <div className="payment-row__info-box">
          <div className="payment-row__info-box-top">
            <div className="payment-row__item payment-row__icon-wrap">
              <div className="payment-row__icon-box">
                {/* <Icon name="master-card-logo" classes="payment-row__icon payment-row__icon--master-card" /> */}
                <Icon name="bank" classes="payment-row__icon payment-row__icon--visa" />
              </div>
            </div>
            <p className="payment-row__text payment-row__item">{`Bank account ending in ${item.redactedAccountNumber}`}</p>
          </div>
          {/* Verify */}
          {!FundingSourceModel.isVerified(item) && (
            <div className="payment-row__verify">
              <p className="payment-row__verify-text">
                <Icon name="attention-square" classes="payment-row__verify-icon" />
                <strong className="payment-row__verify-text-bold">
                  Verify bank account &nbsp;
                </strong>
                to start refunding customers via this account
              </p>
              <button
                type="button"
                className="payment-row__verify-btn"
                onClick={() => {
                  dispatch(setModalVerifyPaymentOpen(true));
                  dispatch(setModalVerifyFundingSource(item));
                  Amplitude.logEvent(AmplitudeEvents.bankAccountVerificationStarted);
                }}
              >
                Verify
              </button>
            </div>
          )}
        </div>
        {canManage && (
          <QuickPaymentActions paymentMethod={item}>
            <button type="button" className="table-dots">
              <Icon name="dots" classes="table-dots__icon" />
            </button>
          </QuickPaymentActions>
        )}
      </div>
    </div>
  );
}
