import { PaymentScheduleItemDto } from '../store';

export type LoanTransportFeeType = 'DEFAULT' | 'STANDARD' | 'EXPRESS' | 'DISABLED' | 'UNRECOGNIZED';

export type LoanScheduleValue = {
  kind: LoanTransportFeeType;
  displayName: string;
  description: string;
  cost?: string;
  scheduleJwt?: string;
  value: { kind: 'FIXED' | 'PERCENT' | 'UNRECOGNIZED'; amount: number };
  payments?: PaymentScheduleItemDto[];
  cta?: string;
};

export type RepaymentPlanPaymentDto = {
  history: PaymentHistoricalEntryDto[];
  currency: string;
  beginningBalance: number;
  endingBalance: number;
  amount: number;
  interest: number;
  plannedExecutionOn: string;
  failed: boolean;
};

export enum PaymentHistoricalEntryType {
  plannedFor = 'plannedFor',
  executedOn = 'executedOn',
  succeededOn = 'succeededOn',
  failedOn = 'failedOn',
  skippedOn = 'skippedOn',
  rescheduledOn = 'rescheduledOn',
  forgivenOn = 'forgivenOn',
}

export enum PaymentStatuses {
  nextUp = 'Next Up',
  upcoming = 'Upcoming',
  paid = 'Paid',
  processing = 'Processing',
  autoPay = 'Autopay',
  completed = 'Completed',
  inProgress = 'In progress',
  missed = 'Missed',
  failed = 'Failed',
  late = 'Late',
  forgiven = 'Forgiven',
}

export type PaymentHistoricalEntryDto = {
  timestamp: string;
  type: PaymentHistoricalEntryType;
};

export type RepaymentPlanDto = {
  installmentInterestPercentage: number;
  payments: RepaymentPlanPaymentDto[];
  processingFee?:
    | LoanScheduleValue
    | {
        kind: 'SELECT' | 'UNRECOGNIZED';
        value: Array<LoanScheduleValue>;
      };
  convenienceFee?: LoanScheduleValue;
};
