import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { UserActiveControlRequestDto } from '../../../../shared/models/platform-admin';

export interface AdminUserActiveControlResult {
  isActive: boolean;
  userId: string;
}

export const adminUserActiveControl = createAsyncThunk<
  AdminUserActiveControlResult,
  UserActiveControlRequestDto
>('platformAdmin/adminUserActiveControl', async ({ userId, isActive }) => {
  await api.put(`/platform-admin/users/${userId}/active`, { isActive });
  return { userId, isActive };
});
