import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import StoreKeys from '../../../../shared/constants/AsyncStoreKeys';
import {
  AuthTokenCreateResponseDto,
  AuthTokenCreateModel,
  AuthTokenCreateResponse,
} from '../../../../shared/models/AuthToken';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const forgotPasswordComplete = createAsyncThunk<
  AuthTokenCreateResponse,
  { token: string; password: string },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('auth/forgotPasswordComplete', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post<AuthTokenCreateResponseDto>(
      '/auth/forgot-password/complete',
      payload,
    );
    const response = AuthTokenCreateModel.fromDTO(data);
    localStorage.setItem(StoreKeys.authToken, JSON.stringify(response));

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
