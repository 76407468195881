import React, { ReactNode } from 'react';

import Typography from '../../../../shared/components/UI/Typography';

type FormWrapperProps = {
  title?: string;
  description?: string;
  children?: ReactNode;
  lastStep?: boolean;
};

export default function FormWrapper({ lastStep, title, description, children }: FormWrapperProps) {
  return (
    <>
      {title && (
        <div
          className={
            title === 'What will you use Zirtue for?' || 'Complete the registration requirements'
              ? 'init-setup__wrapper'
              : ''
          }
        >
          <Typography
            className={
              title === 'What will you use Zirtue for?' || 'Complete the registration requirements'
                ? 'init-setup__title'
                : ''
            }
            tag="h3"
            size="large-title-2"
            weight="bold"
            textAlign={`${lastStep ? 'text-center' : 'text-left'}`}
          >
            {title}
          </Typography>
          {description && (
            <Typography
              className={
                title === 'What will you use Zirtue for?' ||
                'Complete the registration requirements'
                  ? 'init-setup__descr'
                  : ''
              }
              tag="p"
              size="body"
              textAlign={`${lastStep ? 'text-center' : 'text-left'}`}
            >
              {description}
            </Typography>
          )}
        </div>
      )}
      {!lastStep && <div className="init-setup__FormWrapper">{children}</div>}
    </>
  );
}
