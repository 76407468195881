export const quicklinkButton = (link: string) => `
  <a href="${link}" target="_blank"
  onmousedown="this.style.backgroundColor = '#006ACC'; this.style.border = '1px solid #006ACC'"
  onmouseup="this.style.backgroundColor = '#004f99'; this.style.border = '1px solid #004f99'"
  onblur="this.style.backgroundColor = '#004f99'; this.style.border = '1px solid #004f99'"
  style="
    width : 343px;
    height: 56px;
    box-sizing: border-box;
    text-decoration: none;
    color: #FFF;
    display: flex; 
    align-items: center;
    justify-content:center;
    gap: 8px;
    padding: 12px 23px;
    background-color: #004F99;
    border: 1px solid #004F99;
    border-radius: 16px;
    transition: 0.2s ease;
    font-family: inherit;"
  >
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17" fill="none">
    <path d="M13.3 0.287325C11.9644 0.657778 10.7679 1.2505 9.50192 2.18404C8.54197 
      2.89531 7.13683 4.2734 7.37334 4.2734C7.4429 4.2734 8.27764 4.00667 9.2515 3.68067C11.2966
      2.9694 14.0652 2.34704 15.0947 2.34704C16.0824 2.34704 16.3607 2.52486 16.3607 3.16204C16.3607
      4.18449 14.4269 9.04483 13.1609 11.2083C12.9383 11.5935 12.7852 11.9195 12.7991 11.9492C12.827
      11.964 14.9695 11.2527 17.5571 10.3636C20.1448 9.45974 22.7743 8.55583 23.3864 8.34838L24.4994
      7.97793L23.7342 8.05202C20.8405 8.33356 20.8961 8.33356 20.9935 8.06684C21.0491 7.91865 21.1604
      7.35557 21.2439 6.8073C21.5917 4.36231 20.5761 1.97659 18.6562 0.791141C17.3346 -0.0386737
      15.1642 -0.231309 13.3 0.287325Z" fill="white"/>
    <path d="M12.4659 4.08145C12.285 4.12591 11.1303 4.54081 9.89208 5.01499C6.90094 6.15599 3.20026
      7.44516 1.75338 7.83044C1.0995 8.00825 0.543012 8.18607 0.501275 8.23052C0.459539 8.27498 1.44731
      8.27498 2.69942 8.24534C4.49411 8.18607 4.95321 8.20089 4.89756 8.34907C4.67497 9.00107 4.50802
      10.5125 4.56367 11.4905C4.70279 13.6688 5.63492 15.3136 7.27657 16.2323C8.45911 16.8991 9.3495
      17.0769 10.9912 16.9732C13.2171 16.8398 15.1927 15.9656 17.2378 14.217C18.1977 13.3872 19.8116
      11.4609 19.6724 11.2979C19.6307 11.2682 18.1003 11.9054 16.2778 12.7352C14.4414 13.5502 12.3824
      14.4393 11.6729 14.6912L10.3929 15.1654L9.25211 14.9135C8.62606 14.7801 8.08348 14.6171
      8.04174 14.5727C8.00001 14.5282 8.16695 14.217 8.40346 13.8614C10.5599 10.7644 12.0763
      8.17125 12.6189 6.64499C12.925 5.78554 13.1476 3.96291 12.9389 3.99254C12.8693 4.00736
      12.6606 4.05182 12.4659 4.08145Z" fill="white"/>
  </svg>
  <span>Zirtue pay</span>
</a>
`;
