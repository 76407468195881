import { FC } from 'react';

import { Icon } from '../../../shared';

export interface HeaderMenuItem {
  id: string;
  title: string;
  iconName: string;
  onClick: () => void;
  children?: JSX.Element;
}

type HeaderMenuItemProps = HeaderMenuItem & { index: number };

export const HeaderMenuItem: FC<HeaderMenuItemProps> = ({
  title,
  iconName,
  onClick,
  children,
  index,
}) => (
  <li className="dashboard-header__menu__item">
    <div
      role="button"
      className="dashboard-header__menu__item__wrapper"
      onClick={onClick}
      tabIndex={index}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
    >
      <Icon name={iconName} />
      <span className="dashboard-header__menu__item__title">{title}</span>
    </div>
    {children}
  </li>
);
