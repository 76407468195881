import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { getPartner } from '../../auth/store/actions';
import { setClientId } from '../../auth/store/actions/setClientId';
import DevToolsLayout from '../layout/DevToolsLayout';

export default function ModalDocs() {
  const { partner } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const copyClipboard = (value: string, field: string) => {
    navigator.clipboard.writeText(value);
    dispatch(addAlert({ text: `${field} was successfully copied`, type: AlertType.info }));
  };

  const newClientId = async () => {
    if (partner?.uuid) {
      const clientId = await dispatch(setClientId());
      if (setClientId.fulfilled.match(clientId)) {
        setTimeout(async () => {
          await dispatch(getPartner());
        }, 1000);
      }
      if (setClientId.rejected.match(clientId)) {
        dispatch(
          addAlert({
            text: 'ClientId could not be established. Contact administrator or support.',
            type: AlertType.error,
          }),
        );
      }
    }
  };

  const copyButton = (value: string, field: string) => (
    <div className="user-profile__copy-field">
      {value}
      <button
        className="button button--link table__copy-btn"
        type="button"
        onClick={() => copyClipboard(value, field)}
      >
        <span className="">
          <Icon name="copy" />
        </span>
      </button>
    </div>
  );

  return (
    <DevToolsLayout>
      <h3 className="dev-tools__title">Modal API Client ID</h3>
      <h4 className="dev-tools__subtitle">Send this with your modal requests.</h4>
      <div className="dev-tools__block clientid">
        <div className="dev-tools__block-name">Client ID:</div>
        {partner?.details.clientId && copyButton(partner?.details.clientId, 'Partner ID')}
        {!partner?.details.clientId && (
          <button type="submit" onClick={newClientId}>
            Request Client ID
          </button>
        )}
      </div>
    </DevToolsLayout>
  );
}
