import { FC } from 'react';

import { Modal } from '../../../shared';
import Button from '../../../shared/components/Button';
import { Typography } from '../../init-setup/components';

interface DeleteModalProps {
  onCancel: () => void;
  onDelete: () => void;
  isDeleteModal: boolean;
}

const DeleteModal: FC<DeleteModalProps> = ({ onCancel, onDelete, isDeleteModal }) => (
  <Modal opened={isDeleteModal}>
    <div className="bank__delete__modal bank__delete__modal--padding">
      <div className="bank__delete__modal__image modal-content__illustration modal-content__illustration--confirm">
        <img
          className="modal-content__illustration-img modal-content__illustration-img--lv1"
          src="/images/dashboard/modals/delete-lv1.svg"
          alt=""
        />
        <picture>
          <source
            srcSet="/images/dashboard/modals/confirm-lv2@1x.webp 1x, /images/dashboard/modals/confirm-lv2@2x.webp 2x"
            type="image/webp"
          />
          <img
            src="/images/dashboard/modals/confirm-lv2@1x.png"
            srcSet="/images/dashboard/modals/confirm-lv2@2x.png 2x"
            alt=""
            className="modal-content__illustration-img modal-content__illustration-img--lv2"
          />
        </picture>
        <img
          className="modal-content__illustration-img modal-content__illustration-img--lv3"
          src="/images/dashboard/modals/confirm-lv3.svg"
          alt=""
        />
      </div>
      <Typography tag="h3" className="bank__delete__modal__title" size="title-3" weight="bold">
        Delete webhook subscription?
      </Typography>
      <Typography textAlign="text-center" tag="p" className="bank__delete__modal__description">
        Don’t want to receive webhook notifications for a while? You can also disable your
        subscription events by clicking on the Configure option.
      </Typography>
      <div className="bank__delete__modal__policy-info">
        <Typography tag="p" colors="text-disabled">
          By clicking “Delete” you confirm the deletion of this subscription.
        </Typography>
      </div>
      <div style={{ bottom: '110px' }} className="line" />
      <div className="modal-content__btn-delete-box">
        <Button
          type="button"
          className="button button--outline-gray button--lg modal-content__btn--delete"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="button button--warning-red button--lg  modal-content__btn--delete"
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>

      <Button
        type="button"
        className="button bank__plaid__modal__close-button"
        onClick={onCancel}
        icon="cross"
      />
    </div>
  </Modal>
);

export default DeleteModal;
