import React from 'react';

import { Icon, Modal } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import modalCard from '../../assets/modal-card.svg';

type TProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModalLoanCoverage({ isOpen, setIsOpen }: TProps) {
  return (
    <Modal
      opened={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      classes="modal--md"
    >
      <div className="loans__modal">
        <button
          type="button"
          className="modal-content__close loans__modal__close"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="icon-cancel" classes="modal-content__close-icon" />
        </button>
        <div className="loans__modal__overtitle">Zirtue X Payment Guard by TruStage™</div>
        <h2 className="loans__modal__title">Your loan is covered!</h2>
        <div className="loans__modal__body">
          <div className="loans__modal__modalCard">
            <img src={modalCard} alt="modalCard" />
          </div>
          <div className="loans__modal__text">
            At no additional cost to you, active Zirtue loans are protected by Payment Guard
            Insurance from TruStage™.
          </div>
          <div className="loans__modal__text">
            <div className="loans__modal__subtitle">How it works?</div>
            If you experience a covered job loss or disability, you can file a claim with TruStage™
            Payment Guard Insurance.
          </div>
          <div className="loans__modal__text">
            Terms and conditions apply. See program and coverage details.
          </div>
        </div>
        <div className="loans__modal__footer">
          <Button
            type="button"
            className="button button--primary-blue button--lg button--block"
            onClick={() => {
              setIsOpen(false);
              window.open('https://paymentguard.trustage.com/?did=zirtue', '_blank');
            }}
          >
            Review my loan coverage
          </Button>
        </div>
      </div>
    </Modal>
  );
}
