import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { Icon } from '../../../../shared';
import { LoanDetails } from '../../../../shared/models';
import { formatDollarAmount, roundToTwoDecimals } from '../../../../shared/utils';
import { LoanClosureType, LoanStageKind } from '../../dtos/LoanTypes';
import ModalLoanCoverage from '../ModalLoanCoverage/ModalLoanCoverage';
import ModalServiceFee from '../ModalServiceFee/ModalServiceFee';

type TProps = {
  loanDetails: LoanDetails | undefined;
  displayBorrowerName: string | undefined;
};

export default function LoanSummary({ loanDetails, displayBorrowerName }: TProps) {
  const formattedDate = (isoDate: string) => format(parseISO(isoDate), 'MMMM d, yyyy');

  const [isModalServiceFeeOpen, setIsModalServiceFeeOpen] = useState(false);
  const [isModalLoanCoverageOpen, setIsModalLoanCoverageOpen] = useState(false);

  const [isLoansDetailsPopUpOpen, setIsLoansDetailsPopUpOpen] = useState(false);
  const [isLoansRepaymentPopUpOpen, setIsLoansRepaymentPopUpOpen] = useState(false);
  const [isLoansSummaryPopUpOpen, setIsLoansSummaryPopUpOpen] = useState(false);

  const [loansDetailsHeight, setLoansDetailsHeight] = useState(0);
  const [loansRepaymentHeight, setLoansRepaymentHeight] = useState(0);
  const [loansSummaryHeight, setLoansSummaryHeight] = useState(0);

  const [statusName, setStatusName] = useState('Created');
  const [statusDescription, setStatusDescription] = useState(
    'Loan in repayment. See schedule below.',
  );

  const loansDetailsRef = useRef<HTMLDivElement>(null);
  const loansRepaymentRef = useRef<HTMLDivElement>(null);
  const loansSummaryRef = useRef<HTMLDivElement>(null);

  const amount = loanDetails?.terms.amount.value;
  const serviceFee = loanDetails?.terms?.plan?.convenienceFee?.value?.amount
    ? roundToTwoDecimals(
        Number(loanDetails?.terms.amount.value) *
          (loanDetails?.terms?.plan?.convenienceFee?.value?.amount / 100),
      )
    : 0;
  const totalFeeAmount = serviceFee + 0;
  const totalAmount = roundToTwoDecimals(Number(loanDetails?.terms.amount.value) + totalFeeAmount);

  useEffect(() => {
    if (loansDetailsRef.current) {
      setLoansDetailsHeight(loansDetailsRef.current.scrollHeight);
    }
  }, [isLoansDetailsPopUpOpen]);

  useEffect(() => {
    if (loansRepaymentRef.current) {
      setLoansRepaymentHeight(loansRepaymentRef.current.scrollHeight);
    }
  }, [isLoansRepaymentPopUpOpen]);

  useEffect(() => {
    if (loansSummaryRef.current) {
      setLoansSummaryHeight(loansSummaryRef.current.scrollHeight);
    }
  }, [isLoansSummaryPopUpOpen]);

  const loansDetailsAnimation = useSpring({
    marginTop: isLoansDetailsPopUpOpen ? '0' : '0px',
    height: isLoansDetailsPopUpOpen ? `${loansDetailsHeight}px` : '0px',
    opacity: isLoansDetailsPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const loansRepaymentAnimation = useSpring({
    marginTop: isLoansRepaymentPopUpOpen ? '0' : '0px',
    height: isLoansRepaymentPopUpOpen ? `${loansRepaymentHeight}px` : '0px',
    opacity: isLoansRepaymentPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  const loansSummaryAnimation = useSpring({
    marginTop: isLoansSummaryPopUpOpen ? '0' : '0px',
    height: isLoansSummaryPopUpOpen ? `${loansSummaryHeight}px` : '0px',
    opacity: isLoansSummaryPopUpOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 400, friction: 40 },
  });

  useEffect(() => {
    const updateStatusName = () => {
      if (!loanDetails) return 'Active';

      switch (loanDetails.stage.kind) {
        case LoanStageKind.repayment:
          if (loanDetails?.stage?.gracePeriod?.enabled) {
            return 'Repayment late';
          }
          return 'Active';
        case LoanStageKind.closed:
          switch (loanDetails.stage.closureType) {
            case LoanClosureType.forgiven:
              return 'Forgiven';
            case LoanClosureType.paidOut:
              return 'Paid off';
            case LoanClosureType.deactivated:
              return 'Deactivated';
            default:
              return 'Active';
          }
        default:
          return 'Active';
      }
    };

    const updateStatusDescription = () => {
      if (!loanDetails) {
        return 'Loan in repayment. See schedule below.';
      }

      switch (loanDetails.stage.kind) {
        case LoanStageKind.repayment:
          return 'Loan in repayment. See schedule below.';
        case LoanStageKind.closed:
          switch (loanDetails.stage.closureType) {
            case LoanClosureType.forgiven:
              return 'Loan forgiven.';
            case LoanClosureType.paidOut:
              return 'Loan paid off.';
            case LoanClosureType.deactivated:
              return `This loan has been deactivated on ${
                loanDetails?.closedAt ? formattedDate(loanDetails?.closedAt) : ''
              }.`;
            default:
              return 'Loan in repayment. See schedule below.';
          }
        default:
          return 'Loan in repayment. See schedule below.';
      }
    };

    setStatusName(updateStatusName());
    setStatusDescription(updateStatusDescription());
  }, [loanDetails]);

  return (
    <>
      <div className="loan__bank-card">
        <div className="loan__bank-card-primary-section loan__bank-card-primary-section--summary">
          <span>Current loan status</span>
          {statusName}
        </div>
        <div className="loan__bank-card-primary-section-footer">
          <Icon name="blue-lamp" />
          {statusDescription}
        </div>

        <h3 className="loan__summary__title">Loan details</h3>
        <div className="">
          <div className="loans__selectLoanSummaryForm__loansDetails__body loans__selectLoanSummaryForm__loansDetails__body--hasFooter">
            <div
              className={cn(
                'loans__selectLoanSummaryForm__loansDetails__row',
                !isLoansDetailsPopUpOpen
                  ? 'loans__selectLoanSummaryForm__loansDetails__row--mb16'
                  : 'loans__selectLoanSummaryForm__loansDetails__row--mb32',
              )}
            >
              <div className="loans__selectLoanSummaryForm__loansDetails__label">Lending to</div>
              <button
                type="button"
                className={cn(
                  'loans__selectLoanSummaryForm__loansDetails__accordion',
                  isLoansDetailsPopUpOpen
                    ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                    : '',
                )}
                onClick={() => {
                  setIsLoansDetailsPopUpOpen(!isLoansDetailsPopUpOpen);
                }}
              >
                <div className="loans__selectLoanSummaryForm__loansDetails__value">
                  {displayBorrowerName}
                  <Icon name="chevron-down" />
                </div>
              </button>
            </div>
            <animated.div
              style={loansDetailsAnimation}
              ref={loansDetailsRef}
              className="loans__selectLoanSummaryForm__loansDetails__innerBody"
            >
              <div className="loans__selectLoanSummaryForm__loansDetails__row">
                <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                  Phone number
                </div>
                <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                  {loanDetails?.borrower.redactedPhoneNum}
                </div>
              </div>
              <div className="loans__selectLoanSummaryForm__loansDetails__row">
                <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                  Relationship
                </div>
                <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                  {loanDetails?.borrowerType === 'user'
                    ? 'Employee / Team Member'
                    : 'Business Entity'}
                </div>
              </div>
            </animated.div>
            <div
              className={cn(
                'loans__selectLoanSummaryForm__loansDetails__row',
                !isLoansDetailsPopUpOpen
                  ? 'loans__selectLoanSummaryForm__loansDetails__row--mt16'
                  : 'loans__selectLoanSummaryForm__loansDetails__row--mt32',
              )}
            >
              <div className="loans__selectLoanSummaryForm__loansDetails__label">
                Reason for loan
              </div>
              <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                {loanDetails?.terms.description.reason}
              </div>
            </div>

            <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mt32">
              <div className="loans__selectLoanSummaryForm__loansDetails__label">Date created</div>
              <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                {loanDetails?.createdAt ? formattedDate(loanDetails?.createdAt) : ''}
              </div>
            </div>

            <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mt32">
              <div className="loans__selectLoanSummaryForm__loansDetails__label">Created by</div>
              <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                {loanDetails?.createdBy.redactedUsername}
              </div>
            </div>
          </div>
        </div>

        <h3 className="loan__summary__title">Repayment details</h3>
        <div className="">
          <div
            className={cn(
              !loanDetails?.terms.dueDate
                ? 'loans__selectLoanSummaryForm__loansDetails--hasFooter'
                : '',
            )}
          >
            <div
              className={cn(
                'loans__selectLoanSummaryForm__loansDetails__body',
                !loanDetails?.terms.dueDate
                  ? 'loans__selectLoanSummaryForm__loansDetails__body--detailsBlock'
                  : 'loans__selectLoanSummaryForm__loansDetails__body--hasFooter',
              )}
            >
              <div
                className={cn(
                  'loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mb32',
                  !loanDetails?.terms.dueDate
                    ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding'
                    : '',
                )}
              >
                <div className="loans__selectLoanSummaryForm__loansDetails__label">Type</div>
                <div
                  className={cn(
                    'loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second',
                    !loanDetails?.terms.dueDate
                      ? 'loans__selectLoanSummaryForm__loansDetails__value--second'
                      : '',
                  )}
                >
                  {!loanDetails?.terms.dueDate ? 'Monthly' : 'One-time'}
                </div>
              </div>
              <div
                className={cn(
                  'loans__selectLoanSummaryForm__loansDetails__row',
                  isLoansRepaymentPopUpOpen
                    ? 'loans__selectLoanSummaryForm__loansDetails__row--mb32'
                    : '',
                  !loanDetails?.terms.dueDate
                    ? 'loans__selectLoanSummaryForm__loansDetails__row--hasPadding loans__selectLoanSummaryForm__loansDetails__value--second'
                    : '',
                )}
              >
                <div className="loans__selectLoanSummaryForm__loansDetails__label">
                  {!loanDetails?.terms.dueDate ? 'Repayment schedule' : 'Repayment date'}
                </div>
                <button
                  type="button"
                  className={cn(
                    'loans__selectLoanSummaryForm__loansDetails__accordion',
                    isLoansRepaymentPopUpOpen
                      ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                      : '',
                  )}
                  onClick={() => {
                    setIsLoansRepaymentPopUpOpen(!isLoansRepaymentPopUpOpen);
                  }}
                >
                  <div className="loans__selectLoanSummaryForm__loansDetails__value">
                    {loanDetails?.terms.dueDate
                      ? formattedDate(loanDetails?.terms.dueDate)
                      : `${loanDetails?.terms.plan.payments.length} Months`}
                    <Icon name="chevron-down" />
                  </div>
                </button>
              </div>
              <animated.div
                style={loansRepaymentAnimation}
                ref={loansRepaymentRef}
                className="loans__selectLoanSummaryStatus__loansDetails__innerBody"
              >
                <div
                  className="loans__selectLoanSummaryStatus__loansDetails__infoBlock"
                  style={{ margin: !loanDetails?.terms.dueDate ? '0 16px' : '' }}
                >
                  <div className="loans__selectLoanSummaryStatus__loansDetails__footer__iconBlock">
                    <Icon name="sparkle-blue" />
                  </div>
                  <div className="loans__selectLoanSummaryStatus__loansDetails__footer__descr">
                    <div className="loans__selectLoanSummaryStatus__loansDetails__footer__text">
                      Sit back and relax while Auto-Pay takes care of the rest. Your borrower can
                      always make payments ahead of schedule.
                    </div>
                  </div>
                </div>
              </animated.div>
              {!loanDetails?.terms.dueDate ? (
                <div className="loans__selectLoanSummaryStatus__loansDetails__innerBody">
                  <div
                    className={cn(
                      'loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--hasPadding loans__selectLoanSummaryForm__loansDetails__row--mt32',
                    )}
                  >
                    <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                      First payment due
                    </div>
                    <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                      {loanDetails?.repaymentDateFrom
                        ? formattedDate(loanDetails?.repaymentDateFrom)
                        : ''}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'loans__selectLoanSummaryStatus__loansDetails__row loans__selectLoanSummaryStatus__loansDetails__row--hasPadding',
                    )}
                  >
                    <div className="loans__selectLoanSummaryStatus__loansDetails__label">
                      Final payment due
                    </div>
                    <div className="loans__selectLoanSummaryStatus__loansDetails__value loans__selectLoanSummaryStatus__loansDetails__value--second">
                      {loanDetails?.repaymentDateTo
                        ? formattedDate(loanDetails?.repaymentDateTo)
                        : ''}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <h3 className="loans__selectLoanSummaryForm__title">Payment summary</h3>
        <div className="">
          <div className="loans__selectLoanSummaryForm__loansDetails__body">
            <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mb32">
              <div className="loans__selectLoanSummaryForm__loansDetails__label">Loan amount</div>
              <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                {formatDollarAmount(amount)}
              </div>
            </div>
            <div
              className={cn(
                'loans__selectLoanSummaryForm__loansDetails__row',
                isLoansSummaryPopUpOpen
                  ? 'loans__selectLoanSummaryForm__loansDetails__row--mb32'
                  : '',
              )}
            >
              <div className="loans__selectLoanSummaryForm__loansDetails__label">Fees</div>
              <button
                type="button"
                className={cn(
                  'loans__selectLoanSummaryForm__loansDetails__accordion',
                  isLoansSummaryPopUpOpen
                    ? 'loans__selectLoanSummaryForm__loansDetails__accordion--active'
                    : '',
                )}
                onClick={() => {
                  setIsLoansSummaryPopUpOpen(!isLoansSummaryPopUpOpen);
                }}
              >
                <div className="loans__selectLoanSummaryForm__loansDetails__value">
                  {formatDollarAmount(totalFeeAmount)}
                  <Icon name="chevron-down" />
                </div>
              </button>
            </div>
            <animated.div
              style={loansSummaryAnimation}
              ref={loansSummaryRef}
              className="loans__selectLoanSummaryForm__loansDetails__innerBody"
            >
              <div className="loans__selectLoanSummaryForm__loansDetails__row">
                <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                  Service fee
                </div>
                {/* eslint-disable-next-line */}
                <div
                  className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value"
                  onClick={() => setIsModalServiceFeeOpen(true)}
                >
                  {formatDollarAmount(serviceFee)}
                  <Icon
                    name="icon-info"
                    classes="loans__selectAmountForm__summaryBlock__label__icon"
                  />
                </div>
              </div>
              {loanDetails?.borrowerType === 'user' ? (
                <div className="loans__selectLoanSummaryForm__loansDetails__row">
                  <div className="loans__selectLoanSummaryForm__loansDetails__label loans__selectLoanSummaryForm__loansDetails__label">
                    Loan coverage
                    <div className="loans__selectAmountForm__summaryBlock__label__badge">
                      <div className="loans__selectAmountForm__summaryBlock__label__badge__text">
                        NEW
                      </div>
                    </div>
                  </div>
                  {/* eslint-disable-next-line */}
                <div
                    className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value"
                    onClick={() => setIsModalLoanCoverageOpen(true)}
                  >
                    {formatDollarAmount(0)}
                    <Icon
                      name="icon-info"
                      classes="loans__selectAmountForm__summaryBlock__label__icon"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </animated.div>
            <div className="loans__selectLoanSummaryForm__loansDetails__row loans__selectLoanSummaryForm__loansDetails__row--mt32">
              <div className="loans__selectLoanSummaryForm__loansDetails__label">
                Total repayment
              </div>
              <div className="loans__selectLoanSummaryForm__loansDetails__value loans__selectLoanSummaryForm__loansDetails__value--second">
                {formatDollarAmount(totalAmount)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalServiceFee isOpen={isModalServiceFeeOpen} setIsOpen={setIsModalServiceFeeOpen} />
      <ModalLoanCoverage isOpen={isModalLoanCoverageOpen} setIsOpen={setIsModalLoanCoverageOpen} />
    </>
  );
}
