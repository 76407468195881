import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { FundingSource, FundingSourceModel } from '../../../../shared/models/FundingSource';
import { parseError } from '../../../../shared/utils/helpers';

export const getUserPaymentMethods = createAsyncThunk<
  Array<FundingSource>,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/getUserPaymentMethods', async (userId, { rejectWithValue }) => {
  try {
    const { data } = await api.get<Array<FundingSource>>(
      `/platform-admin/users/${userId}/payment-methods`,
    );
    return FundingSourceModel.fromDTOArray(data);
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
