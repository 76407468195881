import { useMemo } from 'react';

import { useAppSelector } from '../../../main/store/hooks';
import { checkIsPhone } from '../../../shared/components/MaskInput';

export const useRepaymentRequestLoan = () => {
  const { loanDetails } = useAppSelector((state) => state.loans);

  const loanSummaryObject = useMemo(
    () => ({
      get beginningBalance() {
        return loanDetails ? loanDetails.terms.plan.payments[0].beginningBalance : undefined;
      },
      get payments() {
        return loanDetails ? loanDetails.terms.plan.payments : undefined;
      },
      get loanStage() {
        return loanDetails ? loanDetails.stage : undefined;
      },
      get scheduleDueDate() {
        return loanDetails ? loanDetails.dueDate : undefined;
      },
      get schedulePayments() {
        return loanDetails ? loanDetails.terms.plan.payments : undefined;
      },
      get accountNumber() {
        return loanDetails ? loanDetails.disbursementSource.redactedAccountNumber : undefined;
      },
      get repaymentDateFrom() {
        return loanDetails ? loanDetails.repaymentDateFrom : undefined;
      },
      get percent() {
        return loanDetails ? loanDetails.percent : undefined;
      },
      get disbursementTarget() {
        if (loanDetails) {
          return loanDetails.terms.disbursementTarget;
        }
        return undefined;
      },
      get contactGivenNameFull() {
        if (loanDetails) {
          const userName = loanDetails.borrower.redactedUsername ?? '';
          if (!checkIsPhone(userName || '')) return userName;
          return loanDetails.terms.description.initialTargetName || userName;
        }
        return undefined;
      },
    }),
    [loanDetails],
  );

  return loanSummaryObject;
};
