export function getFirstLetters(name?: string): string {
  if (name) {
    return name
      .trim()
      .split(' ')
      .map((word: string) => word[0].toUpperCase())
      .join('');
  }
  return '';
}
