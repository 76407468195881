import { LoanStageDto } from './LoanStageDto';
import { LoanUserDataDto } from './LoanUserDataDto';
import { MonetaryValueDto } from './MonetaryValueDto';

export enum LoanType {
  BILL_PAY = 'billpay',
  CUSTOM = 'custom',
}

export enum RepaymentType {
  ONE_TIME = 'onetime',
  MONTHLY = 'monthly',
}

export enum FeeType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ProcessingFeeType {
  STANDARD = 'STANDARD',
  EXPRESS = 'EXPRESS',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ConvenienceFeeType {
  DISABLED = 'DISABLED',
  DEFAULT = 'DEFAULT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type FeeValuesDto = {
  kind: FeeType;
  amount: number;
};

export type ProcessingFeeDto = {
  kind: ProcessingFeeType;
  value: FeeValuesDto;
};

export type ConvenienceFeeDto = {
  kind: ConvenienceFeeType;
  value: FeeValuesDto;
};

export type LoanDto = {
  uuid: string;
  loanType: LoanType;
  isLendLoan: boolean;
  isRepaymentRequest?: boolean;
  lenderId: boolean;
  amount: MonetaryValueDto;
  lender: LoanUserDataDto;
  borrowerId: boolean;
  borrower: LoanUserDataDto;
  stage: LoanStageDto;
  repaymentDateFrom: string;
  repaymentDateTo: string;
  firstPaymentDate: string;
  nextPaymentDate: string;
  targetContactUri: string;
  createdBy: string;
  creator?: LoanUserDataDto;
  createdAt: string;
  dueDate?: string;
  boundAt?: string;
  repaymentDurationMonths: number;
  repaymentType: RepaymentType;
  nextRepaymentAmount?: MonetaryValueDto;
  feeEnabled: boolean;
  processingFee?: ProcessingFeeDto;
  convenienceFee?: ConvenienceFeeDto;
  totalFeeAmount?: MonetaryValueDto;
};
