import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { CompanyRoleDto, CompanyRole, CompanyRoleModel } from '../../../../shared/models';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const getCompanyRoles = createAsyncThunk<
  Array<CompanyRole>,
  undefined,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/getRoles', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<{ groups: Array<CompanyRoleDto> }>(`/security/groups`);
    return data.groups.map((item) => CompanyRoleModel.fromDTO(item));
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
