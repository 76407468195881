export type PartnerInvitedMemberDto = {
  roles: Array<string>;
  createdAt: string;
  contactUri: string;
  securityGroups: Array<string>;
  contacts: Array<string>;
};

export type PartnerInvitedMembersDto = {
  invitedMembers: Array<PartnerInvitedMemberDto>;
};

export type InvitedMember = PartnerInvitedMemberDto;
export type InvitedMembers = PartnerInvitedMembersDto;

export class InvitedMemberModel {
  public static fromDTO(dto: PartnerInvitedMemberDto): InvitedMember {
    return {
      ...dto,
      contactUri: dto.contacts[0],
    };
  }
}
