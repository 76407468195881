import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch, RootState } from '../../../../main/store';
import { parseError } from '../../../../shared/utils/helpers';

export const adminLoanPresetActivate = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('platformAdmin/adminLoanPresetActivate', async (presetId, { rejectWithValue }) => {
  try {
    await api.post(`/platform-admin/loan-presets/activate/${presetId}`);

    return undefined;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
