import classNames from 'classnames';
import { FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { FormWrapper } from '../../../init-setup/components';
import businessEntity from '../../assets/business-entity.svg';
import employee from '../../assets/employee.svg';
import { LoanBorrowerType, setLoanBorrowerType } from '../../store';

type FormData = {
  loanBusinessType?: string | undefined;
};

type FormikDataTypes = {
  currentStepIndex: number;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<FormData>>;
};

type BorrowerTypeSelectProps = {
  type: LoanBorrowerType;
  currentValue: string;
  onSelect: (type: LoanBorrowerType) => void;
  imgSrc: string;
  title: string;
};

const BorrowerTypeSelect = ({
  type,
  currentValue,
  onSelect,
  imgSrc,
  title,
}: BorrowerTypeSelectProps) => (
  // eslint-disable-next-line
  <div
    className={classNames(
      'loans__selectLoanTypeBorrowerForm__typeSelect',
      currentValue === type && 'loans__selectLoanTypeBorrowerForm__typeSelect--active',
    )}
    onClick={() => onSelect(type)}
  >
    <div className="loans__selectLoanTypeBorrowerForm__typeSelect__descr">
      <img
        className="loans__selectLoanTypeBorrowerForm__typeSelect__image"
        src={imgSrc}
        alt={title}
      />
      <div className="loans__selectLoanTypeBorrowerForm__typeSelect__title">{title}</div>
    </div>
  </div>
);

export default function SelectBorrowerTypeForm({
  currentStepIndex,
  setFieldValue,
}: FormikDataTypes) {
  const { loanBorrowerType } = useAppSelector((state) => state.loans);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');

  const handleChangeType = (type: LoanBorrowerType) => {
    dispatch(setLoanBorrowerType(type));
    setFieldValue('loanBorrowerType', type);
    setValue(type);
  };

  useEffect(() => {
    setValue(loanBorrowerType);
  }, [currentStepIndex, loanBorrowerType]);

  return (
    <FormWrapper>
      <div className="loans__selectLoanTypeBorrowerForm">
        <div className="loans__selectLoanTypeBorrowerForm__container">
          <div className="loanTypeForm__head">
            <h2 className="loanTypeForm__title">
              What&lsquo;s your relationship with the borrower?
            </h2>
            <div className="loanTypeForm__subtitle">
              We&lsquo;ll use this info to manage and organize your loan.
            </div>
          </div>
          <div className="loans__selectLoanTypeBorrowerForm__body">
            <BorrowerTypeSelect
              type={LoanBorrowerType.EMPLOYEE}
              currentValue={value}
              onSelect={handleChangeType}
              imgSrc={employee}
              title="Employee / Team Member"
            />
            <BorrowerTypeSelect
              type={LoanBorrowerType.BUSINESS_ENTITY}
              currentValue={value}
              onSelect={handleChangeType}
              imgSrc={businessEntity}
              title="Business Entity"
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}
