import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const validateDomain = createAsyncThunk<
  boolean,
  string,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('auth/validateDomain', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get<string>('/partners/validate-domain', {
      params: {
        domain: payload,
      },
    });

    return Boolean(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
