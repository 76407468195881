import classNames from 'classnames';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../main/store';
import { useAppDispatch } from '../../../main/store/hooks';
import { Alert, Icon, Input, Label } from '../../../shared';
import Switch from '../../../shared/components/Switch';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { addAlert, AlertType } from '../../../shared/store/modals';
import { testSftpUpload } from '../store/actions/testSftpUpload';

interface FileTransferConfigProps {
  onChange: () => void;
}

export default function FileTransferConfig({ onChange }: FileTransferConfigProps) {
  const [checked, setChecked] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const { fileTransferSettings: item } = useSelector((state: RootState) => state.devTools);
  const dispatch = useAppDispatch();

  const handleChange = () => {
    Amplitude.logEvent(AmplitudeEvents.fileTransferIntegrationEditStarted);
    onChange();
  };

  const handleTest = async () => {
    setIsUpload(true);

    dispatch(
      addAlert({
        text: 'A test file will be sent to the specified location shortly.',
      }),
    );

    const resp = await dispatch(testSftpUpload());

    if (testSftpUpload.fulfilled.match(resp)) {
      setIsUpload(false);

      dispatch(
        addAlert({
          text: 'File has been successfully uploaded',
        }),
      );
    }

    if (testSftpUpload.rejected.match(resp)) {
      setIsUpload(false);

      dispatch(
        addAlert({
          text: 'Something went wrong, please try again',
          type: AlertType.error,
        }),
      );
    }
  };

  return (
    <div className="dev-tools">
      <h3 className="dev-tools__title">File transfer</h3>
      <h4 className="dev-tools__subtitle">
        Transfer files with SFTP and FTP connections, and orchestrate it all with our API
      </h4>

      <div className="dev-tools__block dev-tools__block--mbn dev-tools__item-head">
        <div className="dev-tools__block-name dev-tools__block-name--mbn">
          {/* {fileTransferSettings?.protocol} */}
          SFTP (Secure File Transfer Protocol)
        </div>
        <div className="dev-tools__button-wrap">
          <button
            className="button button--mint dev-tools__item-button"
            type="button"
            onClick={handleTest}
            disabled={isUpload}
          >
            <span
              className={classNames('button__icon', {
                'button__icon--test': !isUpload,
                'button__icon--spinner': isUpload,
              })}
            >
              <Icon name={isUpload ? 'spinner' : 'upload'} />
            </span>
            Test
          </button>
          <button
            className="button button--outline-gray dev-tools__item-button"
            type="button"
            onClick={handleChange}
          >
            <span className="dev-tools__btn-icon">
              <Icon name="edit" />
            </span>
            Edit
          </button>
        </div>
      </div>
      {item && (
        <form>
          <div className="dev-tools__block dev-tools__block--no-border">
            <div className="dev-tools__row">
              <div className="dev-tools__col">
                <div className="form-group">
                  <Label forId="hostname">Hostname</Label>
                  <Input
                    type="text"
                    id="hostname"
                    value={item.config.sftp.host}
                    placeholder="Enter webhook subscription name"
                    disabled
                    copyButton
                  />
                </div>
              </div>
              <div className="dev-tools__col">
                <div className="form-group">
                  <Label forId="date">Created on</Label>
                  <Input
                    type="text"
                    id="date"
                    value={format(new Date(item.config.createdAt) || new Date(), 'MMMM d, yyyy')}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="dev-tools__row">
              <div className="dev-tools__col">
                <div className="form-group">
                  <Label forId="username">Username</Label>
                  <Input
                    type="text"
                    id="username"
                    value={item.config.sftp.userName}
                    disabled
                    copyButton
                  />
                </div>
              </div>
              <div className="dev-tools__col">
                <div className="form-group">
                  <Label forId="password">Password</Label>
                  <Input
                    type="password"
                    id="password"
                    value={item.config.sftp.password}
                    placeholder="Enter webhook subscription name"
                    disabled
                    passwordTypeBtn="text"
                  />
                </div>
              </div>
            </div>
          </div>

          {item.config.reportRecipients.attachments && (
            <div className="dev-tools__block">
              <div className="dev-tools__row">
                <div className="dev-tools__col">
                  <div className="form-group">
                    <Label forId="name">Receive reports (.xlsx) to</Label>
                    <Input
                      type="text"
                      id="name"
                      value={item.config.reportRecipients.recipients.join(', ')}
                      disabled
                    />
                  </div>
                </div>
                <div className="dev-tools__col dev-tools__col--switch">
                  <div className="form-group">
                    <Label forId="name">Reporting</Label>
                    <Switch
                      id="rep"
                      onChange={() => {
                        setChecked(!checked);
                      }}
                      checked={checked}
                      isShowText
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
}
