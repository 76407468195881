import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../../main/store/hooks';
import { Search } from '../../../shared/components';
import ModalConfirm from '../../../shared/components/ModalConfirm';
import Permissions from '../../../shared/constants/Permissions';
import { hasRole, isCompanyAdmin } from '../../auth/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import { CompanyMembers } from '../components';
import ValidationRules from '../components/ValidationRules';
import {
  ModalInviteMember,
  ModalInvitationSent,
  ModalDeactivateMember,
  ModalRole,
} from '../modals';

import { CompanyProfileProps } from './CompanyProfile';

const TeamMembersPage = ({ location }: CompanyProfileProps) => {
  const [searchQ, setSearchQ] = useState<string | undefined>();

  const {
    partner,
    isAdmin,
    authToken: authInfo,
    user,
    roles,
  } = useAppSelector((state) => ({ ...state.auth, ...state.company }), shallowEqual);

  const { state } = location;

  const handleSearch = useCallback((q?: string) => {
    setSearchQ(q);
  }, []);

  const isUserCompanyAdmin = user?.uuid ? isCompanyAdmin(user?.uuid, roles) : false;

  const canSeeCompany =
    authInfo &&
    (hasRole(authInfo, Permissions.MEMBERS_MANAGE) ||
      hasRole(authInfo, Permissions.MEMBERS_INVITE) ||
      isAdmin);

  return (
    <DashboardLayout pageTitle="Team Members">
      <div className="profile">
        <form
          action="#"
          className={classNames('profile__search')}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Search
            id="profile-search"
            placeholder="Search for a team member or role"
            onChange={handleSearch}
            classes="search--settings"
          />
        </form>

        {canSeeCompany && <CompanyMembers q={searchQ} />}
        {/* {isAdmin && <ValidationRules />} */}
        <ModalInviteMember isUserCompanyAdmin={isUserCompanyAdmin} isUserSystemAdmin={isAdmin} />
        <ModalInvitationSent />
        <ModalDeactivateMember />
        <ModalRole isUserCompanyAdmin={isUserCompanyAdmin} isUserSystemAdmin={isAdmin} />
        <ModalConfirm />
      </div>
    </DashboardLayout>
  );
};

export default TeamMembersPage;
