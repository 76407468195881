import numeral from 'numeral';
import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
  labels: ['1', '10', '15', '20', '25', '30'],
  datasets: [
    {
      label: 'Last 7 days',
      data: [10000, 5000000, 1000000, 7000000, 4500000, 10000000],
      fill: false,
      backgroundColor: '#004F99',
      borderColor: '#004F99',
      lineTension: 0.4,
    },
    {
      label: 'Last 30 days',
      data: [100000, 2000000, 7000000, 3000000, 1500000, 20000000],
      fill: false,
      backgroundColor: '#D8F3E7',
      borderColor: '#D8F3E7',
      lineTension: 0.4,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      beginAtZero: false,
      ticks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (value: any) => numeral(value).format('0a').toUpperCase(),
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        color: '#3D475C',
        boxWidth: 16,
        boxHeight: 16,
        borderRadius: 4,
        font: {
          size: 14,
          lineHeight: 24,
        },
      },
    },
  },
};

export default function DashboardChart() {
  // @ts-ignore
  return <Line data={data} options={options} />;
}
