import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { PartnerMember } from '../../../../shared/models';
import { addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export const addCompanyRole = createAsyncThunk<
  { uuid: string },
  { name: string; color: string; permissions: string[]; members: PartnerMember[] },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('company/addRole', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.post<{ uuid: string }>(`/security/groups`, payload);

    if (payload.members.length) {
      await Promise.all(
        payload.members.map((member) =>
          api.put(`/members/${member.uuid}/security-groups`, {
            securityGroups: [data.uuid],
          }),
        ),
      );
    }

    dispatch(addAlert({ text: 'New role successfully added' }));

    return data;
  } catch (e) {
    const message = parseError(e as Error);
    return rejectWithValue(message);
  }
});
