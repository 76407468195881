import { last } from 'lodash';

import { LoanStage, LoanStageKind } from './LoanTypes';
import {
  LoanScheduleValue,
  PaymentHistoricalEntryType,
  RepaymentPlanDto,
  RepaymentPlanPaymentDto,
} from './RepaymentPlanDto';

export type RepaymentPlanPayment = RepaymentPlanPaymentDto & {
  position: number;
  isCurrent: boolean;
  isError: boolean;
  isSuccess: boolean;
  idempotencyKey: string;
};

export type RepaymentPlan = {
  installmentInterestPercentage: number;
  payments: RepaymentPlanPayment[];
  convenienceFee?: LoanScheduleValue;
  processingFee?:
    | {
        kind: 'SELECT' | 'UNRECOGNIZED';
        value: Array<LoanScheduleValue>;
      }
    | LoanScheduleValue;
};

export class RepaymentPlanModel {
  public static fromDTO(dto: RepaymentPlanDto): RepaymentPlan {
    const payments: RepaymentPlanPayment[] = dto.payments.map((p, index) => ({
      ...p,
      position: index + 1,
      isCurrent: false,
      isError: false,
      isSuccess: false,
      idempotencyKey: '',
    }));
    return {
      ...dto,
      payments,
    };
  }

  public static addPaymentInfo(payment: RepaymentPlanPayment, stage: LoanStage) {
    if (stage.kind === LoanStageKind.repayment) {
      const currentIndex = Number.isNaN(stage.currentPaymentIndex) ? 0 : stage.currentPaymentIndex;
      const currPos = currentIndex + 1;
      payment.isCurrent = payment.position === currPos;
      payment.isError =
        last(payment.history)?.type === PaymentHistoricalEntryType.failedOn || payment.failed;
      payment.isSuccess =
        last(payment.history)?.type === PaymentHistoricalEntryType.succeededOn ||
        (!payment.isError && currPos > payment.position);
    }

    return { ...payment };
  }
}
