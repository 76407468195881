import classNames from 'classnames';
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, FocusEventHandler } from 'react';

import { TableStatus } from '../../features/payments/components';
import { useOnClickOutside } from '../hooks';

import Icon from './Icon';

export type DataItem = {
  name: string;
  dataId?: string | number;
};

type Props = {
  placeholder?: string;
  id: string;
  value: any;
  classes?: string;
  haveFlag?: boolean;
  status?: boolean;
  data: DataItem[];
  disabled?: boolean;
  listPosition?: string;
  onChange: (arg: any) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  totalItems?: number;
  title?: string;
  icon?: 'chevron' | 'arrow';
  isReset?: boolean;
  multiSelect?: boolean;
  error?: string;
  additionalOffsetTop?: number;
};

export default function Select(props: Props): JSX.Element {
  const {
    placeholder = 'Select',
    id,
    value,
    classes = '',
    haveFlag = false,
    status = false,
    data,
    disabled = false,
    listPosition = 'absolute',
    additionalOffsetTop = 0,
    onChange,
    totalItems = 0,
    title,
    icon = 'chevron',
    isReset = false,
    multiSelect = false,
    error,
    onBlur,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [opened, setOpened] = useState(false);
  const [pos, setPos] = useState<'top' | 'bottom'>('bottom');
  const [opacity, setOpacity] = useState(0);
  const [height, setHeight] = useState('auto');
  const [coll, setColl] = useState<any[]>([]);
  const selectRef = React.createRef<HTMLInputElement>();
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const nextValue = value;
    setInputValue(nextValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems]);

  useEffect(() => {
    if (Array.isArray(value) && multiSelect) {
      setColl(value);
    }
  }, []);

  useEffect(() => {
    if (isReset) {
      setInputValue('');
    }
  }, [isReset]);

  useEffect(() => {
    const dropdown = dropDownRef?.current;
    const select = selectRef?.current;
    const scrollTop = window.scrollY;

    if (opened && dropdown && select) {
      const spaceTop = select.offsetTop - scrollTop;
      const spaceBottom =
        window.innerHeight - (spaceTop + select.offsetHeight + additionalOffsetTop);
      const minSpaceBottom = 195;
      let currentPos;

      if (spaceBottom >= spaceTop) {
        currentPos = spaceBottom;
        setPos('bottom');
      } else if (spaceBottom >= minSpaceBottom) {
        currentPos = spaceBottom;
        setPos('bottom');
      } else {
        currentPos = spaceTop;
        setPos('top');
      }

      if (currentPos < dropdown.offsetHeight) {
        setHeight(`${currentPos - 20}px`);
      }

      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }

    if (!opened) {
      setOpacity(0);
      setHeight('auto');
    }
  }, [opened]);

  useOnClickOutside(selectRef, () => {
    setOpened(false);
  });

  function setValue(val: any) {
    if (!multiSelect) {
      onChange(val);
      const nextValue = val;
      setInputValue(nextValue);
      setOpened(false);
    }

    if (multiSelect) {
      let newColl;

      if (!coll.includes(val)) {
        newColl = [...coll, val];
        setColl(newColl);
      } else {
        newColl = coll.filter((id) => id !== val);
        setColl(newColl);
      }

      setInputValue(newColl.join());
      onChange(newColl.join());
    }
  }

  const handleClose = () => setOpened(false);
  // when props.value updates from outside, set the internal state
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  let listItems: any[] = [];

  if (data && data.length) {
    listItems = data.map(({ dataId = '', name }: DataItem) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <li
        key={dataId}
        className={`select__option ${dataId === inputValue ? 'select__option--selected' : ''}`}
        onClick={() => {
          setValue(dataId);
        }}
      >
        {haveFlag ? <img src="images/flags/usa.svg" className="select__flag" alt="" /> : ''}
        {status ? <TableStatus type={dataId.toString()} marker="loan" /> : name}

        {multiSelect && (
          <Icon
            name="check"
            classes={classNames('select__multi', {
              'select__multi--checked': coll.includes(dataId),
            })}
          />
        )}
      </li>
    ));
  }

  const valueToDisplay = () => {
    if (!multiSelect) {
      const selectedElement = data.find((item) => item.dataId === inputValue);
      return selectedElement
        ? `${data.find((item) => item.dataId === inputValue)?.name}${
            totalItems ? ` (${totalItems})` : ``
          }`
        : '';
    }

    return data
      .reduce<string[]>((acc, i) => (coll.includes(i.dataId) ? [...acc, i.name] : acc), [])
      .join(', ');
  };
  return (
    <div
      className={classNames(`select ${classes}`, {
        'select--opened': opened,
        'select--flag': haveFlag,
        'select--disabled': disabled,
        'select--error': error,
        'select--error-margin': error !== undefined,
      })}
      ref={selectRef}
    >
      <div className="select__box">
        <input
          type="text"
          className="input select__input"
          value={valueToDisplay()}
          placeholder={placeholder}
          name={id}
          id={id}
          readOnly
          onBlur={onBlur}
          disabled={disabled}
          onClick={() => setOpened(!opened)}
        />
        {haveFlag && (
          <img src="images/flags/usa.svg" alt="" className="select__flag select__current-flag" />
        )}
        <div className="select__icon-wrap">
          <span className="select__hidden-value">{valueToDisplay()}</span>
          <div className="select__chevron">
            <Icon name={icon} classes="select__chevron-icon" />
          </div>
        </div>
      </div>
      <div
        ref={dropDownRef}
        style={{ opacity, height }}
        className={`select__option-list select__option-list--${listPosition} select__option-list--${pos}`}
      >
        {title && (
          <h4 className="select__title">
            {title}
            <button type="button" className="select__close" onClick={handleClose}>
              <Icon name="cross" />
            </button>
          </h4>
        )}
        <ul className="select__list">{listItems}</ul>
      </div>
      <div className="input-group__error-text">{error}</div>
    </div>
  );
}
