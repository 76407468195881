import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { Backdrop, PermissionsGuard } from '../../../shared/components';
import { DOCS_LINK } from '../../../shared/constants/Docs';
import Permissions from '../../../shared/constants/Permissions';
import { intercomAPI } from '../../../shared/services/intercom';
import { isAdminRole } from '../../auth/utils';
import { PLATFORM_ADMIN_ROUTES } from '../../platform-admin/routes';
import { setMenuOpen, setShowIntercom } from '../store/dashboardSlice';

import { MobileMenuItem } from './MobileMenuItem';

export default function MobileMenu() {
  const menuOpen = useAppSelector((state) => state.dashboard.menuOpen);
  const [menuClasses, setMenuClasses] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const dispatch = useAppDispatch();
  const { showIntercom } = useAppSelector((state) => state.dashboard);
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);

  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };

  useEffect(() => {
    if (menuOpen) {
      setShowBackdrop(true);
      setMenuClasses('mobile-menu--will-toggle');
      setTimeout(() => {
        setMenuClasses('mobile-menu--show');
      }, 100);
    } else {
      setShowBackdrop(false);
      setMenuClasses('mobile-menu--will-toggle');
      setTimeout(() => {
        setMenuClasses('');
      }, 200);
    }
  }, [menuOpen]);
  return (
    <>
      <Backdrop
        show={showBackdrop}
        onClick={() => {
          dispatch(setMenuOpen(false));
        }}
      />
      <div className={`mobile-menu ${menuClasses || ''}`}>
        <div className="mobile-menu__top-row">
          <Link to="/" className="mobile-menu__logo">
            <Icon name="logo-full" classes="mobile-menu__logo-icon" />
          </Link>
          <button
            type="button"
            className="mobile-menu__close"
            onClick={() => {
              dispatch(setMenuOpen(false));
            }}
          >
            <Icon name="cross" classes="mobile-menu__close-icon" />
          </button>
        </div>
        <div className="mobile-menu__nav">
          <ul className="mobile-menu__nav-list">
            {/* Menu elem */}
            {/* <li className="mobile-menu__nav-item">
              <NavLink
                to="/insights"
                className="mobile-menu__nav-link"
                activeClassName="mobile-menu__nav-link--active"
                exact
              >
                <Icon name="speedometr" classes="mobile-menu__nav-icon" />
                Insights
              </NavLink>
            </li> */}
            {/* Menu elem */}
            {!isAdminRole(authInfo) && (
              <>
                <MobileMenuItem linkTo="/payments" title="Payments" iconName="users" />
                <MobileMenuItem linkTo="/finances" title="Finances" iconName="credit-card" />
                <MobileMenuItem linkTo={DOCS_LINK} title="Docs" iconName="guides" external />
              </>
            )}

            <PermissionsGuard
              permissions={{
                $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT],
              }}
              bypassForAdmin={false}
            >
              {/* TODO: Technical implementation. Fix styles when the design is ready */}

              <div style={{ borderBottom: '1px solid #003F59' }} />

              <MobileMenuItem
                linkTo={PLATFORM_ADMIN_ROUTES.USERS}
                title="Admin Users"
                iconName="users"
              />
              <MobileMenuItem
                linkTo={PLATFORM_ADMIN_ROUTES.LOANS}
                title="Admin Loans"
                iconName="credit-card"
              />
              <MobileMenuItem
                linkTo={PLATFORM_ADMIN_ROUTES.TRANSACTIONS}
                title="Admin Transactions"
                iconName="dollar"
              />
              <MobileMenuItem
                linkTo={PLATFORM_ADMIN_ROUTES.PARTNERS}
                title="Admin Partners"
                iconName="users"
              />
              <MobileMenuItem
                linkTo={PLATFORM_ADMIN_ROUTES.PAYMENTS}
                title="Admin Bill Payments"
                iconName="users"
              />
            </PermissionsGuard>

            {/* Menu elem */}
            {/* <li className="mobile-menu__nav-item">
              <NavLink
                to="/"
                className="mobile-menu__nav-link"
                activeClassName="mobile-menu__nav-link--active"
                exact
              >
                <Icon name="terminal" classes="mobile-menu__nav-icon" />
                Integration
              </NavLink>
            </li> */}
            {/* Menu elem */}
            <li className="mobile-menu__nav-item">
              <button
                type="button"
                className="mobile-menu__nav-link"
                onClick={() => clickSupport()}
                style={{ backgroundColor: 'transparent', border: 0 }}
              >
                <Icon name="question-circle" classes="mobile-menu__nav-icon" />
                Support
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
