import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface PartnersDeeplinksDetailsSectionProps {
  title: string;
  children: ReactNode;
  disabled?: boolean;
}

export const PartnersDeeplinksDetailsSection: FC<PartnersDeeplinksDetailsSectionProps> = ({
  title,
  children,
  disabled,
}) => {
  const arr = '';

  return (
    <div
      className={classNames('partners-deeplinks__details-section', {
        'section--disabled': disabled,
      })}
    >
      <h2 className="partners-deeplinks__details-section-title">
        {title}
        <div className="partners-deeplinks__details-section-title-label">Details ▼</div>
      </h2>
      <div className="partners-deeplinks__details-section-body">{children}</div>
    </div>
  );
};
