import classNames from 'classnames';
import { format } from 'date-fns';
import parsePhoneNumber from 'libphonenumber-js';
import numeral from 'numeral';
import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import {
  DateRangeFilter,
  Icon,
  Label,
  Search,
  Select,
  useLazyEffect,
  Tooltip,
  DateRangeTuple,
} from '../../../shared';
import TableCustomizeFilter from '../../../shared/components/TableCustomizeFilter';
import Permissions from '../../../shared/constants/Permissions';
import { BillPayment, StatusPayment } from '../../../shared/models';
import { getContact, getTimePeriod } from '../../../shared/utils';
import { hasRole, isAdmin } from '../../auth/utils';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import {
  BillPaymentsTableHead,
  TableAccountNumber,
  MemberNameFL,
  TableStatus,
  QuickActionsSelect,
} from '../../payments/components';
import { ModalRefunded, ModalRefund } from '../../payments/modals';
import {
  setModalRefundOpen,
  disableBillPaymentsSettings,
  setTablePaymentsDateFilter,
  setTablePaymentsDateRangeSettings,
} from '../../payments/store';
import {
  getAllBillPayments,
  exportPaymentsCSV,
  setModalSubmittedOpen,
} from '../../payments/store/actions';

const getDate = (date: string) => format(new Date(date), 'MMM dd, yyyy');

const getPaymentDate = (date: string) => format(new Date(date), 'MMM dd, yyyy hh:mm:ss a');

const isValidDate = (d?: string) => {
  if (d === undefined || d === null) {
    return false;
  }
  const parsed = Date.parse(d);

  return !Number.isNaN(parsed);
};

export const PlatformAdminPaymentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const {
    allBillPayments,
    allTotalBillPayments,
    billPaymentsSettings,
    dateRangeFilter,
    dateRangeSettings,
  } = useAppSelector((state) => state.payments);

  const visibleCols = billPaymentsSettings.filter((el) => el.checked && el.id !== 'view');

  const canViewPayments =
    authInfo && (hasRole(authInfo, Permissions.BILL_PAYMENTS_VIEW) || isAdmin(authInfo));

  const canRefund =
    authInfo && (hasRole(authInfo, Permissions.CUSTOMERS_REFUND) || isAdmin(authInfo));

  const [countRow, setCountRow] = useState<string>('8');
  const [skip, setSkip] = useState<number>(0);
  const [nbrPage, setNbrPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('initiatedAt');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [searchText, setSearchText] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('all');
  const [periodTime, setPeriodTime] = useState<string[]>(getTimePeriod(7));
  const [csvData, setCSVData] = useState('');

  const handleResetTableFilter = () => {
    if (canRefund) {
      dispatch(disableBillPaymentsSettings({ id: 'reimbursement', isEnable: true }));
    }
  };

  useEffect(() => {
    handleResetTableFilter();
  }, []);

  const getPayments = () => {
    dispatch(
      getAllBillPayments({
        searchText,
        status: paymentStatus,
        limit: +countRow,
        skip,
        periodTime: dateRangeFilter,
        sortBy,
        sortOrder,
      }),
    );
  };

  useLazyEffect(
    getPayments,
    [
      dispatch,
      countRow,
      skip,
      nbrPage,
      sortBy,
      sortOrder,
      searchText,
      paymentStatus,
      dateRangeFilter,
    ],
    1000,
  );

  const totalPages = Math.ceil((allTotalBillPayments || 0) / +countRow) || 1;

  const changePage = (nbr: number) => {
    let nextNbr: number = nbr;
    if (Number.isNaN(nbr)) nextNbr = 1;
    if (nbr <= 1) nextNbr = 1;
    if (nbr >= totalPages) nextNbr = totalPages;

    setNbrPage(nextNbr);
    setSkip((nextNbr - 1) * +countRow);
  };

  const changeCountRow = (cnt: number) => {
    changePage(1);
    setCountRow(`${cnt}`);
  };

  const handleExportCSV = async () => {
    const response = await dispatch(
      exportPaymentsCSV({
        searchText,
        status: paymentStatus,
        limit: +countRow,
        skip,
        periodTime,
        sortBy,
        sortOrder,
      }),
    );

    if (exportPaymentsCSV.fulfilled.match(response)) {
      setCSVData(response.payload);
    }
  };

  const handleDateFilter = (value: DateRangeTuple) => {
    changePage(1);
    setPeriodTime(value);
    dispatch(setTablePaymentsDateFilter(value));
  };

  const isShowColumn = (id: string) => visibleCols.find((el) => el.id === id);

  return (
    <DashboardLayout pageTitle="Admin Bill Payments">
      {canViewPayments && (
        <div className="customers">
          <div className="table-filter">
            <div className="table-filter__col table-filter__col--select">
              <Select
                classes="table-filter__payment select--no-margin"
                id="filter-payment"
                data={[
                  { name: 'All payment status', dataId: 'all' },
                  { name: 'Payment processed', dataId: 'succeeded' },
                  { name: 'Payment pending', dataId: 'started' },
                  { name: 'Payment failed', dataId: 'failed' },
                  { name: 'Submitted', dataId: 'refund_requested' },
                ]}
                value={paymentStatus}
                totalItems={allTotalBillPayments}
                onChange={(val: string) => {
                  setPaymentStatus(val);
                  changePage(1);
                }}
              />
            </div>
            <div className="table-filter__col table-filter__col--search">
              <Search
                id="filter-search"
                classes="table-filter__search"
                placeholder="Search for a name, account number or email address"
                value={searchText}
                onChange={(value: string) => {
                  setSearchText(value);
                  changePage(1);
                }}
              />
            </div>
            <div className="table-filter__col">
              <DateRangeFilter
                classes="table-filter__date-filter"
                onChangePeriod={(value) => handleDateFilter(value)}
                dateRangeFilter={dateRangeFilter}
                settings={dateRangeSettings}
                onChangeRangeSettings={(settings) => {
                  dispatch(setTablePaymentsDateRangeSettings(settings));
                }}
              />
            </div>
            <div className="table-filter__col">
              <TableCustomizeFilter onReset={handleResetTableFilter} />
            </div>
            <div className="table-filter__col">
              <QuickActionsSelect
                data={csvData}
                text="Quick actions"
                onExportCSV={handleExportCSV}
              />
            </div>
          </div>
          {/* Table */}
          <div className="table-scroll table-scroll--with-fixed-col">
            <div className="table-scroll__box">
              <table className="table">
                <tbody className="table__body">
                  <BillPaymentsTableHead
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    canRefund={!!canRefund}
                    columns={visibleCols}
                  />
                  {allBillPayments?.map(
                    ({
                      uuid,
                      payer,
                      amount,
                      status,
                      initiatedAt,
                      paymentAt,
                      origin,
                      transactionId,
                      refund,
                      batchingStatus,
                    }: BillPayment) => {
                      const paymentAtStr = isValidDate(paymentAt)
                        ? getPaymentDate(paymentAt || '')
                        : '';

                      const instantiatedAtStr = isValidDate(initiatedAt)
                        ? getDate(initiatedAt || '')
                        : '';

                      const statusPayment =
                        batchingStatus && batchingStatus === StatusPayment.SUCCEEDED
                          ? StatusPayment.COMPLETED
                          : status;

                      return (
                        <tr className="table__data-row" key={uuid}>
                          {isShowColumn('customerName') && (
                            <td className="table__cell table__cell--data table__cell--fixed">
                              <MemberNameFL name={payer.name} color="green" />
                            </td>
                          )}
                          {isShowColumn('accountNumber') && (
                            <td className="table__cell table__cell--data">
                              <TableAccountNumber accountNumber={origin.accountNumber} />
                            </td>
                          )}
                          {isShowColumn('dateOfBirth') && (
                            <td className="table__cell table__cell--data">&nbsp;</td>
                          )}
                          {isShowColumn('address') && (
                            <td className="table__cell table__cell--data">&nbsp;</td>
                          )}
                          {isShowColumn('phoneNumber') && (
                            <td className="table__cell table__cell--data">
                              {/* {formatPhoneNumber(getContact(payer.phoneNumber))} */}
                              {parsePhoneNumber(
                                getContact(payer.phoneNumber),
                              )?.formatInternational()}
                            </td>
                          )}
                          {isShowColumn('email') && (
                            <td className="table__cell table__cell--data table__cell--email">
                              <Tooltip text={getContact(payer.email)}>
                                <span>{getContact(payer.email)}</span>
                              </Tooltip>
                            </td>
                          )}
                          {isShowColumn('requestApproved') && (
                            <td className="table__cell table__cell--data">{instantiatedAtStr}</td>
                          )}
                          {isShowColumn('transactionId') && (
                            <td className="table__cell table__cell--data">{transactionId}</td>
                          )}
                          {isShowColumn('paymentAmount') && (
                            <td className="table__cell table__cell--data">
                              <span
                                className={classNames('table-highlight', {
                                  'table-highlight__failed-amount': status === StatusPayment.FAILED,
                                })}
                              >
                                <Icon name="dollar-circle-small" classes="table-highlight__icon" />
                                {numeral(amount.value).format('0,000.00')}
                              </span>
                            </td>
                          )}
                          {isShowColumn('paymentStatus') && (
                            <td className="table__cell table__cell--data">
                              <TableStatus type={statusPayment} />
                            </td>
                          )}
                          {isShowColumn('paymentDate') && (
                            <td className="table__cell table__cell--data">{paymentAtStr}</td>
                          )}

                          {canRefund && isShowColumn('reimbursement') && (
                            <td className="table__cell table__cell--data">
                              {status === StatusPayment.SUCCEEDED && (
                                <button
                                  type="button"
                                  className="refund-action"
                                  onClick={() => {
                                    dispatch(
                                      setModalRefundOpen({
                                        open: true,
                                        rInfo: {
                                          customerName: payer.name,
                                          billingAccountNumber: origin.accountNumber,
                                          transactionId: transactionId || '',
                                          paymentId: uuid,
                                          paymentDate: paymentAt,
                                          amount,
                                        },
                                      }),
                                    );
                                  }}
                                >
                                  <Icon name="lightning-refund" classes="refund-action__icon" />
                                  Refund
                                </button>
                              )}

                              {status === StatusPayment.REFUND_REQUESTED && (
                                <Tooltip text="View submitted refund">
                                  <button
                                    type="button"
                                    className="refund-action refund-action--mint"
                                    onClick={() => {
                                      dispatch(
                                        setModalSubmittedOpen({
                                          submittedBy: refund?.submittedBy || '',
                                          customerName: refund?.customerName || '',
                                          billingAccountNumber: refund?.billingAccountNumber || '',
                                          transactionId: refund?.transactionId || '',
                                          amount: refund?.amount,
                                          paymentDate: paymentAt,
                                          reason: refund?.reason,
                                        }),
                                      );
                                    }}
                                  >
                                    <Icon name="doc-refund" classes="refund-action__icon" />
                                    Submitted
                                  </button>
                                </Tooltip>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pagination */}
          <div className="table-pagination">
            <div className="table-pagination__col">
              <Label forId="per-page" classes="table-pagination__per-page-label">
                Rows per page
              </Label>
              <Select
                classes="select--no-margin table-pagination__per-page-select"
                id="per-page"
                data={[
                  { dataId: '8', name: '8' },
                  { dataId: '16', name: '16' },
                  { dataId: '32', name: '32' },
                  { dataId: '64', name: '64' },
                ]}
                value={countRow}
                onChange={changeCountRow}
              />
            </div>
            <div className="table-pagination__col table-pagination__col--page-nav">
              <button
                type="button"
                className="table-pagination__button table-pagination__button--prev"
                disabled={nbrPage <= 1}
                onClick={() => changePage(nbrPage - 1)}
              >
                <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
              </button>
              <div className="table-pagination__center">
                <input
                  type="text"
                  className="input table-pagination__input"
                  value={nbrPage}
                  onChange={(e) => changePage(+e.target.value)}
                />
                <span className="table-pagination__text">{`of ${totalPages}`}</span>
              </div>
              <button
                type="button"
                className="table-pagination__button table-pagination__button--next"
                onClick={() => changePage(nbrPage + 1)}
                disabled={nbrPage >= totalPages}
              >
                <Icon name="arrow-right-circle" classes="table-pagination__button-icon" />
              </button>
            </div>
          </div>
        </div>
      )}
      <ModalRefund
        pageSettings={{
          searchText,
          status: paymentStatus,
          limit: +countRow,
          skip,
          periodTime,
          sortBy,
          sortOrder,
        }}
      />
      <ModalRefunded />
    </DashboardLayout>
  );
};
