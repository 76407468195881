import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../../main/network';
import { AppDispatch } from '../../../../main/store';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { parseError } from '../../../../shared/utils/helpers';

export type UpdateSftpFormValues = {
  password: string;
  passwordConfirmation: string;
};

export const updateSftpSettings = createAsyncThunk<
  UpdateSftpFormValues,
  UpdateSftpFormValues,
  { rejectValue: string; dispatch: AppDispatch }
>('partners/updateSftpSettings', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await api.patch(`/partners/sftp-settings/account-validation-files`, {
      sftp: { password: payload.password },
    });
    dispatch(addAlert({ text: 'Your password has been successfully changed!' }));
    return payload;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    dispatch(
      addAlert({ text: 'Couldn’t reset password, please try again', type: AlertType.error }),
    );
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
