export enum StatusInTable {
  PENDING = 'pending',
  PROCESSED = 'processed',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum StatusPayment {
  STARTED = 'started',
  SUCCEEDED = 'succeeded',
  REFUND_REQUESTED = 'refund_requested',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export type MonetaryValueDto = {
  value: number;
  currency: string;
};

type BillerDto = {
  partnerId: string;
  accountId: string;
};

type PayerDto = {
  id: string;
  name: string;
  phoneNumber?: string;
  email?: string;
};

type OriginDto = {
  entity: string;
  id: string;
  accountNumber: string;
};

export type RefundDto = {
  customerName: string;
  billingAccountNumber: string;
  transactionId: string;
  amount: MonetaryValueDto;
  reason: string;
  paymentDate: string;
  submittedBy: string;
};

export type BillPaymentDto = {
  uuid: string;
  createdAt: string;
  initiatedAt?: string;
  paymentAt?: string;
  status: string;
  batchingStatus?: string;
  biller: BillerDto;
  origin: OriginDto;
  payer: PayerDto;
  amount: MonetaryValueDto;
  transactionId: string;
  refund?: RefundDto;
  refundHistory: RefundHistoryEntryDto[];
};

export type BillPayment = BillPaymentDto;

export type BillPaymentsSettings = {
  id: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  fixed?: boolean;
  sortName?: string;
  width?: string;
};
export class BillPaymentModel {
  public static fromDTO(dto: BillPaymentDto): BillPayment {
    return {
      ...dto,
    };
  }
}

export type RefundHistoryEntryDto = {
  refundId: string;
  initiatedBy?: {
    uuid: string;
  };
  timestamp: string;
  amount: MonetaryValueDto;
  reason: string;
  outcome?: RefundOutcomeDto;
};

export type RefundOutcomeDto = {
  refundId: string;
  failure?: RefundFailureDto;
};

export type RefundFailureDto = {
  code: string;
  reason: string;
};
