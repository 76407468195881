import classNames from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler, useEffect, useState, useRef } from 'react';

import Icon from './Icon';

type Props = {
  type: 'text' | 'password' | 'email' | 'tel' | 'textarea' | 'number' | 'money';
  id: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string | number | undefined;
  createMode?: boolean;
  disabled?: boolean;
  prependIconName?: string;
  prependImageUrl?: string;
  isShowImage?: boolean;
  prependInitials?: string;
  classes?: string;
  passStrengthVector?: Array<boolean>;
  rerender?: number;
  passwordTypeBtn?: 'text' | 'icon';
  copyButton?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  isHideNumControls?: boolean;
  autoComplete?: boolean;
  postfix?: string;
  defaultValue?: string;
  showError?: boolean;
  showAttentionErrorIcon?: boolean;
};

export default function Input({
  autoComplete = true,
  type,
  id,
  placeholder,
  error,
  onChange,
  onBlur,
  onFocus,
  value,
  createMode = false,
  disabled = false,
  prependIconName,
  prependImageUrl,
  prependInitials,
  classes,
  passStrengthVector,
  rerender,
  isShowImage,
  passwordTypeBtn = 'icon',
  copyButton = false,
  min,
  max,
  minLength,
  maxLength,
  isHideNumControls = false,
  postfix,
  defaultValue,
  showError = true,
  showAttentionErrorIcon = true,
}: Props) {
  const [inputType, setInputType] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [width, setWidth] = useState(0);
  const [fakeValue, setFakeValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const fakeElem = useRef<HTMLSpanElement>(null);
  const [moneyValue, setMoneyValue] = useState(value);

  const passView = () => {
    if (inputType === 'password') {
      return setInputType('text');
    }
    return setInputType('password');
  };

  const formatMoney = (value: string): string => {
    if (!value) return '';

    const cleanedValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');

    let [integer, decimal] = cleanedValue.split('.');

    integer = integer.replace(/^0+/, '') || '0';

    if (integer === '0' && decimal !== undefined && value.startsWith('0.')) {
      integer = '0';
    }

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimal) {
      decimal = decimal.substring(0, 2);
    }

    return decimal !== undefined ? `${integer}.${decimal}` : integer;
  };

  const adjustCursorPosition = (
    originalPosition: number,
    originalValue: string,
    formattedValue: string,
  ): number => {
    const lengthDifference = formattedValue.length - originalValue.length;
    let newPosition = originalPosition + lengthDifference;

    if (formattedValue[newPosition - 1] === ',' && newPosition > 0) {
      newPosition -= 1;
    }

    return newPosition;
  };

  const handleMoneyChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputElement = e.target;
    const originalValue = inputElement.value;
    const originalPosition = inputElement.selectionStart ?? 0;

    const formattedValue = formatMoney(originalValue);
    setMoneyValue(formattedValue);

    if (onChange) {
      const rawValue = formattedValue.replace(/,/g, '').replace(/^0+(?!\.|$)/, '');
      const event = {
        ...e,
        target: {
          ...e.target,
          value: rawValue,
        },
      };
      onChange(event);
    }

    const newPosition = adjustCursorPosition(originalPosition, originalValue, formattedValue);
    setTimeout(() => {
      // eslint-disable-next-line no-multi-assign
      inputElement.selectionStart = inputElement.selectionEnd = newPosition;
    });
  };

  useEffect(() => {
    if (!inputType && type) {
      setInputType(type);
    }

    if (postfix && fakeElem.current) {
      setWidth(fakeElem.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const checkPassStrength = (index: number) => {
    const isCondition = passStrengthVector && passStrengthVector[index];

    return classNames('password-strength__criteria', {
      'password-strength__criteria--active': isCondition,
    });
  };

  const checkRangePassword = () => {
    if (passStrengthVector) {
      const passConditionsCount = passStrengthVector.reduce((acc, i) => (i ? acc + 1 : acc), 0);

      return classNames('password-strength__progress-box', {
        'password-strength__progress-box--red': passConditionsCount >= 1,
        'password-strength__progress-box--half': passConditionsCount === 2,
        'password-strength__progress-box--yellow': passConditionsCount === 3,
        'password-strength__progress-box--mint': passConditionsCount === 4,
      });
    }
    return classNames('password-strength__progress-box');
  };

  const handleChangeNumber = (typeChange: 'increment' | 'decrement') => {
    if (inputRef && inputRef.current) {
      if (typeChange === 'increment') inputRef.current.stepUp();

      if (typeChange === 'decrement') inputRef.current.stepDown();

      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(String(value));
  };

  const handleChangePostfix = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFakeValue(e.target.value);
    if (onChange) onChange(e);
    setTimeout(() => {
      if (fakeElem.current) {
        setWidth(fakeElem.current.offsetWidth);
      }
    });
  };

  return (
    <>
      {postfix && (
        <span className="fake" ref={fakeElem}>
          {fakeValue || placeholder}
        </span>
      )}
      <div
        className={`
        input-group
        ${error ? ' input-group--error' : ''}
        ${disabled ? ' input-group--disabled' : ''}
        ${prependIconName || isShowImage || prependInitials ? ' input-group--with-prepend' : ''}
        ${classes ? ` ${classes}` : ''}`}
      >
        <div className="input-group__input-box">
          {prependIconName && <Icon name={prependIconName} classes="input-group__prepend-icon" />}
          {prependInitials && !isShowImage && (
            <div className="input-group__prepend-initials-box">
              <span className="input-group__prepend-initials">{prependInitials}</span>
            </div>
          )}
          {isShowImage && (
            <div className="input-group__prepend-image-box">
              <img
                className="input-group__prepend-image"
                src={
                  prependImageUrl
                    ? `${prependImageUrl}?${rerender}`
                    : '../images/dashboard/no-photo.png'
                }
                alt=""
              />
            </div>
          )}
          {type !== 'money' ? (
            <>
              {type !== 'textarea' && !postfix && (
                <input
                  autoComplete={!autoComplete ? 'new-password' : undefined}
                  ref={inputRef}
                  className="input input-group__input"
                  type={inputType || type}
                  name={id}
                  id={id}
                  data-testid={id}
                  placeholder={placeholder}
                  onChange={onChange}
                  min={min}
                  max={max}
                  minLength={minLength}
                  maxLength={maxLength}
                  value={value}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  onFocus={(e) => {
                    setIsTouched(true);
                    if (onFocus) onFocus(e);
                  }}
                  onBlur={onBlur}
                />
              )}
            </>
          ) : (
            <>
              <div className="input-group__prefix-flag">
                <Icon name="dollar" />
              </div>
              <input
                autoComplete={!autoComplete ? 'new-password' : undefined}
                ref={inputRef}
                value={moneyValue}
                type={inputType || type}
                className="input input-group__input input__money"
                name={id}
                id={id}
                data-testid={id}
                placeholder={placeholder}
                onChange={handleMoneyChange}
                min={min}
                max={max}
                minLength={minLength}
                maxLength={maxLength}
                defaultValue={defaultValue}
                disabled={disabled}
                onFocus={(e) => {
                  setIsTouched(true);
                  if (onFocus) onFocus(e);
                }}
                onBlur={onBlur}
              />
            </>
          )}
          {postfix && (
            <div className="input input--postfix">
              <input
                autoComplete={!autoComplete ? 'new-password' : undefined}
                type="text"
                id="domain"
                value={value}
                onChange={handleChangePostfix}
                onFocus={(e) => {
                  setIsTouched(true);
                  if (onFocus) onFocus(e);
                }}
                onBlur={onBlur}
                className="input__inline-input"
                placeholder={placeholder}
                style={{ width }}
                minLength={minLength}
                maxLength={maxLength}
              />
              <span>{postfix}</span>
            </div>
          )}
          {type === 'textarea' && (
            <textarea
              className="input input--textarea input-group__input"
              name={id}
              id={id}
              data-testid={id}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              disabled={disabled}
              onFocus={() => {
                setIsTouched(true);
              }}
              minLength={minLength}
              maxLength={maxLength}
              onBlur={onBlur}
            />
          )}
          <div className="input-group__icon-list">
            {type === 'password' ? (
              <div
                className="input-group__icon-box input-group__icon-box--password"
                onMouseDown={() => passView()}
                role="button"
                tabIndex={-1}
              >
                {passwordTypeBtn === 'icon' ? (
                  <Icon
                    name={inputType === 'text' ? 'eye-open' : 'eye-close'}
                    classes="input-group__icon"
                  />
                ) : (
                  <span className="input-group__icon-box--password-txt">
                    {inputType === 'text' ? 'Hide' : 'Show'}
                  </span>
                )}
              </div>
            ) : null}

            {type === 'number' && !isHideNumControls ? (
              <div className="input-group__number-controls">
                <button
                  type="button"
                  className="input-group__number-btn input-group__number-btn--up"
                  onClick={() => handleChangeNumber('increment')}
                >
                  <Icon name="chevron" />
                </button>

                <button
                  type="button"
                  className="input-group__number-btn input-group__number-btn--down"
                  onClick={() => handleChangeNumber('decrement')}
                >
                  <Icon name="chevron" />
                </button>
              </div>
            ) : null}

            {copyButton && (
              <button className="input-group__copy-btn" type="button" onClick={handleCopy}>
                Copy
              </button>
            )}
            {showAttentionErrorIcon && (
              <div className="input-group__icon-box input-group__icon-box--error">
                <Icon name="attention" classes="input-group__icon" />
              </div>
            )}
          </div>
        </div>

        {/* Password strength */}
        {createMode && (
          <div
            className={`password-strength input-group__password-strength${
              isTouched ? ' input-group__password-strength--show' : ''
            }`}
          >
            <ul className="password-strength__criteria-list">
              <li className={checkPassStrength(0)}>
                <Icon name="check" classes="password-strength__check-icon" />
                8&nbsp;characters
              </li>
              <li className={checkPassStrength(1)}>
                <Icon name="check" classes="password-strength__check-icon" />
                Uppercase
              </li>
              <li className={checkPassStrength(2)}>
                <Icon name="check" classes="password-strength__check-icon" />
                Numbers
              </li>
              <li className={checkPassStrength(3)}>
                <Icon name="check" classes="password-strength__check-icon" />
                Symbol
              </li>
            </ul>
            <div className={checkRangePassword()}>
              <div className="password-strength__progress">
                <div className="password-strength__progress-value" />
              </div>
            </div>
          </div>
        )}

        {showError && <span className="input-group__error-text">{error}</span>}
      </div>
    </>
  );
}
